import { FETCH_STATS_DATA } from '../actions/home.action';
import { FETCH_REQUEST_USER_DATA } from '../actions/home.action';
const homeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STATS_DATA:
      return {
        ...state,
        statsData: action.payload,
      };

    case FETCH_REQUEST_USER_DATA:
      return {
        ...state,
        requestUsersDetails: action.payload,
      };

    default:
      // need this for default case
      return state;
  }
};
export default homeReducer;
