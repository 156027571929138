import CallAPI from "../../axios";

export const FETCH_EQUIPMENT_SUB_TYPES = "FETCH_EQUIPMENT_SUB_TYPES";
export const FETCH_EQUIPMENT_SUB_TYPES_DETAILS =
  "FETCH_EQUIPMENT_SUB_TYPES_DETAILS";
export const FETCH_EQUIPMENT_SUB_TYPES_DD = "FETCH_EQUIPMENT_SUB_TYPES_DD";

const fetchData = (data) => {
  return {
    type: FETCH_EQUIPMENT_SUB_TYPES,
    payload: data,
  };
};

const fetchEquipTypeDetailsData = (data) => {
  return {
    type: FETCH_EQUIPMENT_SUB_TYPES_DETAILS,
    payload: data,
  };
};

const fetchEquipTypeDropDown = (data) => {
  return {
    type: FETCH_EQUIPMENT_SUB_TYPES_DD,
    payload: data,
  };
};

export const fetchEquipmentSubType = () => {
  return (dispatch) => {
    CallAPI.getData(`/equipments/equipmentSubTypes`)
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipmentSubTypeDetails = (equipmentSubTypeCode) => {
  return (dispatch) => {
    CallAPI.getData(`/equipments/equipmentSubTypes/${equipmentSubTypeCode}`)
      .then((response) => {
        dispatch(fetchEquipTypeDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipmentSubTypeDropDown = (equipmentTypeCode) => {
  return (dispatch) => {
    CallAPI.getData(`/equipments/types/equipmentSubTypes/${equipmentTypeCode}`)
      .then((response) => {
        dispatch(fetchEquipTypeDropDown(response.data.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
