import CallAPI from "../../axios";

export const FETCH_SENSOR_TYPES = "FETCH_SENSOR_TYPES",
  FETCH_SENSOR_TYPE_DETAILS = "FETCH_SENSOR_TYPE_DETAILS",
  FETCH_SENSOR = "FETCH_SENSOR",
  FETCH_SENSOR_COMPANY = "FETCH_SENSOR_COMPANY",
  FETCH_SENSOR_DETAILS = "FETCH_SENSOR_DETAILS",
  SET_SENSOR_TYPE_ID = 'SET_SENSOR_TYPE_ID';

const fetchData = (data) => {
  return {
    type: FETCH_SENSOR_TYPES,
    payload: data,
  };
};

const fetchDetailsData = (data) => {
  return {
    type: FETCH_SENSOR_TYPE_DETAILS,
    payload: data,
  };
};

const fetchSensorData = (data) => {
  return {
    type: FETCH_SENSOR,
    payload: data,
  };
};
const fetchSensorCompanyData = (data) => {
  return {
    type: FETCH_SENSOR_COMPANY,
    payload: data,
  };
};
const fetchSensorDetailsData = (data) => {
  return {
    type: FETCH_SENSOR_DETAILS,
    payload: data,
  };
};
export const setSensorTypeId = (data) => {
  return {
    type: SET_SENSOR_TYPE_ID,
    payload: data,
  };
};

export const fetchSensorTypes = () => {
  return (dispatch) => {
    CallAPI.getSensorTypes()
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchSensorTypesDetails = (sensorId) => {
  return (dispatch) => {
    CallAPI.getSensorType_byId(sensorId)
      .then((response) => {
        dispatch(fetchDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchSensor = () => {
  return (dispatch) => {
    CallAPI.getData(`/sensors`)
      .then((response) => {
        dispatch(fetchSensorData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchSensorCompany = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await Promise.all([
        CallAPI.sensorManage_AllSensorList(companyId),
        CallAPI.sensorManage_AssignedList(companyId),
      ]);

      const allList = CallAPI.responseHandler(response[0], []);
      const assignedList = CallAPI.responseHandler(response[1], []);

      allList.map((val) => {
        return assignedList.map((item) => {
          if (item.sensorId === val.sensorId) {
            return (val["tagId"] = item.tagId);
          }
        });
      });

      dispatch(fetchSensorCompanyData({ data: allList }));
    } catch (error) {
      throw error;
    }
  };
};

export const fetchSensorDetails = (sensorId) => {
  return (dispatch) => {
    CallAPI.getData(`/sensors/details/${sensorId}`)
      .then((response) => {
        dispatch(fetchSensorDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
