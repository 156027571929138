import * as yup from "yup";

const validation = {
  async validateComponent(object, add = false) {
    let error = "";
    let schema = {};

    let schemaAdd = yup.object().shape({
      customName: yup
        .string()
        .required()
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for Company Name field "
        )
        .label("Component Name"),
      componentType: yup.string().required().label("Component Type"),
      componentSubType: yup.string().required().label("Component Sub Type"),
      // groupCode: yup.number().required().label('Group'),
      // operationalSpecifications: yup.array().of(yup.object().shape({
      //     name: yup.string().required().trim().label('Name in Operational Specification'),
      //     value: yup.string().required().trim().label('Value in Operational Specification')
      // }))
    });

    let schemaUpdate = yup.object().shape({
      customName: yup.string().required().label("Component Name"),
      componentType: yup.string().required().label("Component Type"),
      componentSubType: yup.string().required().label("Component Sub Type"),
      // operationalSpecifications: yup.array().of(yup.object().shape({
      //     name: yup.string().required().trim().label('Name in Operational Specification'),
      //     value: yup.string().required().trim().label('Value in Operational Specification')
      // }))
    });

    schema = add ? schemaAdd : schemaUpdate;

    try {
      await schema.validate(object, { abortEarly: false, strict: true });
    } catch (err) {
      error = err.errors[0];
    }
    return error;
  },
};

export default validation;
