import React from "react";
import { connect } from "react-redux";
import {
  fetchMachineEquipmentDetails,
  fetchEquipmentOptions,
} from "../../../../redux/actions/equipmentType.action";
import _ from "lodash";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  ColumnToggle,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import ImageUploader from "react-images-upload";
import "./equipments.css";
import axios from "../../../../axios";
import MaterialIcon, { colorPalette } from "material-icons-react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

import DIYQuest from "./equipmentConfigForm/index";

const { SearchBar } = Search;
const { ToggleList } = ColumnToggle;
const { ExportCSVButton } = CSVExport;

const options = {
  showTotal: true,
};

let companyId = "";
let machineId = "";

class Equipments extends React.Component {
  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login", 0);
    }
  }

  state = {
    equipments: null,
    list: true,
    equipment: {},
    equipmentTypes: [],
    equipmentType: null,
    images: [],
    saving: false,
    openDIYAns: false,
    columns: [
      {
        text: "Equipment Name",
        dataField: "customName",
        sort: true,
      },
      {
        text: "Equipment Id",
        dataField: "equipmentId",
        sort: true,
      },
      {
        text: "Equipment Type",
        dataField: "equipmentType",
        sort: true,
        hidden: false,
      },
      {
        text: "Equipment Configuration",
        dataField: "DIYQuestionaireId",
        sort: false,
        formatter: this.configDIY.bind(this),
        events: {
          onClick: async (e, column, columnIndex, row, rowIndex) => {
            if (row.equipmentType === "transformer") {
              toast.error("Cannot config Transformer");
              return;
            }
            this.setState({
              questionniare: row,
            });
            this.setState({ openDIYAns: true });
          },
        },
      },
      // {
      //   text: "Equipment Sub Type",
      //   dataField: "equipmentSubTypeData.customName",
      //   sort: true,
      //   hidden: false,
      // },
      // {
      //   text: "Sensor Id",
      //   dataField: "dummy_sensor",
      //   sort: true,
      //   formatter: this.sensorDisplay,
      //   hidden: true,
      // },
      // {
      //   text: "TagId",
      //   dataField: "dummy_tag",
      //   sort: true,
      //   formatter: this.tagDisplay,
      //   hidden: true,
      // },
      {
        text: "Action",
        dataField: "equipmentId",
        sort: false,
        formatter: this.action.bind(this),
      },
      {
        text: "Delete",
        dataField: "equipmentId",
        formatter: this.delete.bind(this),
      },
    ],
    currentQuest: null,
  };

  sensorDisplay(cell, row) {
    let sensorIds = [];
    let tags = row.tag.map((config) => {
      if (config.tagConfigMaps) {
        if (config.tagConfigMaps.sensorConfig) {
          sensorIds.push(config.tagConfigMaps.sensorConfig.sensorId);
        }
      }
    });
    return sensorIds.join(", ");
  }

  tagDisplay(cell, row) {
    let tagIds = [];
    let tags = row.tag.map((config) => {
      return config.tagId;
    });
    return tags.join(", ");
  }

  action(cell, row) {
    return (
      <MaterialIcon
        onClick={() =>
          this.jump(
            "/v1/companies/" +
              companyId +
              "/machines/0000/equipments/" +
              row.equipmentId,
            0,
            row
          )
        }
        icon="open_in_new"
        color="#0288d1"
      ></MaterialIcon>
    );
  }

  delete(cell, row) {
    return (
      <MaterialIcon
        onClick={async () => {
          console.log(row);
          if (window.confirm("Do you really want to delete it?")) {
            await axios.equipmentManage_delete(row.equipmentId);
            toast.success("Equipment deleted Successfully");
            this.props.fetchMachineEquipmentDetails(companyId, "00000");
          }
          return;
        }}
        icon="delete"
        color="red"
      ></MaterialIcon>
    );
  }

  configDIY(cell, row) {
    return <MaterialIcon icon="open_in_new" color="#0288d1"></MaterialIcon>;
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    machineId = this.props.match.params.machineId;

    // await this.getEquipments(); //Get Equipments
    // await this.getEquipmentTypeOptions(); //Get EquipmentType for equipment type filter

    this.props.fetchMachineEquipmentDetails(companyId, "00000");

  }

  async getEquipments(equipmentType = null) {
    // let endpoint = "/equipments/" + companyId + "/00000";
    // if (equipmentType) {
    //   endpoint += "/" + equipmentType;
    // }
    let equipments = await axios.equipmentManage_equipmentList(companyId);
    console.log("equipmentsequipmentsequipments", equipments);
    this.setState({ equipments: equipments.data.data });
  }

  async getEquipmentTypeOptions() {
    let endpoint = "/equipments/options/" + companyId;
    let equipmentTypes = await axios.getData(endpoint);
    let options = [];
    equipmentTypes.data.data.forEach((eq, i) => {
      options.push({
        key: i,
        label: `${eq.equipmentTypeData.customName} - ${eq.equipmentTypeData.equipmentTypeCode}`,
        value: eq.equipmentTypeData.equipmentTypeCode,
      });
    });

    this.setState({ equipmentTypes: options });
  }

  handleSelectChange =
    (equipmentType, type = "filter") =>
    async (option) => {
      if (type === "filter") {
        let equipmentType = option ? option.value : null;
        await this.getEquipments(equipmentType);
      } else if (type === "add") {
        let equipment = { ...this.state.equipment };
        equipment.equipmentType = option.value;
        this.setState({ equipment });
      }
    };

  handleChange = (input) => (e) => {
    let equipment = { ...this.state.equipmet };
    equipment[input] = e.target.value;
    this.setState({ equipment: equipment });
  };

  onDrop = (pictureFiles, pictureData) => {
    // console.log(pictureData.length, '----------')
    let equipment = { ...this.state.equipment };
    equipment.imagesUrl = JSON.stringify(pictureData);
    this.setState({ equipment: equipment });
  };

  async handleSave() {
    this.setState({ saving: true });
    let equipment = { ...this.state.equipment };

    //Validating
    let err = await validation.validate(equipment);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    equipment.companyId = companyId;
    let api = await axios.equipmentManage_create(equipment);
    if (api.status === 200) {
      toast.success("Equipment saved successfully");
      localStorage.removeItem("companyId");
      await this.getEquipments();
      this.setState({ list: true });
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  jump = (url, delay = 1000, passedVal = null) => {
    setTimeout(() => {
      this.props.history.push({ pathname: url, state: passedVal });
    }, delay);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let equipments = null,
      optionsEquipTypeData = [];

    if (prevState.isFilter) {
      return;
    }

    if (nextProps.equipOptionsData !== prevState.equipOptionsData) {
      if (nextProps.equipOptionsData) {
        nextProps.equipOptionsData.data.forEach((eq, i) => {
          if (eq.equipmentTypeData !== null) {
            optionsEquipTypeData.push({
              key: i,
              label: `${eq.customName}`,
              value: null,
            });
          }
        });
      }
    }

    if (
      nextProps.machineEquipDetailsData !== prevState.machineEquipDetailsData
    ) {
      if (nextProps.machineEquipDetailsData) {
        equipments =
          nextProps.machineEquipDetailsData.status !== "failed"
            ? nextProps.machineEquipDetailsData.data
            : null;
      }
    }

    return {
      equipments: equipments !== null ? equipments : null,
      equipmentTypes:
        optionsEquipTypeData.length !== 0 ? optionsEquipTypeData : [],
    };
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.jump('/v1/equipment-details/' + companyId + '/00000/' +  row.equipmentId, 0);
        // this.jump('/v1/companies/' + companyId + '/00000/' +  row.equipmentId, 0);
        this.jump(
          "/v1/companies/" +
            companyId +
            "/machines/0000/equipments/" +
            row.equipmentId,
          0
        );
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row>
            <Col lg="6">
              <div
                onClick={() => {
                  this.jump("/v1/companies/" + companyId, 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
              {this.state.list && (
                <Button
                  color="success"
                  // onClick={() => {this.setState({list: false})}}
                  onClick={() => {
                    this.jump(
                      `/v1/companies/${companyId}/machines/${machineId}/equipments/add`,
                      0
                    );
                  }}
                  size="md"
                  style={{ margin: "10px" }}
                >
                  Add Equipments
                </Button>
              )}{" "}
            </Col>
            <Col lg="6" className="table-caption">
              <h1>Equipments</h1>
            </Col>
          </Row>
          <Row>
            <Col lg="8"></Col>
            {/* <Col lg="4">
              {this.state.list && (
                <Select
                  className="add-btn"
                  isClearable={true}
                  onChange={this.handleSelectChange("equipmentType")}
                  placeholder="Equipment Type"
                  options={this.state.equipmentTypes}
                />
              )}
            </Col> */}
          </Row>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.equipments && this.state.list && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={this.state.columns}
                    data={this.state.equipments}
                    columnToggle
                    search={{ searchFormatted: true }}
                  >
                    {(props) => (
                      <div>
                        {/* <br></br>
                          <center>
                            <ToggleList 
                              contextual="info"
                              className="list-custom-class"
                              btnClassName="list-btn-custom-class"
                              { ...props.columnToggleProps } 
                            />
                          </center> */}
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="_id"
                          // rowEvents={ rowEvents }
                          data={this.state.equipments}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Equipment */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipment Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup style={{ zIndex: 99999 }}>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Equipment Type
                            </label>
                            <Select
                              isClearable={true}
                              onChange={this.handleSelectChange(
                                "equipmentType",
                                "add"
                              )}
                              placeholder=""
                              options={this.state.equipmentTypes}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <div className="image-container-add">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Equipment Image
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                onChange={this.onDrop}
                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                maxFileSize={2000000} //2MB
                                withPreview={true}
                                label="Max file size: 2MB, accepted: jpg|png"
                              />
                            </div>
                          </FormGroup>
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.openDIYAns}
          size="lg"
          toggle={() => {
            this.setState({ openDIYAns: !this.state.openDIYAns });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ openDIYAns: !this.state.openDIYAns });
            }}
          >
            ASSET CONFIGURATION
          </ModalHeader>
          <ModalBody>
            <DIYQuest
              closeModal={() => {
                this.setState({ openDIYAns: false });
              }}
              equipmentDIY={this.state.questionniare}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  equipOptionsData: _.isEmpty(state.equipmentType.equipOptionsData)
    ? null
    : state.equipmentType.equipOptionsData,
  machineEquipDetailsData: _.isEmpty(
    state.equipmentType.machineEquipDetailsData
  )
    ? null
    : state.equipmentType.machineEquipDetailsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEquipmentOptions: (companyId) =>
      dispatch(fetchEquipmentOptions(companyId)),
    fetchMachineEquipmentDetails: (companyId, machineId) =>
      dispatch(fetchMachineEquipmentDetails(companyId, machineId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Equipments);
