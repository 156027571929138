import React from "react";
import { connect } from "react-redux";
import { fetchMachine } from "../../../../redux/actions/machineType.action";
import { fetchGroup } from "../../../../redux/actions/companies.action";
import _ from "lodash";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ImageUploader from "react-images-upload";
import "./machine.css";
import axios from "../../../../axios";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

let companyId = "";

class Machines extends React.Component {
  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  state = {
    machines: null,
    list: true,
    machine: {},
    plantList: [],
    machineTypes: [],
    machineType: null,
    images: [],
    saving: false,
    isFilter: false,
    columns: [
      {
        text: "Machine Name",
        dataField: "customName",
        sort: true,
      },
      {
        text: "Machine Id",
        dataField: "machineId",
        sort: true,
      },
      // {
      //   text: "Machine Type Name",
      //   dataField: "machineTypeData.customName",
      //   sort: true,
      // },
      {
        text: "Action",
        dataField: "machineId",
        sort: false,
        formatter: this.action.bind(this),
      },
      {
        text: "Delete",
        dataField: "machineId",
        formatter: this.delete.bind(this),
      },
    ],
  };

  action(cell, row) {
    return (
      <MaterialIcon
        onClick={() =>
          // this.jump('/v1/companies/' + companyId + '/machines/0000/equipments/' +  row.equipmentId, 0)
          this.jump(
            "/v1/companies/" + companyId + "/machines/" + row.machineId,
            row
          )
        }
        icon="open_in_new"
        color="#0288d1"
      ></MaterialIcon>
    );
  }
  handleDelete = async (row, companyId) => {
    try {
      console.log(row);
      if (window.confirm("Do you really want to delete it?")) {
        const response = await axios.machineManage_delete(row.machineId);
        console.log(response, "rrr789");
        // Check if the response status is 404
        if (response && response.status === 404) {
          toast.error("Item not found or already deleted.");
          // Update state to reflect the deletion, even if the item wasn't found
          await this.props.fetchMachine(companyId);
        } else if (response === undefined) {
          // Response is undefined
          toast.error("No response from the server.");
          await this.props.fetchMachine(companyId);
        } else {
          // Successfully deleted
          toast.success("Deleted Successfully");
          await this.props.fetchMachine(companyId);
        }
      }
    } catch (error) {
      // Handle other errors
      console.error("API call failed: ", error);
      toast.error("Failed to delete the item. Please try again.");
    }
  };

  delete(cell, row) {
    return (
      <MaterialIcon
        onClick={() => this.handleDelete(row, companyId)}
        icon="delete"
        color="red"
      ></MaterialIcon>
    );
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;

    await this.props.fetchMachine(companyId);
    this.props.fetchGroup(companyId);
  }

  async getMachines(machineType = null) {
    let endpoint = "/machines/" + companyId;
    if (machineType) {
      endpoint += "?machineType=" + machineType;
      this.setState({ isFilter: true });
    }
    let machines = await axios.machineManage_machineList(companyId);
    this.setState({ machines: machines.data.data });
  }

  handleSelectChange =
    (machineType, type = "filter") =>
    async (option) => {
      if (type === "filter") {
        let machineType = option ? option.value : null;
        await this.getMachines(machineType);
      } else if (type === "add") {
        let machine = { ...this.state.machine };
        machine.plantId = option.value;
        this.setState({ machine });
      }
    };

  handleChange = (input) => (e) => {
    let machine = { ...this.state.machine };
    machine[input] = e.target.value;
    this.setState({ machine: machine });
  };

  onDrop = (pictureFiles, pictureData) => {
    let machine = { ...this.state.machine };
    machine["imagesUrl"] = JSON.stringify(pictureData);
    console.log(machine, "----------");
    this.setState({ machine: machine });
  };

  async handleSave() {
    this.setState({ saving: true });
    let machine = { ...this.state.machine };

    //Validating
    let err = await validation.validate(machine);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    machine.companyId = companyId;

    machine = {
      customName: machine.customName,
      plantId: machine.plantId,
      imagesUrl: machine.imagesUrl,
      externalId: machine.externalId,
    };

    let tempImageUrl = machine.imagesUrl;
    delete machine.imagesUrl;
    let api = await axios.machineManage_create(machine);
    if (api && api.status === 200) {
      if (tempImageUrl && JSON.parse(tempImageUrl)[0]) {
        let machineImage = {
          file: JSON.parse(tempImageUrl)[0],
          fileName: `${api.data.data.machineId}.jpg`,
          companyId: companyId,
        };
        console.log("machineImagemachineImage:", machineImage);
        let api2 = await axios.fileDataImage_create(machineImage);
      }
      toast.success("Machine saved successfully");
      localStorage.removeItem("companyId");
      this.props.fetchMachine(companyId);
      this.setState({ list: true });
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  jump(url, rowDetails = null) {
    this.props.history.push(url, { rowDetails });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let machineData = null,
      optionsData = [],
      optionsTypeData = [];
    if (prevState.isFilter) {
      return;
    }
    if (nextProps.machineData !== prevState.machineData) {
      if (nextProps.machineData) {
        machineData =
          nextProps.machineData.status !== "failed"
            ? nextProps.machineData.data
            : [];
      }
    }

    if (nextProps.plantListData !== prevState.plantListData) {
      if (
        nextProps.plantListData &&
        nextProps.plantListData.data &&
        Array.isArray(nextProps.plantListData.data)
      ) {
        nextProps.plantListData.data.forEach((eq, i) => {
          optionsTypeData.push({
            key: i,
            label: `${eq.customName}`,
            value: parseInt(eq.plantId),
          });
        });
      }
    }

    if (nextProps.machineOptionsData !== prevState.machineOptionsData) {
      if (nextProps.machineOptionsData) {
        nextProps.machineOptionsData.data &&
          nextProps.machineOptionsData.data.forEach((eq, i) => {
            const { customName, machineTypeCode } = eq.machineTypeData || {};
            optionsData.push({
              key: i,
              label: `${customName} -> ${machineTypeCode}`,
              value: machineTypeCode,
            });
          });
      }
    }

    return {
      machines: machineData !== null ? machineData : null,
      machineTypes: optionsData.length !== 0 ? optionsData : [],
      plantList: optionsTypeData.length !== 0 ? optionsTypeData : [],
    };
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/companies/" + companyId + "/machines/" + row.machineId);
      },
    };
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.list ? (
                <div
                  onClick={() => {
                    this.jump("/v1/companies/" + companyId);
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : (
                <div
                  onClick={() => {
                    this.setState({ list: true });
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              )}
              {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.setState({ list: false });
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Machine
                </Button>
              )}
            </Col>
            <Col lg="6" className="table-caption">
              <h1>Machines</h1>
            </Col>
          </Row>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.machines && this.state.list && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={this.state.columns}
                    data={this.state.machines}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="_id"
                          // rowEvents={ rowEvents }
                          data={this.state.machines}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {!this.state.list && (
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Add Machine</h3>
                      </Col>
                      <Col className="text-right" xs="4"></Col>
                    </Row>
                  </CardHeader>
                )}

                {/* Add Machine */}
                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Machine Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Plant <span className="text-danger">*</span>
                            </label>
                            <Select
                              isClearable={true}
                              onChange={this.handleSelectChange(
                                "plantId",
                                "add"
                              )}
                              placeholder=""
                              options={this.state.plantList}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              External ID{" "}
                              <Tooltip
                                title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                                placement="right-start"
                              >
                                <Info
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    fontSize: "30px",
                                  }}
                                />
                              </Tooltip>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              onChange={this.handleChange("externalId")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        ;
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <div className="image-container-add">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Machine Image
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                onChange={this.onDrop}
                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                maxFileSize={2000000} //2MB
                                withPreview={true}
                                label="Max file size: 2MB, accepted: jpg|png"
                              />
                            </div>
                          </FormGroup>
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  machineData: _.isEmpty(state.machineType.machineData)
    ? null
    : state.machineType.machineData,
  plantListData: _.isEmpty(state.companies.groupData)
    ? null
    : state.companies.groupData,
  machineTypeData: _.isEmpty(state.machineType.machineTypeData)
    ? null
    : state.machineType.machineTypeData,
  machineOptionsData: _.isEmpty(state.machineType.machineOptionsData)
    ? null
    : state.machineType.machineOptionsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMachine: (companyId) => dispatch(fetchMachine(companyId)),
    fetchGroup: (companyId) => dispatch(fetchGroup(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Machines);
