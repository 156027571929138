import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";

import routes from "routes.js";
import { NONAME } from "dns";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {

    let colors = [
      {
        first: '#11cdef',
        second: '#f37d2d'
      },
      {
        first: '#11cdef',
        second: '#1171ef'
      },
      {
        first: '#F1F2B5',
        second: '#135058'
      },
      {
        first: '#7b4397',
        second: '#dc2430'
      },
      {
        first: '#136a8a',
        second: '#267871'
      },
      {
        first: '#780206',
        second: '#061161'
      },
      {
        first: '#267871',
        second: '#ff9472'
      },
    ]

    let r = Math.floor(Math.random() * 6) + 0;  
    return (
      <>
        <div className="main-content">
          <AuthNavbar />
          <div className="header bg-gradient-inf py-7 py-lg-8" style={{ background: 'linear-gradient(87deg, ' + colors[r].first + ' 0, ' + colors[r].second + ' 100%)'}}>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
