import CallAPI from "../../axios";

export const FETCH_EULA_INFO_DATA = "FETCH_EULA_INFO_DATA";
export const FETCH_EULA_INFO_DETAILS_DATA = "FETCH_EULA_INFO_DETAILS_DATA";

const fetchEulaInfoData = (data) => {
  return {
    type: FETCH_EULA_INFO_DATA,
    payload: data,
  };
};

const fetchEulaInfoDetailsData = (data) => {
  return {
    type: FETCH_EULA_INFO_DETAILS_DATA,
    payload: data,
  };
};

export const fetchEulaInfo = () => {
  return (dispatch) => {
    CallAPI.getEula()
      .then((response) => {
        dispatch(fetchEulaInfoData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEulaInfoDetails = (eulaInfoId) => {
  return (dispatch) => {
    CallAPI.getEula_byId(eulaInfoId)
      .then((response) => {
        dispatch(fetchEulaInfoDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
