import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import { fetchComponentType } from "../../../redux/actions/componentTypes.action";
import Select from "react-select";

import axios from "../../../axios";
import Helper from "../../../helper";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;

const columns = [
  {
    text: "Component Type",
    dataField: "customName",
    sort: true,
  },
  {
    text: "Type Code",
    dataField: "componentTypeCode",
    sort: true,
  },
];

const options = {
  showTotal: true,
};

let companyId = "";
let portal_user = "";

class ComponentTypes extends React.Component {
  constructor(props) {
    super(props);
    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }

    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    componentTypes: null,
    componentTypesList: [],
    list: true,
    componentType: {},
    saving: false,
    createAllowed: true,
  };

  async componentDidMount() {
    this.props.fetchComponentType();

    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != "admin") {
      this.setState({ createAllowed: false });
    }
  }

  handleChange = (input) => (e) => {
    let componentType = { ...this.state.componentType };
    componentType[input] = e.target.value;
    this.setState({ componentType: componentType });
  };

  handleSelectChange = () => (option) => {
    let componentType = { ...this.state.componentType };
    componentType.componentTypeMetaCode = option.value;
    this.setState({ componentType: componentType });
  };

  async handleSave() {
    this.setState({ saving: true });
    let componentType = { ...this.state.componentType };

    //Validating
    let err = await validation.validate(componentType);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    // componentType.companyId = companyId;
    let api = await axios.saveComponentType(componentType);
    if (api.status == 200) {
      toast.success("Component type saved successfully");
      await this.getComponentTypes();
      localStorage.removeItem("companyId");
      //Redirect to company details page
      this.setState({ list: true });
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else {
      toast.error("Something went wrong.Please try again.");
    }

    this.setState({ saving: false });
  }

  jump(url, val = null) {
    this.props.history.push({ pathname: url, state: val });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.componentTypeData !== prevState.componentTypeData) {
      if (nextProps.componentTypeData) {
        let optionsEquipTypeData = [];
        Object.keys(nextProps.componentTypeData.data).forEach((eq, i) => {
          if (eq !== "_id") {
            optionsEquipTypeData.push({
              key: i,
              componentTypeCode: `${eq}`,
              customName: eq,
            });
          }
        });
        return {
          componentTypes: optionsEquipTypeData,
          componentTypesList: nextProps.componentTypeData.data,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      // onClick: (e, row, rowIndex) => {
      //   this.jump(
      //     "/v1/component-types/" + row.componentTypeCode,
      //     this.state.componentTypesList[row.componentTypeCode]
      //   );
      // },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6"></Col>
            <Col lg="6 table-caption">
              <h1>Component Types</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Component Types</h3>
                  </CardHeader>
                )}
                {this.state.componentTypes && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          rowEvents={rowEvents}
                          pagination={paginationFactory(options)}
                          keyField="_id"
                          data={this.state.componentTypes}
                          columns={columns}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Equipment Types */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Component Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipment Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                let componentType = {
                                  ...this.state.componentType,
                                };
                                componentType["equipmentTypeCode"] = e.value;
                                this.setState({ componentType: componentType });
                              }}
                              placeholder=""
                              options={this.state.equipmentTypesList}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipment Sub Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              onChange={(e) => {
                                let componentType = {
                                  ...this.state.componentType,
                                };
                                componentType["equipmentSubTypeCode"] = e.value;
                                this.setState({ componentType: componentType });
                                console.log(componentType);
                              }}
                              placeholder=""
                              options={this.state.equipmentSubTypesList}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              About Component Type
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-about-company"
                              rows="8"
                              type="textarea"
                              onChange={this.handleChange("description")}
                            />
                          </FormGroup>

                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i class="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  componentTypeData: _.isEmpty(state.componentTypes.componentTypeData)
    ? null
    : state.componentTypes.componentTypeData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchComponentType: () => dispatch(fetchComponentType()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentTypes);
