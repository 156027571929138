import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  fetchCompanyDetails,
  fetchNotifCount,
  fetchThemeConfig,
  fetchLoginThemeConfig,
} from "../../../redux/actions/companies.action";
import { fetchOrganizationDetails } from "../../../redux/actions/organization.action";
import { fetchMaintenanceTypes } from "../../../redux/actions/feedback.action";
import { fetchNotificationCodes } from "../../../redux/actions/notification.action";
import axios from "../../../axios";
import * as yup from "yup";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import ct from "countries-and-timezones";
import Select from "react-select";
import { Tabs as TabsAnt } from "antd";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ColorPicker from "material-ui-color-picker";
import ImageUploader from "react-images-upload";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { v4 as uuidv4 } from "uuid"; // reactstrap components
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components

import Header from "components/Headers/Header.jsx";
import Tiles from "./Tiles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import validation from "./validations";
import emailValidation from "./emailvalidation";
import Helper from "../../../helper";
import { uniqueId } from "lodash";
// const uuidv4 = require('uuid/v4');
import MomentUtils from "@date-io/moment";

import { countryListAllData } from "../../../../src/countryList";

import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import GraphShimmer from "../../../components/Shimmer/GraphShimmer";
const { TabPane } = TabsAnt;
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

var cards = [
  {
    title: "Company List",
    count: 0,
    icon: "fas fa-users",
    key: "companyList",
    path: "companyList",
  },
];

var cardsWithNotification = [
  {
    title: "Company List",
    count: 0,
    icon: "fas fa-users",
    key: "companyList",
    path: "companyList",
  },
];

var cardsRootAllowed = [
  {
    title: "Company List",
    icon: "fas fa-users",
    key: "companyList",
    path: "companyList",
  },

  {
    title: "Config",
    icon: "fa fa-qrcode",
    key: "config",
    path: "config",
  },
];

let stats = {};
let companyId = "";
let organizationId = "";
let portal_user = "";

const Vibration_Units = [
  { value: "acc", label: "Acceleration (g)" },
  { value: "vel", label: "Velocity (mm/sec)" },
  { value: "dis", label: "Displacement (micron)" },
  { value: "vel-inch", label: "Velocity (in/sec)" },
  { value: "dis-milli", label: "Displacement (milli)" },
];

const Vibration_TimeDomains = [
  { value: "time", label: "Time Domain" },
  { value: "cycle", label: "Order Domain" },
  { value: "envelope", label: "Envelope" },
  { value: "denoised", label: "Denoised Time Domain" },
];

const Vibration_OrderDomains = [
  { value: "time", label: "Frequency Domain" },
  { value: "order", label: "Order Domain" },
  { value: "envelope-order", label: "Envelope Order Domain" },
  { value: "envelope", label: "Envelope Frequency Domain" },
  { value: "cycle", label: "Cycles/minute (CPM)" },
  { value: "denoised", label: "Denoised Frequency Domain" },
];

let countrySelected = [
  {
    value: "",
    label: "",
  },
];

const Vibration_Waterfall_Domains = Vibration_OrderDomains.slice(0, 2);

class OrganizationProfile extends React.Component {
  constructor(props) {
    super(props);

    companyId = this.props.match.params.companyId;
    organizationId = this.props.match.params.organizationId;

    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleSmtpChange = this.handleSmtpChange.bind(this);
  }

  state = {
    orgLogo: false,
    languageList: [
      {
        key: 1,
        label: "English",
        value: "eng",
      },
      {
        key: 0,
        label: "Japanese",
        value: "jap",
      },
    ],
    regionList: [
      {
        key: 0,
        label: "Europe",
        value: "eu",
      },
      {
        key: 1,
        label: "Middle East",
        value: "me",
      },
      {
        key: 2,
        label: "Africa",
        value: "af",
      },
      {
        key: 3,
        label: "North America",
        value: "ne",
      },
      {
        key: 4,
        label: "South America",
        value: "se",
      },
      {
        key: 5,
        label: "India",
        value: "in",
      },
      {
        key: 6,
        label: "Asia",
        value: "as",
      },
      {
        key: 7,
        label: "Australia New Zealand",
        value: "aus",
      },
    ],

    masterUserList: [],
    nanoAiSetting: false,
    emailConfigurationSetting: false,
    disableSmtp: false,
    layout: [
      { value: 0, label: "Equipment" },
      { value: 1, label: "Machine" },
    ],
    company: {
      temperature: "C",
      notificationToBeChecked: true,
      active: true,
    },
    organization: {
      temperature: "C",
      notificationToBeChecked: true,
      active: true,
    },
    organizationConfig: {
      country: null,
      cmPOC: null,
      csPOC: null,
      isClientQuery: true,
      dailyConsolidatedAlarm: {
        active: false,
        directMessageCodes: {},
        includeDirectNotification: false,
        numberOfBatchNotificationPerDay: 0,
        smsNotification: false,
      },
      dashboard_link: "",
      dataExport: {
        active: false,
        key: "",
        url: "",
      },
      defaultLang: {
        key: "defaultLang",
        label: "English",
        langType: "eng",
      },
      demo: false,
      exportDataToPtc: false,
      healthSummaryDashboard: false,
      hyperlink_link: "",
      idleTimeConfig: {
        rangeColor: [
          {
            color: "#7ada2c",
            idleDuration: "0-30",
          },
          {
            color: "#f0c82a",
            idleDuration: "31-60",
          },
          {
            color: "#f57c00",
            idleDuration: "61-90",
          },
          {
            color: "#fa0011",
            idleDuration: ">90",
          },
          {
            color: "#BDBDBD",
            idleDuration: "No Data",
          },
        ],
        type: "lastRunning",
      },
      maintenanceLog: false,
      notificationConfig: {
        batteryNotification: false,
        batteryNotificationThreshold: 2.5,
      },
      notificationToBeChecked: false,
      overallrms: false,
      s3Export: {
        active: false,
        bucket: "",
      },
      secondLang: {
        key: "secondLang",
        label: "English",
        langType: "eng",
      },
      sensorHealthDashboard: false,
      sensorStatsFrequencyInHours: 96,
      sensorStatsLastCheckedTime: 1661799908,
      soundHPF: false,
      thresholdSharing: {
        active: false,
        keyList: [],
      },
      timezone: "Africa/Addis_Ababa",
      unit: "metric",
      isTrainingResource: false,
      trainingLink: "",
      smtpConfig: {
        smtpEmail: null,
        smtpUsername: null,
        smtpPassword: null,
        smtpPort: null,
        smtpServer: null,
        senderId: null,
        senderName: null,
      },
      azureEventhubConfig: {
        clientId: "",
        clientSecret: "",
        eventhubName: "",
        namespace: "",
        pushToEventhub: false,
        tenantId: "",
      },
    },
    currentTabIndex: 0,
    thresholdSharingTypes: [
      {
        key: 0,
        label: "RMS Acceleration",
        value: "rms_acc",
      },
      {
        key: 1,
        label: "RMS Velocity ",
        value: "rms_vel",
      },
      {
        key: 2,
        label: "RMS Displacement",
        value: "rms_dis",
      },
      {
        key: 3,
        label: "Temperature",
        value: "temperature",
      },
      {
        key: 4,
        label: "RMS AE",
        value: "rms_ae",
      },
    ],
    companyBackup: {},
    readOnly: true,
    loading: true,
    saving: false,
    timezone: null,
    defaultTimezone: "",
    configOpen: false,
    themeConfigOpen: false,
    loginThemeConfigOpen: false,
    codesOpen: false,
    open: false,
    frequency: [
      {
        value: 1,
        label: "1 Per Day",
      },
      {
        value: 2,
        label: "2 Per Day",
      },
      {
        value: 4,
        label: "4 Per Day",
      },
      {
        value: 6,
        label: "6 Per Day",
      },
    ],
    selectedFrequency: -1,
    saving: false,
    sensorsUploadFrequency: [],
    sensorsUploadFrequencyError: [],
    createAllowed: false,
    onlyRootAllowed: true,
    notificationCount: 0,
    isDynamicDashboardDetails: false,
    isNameInFooter: false,
    IsHyperlinkActive: true,
    bucket: "",
    isS3Export: false,
    imageUrl: null,
    fileName: null,
    loginBackGroundImageUrl: null,
    loginBackGroundFileName: null,
    loginLogoImageUrl: null,
    loginLogoFileName: null,
    Dashboardtittle: null,
    raiseIssueEmails: null,
    contactUsEmails: null,
    DashboardUrl: null,
    das_backgroundColor: "#000",
    das_textColor: "#000",
    unitTypes: [
      {
        key: 0,
        label: "Metric",
        value: "metric",
      },
      {
        key: 1,
        label: "Imperial",
        value: "imperial",
      },
    ],
    numberNotification: [
      {
        key: 0,
        label: "once a day",
        value: 1,
      },
      {
        key: 1,
        label: "twice a day",
        value: 2,
      },
      {
        key: 2,
        label: "thrice a day",
        value: 3,
      },
    ],
    CDPCount: [
      {
        key: 0,
        label: "One",
        value: 1,
      },
      {
        key: 1,
        label: "Two",
        value: 2,
      },
      {
        key: 2,
        label: "Three",
        value: 3,
      },
      {
        key: 3,
        label: "Four",
        value: 4,
      },
      {
        key: 4,
        label: "Five",
        value: 5,
      },
      {
        key: 5,
        label: "Six",
        value: 6,
      },
      {
        key: 6,
        label: "Seven",
        value: 7,
      },
      {
        key: 7,
        label: "Eight",
        value: 8,
      },
      {
        key: 8,
        label: "Nine",
        value: 9,
      },
      {
        key: 9,
        label: "Ten",
        value: 10,
      },
    ],
    faultStages: [
      {
        key: 0,
        label: "1",
        value: 1,
      },
      {
        key: 1,
        label: "2",
        value: 2,
      },
      {
        key: 2,
        label: "3",
        value: 3,
      },
      {
        key: 3,
        label: "4",
        value: 4,
      },
    ],
    startTime: {
      alarmStartTimeString: moment(
        moment().format("YYYY-MM-DD") + " " + "08:01"
      ),
      alarmStartTime: "08:01",
    },
    statsTemp: null,
    themeConfig: {
      primaryTextColor: "#223141",
      secondaryTextColor: "#3F7C6F",
      bodyTextColor: "#636466",
      primaryBackgroundColor: "#223141",
      secondaryBackgroundColor: "#3F7C6F",
      primaryButtonColor: "#223141",
      secondaryButtonColor: "#3F7C6F",
      tertiaryButtonColor: "#636466",
      linkButtonColor: "#636466",
      primaryButtonTextColor: "#223141",
      secondaryButtonTextColor: "#3F7C6F",
      tertiaryButtonTextColor: "#636466",
      linkButtonTextColor: "#636466",
      primaryButtonOnHoverColor: "#223141",
      secondaryButtonOnHoverColor: "#3F7C6F",
      primaryColor: "#223141",
      secondaryColor: "#3F7C6F",
      tertiaryColor: "#636466",
      primaryGraphColor: "#223141",
      secondaryGraphColor: "#3F7C6F",
    },
    loginThemeConfig: {
      backgroundImageFile: null,
      backgroundImageFileName: null,
      logoImageFile: null,
      logoImageFileName: null,
      domainName: "",
      primaryTextColor: "#223141",
      bodyTextColor: "#636466",
      primaryBackgroundColor: "#223141",
      secondaryBackgroundColor: "#3F7C6F",
      primaryButtonColor: "#223141",
    },
    isFeedbackConfigManual: false,
    maintenanceTypeList: [],
    numberOfFooterRows: [],
    footerOrg: false,
  };

  fetchNotificationCodes = async () => {
    const api = await axios.getData("/notificationCodes/notifcationCategories");

    let notificationCodesTemp = [];
    api.data &&
      api.data.map((x, i) => {
        Object.keys(x.codes).map((y, j) => {
          let obj = {
            key: y,
            appId: x.appId,
            code: x.codes[y],
            apply: false,
            id: i + "_" + j,
          };
          if (y === "code_fault_customer") {
            obj.faultStage = null;
            obj.rul = null;
          }
          notificationCodesTemp.push(obj);
        });
      });

    this.setState({ notificationCodes: api.data, notificationCodesTemp });
  };

  async componentDidMount() {
    await this.getTimezones();
    let apiOrg = await axios.organization_Details(organizationId);

    let masterUser = await axios.fetchMasterUser("master");
    let options = [];
    masterUser &&
      masterUser.data.map((eq, i) => {
        options.push({
          key: i,
          label: `${eq.username}`,
          value: eq,
        });
      });
    this.setState({ masterUserList: options });

    let data = apiOrg.data.data;

    this.setState({
      organization: data,
      organizationBackup: data,
      organizationId: data.organizationId,
      loading: false,
    });

    //Checking Authorization
    let slug = portal_user && portal_user.department.slug;
    // onlyRootAllowed
    if (slug === "root") {
      this.setState({ onlyRootAllowed: true });
    }
    if (["hardware", "admin"].includes(slug)) {
      //Allowing to Change Upload Frequency to hardware group users
      this.setState({ createAllowed: true });
    }
  }

  getThemeConfig = async () => {
    const theme = await axios.getData("/companies/theme/" + companyId);
    if (theme && theme.data && theme.data.data && theme.data.data.theme) {
      delete theme.data.data.theme._id;
      this.setState({ themeConfig: theme.data.data.theme });
    }
  };

  getLoginThemeConfig = async () => {
    const loginTheme = await axios.company_loginTheme(companyId);
    if (
      loginTheme &&
      loginTheme.data &&
      loginTheme.data.data &&
      loginTheme.data.data.theme
    ) {
      delete loginTheme.data.data.theme._id;
      this.setState({
        loginThemeConfig: loginTheme.data.data.theme,
        loginLogoFileName: loginTheme.data.data.theme.logoImageFileName,
        loginBackGroundFileName:
          loginTheme.data.data.theme.backgroundImageFileName,
      });
    }
  };

  setCsPOC = async (item) => {
    if (item === null) return;
    let obj = {
      key: 0,
      label: item,
      value: item,
    };
    this.setState({ selectedCsPOC: obj });
  };

  setCmPOC = async (item) => {
    if (item === null) return;
    let obj = {
      key: 0,
      label: item,
      value: item,
    };
    this.setState({ selectedCmPOC: obj });
  };

  async getCompany() {
    let api = await axios.organizationConfig_details(this.state.organizationId);
    if (
      api &&
      api.data &&
      api.data.data &&
      api.data.data.dynamicDashboardetails &&
      api.data.data.dynamicDashboardetails.fileName
    ) {
      let res = await axios.get_orgLogo(
        api.data.data.dynamicDashboardetails.fileName
      );
      if (res && res.status === 404) {
        this.setState({
          orgLogo: false,
          fileName: "nano_default_logo.png",
          logoFileName: "nano_default_logo.png",
        });
      }
      if (res && res.status === 200) {
        this.setState({
          orgLogo: true,
        });
      }
    }
    if ((api && api.status === 404) || typeof api === "undefined") {
      return toast.warning("Organization config not found");
    }

    let stats = api.data.data;
    let company = api.data.data;
    let organization = api.data.data;
    let timezone = api.data.data.timezone;
    if (timezone) this.setState({ timezone: timezone });
    let logoFileName =
      api.data.data &&
      api.data.data.dynamicDashboardetails &&
      api.data.data.dynamicDashboardetails.fileName;
    let unit = stats.unit;
    let batchNumber =
      stats &&
      stats.dailyConsolidatedAlarm &&
      stats.dailyConsolidatedAlarm.numberOfBatchNotificationPerDay;
    let countNumber = stats.defaultCDPSessions ? stats.defaultCDPSessions : 1;
    if (organization.smtpConfig) {
      let orgConfig = { ...this.state.organizationConfig };
      let smtpData = organization.smtpConfig;
      orgConfig.smtpConfig = smtpData;
      this.setState({
        emailConfigurationSetting: true,
        organizationConfig: orgConfig,
      });
    }
    if (organization.country) {
      let orgConfig = { ...this.state.organizationConfig };
      if (organization.csPOC) {
        orgConfig.csPOC = organization.csPOC;
      }
      if (organization.cmPOC) {
        orgConfig.cmPOC = organization.cmPOC;
      }
      orgConfig.country = organization.country;
      countrySelected = [
        {
          value: organization.country,
          label: organization.country,
        },
      ];
      this.setState({
        organizationConfig: orgConfig,
        selectedRegion: organization.country,
      });
    }
    this.setCsPOC(organization.csPOC || null);
    this.setCmPOC(organization.cmPOC || null);
    // this.getRegion(organization.region || null);

    let index =
      this.state.timezones &&
      this.state.timezones.findIndex((x) => x.value === timezone);
    let unitIndex = this.state.unitTypes.findIndex((x) => x.value === unit);
    let batchIndex = this.state.numberNotification.findIndex(
      (x) => x.value === batchNumber
    );
    let countIndex = this.state.CDPCount.findIndex(
      (x) => x.value === countNumber
    );
    let thresholdKeys = [];

    let numberOfFooterRows =
      company && company.footer
        ? Object.entries(company.footer).map(([key, value]) => ({
            key_footer: key,
            value_footer: value,
          }))
        : [];
    let footerOrg = company && company.footerActive === true ? true : false;
    let isClientQuery =
      company && company.isClientQuery ? company.isClientQuery : false;

    let orgData = { ...this.state.organizationConfig };
    orgData.isClientQuery = isClientQuery;

    stats.thresholdSharing &&
      stats.thresholdSharing.keyList.map((x) => {
        this.state.thresholdSharingTypes.map((y) => {
          if (y.value == x) {
            thresholdKeys.push(y);
          }
        });
      });

    let { notificationCodesTemp } = this.state;
    let directMessageCodes =
      stats.dailyConsolidatedAlarm &&
      stats.dailyConsolidatedAlarm.directMessageCodes;

    let defaultLang = "";
    organization &&
      organization.defaultLang &&
      this.state.languageList &&
      this.state.languageList.map((x) => {
        if (x.value == organization.defaultLang.langType) {
          defaultLang = x.key;
        }
      });

    let secondLang = "";
    organization &&
      organization.secondLang &&
      this.state.languageList &&
      this.state.languageList.map((x) => {
        if (x.value == organization.secondLang.langType) {
          secondLang = x.key;
        }
      });

    let nanoAiFromAPI =
      organization &&
      organization.hasOwnProperty("alarmHandlerConfig") &&
      organization &&
      organization.alarmHandlerConfig.hasOwnProperty("active")
        ? organization.alarmHandlerConfig.active
        : false;

    let startTime =
      stats.dailyConsolidatedAlarm &&
      stats.dailyConsolidatedAlarm.startTime &&
      Object.keys(stats.dailyConsolidatedAlarm.startTime).length !== 0
        ? stats.dailyConsolidatedAlarm.startTime
        : {
            alarmStartTimeString: moment(
              moment().format("YYYY-MM-DD") + " " + "08:01"
            ),
            alarmStartTime: "08:01",
          };

    delete api.data.data._id;
    delete api.data.data.active;
    delete api.data.data.createdAt;
    delete api.data.data.updatedAt;

    this.setState({
      defaultLang: defaultLang,
      secondLang: secondLang,
      notificationCodesTemp: notificationCodesTemp,
      directMessageCodes: directMessageCodes,
      startTime: startTime,
      // company: apiCompnayDetails.data.data,
      // companyBackup: apiCompnayDetails.data.data,
      loading: false,
      timezone: timezone,
      logoFileName: logoFileName,
      defaultTimezone: index,
      dashboard_link: organization.dashboard_link
        ? organization.dashboard_link
        : "",
      hyperlink_link: organization.hyperlink_link
        ? organization.hyperlink_link
        : "",
      isNameInFooter: organization.dynamicDashboardetails
        ? organization.dynamicDashboardetails.isNameInFooter
        : null,
      IsHyperlinkActive: organization.dynamicDashboardetails
        ? organization.dynamicDashboardetails.IsHyperlinkActive
        : null,
      Dashboardtittle: organization.dynamicDashboardetails
        ? organization.dynamicDashboardetails.tittle
        : null,
      raiseIssueEmails: organization.dynamicDashboardetails
        ? organization.dynamicDashboardetails.raiseIssueEmails
        : null,
      contactUsEmails: organization.dynamicDashboardetails
        ? organization.dynamicDashboardetails.contactUsEmails
        : null,
      vibSpecDomainValue:
        organization &&
        organization.config &&
        organization.config.vibSpecDomainValue
          ? organization.config.vibSpecDomainValue
          : null,
      vibSpecRange:
        organization && organization.config && organization.config.vibSpecRange
          ? organization.config.vibSpecRange
          : null,
      DashboardUrl: organization.config
        ? organization.config.dashboardUrl
        : null,
      das_backgroundColor:
        organization.dynamicDashboardetails &&
        organization.dynamicDashboardetails.das_backgroundColor
          ? organization.dynamicDashboardetails.das_backgroundColor
          : "#000",
      das_textColor:
        organization.dynamicDashboardetails &&
        organization.dynamicDashboardetails.das_textColor
          ? organization.dynamicDashboardetails.das_textColor
          : "#000",
      imageUrl:
        organization.dynamicDashboardetails &&
        organization.dynamicDashboardetails.imagesUrl
          ? organization.dynamicDashboardetails.imagesUrl
          : null,
      fileName:
        organization.dynamicDashboardetails &&
        organization.dynamicDashboardetails.fileName
          ? organization.dynamicDashboardetails.fileName
          : null,
          isDynamicDashboardDetails: organization.dynamicDashboardetails
        ? true
        : false,
      selectedUnit: unitIndex,
      selectedKeys: thresholdKeys,
      selectedBatch: batchIndex,
      selectedCount: countIndex,
      organizationConfig: api.data.data,
      backupOrganizationConfig: api.data.data,
      numberOfFooterRows,
      footerOrg,
      nanoAiSetting: nanoAiFromAPI,
    });
    this.setState({ orgData });
    localStorage.setItem("companyId", companyId);
    localStorage.setItem("organizationId", organizationId);
    localStorage.setItem("stats", JSON.stringify(stats));
    localStorage.setItem("company", JSON.stringify(company));
    localStorage.setItem("organization", JSON.stringify(organization));
    // localStorage.setItem("layout", apiCompnayDetails.data.data.Layout);
  }

  getNotificationCount = async () => {
    const api = await axios.getData(
      "/notificationAlertData/count/" + companyId + "/false"
    );
    this.setState({ notificationCount: api.data.data });
  };

  handleTabChange = (index, lastindex, event) => {
    this.setState({
      currentTab: this.state.notificationCodes[index].appId,
      currentTabIndex: index,
    });
  };

  handleAlarmStart = (e) => {
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig["dailyConsolidatedAlarm"] = {
      ...organizationConfig.dailyConsolidatedAlarm,
      startTime: {
        alarmStartTimeString: moment(e._d),
        alarmStartTime: moment(e._d).format("HH:mm"),
      },
    };
    this.setState({
      startTime: {
        alarmStartTimeString: moment(e._d),
        alarmStartTime: moment(e._d).format("HH:mm"),
      },
      organizationConfig,
    });
  };

  handleActive = async (event) => {
    if (window.confirm("Confirm changing company status")) {
      this.setState({ readOnly: false });
      let company = { ...this.state.company };
      company.active = event.target.checked;
      if (company.active === false) {
        company.notificationToBeChecked = false;
      }
      const companyDup = company;
      delete companyDup._id;
      let api = await axios.organizationConfig_update(
        this.state.company.companyId,
        {
          companyName: company.companyName,
          aboutCompany: company.company,
          customName: company.customName,
          headQuarters: company.headQuarters,
          externalId: company.externalId,
          active: company.active,
        }
      );

      if (api.status === 200) {
        toast.success("Organization status set successfully!");
        this.props.history.push("/v1/companies");
        return;
        company._id = api.data.data._id;
        company.active = api.data.data.active;
        this.setState({ company }, () => {
          this.props.fetchCompanyDetails(companyId);
        });
      } else if (api.status === 400) {
        toast.warning(api.data.message);
      } else if (api.status === 500) {
        toast.danger("Something went wrong. Please try again.");
      }
      this.setState({ readOnly: true });
    } else return;
  };

  handleNotificationActive = async (event) => {
    let organizationConfig = { ...this.state.organizationConfig };
    if (window.confirm("Confirm changing Notification status")) {
      organizationConfig.notificationToBeChecked = event.target.checked;
      this.setState({ organizationConfig });
    } else return;
  };

  handleDynamicDashboardDetails = (details) => {
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig["dynamicDashboardetails"] = {
      ...organizationConfig.dynamicDashboardetails,
      isDynamicDashboardDetails: !this.state.isDynamicDashboardDetails,
    };
    this.setState({
      organizationConfig,
      isDynamicDashboardDetails: !this.state.isDynamicDashboardDetails,
    });
  };

  handleisNameInFooter = (details) => {
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig["dynamicDashboardetails"] = {
      ...organizationConfig.dynamicDashboardetails,
      isNameInFooter: !this.state.isNameInFooter,
    };
    this.setState({
      organizationConfig,
      isNameInFooter: !this.state.isNameInFooter,
    });
  };

  handleIsHyperlinkActive = (details) => {
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig["dynamicDashboardetails"] = {
      ...organizationConfig.dynamicDashboardetails,
      IsHyperlinkActive: !this.state.IsHyperlinkActive,
    };
    this.setState({
      organizationConfig,
      IsHyperlinkActive: !this.state.IsHyperlinkActive,
    });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleSoundHPFActive = async (event) => {
    let organizationConfig = { ...this.state.organizationConfig };

    if (organizationConfig.active === false) {
      return;
    }
    if (window.confirm("Confirm changing soundHPF status")) {
      organizationConfig.soundHPF = event.target.checked;
      this.setState({ organizationConfig });
    } else return;
  };

  handleFooterDelete = (index, event) => {
    this.setState((prevState) => {
      const updatedRows = [...prevState.numberOfFooterRows];
      updatedRows.splice(index, 1);
      return { numberOfFooterRows: updatedRows };
    });
    toast.info("Footer deleted. Save the Settings");
  };

  handleOverallRmsActive = async (event) => {
    let organizationConfig = { ...this.state.organizationConfig };

    if (window.confirm("Confirm changing Overall rms status")) {
      organizationConfig.overallrms = event.target.checked;
      this.setState({ organizationConfig });
    } else return;
  };

  handleMenuOptionVisible = async (event) => {
    let organizationConfig = { ...this.state.organizationConfig };

    if (window.confirm("Confirm changing Collapsible Menu status")) {
      organizationConfig.menuOptionVisible = event.target.checked;
      this.setState({ organizationConfig });
    } else return;
  };

  handleNavigationOptionVisible = async (event) => {
    let organizationConfig = { ...this.state.organizationConfig };

    if (window.confirm("Confirm changing Navigation Asset Options status")) {
      organizationConfig.navigationOptionVisible = event.target.checked;
      this.setState({ organizationConfig });
    } else return;
  };

  handleConfigActive = (type, event) => {
    if (type === "nanoAiSetting") {
      this.setState({
        nanoAiSetting: event.target.checked,
      });
    }
    if (type === "emailConfigurationSetting") {
      this.setState({
        emailConfigurationSetting: event.target.checked,
      });
    }
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig[type] = event.target.checked;

    this.setState({
      organizationConfig: organizationConfig,
    });
  };
  handleChildProperty = (type, child, eventVal) => {
    let organizationConfig = { ...this.state.organizationConfig };
    if (organizationConfig[type]) {
      organizationConfig[type][child] = eventVal.target.checked;
      this.setState({
        organizationConfig: organizationConfig,
      });
    }
  };

  handleChildPropertyInput = (type, sub, e) => {
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig[type][sub] = e.target.value;
    this.setState({
      organizationConfig: organizationConfig,
    });
  };

  handleVibChanges = (type, e) => {
    let company = { ...this.state.company };
    company.config[type] = e.target.value;
    this.setState({
      company: company,
    });
  };

  handleMultiSelectChange = (e) => {
    let organizationConfig = { ...this.state.organizationConfig };
    if (e) {
      let keys = e.map((x) => {
        return x.value;
      });
      organizationConfig.thresholdSharing.keyList = [...keys];
      this.setState({
        organizationConfig: organizationConfig,
        selectedKeys: e,
      });
    } else {
      organizationConfig.thresholdSharing.keyList = [];
      this.setState({
        organizationConfig: organizationConfig,
        selectedKeys: e,
      });
    }
  };
  handleChange = (input) => (e) => {
    let organization = { ...this.state.organization };
    organization[input] = e.target.value;
    this.setState({ organization: organization });
  };

  handleChangeLanguage = (input, e) => {
    let organizationConfig = { ...this.state.organizationConfig };
    let setObject = {
      key: input,
      label: e.label,
      langType: e.value,
    };
    organizationConfig[input] = setObject;
    this.setState({ organizationConfig: organizationConfig, [input]: e.key });
  };

  handleRegionChange = (input, e) => {
    let organizationConfig = { ...this.state.organizationConfig };
    switch (true) {
      case input.includes("country"):
        organizationConfig[input] = e.label;
        this.setState({ selectedRegion: e });
        countrySelected = [
          {
            value: e.label,
            label: e.label,
          },
        ];
        break;
      case input.includes("cmPOC"):
        organizationConfig[input] = e.label;
        this.setState({ selectedCmPOC: e });
        break;
      case input.includes("csPOC"):
        organizationConfig[input] = e.label;
        this.setState({ selectedCsPOC: e });
        break;
      default:
        break;
    }
    this.setState({
      organizationConfig: organizationConfig,
    });
  };

  handleEdit(edit) {
    this.setState({ readOnly: edit });
  }

  handleCancel() {
    let companyBackup = { ...this.state.companyBackup };
    this.setState({ company: companyBackup, readOnly: true });
  }

  handleSelectChange(field, type = "filter", option, action) {
    if (action.action === "select-option") {
      if (type === "add") {
        if (field === "timezone") {
          let organizationConfig = { ...this.state.organizationConfig };
          organizationConfig.timezone = option.value;
          this.setState({
            organizationConfig,
            defaultTimezone: option.key,
          });
        }

        if (field === "upload_frequency") {
          let index = this.state.frequency.findIndex(
            (x) => x.value === option.value
          );
          this.setState({ selectedFrequency: index });
        }
      }
    } else if (action.action === "clear") {
      let organizationConfig = { ...this.state.organizationConfig };
      organizationConfig.timezone = "";
      this.setState({ organizationConfig, defaultTimezone: -1 });
    }
  }

  handleUnitChange = (e) => {
    let { organizationConfig } = this.state;
    organizationConfig.unit = e.value;
    this.setState({ selectedUnit: e.key, organizationConfig });
  };
  handleDefaultCDPSessions = (e) => {
    let { organizationConfig } = this.state;
    organizationConfig.defaultCDPSessions = e.value;
    this.setState({ selectedCount: e.key, organizationConfig });
  };

  handleBatchChange = (e) => {
    let { organizationConfig } = this.state;
    if (organizationConfig && organizationConfig.dailyConsolidatedAlarm) {
      organizationConfig.dailyConsolidatedAlarm.numberOfBatchNotificationPerDay =
        e.value;
      this.setState({ selectedBatch: e.key, organizationConfig });
    }
  };
  handleRadioChange(field, e) {
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig[field] = e.target.value;
    this.setState({ organizationConfig });
  }

  async validation() {
    //Defining schema
    // let schema = yup.object().shape({
    // })
  }

  handleKeyChange = (event, index) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const updatedRows = [...prevState.numberOfFooterRows];
      updatedRows[index].key_footer = value;
      return { numberOfFooterRows: updatedRows };
    });
  };
  // This is for Company Config
  handleValueChange = (event, index) => {
    const { value } = event.target;
    this.setState((prevState) => {
      const updatedRows = [...prevState.numberOfFooterRows];
      updatedRows[index].value_footer = value;
      return { numberOfFooterRows: updatedRows };
    });
  };
  handleSmtpChange = (field, e) => {
    let organizationConfig = { ...this.state.organizationConfig };
    let modifiedConfig = { ...organizationConfig["smtpConfig"] };
    modifiedConfig[field] = e.target.value;
    organizationConfig["smtpConfig"] = modifiedConfig;
    this.setState({ organizationConfig });
  };

  handleAzureConfigChange = (field, value) => {
    let organizationConfig = { ...this.state.organizationConfig };
    let modifiedConfig = { ...organizationConfig["azureEventhubConfig"] };
    modifiedConfig[field] = value;
    organizationConfig["azureEventhubConfig"] = modifiedConfig;
    this.setState({ organizationConfig });
  };

  handleFooterActive = (type, event) => {
    if (type === "org") {
      // let organizationConfig = { ...this.state.organizationConfig };
      if (window.confirm("Confirm Changing Footer status")) {
        this.setState({ footerOrg: event.target.checked });
      } else {
        return;
      }
    }
  };

  isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  isValidPort = (port) => {
    const portNumber = Number(port);
    return (
      Number.isInteger(portNumber) && portNumber >= 0 && portNumber <= 65535
    );
  };
  isValidServerId = (serverId) => {
    const serverIdRegex =
      /^(?!:\/\/)(?:(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.?)+[A-Za-z]{2,}$/;
    return serverIdRegex.test(serverId);
  };
  isValidUsername = (name) => {
    const usernameRegex = /^[a-zA-Z0-9-_]{3,20}$/;

    // Test the username against the regular expression
    return usernameRegex.test(name);
  };
  // checkRegionIsEmpty = () => {
  //   if (
  //     typeof this.state.organizationConfig.cmPOC === "undefined" ||
  //     this.state.organizationConfig.cmPOC == null
  //   ) {
  //     return toast.warn("Please enter CM POC in general settings");
  //   }
  //   if (
  //     typeof this.state.organizationConfig.csPOC === "undefined" ||
  //     this.state.organizationConfig.csPOC == null
  //   ) {
  //     return toast.warn("Please enter CS POC in general settings");
  //   }
  //   if (
  //     typeof this.state.organizationConfig.country === "undefined" ||
  //     this.state.organizationConfig.country == null
  //   ) {
  //     return toast.warn("Please enter country in general settings");
  //   }
  //   return true;
  // };
  emailFormValidation = (smtpConfig) => {
    if (typeof smtpConfig === "undefined") {
      this.setState({ disableSmtp: true });
      return toast.warn("Please fill the form");
    }

    for (let prop in smtpConfig) {
      if (!smtpConfig[prop] || typeof smtpConfig[prop] === "undefined") {
        return toast.warn(`Please enter ${prop}`);
      }
    }
    if (!this.isValidEmail(smtpConfig && smtpConfig.smtpEmail)) {
      return toast.warn(`Please enter valid email`);
    }
    if (!this.isValidUsername(smtpConfig && smtpConfig.smtpUsername)) {
      return toast.warn(`Please enter valid username`);
    }
    if (!this.isValidPort(smtpConfig && smtpConfig.smtpPort)) {
      return toast.warn(`Please enter valid port`);
    }
    if (!this.isValidServerId(smtpConfig && smtpConfig.smtpServer)) {
      return toast.warn(`Please enter valid server name`);
    }
    if (!this.isValidEmail(smtpConfig && smtpConfig.senderId)) {
      return toast.warn(`Please enter valid sender id`);
    }
    if (!this.isValidUsername(smtpConfig && smtpConfig.senderName)) {
      return toast.warn(`Please enter valid sender name`);
    }
    return true;
  };

  async getTimezones() {
    let timezones = [];
    const response = Helper.getTimezonOptions();
    response.forEach((z, i) => {
      timezones.push({
        key: i,
        ...z,
      });
    });
    this.setState({
      timezones: timezones,
    });
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.fetchCompanyDetails(companyId);
  };
  handleConfigClose = () => {
    this.setState({
      configOpen: false,
      selectedCmPOC: null,
      selectedCsPOC: null,
      selectedRegion: null,
    });
    countrySelected = [
      {
        value: "",
        label: "",
      },
    ];
  };
  handleThemeConfigClose = () => {
    this.setState({ themeConfigOpen: false });
  };
  handleLoginThemeConfigClose = () => {
    this.setState({ loginThemeConfigOpen: false });
  };
  handleCodesClose = () => {
    this.setState({ codesOpen: false });
  };
  handleUploadRateSubmit = async () => {
    if (this.state.selectedFrequency < 0) {
      toast.warn("Select Upload Frequency");
      return;
    }
    this.setState({ saving: true });

    let endpoint = "/upload-frequency/companies";
    let data = {
      companyId: companyId,
      uploadRate: this.state.frequency[this.state.selectedFrequency].value,
    };

    let api = await axios.postData(endpoint, data);
    if (api.status === 200) {
      toast.success("Operation Successfull");

      let sensorsUploadFrequencyError = api.data.filter((sensor) => {
        return sensor.resStatus != 200;
      });

      this.setState({
        saving: false,
        sensorsUploadFrequency: api.data,
        sensorsUploadFrequencyError,
      });
    } else if (api.status === 400) {
      toast.warn(api.data.error);
      this.setState({ saving: false });
    } else {
      toast.error("Something went wrong. Please try again.");
      this.setState({ saving: false });
    }
    // this.handleClose()
  };

  checkCode = (id, type, e) => {
    let { notificationCodesTemp } = this.state;
    if (type == "check") {
      notificationCodesTemp = notificationCodesTemp.map((x) => {
        if (x.id == id) {
          x.apply = e.target.checked;
        }
        return x;
      });
    } else {
      notificationCodesTemp = notificationCodesTemp.map((x) => {
        if (x.id == id) {
          x[type] = e;
        }
        return x;
      });
    }
    this.setState({ notificationCodesTemp });
  };

  handleThemeConfigSave = async () => {
    this.setState({ saving: true });
    this.state.themeConfig["companyCode"] = this.state.company.companyId;
    let api = await axios.saveCompanyThemeConfig(this.state.themeConfig);
    if (api.status === 200) {
      this.props.fetchThemeConfig(this.state.company.companyId);
      toast.success("Company Theme config saved successfully !");
      this.setState({ themeConfigOpen: false, saving: false });
    } else if (api.status === 400) {
      toast.warning(api.data.message);
      this.setState({ themeConfigOpen: false, saving: false });
    } else if (api.status === 500) {
      toast.danger("Something went wrong. Please try again.");
      this.setState({ themeConfigOpen: false, saving: false });
    }
  };

  handleLoginThemeConfigSave = async () => {
    this.setState({ saving: true });
    this.state.loginThemeConfig["companyCode"] = this.state.company.companyId;
    this.state.loginThemeConfig["backgroundImageFile"] = this.state
      .loginBackGroundImageUrl
      ? this.state.loginBackGroundImageUrl
      : this.state.loginThemeConfig.backgroundImageFile;
    this.state.loginThemeConfig["backgroundImageFileName"] = this.state
      .loginBackGroundFileName
      ? this.state.loginBackGroundFileName
      : this.state.loginThemeConfig.backgroundImageFileName;
    this.state.loginThemeConfig["logoImageFile"] = this.state.loginLogoImageUrl
      ? this.state.loginLogoImageUrl
      : this.state.loginThemeConfig.logoImageFile;
    this.state.loginThemeConfig["logoImageFileName"] = this.state
      .loginLogoFileName
      ? this.state.loginLogoFileName
      : this.state.loginThemeConfig.logoImageFileName;
    let api = await axios.saveCompanyLoginThemeConfig(
      this.state.loginThemeConfig
    );
    if (api.status === 200) {
      this.props.fetchLoginThemeConfig(this.state.company.companyId);
      toast.success("Company Login Theme config saved successfully !");
      this.setState({ loginThemeConfigOpen: false, saving: false });
    } else if (api.status === 400) {
      toast.warning(api.data.message);
      this.setState({ loginThemeConfigOpen: false, saving: false });
    } else if (api.status === 500) {
      toast.danger("Something went wrong. Please try again.");
      this.setState({ loginThemeConfigOpen: false, saving: false });
    }
  };

  handleToggle = async (event) => {
    let organization = { ...this.state.organization };
    organization.view = !organization.view;
    this.setState({ organization });
  };

  handleSave = async () => {
    if (this.state.organization.organizationId) {
      this.setState({ saving: true });
      let organization = {
        ...this.state.organization,
      };

      //Validating
      let err = await validation.validate(this.state.organization);
      if (err) {
        toast.warn(err);
        this.setState({ saving: false });
        return;
      }

      delete organization._id;

      let api = await axios.organization_Update(
        this.state.organization.organizationId,
        {
          customName: organization.customName,
          externalId: organization.externalId,
        }
      );
      this.setState({ saving: false });
      if (api && api.status === 200) {
        toast.success("Organization saved successfully !");
        this.props.fetchOrganizationDetails(organizationId);
        localStorage.removeItem("organizationId");
        this.setState({ readOnly: true, configOpen: false });
      } else if (api && api.status === 400) {
        toast.warning(api.data.message);
      } else if (api && api.status === 500) {
        toast.danger("Something went wrong. Please try again.");
      }
    }
  };

  // handleConfigSave = async () => {
  //   if (this.state.imageUrl !== null) {
  //     if (this.state.imageUrl.includes("data:image/")) {
  //       let imageorganizationConfig = await axios.fileDataLogo_create({
  //         file: this.state.imageUrl,
  //         fileName: `${companyId}-icon.${this.state.fileName.split(".")[1]}`,
  //         companyId: companyId,
  //       });
  //       let organizationConfig = { ...this.state.organizationConfig };
  //       organizationConfig["dynamicDashboardetails"] = {
  //         ...organizationConfig.dynamicDashboardetails,
  //         fileName: `${companyId}-icon.${this.state.fileName.split(".")[1]}`,
  //       };
  //       this.setState({ organizationConfig });
  //     }
  //   }
  //   let updateorganizationConfig = await axios.organizationConfig_allUpdate(
  //     this.state.organizationConfig,
  //     companyId
  //   );
  //   if (updateorganizationConfig.status === 200) {
  //     toast.success("Company config updated succesfully!");
  //     this.setState({ configOpen: false });
  //     return;
  //   }
  // };

  handleSmtpConfigSave = async () => {
    let smtpConfig = { ...this.state.organizationConfig.smtpConfig };
    //Validating
    const result = this.emailFormValidation(
      this.state.organizationConfig.smtpConfig
    );
    if (typeof result === "string") {
      return;
    }

    let respSmtp;
    respSmtp = await axios.put_emailConfigOrg(
      this.state.organization.organizationId,
      {
        smtpConfig: smtpConfig,
      }
    );
    this.setState({ allowUpdate: false });
    if (respSmtp && respSmtp.status === 200) {
      toast.success("Smtp data saved successfully");
      this.setState({ configOpen: false });
    } else {
      toast.warn("Failed to save Smtp data");
    }
  };

  handleConfigSave = async () => {
    let organizationConfig = {};
    organizationConfig = { ...this.state.organizationConfig };
    let resp = null;
    let res = null;

    const { cmPOC, csPOC, country, defaultLang, secondLang } =
      this.state.organizationConfig;
    if (!defaultLang) {
      toast.warning("Please Select a Default language");
      return;
    }
    if (!secondLang) {
      toast.warning("Please Select a Secondary language");
      return;
    }

    if (this.state.raiseIssueEmails && this.state.raiseIssueEmails.length) {
      const raiseIssueEmailsArray = this.state.raiseIssueEmails.split(",");
      let emailsInvalid = false;
      raiseIssueEmailsArray.map((ele) => {
        if (!this.isValidEmail(ele)) {
          emailsInvalid = true;
        }
      });
      if (emailsInvalid) {
        return toast.warn(`Please enter valid raise issue email`);
      }
    }
    if (this.state.contactUsEmails && this.state.contactUsEmails.length) {
      const contactUsEmailsArray = this.state.contactUsEmails.split(",");
      let emailsInvalid = false;
      contactUsEmailsArray.map((ele) => {
        if (!this.isValidEmail(ele)) {
          emailsInvalid = true;
        }
      });
      if (emailsInvalid) {
        return toast.warn(`Please enter valid conatct us email`);
      }
    }

    // if (!cmPOC || !csPOC || !country) {
    //   let res = this.checkRegionIsEmpty();
    //   if (res != true) return;
    // }
    if (this.state.organizationConfig.isTrainingResource) {
      if (!Helper.isValidHttpUrl(this.state.organizationConfig.trainingLink)) {
        return toast.warning("Please enter valid training link");
      }
    }
    if (this.state.emailConfigurationSetting) {
      this.handleSmtpConfigSave();
    }

    if (this.state.imageUrl !== null) {
      if (this.state.imageUrl.includes("data:image/")) {
        let file_name = `${
          this.state.organization && this.state.organization.organizationId
        }.${this.state.fileName.split(".")[1]}`;
        if (this.state.logoFileName) {
          res = await axios.update_orgLogo(file_name, {
            file: this.state.imageUrl,
            fileName: `${
            this.state.organization && this.state.organization.organizationId
          }.${this.state.fileName}`
          });
        } else {
          resp = await axios.add_orgLogo({
            file: this.state.imageUrl,
            fileName: `${
              this.state.organization && this.state.organization.organizationId
            }.${this.state.fileName.split(".")[1]}`,
          });
        }
        organizationConfig["dynamicDashboardetails"] = {
          ...organizationConfig.dynamicDashboardetails,
          fileName: `${
            this.state.organization && this.state.organization.organizationId
          }.${this.state.fileName.split(".")[1]}`,
        };
        delete organizationConfig.maintenanceActionFeedback;
        // this.setState({ maintenanceActionFeedback: null });

        if ((resp && resp.status === 200) || (res && res.status === 200)) {
          this.setState({
            organizationConfig,
            logoFileName: `${
              this.state.organization && this.state.organization.organizationId
            }.${this.state.fileName.split(".")[1]}`,
          });
        }
      }
    }

    let azureEventhubConfig = {
      pushToEventhub:
        (this.state.organizationConfig.azureEventhubConfig &&
          this.state.organizationConfig.azureEventhubConfig.pushToEventhub) ||
        false,
    };
    if (azureEventhubConfig && azureEventhubConfig.pushToEventhub) {
      const { tenantId, namespace, eventhubName, clientSecret, clientId } =
        this.state.organizationConfig.azureEventhubConfig;
      if (!clientId) return toast.warning("Please enter Client ID");
      if (!clientSecret) return toast.warning("Please enter Client Secret");
      if (!eventhubName) return toast.warning("Please enter Eventhub Name");
      if (!namespace) return toast.warning("Please enter Namespace");
      if (!tenantId) return toast.warning("Please enter Tenant ID");
      azureEventhubConfig = {
        ...this.state.organizationConfig.azureEventhubConfig,
      };
    }
    await this.setState((prevState) => ({
      organizationConfig: {
        ...prevState.organizationConfig,
        azureEventhubConfig: azureEventhubConfig,
      },
    }));
    if (
      this.state.organizationConfig &&
      this.state.organizationConfig.maintenanceActionFeedback
    ) {
      let maintenanceActionFeedback = {
        ...this.state.organizationConfig.maintenanceActionFeedback,
      };
      Object.keys(maintenanceActionFeedback).forEach((key) => {
        Object.keys(maintenanceActionFeedback[key]).forEach((innerKey) => {
          if (maintenanceActionFeedback[key]["val"] == null) {
            maintenanceActionFeedback[key]["val"] = 0;
          }
        });
      });
      this.setState({ organizationConfig });
    }
    let isFieldEmpty = false;
    if (this.state.footerOrg === true) {
      const { numberOfFooterRows } = this.state;
      const getElementValueById = (id) =>
        document.getElementById(id) && document.getElementById(id).value;

      // Final Footer store data in Array of Object
      const final_footer = numberOfFooterRows.map((v, i) => {
        const value_footer = getElementValueById(`${i}input-value-footer`);
        const key_footer = getElementValueById(`${i}input-key-footer`);

        if (value_footer === "" || key_footer === "") {
          isFieldEmpty = true;
        }

        return { key_footer, value_footer };
      });
      // Footerff store data in object
      const footerff = final_footer.reduce(
        (acc, { key_footer, value_footer }) => {
          acc[key_footer] = value_footer;
          return acc;
        },
        {}
      );

      if (numberOfFooterRows.length === 0) {
        toast.warn("Either Add Footer or Disable Footer");
        return;
      }

      let numberOfFooterRows1 = { ...this.state.numberOfFooterRows };

      numberOfFooterRows1 = final_footer;
      this.setState({ numberOfFooterRows: numberOfFooterRows1 });
      organizationConfig["footer"] = footerff;
      organizationConfig["footerActive"] = true;

      await this.setState((prevState) => ({
        organizationConfig: {
          ...prevState.organizationConfig,
          footer: footerff,
          footerActive: true,
        },
      }));
    }

    if (!this.state.footerOrg) {
      let organizationConfig = { ...this.state.organizationConfig };
      organizationConfig["footer"] = {};
      organizationConfig["footerActive"] = false;
      this.setState({ organizationConfig, numberOfFooterRows: [] });
      await new Promise((resolve) => {
        this.setState({ organizationConfig }, resolve);
      });
    }
    if (this.state.nanoAiSetting || !this.state.nanoAiSetting) {
      let organizationConfig = { ...this.state.organizationConfig };
      if (this.state.organizationConfig.hasOwnProperty("alarmHandlerConfig")) {
        organizationConfig["alarmHandlerConfig"]["active"] =
          this.state.nanoAiSetting;
        delete organizationConfig.nanoAiSetting;
        this.setState(organizationConfig);
        await new Promise((resolve) => {
          this.setState({ organizationConfig }, resolve);
        });
      } else {
        delete organizationConfig.nanoAiSetting;
        await new Promise((resolve) => {
          this.setState({ organizationConfig }, resolve);
        });
      }
    }

    if (isFieldEmpty) {
      toast.warn("All fields are necessary");
      return; // Stop execution for Footer if any field is empty
    }
    delete this.state.organizationConfig.emailConfigurationSetting;
    if (this.state.disableSmtp) {
      this.setState({ emailConfigurationSetting: false });
    }
    let updateorganizationConfig = await axios.organizationConfig_update(
      organizationId,
      this.state.organizationConfig
    );

    if (this.state.dashboard_link === "") {
      toast.warning("Dashboard link required");
    } else if (this.state.hyperlink_link === "") {
      toast.warning("Hyper link required");
    } else if (
      updateorganizationConfig &&
      updateorganizationConfig.status === 200
    ) {
      toast.success("Organization config updated successfully!");
      this.setState({ configOpen: false });
      return;
    }
  };

  processDirectCodes = async () => {
    let { notificationCodesTemp } = this.state;

    let directMessageCodes = {};
    notificationCodesTemp.map((x) => {
      let obj = {
        [x.code]: {
          condition: null,
        },
      };
      if (x.apply === true) {
        if (x.key === "code_fault_customer") {
          obj[x.code]["condition"] = {
            stage: x.faultStage,
            rul: x.rul,
          };
        }
        directMessageCodes = { ...directMessageCodes, ...obj };
      }
    });
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig.dailyConsolidatedAlarm.directMessageCodes =
      directMessageCodes;
    this.setState({ directMessageCodes, organizationConfig });
    this.handleCodesClose();
  };

  jump =
    () =>
    (url, delay = 0) => {
      if (url.includes("config")) {
        this.setState({ configOpen: true });
        this.getCompany();
      } else if (url.includes("theme")) {
        this.setState({ themeConfigOpen: true });
      } else if (url.includes("loginTheme")) {
        this.setState({ loginThemeConfigOpen: true });
      } else if (url.includes("feedbackConfigManual")) {
        this.setState({ isFeedbackConfigManual: true });
      } else {
        setTimeout(() => {
          this.props.history.push(url);
        }, delay);
      }
    };
  handleColorChanges(color) {
    let organizationConfig = { ...this.state.organizationConfig };
    organizationConfig["dynamicDashboardetails"] = {
      ...organizationConfig.dynamicDashboardetails,
      das_backgroundColor: color,
    };

    this.setState({ organizationConfig, das_backgroundColor: color });
  }

  handleThemeColorChanges(field, color) {
    let tempThemeconfig = this.state.themeConfig;
    tempThemeconfig[field] = color;
    this.setState({ themeConfig: tempThemeconfig });
  }

  handleLoginThemeColorChanges(field, color) {
    this.state.loginThemeConfig[field] = color;
    this.setState({ loginThemeConfig: this.state.loginThemeConfig });
  }

  handleTextColorChanges(color) {
    let organizationConfig = { ...this.state.organizationConfig };

    organizationConfig["dynamicDashboardetails"] = {
      ...organizationConfig.dynamicDashboardetails,
      das_textColor: color,
    };

    this.setState({ organizationConfig, das_textColor: color });
  }

  /**
   * Retrieve Company Config from ID
   * @param id
   * @param defaultValue
   */
  getorganizationConfigValue = (id, defaultValue = null) => {
    const organizationConfig = this.state.organizationConfig;
    if (
      !organizationConfig ||
      typeof id !== "string" ||
      !(id in organizationConfig)
    )
      return defaultValue;

    return organizationConfig[id];
  };

  /**
   * Set Company Config for ID
   * @param id
   * @param value
   */
  handleorganizationConfigChange = (id, value) => {
    if (!id) return;

    let config = this.state.organizationConfig;
    config = { ...config, [id]: value };

    this.setState({
      organizationConfig: config,
    });
  };

  handleOptionChange = async (e, changeEvent) => {
    let obj = this.state.maintenanceTypeList.find(
      (o) => o.val === changeEvent.val
    );

    switch (e.target.value) {
      case "mainTenanceWatch":
        if (
          !obj.maintenanceWatch === false &&
          changeEvent.resetFaultDetection === false
        ) {
          obj.maintenanceWatch = !obj.maintenanceWatch;
          obj.resetFaultDetection = changeEvent.resetFaultDetection;
          obj.noAction = true;
          break;
        }
        obj.maintenanceWatch = !obj.maintenanceWatch;
        obj.resetFaultDetection = changeEvent.resetFaultDetection;
        obj.noAction = false;
        break;
      case "rstFaultDetect":
        if (
          !obj.resetFaultDetection === false &&
          changeEvent.maintenanceWatch === false
        ) {
          obj.maintenanceWatch = changeEvent.maintenanceWatch;
          obj.resetFaultDetection = !obj.resetFaultDetection;
          obj.noAction = true;
          break;
        }
        obj.maintenanceWatch = changeEvent.maintenanceWatch;
        obj.resetFaultDetection = !obj.resetFaultDetection;
        obj.noAction = false;
        break;
      case "noAction":
        obj.maintenanceWatch = false;
        obj.resetFaultDetection = false;
        obj.noAction = true;
        break;
      default:
        break;
    }

    if (window.confirm("Are you Sure you want to change?")) {
      let items = this.state.maintenanceTypeList;
      let foundIndex = items.findIndex((x) => x.val == obj.val);
      items[foundIndex] = obj;
      this.setState({ maintenanceTypeList: items });
      await axios.postData(
        `/feedbackLog/maintenanceTypes/maintenanceTypes/${companyId}`,
        obj
      );
    } else {
      this.setState({ isFeedbackConfigManual: false });
      this.props.fetchMaintenanceTypes(companyId);
      return;
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.readOnly ||
      prevState.configOpen ||
      prevState.loginThemeConfigOpen ||
      prevState.themeConfigOpen
    ) {
      // updateMode name changes here
      return;
    }
    let api = null,
      orgData = null,
      stats = null,
      company = null,
      timezone = null,
      unit = null,
      index = null,
      unitIndex = null,
      thresholdKeys = [],
      notificationCount = 0,
      themeConfigData = null,
      loginThemeConfigData = null,
      loginLogoFileNameData = null,
      loginBackGroundFileNameData = null;
    if (nextProps.notifData !== prevState.notifData) {
      if (nextProps.notifData) {
        notificationCount = nextProps.notifData.data;
      }
    }

    if (
      nextProps.organizationDetailsData !== prevState.organizationDetailsData
    ) {
      if (nextProps.organizationDetailsData) {
        orgData = nextProps.organizationDetailsData;
      }
    }
    if (nextProps.companyDetailsData !== prevState.companyDetailsData) {
      if (nextProps.companyDetailsData) {
        api = nextProps.companyDetailsData;
        stats = api.data;
        company = api.data && api.data.company;
        timezone = api.data && api.data.company && api.data.company.timezone;
        unit =
          stats &&
          stats.company &&
          stats.company.config &&
          stats.company.config.unit;
        unitIndex = prevState.unitTypes.findIndex((x) => x.value === unit);
        thresholdKeys = [];
        stats &&
          stats.company &&
          stats.company.config &&
          stats.company.config.thresholdSharing &&
          stats.company.config.thresholdSharing.keyList.map((x) => {
            prevState.thresholdSharingTypes.map((y) => {
              if (y.value == x) {
                thresholdKeys.push(y);
              }
            });
          });

        localStorage.setItem("companyId", companyId);
        localStorage.setItem("stats", JSON.stringify(stats));

        localStorage.setItem("company", JSON.stringify(company));
      }
    } //endcompanyDetails

    if (nextProps.themeConfigData !== prevState.themeConfigData) {
      if (
        nextProps.themeConfigData &&
        nextProps.themeConfigData.data &&
        nextProps.themeConfigData.data.theme
      ) {
        delete nextProps.themeConfigData.data.theme._id;
        themeConfigData = nextProps.themeConfigData.data.theme;
      }
    }

    if (nextProps.loginThemeConfigData !== prevState.loginThemeConfigData) {
      if (
        nextProps.loginThemeConfigData &&
        nextProps.loginThemeConfigData.data &&
        nextProps.loginThemeConfigData.data.theme
      ) {
        delete nextProps.loginThemeConfigData.data.theme._id;
        loginThemeConfigData = nextProps.loginThemeConfigData.data.theme;
        loginLogoFileNameData =
          nextProps.loginThemeConfigData.data.theme.logoImageFileName;
        loginBackGroundFileNameData =
          nextProps.loginThemeConfigData.data.theme.backgroundImageFileName;
      }
    }
    let maintenanceTypeList = [];
    if (nextProps.maintenanceTypesData !== prevState.maintenanceTypesData) {
      if (
        nextProps.maintenanceTypesData &&
        typeof nextProps.maintenanceTypesData.data !== "string"
      ) {
        maintenanceTypeList = nextProps.maintenanceTypesData.data
          ? nextProps.maintenanceTypesData.data
          : [];
      }
    }
    return {
      company:
        api !== null
          ? api.data.company
          : {
              temperature: "C",
              notificationToBeChecked: true,
              active: true,
              config: {
                unit: "metric",
                dataExport: {
                  active: false,
                  url: "",
                  key: "",
                },
                s3Export: {
                  active: false,
                  bucket: "",
                },
                sensorHealthDashboard: true,
                exportDataToPtc: false,
                energyEfficiency: false,
                soundHPF: true,
                maintenanceLog: true,
                thresholdSharing: {
                  active: false,
                  keyList: [],
                },
              },
            },
      companyBackup: api !== null ? api.data.company : {},
      timezone: api !== null ? timezone : null,
      defaultTimezone: api !== null ? index : 0,
      statsTemp: stats,
      isNameInFooter:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.isNameInFooter
            : false
          : false,
      IsHyperlinkActive:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.IsHyperlinkActive
            : null
          : null,
      Dashboardtittle:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.tittle
            : null
          : null,
      DashboardUrl:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.dashboardUrl
            : null
          : null,
      das_backgroundColor:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.das_backgroundColor
            ? company.dynamicDashboardetails.das_backgroundColor
            : "#000"
          : "#000",
      das_textColor:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.das_textColor
            ? company.dynamicDashboardetails.das_textColor
            : "#000"
          : "#000",
      imageUrl:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.imagesUrl
            ? company.dynamicDashboardetails.imagesUrl
            : null
          : null,
      fileName:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.fileName
            ? company.dynamicDashboardetails.fileName
            : null
          : null,
      isDynamicDashboardDetails:
        api !== null ? (company.dynamicDashboardetails ? true : false) : false,
      selectedUnit: api !== null ? unitIndex : null,
      selectedKeys: api !== null ? thresholdKeys : [],
      notificationCount,
      themeConfigData: themeConfigData,
      themeConfig:
        themeConfigData !== null ? themeConfigData : prevState.themeConfig,
      loginThemeConfigData: loginThemeConfigData,
      loginThemeConfig:
        loginThemeConfigData !== null
          ? loginThemeConfigData
          : prevState.loginThemeConfig,
      loginLogoFileName:
        loginLogoFileNameData !== null
          ? loginLogoFileNameData
          : prevState.loginLogoFileName,
      loginBackGroundFileName:
        loginBackGroundFileNameData !== null
          ? loginBackGroundFileNameData
          : prevState.loginBackGroundFileName,
      maintenanceTypeList: maintenanceTypeList,
    };
  }

  render() {
    cards = cards.map((card, key) => {
      if (this.state.statsTemp !== null) {
        card.count = this.state.statsTemp.company[card.key];
        return card;
      } else {
        return card;
      }
    });
    cardsRootAllowed = cardsRootAllowed.map((cardsRootAllowed, key) => {
      if (this.state.statsTemp !== null) {
        cardsRootAllowed.count =
          cardsRootAllowed.key !== "config"
            ? this.state.statsTemp.company[cardsRootAllowed.key]
            : null;
        return cardsRootAllowed;
      } else {
        return cardsRootAllowed;
      }
    });
    cardsWithNotification = cardsWithNotification.map((card, key) => {
      if (this.state.statsTemp !== null) {
        if (card.key === "notification") {
          card.count = this.state.notificationCount;
        } else {
          card.count = this.state.statsTemp.company[card.key];
        }
        return card;
      } else {
        return card;
      }
    });

    // if (stats['machines'] > 0) {
    //   delete cards[2]
    // }
    let selectedCard =
      this.state.onlyRootAllowed === true
        ? cardsRootAllowed
        : this.state.company.notificationToBeChecked === "false"
        ? cards
        : cardsWithNotification;

    if (this.state.company.Layout === 0) {
      selectedCard = selectedCard.filter((el) => {
        return el.key != "machines";
      });
    }
    return (
      <>
        <ToastContainer position="top-left" />
        {this.state.loading ? (
          <GraphShimmer />
        ) : (
          <>
            {" "}
            <Header />
            <Tiles
              cards={selectedCard}
              jumpPage={this.jump()}
              organizationId={organizationId}
            />
            {/* Page content */}
            <Container className="mt--7" fluid>
              <br></br>

              <Row>
                <Col className="order-xl-1" xl="12">
                  <div
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                    style={{
                      width: "2rem",
                      height: "2rem",
                      cursor: "pointer",
                      marginBottom: "1rem",
                    }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      {this.state.createAllowed ? (
                        <Row className="align-items-center">
                          <Col xs="2">
                            <h3 className="mb-0">Organization Details</h3>
                          </Col>
                          <Col xs="6" class="float-left">
                            <FormControlLabel
                              className="mb-0"
                              control={
                                <IOSSwitch
                                  checked={this.state.organization.active}
                                  onChange={this.handleActive}
                                  name="check"
                                />
                              }
                              label={
                                this.state.organization.active === true
                                  ? "Organization is Active"
                                  : "Organization is not Active"
                              }
                            />{" "}
                          </Col>

                          <Col className="text-right" xs="4">
                            {this.state.readOnly && (
                              <Button
                                color="primary"
                                onClick={() => this.handleEdit(false)}
                                size="sm"
                              >
                                Edit
                              </Button>
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Organization Details</h3>
                          </Col>

                          <Col className="text-right" xs="4">
                            {this.state.readOnly && (
                              <Button
                                color="primary"
                                onClick={() => this.handleEdit(false)}
                                size="sm"
                              >
                                Edit
                              </Button>
                            )}
                          </Col>
                        </Row>
                      )}
                    </CardHeader>
                    <CardBody>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Organization Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.organization.customName}
                                onChange={this.handleChange("customName")}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Organization Code{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                type="text"
                                value={this.state.organization.organizationId}
                                onChange={this.handleChange("organizationId")}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                External ID{" "}
                                <Tooltip
                                  title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                                  placement="right-start"
                                >
                                  <Info
                                    style={{
                                      marginRight: "10px",
                                      cursor: "pointer",
                                      fontSize: "30px",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-last-name"
                                type="text"
                                value={this.state.organization.externalId}
                                onChange={this.handleChange("externalId")}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.organization.view}
                                  name="checkedB"
                                  color="primary"
                                  disabled="true"
                                />
                              }
                              label="View"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            {!this.state.readOnly && (
                              <Button
                                className="right"
                                color="success"
                                onClick={() => this.handleSave()}
                                size="md"
                                disabled={this.state.saving}
                              >
                                {this.state.saving ? (
                                  <i class="fas fa-spinner fa-pulse"></i>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            )}

                            {!this.state.readOnly && !this.state.saving && (
                              <Button
                                className="right cmr-10"
                                color="danger"
                                onClick={() => this.handleCancel()}
                                size="md"
                              >
                                Cancel
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              scroll={"body"}
              open={this.state.configOpen}
              onClose={this.handleConfigClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ textAlign: "center" }}
              >
                Organization Configurations
              </DialogTitle>
              <DialogContent style={{ height: "550px" }}>
                <TabsAnt tabPosition="left" defaultActiveKey="1">
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`General`}
                    key={1}
                  >
                    <Row>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Default Language
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={(e) => {
                            this.handleChangeLanguage("defaultLang", e);
                          }}
                          options={this.state.languageList}
                          value={this.state.organizationConfig.defaultLang}
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Second Language
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={(e) => {
                            this.handleChangeLanguage("secondLang", e);
                          }}
                          options={this.state.languageList}
                          value={this.state.organizationConfig.secondLang}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                          style={{ marginTop: "10px" }}
                        >
                          CM POC
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={(e) => {
                            this.handleRegionChange("cmPOC", e);
                          }}
                          options={this.state.masterUserList}
                          value={this.state.selectedCmPOC}
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                          style={{ marginTop: "10px" }}
                        >
                          CS POC
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={(e) => {
                            this.handleRegionChange("csPOC", e);
                          }}
                          options={this.state.masterUserList}
                          value={this.state.selectedCsPOC}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label
                          htmlFor="input-first-name"
                          style={{ marginTop: "10px" }}
                        >
                          Country
                        </label>
                        <Select
                          key={countryListAllData.code}
                          className="form-control-alternative"
                          onChange={(e) => {
                            this.handleRegionChange("country", e);
                          }}
                          options={countryListAllData}
                          value={countrySelected}
                        />
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 550,
                    }}
                    tab={`Dashboard Link`}
                    key={15}
                  >
                    <Row>
                      <Col span={12}>
                        <Label>Dashboard Link</Label>
                        <Input
                          className="form-control-alternative"
                          id="input-dashboard-link"
                          value={this.state.dashboard_link}
                          onChange={(e) => {
                            let organizationConfig = {
                              ...this.state.organizationConfig,
                            };
                            organizationConfig["dashboard_link"] =
                              e.target.value;
                            this.setState({
                              dashboard_link: e.target.value,
                              organizationConfig,
                            });
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Hyper Link</Label>
                        <Input
                          className="form-control-alternative"
                          id="input-dashboard-hyper-link"
                          value={this.state.hyperlink_link}
                          onChange={(e) => {
                            let organizationConfig = {
                              ...this.state.organizationConfig,
                            };
                            organizationConfig["hyperlink_link"] =
                              e.target.value;
                            this.setState({
                              hyperlink_link: e.target.value,
                              organizationConfig,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 550,
                    }}
                    tab={`Sensor Stats Frequency`}
                    key={22}
                  >
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Sensor Stats email Frequency (in hours){" "}
                          <Tooltip
                            title="Send notification if the data is not being received for this amount of time from the sensor. Default value is 4 days or 96 hours"
                            placement="right-start"
                          >
                            <Info
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                                fontSize: "30px",
                              }}
                            />
                          </Tooltip>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-last-name"
                          type="number"
                          value={
                            this.state.organizationConfig
                              .sensorStatsFrequencyInHours
                          }
                          onChange={(e) => {
                            let organizationConfig = {
                              ...this.state.organizationConfig,
                            };
                            organizationConfig["sensorStatsFrequencyInHours"] =
                              parseInt(e.target.value);
                            this.setState({
                              organizationConfig,
                            });
                          }}

                          // disabled={this.state.readOnly}
                        />
                      </FormGroup>
                    </Col>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 550,
                    }}
                    tab={`Notification`}
                    key={2}
                  >
                    {" "}
                    {/* Feature commented NDS-4150 */}
                    {/* <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .dailyConsolidatedAlarm &&
                                this.state.organizationConfig
                                  .dailyConsolidatedAlarm.active
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dailyConsolidatedAlarm",
                                  "active",
                                  e
                                );
                              }}
                              name="notification"
                            />
                          }
                          label={
                            this.state.organizationConfig
                              .dailyConsolidatedAlarm &&
                            this.state.organizationConfig.dailyConsolidatedAlarm
                              .active === true
                              ? "Daily Alarm Notification is Enabled"
                              : "Daily Alarm Notification is disabled"
                          }
                        />{" "}
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Number of notification per day
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={this.handleBatchChange}
                          options={this.state.numberNotification}
                          value={
                            this.state.numberNotification[
                              this.state.selectedBatch
                            ]
                          }
                          isDisabled={
                            this.state.organizationConfig &&
                            this.state.organizationConfig
                              .dailyConsolidatedAlarm &&
                            !this.state.organizationConfig
                              .dailyConsolidatedAlarm.active
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig &&
                                this.state.organizationConfig
                                  .dailyConsolidatedAlarm &&
                                this.state.organizationConfig
                                  .dailyConsolidatedAlarm
                                  .includeDirectNotification
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dailyConsolidatedAlarm",
                                  "includeDirectNotification",
                                  e
                                );
                              }}
                              name="notification"
                            />
                          }
                          label={
                            "Include Direct Notifications in Daily Summary Email"
                          }
                        />{" "}
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Start Time To Send Notification
                          <span className="text-danger">*</span>
                        </label>
                        <div>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              disabled={
                                !this.state.organizationConfig
                                  .dailyConsolidatedAlarm.active
                              }
                              value={this.state.startTime.alarmStartTimeString}
                              onChange={this.handleAlarmStart}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .dailyConsolidatedAlarm.smsNotification
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dailyConsolidatedAlarm",
                                  "smsNotification",
                                  e
                                );
                              }}
                              name="smsNotification"
                            />
                          }
                          label={
                            "Include sms notification for Daily Summary Notification"
                          }
                        />{" "}
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <Button
                          onClick={() => {
                            this.setState({
                              codesOpen: true,
                              currentTabIndex: 0,
                            });
                          }}
                          color="info"
                          disabled={
                            !this.state.organizationConfig
                              .dailyConsolidatedAlarm.active
                          }
                        >
                          Select Direct Codes
                        </Button>
                      </Col>
                      {this.state.directMessageCodes &&
                        Object.keys(this.state.directMessageCodes).length >
                          0 && (
                          <Col lg="12">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Direct codes (sent to customer directly)
                              <span className="text-danger">*</span>
                            </label>
                          </Col>
                        )}
                    </Row>
                    {this.state.directMessageCodes &&
                      Object.keys(this.state.directMessageCodes).map((key, i) =>
                        this.state.directMessageCodes[key]["condition"] ? (
                          <Row key={key}>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Code <span className="text-danger">*</span>
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={key}
                                disabled={true}
                              />
                            </Col>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Fault Stage
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={
                                  this.state.directMessageCodes[key][
                                    "condition"
                                  ]["stage"]
                                }
                                disabled={true}
                              />
                            </Col>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                RUL
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={
                                  this.state.directMessageCodes[key][
                                    "condition"
                                  ]["rul"]
                                }
                                disabled={true}
                              />
                            </Col>
                            {/* 
                    <Col xs={3} lg={3}>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        {' '}
                      </label>
                      <div>
                        <Button
                          color="info"
                          style={{ height: '40px' }}
                          onClick={() => {
                            this.deleteCode(key);
                          }}
                          disabled={
                            !this.state.company.config.dailyConsolidatedAlarm
                              .active
                          }
                        >
                          <MaterialIcon icon="delete" color="#ff0000" />
                        </Button>
                      </div>
                    </Col> */}
                          </Row>
                        ) : (
                          <Row key={key}>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Code <span className="text-danger">*</span>
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={key}
                                disabled={true}
                              />
                            </Col>
                            {/* <Col xs={3} lg={3}>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        {' '}
                      </label>
                      <div>
                        <Button
                          color="info"
                          style={{ height: '40px' }}
                          onClick={() => {
                            this.deleteCode(key);
                          }}
                          disabled={
                            !this.state.company.config.dailyConsolidatedAlarm
                              .active
                          }
                        >
                          <MaterialIcon icon="delete" color="#ff0000" />
                        </Button>
                      </div>
                    </Col> */}
                          </Row>
                        )
                      )}
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Health Summary`}
                    key={3}
                  >
                    {" "}
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .healthSummaryDashboard
                              }
                              onChange={(e) => {
                                this.handleConfigActive(
                                  "healthSummaryDashboard",
                                  e
                                );
                              }}
                              name="Sensor Health Dashboard"
                            />
                          }
                          label={
                            this.state.organizationConfig
                              .healthSummaryDashboard === true
                              ? "Health Summary Dashboard is Enabled"
                              : "Health Summary Dashboard is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  {/* <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Chat Support`}
                    key={4}
                  >
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig &&
                                this.state.organizationConfig.chatlio
                              }
                              onChange={(e) => {
                                this.handleConfigActive("chatlio", e);
                              }}
                              name="chatlio"
                            />
                          }
                          label={
                            this.state.organizationConfig &&
                            this.state.organizationConfig.chatlio === true
                              ? "Chat Support is Enabled"
                              : "Chat Support is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane> */}

                  <TabPane
                    style={{
                      height: 300,
                    }}
                    tab={`Ticket Feature`}
                    key={17}
                  >
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                typeof this.state.organizationConfig
                                  .isClientQuery === "undefined"
                                  ? true
                                  : this.state.organizationConfig.isClientQuery
                              }
                              onChange={(e) => {
                                this.handleConfigActive("isClientQuery", e);
                              }}
                              name="isClientQuery"
                            />
                          }
                          label={
                            typeof this.state.organizationConfig
                              .isClientQuery === "undefined"
                              ? "Ticket is Enabled"
                              : this.state.organizationConfig.isClientQuery ===
                                true
                              ? "Ticket is Enabled"
                              : "Ticket is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Sound`}
                    key={5}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .notificationToBeChecked
                              }
                              onChange={this.handleNotificationActive}
                              name="notification"
                            />
                          }
                          label={
                            this.state.organizationConfig
                              .notificationToBeChecked === true
                              ? "Notification Screening is Enabled"
                              : "Notification Screening is disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.organizationConfig.soundHPF}
                              onChange={this.handleSoundHPFActive}
                              name="soundHPF"
                            />
                          }
                          label={
                            this.state.organizationConfig.soundHPF === true
                              ? "SoundHPF is Enabled"
                              : "SoundHPF is disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Config`}
                    key={6}
                  >
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.organizationConfig.overallrms}
                              onChange={this.handleOverallRmsActive}
                              name="overallrms"
                            />
                          }
                          label={
                            this.state.organizationConfig.overallrms === true
                              ? "Overall RMS is Enabled"
                              : "Overall RMS is Disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .sensorHealthDashboard
                              }
                              onChange={(e) => {
                                this.handleConfigActive(
                                  "sensorHealthDashboard",
                                  e
                                );
                              }}
                              name="Sensor Health Dashboard"
                            />
                          }
                          label={
                            this.state.organizationConfig
                              .sensorHealthDashboard === true
                              ? "Sensor Health Dashboard is Enabled"
                              : "SensorHealth Dashboard is Disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig.energyEfficiency
                              }
                              onChange={(e) => {
                                this.handleConfigActive("energyEfficiency", e);
                              }}
                              name="Energy Efficiency"
                            />
                          }
                          label={
                            this.state.organizationConfig.energyEfficiency ===
                            true
                              ? "Energy Efficiency is Enabled"
                              : "Energy Efficiency is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig.menuOptionVisible
                              }
                              onChange={this.handleMenuOptionVisible}
                              name="Navigation Asset Options"
                            />
                          }
                          label={
                            this.state.organizationConfig.menuOptionVisible ===
                            true
                              ? "Collapsible Menu is Enabled"
                              : "Collapsible Menu is Disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .navigationOptionVisible
                              }
                              onChange={this.handleNavigationOptionVisible}
                              name="Navigation Asset Options"
                            />
                          }
                          label={
                            this.state.organizationConfig
                              .navigationOptionVisible === true
                              ? "Navigation Asset Options is Enabled"
                              : "Navigation Asset Options is Disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right"></Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Export Data`}
                    key={7}
                  >
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig.exportDataToPtc
                              }
                              onChange={(e) => {
                                this.handleConfigActive("exportDataToPtc", e);
                              }}
                              name="Export Data to PTC"
                            />
                          }
                          label={
                            this.state.organizationConfig.exportDataToPtc ===
                            true
                              ? "Export Data to PTC is Enabled"
                              : "Export Data to PTC is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 500,
                    }}
                    tab={`CDP Session`}
                    key={8}
                  >
                    <Row>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Number of default CDP Session
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={this.handleDefaultCDPSessions}
                          options={this.state.CDPCount}
                          value={this.state.CDPCount[this.state.selectedCount]}
                        />
                      </Col>
                      <Col></Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Training`}
                    key={20}
                  >
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig &&
                                this.state.organizationConfig.isTrainingResource
                              }
                              onChange={(e) => {
                                this.handleConfigActive(
                                  "isTrainingResource",
                                  e
                                );
                              }}
                              name="training"
                            />
                          }
                          label={
                            this.state.organizationConfig &&
                            this.state.organizationConfig.isTrainingResource ===
                              true
                              ? "Training is Enabled"
                              : "Training is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>

                    {this.state.organizationConfig.isTrainingResource ===
                      true && (
                      <Row>
                        <Col span={12}>
                          <Label>Training Link</Label>
                          <Input
                            className="form-control-alternative"
                            id="input-dashboard-link"
                            value={this.state.organizationConfig.trainingLink}
                            onChange={(e) => {
                              let organizationConfig = {
                                ...this.state.organizationConfig,
                              };
                              organizationConfig["trainingLink"] =
                                e.target.value;
                              this.setState({
                                dashboard_link: e.target.value,
                                organizationConfig,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    )}
                  </TabPane>

                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Maintaince`}
                    key={9}
                  >
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig.maintenanceLog
                              }
                              onChange={(e) => {
                                this.handleConfigActive("maintenanceLog", e);
                              }}
                              name="Maintenance Log"
                            />
                          }
                          label={
                            this.state.organizationConfig.maintenanceLog ===
                            true
                              ? "Maintenance Log is Enabled"
                              : "Maintenance Log is Disabled"
                          }
                        />{" "}
                      </Col>

                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                            style={{ fontWeight: "bold" }}
                          >
                            Unit{" "}
                          </label>
                          {this.state.unitTypes && (
                            <Select
                              className="form-control-alternative"
                              onChange={this.handleUnitChange}
                              placeholder="Select UnitType"
                              options={this.state.unitTypes}
                              value={
                                this.state.unitTypes[this.state.selectedUnit]
                              }
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 600,
                    }}
                    tab={`Vibration`}
                    key={10}
                  >
                    <Row>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-RMS Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_rms_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue("vib_rms_unit")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Peak Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_peak_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue("vib_peak_unit")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Spectrum Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_spectrum_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue(
                                "vib_spectrum_unit"
                              )
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Spectrum Domain
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_spectrum_domain",
                              value
                            );
                          }}
                          options={Vibration_OrderDomains}
                          value={Vibration_OrderDomains.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue(
                                "vib_spectrum_domain"
                              )
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waterfall Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_waterfall_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue(
                                "vib_waterfall_unit"
                              )
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waterfall Domain
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_waterfall_domain",
                              value
                            );
                          }}
                          options={Vibration_Waterfall_Domains}
                          value={Vibration_Waterfall_Domains.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue(
                                "vib_waterfall_domain"
                              )
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waveform Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_waveform_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue(
                                "vib_waveform_unit"
                              )
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waveform Domain
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleorganizationConfigChange(
                              "vib_waveform_domain",
                              value
                            );
                          }}
                          options={Vibration_TimeDomains}
                          value={Vibration_TimeDomains.find(
                            ({ value }) =>
                              value ===
                              this.getorganizationConfigValue(
                                "vib_waveform_domain"
                              )
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Threshold`}
                    key={11}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .thresholdSharing &&
                                this.state.organizationConfig.thresholdSharing
                                  .active
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "thresholdSharing",
                                  "active",
                                  e
                                );
                              }}
                              name="Active"
                            />
                          }
                          label={
                            this.state.organizationConfig.thresholdSharing
                              .active === true
                              ? "ThresholdSharing is Active"
                              : "ThresholdSharing is Inactive"
                          }
                        />{" "}
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Keys
                            <span style={{ color: "orange", fontSize: "12px" }}>
                              (
                              {this.state.organizationConfig.thresholdSharing &&
                              this.state.organizationConfig.thresholdSharing
                                .active
                                ? "select atleast one of the keys"
                                : "set Threshold Sharing active"}
                              )
                            </span>
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            closeMenuOnSelect={false}
                            isClearable={true}
                            isMulti
                            options={this.state.thresholdSharingTypes}
                            value={this.state.selectedKeys}
                            onChange={this.handleMultiSelectChange}
                            isDisabled={
                              !this.state.organizationConfig.thresholdSharing
                                .active
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Integration`}
                    key={12}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig.dataExport &&
                                this.state.organizationConfig.dataExport.active
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dataExport",
                                  "active",
                                  e
                                );
                              }}
                              name="Relayr Export"
                            />
                          }
                          label={
                            this.state.organizationConfig.dataExport &&
                            this.state.organizationConfig.dataExport.active ===
                              true
                              ? "Relayr Export is Active"
                              : "Relayr Export is Inactive"
                          }
                        />{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            URL
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.organizationConfig.dataExport.url}
                            onChange={(e) =>
                              this.handleChildPropertyInput(
                                "dataExport",
                                "url",
                                e
                              )
                            }
                            disabled={
                              this.state.organizationConfig.dataExport &&
                              !this.state.organizationConfig.dataExport.active
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            key
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.organizationConfig.dataExport.key}
                            onChange={(e) =>
                              this.handleChildPropertyInput(
                                "dataExport",
                                "key",
                                e
                              )
                            }
                            disabled={
                              this.state.organizationConfig.dataExport &&
                              !this.state.organizationConfig.dataExport.active
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* Azure config setting */}
                    <Row className="mt-3">
                      <Col span={12}>
                        <FormControlLabel
                          className="form-control-label"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.organizationConfig
                                  .azureEventhubConfig &&
                                this.state.organizationConfig
                                  .azureEventhubConfig.pushToEventhub
                              }
                              onChange={(e) => {
                                this.handleAzureConfigChange(
                                  "pushToEventhub",
                                  e.target.checked
                                );
                              }}
                              name="pushToEventhub"
                            />
                          }
                          label={
                            this.state.nanoAiSetting === true
                              ? "pushToEventhub is Enabled"
                              : "pushToEventhub is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                    {this.state.organizationConfig.azureEventhubConfig &&
                      this.state.organizationConfig.azureEventhubConfig
                        .pushToEventhub && (
                        <>
                          <Row className="mb-4">
                            <Col span={12}>
                              <Label className="form-control-label">
                                Client ID <span className="text-danger">*</span>
                              </Label>
                              <Input
                                className="form-control-alternative"
                                id="input-azure-clientId"
                                value={
                                  this.state.organizationConfig
                                    .azureEventhubConfig &&
                                  this.state.organizationConfig
                                    .azureEventhubConfig.clientId
                                }
                                onChange={(e) => {
                                  this.handleAzureConfigChange(
                                    "clientId",
                                    e.target.value
                                  );
                                }}
                              />
                            </Col>
                            <Col span={12}>
                              <Label className="form-control-label">
                                Client Secret{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                className="form-control-alternative"
                                id="input-azure-clientSecret"
                                value={
                                  this.state.organizationConfig
                                    .azureEventhubConfig &&
                                  this.state.organizationConfig
                                    .azureEventhubConfig.clientSecret
                                }
                                onChange={(e) => {
                                  this.handleAzureConfigChange(
                                    "clientSecret",
                                    e.target.value
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col span={12}>
                              <Label className="form-control-label">
                                Eventhub Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                className="form-control-alternative"
                                id="input-azure-eventhubName"
                                value={
                                  this.state.organizationConfig
                                    .azureEventhubConfig &&
                                  this.state.organizationConfig
                                    .azureEventhubConfig.eventhubName
                                }
                                onChange={(e) => {
                                  this.handleAzureConfigChange(
                                    "eventhubName",
                                    e.target.value
                                  );
                                }}
                              />
                            </Col>
                            <Col span={12}>
                              <Label className="form-control-label">
                                Namespace <span className="text-danger">*</span>
                              </Label>
                              <Input
                                className="form-control-alternative"
                                id="input-azure-namespace"
                                value={
                                  this.state.organizationConfig
                                    .azureEventhubConfig &&
                                  this.state.organizationConfig
                                    .azureEventhubConfig.namespace
                                }
                                onChange={(e) => {
                                  this.handleAzureConfigChange(
                                    "namespace",
                                    e.target.value
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-4">
                            <Col span={12}>
                              <Label className="form-control-label">
                                Tenant ID <span className="text-danger">*</span>
                              </Label>
                              <Input
                                className="form-control-alternative"
                                id="input-azure-tenantId"
                                value={
                                  this.state.organizationConfig
                                    .azureEventhubConfig &&
                                  this.state.organizationConfig
                                    .azureEventhubConfig.tenantId
                                }
                                onChange={(e) => {
                                  this.handleAzureConfigChange(
                                    "tenantId",
                                    e.target.value
                                  );
                                }}
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                        </>
                      )}
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Dynamic Dashboard`}
                    key={13}
                  >
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.isDynamicDashboardDetails}
                              onChange={this.handleDynamicDashboardDetails}
                              name="isDynamicDashboardDetails"
                            />
                          }
                          label={"Dynamic Dashboard?"}
                        />{" "}
                      </Col>
                      <Col class="float-left">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Upload Image
                          </label>
                          <input
                            type="file"
                            ref={(input) => {
                              this.filesInput = input;
                            }}
                            accept={[".jpg", ".jpeg", ".png"]}
                            name="file"
                            disabled={!this.state.isDynamicDashboardDetails}
                            placeholder={this.state.fileName}
                            onChange={async (event) => {
                              if (
                                event.target.files[0] &&
                                event.target.files[0].size > 2000000
                              ) {
                                alert("File Size should be less than 2MB");
                                return;
                              }

                              const fileData = event.target.files[0];
                              const name = event.target.files[0].name;
                              const base64 = await this.convertBase64(fileData);
                              this.setState({
                                imageUrl: base64,
                                fileName: name,
                                fileData: fileData,
                              });
                            }}
                          />
                          {this.state.fileName ? (
                            <span>
                              Uploaded Image -{" "}
                              {this.state.logoFileName
                                ? this.state.logoFileName
                                : this.state.fileName}
                            </span>
                          ) : null}

                          {this.state.fileName || this.state.logoFileName ? (
                            <>
                            {this.state.orgLogo ? (
                              <Col class="float right">
                              <MaterialIcon
                                onClick={async () => {
                                  if (
                                    window.confirm(
                                      "Do you really want to delete it?"
                                    )
                                  ) {
                                    //apis
                                    let resp = await axios.delete_orgLogo(
                                      this.state.fileName
                                        ? this.state.fileName
                                        : this.state.logoFileName
                                    );
                                    if (resp.status === 200) {
                                      toast.success(
                                        "Logo deleted successfully!"
                                      );
                                      let organizationConfig = {};
                                      organizationConfig = {
                                        ...this.state.organizationConfig,
                                      };
                                      organizationConfig[
                                        "dynamicDashboardetails"
                                      ] = {
                                        ...organizationConfig.dynamicDashboardetails,
                                        fileName: "nano_default_logo.png",
                                      };
                                      delete organizationConfig.maintenanceActionFeedback;
                                      await axios.organizationConfig_update(
                                        organizationId,
                                        organizationConfig
                                      );
                                      this.setState({
                                        fileName: "",
                                        logoFileName: "",
                                        orgLogo: false,
                                      });
                                    }
                                    await axios.organizationConfig_details(
                                      this.state.organizationId
                                    );
                                  }
                                  return;
                                }}
                                icon="delete"
                                color="red"
                              ></MaterialIcon>
                            </Col>
                            ) : null}
                            </>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.isNameInFooter}
                              onChange={this.handleisNameInFooter}
                              name="isDynamicDashboardDetails"
                            />
                          }
                          label={"Is Company Name in footer?"}
                        />{" "}
                      </Col>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.IsHyperlinkActive}
                              onChange={this.handleIsHyperlinkActive}
                              name="isDynamicDashboardDetails"
                            />
                          }
                          label={"Is Hyperlink Active?"}
                        />{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Title Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.Dashboardtittle}
                            onChange={(e) => {
                              let organizationConfig = {
                                ...this.state.organizationConfig,
                              };
                              organizationConfig["dynamicDashboardetails"] = {
                                ...organizationConfig.dynamicDashboardetails,
                                Dashboardtittle: e.target.value,
                              };
                              this.setState({
                                Dashboardtittle: e.target.value,
                                organizationConfig,
                              });
                            }}
                            disabled={!this.state.isDynamicDashboardDetails}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Company Url
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.DashboardUrl}
                            onChange={(e) => {
                              let organizationConfig = {
                                ...this.state.organizationConfig,
                              };
                              organizationConfig["dynamicDashboardetails"] = {
                                ...organizationConfig.dynamicDashboardetails,
                                DashboardUrl: e.target.value,
                              };
                              this.setState({
                                DashboardUrl: e.target.value,
                                organizationConfig,
                              });
                            }}
                            disabled={!this.state.isDynamicDashboardDetails}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Background Color
                          </label>
                          <ColorPicker
                            name="color"
                            defaultValue={this.state.das_backgroundColor}
                            value={this.state.das_backgroundColor} //- for controlled component
                            onChange={(color) => this.handleColorChanges(color)}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Text Color
                          </label>
                          <ColorPicker
                            name="color"
                            defaultValue={this.state.das_textColor}
                            value={this.state.das_textColor} //- for controlled component
                            onChange={(color) =>
                              this.handleTextColorChanges(color)
                            }
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Raise Issue Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.raiseIssueEmails}
                            onChange={(e) => {
                              let organizationConfig = {
                                ...this.state.organizationConfig,
                              };
                              organizationConfig["dynamicDashboardetails"] = {
                                ...organizationConfig.dynamicDashboardetails,
                                raiseIssueEmails: e.target.value,
                              };
                              this.setState({
                                raiseIssueEmails: e.target.value,
                                organizationConfig,
                              });
                            }}
                            disabled={!this.state.isDynamicDashboardDetails}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Contact Us Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.contactUsEmails}
                            onChange={(e) => {
                              let organizationConfig = {
                                ...this.state.organizationConfig,
                              };
                              organizationConfig["dynamicDashboardetails"] = {
                                ...organizationConfig.dynamicDashboardetails,
                                contactUsEmails: e.target.value,
                              };
                              this.setState({
                                contactUsEmails: e.target.value,
                                organizationConfig,
                              });
                            }}
                            disabled={!this.state.isDynamicDashboardDetails}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 500,
                    }}
                    tab={`Timezone`}
                    key={14}
                  >
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                            style={{ fontWeight: "bold" }}
                          >
                            Timezone{" "}
                            <span className="text-danger">
                              * (This will effect the Date & Time parameter in
                              the notifications)
                            </span>
                          </label>
                          {this.state.timezones && (
                            <Select
                              className="form-control-alternative"
                              isClearable={true}
                              onChange={this.handleSelectChange.bind(
                                this,
                                "timezone",
                                "add"
                              )}
                              placeholder=""
                              options={this.state.timezones}
                              value={
                                this.state.timezones[this.state.defaultTimezone]
                              }
                              // isDisabled={this.state.readOnly}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                            style={{ fontWeight: "bold" }}
                          >
                            Temperature Unit{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <br></br>
                          {
                            <FormGroup check>
                              <Label
                                style={
                                  this.state.organizationConfig.temperature ===
                                  "C"
                                    ? { color: "green" }
                                    : {}
                                }
                                className="inline"
                                check
                              >
                                <Input
                                  type="radio"
                                  name="temperature"
                                  value="C"
                                  onChange={this.handleRadioChange.bind(
                                    this,
                                    "temperature"
                                  )}
                                  // disabled={this.state.readOnly}
                                  checked={
                                    this.state.organizationConfig.temperature ==
                                    "C"
                                      ? true
                                      : false
                                  }
                                />
                                Celsius
                              </Label>

                              <Label
                                style={
                                  this.state.organizationConfig.temperature ===
                                  "F"
                                    ? { color: "green" }
                                    : {}
                                }
                                className="inline"
                                check
                              >
                                <Input
                                  type="radio"
                                  name="temperature"
                                  value="F"
                                  onChange={this.handleRadioChange.bind(
                                    this,
                                    "temperature"
                                  )}
                                  // disabled={this.state.readOnly}
                                  checked={
                                    this.state.organizationConfig.temperature ==
                                    "F"
                                      ? true
                                      : false
                                  }
                                />
                                Fahrenheit
                              </Label>
                            </FormGroup>
                          }
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 500,
                    }}
                    tab={`Footer`}
                    key={16}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.footerOrg}
                              onChange={(e) => {
                                this.handleFooterActive("org", e);
                              }}
                              name="footer"
                            />
                          }
                          label={
                            this.state.footerOrg === true
                              ? "Footer is Enabled"
                              : "Footer is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                    {this.state.footerOrg === true &&
                      this.state.numberOfFooterRows.map((v, i) => {
                        return (
                          <Row>
                            <Col span={12}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Footer Key{" "}
                                <span className="text-danger">
                                  <Input
                                    style={{
                                      height: "40px",
                                      width: "450px",
                                      marginRight: "20px",
                                    }}
                                    className="form-control-alternative"
                                    id={i + "input-key-footer"}
                                    type="text"
                                    value={
                                      // this.state.companyConfig.footer[i].key_footer
                                      this.state.numberOfFooterRows[i]
                                        .key_footer
                                    }
                                    onChange={(event) =>
                                      this.handleKeyChange(event, i)
                                    }
                                  />
                                </span>
                              </label>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Footer Value{" "}
                                <span className="text-danger">
                                  <Input
                                    style={{
                                      height: "40px",
                                      width: "450px",
                                      marginRight: "20px",
                                    }}
                                    className="form-control-alternative"
                                    id={i + "input-value-footer"}
                                    type="text"
                                    value={
                                      this.state.numberOfFooterRows[i]
                                        .value_footer
                                      // this.state.companyConfig.footer[i].value_footer
                                    }
                                    onChange={(event) =>
                                      this.handleValueChange(event, i)
                                    }
                                  />
                                </span>
                              </label>
                              <Button
                                style={{ padding: "8px", height: "38px" }}
                                onClick={(e) => this.handleFooterDelete(i, v)}
                                color="danger"
                              >
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    {this.state.footerOrg === true ? (
                      <Row style={{ paddingTop: "10px" }}>
                        <Col xs={{ size: 2, offset: 10 }}>
                          <Button
                            onClick={() => {
                              this.setState({
                                numberOfFooterRows: [
                                  ...this.state.numberOfFooterRows,
                                  {},
                                ],
                              });
                            }}
                            color="success"
                          >
                            Add Footer
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Alert Settings`}
                    key={19}
                  >
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.nanoAiSetting}
                              onChange={(e) => {
                                this.handleConfigActive("nanoAiSetting", e);
                              }}
                              name="NanoAi"
                            />
                          }
                          label={
                            this.state.nanoAiSetting === true
                              ? "Alert Settings is Enabled"
                              : "Alert Settings is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Email Configuration`}
                    key={21}
                  >
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.emailConfigurationSetting}
                              onChange={(e) => {
                                this.handleConfigActive(
                                  "emailConfigurationSetting",
                                  e
                                );
                              }}
                              name="emailConfigurationSetting"
                            />
                          }
                          label={
                            this.state.emailConfigurationSetting === true
                              ? "Enable email configuration"
                              : "Disabled email configuration"
                          }
                        />{" "}
                      </Col>
                      {this.state.backupOrganizationConfig &&
                        this.state.backupOrganizationConfig.smtpConfig && (
                          <Col className="text-right">
                            <Button
                              color="danger"
                              onClick={async () => {
                                if (
                                  window.confirm(
                                    "Are you Sure you want to delete?"
                                  )
                                ) {
                                  const { backupOrganizationConfig } =
                                    this.state;
                                  backupOrganizationConfig.smtpConfig = null;
                                  let updateorganizationConfig =
                                    await axios.organizationConfig_update(
                                      organizationId,
                                      backupOrganizationConfig
                                    );
                                  if (
                                    updateorganizationConfig &&
                                    updateorganizationConfig.status === 200
                                  ) {
                                    toast.success(
                                      "Email config deleted successfully!"
                                    );
                                    delete this.state.organizationConfig
                                      .smtpConfig;
                                    this.setState({
                                      organizationConfig:
                                        this.state.organizationConfig,
                                      backupOrganizationConfig:
                                        backupOrganizationConfig,
                                      emailConfigurationSetting: false,
                                    });
                                    return;
                                  }
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </Col>
                        )}
                    </Row>

                    {this.state.emailConfigurationSetting && (
                      <div>
                        <Row>
                          <Col>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              SMTP Email <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email-org"
                              value={
                                this.state.organizationConfig.smtpConfig &&
                                this.state.organizationConfig.smtpConfig
                                  .smtpEmail
                              }
                              onChange={(e) => {
                                this.handleSmtpChange("smtpEmail", e);
                              }}
                            />
                          </Col>
                          <Col>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              SMTP Username{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username-org"
                              value={
                                this.state.organizationConfig.smtpConfig &&
                                this.state.organizationConfig.smtpConfig
                                  .smtpUsername
                              }
                              onChange={(e) => {
                                this.handleSmtpChange("smtpUsername", e);
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              SMTP Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-password"
                              value={
                                this.state.organizationConfig.smtpConfig &&
                                this.state.organizationConfig.smtpConfig
                                  .smtpPassword
                              }
                              onChange={(e) => {
                                this.handleSmtpChange("smtpPassword", e);
                              }}
                            />
                          </Col>
                          <Col>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              SMTP Port <span className="text-danger">*</span>
                            </label>
                            <Tooltip
                              title="Please enter a numerical value for the port"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              />
                            </Tooltip>
                            <Input
                              className="form-control-alternative"
                              id="input-port"
                              value={
                                this.state.organizationConfig.smtpConfig &&
                                this.state.organizationConfig.smtpConfig
                                  .smtpPort
                              }
                              onChange={(e) => {
                                this.handleSmtpChange("smtpPort", e);
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              SMTP Server <span className="text-danger">*</span>
                            </label>
                            <Tooltip
                              title="Enter domain name ex:- smtp.example.com"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              />
                            </Tooltip>
                            <Input
                              className="form-control-alternative"
                              id="input-server"
                              value={
                                this.state.organizationConfig.smtpConfig &&
                                this.state.organizationConfig.smtpConfig
                                  .smtpServer
                              }
                              onChange={(e) => {
                                this.handleSmtpChange("smtpServer", e);
                              }}
                            />
                          </Col>
                          <Col>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Sender Id <span className="text-danger">*</span>
                            </label>
                            <Tooltip
                              title="Enter sender email address"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              />
                            </Tooltip>
                            <Input
                              className="form-control-alternative"
                              id="input-smtp-id"
                              value={
                                this.state.organizationConfig.smtpConfig &&
                                this.state.organizationConfig.smtpConfig
                                  .senderId
                              }
                              onChange={(e) => {
                                this.handleSmtpChange("senderId", e);
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Sender Name <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id=""
                              value={
                                this.state.organizationConfig.smtpConfig &&
                                this.state.organizationConfig.smtpConfig
                                  .senderName
                              }
                              onChange={(e) => {
                                this.handleSmtpChange("senderName", e);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </TabPane>
                </TabsAnt>
              </DialogContent>
              <DialogActions>
                 ̰
                <Button onClick={this.handleConfigClose} color="danger">
                  Cancel
                </Button>
                <Button onClick={this.handleConfigSave} color="success">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={"xl"}
              scroll={"body"}
              open={this.state.themeConfigOpen}
              onClose={this.handleThemeConfigClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ textAlign: "center" }}
              >
                Company Theme Configurations
              </DialogTitle>
              <DialogContent style={{ height: "500px" }}>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Text Color (
                        {this.state.themeConfig.primaryTextColor
                          ? this.state.themeConfig.primaryTextColor
                          : null}
                      </label>
                      <ColorPicker
                        style={{ color: "black" }}
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Text Color (
                        {this.state.themeConfig.secondaryTextColor
                          ? this.state.themeConfig.secondaryTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Body Text Color (
                        {this.state.themeConfig.bodyTextColor
                          ? this.state.themeConfig.bodyTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.bodyTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("bodyTextColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Background Color (
                        {this.state.themeConfig.primaryBackgroundColor
                          ? this.state.themeConfig.primaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Background Color (
                        {this.state.themeConfig.secondaryBackgroundColor
                          ? this.state.themeConfig.secondaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryBackgroundColor
                        }
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button Color (
                        {this.state.themeConfig.primaryButtonColor
                          ? this.state.themeConfig.primaryButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Button Color (
                        {this.state.themeConfig.secondaryButtonColor
                          ? this.state.themeConfig.secondaryButtonColor
                          : null}
                        )
                      </label>

                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Tertiary Button Color (
                        {this.state.themeConfig.tertiaryButtonColor
                          ? this.state.themeConfig.tertiaryButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.tertiaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "tertiaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Link Button Color (
                        {this.state.themeConfig.linkButtonColor
                          ? this.state.themeConfig.linkButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.linkButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("linkButtonColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Color (
                        {this.state.themeConfig.primaryColor
                          ? this.state.themeConfig.primaryColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("primaryColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Color (
                        {this.state.themeConfig.secondaryColor
                          ? this.state.themeConfig.secondaryColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("secondaryColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Tertiary Color (
                        {this.state.themeConfig.tertiaryColor
                          ? this.state.themeConfig.tertiaryColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.tertiaryColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("tertiaryColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button Text Color (
                        {this.state.themeConfig.primaryButtonTextColor
                          ? this.state.themeConfig.primaryButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Button Text Color (
                        {this.state.themeConfig.secondaryButtonTextColor
                          ? this.state.themeConfig.secondaryButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Link Button Text Color (
                        {this.state.themeConfig.linkButtonTextColor
                          ? this.state.themeConfig.linkButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.linkButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "linkButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Tertiary Button Text Color (
                        {this.state.themeConfig.tertiaryButtonTextColor
                          ? this.state.themeConfig.tertiaryButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.tertiaryButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "tertiaryButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button OnHover Color (
                        {this.state.themeConfig.primaryButtonOnHoverColor
                          ? this.state.themeConfig.primaryButtonOnHoverColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryButtonOnHoverColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryButtonOnHoverColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Button OnHover Color (
                        {this.state.themeConfig.secondaryButtonOnHoverColor
                          ? this.state.themeConfig.secondaryButtonOnHoverColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryButtonOnHoverColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryButtonOnHoverColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Graph Color (
                        {this.state.themeConfig.primaryGraphColor
                          ? this.state.themeConfig.primaryGraphColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryGraphColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryGraphColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Graph Color(
                        {this.state.themeConfig.secondaryGraphColor
                          ? this.state.themeConfig.secondaryGraphColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryGraphColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryGraphColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleThemeConfigClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={this.handleThemeConfigSave}
                  color="primary"
                  disabled={this.state.saving}
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Save"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={"md"}
              scroll={"body"}
              open={this.state.loginThemeConfigOpen}
              onClose={this.handleLoginThemeConfigClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ textAlign: "center" }}
              >
                Company Login Theme Config
              </DialogTitle>
              <DialogContent style={{ height: "250px" }}>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button Color (
                        {this.state.loginThemeConfig.primaryButtonColor
                          ? this.state.loginThemeConfig.primaryButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.primaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "primaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Body Text Color (
                        {this.state.loginThemeConfig.bodyTextColor
                          ? this.state.loginThemeConfig.bodyTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.bodyTextColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "bodyTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Background Color (
                        {this.state.loginThemeConfig.primaryBackgroundColor
                          ? this.state.loginThemeConfig.primaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.primaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "primaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Background Color (
                        {this.state.loginThemeConfig.secondaryBackgroundColor
                          ? this.state.loginThemeConfig.secondaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.secondaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "secondaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Background Image Upload
                      </label>
                      <input
                        type="file"
                        ref={(input) => {
                          this.filesInput = input;
                        }}
                        accept={[".jpg", ".jpeg", ".png"]}
                        name="file"
                        placeholder={this.state.loginBackGroundFileName}
                        onChange={async (event) => {
                          const file = event.target.files[0];
                          const name = event.target.files[0].name;
                          const base64 = await this.convertBase64(file);
                          this.setState({
                            loginBackGroundImageUrl: base64,
                            loginBackGroundFileName: name,
                          });
                        }}
                      />
                      {this.state.loginBackGroundFileName ? (
                        <span>
                          Uploaded Image - {this.state.loginBackGroundFileName}
                        </span>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Logo Image Upload
                      </label>
                      <input
                        type="file"
                        ref={(input) => {
                          this.filesInput = input;
                        }}
                        accept={[".jpg", ".jpeg", ".png"]}
                        name="file"
                        placeholder={this.state.loginLogoFileName}
                        onChange={async (event) => {
                          const file = event.target.files[0];
                          const name = event.target.files[0].name;
                          const base64 = await this.convertBase64(file);
                          this.setState({
                            loginLogoImageUrl: base64,
                            loginLogoFileName: name,
                          });
                        }}
                      />
                      {this.state.loginLogoFileName ? (
                        <span>
                          Uploaded Image Logo - {this.state.loginLogoFileName}
                        </span>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Domain Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-email"
                        type="text"
                        placeholder="nanoprecisese.com or sensata.ai"
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.domainName
                        }
                        onChange={(e) => {
                          this.state.loginThemeConfig["domainName"] =
                            e.target.value;
                          this.setState({
                            loginThemeConfig:
                              this.state.loginThemeConfig &&
                              this.state.loginThemeConfig,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleLoginThemeConfigClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleLoginThemeConfigSave}
                  color="primary"
                  disabled={this.state.saving}
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Save"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              scroll={"body"}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Upload Frequency</DialogTitle>
              <DialogContent style={{ height: "250px" }}>
                <Select
                  className="form-control-alternative"
                  isClearable={false}
                  onChange={this.handleSelectChange.bind(
                    this,
                    "upload_frequency",
                    "add"
                  )}
                  placeholder=""
                  options={this.state.frequency}
                  value={this.state.frequency[this.state.selectedFrequency]}
                  // isDisabled={this.state.readOnly}
                  fullWidth
                  margin="dense"
                  autoFocus
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleClose} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              scroll={"body"}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Upload Frequency</DialogTitle>
              <DialogContent style={{ height: "250px" }}>
                <Select
                  className="form-control-alternative"
                  isClearable={false}
                  onChange={this.handleSelectChange.bind(
                    this,
                    "upload_frequency",
                    "add"
                  )}
                  placeholder=""
                  options={this.state.frequency}
                  value={this.state.frequency[this.state.selectedFrequency]}
                  fullWidth
                  margin="dense"
                  autoFocus
                />
                <br></br>
                {this.state.sensorsUploadFrequency.length > 0 && (
                  <center>
                    <h4 style={{ color: "green" }}>Sensors Updated</h4>
                  </center>
                )}
                {this.state.sensorsUploadFrequency.length > 0 &&
                  this.state.sensorsUploadFrequency.map((sensor) => {
                    return (
                      sensor.resStatus == 200 && (
                        <Chip
                          style={{
                            border: "1px solid #2dce89",
                            color: "#000",
                            margin: "2px",
                          }}
                          label={sensor.sensorId}
                          color="primary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
                <br></br>
                <br></br>
                {this.state.sensorsUploadFrequencyError.length > 0 && (
                  <center>
                    <h4 style={{ color: "#f5365c" }}>
                      Electric Imp Exception in Sensors
                    </h4>
                  </center>
                )}
                {this.state.sensorsUploadFrequencyError.length > 0 &&
                  this.state.sensorsUploadFrequencyError.map((sensor) => {
                    return (
                      sensor.resStatus != 200 && (
                        <Chip
                          style={{
                            border: "1px solid #f5365c",
                            color: "#000",
                            margin: "2px",
                          }}
                          label={sensor.sensorId}
                          color="primary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="danger">
                  Close
                </Button>
                <Button
                  onClick={this.handleUploadRateSubmit}
                  color="success"
                  disabled={this.state.saving}
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              fullWidth={true}
              maxWidth={"md"}
              scroll={"body"}
              open={this.state.codesOpen}
              onClose={this.handleCodesClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent style={{ height: "500px" }}>
                <h3>Notification codes</h3>
                <div>
                  <Tabs onSelect={this.handleTabChange}>
                    <TabList>
                      {this.state.notificationCodes &&
                        this.state.notificationCodes.map((x, i) => (
                          <Tab key={i} style={{ color: "#e57373" }}>
                            {x.appId}
                          </Tab>
                        ))}
                    </TabList>

                    {/* {this.state.notificationCodes &&
                  this.state.notificationCodes[this.state.currentTabIndex] &&
                  this.state.notificationCodes[this.state.currentTabIndex]
                    .codes &&
                  Object.keys(
                    this.state.notificationCodes[this.state.currentTabIndex]
                      .codes
                  ). */}
                    {this.state.notificationCodesTemp &&
                      this.state.notificationCodesTemp.map((y) => {
                        return y.id.startsWith(
                          String(this.state.currentTabIndex)
                        ) ? (
                          y.key === "code_fault_customer" ? (
                            <Container maxWidth="sm">
                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions1-content"
                                  id="additional-actions1-header"
                                >
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <Checkbox
                                        checked={y.apply}
                                        onChange={(e) => {
                                          this.checkCode(y.id, "check", e);
                                        }}
                                      ></Checkbox>
                                    }
                                    label={y.key}
                                  />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Row key={y.key}>
                                    <Col xs={6} lg={6}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Fault Stage
                                      </label>
                                      <Select
                                        className="form-control-alternative"
                                        onChange={(e) => {
                                          this.checkCode(
                                            y.id,
                                            "faultStage",
                                            parseInt(e.value)
                                          );
                                        }}
                                        options={this.state.faultStages}
                                        isDisabled={!y.apply}
                                        value={
                                          this.state.faultStages.filter((x) => {
                                            return x.label == y.faultStage;
                                          })[0]
                                        }
                                      />
                                    </Col>
                                    <Col xs={6} lg={6}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        RUL
                                      </label>
                                      <Input
                                        style={{ height: "40px" }}
                                        className="form-control-alternative"
                                        type="number"
                                        value={y.rul}
                                        onChange={(e) => {
                                          this.checkCode(
                                            y.id,
                                            "rul",
                                            parseFloat(e.target.value)
                                          );
                                        }}
                                        disabled={!y.apply}
                                      />
                                    </Col>
                                  </Row>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Container>
                          ) : (
                            <Container maxWidth="sm">
                              <ExpansionPanel
                              // expanded={this.state.expanded === 'panel' + i}
                              // onChange={this.handleExpandChange('panel' + i)}
                              >
                                <ExpansionPanelSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions1-content"
                                  id="additional-actions1-header"
                                >
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <Checkbox
                                        checked={y.apply}
                                        onChange={(e) => {
                                          this.checkCode(y.id, "check", e);
                                        }}
                                      ></Checkbox>
                                    }
                                    label={y.key}
                                  />
                                </ExpansionPanelSummary>
                              </ExpansionPanel>
                            </Container>
                          )
                        ) : null;
                      })}
                  </Tabs>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCodesClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.processDirectCodes} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              maxWidth="lg"
              onClose={() => {
                this.setState({ isFeedbackConfigManual: false });
              }}
              aria-labelledby="customized-dialog-title"
              open={this.state.isFeedbackConfigManual}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ isFeedbackConfigManual: false });
                }}
              >
                Maintenance Type
              </DialogTitle>
              <Button
                onClick={() => {
                  this.setState({
                    isCreateMaintenanceType:
                      !this.state.isCreateMaintenanceType,
                  });
                }}
              >
                {!this.state.isCreateMaintenanceType ? "Create New" : "Back"}
              </Button>
              {this.state.isCreateMaintenanceType ? (
                <DialogContent dividers>
                  <div>
                    <Input
                      placeHolder="Name"
                      name="name"
                      onChange={(e) => {
                        this.setState({ maintenanceTypeName: e.target.value });
                      }}
                    />
                    <Input
                      placeHolder="Description"
                      name="desc"
                      onChange={(e) => {
                        this.setState({ maintenanceTypeDesc: e.target.value });
                      }}
                    />
                    {/* <Input
                  placeHolder="Key"
                  name="val"
                  onChange={(e) => {
                    this.setState({ maintenanceTypeVal: e.target.value });
                  }}
                /> */}
                    <Button
                      onClick={async () => {
                        let tempCheck = false;
                        if (!this.state.maintenanceTypeName) {
                          alert("Please Enter Name!");
                          return;
                        }
                        this.state.maintenanceTypeList.map((val) => {
                          if (val.val === this.state.maintenanceTypeVal) {
                            alert("Key Already Exists");
                            tempCheck = true;
                          }
                          if (val.name === this.state.maintenanceTypeName) {
                            alert("Name Already Exists");
                            tempCheck = true;
                          }
                        });
                        if (this.state.maintenanceTypeName === null) {
                          alert("Kindly change the name");
                          tempCheck = true;
                        }
                        if (tempCheck) {
                          this.setState({
                            maintenanceTypeVal: 0,
                            maintenanceTypeName: null,
                          });
                          return;
                        }

                        if (window.confirm("Are you Sure you want to Add?")) {
                          let obj = {
                            val: this.state.maintenanceTypeVal || 0,
                            name: this.state.maintenanceTypeName,
                            desc: this.state.maintenanceTypeDesc,
                            resetFaultDetection: false,
                            maintenanceWatch: false,
                            noAction: true,
                          };
                          await axios.postData(
                            `/feedbackLog/maintenanceTypes/maintenanceTypesAdd/${companyId}`,
                            obj
                          );
                          this.props.fetchMaintenanceTypes(companyId);
                          toast.success("Successfully Added!");
                          this.setState({
                            isCreateMaintenanceType: false,
                            maintenanceTypeVal: 0,
                            maintenanceTypeName: null,
                            maintenanceTypeDesc: null,
                          });
                        } else {
                          // this.setState({
                          //   isCreateMaintenanceType: false,
                          //   maintenanceTypeVal: null,
                          //   maintenanceTypeName: null,
                          //   maintenanceTypeDesc: null,
                          // });
                          return;
                        }
                      }}
                    >
                      Done
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({
                          isCreateMaintenanceType: false,
                          maintenanceTypeVal: 0,
                          maintenanceTypeName: null,
                          maintenanceTypeDesc: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </DialogContent>
              ) : (
                <DialogContent dividers>
                  <div>
                    {this.state.maintenanceTypeList &&
                      this.state.maintenanceTypeList.length > 0 &&
                      this.state.maintenanceTypeList.map((val) => {
                        return (
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <div>
                                <Typography>
                                  <b>{val.name}</b>
                                </Typography>
                              </div>
                              <span> </span>
                              <div>
                                <Typography>{val.desc}</Typography>
                              </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <FormGroup tag="fieldset">
                                <div>
                                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                    <i
                                      className="fa fa-trash"
                                      onClick={async () => {
                                        if (
                                          window.confirm(
                                            "Are you Sure you want to delete?"
                                          )
                                        ) {
                                          await axios.postData(
                                            `/feedbackLog/maintenanceTypes/maintenanceTypesDel/${companyId}`,
                                            { val: val.val }
                                          );
                                          toast.success(
                                            "Successfully Deleted!"
                                          );
                                          this.props.fetchMaintenanceTypes(
                                            companyId
                                          );
                                        } else {
                                          this.setState({
                                            isFeedbackConfigManual: false,
                                          });
                                          this.props.fetchMaintenanceTypes(
                                            companyId
                                          );
                                          return;
                                        }
                                      }}
                                    />
                                  </div>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        name={val.val}
                                        value="rstFaultDetect"
                                        checked={val.resetFaultDetection}
                                        onChange={(e) =>
                                          this.handleOptionChange(e, val)
                                        }
                                      />{" "}
                                      Reset Fault Detection
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        name={val.val}
                                        value="mainTenanceWatch"
                                        checked={val.maintenanceWatch}
                                        onChange={(e) =>
                                          this.handleOptionChange(e, val)
                                        }
                                      />{" "}
                                      Maintenance Watch
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name={val.val}
                                        value="noAction"
                                        checked={val.noAction}
                                        onChange={(e) =>
                                          this.handleOptionChange(e, val)
                                        }
                                      />{" "}
                                      No Action
                                    </Label>
                                  </FormGroup>
                                </div>
                              </FormGroup>
                            </ExpansionPanelDetails>
                            <Divider />
                          </ExpansionPanel>
                        );
                      })}
                  </div>
                </DialogContent>
              )}
            </Dialog>
          </>
        )}
        ;
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  notifData: _.isEmpty(state.companies.notifData)
    ? null
    : state.companies.notifData,
  companyDetailsData: _.isEmpty(state.companies.companyDetailsData)
    ? null
    : state.companies.companyDetailsData,
  organizationDetailsData: _.isEmpty(state.organization.organizationDetailsData)
    ? null
    : state.organization.organizationDetailsData,
  themeConfigData: _.isEmpty(state.companies.themeConfigData)
    ? null
    : state.companies.themeConfigData,
  loginThemeConfigData: _.isEmpty(state.companies.loginThemeConfigData)
    ? null
    : state.companies.loginThemeConfigData,
  maintenanceTypesData: _.isEmpty(state.feedback.maintenanceTypesData)
    ? null
    : state.feedback.maintenanceTypesData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrganizationDetails: (organizationId) =>
      dispatch(fetchOrganizationDetails(organizationId)),
    fetchCompanyDetails: (companyId) =>
      dispatch(fetchCompanyDetails(companyId)),
    fetchNotifCount: (companyId, booleanVar) =>
      dispatch(fetchNotifCount(companyId, booleanVar)),
    fetchThemeConfig: (companyId) => dispatch(fetchThemeConfig(companyId)),
    fetchLoginThemeConfig: (companyId) =>
      dispatch(fetchLoginThemeConfig(companyId)),
    fetchMaintenanceTypes: (companyId) =>
      dispatch(fetchMaintenanceTypes(companyId)),
    fetchNotificationCodes: (roleId) =>
      dispatch(fetchNotificationCodes(roleId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationProfile);
