import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Helper from "../../../../helper";
import Select from "react-select";

// reactstrap components
import { Button, FormGroup, Input } from "reactstrap";

class CustomDialog extends React.Component {
  state = {
    showSelect: false,
  };
  componentDidMount() {}

  handleChange = (state, key) => (e) => {
    let subject = { ...this.state[state] };
    Helper.reduce(subject, key, e.target.value);
    this.setState({ [state]: subject });
  };

  render() {
    const days = [
      {
        value: 0,
        label: "0",
      },
      {
        value: 1,
        label: "1",
      },
      {
        value: 2,
        label: "2",
      },
      {
        value: 3,
        label: "3",
      },
      {
        value: 4,
        label: "4",
      },
      {
        value: 5,
        label: "5",
      },
      {
        value: 6,
        label: "6",
      },
    ];
    return (
      <Dialog
        open={this.props.isDialogOpen}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <div
          style={{
            flexDirection: "row",
            width: 500,
            height: 300,
            justifySelf: "center",
            padding: 10,
          }}
        >
          <div>
            <span>Reason for Disapproval:</span>
            <Input
              type='textarea'
              name='text'
              id='exampleText'
              value={this.props.reason}
              onChange={this.props.handleChange("disapprove", "reason")}
            />
          </div>
          <div>
            {this.props.notificationDetails && (
              <FormGroup>
                <label
                  className='form-control-label'
                  htmlFor='input-last-name'
                  style={{ fontWeight: "bold" }}
                >
                  Mute Notifications (days){" "}
                </label>
                <Select
                  maxMenuHeight={200}
                  options={days}
                  className='form-control-alternative'
                  placeholder=''
                  classNamePrefix='select'
                  defaultValue={days[0]}
                  isSearchable={true}
                  options={days}
                  onChange={this.props.handleSelectChange(
                    "disapprove",
                    "noOfDays"
                  )}
                ></Select>
              </FormGroup>
            )}
          </div>
        </div>

        <DialogActions>
          <Button onClick={() => this.props.closeDialog()} color='danger'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.props.handleArchive();
            }}
            color='success'
            variant='contained'
            style={{ color: "#fff" }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CustomDialog;
