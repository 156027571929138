import React from "react";
import "./Shimmer.css";

const GraphShimmer = () => {
  return (
    <div className="graph-shimmer">
      <div className="rectangular-box-oneg">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-box-twog">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-box-threeg">
        <div className="shimmer-effect"></div>
      </div>
    </div>
  );
};

export default GraphShimmer;