import React from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ifvisible from "ifvisible.js";
import $ from "jquery";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";

import "./sensors.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from "reactstrap";
// core components

import UserHeader from "components/Headers/Header.jsx";
import Helper from "../../../../helper";
import axios from "../../../../axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";
import readXlsxFile from "read-excel-file";
import QRCode from "qrcode.react";

let portalUser = {};
const options = {
  showTotal: true
};
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

class QRExport extends React.Component {
  constructor(props) {
    super(props);
    // if (!Helper.isAuth()) {
    //     this.jump('/auth/login');
    // }
  }

  state = {
    data: "",
    readOnly: true,
    saving: false,
    columns: [
      {
        text: "Sensor Id",
        dataField: "sensorId",
        sort: false
      },
      {
        text: "QR Code",
        dataField: "sensorId",
        sort: false,
        formatter: this.qrCodes
      },
      {
        text: "Equipment",
        dataField: "equipment",
        sort: false
      },
      {
        text: "Component",
        dataField: "component",
        sort: false
      }
    ],
    openDelete: false,
    print: false
  };

  componentDidMount() {
    let data = JSON.parse(localStorage.getItem("print-job"));
    this.setState({ data: data });
  }

  qrCodes(cell, row) {
    return (
      <QRCode
        id={cell}
        style={{ height: 60, width: 60 }}
        value={"Nano_" + cell}
        includeMargin={true}
      />
    );
  }

  handleChange = (state, key) => e => {
    let subject = { ...this.state[state] };
    Helper.reduce(subject, key, e.target.value);
    this.setState({ [state]: subject });
  };

  handleAlarmDelete() {
    window.print();
  }

  handleCancel = () => {
    this.setState({
      user: this.state.userBackup,
      readOnly: true
    });
  };

  render() {
    return (
      <>
        {/* <Row>
                <div className="col">
                <Card className="bg-secondary shadow">
                {
                    this.state.data && 
                    <ToolkitProvider
                    keyField="id"
                    data={ this.state.data }
                    columns={ this.state.columns }
                    search={ { searchFormatted: true } }
                    >
                    {
                        props => (
                        <div>
                            <BootstrapTable
                                { ...props.baseProps }
                                striped
                                hover
                            />
                        </div>
                        )
                    }
                    </ToolkitProvider>
                }
                </Card>
                </div>
            </Row> */}

        {/* <Row>
              {
                this.state.data &&
                this.state.data.map(qr => {
                  return (
                    <>
                      <Col lg="4">
                        <Row>
                          <Col lg="2">
                            <QRCode className="orange" id={qr.sensorId} style={{ height: 60, width: 60 }} value={"Nano_" + qr.sensorId} includeMargin={true} />
                          </Col>  
                          <Col lg="10">
                            <h6 className="orange">{qr.equipment}</h6> 
                            <h6 className="orange">{qr.component}</h6>
                            <h6 className="orange">{qr.sensorId}</h6>
                          </Col>
                        </Row>
                      <br></br>
                      </Col>
                    </>
                  )
                })
              }
            </Row> */}

        <div class="grid-container">
          {this.state.data &&
            this.state.data.map(qr => {
              return (
                <>
                  <div class="grid-item">
                    <div style={{ borderwidth: 1, bordercolor: "#000" }}>
                      <QRCode
                        id={qr.sensorId}
                        style={{ height: 60, width: 60 }}
                        value={"Nano_" + qr.sensorId}
                        includeMargin={true}
                      />
                      <div style={{ marginTop: "10px" }}>
                        <h6 className="">{qr.sensorId}</h6>
                        <h6 className="">{qr.ssid}</h6>
                      </div>
                    </div>
                  </div>
                  {/* <div style={{flexDirection:"column"}} >
                          <QRCode className="inline-print" id={qr.sensorId} style={{ height: 60, width: 60 }} value={"Nano_" + qr.sensorId} includeMargin={true} />
                          
                            <h6 className="">{qr.equipment}</h6>
                            <h6 className="">{qr.component}</h6>
                            <h6 className="">{qr.sensorId}</h6>
                          
                        </div> */}
                </>
              );
            })}
        </div>
      </>
    );
  }
}

export default QRExport;