import CallAPI from "../../axios";

export const FETCH_MAINTENANCE_TYPES_DATA = "FETCH_MAINTENANCE_TYPES_DATA";

export const FETCH_FEEDBACK_LOG_DATA = "FETCH_FEEDBACK_LOG_DATA";

const fetchMaintenanceTypesData = (data) => {
  return {
    type: FETCH_MAINTENANCE_TYPES_DATA,
    payload: data,
  };
};

const fetchFeedbackLogData = (data) => {
  return {
    type: FETCH_FEEDBACK_LOG_DATA,
    payload: data,
  };
};

export const fetchMaintenanceTypes = (companyId) => {
  return (dispatch) => {
    CallAPI.get_maintenanceType(companyId)
      .then((response) => {
        response &&
          dispatch(fetchMaintenanceTypesData(response && response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchFeedbackLog = (companyId, archived, endTime, startTime) => {
  return (dispatch) => {
    CallAPI.get_feedBack(companyId, archived, endTime, startTime)
      .then((response) => {
        dispatch(fetchFeedbackLogData(response && response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
