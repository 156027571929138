import React from "react";
import { connect } from "react-redux";
import { fetchGroupDetails } from "../../../../redux/actions/companies.action";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import Select from "react-select";

import axios from "../../../../axios";
import "./groups.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Form,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const options = {
  showTotal: true,
};

let companyId = "";
let plantId = "";

class GroupDetails extends React.Component {
  state = {
    group: {},
    hierarchy: null,
    readOnly: true,
    saving: false,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    plantId = this.props.match.params.plantId;
    this.props.fetchGroupDetails(companyId, plantId);
  }

  async getGroup() {
    let api = await axios.plantManage_plantDetails(plantId);
    let group = api.data.data;
    let hierarchy = group.hierarchy;
    delete group.hierarchy;
    this.setState({ group, hierarchy });
  }

  handleChange = (input) => (e) => {
    let group = { ...this.state.group };
    group[input] = e.target.value;
    this.setState({ group: group });
  };

  handleSelectChange = () => (option) => {
    let equipmentType = { ...this.state.equipmentType };
    equipmentType.equipmentType = option.value;
    this.setState({ equipmentType: equipmentType });
  };

  async handleSave() {
    this.setState({ saving: true });
    let group = { ...this.state.group };

    //Validating
    let testGroup = { ...group };
    testGroup.hierarchy = this.state.hierarchy;
    let err = await validation.validate(testGroup);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    group.companyId = companyId;

    // Hierachy Code
    // let hierarchy = [...this.state.hierarchy];
    // hierarchy.map((hier, i) => {
    //     delete hier._id;
    //     delete hier.__v;
    //     delete hier.as;
    //     hier.level = parseInt(i+1);
    // });

    // group.hierarchy = JSON.stringify(hierarchy);

    delete group._id;
    delete group.__v;

    group = {
      customName: group.customName,
      externalId: group.externalId,
    };

    let api = await axios.plantManage_update(this.props.match.params.plantId, group);
    this.setState({ saving: false });
    if (api.status == 200) {
      toast.success("Group saved successfully");
      this.setState({ readOnly: true });
      //Redirect to company details page
      this.jump("/v1/companies/" + companyId + "/groups", 1000);
    } else if (api.status == 400) {
      toast.warning(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  handleAdd() {
    let hierarchy = [...this.state.hierarchy];
    let obj = {
      name: "",
      level: "",
      value: "",
      description: "",
    };
    hierarchy.push(obj);
    this.setState({ hierarchy });
  }

  handleRemove() {
    let hierarchy = [...this.state.hierarchy];
    hierarchy.pop();
    this.setState({ hierarchy });
  }

  handleHierarchyChange = (input, index) => (e) => {
    let hierarchy = [...this.state.hierarchy];
    hierarchy[index][input] = e.target.value;
    this.setState({ hierarchy });
  };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.readOnly) {
      return;
    }
    if (nextProps.groupDetailsData !== prevState.groupDetailsData) {
      if (nextProps.groupDetailsData) {
        let hierarchy = nextProps.groupDetailsData.data.hierarchy;
        delete nextProps.groupDetailsData.data.hierarchy;

        return {
          group: nextProps.groupDetailsData.data,
          hierarchy: hierarchy,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/groups/" + companyId + "/" + row.plantId);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}

          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump("/v1/companies/" + companyId + "/groups", 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Plant Details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnly && (
                        <Button
                          color="primary"
                          onClick={() => this.setState({ readOnly: false })}
                          size="md"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>

                {
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Plant Name:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                              value={this.state.group.customName}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            External ID{" "}
                            <Tooltip
                              title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "30px",
                                }}
                              />
                            </Tooltip>
                          </label>
                        </Col>
                        <Col lg="2">
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            value={this.state.group.externalId}
                            onChange={this.handleChange("externalId")}
                            disabled={this.state.readOnly}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Description:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("description")}
                              value={this.state.group.description}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row> */}
                      {/* <Row>
                    <Col lg="2"></Col>
                    <Col lg="2">
                        <label
                            className="form-control-label"
                            htmlFor="input-username"
                            >
                          Hierarchy:
                        </label>
                    </Col>
                    <Col lg="6">
                        {   
                            this.state.hierarchy &&
                            this.state.hierarchy.map((hier, i) => {
                                return (
                                    <div className="grey" key={i}>
                                        
                                    <Col md="12">
                                        <Row>
                                            <Col lg="3">
                                                <label className="form-control-label" htmlFor="input-username">Name:</label>
                                            </Col>
                                            <Col lg="9">
                                                <FormGroup>
                                                    <Input 
                                                        id="exampleFormControlInput1" 
                                                        type="text"
                                                        value={this.state.hierarchy[i].name}
                                                        onChange={this.handleHierarchyChange('name', i)}
                                                        disabled={this.state.readOnly}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                        <Row>
                                            <Col lg="3">
                                                <label className="form-control-label" htmlFor="input-username">Value:</label>
                                            </Col>
                                            <Col lg="9">
                                                <FormGroup>
                                                    <Input 
                                                        id="exampleFormControlInput1" 
                                                        type="text"
                                                        value={this.state.hierarchy[i].value}
                                                        onChange={this.handleHierarchyChange('value', i)}
                                                        disabled={this.state.readOnly}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                        <Row>
                                            <Col lg="3">
                                                <label className="form-control-label" htmlFor="input-username">Description:</label>
                                            </Col>
                                            <Col lg="9">
                                                <FormGroup>
                                                    <Input 
                                                        id="exampleFormControlInput1" 
                                                        type="text"
                                                        value={this.state.hierarchy[i].description}
                                                        onChange={this.handleHierarchyChange('description', i)}
                                                        disabled={this.state.readOnly}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col> 
                                </div>
                                )
                            })
                        }
                    </Col>
                    <Col lg="2">
                        <Button
                          className=""
                          color="success"
                          onClick={() => this.handleAdd()}
                          size="sm"
                        >
                          Add
                        </Button>
                        {
                            this.state.hierarchy &&
                            this.state.hierarchy.length >=2 &&
                            <Button
                            className="right"
                            color="danger"
                            onClick={() => this.handleRemove()}
                            size="sm"
                            >
                            Remove
                            </Button>
                        }
                    </Col>
                </Row> */}

                      <Row>
                        <Col lg="12">
                          {!this.state.readOnly && (
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          )}

                          {!this.state.readOnly && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ readOnly: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                }
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  groupDetailsData: _.isEmpty(state.companies.groupDetailsData)
    ? null
    : state.companies.groupDetailsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroupDetails: (companyId, userId) =>
      dispatch(fetchGroupDetails(companyId, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
