import {
  FETCH_NOTIFCATION_MUTE_DATA,
  FETCH_NOTIFCATION_DATA,
  FETCH_NOTIFCATION_CODES_DATA,
  FETCH_NOTIFCATION_GROUP_DATA
} from "../actions/notification.action";

const notificationReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFCATION_MUTE_DATA:
      return {
        ...state,
        notificationMuteData: action.payload,
      };
    case FETCH_NOTIFCATION_DATA:
      return {
        ...state,
        currentNotificationData: action.payload,
      };
    case FETCH_NOTIFCATION_CODES_DATA:
      return {
        ...state,
        notificationCodes: action.payload,
      };
    case FETCH_NOTIFCATION_GROUP_DATA:
      return{
        ...state,
        notificationGroup: action.payload
      }
    default:
      // need this for default case
      return state;
  }
};

export default notificationReducer;
