import Index from "views/Index.jsx";
// import Register from "views/examples/Register.jsx";
import Login from "views/examples/Login.jsx";
import ForgotPassword from "views/examples/ForgotPassword.jsx";
// import ResetPassword from "views/examples/ResetPassword.jsx";
import Companies from "views/examples/Companies/Companies.jsx";

import CompanyProfile from "views/examples/Companies/CompanyProfile.jsx";
// import MachineTypes from "views/examples/MachineTypes/MachineTypes";
// import MachineTypesDetails from "views/examples/MachineTypes/MachineTypeDetails";

import EquipmentTypes from "views/examples/EquipmentTypes/EquipmentTypes";
import EquipmentTypesDetails from "views/examples/EquipmentTypes/EquipmentTypeDetails";
// import EquipmentSubTypeDetails from "views/examples/EquipmentSubTypes/EquipmentSubTypesDetails";
// import EquipmentSubTypes from "views/examples/EquipmentSubTypes/EquipmentSubTypes";
import ComponentTypes from "views/examples/ComponentTypes/ComponentTypes";
import ComponentTypesDetails from "views/examples/ComponentTypes/ComponentTypeDetails";
import Machines from "views/examples/Companies/Machines/Machines";
import MachineDetails from "views/examples/Companies/Machines/MachineDetails";
import Equipments from "views/examples/Companies/Equipments/Equipments";
import EquipmentDetails from "views/examples/Companies/Equipments/EquipmentDetails";
import EquipmentsAdd from "views/examples/Companies/Equipments/EquipmentAdd";
import ComponentDetails from "views/examples/Companies/Components/ComponentDetails";
import ComponentAdd from "views/examples/Companies/Components/ComponentAdd";
import Sensors from "views/examples/Companies/Sensors/Sensors";
import SensorAdd from "views/examples/Companies/Sensors/SensorAdd";
import SensorAssignConfig from "views/examples/Companies/Sensors/SensorAssignConfig";
import SensorDetails from "views/examples/Companies/Sensors/SensorDetails";
import SensorDetailsOldConfig from "views/examples/Companies/Sensors/SensorDetailsOldConfig";
import NotificationMain from "views/examples/Companies/Notification/Notification";
import NotificationDetails from "views/examples/Companies/Notification/NotificationDetails";
import NotificationGroup from "views/examples/Companies/Notification/NotificationGroup";
// import NotificationDetails from "../src/views/examples/Companies/Notification/NotificationDetails";
import Groups from "views/examples/Companies/Groups/Groups";
import GroupDetails from "views/examples/Companies/Groups/GroupDetails";

import Reports from "views/examples/Companies/Reports/Reports";
import ReportDetails from "views/examples/Companies/Reports/ReportDetails";

// import Users from "views/examples/Companies/Users/Users";
// import userDetails from "views/examples/Companies/Users/UserDetails";

import Eula from "../src/views/examples/Companies/eula/Eula";
import EulaDetails from "../src/views/examples/Companies/eula/EulaDetails";

// import TechnicalSpecifications from "views/examples/TechnicalSpecifications/TechnicalSpecifications";
// import TechnicalSpecificationDetails from "views/examples/TechnicalSpecifications/TechnicalSpecificationDetails";
// import Hardwares from "views/examples/Hardwares/Hardwares";
// import HardwareDetails from "views/examples/Hardwares/HardwareDetails";
import UserProfile from "views/examples/Profile/Profile";
// import ChangePassword from "views/examples/Profile/ChangePassword";

import SensorTypes from "views/examples/SensorTypes/SensorTypes";
import SensorTypeDetails from "views/examples/SensorTypes/SensorTypeDetails";
import SensorTypeAdd from "views/examples/SensorTypes/SensorTypeAdd";

import FirmwareTypes from "views/examples/FirmwareTypes/Firmwares";
import FirmwareAdd from "views/examples/FirmwareTypes/FirmwareAdd";
import FirmwareDetails from "views/examples/FirmwareTypes/FirmwareDetails";

import ProcessConfigs from "views/examples/ProcessConfigs/ProcessConfigs";
import ProcessConfigAdd from "views/examples/ProcessConfigs/ProcessConfigAdd";
import ProcessConfigDetails from "views/examples/ProcessConfigs/ProcessConfigDetails";

import MountConfigs from "views/examples/MountConfigs/MountConfigs";
import MountConfigAdd from "views/examples/MountConfigs/MountConfigAdd";
import MountConfigDetails from "views/examples/MountConfigs/MountConfigDetails";

// import Activites from "views/examples/Activity/Activities";
// import UserSessions from "views/examples/Activity/UserSessions";
// import SessionEvents from "views/examples/Activity/SessionEvents";
import SensorTags from "./views/examples/Companies/Sensors/SensorTags";

import GenericScanners from "./views/examples/Scanners";

import Scanners from "./views/examples/Companies/Scanners/Scanners";
import ScannerDetails from "./views/examples/Companies/Scanners/ScannerDetails";

// import QRSensorTypes from "views/examples/SensorTypes/QRSensorTypes";

import Feedback from "./views/examples/Companies/FeedbackLog/Feedback";
import FeedbackSection from "./views/examples/Companies/FeedbackLog/FeedbackSection";

import SensorsAddQr from "views/examples/Companies/Sensors/SensorAddQr.jsx";

import QRExport from "views/examples/Companies/Sensors/QRExport";

import DelayedCDP from "views/examples/DelayedCDP/delayedCDP";

import Manual from "views/examples/Manuals/Manual";
import ManualDetails from "views/examples/Manuals/ManualDetails";

import Organizations from "./views/examples/Organizations/Organizations";
import OrganizationProfile from "./views/examples/Organizations/OrganizationProfile";

import CompanyList from "./views/examples/Organizations/CompanyList/CompanyList";
import OrganizationGroup from "./views/examples/Organizations/Groups/Groups";
// import OrganizationMachine from "./views/examples/Organizations/Machines/Machines";
// import OrganizationEquipment from "./views/examples/Organizations/Equipments/Equipments";
import OrganizationCompanyProfile from "./views/examples/Organizations/OrganizationCompanyProfile";
// import OrganizationUser from "./views/examples/Organizations/Users/Users";
// import OrganizationSensor from "./views/examples/Organizations/Sensors/Sensors";
// import OrganizationNotification from "./views/examples/Organizations/Notification/Notification";
// import OrganizationFeedback from "./views/examples/Organizations/FeedbackLog/Feedback";

/**
 * Root Account
 */

// import RootUsers from "views/examples/Users/Users";
// import RootUserAdd from "views/examples/Users/UserAdd";
// import RootUserDetails from "views/examples/Users/UserDetails";
// import RequestUser from "./views/examples/requestUser/requestUser";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    visible: false,
    id: 12,
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  //   visible: false,
  //   id: 13,
  // },
  {
    path: "/qr-export",
    name: "Qr Export",
    icon: "ni ni-single-02 text-yellow",
    component: QRExport,
    layout: "/v1",
    visible: false,
    id: 59,
  },
  {
    path: "/companies/:companyId/scanners/:sensorId",
    name: "Scanner Data Details",
    icon: "fa fa-address-book text-orange",
    component: ScannerDetails,
    layout: "/v1",
    visible: false,
    id: 57,
  },
  {
    path: "/companies/:companyId/scanners/",
    name: "Scanner Data",
    icon: "fa fa-address-book text-orange",
    component: Scanners,
    layout: "/v1",
    visible: false,
    id: 56,
  },
  {
    path: "/companies/:companyId/feedback",
    name: "Feedback Data",
    icon: "fa fa-address-book text-orange",
    component: Feedback,
    layout: "/v1",
    visible: false,
    id: 101,
  },
  {
    path: "/companies/:companyId/feedbacks/:tagId",
    name: "Feedback Section",
    icon: "fa fa-address-book text-orange",
    component: FeedbackSection,
    layout: "/v1",
    visible: false,
    id: 150,
  },
  {
    path: "/companies/:companyId/notification/",
    name: "Notification",
    icon: "fa fa-address-book text-orange",
    component: NotificationMain,
    layout: "/v1",
    visible: false,
    id: 60,
  },
  // {
  //   path: "/companies/:companyId/notification/:id",
  //   name: "Notification Details",
  //   icon: "fa fa-address-book text-orange",
  //   component: NotificationDetails,
  //   layout: "/v1",
  //   visible: false,
  //   id: 61
  // },
  {
    path: "/companies/:companyId/machines/:machineId/equipments/:equipmentId/components/add",
    name: "Component Details",
    icon: "ni ni-single-02 text-yellow",
    component: ComponentAdd,
    layout: "/v1",
    visible: false,
    id: 14,
  },
  {
    path: "/companies/:companyId/machines/:machineId/equipments/:equipmentId/components/:componentId",
    name: "Component Details",
    icon: "ni ni-single-02 text-yellow",
    component: ComponentDetails,
    layout: "/v1",
    visible: false,
    id: 15,
  },
  {
    path: "/companies/:companyId/machines/:machineId/equipments/add",
    name: "Equipment Details",
    icon: "ni ni-single-02 text-yellow",
    component: EquipmentsAdd,
    layout: "/v1",
    visible: false,
    id: 16,
  },
  {
    path: "/companies/:companyId/machines/:machineId/equipments/:equipmentId",
    name: "Equipment Details",
    icon: "ni ni-single-02 text-yellow",
    component: EquipmentDetails,
    layout: "/v1",
    visible: false,
    id: 17,
  },

  {
    path: "/companies/:companyId/machines/:machineId/notification/:id/:status",
    name: "Equipment Details",
    icon: "ni ni-single-02 text-yellow",
    component: NotificationDetails,
    layout: "/v1",
    visible: false,
    id: 61,
  },
  {
    path: "/companies/:companyId/notificationGroup/",
    name: "Notification Group",
    icon: "ni ni-single-02 text-yellow",
    component: NotificationGroup,
    layout: "/v1",
    visible: false,
    id: 63,
  },
  {
    path: "/companies/:companyId/machines/:machineId/equipments",
    name: "Equipments",
    icon: "ni ni-single-02 text-yellow",
    component: Equipments,
    layout: "/v1",
    visible: false,
    id: 18,
  },
  {
    path: "/companies/:companyId/groups/:plantId",
    name: "Plants",
    icon: "ni ni-single-02 text-yellow",
    component: GroupDetails,
    layout: "/v1",
    visible: false,
    id: 19,
  },
  {
    path: "/companies/:companyId/reports/:companyId",
    name: "Reports",
    icon: "ni ni-single-02 text-yellow",
    component: ReportDetails,
    layout: "/v1",
    visible: false,
    id: 20,
  },
  {
    path: "/manual/:manualId",
    name: "dashboardManual",
    icon: "fa fa-university text-orange",
    component: ManualDetails,
    layout: "/v1",
    visible: false,
    id: 64,
  },
  {
    path: "/manual",
    name: "dashboardManual",
    icon: "fa fa-university text-orange",
    component: Manual,
    layout: "/v1",
    visible: false,
    id: 65,
  },
  // {
  //   path: "/companies/:companyId/users/:plantId",
  //   name: "Users",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: userDetails,
  //   layout: "/v1",
  //   visible: false,
  //   id: 21,
  // },
  // {
  //   path: "/companies/:companyId/users",
  //   name: "Users",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Users,
  //   layout: "/v1",
  //   visible: false,
  //   id: 22,
  // },

  {
    path: "/eula/:eulaInfoId",
    name: "eulaInfo",
    icon: "fa fa-university text-orange",
    component: EulaDetails,
    layout: "/v1",
    visible: false,
    id: 64,
  },
  {
    path: "/eula",
    name: "eulaInfo",
    icon: "fa fa-university text-orange",
    component: Eula,
    layout: "/v1",
    visible: false,
    id: 65,
  },
  {
    path: "/companies/:companyId/groups",
    name: "Plants",
    icon: "ni ni-single-02 text-yellow",
    component: Groups,
    layout: "/v1",
    visible: false,
    id: 23,
  },
  {
    path: "/companies/:companyId/reports",
    name: "Plants",
    icon: "ni ni-single-02 text-yellow",
    component: Reports,
    layout: "/v1",
    visible: false,
    id: 24,
  },
  {
    path: "/companies/:companyId/sensors/add/qr",
    name: "Sensor Add",
    icon: "ni ni-single-02 text-yellow",
    component: SensorsAddQr,
    layout: "/v1",
    visible: false,
    id: 58,
  },
  {
    path: "/companies/:companyId/sensors/add",
    name: "Sensor Add",
    icon: "ni ni-single-02 text-yellow",
    component: SensorAdd,
    layout: "/v1",
    visible: false,
    id: 25,
  },
  {
    path: "/assignConfig/:companyId/:sensorId",
    name: "Config Add",
    icon: "ni ni-single-02 text-yellow",
    component: SensorAssignConfig,
    layout: "/v1",
    visible: false,
    id: 62,
  },
  {
    path: "/companies/:companyId/sensors/o/:sensorId",
    name: "Sensor Details",
    icon: "ni ni-single-02 text-yellow",
    component: SensorDetailsOldConfig,
    layout: "/v1",
    visible: false,
    id: 26,
  },
  {
    path: "/companies/:companyId/sensors/restore/:sensorId",
    name: "Sensor Restore",
    icon: "ni ni-single-02 text-yellow",
    component: SensorAdd,
    layout: "/v1",
    visible: false,
    id: 27,
  },
  {
    path: "/companies/:companyId/sensors/:sensorId/tags/:tagId",
    name: "Sensor Restore",
    icon: "ni ni-single-02 text-yellow",
    component: SensorDetails,
    layout: "/v1",
    visible: false,
    id: 28,
  },
  {
    path: "/companies/:companyId/sensors/:sensorId/tags",
    name: "Sensor Restore",
    icon: "ni ni-single-02 text-yellow",
    component: SensorTags,
    layout: "/v1",
    visible: false,
    id: 29,
  },
  {
    path: "/companies/:companyId/sensors/:sensorId",
    name: "Sensor Details",
    icon: "ni ni-single-02 text-yellow",
    component: SensorDetails,
    layout: "/v1",
    visible: false,
    id: 30,
  },
  {
    path: "/companies/:companyId/sensors",
    name: "Sensors",
    icon: "ni ni-single-02 text-yellow",
    component: Sensors,
    layout: "/v1",
    visible: false,
    id: 31,
  },
  {
    path: "/companies/:companyId/machines/:machineId",
    name: "Machine Details",
    icon: "fas fa-cogs text-green",
    component: MachineDetails,
    layout: "/v1",
    visible: false,
    id: 32,
  },
  {
    path: "/companies/:companyId/machines",
    name: "Machines",
    icon: "fas fa-cogs text-green",
    component: Machines,
    layout: "/v1",
    visible: false,
    id: 33,
  },
  {
    path: "/companies/edit/:companyId",
    name: "Company Profile",
    icon: "ni ni-single-02 text-yellow",
    component: CompanyProfile,
    layout: "/v1",
    visible: false,
    id: 34,
  },
  {
    path: "/profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: UserProfile,
    layout: "/v1",
    visible: false,
    id: 35,
  },
  {
    path: "/companies/:companyId",
    name: "Company Profile",
    icon: "ni ni-single-02 text-yellow",
    component: CompanyProfile,
    layout: "/v1",
    visible: false,
    id: 36,
  },

  // {
  //   path: "/machine-types/:machineTypeCode",
  //   name: "Machine Type Details",
  //   icon: "fas fa-cogs text-haze",
  //   component: MachineTypesDetails,
  //   layout: "/v1",
  //   visible: false,
  //   id: 37,
  // },
  {
    path: "/equipment-types/:equipmentTypeCode",
    name: "Equipment Types",
    icon: "ni ni-single-02 text-yellow",
    component: EquipmentTypesDetails,
    layout: "/v1",
    visible: false,
    id: 38,
  },
  // {
  //   path: "/equipment-sub-types/:equipmentSubTypeCode",
  //   name: "Equipment Sub Types",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: EquipmentSubTypeDetails,
  //   layout: "/v1",
  //   visible: false,
  //   id: 64,
  // },
  {
    path: "/component-types/:componentTypeCode",
    name: "Component Types",
    icon: "ni ni-single-02 text-yellow",
    component: ComponentTypesDetails,
    layout: "/v1",
    visible: false,
    id: 39,
  },
  // {
  //   path: "/technical-specifications/:code",
  //   name: "Technical Specifications",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: TechnicalSpecificationDetails,
  //   layout: "/v1",
  //   visibl: false,
  //   id: 40,
  // },
  {
    path: "/sensor-inventory/add",
    name: "Sensor Types",
    icon: "ni ni-single-02 text-yellow",
    component: SensorTypeAdd,
    layout: "/v1",
    visible: false,
    id: 41,
  },
  {
    path: "/sensor-inventory/:id",
    name: "Sensor Types",
    icon: "ni ni-single-02 text-yellow",
    component: SensorTypeDetails,
    layout: "/v1",
    visible: false,
    id: 42,
  },
  {
    path: "/firmware-types/add",
    name: "Firmware Types",
    icon: "ni ni-single-02 text-yellow",
    component: FirmwareAdd,
    layout: "/v1",
    visible: false,
    id: 43,
  },
  {
    path: "/firmware-types/:id",
    name: "Firmware Types",
    icon: "ni ni-single-02 text-yellow",
    component: FirmwareDetails,
    layout: "/v1",
    visible: false,
    id: 44,
  },
  {
    path: "/process-configs/add",
    name: "Process Configurations",
    icon: "ni ni-single-02 text-yellow",
    component: ProcessConfigAdd,
    layout: "/v1",
    visible: false,
    id: 45,
  },
  {
    path: "/process-configs",
    name: "Process Configurations",
    icon: "ni ni-single-02 text-yellow",
    component: ProcessConfigDetails,
    layout: "/v1",
    visible: false,
    id: 46,
  },
  {
    path: "/mount-configs/add",
    name: "Mount Configurations",
    icon: "ni ni-single-02 text-yellow",
    component: MountConfigAdd,
    layout: "/v1",
    visible: false,
    id: 47,
  },
  {
    path: "/mount-configs/:id",
    name: "Mount Configurations",
    icon: "ni ni-single-02 text-yellow",
    component: MountConfigDetails,
    layout: "/v1",
    visible: false,
    id: 48,
  },
  // {
  //   path: "/activities/:companyId/:sessionId",
  //   name: "Events",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: SessionEvents,
  //   layout: "/v1",
  //   visible: false,
  //   id: 49,
  // },
  // {
  //   path: "/activities/:companyId",
  //   name: "Activity Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: UserSessions,
  //   layout: "/v1",
  //   visible: false,
  //   id: 50,
  // },
  // {
  //   path: "/users/add",
  //   name: "User Add",
  //   icon: "fa fa-address-book text-orange",
  //   component: RootUserAdd,
  //   layout: "/v1",
  //   visible: false,
  //   id: 51,
  // },
  // {
  //   path: "/users/:id",
  //   name: "User Details",
  //   icon: "fa fa-address-book text-orange",
  //   component: RootUserDetails,
  //   layout: "/v1",
  //   visible: false,
  //   id: 52,
  // },
  {
    path: "/forgot-password",
    name: "Reset Password",
    icon: "fa fa-address-book text-orange",
    component: ForgotPassword,
    layout: "/auth",
    visible: false,
    id: 53,
  },
  // {
  //   path: "/reset-password/:reset_password_token",
  //   name: "Reset Password",
  //   icon: "fa fa-address-book text-orange",
  //   component: ResetPassword,
  //   layout: "/auth",
  //   visible: false,
  //   id: 54,
  // },
  // {
  //   path: "/change-password",
  //   name: "Change Password",
  //   icon: "fa fa-address-book text-orange",
  //   component: ChangePassword,
  //   layout: "/v1",
  //   visible: false,
  //   id: 55,
  // },
  {
    path: "/scanners",
    name: "Scanners",
    icon: "fa fa-address-book text-orange",
    component: GenericScanners,
    layout: "/v1",
    visible: false,
    id: 56,
  },
  {
    path: "/delayedCDP",
    name: "DelayedCDP",
    icon: "fa fa-address-book text-orange",
    component: DelayedCDP,
    layout: "/v1",
    visible: false,
    id: 62,
  },
  // {
  //   path: "/requestUsers",
  //   name: "RequestUsers",
  //   icon: "fa fa-address-book text-orange",
  //   component: RequestUser,
  //   layout: "/v1",
  //   visible: false,
  //   id: 63,
  // },
];

/**
 * Main sidebad routes
 */

var sidebarRoutes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-orange",
    component: Index,
    layout: "/v1",
    visible: true,
    id: 1,
  },

  //Organizations
  {
    path: "/organizations",
    name: "Organizations",
    icon: "fa fa-university text-orange",
    component: Organizations,
    layout: "/v1",
    visible: true,
    id: 66,
  },

  {
    path: "/companies",
    name: "Companies",
    icon: "fa fa-university text-orange",
    component: Companies,
    layout: "/v1",
    visible: true,
    id: 10,
  },
  {
    path: "/sensor-inventory",
    name: "Sensor Types",
    icon: "fa fa-server text-orange",
    component: SensorTypes,
    layout: "/v1",
    visible: true,
    id: 2,
  },
  {
    path: "/firmware-types",
    name: "Firmware Types",
    icon: "fa fa-microchip text-orange",
    component: FirmwareTypes,
    layout: "/v1",
    visible: true,
    id: 3,
  },
  {
    path: "/process-configs",
    name: "Process Configurations",
    icon: "fa fa-cog text-orange",
    component: ProcessConfigs,
    layout: "/v1",
    visible: true,
    id: 4,
  },
  {
    path: "/mount-configs",
    name: "Mount Configurations",
    icon: "fa fa-arrows-alt text-orange",
    component: MountConfigs,
    layout: "/v1",
    visible: true,
    id: 5,
  },
  // {
  //   path: "/machine-types",
  //   name: "Machine Types",
  //   icon: "fas fa-cogs text-orange",
  //   component: MachineTypes,
  //   layout: "/v1",
  //   visible: true,
  //   id: 6,
  // },
  {
    path: "/equipment-types",
    name: "Equipment Types",
    icon: "fas fa-list-ul text-orange",
    component: EquipmentTypes,
    layout: "/v1",
    visible: true,
    id: 7,
  },
  // {
  //   path: "/equipment-sub-types",
  //   name: "Equipment Sub Types",
  //   icon: "fas fa-list-ul text-orange",
  //   component: EquipmentSubTypes,
  //   layout: "/v1",
  //   visible: true,
  //   id: 13,
  // },
  {
    path: "/component-types/",
    name: "Component Types",
    icon: "fas fa-list-alt text-orange",
    component: ComponentTypes,
    layout: "/v1",
    visible: true,
    id: 8,
  },
  // {
  //   path: "/technical-specifications",
  //   name: "Technical Specifications",
  //   icon: "fa fa-code text-orange",
  //   component: TechnicalSpecifications,
  //   layout: "/v1",
  //   visible: true,
  //   id: 9,
  // },

  // {
  //   path: "/activities",
  //   name: "Activities",
  //   icon: "fa fa-anchor text-orange",
  //   component: Activites,
  //   layout: "/v1",
  //   visible: true,
  //   id: 11,
  // },
  // {
  //   path: "/users",
  //   name: "Employee Management",
  //   icon: "fa fa-address-book text-orange",
  //   component: RootUsers,
  //   layout: "/v1",
  //   visible: true,
  //   id: 12,
  // },
  {
    path: "/notification",
    name: "Notification",
    icon: "fa fa-code text-orange",
    component: NotificationMain,
    layout: "/v1",
    visible: true,
    id: 9,
  },
  // {
  //   path: "/qrsensor-inventory",
  //   name: "QR Sensor Types",
  //   icon: "fa fa-server text-orange",
  //   component: QRSensorTypes,
  //   layout: "/v1",
  //   visible: true,
  //   id: 13
  // }

  //OrganizationProfile
  {
    path: "/organizationdetail/:organizationId",
    name: "Organization Profile",
    icon: "ni ni-single-02 text-yellow",
    component: OrganizationProfile,
    layout: "/v1",
    visible: false,
    id: 67,
  },
  {
    path: "/organizationdetail/edit/:organizationId",
    name: "Organization Profile",
    icon: "ni ni-single-02 text-yellow",
    component: OrganizationProfile,
    layout: "/v1",
    visible: false,
    id: 68,
  },
  //CompanyList
  {
    path: "/organizationdetails/:organizationId/companyList",
    name: "CompanyList",
    icon: "ni ni-single-02 text-yellow",
    component: CompanyList,
    layout: "/v1",
    visible: false,
    id: 69,
  },
  {
    //OrganizationGroup
    path: "/organizationdetails/:organizationId/groups",
    name: "Plants",
    icon: "ni ni-single-02 text-yellow",
    component: OrganizationGroup,
    layout: "/v1",
    visible: false,
    id: 70,
  },
  // {
  //   //OrganizationMachine
  //   path: "/organizationdetails/:organizationId/machines",
  //   name: "Machines",
  //   icon: "fas fa-cogs text-green",
  //   component: OrganizationMachine,
  //   layout: "/v1",
  //   visible: false,
  //   id: 71,
  // },
  // {
  //   path: "/organizationdetails/:organizationId/company/equipment",
  //   name: "OrganizationEquipment",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: OrganizationEquipment,
  //   layout: "/v1",
  //   visible: false,
  //   id: 72,
  // },
  //OrganizationCompanyProfile
  {
    path: "/organizationdetails/:organizationId/company/:companyId",
    name: "Equipments",
    icon: "ni ni-single-02 text-yellow",
    component: OrganizationCompanyProfile,
    layout: "/v1",
    visible: false,
    id: 73,
  },
  // {
  //   path: "/organizationdetails/:organizationId/users",
  //   name: "OrganizationUser",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: OrganizationUser,
  //   layout: "/v1",
  //   visible: false,
  //   id: 74,
  // },
  // {
  //   path: "/organizationdetails/:organizationId/sensors",
  //   name: "OrganizationSensor",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: OrganizationSensor,
  //   layout: "/v1",
  //   visible: false,
  //   id: 75,
  // },
  // {
  //   path: "/organizationdetails/:organizationId/notification",
  //   name: "OrganizationNotification",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: OrganizationNotification,
  //   layout: "/v1",
  //   visible: false,
  //   id: 76,
  // },

  // {
  //   path: "/organizationdetails/:organizationId/feedback",
  //   name: "OrganizationFeedback",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: OrganizationFeedback,
  //   layout: "/v1",
  //   visible: false,
  //   id: 77,
  // },
];

routes = routes.concat(sidebarRoutes);

export default routes;
