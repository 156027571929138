import React from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import _ from 'lodash';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
// core components

import axios from "../../../axios";
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";

import { ToastContainer, toast } from "react-toastify";
import { fetchEquipmentTypeDetails } from "../../../redux/actions/equipmentType.action";
import 'react-toastify/dist/ReactToastify.css';
import validation from './validation';


let equipmentTypeCode = "";
let portal_user = "";

// const options = [
//     { value: 'motor', label: 'motor' },
//     { value: 'pump', label: 'pump' },
//   ];

class EquipmentTypeDetails extends React.Component {

  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump('/auth/login') : null;

    portal_user = JSON.parse(localStorage.getItem('portal-user'));
  }


  state = {
    equipmentType: {},
    equipmentTypeBackup: {},
    readOnly: true,
    optData: [],
    defaultOption: 0,
    defaultOptionBackup: 0,
    saving: false,
    createAllowed: true
  };

  async componentDidMount() {
    

    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != 'admin') {
      this.setState({'createAllowed': false});
    }

    equipmentTypeCode = this.props.match.params.equipmentTypeCode;
    // await this.getEquipmentTypes();
    this.props.fetchEquipmentTypeDetails(equipmentTypeCode);
  }


  async getEquipmentTypes() {
    let api = await axios.getData( '/equipments/types/' + equipmentTypeCode);
    let data = api.data.data;
    this.setState({equipmentType: data, equipmentTypeBackup: data});
  }

  handleChange = input => (e) => {
    let equipmentType = {...this.state.equipmentType};
    equipmentType[input] = e.target.value;
    this.setState({equipmentType: equipmentType});
  }

  handleEdit(edit) {
    this.setState({readOnly: edit});
  }

  handleCancel() {
    let equipmentTypeBackup = {...this.state.equipmentTypeBackup};
    this.setState({equipmentType: equipmentTypeBackup, defaultOption: this.state.defaultOptionBackup, readOnly: true})
  }

  handleSelectChange = () => (option) => {
      let equipmentType = {...this.state.equipmentType};
      equipmentType.equipmentTypeMetaCode = option.value
      this.setState({equipmentType: equipmentType, defaultOption: option.key});
  };

  async handleSave() {
    this.setState({saving: true});
    let equipmentType = {
      ...this.state.equipmentType
    };

    //Validating
    let err = await validation.validate(equipmentType);
    if (err) {
      toast.warn(err);
      this.setState({saving: false});
      return;
    }


    delete equipmentType._id;
    let api = await axios.saveEquipmentType(equipmentType, this.state.equipmentType._id);
    // console.log(api)
    if (api.status === 200) {
      toast.success('Equipment type saved successfully');
      this.setState({readOnly: true});
      this.jump('/v1/equipment-types', 1000);
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error('Something went wrong.Please try again.')
    }
    this.setState({saving: false});
  }

  jump = (url, delay=0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.equipTypeDetailsData !== prevState.equipTypeDetailsData) {
      if (nextProps.equipTypeDetailsData) {
        return {
          equipmentType: nextProps.equipTypeDetailsData.data,
          equipmentTypeBackup: nextProps.equipTypeDetailsData.data
        };
      }
    }
  }

  render() {

    return (
      <>
        <ToastContainer position="top-left"/>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">

                <div onClick={() => { this.jump("/v1/equipment-types", 0) } } style={{width:'2rem', height: "2rem", cursor: 'pointer'}} className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow">
                  <i style={{fontSize:'25px'}} className="fa fa-arrow-left" aria-hidden="true"></i>
                </div>


                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Equipment Type Details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {
                        this.state.readOnly && this.state.createAllowed &&
                        <Button
                          color="primary"
                          onClick={() => this.handleEdit(false)}
                          size="md"
                        >
                          Edit
                        </Button>
                      }
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipment Type <span className="text-danger">*</span>
                            </label>
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              value={this.state.equipmentType.customName}
                              onChange={this.handleChange('customName')}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              About Equipment Type
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-about-company"
                              // placeholder="A few words about company ..."
                              rows="8"
                              type="textarea"
                              value={this.state.equipmentType.description}
                              onChange={this.handleChange('description')}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                          
                          {
                            !this.state.readOnly &&
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              { 
                                this.state.saving ?
                                <i class="fas fa-spinner fa-pulse"></i> :
                                'Save'
                              }
                            </Button>
                          }

                          {             
                            !this.state.readOnly && !this.state.saving &&
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.handleCancel()}
                              size="md"
                            >
                              Cancel
                            </Button>
                          }


                        </Col>

                      </Row>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  equipTypeDetailsData: _.isEmpty(state.equipmentType.equipTypeDetailsData)? null : state.equipmentType.equipTypeDetailsData
});

const mapDispatchToProps = dispatch => {
  return {
    fetchEquipmentTypeDetails: (id) => dispatch(fetchEquipmentTypeDetails(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentTypeDetails);

// export default EquipmentTypeDetails;
