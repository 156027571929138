import {
  FETCH_MAINTENANCE_TYPES_DATA,
  FETCH_FEEDBACK_LOG_DATA,
} from "../actions/feedback.action";

const feedbackReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MAINTENANCE_TYPES_DATA:
      return {
        ...state,
        maintenanceTypesData: action.payload,
      };
    case FETCH_FEEDBACK_LOG_DATA:
      return {
        ...state,
        feedbackLogData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default feedbackReducer;
