import CallAPI from "../../axios";

export const FETCH_PROCESS_CONFIGS_DATA = "FETCH_PROCESS_CONFIGS_DATA";

const fetchData = (data) => {
  return {
    type: FETCH_PROCESS_CONFIGS_DATA,
    payload: data,
  };
};

export const fetchProcessConfigData = (id) => {
  return (dispatch) => {
    CallAPI.get_Process_config()
      .then((response) => {
        response && dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
