import CallAPI from "../../axios";

export const FETCH_MOUNT_TYPES = "FETCH_MOUNT_TYPES";
export const FETCH_MOUNT_TYPES_DETAILS = "FETCH_MOUNT_TYPES_DETAILS";

const fetchData = (data) => {
  return {
    type: FETCH_MOUNT_TYPES,
    payload: data,
  };
};

const fetchMountTypeDetailsData = (data) => {
  return {
    type: FETCH_MOUNT_TYPES_DETAILS,
    payload: data,
  };
};

export const fetchMountType = () => {
  return (dispatch) => {
    CallAPI.mountConfig_List()
      .then((response) => {
        response && dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchMountTypeDetails = (id) => {
  return (dispatch) => {
    CallAPI.mountConfig_details(id)
      .then((response) => {
        dispatch(fetchMountTypeDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
