import * as yup from "yup";

const validations = {
  async validate(object) {
    let error = "";
    let schema = yup.object().shape({
      companyName: yup
        .string()
        .required()
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for Company Name field "
        )
        .min(4)
        .label("Company Name"),
      companyId: yup
        .string()
        .required()
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for Company Code field "
        )
        .length(4)
        .uppercase()
        .label("Company Code"),
      customName: yup
        .string()
        .required()
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for Custom Name field "
        )
        .min(3)
        .label("Custom Name"),
      headQuarters: yup
        .string()
        .required()
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for Headquarter field "
        )
        .min(3)
        .label("Headquarter"),
      Layout: yup.number().required().label("Layout"),
      aboutCompany: yup
        .string()
        .required()
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for About Company field "
        )
        .min(3)
        .label("About Company"),
      // Layout: yup.number().required().label("Layout"),
      timezone: yup.string().label("Timezone"),
      temperature: yup.string().label("Temperature"),
    });

    //validating against schema
    try {
      console.log({ object });
      await schema.validate(object, { abortEarly: false, strict: true });
    } catch (err) {
      error = err.errors[0];
    }
    return error;
  },
};

export default validations;
