import React from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ifvisible from "ifvisible.js";
import $ from "jquery";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components

import UserHeader from "components/Headers/Header.jsx";
import Helper from "../../../../helper";
import axios from "../../../../axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let portalUser = {};
let companyId = "";

class SensorAdd extends React.Component {
  state = {
    body: {
      sensorId: "",
    },
    readOnly: true,
    saving: false,
  };

  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }

    //Binding methods
    // this.handleCancel = this.handleCancel.bind(this);
    // this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    // await this.parseUser();
  }

  parseUser() {
    let user = JSON.parse(localStorage.getItem("portal-user"));
    let userBackup = JSON.parse(localStorage.getItem("portal-user"));
    this.setState({ user, userBackup });
  }

  handleChange = (state, key) => (e) => {
    let subject = { ...this.state[state] };
    if (key === "dob") {
      try {
        let moment = e.format("DD/MMMM/YYYY");
        subject[key] = moment;
      } catch (err) {
        subject[key] = "";
      }
    } else {
      Helper.reduce(subject, key, e.target.value);
    }
    this.setState({ [state]: subject });
  };

  handleCancel = () => {
    this.setState({
      user: this.state.userBackup,
      readOnly: true,
    });
  };

  handleSave = async () => {
    this.setState({ saving: true });
    if (!this.state.body.sensorId) {
      toast.error("SensorId is required   ");
    }

    let body = {
      sensorId: this.state.body.sensorId,
      companyId: companyId,
    };

    let api = await axios.postData("/sensors/qr", body);
    if (api.status === 200) {
      toast.success("Sensor added successfully");
      localStorage.removeItem("companyId");
      this.jump(`/v1/companies/${companyId}/sensors`, 1000);
    } else if (api.status === 400) {
      toast.error(api.data.message);
    } else {
      toast.error("Something went wrong. Please try again..");
    }
    this.setState({ saving: false });
  };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    var today = moment();
    var valid = function (current) {
      return current.isBefore(today);
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6 ">
              <div
                onClick={() => {
                  this.jump("/v1/companies/" + companyId + "/sensors", 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
            </Col>
            <Col lg="6 table-caption">
              <h1>Add Sensor</h1>
            </Col>
          </Row>

          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0"></CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        {/* <Col lg="2"></Col> */}
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              SensorId
                            </label>{" "}
                            <span className="text-danger">*</span>
                            <Input
                              className="form-control-alternative"
                              onChange={this.handleChange("body", "sensorId")}
                              id="input-username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => {
                                this.jump("/v1/users", 0);
                              }}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SensorAdd;
