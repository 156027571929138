import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import axios from '../../../axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import MaterialIcon, {colorPalette} from 'material-icons-react';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

import { fetchMountType } from "../../../redux/actions/mountConfiguration.action";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";

const { SearchBar } = Search;

const options = {
  showTotal: true
}

let portal_user = "";

class MountConfigs extends React.Component {

  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump('/auth/login') : null;

    portal_user = JSON.parse(localStorage.getItem('portal-user'));
  }

  state = {
    mountConfigs: null,
    list: true,
    hardware: {},
    createAllowed: true,
    columns: [
      {
        text: 'Id',
        dataField: '_id',
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump('/v1/mount-configs/' + row._id);
          }
        }
      },
      {
        text: 'Mount Configs',
        dataField: 'mountConfig',
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump('/v1/mount-configs/' + row._id);
          }
        }
      },
      {
        text: 'Mount Types',
        dataField: 'label',
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump('/v1/mount-configs/' + row._id);
          }
        }
      },
      {
        text: 'Actions',
        formatter: this.actionFormatter.bind(this),
        hidden: false
      }
    ]
  }

  actionFormatter(cell, row, rowIndex, extraData) {
    return(
      <MaterialIcon 
        onClick={this.handleDelete.bind(this, row)}
        icon="delete"
        color='red'
      ></MaterialIcon>
    );
  }

  async componentDidMount() {
    
    //Checking Authorization
    let slug = portal_user && portal_user.department && portal_user.department.slug;
    if (slug != 'admin') {

      //Hiding action column from table for normal users
      let col = [...this.state.columns];
      col[3].hidden = true;

      this.setState({'createAllowed': true, columns: col});
    }

    // await this.getmountConfigs();
    this.props.fetchMountType();
  }

  async getmountConfigs() {
    let api = await axios.getData('/mount-configs');
    let mountConfigs = api.data.data;
    this.setState({mountConfigs});
  }

  handleTextChange = (state, key=null) => (e) => {
    let subject = {...this.state[state]};
    subject[key] = e.target.value;
    this.setState({[state]: subject});
  }

  async handleDelete(mountConfig) {
    if (window.confirm("Do you really want to delete it?")) {
      //Calling backend API to delelte firmwareTypes
      let api = await axios.deleteReq('/mount-configs/' + mountConfig._id);
      if (api.status === 200) {
        //Remove from state
        let index = this.state.mountConfigs.findIndex(x => x._id === mountConfig._id);
        let mountConfigs = [...this.state.mountConfigs];
        mountConfigs.splice(index, 1);
        this.setState({mountConfigs: mountConfigs});
        toast.success("Data deleted successfully");
      }

      if (api.status === 400) {
        toast.error(api.data.message)
      }

      if (api.status === 500) {
        toast.error("Something went wrong. Please try again");
      }

    }
  }

  jump(url) {
    this.props.history.push(url);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.mountConfigurationData !== prevState.mountConfigurationData) {
      if (nextProps.mountConfigurationData) {
        return {
          mountConfigs: nextProps.mountConfigurationData.data
        };
      }
    }
  }

  render() {

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump('/v1/mount-configs/' + row._id);
      }
    };
      
    return (
      <>
        <ToastContainer position="top-left"/>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {
                this.state.createAllowed && 
                <Button
                  color="success"
                  onClick={() => {
                    this.jump(`/v1/mount-configs/add`);
                  }}
                  size="md"
                  className='add-btn'
                  >
                  Add Mount Configuration
                </Button>
              }
            </Col>
            <Col lg="6 table-caption">
                <h1>Mount Configurations</h1>
            </Col>
          </Row>
         
          {/* Table */}
          <Row> 
            <div className="col">          
              <Card className="bg-secondary shadow">
              {
                this.state.mountConfigs && this.state.list && 
                <ToolkitProvider
                  search
                >
                  {
                    props => (
                      <div>
                          <SearchBar { ...props.searchProps } />
                          <BootstrapTable
                            { ...props.baseProps }
                            keyField='_id'
                            data={this.state.mountConfigs}
                            columns={this.state.columns}
                            pagination={ paginationFactory(options) }
                            striped
                            hover
                          />
                      </div>
                      )
                  }
                </ToolkitProvider>
              }
              
              </Card>
            </div>
          </Row>
          
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  mountConfigurationData: _.isEmpty(state.mountConfiguration.mountTypeData)? null : state.mountConfiguration.mountTypeData
});

const mapDispatchToProps = dispatch => {
  return {
    fetchMountType: () => dispatch(fetchMountType())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MountConfigs);