import {
  FETCH_EMPLOPEES_DATA,
  FETCH_EMPLOPEES_DETAILS_DATA,
  FETCH_DEPARTMENTS_DATA,
} from "../actions/employee.action";

const employeeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EMPLOPEES_DATA:
      return {
        ...state,
        employeeData: action.payload,
      };
    case FETCH_EMPLOPEES_DETAILS_DATA:
      return {
        ...state,
        employeeDetailsData: action.payload,
      };
    case FETCH_DEPARTMENTS_DATA:
      return {
        ...state,
        departmentData: action.payload,
      };
    default:
      return state;
  }
};
export default employeeReducer;
