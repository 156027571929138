import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  fetchCompanyDetails,
  fetchNotifCount,
  fetchThemeConfig,
  fetchLoginThemeConfig,
} from "../../../redux/actions/companies.action";
import { fetchMaintenanceTypes } from "../../../redux/actions/feedback.action";
import axios from "../../../axios";
import * as yup from "yup";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import ct from "countries-and-timezones";
import Select from "react-select";
import { Tabs as TabsAnt } from "antd";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ColorPicker from "material-ui-color-picker";
import ImageUploader from "react-images-upload";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { v4 as uuidv4 } from "uuid"; // reactstrap components
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components

import Header from "components/Headers/Header.jsx";
import Tiles from "./Tiles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import validation from "./validations";
import Helper from "../../../helper";
import { uniqueId } from "lodash";
// const uuidv4 = require('uuid/v4');
import MomentUtils from "@date-io/moment";

import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import GraphShimmer from "../../../components/Shimmer/GraphShimmer";
const { TabPane } = TabsAnt;
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

var cards = [
  {
    title: "Users",
    count: 0,
    icon: "fas fa-users",
    key: "users",
    path: "users",
  },
  {
    title: "Plants",
    count: 0,
    icon: "fas fa-object-group",
    key: "groups",
    path: "groups",
  },
  {
    title: "Machines",
    count: 0,
    icon: "fas fa-cogs",
    key: "machines",
    path: "machines",
  },
  {
    title: "Equipments",
    count: 0,
    icon: "fas fa-rocket",
    key: "equipments",
    path: "machines/0000/equipments",
  },
  {
    title: "Sensors",
    count: 0,
    icon: "fas fa-microchip",
    key: "sensors",
    path: "sensors",
  },
  // {
  //   title: 'Reports',
  //   count: 0,
  //   icon: 'fas fa-microchip',
  //   key: 'reports',
  //   path: 'reports'
  // },
  // {
  //   title: "Scanner Data",
  //   count: 0,
  //   icon: "fa fa-qrcode",
  //   key: "scanners",
  //   path: "scanners",
  // },
  // {
  //   title: 'Config',
  //   count: 0,
  //   icon: 'fa fa-qrcode',
  //   key: 'config',
  //   path: 'config',
  // },
  // {
  //   title: 'Theme Config',
  //   count: 0,
  //   icon: 'fa fa-qrcode',
  //   key: 'themeConfig',
  //   path: 'themeConfig',
  // },
  // {
  //   title: 'Login Theme Config',
  //   count: 0,
  //   icon: 'fa fa-qrcode',
  //   key: 'loginTheme',
  //   path: 'loginTheme',
  // },
];

var cardsWithNotification = [
  {
    title: "Users",
    count: 0,
    icon: "fas fa-users",
    key: "users",
    path: "users",
  },
  {
    title: "Plants",
    count: 0,
    icon: "fas fa-object-group",
    key: "groups",
    path: "groups",
  },
  {
    title: "Machines",
    count: 0,
    icon: "fas fa-cogs",
    key: "machines",
    path: "machines",
  },
  {
    title: "Equipments",
    count: 0,
    icon: "fas fa-rocket",
    key: "equipments",
    path: "machines/0000/equipments",
  },
  {
    title: "Sensors",
    count: 0,
    icon: "fas fa-microchip",
    key: "sensors",
    path: "sensors",
  },
  {
    title: "FeedBack",
    count: 0,
    icon: "fa fa-book",
    key: "feedback",
    path: "feedback",
  },
  // {
  //   title: 'Reports',
  //   count: 0,
  //   icon: 'fas fa-microchip',
  //   key: 'reports',
  //   path: 'reports'
  // },
  // {
  //   title: "Scanner Data",
  //   count: 0,
  //   icon: "fa fa-qrcode",
  //   key: "scanners",
  //   path: "scanners",
  // },
  {
    title: "Notification",
    count: 0,
    icon: "fa fa-qrcode",
    key: "notification",
    path: "notification",
  },
  // {
  //   title: 'Config',
  //   count: 0,
  //   icon: 'fa fa-qrcode',
  //   key: 'config',
  //   path: 'config',
  // },
  // {
  //   title: 'Theme Config',
  //   count: 0,
  //   icon: 'fa fa-qrcode',
  //   key: 'themeConfig',
  //   path: 'themeConfig',
  // },
  // {
  //   title: 'Login Theme Config',
  //   count: 0,
  //   icon: 'fa fa-qrcode',
  //   key: 'loginTheme',
  //   path: 'loginTheme',
  // },
  {
    title: "Feedback Config Manual",
    count: 0,
    icon: "fa fa-qrcode",
    key: "feedbackConfigManual",
    path: "feedbackConfigManual",
  },
];

var cardsRootAllowed = [
  {
    title: "Users",
    count: 0,
    icon: "fas fa-users",
    key: "users",
    path: "users",
  },
  {
    title: "Plants",
    count: 0,
    icon: "fas fa-object-group",
    key: "groups",
    path: "groups",
  },
  {
    title: "Machines",
    count: 0,
    icon: "fas fa-cogs",
    key: "machines",
    path: "machines",
  },
  {
    title: "Equipments",
    count: 0,
    icon: "fas fa-rocket",
    key: "company/equipment",
    path: "company/equipment",
  },
  {
    title: "Sensors",
    count: 0,
    icon: "fas fa-microchip",
    key: "sensors",
    path: "sensors",
  },
  {
    title: "Notification",
    count: 0,
    icon: "fa fa-qrcode",
    key: "notification",
    path: "notification",
  },
  {
    title: "Config",
    count: 0,
    icon: "fa fa-qrcode",
    key: "config",
    path: "config",
  },
  {
    title: "FeedBack",
    count: 0,
    icon: "fa fa-book",
    key: "feedback",
    path: "feedback",
  },
  {
    title: "Theme Config",
    count: 0,
    icon: "fa fa-qrcode",
    key: "themeConfig",
    path: "themeConfig",
  },
  {
    title: "Login Theme Config",
    count: 0,
    icon: "fa fa-qrcode",
    key: "loginTheme",
    path: "loginTheme",
  },
  {
    title: "Feedback Config Manual",
    count: 0,
    icon: "fa fa-qrcode",
    key: "feedbackConfigManual",
    path: "feedbackConfigManual",
  },
];

let stats = {};
let companyId = "";
let portal_user = "";

const Vibration_Units = [
  { value: "acc", label: "Acceleration (g)" },
  { value: "vel", label: "Velocity (mm/sec)" },
  { value: "dis", label: "Displacement (micron)" },
  { value: "vel-inch", label: "Velocity (in/sec)" },
  { value: "dis-milli", label: "Displacement (milli)" },
];

const Vibration_TimeDomains = [
  { value: "time", label: "Time Domain" },
  { value: "cycle", label: "Order Domain" },
  { value: "envelope", label: "Envelope" },
  { value: "denoised", label: "Denoised Time Domain" },
];

const Vibration_OrderDomains = [
  { value: "time", label: "Frequency Domain" },
  { value: "order", label: "Order Domain" },
  { value: "envelope-order", label: "Envelope Order Domain" },
  { value: "envelope", label: "Envelope Frequency Domain" },
  { value: "cycle", label: "Cycles/minute (CPM)" },
  { value: "denoised", label: "Denoised Frequency Domain" },
];

const Vibration_Waterfall_Domains = Vibration_OrderDomains.slice(0, 2);

class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);

    companyId = this.props.match.params.companyId;

    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  state = {
    languageList: [],
    layout: [
      { value: 0, label: "Equipment" },
      { value: 1, label: "Machine" },
    ],
    company: {
      temperature: "C",
      notificationToBeChecked: true,
      active: true,
    },
    companyConfig: {
      dailyConsolidatedAlarm: {
        active: false,
        directMessageCodes: {},
        includeDirectNotification: false,
        numberOfBatchNotificationPerDay: 0,
        smsNotification: false,
      },
      dashboard_link: "",
      dataExport: {
        active: false,
        key: "",
        url: "",
      },
      defaultLang: {
        key: "defaultLang",
        label: "English",
        langType: "eng",
      },
      demo: false,
      exportDataToPtc: false,
      healthSummaryDashboard: false,
      hyperlink_link: "",
      idleTimeConfig: {
        rangeColor: [
          {
            color: "#7ada2c",
            idleDuration: "0-30",
          },
          {
            color: "#f0c82a",
            idleDuration: "31-60",
          },
          {
            color: "#f57c00",
            idleDuration: "61-90",
          },
          {
            color: "#fa0011",
            idleDuration: ">90",
          },
          {
            color: "#BDBDBD",
            idleDuration: "No Data",
          },
        ],
        type: "lastRunning",
      },
      maintenanceLog: false,
      notificationConfig: {
        batteryNotification: false,
        batteryNotificationThreshold: 2.5,
      },
      notificationToBeChecked: false,
      overallrms: false,
      s3Export: {
        active: false,
        bucket: "",
      },
      secondLang: {
        key: "secondLang",
        label: "English",
        langType: "eng",
      },
      sensorHealthDashboard: false,
      sensorStatsFrequencyInHours: 96,
      sensorStatsLastCheckedTime: 1661799908,
      soundHPF: false,
      thresholdSharing: {
        active: false,
        keyList: [],
      },
      timezone: "Africa/Addis_Ababa",
      unit: "metric",
    },

    currentTabIndex: 0,
    thresholdSharingTypes: [
      {
        key: 0,
        label: "RMS Acceleration",
        value: "rms_acc",
      },
      {
        key: 1,
        label: "RMS Velocity ",
        value: "rms_vel",
      },
      {
        key: 2,
        label: "RMS Displacement",
        value: "rms_dis",
      },
      {
        key: 3,
        label: "Temperature",
        value: "temperature",
      },
      {
        key: 4,
        label: "RMS AE",
        value: "rms_ae",
      },
    ],
    companyBackup: {},
    readOnly: true,
    loading: true,
    saving: false,
    timezone: null,
    defaultTimezone: "",
    configOpen: false,
    themeConfigOpen: false,
    loginThemeConfigOpen: false,
    codesOpen: false,
    open: false,
    frequency: [
      {
        value: 1,
        label: "1 Per Day",
      },
      {
        value: 2,
        label: "2 Per Day",
      },
      {
        value: 4,
        label: "4 Per Day",
      },
      {
        value: 6,
        label: "6 Per Day",
      },
    ],
    selectedFrequency: -1,
    saving: false,
    sensorsUploadFrequency: [],
    sensorsUploadFrequencyError: [],
    createAllowed: false,
    onlyRootAllowed: true,
    notificationCount: 0,
    isDynamicDashboardDetails: false,
    isNameInFooter: false,
    IsHyperlinkActive: true,
    bucket: "",
    isS3Export: false,
    imageUrl: null,
    fileName: null,
    loginBackGroundImageUrl: null,
    loginBackGroundFileName: null,
    loginLogoImageUrl: null,
    loginLogoFileName: null,
    Dashboardtittle: null,
    DashboardUrl: null,
    das_backgroundColor: "#000",
    das_textColor: "#000",
    unitTypes: [
      {
        key: 0,
        label: "Metric",
        value: "metric",
      },
      {
        key: 1,
        label: "Imperial",
        value: "imperial",
      },
    ],
    numberNotification: [
      {
        key: 0,
        label: "once a day",
        value: 1,
      },
      {
        key: 1,
        label: "twice a day",
        value: 2,
      },
      {
        key: 2,
        label: "thrice a day",
        value: 3,
      },
    ],
    CDPCount: [
      {
        key: 0,
        label: "One",
        value: 1,
      },
      {
        key: 1,
        label: "Two",
        value: 2,
      },
      {
        key: 2,
        label: "Three",
        value: 3,
      },
      {
        key: 3,
        label: "Four",
        value: 4,
      },
      {
        key: 4,
        label: "Five",
        value: 5,
      },
      {
        key: 5,
        label: "Six",
        value: 6,
      },
      {
        key: 6,
        label: "Seven",
        value: 7,
      },
      {
        key: 7,
        label: "Eight",
        value: 8,
      },
      {
        key: 8,
        label: "Nine",
        value: 9,
      },
      {
        key: 9,
        label: "Ten",
        value: 10,
      },
    ],
    faultStages: [
      {
        key: 0,
        label: "1",
        value: 1,
      },
      {
        key: 1,
        label: "2",
        value: 2,
      },
      {
        key: 2,
        label: "3",
        value: 3,
      },
      {
        key: 3,
        label: "4",
        value: 4,
      },
    ],
    startTime: {
      alarmStartTimeString: moment(
        moment().format("YYYY-MM-DD") + " " + "08:01"
      ),
      alarmStartTime: "08:01",
    },
    statsTemp: null,
    themeConfig: {
      primaryTextColor: "#223141",
      secondaryTextColor: "#3F7C6F",
      bodyTextColor: "#636466",
      primaryBackgroundColor: "#223141",
      secondaryBackgroundColor: "#3F7C6F",
      primaryButtonColor: "#223141",
      secondaryButtonColor: "#3F7C6F",
      tertiaryButtonColor: "#636466",
      linkButtonColor: "#636466",
      primaryButtonTextColor: "#223141",
      secondaryButtonTextColor: "#3F7C6F",
      tertiaryButtonTextColor: "#636466",
      linkButtonTextColor: "#636466",
      primaryButtonOnHoverColor: "#223141",
      secondaryButtonOnHoverColor: "#3F7C6F",
      primaryColor: "#223141",
      secondaryColor: "#3F7C6F",
      tertiaryColor: "#636466",
      primaryGraphColor: "#223141",
      secondaryGraphColor: "#3F7C6F",
    },
    loginThemeConfig: {
      backgroundImageFile: null,
      backgroundImageFileName: null,
      logoImageFile: null,
      logoImageFileName: null,
      domainName: "",
      primaryTextColor: "#223141",
      bodyTextColor: "#636466",
      primaryBackgroundColor: "#223141",
      secondaryBackgroundColor: "#3F7C6F",
      primaryButtonColor: "#223141",
    },
    isFeedbackConfigManual: false,
    maintenanceTypeList: [],
  };

  fetchNotificationCodes = async () => {
    // const api = await axios.getData('/notificationCodes');
    // let arr = [];
    // api.data &&
    //   api.data.map((x, i) => {
    //     let obj = {
    //       key: i,
    //       label: x,
    //       value: x,
    //     };
    //     arr.push(obj);
    //   });

    // this.setState({ notificationCodes: arr });
    const api = await axios.getData("/notificationCodes/notifcationCategories");

    let notificationCodesTemp = [];
    api.data &&
      api.data.map((x, i) => {
        Object.keys(x.codes).map((y, j) => {
          let obj = {
            key: y,
            appId: x.appId,
            code: x.codes[y],
            apply: false,
            id: i + "_" + j,
          };
          if (y === "code_fault_customer") {
            obj.faultStage = null;
            obj.rul = null;
          }
          notificationCodesTemp.push(obj);
        });
      });

    this.setState({ notificationCodes: api.data, notificationCodesTemp });
  };

  async componentDidMount() {
    // let api = await axios.getCompanies(companyId);
    // let data = api.data.data.company;
    // this.setState({company: data, companyBackup: data});
    // alert(JSON.stringify(languageList))
    await this.fetchNotificationCodes();
    await this.props.fetchMaintenanceTypes(companyId);
    this.props.fetchCompanyDetails(companyId);
    this.props.fetchNotifCount(companyId, false);
    this.props.fetchThemeConfig(companyId);
    this.props.fetchLoginThemeConfig(companyId);

    let companyIdStorage = localStorage.getItem("companyId");

    if (companyIdStorage === companyId) {
      let statsStorage = JSON.parse(localStorage.getItem("stats"));
      let companyStorage = JSON.parse(localStorage.getItem("company"));
      stats = statsStorage;
      let index =
        this.state.timezones &&
        this.state.timezones.findIndex(
          (x) => x.value === companyStorage.timezone
        );
      this.setState({
        company: companyStorage,
        companyBackup: companyStorage,
        loading: false,
        defaultTimezone: index >= 0 ? index : "",
      });
    } else {
      this.props.fetchCompanyDetails(companyId);
    }

    //Checking Authorization
    let slug = portal_user && portal_user.department.slug;
    // onlyRootAllowed
    console.log(slug, "********slug*********");
    if (slug === "root") {
      this.setState({ onlyRootAllowed: true });
    }
    if (["hardware", "admin"].includes(slug)) {
      //Allowing to Change Upload Frequency to hardware group users
      this.setState({ createAllowed: true });
    }
  }

  getThemeConfig = async () => {
    const theme = await axios.getData("/companies/theme/" + companyId);
    if (theme && theme.data && theme.data.data && theme.data.data.theme) {
      delete theme.data.data.theme._id;
      this.setState({ themeConfig: theme.data.data.theme });
    }
  };

  getLoginThemeConfig = async () => {
    const loginTheme = await axios.company_loginTheme(companyId);
    if (
      loginTheme &&
      loginTheme.data &&
      loginTheme.data.data &&
      loginTheme.data.data.theme
    ) {
      delete loginTheme.data.data.theme._id;
      this.setState({
        loginThemeConfig: loginTheme.data.data.theme,
        loginLogoFileName: loginTheme.data.data.theme.logoImageFileName,
        loginBackGroundFileName:
          loginTheme.data.data.theme.backgroundImageFileName,
      });
    }
  };

  async getCompany() {
    let api = await axios.companyConfig_details(companyId);
    console.log("configOneTwo:", api.data.data);
    let apiCompnayDetails = await axios.companyManage_CompanyDetails(companyId);

    let stats = api.data.data;
    let company = api.data.data;
    let timezone = api.data.data.timezone;
    let unit = stats.unit;
    let batchNumber =
      stats.dailyConsolidatedAlarm.numberOfBatchNotificationPerDay;
    let countNumber = stats.defaultCDPSessions ? stats.defaultCDPSessions : 1;
    let index =
      this.state.timezones &&
      this.state.timezones.findIndex((x) => x.value === timezone);
    let unitIndex = this.state.unitTypes.findIndex((x) => x.value === unit);
    let batchIndex = this.state.numberNotification.findIndex(
      (x) => x.value === batchNumber
    );
    let countIndex = this.state.CDPCount.findIndex(
      (x) => x.value === countNumber
    );
    let thresholdKeys = [];

    stats.thresholdSharing.keyList.map((x) => {
      this.state.thresholdSharingTypes.map((y) => {
        if (y.value == x) {
          thresholdKeys.push(y);
        }
      });
    });

    let { notificationCodesTemp } = this.state;
    let directMessageCodes = stats.dailyConsolidatedAlarm.directMessageCodes;

    notificationCodesTemp = notificationCodesTemp.map((x) => {
      stats.dailyConsolidatedAlarm &&
        // console.log(directMessageCodes);
        directMessageCodes !== undefined &&
        directMessageCodes !== null &&
        Object.keys(directMessageCodes).map((y) => {
          if (y == x.code) {
            x.apply = true;
            if (
              directMessageCodes[y]["condition"] &&
              directMessageCodes[y]["condition"]["stage"]
            ) {
              x.faultStage = this.state.faultStages.filter((x) => {
                return x.label == directMessageCodes[y]["condition"]["stage"];
              })[0].value;
            }
            if (
              directMessageCodes[y]["condition"] &&
              directMessageCodes[y]["condition"]["rul"]
            ) {
              x.rul = directMessageCodes[y]["condition"]["rul"];
            }
          }
        });
      return x;
    });

    // let defaultLang = "";
    // company &&
    //   company.defaultLang &&
    //   this.state.languageList &&
    //   this.state.languageList.map((x) => {
    //     if (x.value == company.defaultLang.langType) {
    //       defaultLang = x.key;
    //     }
    //   });

    // let secondLang = "";
    // company &&
    //   company.secondLang &&
    //   this.state.languageList &&
    //   this.state.languageList.map((x) => {
    //     if (x.value == company.secondLang.langType) {
    //       secondLang = x.key;
    //     }
    //   });

    let startTime =
      stats.dailyConsolidatedAlarm.startTime &&
      Object.keys(stats.dailyConsolidatedAlarm.startTime).length !== 0
        ? stats.dailyConsolidatedAlarm.startTime
        : {
            alarmStartTimeString: moment(
              moment().format("YYYY-MM-DD") + " " + "08:01"
            ),
            alarmStartTime: "08:01",
          };

    delete api.data.data._id;
    delete api.data.data.active;
    delete api.data.data.createdAt;
    delete api.data.data.updatedAt;

    this.setState({
      // defaultLang: defaultLang,
      // secondLang: secondLang,
      notificationCodesTemp: notificationCodesTemp,
      directMessageCodes: directMessageCodes,
      startTime: startTime,
      company: apiCompnayDetails.data.data,
      companyBackup: apiCompnayDetails.data.data,
      loading: false,
      timezone: timezone,
      defaultTimezone: index,
      dashboard_link: company.dashboard_link ? company.dashboard_link : "",
      hyperlink_link: company.hyperlink_link ? company.hyperlink_link : "",
      isNameInFooter: company.dynamicDashboardetails
        ? company.dynamicDashboardetails.isNameInFooter
        : null,
      IsHyperlinkActive: company.dynamicDashboardetails
        ? company.dynamicDashboardetails.IsHyperlinkActive
        : null,
      Dashboardtittle: company.dynamicDashboardetails
        ? company.dynamicDashboardetails.tittle
        : null,
      vibSpecDomainValue:
        company && company.config && company.config.vibSpecDomainValue
          ? company.config.vibSpecDomainValue
          : null,
      vibSpecRange:
        company && company.config && company.config.vibSpecRange
          ? company.config.vibSpecRange
          : null,
      DashboardUrl: company.config ? company.config.dashboardUrl : null,
      das_backgroundColor:
        company.dynamicDashboardetails &&
        company.dynamicDashboardetails.das_backgroundColor
          ? company.dynamicDashboardetails.das_backgroundColor
          : "#000",
      das_textColor:
        company.dynamicDashboardetails &&
        company.dynamicDashboardetails.das_textColor
          ? company.dynamicDashboardetails.das_textColor
          : "#000",
      imageUrl:
        company.dynamicDashboardetails &&
        company.dynamicDashboardetails.imagesUrl
          ? company.dynamicDashboardetails.imagesUrl
          : null,
      fileName:
        company.dynamicDashboardetails &&
        company.dynamicDashboardetails.fileName
          ? company.dynamicDashboardetails.fileName
          : null,
      isDynamicDashboardDetails: company.dynamicDashboardetails ? true : false,
      selectedUnit: unitIndex,
      selectedKeys: thresholdKeys,
      selectedBatch: batchIndex,
      selectedCount: countIndex,
      companyConfig: api.data.data,
    });

    localStorage.setItem("companyId", companyId);
    localStorage.setItem("stats", JSON.stringify(stats));
    localStorage.setItem("company", JSON.stringify(company));
    localStorage.setItem("layout", apiCompnayDetails.data.data.Layout);
  }

  getNotificationCount = async () => {
    const api = await axios.getData(
      "/notificationAlertData/count/" + companyId + "/false"
    );
    this.setState({ notificationCount: api.data.data });
  };

  handleTabChange = (index, lastindex, event) => {
    this.setState({
      currentTab: this.state.notificationCodes[index].appId,
      currentTabIndex: index,
    });
  };

  handleAlarmStart = (e) => {
    let companyConfig = { ...this.state.companyConfig };
    companyConfig["dailyConsolidatedAlarm"] = {
      ...companyConfig.dailyConsolidatedAlarm,
      startTime: {
        alarmStartTimeString: moment(e._d),
        alarmStartTime: moment(e._d).format("HH:mm"),
      },
    };
    this.setState({
      startTime: {
        alarmStartTimeString: moment(e._d),
        alarmStartTime: moment(e._d).format("HH:mm"),
      },
      companyConfig,
    });
  };

  // deleteCode = (key) => {
  //   let { directMessageCodes } = this.state;
  //   directMessageCodes = directMessageCodes.filter((x) => {
  //     return x.id != key.id;
  //   });
  //   console.log(directMessageCodes);
  //   this.setState({
  //     directMessageCodes: directMessageCodes,
  //   });
  // };

  // addCodeValue = (e, key) => {
  //   let { directMessageCodes, notificationCodesTemp } = this.state;
  //   directMessageCodes = directMessageCodes.map((x) => {
  //     if (x.id == key.id) {
  //       x.code = e.value;
  //     }
  //     return x;
  //   });
  //   notificationCodesTemp = notificationCodesTemp.filter((e) => {
  //     return e.label != key.code;
  //   });
  //   this.setState({ directMessageCodes, notificationCodesTemp });
  // };

  // addCodeValue = (e, key) => {
  //   let { directMessageCodes, notificationCodesTemp } = this.state;
  //   directMessageCodes = directMessageCodes.map((x) => {
  //     if (x.id == key.id) {
  //       x.code = e.value;
  //     }
  //     return x;
  //   });
  //   notificationCodesTemp = notificationCodesTemp.filter((e) => {
  //     return e.label != key.code;
  //   });
  //   this.setState({ directMessageCodes, notificationCodesTemp });
  // };

  // addConditionValue = (e, key, type) => {
  //   let { directMessageCodes } = this.state;
  //   directMessageCodes = directMessageCodes.map((x) => {
  //     if (x.id == key.id) {
  //       x.condition[type] = type == 'rul' ? parseFloat(e.value) : e.value;
  //     }

  //     return x;
  //   });

  //   this.setState({ directMessageCodes });
  // };

  handleActive = async (event) => {
    if (window.confirm("Confirm changing company status")) {
      this.setState({ readOnly: false });
      let company = { ...this.state.company };
      company.active = event.target.checked;
      if (company.active === false) {
        company.notificationToBeChecked = false;
      }
      const companyDup = company;
      delete companyDup._id;
      let api = await axios.companyConfig_update(this.state.company.companyId, {
        companyName: company.companyName,
        aboutCompany: company.company,
        customName: company.customName,
        headQuarters: company.headQuarters,
        externalId: company.externalId,
        active: company.active,
      });
      if (api.status === 200) {
        toast.success("Company status set succesfully!");
        this.props.history.push("/v1/companies");
        return;
        company._id = api.data.data._id;
        company.active = api.data.data.active;
        this.setState({ company }, () => {
          this.props.fetchCompanyDetails(companyId);
        });
      } else if (api.status === 400) {
        toast.warning(api.data.message);
      } else if (api.status === 500) {
        toast.danger("Something went wrong. Please try again.");
      }
      this.setState({ readOnly: true });
    } else return;
  };

  handleNotificationActive = async (event) => {
    let companyConfig = { ...this.state.companyConfig };
    if (window.confirm("Confirm changing Notification status")) {
      companyConfig.notificationToBeChecked = event.target.checked;
      this.setState({ companyConfig });
    } else return;
  };

  handleDynamicDashboardDetails = (details) => {
    let companyConfig = { ...this.state.companyConfig };
    companyConfig["dynamicDashboardetails"] = {
      ...companyConfig.dynamicDashboardetails,
      isDynamicDashboardDetails: !this.state.isDynamicDashboardDetails,
    };
    this.setState({
      companyConfig,
      isDynamicDashboardDetails: !this.state.isDynamicDashboardDetails,
    });
  };

  handleisNameInFooter = (details) => {
    let companyConfig = { ...this.state.companyConfig };
    companyConfig["dynamicDashboardetails"] = {
      ...companyConfig.dynamicDashboardetails,
      isNameInFooter: !this.state.isNameInFooter,
    };
    this.setState({
      companyConfig,
      isNameInFooter: !this.state.isNameInFooter,
    });
  };

  handleIsHyperlinkActive = (details) => {
    let companyConfig = { ...this.state.companyConfig };
    companyConfig["dynamicDashboardetails"] = {
      ...companyConfig.dynamicDashboardetails,
      IsHyperlinkActive: !this.state.IsHyperlinkActive,
    };
    this.setState({
      companyConfig,
      IsHyperlinkActive: !this.state.IsHyperlinkActive,
    });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleSoundHPFActive = async (event) => {
    let companyConfig = { ...this.state.companyConfig };

    if (companyConfig.active === false) {
      return;
    }
    if (window.confirm("Confirm changing soundHPF status")) {
      companyConfig.soundHPF = event.target.checked;
      this.setState({ companyConfig });
    } else return;
  };

  handleOverallRmsActive = async (event) => {
    let companyConfig = { ...this.state.companyConfig };

    if (window.confirm("Confirm changing Overall rms status")) {
      companyConfig.overallrms = event.target.checked;
      this.setState({ companyConfig });
    } else return;
  };

  handleConfigActive = (type, event) => {
    let companyConfig = { ...this.state.companyConfig };
    companyConfig[type] = event.target.checked;

    this.setState({
      companyConfig: companyConfig,
    });
  };
  handleChildProperty = (type, child, eventVal) => {
    let companyConfig = { ...this.state.companyConfig };
    if (companyConfig[type]) {
      companyConfig[type][child] = eventVal.target.checked;
      this.setState({
        companyConfig: companyConfig,
      });
    }
  };

  handleChildPropertyInput = (type, sub, e) => {
    let companyConfig = { ...this.state.companyConfig };
    companyConfig[type][sub] = e.target.value;
    this.setState({
      companyConfig: companyConfig,
    });
  };

  handleVibChanges = (type, e) => {
    let company = { ...this.state.company };
    company.config[type] = e.target.value;
    this.setState({
      company: company,
    });
  };

  handleMultiSelectChange = (e) => {
    let companyConfig = { ...this.state.companyConfig };
    if (e) {
      let keys = e.map((x) => {
        return x.value;
      });
      companyConfig.thresholdSharing.keyList = [...keys];
      this.setState({ companyConfig: companyConfig, selectedKeys: e });
    } else {
      companyConfig.thresholdSharing.keyList = [];
      this.setState({ companyConfig: companyConfig, selectedKeys: e });
    }
  };

  handleChange = (input) => (e) => {
    let company = { ...this.state.company };
    if (input === "Layout") {
      company[input] = e.value;
    } else {
      company[input] = e.target.value;
    }
    this.setState({ company: company });
  };

  handleChangeLanguage = (input, e) => {
    let companyConfig = { ...this.state.companyConfig };
    let setObject = {
      key: input,
      label: e.label,
      langType: e.value,
    };
    companyConfig[input] = setObject;
    this.setState({ companyConfig: companyConfig, [input]: e.key });
  };

  handleEdit(edit) {
    this.setState({ readOnly: edit });
  }

  handleCancel() {
    let companyBackup = { ...this.state.companyBackup };
    this.setState({ company: companyBackup, readOnly: true });
  }

  handleSelectChange(field, type = "filter", option, action) {
    if (action.action === "select-option") {
      if (type === "add") {
        if (field === "timezone") {
          let companyConfig = { ...this.state.companyConfig };
          companyConfig.timezone = option.value;
          this.setState({
            companyConfig,
            defaultTimezone: option.key,
          });
        }

        if (field === "upload_frequency") {
          let index = this.state.frequency.findIndex(
            (x) => x.value === option.value
          );
          this.setState({ selectedFrequency: index });
        }
      }
    } else if (action.action === "clear") {
      let companyConfig = { ...this.state.companyConfig };
      companyConfig.timezone = "";
      this.setState({ companyConfig, defaultTimezone: -1 });
    }
  }

  handleUnitChange = (e) => {
    let { companyConfig } = this.state;
    companyConfig.unit = e.value;
    this.setState({ selectedUnit: e.key, companyConfig });
  };
  handleDefaultCDPSessions = (e) => {
    let { companyConfig } = this.state;
    companyConfig.defaultCDPSessions = e.value;
    this.setState({ selectedCount: e.key, companyConfig });
  };

  handleBatchChange = (e) => {
    let { companyConfig } = this.state;
    if (companyConfig && companyConfig.dailyConsolidatedAlarm) {
      companyConfig.dailyConsolidatedAlarm.numberOfBatchNotificationPerDay =
        e.value;
      this.setState({ selectedBatch: e.key, companyConfig });
    }
  };
  handleRadioChange(field, e) {
    let companyConfig = { ...this.state.companyConfig };
    companyConfig[field] = e.target.value;
    this.setState({ companyConfig });
  }

  async validation() {
    //Defining schema
    // let schema = yup.object().shape({
    // })
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.fetchCompanyDetails(companyId);
  };
  handleConfigClose = () => {
    this.setState({ configOpen: false });
  };
  handleThemeConfigClose = () => {
    this.setState({ themeConfigOpen: false });
  };
  handleLoginThemeConfigClose = () => {
    this.setState({ loginThemeConfigOpen: false });
  };
  handleCodesClose = () => {
    this.setState({ codesOpen: false });
  };
  handleUploadRateSubmit = async () => {
    if (this.state.selectedFrequency < 0) {
      toast.warn("Select Upload Frequency");
      return;
    }
    this.setState({ saving: true });

    let endpoint = "/upload-frequency/companies";
    let data = {
      companyId: companyId,
      uploadRate: this.state.frequency[this.state.selectedFrequency].value,
    };

    let api = await axios.postData(endpoint, data);
    if (api.status === 200) {
      toast.success("Operation Successfull");

      let sensorsUploadFrequencyError = api.data.filter((sensor) => {
        return sensor.resStatus != 200;
      });

      this.setState({
        saving: false,
        sensorsUploadFrequency: api.data,
        sensorsUploadFrequencyError,
      });
    } else if (api.status === 400) {
      toast.warn(api.data.error);
      this.setState({ saving: false });
    } else {
      toast.error("Something went wrong. Please try again.");
      this.setState({ saving: false });
    }
    // this.handleClose()
  };

  checkCode = (id, type, e) => {
    let { notificationCodesTemp } = this.state;
    if (type == "check") {
      notificationCodesTemp = notificationCodesTemp.map((x) => {
        if (x.id == id) {
          x.apply = e.target.checked;
        }
        return x;
      });
    } else {
      notificationCodesTemp = notificationCodesTemp.map((x) => {
        if (x.id == id) {
          x[type] = e;
        }
        return x;
      });
    }
    this.setState({ notificationCodesTemp });
  };

  handleThemeConfigSave = async () => {
    this.setState({ saving: true });
    this.state.themeConfig["companyCode"] = this.state.company.companyId;
    let api = await axios.saveCompanyThemeConfig(this.state.themeConfig);
    if (api.status === 200) {
      this.props.fetchThemeConfig(this.state.company.companyId);
      toast.success("Company Theme config saved successfully !");
      this.setState({ themeConfigOpen: false, saving: false });
    } else if (api.status === 400) {
      toast.warning(api.data.message);
      this.setState({ themeConfigOpen: false, saving: false });
    } else if (api.status === 500) {
      toast.danger("Something went wrong. Please try again.");
      this.setState({ themeConfigOpen: false, saving: false });
    }
  };

  handleLoginThemeConfigSave = async () => {
    this.setState({ saving: true });
    this.state.loginThemeConfig["companyCode"] = this.state.company.companyId;
    this.state.loginThemeConfig["backgroundImageFile"] = this.state
      .loginBackGroundImageUrl
      ? this.state.loginBackGroundImageUrl
      : this.state.loginThemeConfig.backgroundImageFile;
    this.state.loginThemeConfig["backgroundImageFileName"] = this.state
      .loginBackGroundFileName
      ? this.state.loginBackGroundFileName
      : this.state.loginThemeConfig.backgroundImageFileName;
    this.state.loginThemeConfig["logoImageFile"] = this.state.loginLogoImageUrl
      ? this.state.loginLogoImageUrl
      : this.state.loginThemeConfig.logoImageFile;
    this.state.loginThemeConfig["logoImageFileName"] = this.state
      .loginLogoFileName
      ? this.state.loginLogoFileName
      : this.state.loginThemeConfig.logoImageFileName;
    let api = await axios.saveCompanyLoginThemeConfig(
      this.state.loginThemeConfig
    );
    if (api.status === 200) {
      this.props.fetchLoginThemeConfig(this.state.company.companyId);
      toast.success("Company Login Theme config saved successfully !");
      this.setState({ loginThemeConfigOpen: false, saving: false });
    } else if (api.status === 400) {
      toast.warning(api.data.message);
      this.setState({ loginThemeConfigOpen: false, saving: false });
    } else if (api.status === 500) {
      toast.danger("Something went wrong. Please try again.");
      this.setState({ loginThemeConfigOpen: false, saving: false });
    }
  };

  handleSave = async () => {
    if (this.state.company.companyId) {
      this.setState({ saving: true });
      let company = {
        ...this.state.company,
      };

      //Validating
      let err = await validation.validate(this.state.company);
      if (err) {
        toast.warn(err);
        this.setState({ saving: false });
        return;
      }

      delete company._id;

      let api = await axios.companyConfig_update(this.state.company.companyId, {
        companyName: company.companyName,
        aboutCompany: company.company,
        customName: company.customName,
        headQuarters: company.headQuarters,
        externalId: company.externalId,
        active: company.active,
      });
      this.setState({ saving: false });
      if (api.status === 200) {
        toast.success("Company saved successfully !");
        this.props.fetchCompanyDetails(companyId);
        localStorage.removeItem("companyId");
        this.setState({ readOnly: true, configOpen: false });
      } else if (api.status === 400) {
        toast.warning(api.data.message);
      } else if (api.status === 500) {
        toast.danger("Something went wrong. Please try again.");
      }
    }
  };

  handleConfigSave = async () => {
    if (this.state.imageUrl !== null) {
      if (this.state.imageUrl.includes("data:image/")) {
        let imageCompanyConfig = await axios.fileDataLogo_create({
          file: this.state.imageUrl,
          fileName: `${companyId}-icon.${this.state.fileName.split(".")[1]}`,
          companyId: companyId,
        });
        let companyConfig = { ...this.state.companyConfig };
        companyConfig["dynamicDashboardetails"] = {
          ...companyConfig.dynamicDashboardetails,
          fileName: `${companyId}-icon.${this.state.fileName.split(".")[1]}`,
        };
        this.setState({ companyConfig });
      }
    }

    let updateCompanyConfig = await axios.companyConfig_allUpdate(
      this.state.companyConfig,
      companyId
    );
    if (updateCompanyConfig.status === 200) {
      toast.success("Organization config updated succesfully!");
      this.setState({ configOpen: false });
      return;
    }
  };

  processDirectCodes = async () => {
    let { notificationCodesTemp } = this.state;

    let directMessageCodes = {};
    notificationCodesTemp.map((x) => {
      let obj = {
        [x.code]: {
          condition: null,
        },
      };
      if (x.apply === true) {
        if (x.key === "code_fault_customer") {
          obj[x.code]["condition"] = {
            stage: x.faultStage,
            rul: x.rul,
          };
        }
        directMessageCodes = { ...directMessageCodes, ...obj };
      }
    });
    let companyConfig = { ...this.state.companyConfig };
    companyConfig.dailyConsolidatedAlarm.directMessageCodes =
      directMessageCodes;
    this.setState({ directMessageCodes, companyConfig });
    this.handleCodesClose();
  };

  jump =
    () =>
    (url, delay = 0) => {
      if (url.includes("config")) {
        this.setState({ configOpen: true });
        this.getCompany();
      } else if (url.includes("theme")) {
        this.setState({ themeConfigOpen: true });
      } else if (url.includes("loginTheme")) {
        this.setState({ loginThemeConfigOpen: true });
      } else if (url.includes("feedbackConfigManual")) {
        this.setState({ isFeedbackConfigManual: true });
      } else {
        setTimeout(() => {
          this.props.history.push(url);
        }, delay);
      }
    };
  handleColorChanges(color) {
    // console.log(color);
    let companyConfig = { ...this.state.companyConfig };
    companyConfig["dynamicDashboardetails"] = {
      ...companyConfig.dynamicDashboardetails,
      das_backgroundColor: color,
    };

    this.setState({ companyConfig, das_backgroundColor: color });
  }

  handleThemeColorChanges(field, color) {
    let tempThemeconfig = this.state.themeConfig;
    console.log("this.state.themeConfig:", this.state.themeConfig);
    tempThemeconfig[field] = color;
    console.log("this.state.themeConfig2:", this.state.themeConfig);
    this.setState({ themeConfig: tempThemeconfig });
  }

  handleLoginThemeColorChanges(field, color) {
    this.state.loginThemeConfig[field] = color;
    this.setState({ loginThemeConfig: this.state.loginThemeConfig });
  }

  handleTextColorChanges(color) {
    // console.log(color);
    let companyConfig = { ...this.state.companyConfig };

    companyConfig["dynamicDashboardetails"] = {
      ...companyConfig.dynamicDashboardetails,
      das_textColor: color,
    };

    this.setState({ companyConfig, das_textColor: color });
  }

  /**
   * Retrieve Company Config from ID
   * @param id
   * @param defaultValue
   */
  getCompanyConfigValue = (id, defaultValue = null) => {
    const companyConfig = this.state.companyConfig;
    if (!companyConfig || typeof id !== "string" || !(id in companyConfig))
      return defaultValue;

    return companyConfig[id];
  };

  /**
   * Set Company Config for ID
   * @param id
   * @param value
   */
  handleCompanyConfigChange = (id, value) => {
    if (!id) return;

    let config = this.state.companyConfig;
    config = { ...config, [id]: value };

    this.setState({
      companyConfig: config,
    });
  };

  handleOptionChange = async (e, changeEvent) => {
    let obj = this.state.maintenanceTypeList.find(
      (o) => o.val === changeEvent.val
    );

    switch (e.target.value) {
      case "mainTenanceWatch":
        if (
          !obj.maintenanceWatch === false &&
          changeEvent.resetFaultDetection === false
        ) {
          obj.maintenanceWatch = !obj.maintenanceWatch;
          obj.resetFaultDetection = changeEvent.resetFaultDetection;
          obj.noAction = true;
          break;
        }
        obj.maintenanceWatch = !obj.maintenanceWatch;
        obj.resetFaultDetection = changeEvent.resetFaultDetection;
        obj.noAction = false;
        break;
      case "rstFaultDetect":
        if (
          !obj.resetFaultDetection === false &&
          changeEvent.maintenanceWatch === false
        ) {
          obj.maintenanceWatch = changeEvent.maintenanceWatch;
          obj.resetFaultDetection = !obj.resetFaultDetection;
          obj.noAction = true;
          break;
        }
        obj.maintenanceWatch = changeEvent.maintenanceWatch;
        obj.resetFaultDetection = !obj.resetFaultDetection;
        obj.noAction = false;
        break;
      case "noAction":
        obj.maintenanceWatch = false;
        obj.resetFaultDetection = false;
        obj.noAction = true;
        break;
      default:
        break;
    }

    if (window.confirm("Are you Sure you want to change?")) {
      let items = this.state.maintenanceTypeList;
      let foundIndex = items.findIndex((x) => x.val == obj.val);
      items[foundIndex] = obj;
      this.setState({ maintenanceTypeList: items });
      await axios.postData(
        `/feedbackLog/maintenanceTypes/maintenanceTypes/${companyId}`,
        obj
      );
    } else {
      this.setState({ isFeedbackConfigManual: false });
      this.props.fetchMaintenanceTypes(companyId);
      return;
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.readOnly ||
      prevState.configOpen ||
      prevState.loginThemeConfigOpen ||
      prevState.themeConfigOpen
    ) {
      // updateMode name changes here
      return;
    }
    let api = null,
      stats = null,
      company = null,
      timezone = null,
      unit = null,
      index = null,
      unitIndex = null,
      thresholdKeys = [],
      notificationCount = 0,
      themeConfigData = null,
      loginThemeConfigData = null,
      loginLogoFileNameData = null,
      loginBackGroundFileNameData = null;
    if (nextProps.notifData !== prevState.notifData) {
      if (nextProps.notifData) {
        notificationCount = nextProps.notifData.data;
      }
    }
    if (nextProps.companyDetailsData !== prevState.companyDetailsData) {
      if (nextProps.companyDetailsData) {
        api = nextProps.companyDetailsData;
        stats = api.data;
        company = api.data && api.data.company;
        timezone = api.data && api.data.company && api.data.company.timezone;
        unit =
          stats &&
          stats.company &&
          stats.company.config &&
          stats.company.config.unit;
        index = prevState.timezones
          ? prevState.timezones.findIndex((x) => x.value === timezone)
          : 0;
        unitIndex = prevState.unitTypes.findIndex((x) => x.value === unit);
        thresholdKeys = [];
        stats &&
          stats.company &&
          stats.company.config &&
          stats.company.config.thresholdSharing &&
          stats.company.config.thresholdSharing.keyList.map((x) => {
            prevState.thresholdSharingTypes.map((y) => {
              if (y.value == x) {
                thresholdKeys.push(y);
              }
            });
          });

        localStorage.setItem("companyId", companyId);
        localStorage.setItem("stats", JSON.stringify(stats));

        localStorage.setItem("company", JSON.stringify(company));
      }
    } //endcompanyDetails

    if (nextProps.themeConfigData !== prevState.themeConfigData) {
      if (
        nextProps.themeConfigData &&
        nextProps.themeConfigData.data &&
        nextProps.themeConfigData.data.theme
      ) {
        delete nextProps.themeConfigData.data.theme._id;
        themeConfigData = nextProps.themeConfigData.data.theme;
      }
    }

    if (nextProps.loginThemeConfigData !== prevState.loginThemeConfigData) {
      if (
        nextProps.loginThemeConfigData &&
        nextProps.loginThemeConfigData.data &&
        nextProps.loginThemeConfigData.data.theme
      ) {
        delete nextProps.loginThemeConfigData.data.theme._id;
        loginThemeConfigData = nextProps.loginThemeConfigData.data.theme;
        loginLogoFileNameData =
          nextProps.loginThemeConfigData.data.theme.logoImageFileName;
        loginBackGroundFileNameData =
          nextProps.loginThemeConfigData.data.theme.backgroundImageFileName;
      }
    }
    let maintenanceTypeList = [];
    if (nextProps.maintenanceTypesData !== prevState.maintenanceTypesData) {
      if (nextProps.maintenanceTypesData) {
        maintenanceTypeList = nextProps.maintenanceTypesData.data
          ? nextProps.maintenanceTypesData.data
          : [];
      }
    }

    let obj,
      obj_2 = {};
    if (
      prevState.defaultLang === undefined ||
      Object.keys(prevState.defaultLang).length === 0
    ) {
      obj = {
        key: "defaultLang",
        label: "English",
        langType: "eng",
      };
    }
    if (
      prevState.secondLang === undefined ||
      Object.keys(prevState.secondLang).length === 0
    ) {
      obj_2 = {
        key: "secondLang",
        label: "English",
        langType: "eng",
      };
    }
    return {
      defaultLang: obj,
      secondLang: obj_2,
      company:
        api !== null
          ? api.data.company
          : {
              temperature: "C",
              notificationToBeChecked: true,
              active: true,
              config: {
                unit: "metric",
                dataExport: {
                  active: false,
                  url: "",
                  key: "",
                },
                s3Export: {
                  active: false,
                  bucket: "",
                },
                sensorHealthDashboard: true,
                exportDataToPtc: false,
                energyEfficiency: false,
                soundHPF: true,
                maintenanceLog: true,
                thresholdSharing: {
                  active: false,
                  keyList: [],
                },
              },
            },
      companyBackup: api !== null ? api.data.company : {},
      loading: api !== null ? false : true,
      timezone: api !== null ? timezone : null,
      defaultTimezone: api !== null ? index : 0,
      statsTemp: stats,
      isNameInFooter:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.isNameInFooter
            : false
          : false,
      IsHyperlinkActive:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.IsHyperlinkActive
            : null
          : null,
      Dashboardtittle:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.tittle
            : null
          : null,
      DashboardUrl:
        api !== null
          ? company.dynamicDashboardetails
            ? company.dynamicDashboardetails.dashboardUrl
            : null
          : null,
      das_backgroundColor:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.das_backgroundColor
            ? company.dynamicDashboardetails.das_backgroundColor
            : "#000"
          : "#000",
      das_textColor:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.das_textColor
            ? company.dynamicDashboardetails.das_textColor
            : "#000"
          : "#000",
      imageUrl:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.imagesUrl
            ? company.dynamicDashboardetails.imagesUrl
            : null
          : null,
      fileName:
        api !== null
          ? company.dynamicDashboardetails &&
            company.dynamicDashboardetails.fileName
            ? company.dynamicDashboardetails.fileName
            : null
          : null,
      isDynamicDashboardDetails:
        api !== null ? (company.dynamicDashboardetails ? true : false) : false,
      selectedUnit: api !== null ? unitIndex : null,
      selectedKeys: api !== null ? thresholdKeys : [],
      notificationCount,
      themeConfigData: themeConfigData,
      themeConfig:
        themeConfigData !== null ? themeConfigData : prevState.themeConfig,
      loginThemeConfigData: loginThemeConfigData,
      loginThemeConfig:
        loginThemeConfigData !== null
          ? loginThemeConfigData
          : prevState.loginThemeConfig,
      loginLogoFileName:
        loginLogoFileNameData !== null
          ? loginLogoFileNameData
          : prevState.loginLogoFileName,
      loginBackGroundFileName:
        loginBackGroundFileNameData !== null
          ? loginBackGroundFileNameData
          : prevState.loginBackGroundFileName,
      maintenanceTypeList: maintenanceTypeList,
    };
  }

  render() {
    cards = cards.map((card, key) => {
      if (this.state.statsTemp !== null) {
        card.count = this.state.statsTemp.company[card.key];
        return card;
      } else {
        return card;
      }
    });
    cardsRootAllowed = cardsRootAllowed.map((cardsRootAllowed, key) => {
      if (this.state.statsTemp !== null) {
        cardsRootAllowed.count =
          cardsRootAllowed.key !== "config"
            ? this.state.statsTemp.company[cardsRootAllowed.key]
            : null; //when api update i will ad the numbers
        return cardsRootAllowed;
      } else {
        return cardsRootAllowed;
      }
    });
    cardsWithNotification = cardsWithNotification.map((card, key) => {
      if (this.state.statsTemp !== null) {
        if (card.key === "notification") {
          card.count = this.state.notificationCount;
        } else {
          card.count = this.state.statsTemp.company[card.key];
        }
        return card;
      } else {
        return card;
      }
    });

    // if (stats['machines'] > 0) {
    //   delete cards[2]
    // }
    let selectedCard =
      this.state.onlyRootAllowed === true
        ? cardsRootAllowed
        : this.state.company.notificationToBeChecked === "false"
        ? cards
        : cardsWithNotification;

    if (this.state.company.Layout === 0) {
      selectedCard = selectedCard.filter((el) => {
        return el.key != "machines";
      });
    }

    return (
      <>
        <ToastContainer position="top-left" />
        {this.state.loading ? (
          <GraphShimmer />
        ) : (
          <>
            <Header />
            <Tiles
              cards={selectedCard}
              jumpPage={this.jump()}
              companyId={companyId}
            />
            {/* Page content */}
            <Container className="mt--7" fluid>
              <Row style={{ marginLeft: "0px" }}>
                {this.state.createAllowed && (
                  <Button
                    style={{ background: "#df7126", borderColor: "#df7126" }}
                    color="primary"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                    size="xs"
                  >
                    Upload Frequency
                  </Button>
                )}
              </Row>

              <br></br>

              <Row>
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      {this.state.createAllowed ? (
                        <Row className="align-items-center">
                          <Col xs="2">
                            <h3 className="mb-0">Company Details</h3>
                          </Col>
                          <Col xs="6" class="float-left">
                            <FormControlLabel
                              className="mb-0"
                              control={
                                <IOSSwitch
                                  checked={this.state.company.active}
                                  onChange={this.handleActive}
                                  name="check"
                                />
                              }
                              label={
                                this.state.company.active === true
                                  ? "Company is Active"
                                  : "Company is not Active"
                              }
                            />{" "}
                          </Col>

                          <Col className="text-right" xs="4">
                            {this.state.readOnly && (
                              <Button
                                color="primary"
                                onClick={() => this.handleEdit(false)}
                                size="sm"
                              >
                                Edit
                              </Button>
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">Company Details</h3>
                          </Col>

                          <Col className="text-right" xs="4">
                            {this.state.readOnly && (
                              <Button
                                color="primary"
                                onClick={() => this.handleEdit(false)}
                                size="sm"
                              >
                                Edit
                              </Button>
                            )}
                          </Col>
                        </Row>
                      )}
                    </CardHeader>
                    <CardBody>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Company Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.company.companyName}
                                onChange={this.handleChange("companyName")}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Company Code (4 Char Upper Case){" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                type="text"
                                value={this.state.company.companyId}
                                onChange={this.handleChange("companyId")}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Custom Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-first-name"
                                type="text"
                                value={this.state.company.customName}
                                onChange={this.handleChange("customName")}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Headquarter{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-last-name"
                                type="text"
                                value={this.state.company.headQuarters}
                                onChange={this.handleChange("headQuarters")}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            DashBoard{" "}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            value={this.state.company.dashboard_link}
                            onChange={this.handleChange("dashboard_link")}
                            disabled={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col> */}
                          {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Hyperlink{" "}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            value={this.state.company.hyperlink_link}
                            onChange={this.handleChange("hyperlink_link")}
                            disabled={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col> */}
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Sensor Stats email Frequency (in hours){" "}
                                <Tooltip
                                  title="Send notification if the data is not being received for this amount of time from the sensor. Default value is 4 days or 96 hours"
                                  placement="right-start"
                                >
                                  <Info
                                    style={{
                                      marginRight: "10px",
                                      cursor: "pointer",
                                      fontSize: "30px",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-last-name"
                                type="number"
                                value={
                                  this.state.company.sensorStatsFrequencyInHours
                                }
                                onChange={this.handleChange(
                                  "sensorStatsFrequencyInHours"
                                )}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Layout{" "}
                                <Tooltip
                                  title="Select Machine Layout or Equipment Layout"
                                  placement="right-start"
                                >
                                  <Info
                                    style={{
                                      marginRight: "10px",
                                      cursor: "pointer",
                                      fontSize: "30px",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                              <Select
                                className="form-control-alternative"
                                isDisabled={true}
                                onChange={this.handleChange("Layout")}
                                options={this.state.layout}
                                value={
                                  this.state.layout[this.state.company.Layout]
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                External ID{" "}
                                <Tooltip
                                  title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                                  placement="right-start"
                                >
                                  <Info
                                    style={{
                                      marginRight: "10px",
                                      cursor: "pointer",
                                      fontSize: "30px",
                                    }}
                                  />
                                </Tooltip>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-last-name"
                                type="text"
                                value={this.state.company.externalId}
                                onChange={this.handleChange("externalId")}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                About Company{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-about-company"
                                // placeholder="A few words about company ..."
                                rows="8"
                                type="textarea"
                                value={this.state.company.aboutCompany}
                                onChange={this.handleChange("aboutCompany")}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="12">
                            {!this.state.readOnly && (
                              <Button
                                className="right"
                                color="success"
                                onClick={() => this.handleSave()}
                                size="md"
                                disabled={this.state.saving}
                              >
                                {this.state.saving ? (
                                  <i class="fas fa-spinner fa-pulse"></i>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            )}

                            {!this.state.readOnly && !this.state.saving && (
                              <Button
                                className="right cmr-10"
                                color="danger"
                                onClick={() => this.handleCancel()}
                                size="md"
                              >
                                Cancel
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>

            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              scroll={"body"}
              open={this.state.configOpen}
              onClose={this.handleConfigClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ textAlign: "center" }}
              >
                Organization Configurations
              </DialogTitle>
              <DialogContent style={{ height: "650px" }}>
                <TabsAnt tabPosition="left" defaultActiveKey="1">
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Language`}
                    key={1}
                  >
                    <Row>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Default Language
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={(e) => {
                            this.handleChangeLanguage("defaultLang", e);
                          }}
                          options={this.state.languageList}
                          value={this.state.companyConfig.defaultLang}
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Second Language
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={(e) => {
                            this.handleChangeLanguage("secondLang", e);
                          }}
                          options={this.state.languageList}
                          value={this.state.companyConfig.secondLang}
                        />
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 550,
                    }}
                    tab={`Dashaboard Link`}
                    key={15}
                  >
                    <Row>
                      <Col span={12}>
                        <Label>Dashboard Link</Label>
                        <Input
                          className="form-control-alternative"
                          id="input-dashboard-link"
                          value={this.state.dashboard_link}
                          onChange={(e) => {
                            let companyConfig = { ...this.state.companyConfig };
                            companyConfig["dashboard_link"] = e.target.value;
                            this.setState({
                              dashboard_link: e.target.value,
                              companyConfig,
                            });
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <Label>Hyper Link</Label>
                        <Input
                          className="form-control-alternative"
                          id="input-dashboard-hyper-link"
                          value={this.state.hyperlink_link}
                          onChange={(e) => {
                            let companyConfig = { ...this.state.companyConfig };
                            companyConfig["hyperlink_link"] = e.target.value;
                            this.setState({
                              hyperlink_link: e.target.value,
                              companyConfig,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane
                    style={{
                      height: 550,
                    }}
                    tab={`Notification`}
                    key={2}
                  >
                    {" "}
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.dailyConsolidatedAlarm
                                  .active
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dailyConsolidatedAlarm",
                                  "active",
                                  e
                                );
                              }}
                              name="notification"
                            />
                          }
                          label={
                            this.state.companyConfig.dailyConsolidatedAlarm
                              .active === true
                              ? "Daily Alarm Notification is Enabled"
                              : "Daily Alarm Notification is disabled"
                          }
                        />{" "}
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Number of notification per day
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={this.handleBatchChange}
                          options={this.state.numberNotification}
                          value={
                            this.state.numberNotification[
                              this.state.selectedBatch
                            ]
                          }
                          isDisabled={
                            !this.state.companyConfig.dailyConsolidatedAlarm
                              .active
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.dailyConsolidatedAlarm
                                  .includeDirectNotification
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dailyConsolidatedAlarm",
                                  "includeDirectNotification",
                                  e
                                );
                              }}
                              name="notification"
                            />
                          }
                          label={
                            "Include Direct Notifications in Daily Summary Email"
                          }
                        />{" "}
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Start Time To Send Notification
                          <span className="text-danger">*</span>
                        </label>
                        <div>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <TimePicker
                              disabled={
                                !this.state.companyConfig.dailyConsolidatedAlarm
                                  .active
                              }
                              value={this.state.startTime.alarmStartTimeString}
                              onChange={this.handleAlarmStart}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.dailyConsolidatedAlarm
                                  .smsNotification
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dailyConsolidatedAlarm",
                                  "smsNotification",
                                  e
                                );
                              }}
                              name="smsNotification"
                            />
                          }
                          label={
                            "Include sms notification for Daily Summary Notification"
                          }
                        />{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          onClick={() => {
                            this.setState({
                              codesOpen: true,
                              currentTabIndex: 0,
                            });
                          }}
                          color="info"
                          disabled={
                            !this.state.companyConfig.dailyConsolidatedAlarm
                              .active
                          }
                        >
                          Select Direct Codes
                        </Button>
                      </Col>
                      {this.state.directMessageCodes &&
                        Object.keys(this.state.directMessageCodes).length >
                          0 && (
                          <Col lg="12">
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Direct codes (sent to customer directly)
                              <span className="text-danger">*</span>
                            </label>
                          </Col>
                        )}
                    </Row>
                    {this.state.directMessageCodes &&
                      Object.keys(this.state.directMessageCodes).map((key, i) =>
                        this.state.directMessageCodes[key]["condition"] ? (
                          <Row key={key}>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Code <span className="text-danger">*</span>
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={key}
                                disabled={true}
                              />
                            </Col>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Fault Stage
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={
                                  this.state.directMessageCodes[key][
                                    "condition"
                                  ]["stage"]
                                }
                                disabled={true}
                              />
                            </Col>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                RUL
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={
                                  this.state.directMessageCodes[key][
                                    "condition"
                                  ]["rul"]
                                }
                                disabled={true}
                              />
                            </Col>
                            {/* 
                    <Col xs={3} lg={3}>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        {' '}
                      </label>
                      <div>
                        <Button
                          color="info"
                          style={{ height: '40px' }}
                          onClick={() => {
                            this.deleteCode(key);
                          }}
                          disabled={
                            !this.state.company.config.dailyConsolidatedAlarm
                              .active
                          }
                        >
                          <MaterialIcon icon="delete" color="#ff0000" />
                        </Button>
                      </div>
                    </Col> */}
                          </Row>
                        ) : (
                          <Row key={key}>
                            <Col xs={3} lg={3}>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Code <span className="text-danger">*</span>
                              </label>
                              <Input
                                style={{ height: "40px" }}
                                value={key}
                                disabled={true}
                              />
                            </Col>
                            {/* <Col xs={3} lg={3}>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        {' '}
                      </label>
                      <div>
                        <Button
                          color="info"
                          style={{ height: '40px' }}
                          onClick={() => {
                            this.deleteCode(key);
                          }}
                          disabled={
                            !this.state.company.config.dailyConsolidatedAlarm
                              .active
                          }
                        >
                          <MaterialIcon icon="delete" color="#ff0000" />
                        </Button>
                      </div>
                    </Col> */}
                          </Row>
                        )
                      )}
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Health Summary`}
                    key={3}
                  >
                    {" "}
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.healthSummaryDashboard
                              }
                              onChange={(e) => {
                                this.handleConfigActive(
                                  "healthSummaryDashboard",
                                  e
                                );
                              }}
                              name="Sensor Health Dashboard"
                            />
                          }
                          label={
                            this.state.companyConfig.healthSummaryDashboard ===
                            true
                              ? "Health Summary Dashboard is Enabled"
                              : "Health Summary Dashboard is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Chatlio`}
                    key={4}
                  >
                    <Row>
                      {/* <Col class="float-right">
                    <FormControlLabel
                      className="mb-0"
                      control={
                        <IOSSwitch
                          checked={
                            this.state.company.config &&
                            this.state.company.config.cpm
                          }
                          onChange={(e) => {
                            this.handleConfigActive("cpm", e);
                          }}
                          name="Cycle per minute"
                        />
                      }
                      label={
                        this.state.company.config &&
                        this.state.company.config.cpm === true
                          ? "CPM is Enabled"
                          : "CPM is Disabled"
                      }
                    />{" "}
                  </Col> */}

                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig &&
                                this.state.companyConfig.chatlio
                              }
                              onChange={(e) => {
                                this.handleConfigActive("chatlio", e);
                              }}
                              name="chatlio"
                            />
                          }
                          label={
                            this.state.companyConfig &&
                            this.state.companyConfig.chatlio === true
                              ? "Chatlio is Enabled"
                              : "Chatlio is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Sound`}
                    key={5}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.notificationToBeChecked
                              }
                              onChange={this.handleNotificationActive}
                              name="notification"
                            />
                          }
                          label={
                            this.state.companyConfig.notificationToBeChecked ===
                            true
                              ? "Notification Screening is Enabled"
                              : "Notification Screening is disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.companyConfig.soundHPF}
                              onChange={this.handleSoundHPFActive}
                              name="soundHPF"
                            />
                          }
                          label={
                            this.state.companyConfig.soundHPF === true
                              ? "SoundHPF is Enabled"
                              : "SoundHPF is disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Config`}
                    key={6}
                  >
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.companyConfig.overallrms}
                              onChange={this.handleOverallRmsActive}
                              name="overallrms"
                            />
                          }
                          label={
                            this.state.companyConfig.overallrms === true
                              ? "Overall RMS is Enabled"
                              : "Overall RMS is Disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.sensorHealthDashboard
                              }
                              onChange={(e) => {
                                this.handleConfigActive(
                                  "sensorHealthDashboard",
                                  e
                                );
                              }}
                              name="Sensor Health Dashboard"
                            />
                          }
                          label={
                            this.state.companyConfig.sensorHealthDashboard ===
                            true
                              ? "Sensor Health Dashboard is Enabled"
                              : "SensorHealth Dashboard is Disabled"
                          }
                        />{" "}
                      </Col>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.energyEfficiency
                              }
                              onChange={(e) => {
                                this.handleConfigActive("energyEfficiency", e);
                              }}
                              name="Energy Efficiency"
                            />
                          }
                          label={
                            this.state.companyConfig.energyEfficiency === true
                              ? "Energy Efficiency is Enabled"
                              : "Energy Efficiency is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Export Data`}
                    key={7}
                  >
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.companyConfig.exportDataToPtc}
                              onChange={(e) => {
                                this.handleConfigActive("exportDataToPtc", e);
                              }}
                              name="Export Data to PTC"
                            />
                          }
                          label={
                            this.state.companyConfig.exportDataToPtc === true
                              ? "Export Data to PTC is Enabled"
                              : "Export Data to PTC is Disabled"
                          }
                        />{" "}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 500,
                    }}
                    tab={`CDP Session`}
                    key={8}
                  >
                    <Row>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Number of default CDP Session
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={this.handleDefaultCDPSessions}
                          options={this.state.CDPCount}
                          value={this.state.CDPCount[this.state.selectedCount]}
                        />
                      </Col>
                      <Col></Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Maintaince`}
                    key={9}
                  >
                    <Row>
                      <Col class="float-right">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.companyConfig.maintenanceLog}
                              onChange={(e) => {
                                this.handleConfigActive("maintenanceLog", e);
                              }}
                              name="Maintenance Log"
                            />
                          }
                          label={
                            this.state.companyConfig.maintenanceLog === true
                              ? "Maintenance Log is Enabled"
                              : "Maintenance Log is Disabled"
                          }
                        />{" "}
                      </Col>

                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                            style={{ fontWeight: "bold" }}
                          >
                            Unit{" "}
                          </label>
                          {this.state.unitTypes && (
                            <Select
                              className="form-control-alternative"
                              onChange={this.handleUnitChange}
                              placeholder="Select UnitType"
                              options={this.state.unitTypes}
                              value={
                                this.state.unitTypes[this.state.selectedUnit]
                              }
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 600,
                    }}
                    tab={`Vibration`}
                    key={10}
                  >
                    <Row>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-RMS Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_rms_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_rms_unit")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Peak Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_peak_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_peak_unit")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Spectrum Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_spectrum_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_spectrum_unit")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Spectrum Domain
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_spectrum_domain",
                              value
                            );
                          }}
                          options={Vibration_OrderDomains}
                          value={Vibration_OrderDomains.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_spectrum_domain")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waterfall Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_waterfall_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_waterfall_unit")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waterfall Domain
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_waterfall_domain",
                              value
                            );
                          }}
                          options={Vibration_Waterfall_Domains}
                          value={Vibration_Waterfall_Domains.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_waterfall_domain")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waveform Unit
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_waveform_unit",
                              value
                            );
                          }}
                          options={Vibration_Units}
                          value={Vibration_Units.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_waveform_unit")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                      <Col>
                        <label
                          className="form-control-label mt-3"
                          htmlFor="input-first-name"
                        >
                          Default Vibration-Waveform Domain
                        </label>
                        <Select
                          className="form-control-alternative"
                          onChange={({ value }) => {
                            this.handleCompanyConfigChange(
                              "vib_waveform_domain",
                              value
                            );
                          }}
                          options={Vibration_TimeDomains}
                          value={Vibration_TimeDomains.find(
                            ({ value }) =>
                              value ===
                              this.getCompanyConfigValue("vib_waveform_domain")
                          )}
                          // isDisabled={this.state.readOnly}
                          // isDisabled={
                          //   this.state.company.config.dailyConsolidatedAlarm &&
                          //   !this.state.company.config.dailyConsolidatedAlarm.active
                          // }
                        />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Threshold`}
                    key={11}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.thresholdSharing.active
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "thresholdSharing",
                                  "active",
                                  e
                                );
                              }}
                              name="Active"
                            />
                          }
                          label={
                            this.state.companyConfig.thresholdSharing.active ===
                            true
                              ? "ThresholdSharing is Active"
                              : "ThresholdSharing is Inactive"
                          }
                        />{" "}
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Keys
                            <span style={{ color: "orange", fontSize: "12px" }}>
                              (
                              {this.state.companyConfig.thresholdSharing.active
                                ? "select atleast one of the keys"
                                : "set Threshold Sharing active"}
                              )
                            </span>
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            closeMenuOnSelect={false}
                            isClearable={true}
                            isMulti
                            options={this.state.thresholdSharingTypes}
                            value={this.state.selectedKeys}
                            onChange={this.handleMultiSelectChange}
                            isDisabled={
                              !this.state.companyConfig.thresholdSharing.active
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Relayr Export`}
                    key={12}
                  >
                    <Row>
                      <Col>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.companyConfig.dataExport.active
                              }
                              onChange={(e) => {
                                this.handleChildProperty(
                                  "dataExport",
                                  "active",
                                  e
                                );
                              }}
                              name="Relayr Export"
                            />
                          }
                          label={
                            this.state.companyConfig.dataExport.active === true
                              ? "Relayr Export is Active"
                              : "Relayr Export is Inactive"
                          }
                        />{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            URL
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.companyConfig.dataExport.url}
                            onChange={(e) =>
                              this.handleChildPropertyInput(
                                "dataExport",
                                "url",
                                e
                              )
                            }
                            disabled={
                              !this.state.companyConfig.dataExport.active
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            key
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.companyConfig.dataExport.key}
                            onChange={(e) =>
                              this.handleChildPropertyInput(
                                "dataExport",
                                "key",
                                e
                              )
                            }
                            disabled={
                              !this.state.companyConfig.dataExport.active
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 350,
                    }}
                    tab={`Dynamic Dashboard`}
                    key={13}
                  >
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.isDynamicDashboardDetails}
                              onChange={this.handleDynamicDashboardDetails}
                              name="isDynamicDashboardDetails"
                            />
                          }
                          label={"Dynamic Dashboard?"}
                        />{" "}
                      </Col>
                      <Col class="float-left">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Upload Image
                          </label>
                          <input
                            type="file"
                            ref={(input) => {
                              this.filesInput = input;
                            }}
                            accept={[".jpg", ".jpeg", ".png"]}
                            name="file"
                            disabled={!this.state.isDynamicDashboardDetails}
                            placeholder={this.state.fileName}
                            onChange={async (event) => {
                              if (
                                event.target.files[0] &&
                                event.target.files[0].size > 2000000
                              ) {
                                alert("File Size should be less than 2MB");
                                return;
                              }

                              const file = event.target.files[0];
                              const name = event.target.files[0].name;
                              const base64 = await this.convertBase64(file);
                              this.setState({
                                imageUrl: base64,
                                fileName: name,
                              });
                              // console.log(e);
                            }}
                          />
                          {this.state.fileName ? (
                            <span>Uploaded Image - {this.state.fileName}</span>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.isNameInFooter}
                              onChange={this.handleisNameInFooter}
                              name="isDynamicDashboardDetails"
                            />
                          }
                          label={"Is Company Name in footer?"}
                        />{" "}
                      </Col>
                      <Col class="float-left">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={this.state.IsHyperlinkActive}
                              onChange={this.handleIsHyperlinkActive}
                              name="isDynamicDashboardDetails"
                            />
                          }
                          label={"Is Hyperlink Active?"}
                        />{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Title Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.Dashboardtittle}
                            onChange={(e) => {
                              let companyConfig = {
                                ...this.state.companyConfig,
                              };
                              companyConfig["dynamicDashboardetails"] = {
                                ...companyConfig.dynamicDashboardetails,
                                Dashboardtittle: e.target.value,
                              };
                              this.setState({
                                Dashboardtittle: e.target.value,
                                companyConfig,
                              });
                            }}
                            disabled={!this.state.isDynamicDashboardDetails}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Company Url
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            value={this.state.DashboardUrl}
                            onChange={(e) => {
                              let companyConfig = {
                                ...this.state.companyConfig,
                              };
                              companyConfig["dynamicDashboardetails"] = {
                                ...companyConfig.dynamicDashboardetails,
                                DashboardUrl: e.target.value,
                              };
                              this.setState({
                                DashboardUrl: e.target.value,
                                companyConfig,
                              });
                            }}
                            disabled={!this.state.isDynamicDashboardDetails}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Background Color
                          </label>
                          <ColorPicker
                            name="color"
                            defaultValue={this.state.das_backgroundColor}
                            value={this.state.das_backgroundColor} //- for controlled component
                            onChange={(color) => this.handleColorChanges(color)}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            // htmlFor='input-last-name'
                            style={{ fontWeight: "bold" }}
                          >
                            Text Color
                          </label>
                          <ColorPicker
                            name="color"
                            defaultValue={this.state.das_textColor}
                            value={this.state.das_textColor} //- for controlled component
                            onChange={(color) =>
                              this.handleTextColorChanges(color)
                            }
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane
                    style={{
                      height: 500,
                    }}
                    tab={`Timezone`}
                    key={14}
                  >
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                            style={{ fontWeight: "bold" }}
                          >
                            Timezone{" "}
                            <span className="text-danger">
                              * (This will effect the Date & Time parameter in
                              the notifications)
                            </span>
                          </label>
                          {this.state.timezones && (
                            <Select
                              className="form-control-alternative"
                              isClearable={true}
                              onChange={this.handleSelectChange.bind(
                                this,
                                "timezone",
                                "add"
                              )}
                              placeholder=""
                              options={this.state.timezones}
                              value={
                                this.state.timezones[this.state.defaultTimezone]
                              }
                              // isDisabled={this.state.readOnly}
                            />
                          )}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                            style={{ fontWeight: "bold" }}
                          >
                            Temperature Unit{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <br></br>
                          {
                            <FormGroup check>
                              <Label
                                style={
                                  this.state.companyConfig.temperature === "C"
                                    ? { color: "green" }
                                    : {}
                                }
                                className="inline"
                                check
                              >
                                <Input
                                  type="radio"
                                  name="temperature"
                                  value="C"
                                  onChange={this.handleRadioChange.bind(
                                    this,
                                    "temperature"
                                  )}
                                  // disabled={this.state.readOnly}
                                  checked={
                                    this.state.companyConfig.temperature == "C"
                                      ? true
                                      : false
                                  }
                                />
                                Celsius
                              </Label>

                              <Label
                                style={
                                  this.state.companyConfig.temperature === "F"
                                    ? { color: "green" }
                                    : {}
                                }
                                className="inline"
                                check
                              >
                                <Input
                                  type="radio"
                                  name="temperature"
                                  value="F"
                                  onChange={this.handleRadioChange.bind(
                                    this,
                                    "temperature"
                                  )}
                                  // disabled={this.state.readOnly}
                                  checked={
                                    this.state.companyConfig.temperature == "F"
                                      ? true
                                      : false
                                  }
                                />
                                Fahrenheit
                              </Label>
                            </FormGroup>
                          }
                        </FormGroup>
                      </Col>
                    </Row>
                  </TabPane>
                </TabsAnt>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleConfigClose} color="danger">
                  Cancel
                </Button>
                <Button onClick={this.handleConfigSave} color="success">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth={true}
              maxWidth={"xl"}
              scroll={"body"}
              open={this.state.themeConfigOpen}
              onClose={this.handleThemeConfigClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ textAlign: "center" }}
              >
                Company Theme Configurations
              </DialogTitle>
              <DialogContent style={{ height: "500px" }}>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Text Color (
                        {this.state.themeConfig.primaryTextColor
                          ? this.state.themeConfig.primaryTextColor
                          : null}
                      </label>
                      <ColorPicker
                        style={{ color: "black" }}
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Text Color (
                        {this.state.themeConfig.secondaryTextColor
                          ? this.state.themeConfig.secondaryTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Body Text Color (
                        {this.state.themeConfig.bodyTextColor
                          ? this.state.themeConfig.bodyTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.bodyTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("bodyTextColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Background Color (
                        {this.state.themeConfig.primaryBackgroundColor
                          ? this.state.themeConfig.primaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Background Color (
                        {this.state.themeConfig.secondaryBackgroundColor
                          ? this.state.themeConfig.secondaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryBackgroundColor
                        }
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button Color (
                        {this.state.themeConfig.primaryButtonColor
                          ? this.state.themeConfig.primaryButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Button Color (
                        {this.state.themeConfig.secondaryButtonColor
                          ? this.state.themeConfig.secondaryButtonColor
                          : null}
                        )
                      </label>

                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Tertiary Button Color (
                        {this.state.themeConfig.tertiaryButtonColor
                          ? this.state.themeConfig.tertiaryButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.tertiaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "tertiaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Link Button Color (
                        {this.state.themeConfig.linkButtonColor
                          ? this.state.themeConfig.linkButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.linkButtonColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("linkButtonColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Color (
                        {this.state.themeConfig.primaryColor
                          ? this.state.themeConfig.primaryColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("primaryColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Color (
                        {this.state.themeConfig.secondaryColor
                          ? this.state.themeConfig.secondaryColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("secondaryColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Tertiary Color (
                        {this.state.themeConfig.tertiaryColor
                          ? this.state.themeConfig.tertiaryColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.tertiaryColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges("tertiaryColor", color)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button Text Color (
                        {this.state.themeConfig.primaryButtonTextColor
                          ? this.state.themeConfig.primaryButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Button Text Color (
                        {this.state.themeConfig.secondaryButtonTextColor
                          ? this.state.themeConfig.secondaryButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Link Button Text Color (
                        {this.state.themeConfig.linkButtonTextColor
                          ? this.state.themeConfig.linkButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.linkButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "linkButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Tertiary Button Text Color (
                        {this.state.themeConfig.tertiaryButtonTextColor
                          ? this.state.themeConfig.tertiaryButtonTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.tertiaryButtonTextColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "tertiaryButtonTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button OnHover Color (
                        {this.state.themeConfig.primaryButtonOnHoverColor
                          ? this.state.themeConfig.primaryButtonOnHoverColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryButtonOnHoverColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryButtonOnHoverColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Button OnHover Color (
                        {this.state.themeConfig.secondaryButtonOnHoverColor
                          ? this.state.themeConfig.secondaryButtonOnHoverColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryButtonOnHoverColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryButtonOnHoverColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Graph Color (
                        {this.state.themeConfig.primaryGraphColor
                          ? this.state.themeConfig.primaryGraphColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.primaryGraphColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "primaryGraphColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Graph Color(
                        {this.state.themeConfig.secondaryGraphColor
                          ? this.state.themeConfig.secondaryGraphColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.themeConfig &&
                          this.state.themeConfig.secondaryGraphColor
                        }
                        onChange={(color) =>
                          this.handleThemeColorChanges(
                            "secondaryGraphColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleThemeConfigClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={this.handleThemeConfigSave}
                  color="primary"
                  disabled={this.state.saving}
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Save"
                  )}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth={true}
              maxWidth={"md"}
              scroll={"body"}
              open={this.state.loginThemeConfigOpen}
              onClose={this.handleLoginThemeConfigClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                id="form-dialog-title"
                style={{ textAlign: "center" }}
              >
                Company Login Theme Config
              </DialogTitle>
              <DialogContent style={{ height: "250px" }}>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Button Color (
                        {this.state.loginThemeConfig.primaryButtonColor
                          ? this.state.loginThemeConfig.primaryButtonColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.primaryButtonColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "primaryButtonColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Body Text Color (
                        {this.state.loginThemeConfig.bodyTextColor
                          ? this.state.loginThemeConfig.bodyTextColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.bodyTextColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "bodyTextColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Primary Background Color (
                        {this.state.loginThemeConfig.primaryBackgroundColor
                          ? this.state.loginThemeConfig.primaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.primaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "primaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Secondary Background Color (
                        {this.state.loginThemeConfig.secondaryBackgroundColor
                          ? this.state.loginThemeConfig.secondaryBackgroundColor
                          : null}
                        )
                      </label>
                      <ColorPicker
                        name="color"
                        defaultValue={"Selected Color"}
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.secondaryBackgroundColor
                        }
                        onChange={(color) =>
                          this.handleLoginThemeColorChanges(
                            "secondaryBackgroundColor",
                            color
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Background Image Upload
                      </label>
                      <input
                        type="file"
                        ref={(input) => {
                          this.filesInput = input;
                        }}
                        accept={[".jpg", ".jpeg", ".png"]}
                        name="file"
                        placeholder={this.state.loginBackGroundFileName}
                        onChange={async (event) => {
                          const file = event.target.files[0];
                          const name = event.target.files[0].name;
                          const base64 = await this.convertBase64(file);
                          this.setState({
                            loginBackGroundImageUrl: base64,
                            loginBackGroundFileName: name,
                          });
                          // console.log(e);
                        }}
                      />
                      {this.state.loginBackGroundFileName ? (
                        <span>
                          Uploaded Image - {this.state.loginBackGroundFileName}
                        </span>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Logo Image Upload
                      </label>
                      <input
                        type="file"
                        ref={(input) => {
                          this.filesInput = input;
                        }}
                        accept={[".jpg", ".jpeg", ".png"]}
                        name="file"
                        placeholder={this.state.loginLogoFileName}
                        onChange={async (event) => {
                          const file = event.target.files[0];
                          const name = event.target.files[0].name;
                          const base64 = await this.convertBase64(file);
                          this.setState({
                            loginLogoImageUrl: base64,
                            loginLogoFileName: name,
                          });
                        }}
                      />
                      {this.state.loginLogoFileName ? (
                        <span>
                          Uploaded Image Logo - {this.state.loginLogoFileName}
                        </span>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Domain Name
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-email"
                        type="text"
                        placeholder="nanoprecisese.com or sensata.ai"
                        value={
                          this.state.loginThemeConfig &&
                          this.state.loginThemeConfig.domainName
                        }
                        onChange={(e) => {
                          this.state.loginThemeConfig["domainName"] =
                            e.target.value;
                          this.setState({
                            loginThemeConfig:
                              this.state.loginThemeConfig &&
                              this.state.loginThemeConfig,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.handleLoginThemeConfigClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleLoginThemeConfigSave}
                  color="primary"
                  disabled={this.state.saving}
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Save"
                  )}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              scroll={"body"}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Upload Frequency</DialogTitle>
              <DialogContent style={{ height: "250px" }}>
                <Select
                  className="form-control-alternative"
                  isClearable={false}
                  onChange={this.handleSelectChange.bind(
                    this,
                    "upload_frequency",
                    "add"
                  )}
                  placeholder=""
                  options={this.state.frequency}
                  value={this.state.frequency[this.state.selectedFrequency]}
                  // isDisabled={this.state.readOnly}
                  fullWidth
                  margin="dense"
                  autoFocus
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleClose} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              scroll={"body"}
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Upload Frequency</DialogTitle>
              <DialogContent style={{ height: "250px" }}>
                <Select
                  className="form-control-alternative"
                  isClearable={false}
                  onChange={this.handleSelectChange.bind(
                    this,
                    "upload_frequency",
                    "add"
                  )}
                  placeholder=""
                  options={this.state.frequency}
                  value={this.state.frequency[this.state.selectedFrequency]}
                  fullWidth
                  margin="dense"
                  autoFocus
                />
                <br></br>
                {this.state.sensorsUploadFrequency.length > 0 && (
                  <center>
                    <h4 style={{ color: "green" }}>Sensors Updated</h4>
                  </center>
                )}
                {this.state.sensorsUploadFrequency.length > 0 &&
                  this.state.sensorsUploadFrequency.map((sensor) => {
                    return (
                      sensor.resStatus == 200 && (
                        <Chip
                          style={{
                            border: "1px solid #2dce89",
                            color: "#000",
                            margin: "2px",
                          }}
                          label={sensor.sensorId}
                          color="primary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
                <br></br>
                <br></br>
                {this.state.sensorsUploadFrequencyError.length > 0 && (
                  <center>
                    <h4 style={{ color: "#f5365c" }}>
                      Electric Imp Exception in Sensors
                    </h4>
                  </center>
                )}
                {this.state.sensorsUploadFrequencyError.length > 0 &&
                  this.state.sensorsUploadFrequencyError.map((sensor) => {
                    return (
                      sensor.resStatus != 200 && (
                        <Chip
                          style={{
                            border: "1px solid #f5365c",
                            color: "#000",
                            margin: "2px",
                          }}
                          label={sensor.sensorId}
                          color="primary"
                          variant="outlined"
                        />
                      )
                    );
                  })}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="danger">
                  Close
                </Button>
                <Button
                  onClick={this.handleUploadRateSubmit}
                  color="success"
                  disabled={this.state.saving}
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              fullWidth={true}
              maxWidth={"md"}
              scroll={"body"}
              open={this.state.codesOpen}
              onClose={this.handleCodesClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent style={{ height: "500px" }}>
                <h3>Notification codes</h3>
                <div>
                  <Tabs onSelect={this.handleTabChange}>
                    <TabList>
                      {this.state.notificationCodes &&
                        this.state.notificationCodes.map((x, i) => (
                          <Tab key={i} style={{ color: "#e57373" }}>
                            {x.appId}
                          </Tab>
                        ))}
                    </TabList>

                    {/* {this.state.notificationCodes &&
                  this.state.notificationCodes[this.state.currentTabIndex] &&
                  this.state.notificationCodes[this.state.currentTabIndex]
                    .codes &&
                  Object.keys(
                    this.state.notificationCodes[this.state.currentTabIndex]
                      .codes
                  ). */}
                    {this.state.notificationCodesTemp &&
                      this.state.notificationCodesTemp.map((y) => {
                        return y.id.startsWith(
                          String(this.state.currentTabIndex)
                        ) ? (
                          y.key === "code_fault_customer" ? (
                            <Container maxWidth="sm">
                              <ExpansionPanel>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions1-content"
                                  id="additional-actions1-header"
                                >
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <Checkbox
                                        checked={y.apply}
                                        onChange={(e) => {
                                          this.checkCode(y.id, "check", e);
                                        }}
                                      ></Checkbox>
                                    }
                                    label={y.key}
                                  />
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Row key={y.key}>
                                    <Col xs={6} lg={6}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        Fault Stage
                                      </label>
                                      <Select
                                        className="form-control-alternative"
                                        onChange={(e) => {
                                          this.checkCode(
                                            y.id,
                                            "faultStage",
                                            parseInt(e.value)
                                          );
                                        }}
                                        options={this.state.faultStages}
                                        isDisabled={!y.apply}
                                        value={
                                          this.state.faultStages.filter((x) => {
                                            return x.label == y.faultStage;
                                          })[0]
                                        }
                                      />
                                    </Col>
                                    <Col xs={6} lg={6}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                      >
                                        RUL
                                      </label>
                                      <Input
                                        style={{ height: "40px" }}
                                        className="form-control-alternative"
                                        type="number"
                                        value={y.rul}
                                        onChange={(e) => {
                                          this.checkCode(
                                            y.id,
                                            "rul",
                                            parseFloat(e.target.value)
                                          );
                                        }}
                                        disabled={!y.apply}
                                      />
                                    </Col>
                                  </Row>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Container>
                          ) : (
                            <Container maxWidth="sm">
                              <ExpansionPanel
                              // expanded={this.state.expanded === 'panel' + i}
                              // onChange={this.handleExpandChange('panel' + i)}
                              >
                                <ExpansionPanelSummary
                                  // expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions1-content"
                                  id="additional-actions1-header"
                                >
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <Checkbox
                                        checked={y.apply}
                                        onChange={(e) => {
                                          this.checkCode(y.id, "check", e);
                                        }}
                                      ></Checkbox>
                                    }
                                    label={y.key}
                                  />
                                </ExpansionPanelSummary>
                              </ExpansionPanel>
                            </Container>
                          )
                        ) : null;
                      })}
                  </Tabs>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCodesClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.processDirectCodes} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              maxWidth="lg"
              onClose={() => {
                this.setState({ isFeedbackConfigManual: false });
              }}
              aria-labelledby="customized-dialog-title"
              open={this.state.isFeedbackConfigManual}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  this.setState({ isFeedbackConfigManual: false });
                }}
              >
                Maintenance Type
              </DialogTitle>
              <Button
                onClick={() => {
                  this.setState({
                    isCreateMaintenanceType:
                      !this.state.isCreateMaintenanceType,
                  });
                }}
              >
                {!this.state.isCreateMaintenanceType ? "Create New" : "Back"}
              </Button>
              {this.state.isCreateMaintenanceType ? (
                <DialogContent dividers>
                  <div>
                    <Input
                      placeHolder="Name"
                      name="name"
                      onChange={(e) => {
                        this.setState({ maintenanceTypeName: e.target.value });
                      }}
                    />
                    <Input
                      placeHolder="Description"
                      name="desc"
                      onChange={(e) => {
                        this.setState({ maintenanceTypeDesc: e.target.value });
                      }}
                    />
                    {/* <Input
                  placeHolder="Key"
                  name="val"
                  onChange={(e) => {
                    this.setState({ maintenanceTypeVal: e.target.value });
                  }}
                /> */}
                    <Button
                      onClick={async () => {
                        let tempCheck = false;
                        if (!this.state.maintenanceTypeName) {
                          alert("Please Enter Name!");
                          return;
                        }
                        this.state.maintenanceTypeList.map((val) => {
                          if (val.val === this.state.maintenanceTypeVal) {
                            alert("Key Already Exists");
                            tempCheck = true;
                          }
                          if (val.name === this.state.maintenanceTypeName) {
                            alert("Name Already Exists");
                            tempCheck = true;
                          }
                        });
                        if (this.state.maintenanceTypeName === null) {
                          alert("Kindly change the name");
                          tempCheck = true;
                        }
                        if (tempCheck) {
                          this.setState({
                            maintenanceTypeVal: 0,
                            maintenanceTypeName: null,
                          });
                          return;
                        }

                        if (window.confirm("Are you Sure you want to Add?")) {
                          let obj = {
                            val: this.state.maintenanceTypeVal || 0,
                            name: this.state.maintenanceTypeName,
                            desc: this.state.maintenanceTypeDesc,
                            resetFaultDetection: false,
                            maintenanceWatch: false,
                            noAction: true,
                          };
                          await axios.postData(
                            `/feedbackLog/maintenanceTypes/maintenanceTypesAdd/${companyId}`,
                            obj
                          );
                          this.props.fetchMaintenanceTypes(companyId);
                          toast.success("Successfully Added!");
                          this.setState({
                            isCreateMaintenanceType: false,
                            maintenanceTypeVal: 0,
                            maintenanceTypeName: null,
                            maintenanceTypeDesc: null,
                          });
                        } else {
                          // this.setState({
                          //   isCreateMaintenanceType: false,
                          //   maintenanceTypeVal: null,
                          //   maintenanceTypeName: null,
                          //   maintenanceTypeDesc: null,
                          // });
                          return;
                        }
                      }}
                    >
                      Done
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({
                          isCreateMaintenanceType: false,
                          maintenanceTypeVal: 0,
                          maintenanceTypeName: null,
                          maintenanceTypeDesc: null,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </DialogContent>
              ) : (
                <DialogContent dividers>
                  <div>
                    {this.state.maintenanceTypeList &&
                      this.state.maintenanceTypeList.map((val) => {
                        return (
                          <ExpansionPanel>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <div>
                                <Typography>
                                  <b>{val.name}</b>
                                </Typography>
                              </div>
                              <span> </span>
                              <div>
                                <Typography>{val.desc}</Typography>
                              </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <FormGroup tag="fieldset">
                                <div>
                                  <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                    <i
                                      className="fa fa-trash"
                                      onClick={async () => {
                                        if (
                                          window.confirm(
                                            "Are you Sure you want to delete?"
                                          )
                                        ) {
                                          await axios.postData(
                                            `/feedbackLog/maintenanceTypes/maintenanceTypesDel/${companyId}`,
                                            { val: val.val }
                                          );
                                          toast.success(
                                            "Successfully Deleted!"
                                          );
                                          this.props.fetchMaintenanceTypes(
                                            companyId
                                          );
                                        } else {
                                          this.setState({
                                            isFeedbackConfigManual: false,
                                          });
                                          this.props.fetchMaintenanceTypes(
                                            companyId
                                          );
                                          return;
                                        }
                                      }}
                                    />
                                  </div>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        name={val.val}
                                        value="rstFaultDetect"
                                        checked={val.resetFaultDetection}
                                        onChange={(e) =>
                                          this.handleOptionChange(e, val)
                                        }
                                      />{" "}
                                      Reset Fault Detection
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="checkbox"
                                        name={val.val}
                                        value="mainTenanceWatch"
                                        checked={val.maintenanceWatch}
                                        onChange={(e) =>
                                          this.handleOptionChange(e, val)
                                        }
                                      />{" "}
                                      Maintenance Watch
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div>
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        type="radio"
                                        name={val.val}
                                        value="noAction"
                                        checked={val.noAction}
                                        onChange={(e) =>
                                          this.handleOptionChange(e, val)
                                        }
                                      />{" "}
                                      No Action
                                    </Label>
                                  </FormGroup>
                                </div>
                              </FormGroup>
                            </ExpansionPanelDetails>
                            <Divider />
                          </ExpansionPanel>
                        );
                      })}
                  </div>
                </DialogContent>
              )}
            </Dialog>
          </>
        )}
        ;
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  notifData: _.isEmpty(state.companies.notifData)
    ? null
    : state.companies.notifData,
  companyDetailsData: _.isEmpty(state.companies.companyDetailsData)
    ? null
    : state.companies.companyDetailsData,
  themeConfigData: _.isEmpty(state.companies.themeConfigData)
    ? null
    : state.companies.themeConfigData,
  loginThemeConfigData: _.isEmpty(state.companies.loginThemeConfigData)
    ? null
    : state.companies.loginThemeConfigData,
  maintenanceTypesData: _.isEmpty(state.feedback.maintenanceTypesData)
    ? null
    : state.feedback.maintenanceTypesData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanyDetails: (companyId) =>
      dispatch(fetchCompanyDetails(companyId)),
    fetchNotifCount: (companyId, booleanVar) =>
      dispatch(fetchNotifCount(companyId, booleanVar)),
    fetchThemeConfig: (companyId) => dispatch(fetchThemeConfig(companyId)),
    fetchLoginThemeConfig: (companyId) =>
      dispatch(fetchLoginThemeConfig(companyId)),
    fetchMaintenanceTypes: (companyId) =>
      dispatch(fetchMaintenanceTypes(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
