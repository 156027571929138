import {
    FETCH_FIRMWARE_TYPES,
    FETCH_FIRMWARE_DETAILS,
  } from "../actions/firmwareTypes.action";
  
  const firmwareTypesReducer = (state = {}, action) => {
    switch (action.type) {
      case FETCH_FIRMWARE_TYPES:
        return {
          ...state,
          firmwareTypesData: action.payload,
        };
      case FETCH_FIRMWARE_DETAILS:
        return {
          ...state,
          firmwareTypesDetailsData: action.payload,
        };
      default:
        // need this for default case
        return state;
    }
  };
  export default firmwareTypesReducer;
  