import CallAPI from "../../axios";

export const FETCH_DASHBOARD_MANUAL_DATA = "FETCH_DASHBOARD_MANUAL_DATA";
export const FETCH_DASHBOARD_MANUAL_DETAILS_DATA =
  "FETCH_DASHBOARD_MANUAL_DETAILS_DATA";

const fetchDashboardManualData = (data) => {
  return {
    type: FETCH_DASHBOARD_MANUAL_DATA,
    payload: data,
  };
};

const fetchDashboardManualDetailsData = (data) => {
  return {
    type: FETCH_DASHBOARD_MANUAL_DETAILS_DATA,
    payload: data,
  };
};

export const fetchDashboardManual = () => {
  return (dispatch) => {
    CallAPI.getDashboardManual()
      .then((response) => {
        dispatch(fetchDashboardManualData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchDashboardManualDetails = (manualId) => {
  return (dispatch) => {
    CallAPI.getDashboardManual_byId(manualId)
      .then((response) => {
        dispatch(fetchDashboardManualDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
