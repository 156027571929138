import React from "react";
import { connect } from "react-redux";
import {
  fetchEquipmentType,
  fetchEquipmentDetails,
  fetchRpmRatioData,
} from "../../../../redux/actions/equipmentType.action";
import { fetchGroup } from "../../../../redux/actions/companies.action";
import { fetchComponentOptions } from "../../../../redux/actions/componentTypes.action";
import { fetchEquipmentSubType } from "../../../../redux/actions/equipmentSubTypes.action";
import { fetchEquipmentSubTypeDropDown } from "../../../../redux/actions/equipmentSubTypes.action";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import Select from "react-select";
import _ from "lodash";
import DIYQuestEquip from "./equipmentConfigForm/index";
import ImageUploader from "react-images-upload";
import BootstrapTable from "react-bootstrap-table-next";
import TableHeaderColumn from "react-bootstrap-table-next";
import InsertButton from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import MaterialIcon, { colorPalette } from "material-icons-react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
// core components

import classes from "./equipments.css";
import axios from "../../../../axios";
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";
import DIYQuest from "./componentConfigForm/index";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

//Datatable configurations for components

const { SearchBar } = Search;

// function strong(cell, row) {
//   if (1) {
//     return (
//       // <span>
//       //   <strong style={ { fontWeight: 'bold' } }>{ cell }</strong>
//       // </span>
//       <a href="javascript:void(0)" onClick={
//         // this.jump('/v1/components/' + row.companyId + '/' + row.equipmentId + '/' + row.componentId, 0)
//         }>
//           {/* <i class="fa fa-pencil" aria-hidden="true"></i> */}
//           {/* <Button className="btn btn-sm btn-info"></Button> */}
//           <MaterialIcon icon="open_in_new" color='#0288d1'/>
//       </a>
//     );
//   }
// }

const options = {
  showTotal: true,
};
const rpmTypeData = [
  { key: 0, value: 0, label: "variable" },
  { key: 1, value: 1, label: "constant" },
];

//Datatable configurations end
let companyId = "";
let equipmentId = "";
let machineId = "";
const linkFormatter = (cell, row, rowIndex) => {
  console.log(cell);
  if (row && row.url) {
    return (
      <a href={row.url} target="_blank">
        <MaterialIcon icon="get_app" color="#0288D1" />
      </a>
    );
  } else {
    return (
      <MaterialIcon
        icon="get_app"
        color="#0288D1"
        onClick={() => downloadPDF(row)}
      />
    );
  }
};

const getReport = async (row) => {
  let equipmentId = row.equipmentId;

  let reportData = await axios.fileDataReport_get(
    row.fileObj.fileName,
    companyId
  );
  if (reportData.status === 200) {
    return reportData.data.data;
  }
};

const downloadPDF = async (row) => {
  let report = await getReport(row);
  let linkSource = report.file;
  let fileName = report.fileName;

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    console.log("edge and explorer");
    linkSource = linkSource.replace("data:application/pdf;base64,", "");
    const byteCharacters = atob(linkSource);

    let byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    console.log("other browsers");
    let downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
};

const deleteFormater = (cell, row, rowIndex) => {
  // console.log(cell);
  return (
    // <a href={cell} target="_blank">
    //   Download
    // </a>
    <button style={{ width: 100, height: 40 }} onClick={() => console.log("")}>
      {" "}
      Delete{" "}
    </button>
  );
};
let portal_user = "";

class EquipmentDetails extends React.Component {
  constructor(props) {
    super(props);
    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    equipment: {},
    equipmentBackup: {},
    equipmentImages: [],
    equipmentTypes: [],
    groups: [],
    readOnly: true,
    optData: [],
    defaultOption: 0,
    defaultOptionBackup: 0,
    defaultSubOption: 0,
    defaultSubOptionBackup: 0,
    defaultOptionGroup: 0,
    defaultOptionBackupGroup: 0,
    //Components state
    components: null,
    list: true,
    componentTypes: [],
    selectedComponentType: null,
    saving: false,
    reportResponse: [],
    popoverOpen: false,
    currentSensor: null,
    report: {
      companyId: "",
      file: "",
      name: "",
      equipmentId: "",
      timestamp: "",
      fileName: "",
    },
    reportColumns: [
      {
        text: "EquipmentId",
        dataField: "equipmentId",
        sort: true,
      },
      {
        text: "Equipment Name",
        dataField: "name",
        sort: true,
      },
      {
        text: "Upload Date",
        dataField: "timestamp",
        sort: true,
      },
      {
        text: "Action",
        dataField: "fileObj",
        sort: false,
        formatter: linkFormatter,

        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            // console.log(columnIndex, row.url, rowIndex);
            // {
            //   row.url;
            // }
            // <Link>
            // <button onClick={row.url}> </button>;
            // </Link>;
          },
        },
      },
      {
        text: "",
        dataField: "url",
        sort: false,
        formatter: this.delete,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            if (row._id) {
              this.removeSensor(row._id, row);
            }
          },
        },
      },
    ],
    columns: [
      {
        text: "Component Name",
        dataField: "customName",
        sort: true,
      },
      {
        text: "Component Id",
        dataField: "componentId",
        sort: true,
      },
      {
        text: "Component Type",
        dataField: "componentType",
        sort: true,
      },
      {
        text: "Tag Id",
        dataField: "tagId",
        sort: true,
        // formatter: strong,
      },
      {
        text: "Sensor Id",
        dataField: "tag.tagConfigMaps.sensorConfig.sensorId",
        sort: true,
        formatter: this.strong.bind(this),
      },
      {
        text: "Process Configuration",
        dataField: "tag.tagConfigMaps.sensorConfig.sensorId",
        sort: false,
        formatter: this.action,
        events: {
          onClick: async (e, column, columnIndex, row, rowIndex) => {
            if (row.sensorId === null) {
              toast.error("Please assign the sensor");
              return;
            } else {
              this.jump(
                `/v1/companies/${companyId}/sensors/${row.sensorId}/tags/${row.tagId}`,
                0,
                {
                  componentDetails: row,
                  plantName: this.state.equipment.plantName,
                  currentPage: 4,
                  tagId: row.tagId,
                  backUrl: `/v1/companies/${companyId}/machines/${machineId}/equipments/${equipmentId}`,
                }
              );
              return;
            }
          },
        },
      },
      {
        text: "DIY Configuration",
        dataField: "DIYQuestionaireId",
        sort: false,
        formatter: this.action,
        events: {
          onClick: async (e, column, columnIndex, row, rowIndex) => {
            console.log(row, "******row");
            // let details = await axios.configTypeDetails(row.tagId);
            // if (details.data.data.isOldConfig) {
            //   toast.error("Already assigned old config!");
            //   return;
            // }
            if (row.componentSubType === "suckerRod") {
              toast.error("Cannot configure for sub-component sucker-rod");
              return;
            } else if (row.componentType === "transformer") {
              toast.error("Cannot configure for component transformer");
              return;
            }
            this.setState({ openDIYAns: true, componentDIY: row });
          },
        },
      },
      // {
      //   text: "DIY Questionaire",
      //   dataField: "DIYQuestionaireId",
      //   sort: false,
      //   formatter: this.action,
      //   events: {
      //     onClick: async (e, column, columnIndex, row, rowIndex) => {
      //       await this.getDIYQuestionaire(row.tagId);
      //       this.setState({ openDIY: true });
      //     },
      //   },
      // },
      {
        text: "Action",
        dataField: "equipmentId",
        sort: false,
        formatter: this.action,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.jump(
              "/v1/companies/" +
                companyId +
                "/machines/" +
                machineId +
                "/equipments/" +
                row.equipmentId +
                "/components/" +
                row.componentId,
              0,
              row
            );
          },
        },
      },
      {
        text: "Delete",
        dataField: "equipmentId",
        sort: false,
        formatter: this.delete,
        events: {
          onClick: async (e, column, columnIndex, row, rowIndex) => {
            if (window.confirm("Do you really want to delete it?")) {
              await axios.assetManage_ComponentDelete(row.componentId);
              toast.success("Deleted Successfully");
              await this.getComponents();
            }
          },
        },
      },
    ],
    open: false,
    openDIY: false,
    openDIYAns: false,
    DIYQuestionaire: null,
    frequency: [
      {
        value: 1,
        label: "1 Per Day",
      },
      {
        value: 2,
        label: "2 Per Day",
      },
      {
        value: 4,
        label: "4 Per Day",
      },
      {
        value: 6,
        label: "6 Per Day",
      },
    ],
    selectedFrequency: -1,
    saving: false,
    sensorsUploadFrequency: [],
    sensorsUploadFrequencyError: [],
    createAllowed: false,
    modal: false,
    rpmRatioSelected: [],
    ratioDropDownData: [],
    primaryList: [],
    linkedList: [],
    primaryListTemp: [],
    linkedListTemp: [],
    currentPrimary: null,
    currentRatio: null,
    currentLinked: null,
    currentItem: null,
    finalDataPush: [],
    isDisableRpmRatio: false,
    rpmTypeSelected: null,
    rpmType: null,
    rpmTypeModal: false,
    localRows: [],
    equipmentSubTypeDropDown: [],
    currentQuest: null,
    images: [],
  };

  async deleteReport(row) {
    console.log(row, "kunal");
  }

  clearField(input) {
    input.target.value = null;
  }

  removeSensor = async (sensorId, row) => {
    if (window.confirm("Are You sure you want to delete this report?")) {
      if (window.confirm("please confirm!")) {
        await axios.fileDataReport_delete(row.fileObj.fileName, companyId,equipmentId);
        let endpoint = "/reports/" + row._id;
        let api2 = await axios.deleteReq(endpoint);
      } else return;
    } else return;
  };
  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    equipmentId = +this.props.match.params.equipmentId;
    machineId = this.props.match.params.machineId;

    //Checking Authorization
    let slug = portal_user.department.slug;
    if (["hardware", "admin"].includes(slug)) {
      //Allowing to Change Upload Frequency to hardware group users
      this.setState({
        createAllowed: true,
        // 'rpmTypeSelected': 1,
        // 'rpmType': [{ key: 0, value: 0, label: 'variable' }, { key: 1, value: 1, label: 'constant' }]
      });
    }

    // await this.getReport();
    // await this.getEquipment();
    this.props.fetchEquipmentDetails(equipmentId); ///do same for subtypes

    //added
    // this.props.fetchRpmRatioData({ equipmentId });

    // await this.getGroupOptions();
    this.props.fetchGroup(companyId);

    // await this.getEquipmentTypeOptions();
    this.props.fetchEquipmentType(companyId);

    await this.getComponents();

    await this.getComponentTypeOptions();
    let image = await axios.fileDataImage_get(
      `${this.props.match.params.equipmentId}.jpg`,
      companyId
    );
    // let componentsSelected = await axios.rpmRatio_get({ equipmentId });
    // if (componentsSelected) console.log("tttttttttttttttt", componentsSelected);

    // this.setState({
    //   ratio_data: componentsSelected && componentsSelected.data,
    // });

    let equipConfig = await axios.equipConfig_details(
      this.props.match.params.equipmentId
    );
    this.setState({
      images: image && image.data && image.data.data && image.data.data.file ? [image.data.data.file] : [],
      rpmType: equipConfig && equipConfig.data && equipConfig.data.data && equipConfig.data.data.hasOwnProperty("isConstantSpeed")
        ? equipConfig.data.data.isConstantSpeed
          ? "constant"
          : "variable"
        : null,
      isDisableRpmRatio: equipConfig && equipConfig.data && equipConfig.data.data.hasOwnProperty("isConstantSpeed")
        ? false
        : true
    });
  }
  async getReport(fileName = null) {
    // console.log(equipmentId);

    let endpoint = "/reports/" + companyId + "/" + +equipmentId;
    let reportData = await axios.fileDataReport_get(fileName, companyId);
    if (reportData.status === 200) {
      this.setState({ reportResponse: reportData.data.data });
      console.log(reportData.data.data);
    }
  }

  // async getEquipmentTypeOptions() {
  //   let endpoint = "/equipments/types";
  //   let equipmentTypes = await axios.equipmentType_get(companyId);
  //   let options = [];
  //   console.log("equipmentTypesequipmentTypesequipmentTypes:", equipmentTypes);
  //   equipmentTypes.data.data.forEach((eq, i) => {
  //     options.push({
  //       key: i,
  //       label: `${eq.customName} -> (${eq.equipmentTypeCode})`,
  //       value: eq.equipmentTypeCode,
  //     });
  //   });

  //   let index = options.findIndex(
  //     (x) =>
  //       x.value === this.state.equipment &&
  //       this.state.equipment.equipmentTypeCode
  //   );
  //   console.log(
  //     "index",
  //     index,
  //     options,
  //     "**************\n",
  //     this.state.equipment
  //   );
  //   this.setState({ defaultOption: index, defaultOptionBackup: index });
  //   this.setState({ equipmentTypes: options });
  // }

  async getGroupOptions() {
    let groups = await axios.plantManage_plantList(companyId);
    let options = [];
    groups.data.data.forEach((eq, i) => {
      options.push({
        key: i,
        label: `${eq.groupName} -> (${eq.plantId})`,
        value: eq.plantId,
      });
    });

    let index = options.findIndex(
      (x) => x.value === this.state.equipment.plantId
    );
    this.setState({
      defaultOptionGroup: index,
      defaultOptionBackupGroup: index,
    });
    this.setState({ groups: options });
  }

  async getEquipment() {
    let api = await axios.equipmentManage_equipmentDetails(equipmentId);
    if (api && api.data.data) {
      localStorage.setItem("equipmentName", api.data.data.customName);
      this.setState({
        equipment: api.data.data,
        equipmentBackup: api.data.data,
      });
    }
  }

  async getComponentTypeOptions() {
    let componentTypes = await axios.componentType_get();
    let equipTypes = await axios.equipmentType_get(),
      options = [];
    if (
      componentTypes &&
      this.state.equipmentTypes.length > 0 &&
      this.state.defaultOption !== -1
    ) {
      Object.keys(componentTypes.data.data).forEach((eq, i) => {
        if (eq !== "_id") {
          equipTypes.data.data[
            this.state.equipmentTypes[this.state.defaultOption].value
          ].map((val) => {
            if (val === eq) {
              options.push({
                key: i,
                label: `${eq}`,
                value: eq,
              });
            }
          });
        }
      });
    }
    this.setState({ componentTypes: options });
  }

  async getComponents(componentType = null) {
    let endpoint = "/components/" + companyId + "/" + equipmentId;
    if (componentType) {
      endpoint += "/" + componentType;
      this.setState({
        components:
          this.state.components &&
          this.state.components.filter((val) => {
            return val.componentType === componentType;
          }),
      });
      return;
    }
    let components = await axios.assetManage_ComponentListOfEquipment(
      equipmentId
    );
    if (components && components.data && components.data.status === "failed") {
      return;
    }
    let temp = [
        {
          isLocal: true,
          tagId: null,
          linkedTagId: null,
          primary: true,
          ratioConfigID: null,
          ratio: "",
        },
      ],
      tempDropDownData = [];

    // for (
    //   let index = 0;
    //   index < components && components.data && components.data.data.length;
    //   index++
    // ) {
    //   if (components.data.status !== "failed") {
       
    //   }
    //   // this.getSelectedComponent(components.data.data);
    // }
    components && components.data && components.data.data.map((row) => {
      row["key"] = row.tagId;
      row["label"] = row.customName;
      row["value"] = row.tagId;
      tempDropDownData.push(row);
    })

    let assignedListSens = await axios.sensorManage_AssignedList(companyId);

    components &&
      components.data &&
      components.data.data.map((val, key) => {
        if (
          assignedListSens &&
          assignedListSens.data &&
          assignedListSens.data.status !== "failed" &&
          _.find(assignedListSens.data.data, { tagId: val.tagId })
        ) {
          components.data.data[key]["sensorId"] = _.find(
            assignedListSens.data.data,
            { tagId: val.tagId }
          ).sensorId;
        } else {
          components.data.data[key]["sensorId"] = null;
        }
      });

    this.setState({
      components: components && components.data && components.data.data,
      rpmRatioSelected: temp,
      ratioDropDownData: tempDropDownData,
      primaryList: tempDropDownData,
      linkedList: tempDropDownData,
      primaryListTemp: tempDropDownData,
      linkedListTemp: tempDropDownData,
    });
  }

  async getSelectedComponent(components) {
    let rpmRatio = [],
      alreadySelectedComponents = [];
    components &&
      components.map((val) => {
        rpmRatio.push(val.tagId);
      });
    let componentsSelected = await axios.rpmRatio_get({ equipmentId });

    componentsSelected =
      componentsSelected &&
      componentsSelected.data &&
      componentsSelected.data.data;

    componentsSelected = _.filter(componentsSelected, "primary"); //filter only primary here


    if (componentsSelected.length > 0) {
      this.setState({ rpmRatioSelected: componentsSelected });
      componentsSelected.map((val) => {
        // alreadySelectedComponents.push(val.tagId);
        alreadySelectedComponents.push(val.linkedTagId);
      });

      alreadySelectedComponents = _.uniq(alreadySelectedComponents);


      //primary data
      alreadySelectedComponents.map((e) => {
        this.setState({
          primaryList:
            this.state.primaryList &&
            this.state.primaryList.filter((val) => {
              return val.value != e;
            }),
          primaryListTemp:
            this.state.primaryList &&
            this.state.primaryList.filter((val) => {
              return val.value != e;
            }),
        });
      });

      //linked list
      componentsSelected.map((val) => {
        alreadySelectedComponents.push(val.tagId);
        // alreadySelectedComponents.push(val.linkedTagId);
      });
      alreadySelectedComponents = _.uniq(alreadySelectedComponents);

      alreadySelectedComponents.map((e) => {
        this.setState({
          linkedList:
            this.state.linkedList &&
            this.state.linkedList.filter((val) => {
              return val.value != e;
            }),
          linkedListTemp:
            this.state.linkedList &&
            this.state.linkedList.filter((val) => {
              return val.value != e;
            }),
        });
      });
    }
  }

  async getDIYQuestionaire(tagId) {
    let endpoint = "/components/questionare/" + tagId;
    let DIYQuestionaire = await axios.getData(endpoint);
    let DIYQuestionaireData = {};
    if (DIYQuestionaire && DIYQuestionaire.data.data) {
      for (let record in DIYQuestionaire.data.data.DIYQuestionaireAndAnswer) {
        let data = DIYQuestionaire.data.data.DIYQuestionaireAndAnswer[record];
        console.log(data);
        if (data) {
          if (record === "faultDetection") {
            for (let fault in data) {
              let faultData = data[fault];
              if (faultData) {
                if (fault === "bearing") {
                  DIYQuestionaireData[faultData.ques] = faultData.answer;
                  if (faultData.answer === "Yes") {
                    DIYQuestionaireData[faultData.bearingType.ques] =
                      faultData.bearingType.answer;
                    if (
                      faultData.bearingType.answer === "Anti-Friction Bearing"
                    ) {
                      DIYQuestionaireData[faultData.bearingNumber.ques] =
                        faultData.bearingNumber.answer;
                    }
                  }
                } else if (fault === "gearbox") {
                  DIYQuestionaireData[faultData.ques] = faultData.answer;
                  if (faultData.answer === "Yes") {
                    DIYQuestionaireData[faultData.teethNumber.ques] =
                      faultData.teethNumber.answer;
                    DIYQuestionaireData[faultData.gearRatio.ques] =
                      faultData.gearRatio.answer;
                  }
                } else if (fault === "ACMotor") {
                  DIYQuestionaireData[faultData.ques] = faultData.answer;
                  if (faultData.answer === "Yes") {
                    DIYQuestionaireData[faultData.frequency.ques] =
                      faultData.frequency.answer;
                    DIYQuestionaireData[faultData.NumberOfRotorBars.ques] =
                      faultData.NumberOfRotorBars.answer;
                  }
                } else if (fault === "PumpOrFan") {
                  DIYQuestionaireData[faultData.ques] = faultData.answer;
                  if (faultData.answer === "Yes") {
                    DIYQuestionaireData[faultData.numberOfVanes.ques] =
                      faultData.numberOfVanes.answer;
                  }
                } else if (fault === "gasCompressor") {
                  DIYQuestionaireData[faultData.ques] = faultData.answer;
                  if (faultData.answer === "Yes") {
                    DIYQuestionaireData[faultData.sensorLocation.ques] =
                      faultData.sensorLocation.answer;
                  }
                }
              }
            }
          } else if (record === "isRpm") {
            DIYQuestionaireData[data.ques] = data.answer;
            DIYQuestionaireData[data.primaryComponent.ques] =
              data.primaryComponent.answer;
            DIYQuestionaireData[data.RPMRatio.ques] = data.RPMRatio.answer;
          } else if (record === "turnedOnPercent") {
            DIYQuestionaireData[data.ques] = data.answer;
            if (data.answer === "< 70%") {
              DIYQuestionaireData[data.runTime.ques] = data.runTime.answer;
            }
          } else if (record === "tagId") {
            DIYQuestionaireData[record] = data;
          } else {
            DIYQuestionaireData[data.ques] = data.answer;
          }
        }
      }
      DIYQuestionaireData["configId"] = DIYQuestionaire.data.data.configId;
    } else {
      DIYQuestionaireData = null;
    }
    this.setState({ DIYQuestionaire: DIYQuestionaireData });
  }

  handleChange = (input) => (e) => {
    // if (input === "equipmentId") {
    //   this.props.fetchEquipmentSubTypeDropDown(e.target.value);
    // }

    let equipment = { ...this.state.equipment };
    equipment[input] = e.target.value;
    this.setState({ equipment: equipment });
  };

  handleEdit(edit) {
    // this.props.fetchEquipmentSubTypeDropDown(
    //   this.state.equipment && this.state.equipment.equipmentTypeCode
    // );
    this.setState({ readOnly: edit });
  }

  handleCancel() {
    let equipmentBackup = { ...this.state.equipmentBackup };
    this.setState({
      equipment: equipmentBackup,
      defaultOption: this.state.defaultOptionBackup,
      defaultOptionGroup: this.state.defaultOptionBackupGroup,
      readOnly: true,
      equipmentImages: [],
    });
  }

  handleSelectChange =
    (type, key = null) =>
    async (option) => {
      if (type === "equipment") {
        let equipment = { ...this.state.equipment };
        if (key === "plantId") {
          equipment.plantId = option.value;
          this.setState({ equipment, defaultOptionGroup: option.key });
        }
        if (key === "equipmentType") {
          // this.props.fetchEquipmentSubTypeDropDown(option.value);
          equipment.equipmentTypeCode = option.value;
          delete equipment.equipmentType;
          this.setState({ equipment, defaultOption: option.key });
        }
        if (key === "equipmentSubType") {
          equipment.equipmentSubTypeCode = option.value;
          this.setState({ equipment, defaultSubOption: option.key });
        }
      } else if (type === "component") {
        let selectedComponentType = option ? option.value : null;
        await this.getComponents(selectedComponentType);
      } else if (type === "uploadFrequency") {
        let index = this.state.frequency.findIndex(
          (x) => x.value === option.value
        );
        this.setState({ selectedFrequency: index });
      }
    };

  handleUploadRateSubmit = async () => {
    if (this.state.selectedFrequency < 0) {
      toast.warn("Select Upload Frequency");
      return;
    }
    this.setState({ saving: true });

    let endpoint = "/upload-frequency/equipments";

    let data = {
      equipmentId: equipmentId,
      uploadRate: this.state.frequency[this.state.selectedFrequency].value,
    };

    let api = await axios.postData(endpoint, data);
    if (api.status === 200) {
      if (api.data.length <= 0) {
        toast.warn("No sensor link found in ...");
      } else {
        toast.success("Operation Successfull");
        let sensorsUploadFrequencyError =
          api.data &&
          api.data.filter((sensor) => {
            return sensor.resStatus != 200;
          });

        this.setState({
          saving: false,
          sensorsUploadFrequency: api.data,
          sensorsUploadFrequencyError: sensorsUploadFrequencyError,
        });
      }
    } else if (api.status === 400) {
      toast.warn(api.data.error);
      this.setState({ saving: false });
    } else {
      toast.error("Something went wrong. Please try again.");
      this.setState({ saving: false });
    }
    // this.handleClose()
  };

  onDrop = (pictureFiles, pictureData) => {
    let equipment = { ...this.state.equipment };
    equipment.imagesUrl = pictureData.length > 0 ? pictureData : null;
    this.setState({ equipment: equipment });
  };

  onDropAdd = (pictureFiles, pictureData) => {
    let equipmentImages = { ...this.state.equipmentImages };
    equipmentImages = pictureData;
    this.setState({ equipmentImages });
  };

  async handleSave() {
    this.setState({ saving: true });
    let equipment = {
      ...this.state.equipment,
    };
    if (equipment.equipmentType) {
      equipment.equipmentTypeCode = equipment.equipmentType;
      delete equipment.equipmentType;
    }
    //Validating
    let err = await validation.validate(equipment);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    // delete equipment._id;
    // if (this.state.equipmentImages.length)
    //   equipment.equipmentImages = this.state.equipmentImages);

    let endpoint = "/equipments";

    let equipmentIdtemp = equipment.equipmentId;

    equipment = {
      customName: equipment.customName,
      machineId: equipment.machineId,
      externalId: equipment.externalId,
      equipmentType: equipment.equipmentTypeCode
    };

    if (this.state.equipmentImages.length) {
      let compImage = {
        file: this.state.equipmentImages[0],
        fileName: `${equipmentIdtemp}.jpg`,
        companyId: companyId,
      };
      let api2 = await axios.fileDataImage_create(compImage);
      toast.success("Image Uploaded successfully");
    }

    let api = await axios.equipmentManage_update(equipmentIdtemp, equipment);
    if (api && api.status == 200) {
      toast.success("Equipment saved successfully");
      this.setState({
        readOnly: true,
        equipment: api.data.data,
        equipmentImages: [],
      });
      localStorage.setItem("equipmentName", api.data.data.customName);
      // this.jump('/v1/machines/' + companyId + '/' + machineId)

      let backUrl = "";
      if (machineId === "0000")
        backUrl =
          "/v1/companies/" +
          companyId +
          "/machines/" +
          machineId +
          "/equipments";
      else backUrl = "/v1/companies/" + companyId + "/machines/" + machineId;

      this.jump(backUrl);
    } else if (api && api.status === 400) {
      toast.warn(api.data.message);
    } else if (api && api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  jump = (url, delay = 1000, passedVal = null) => {
    setTimeout(() => {
      this.props.history.push({ pathname: url, state: passedVal });
    }, delay);
  };

  action(cell, row) {
    let _this = this;
    return <MaterialIcon icon="open_in_new" color="#0288d1"></MaterialIcon>;
  }
  delete(cell, row) {
    let _this = this;
    return <MaterialIcon icon="delete" color="#ff0000" />;
  }
  deleteAction(cell, row) {
    let _this = this;
    return <MaterialIcon icon="open_in_new" color="#0288d1"></MaterialIcon>;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseDIY = () => {
    this.setState({ openDIY: false });
  };

  strong(cell, row) {
    if (row.sensorId !== null) {
      return (
        <div>
          <Label>{row.sensorId}</Label>
          <br />
          <Button
            color="primary"
            onClick={() => {
              this.setState({
                popoverOpen: !this.state.popoverOpen,
                currentSensor: row,
              });
            }}
            size="sm"
          >
            Manage Sensor
          </Button>
        </div>
      );
    }
    return (
      <Button
        color="primary"
        onClick={async () => {
          let assignedList = await axios.sensorManage_AssignedList(companyId);
          if (
            _.find(
              assignedList && assignedList.data && assignedList.data.data,
              { tagId: row.tagId }
            )
          ) {
            toast.info("Already assigned to sensor!");
            return;
          }
          this.props.history.push(`/v1/companies/${companyId}/sensors/add`, {
            componentDetails: row,
            plantName: this.state.equipment.plantName,
          });
        }}
        size="sm"
      >
        Assign
      </Button>
    );
  }

  handleFileInput = async (e) => {
    if (e.target.files[0] && e.target.files[0].size > 2000000) {
      alert("File Size should be less than 2MB");
      return;
    }
    let fileName = e.target.files[0] ? e.target.files[0].name : "";
    let file = "";
    if (fileName) {
      file = await this.toBase64(e.target.files[0]);
    }
    // console.log(fileName);

    this.setState({
      fileName,
      file,
    });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async handleSaveReport() {
    console.log("fffff", this.state.fileName)
    if (typeof this.state.fileName === "undefined") {
      alert("Please upload file");
      return;
    }
    if (this.state.file === "") {
      alert("Please upload file with valid size");
      return;
    }
    this.setState({ saving: true });
    this.state.report.companyId = this.state.equipment.companyId;
    this.state.report.equipmentId = this.state.equipment.equipmentId;
    this.state.report.file = this.state.file;
    this.state.report.fileName = this.state.fileName;
    this.state.report.name = this.state.equipment.customName;
    this.state.report.timestamp = parseInt(
      (new Date().getTime() / 1000) * 1000
    );

    // let report = {...this.state.report};
    // report.timestamp = this.state.timestamp
    // report.file = this.state.file
    // //Validating
    // let testGroup = {...report};
    // let err = await validation.validate(testGroup);
    // if (err) {
    //   toast.warn(err);
    //   this.setState({saving: false});
    //   return;
    // }

    // report.companyId = companyId;
    // report.fileName = this.state.fileName;

    let api = await axios.fileDataReport_create({
      file: this.state.report.file,
      fileName: this.state.report.fileName,
      companyId: this.state.report.companyId,
      equipmentId: this.state.equipment.equipmentId,
    });
    this.setState({ saving: false });
    if (api && api.status == 200) {
      // let endpoint = "/reports/" + companyId;
      // let api && api2 = await axios.postData(endpoint, {
      //   companyCode: companyId,
      //   equipmentId: this.state.equipment.equipmentId,
      //   timestamp: parseInt((new Date().getTime() / 1000) * 1000),
      //   fileName: this.state.report.fileName,
      // });

      toast.success("Report saved successfully");
      localStorage.removeItem("companyId");
      this.setState({ list: true });
      // this.setState({report.fileName:""})
      await this.getReport(this.state.report.fileName);
    } else if (api && api.status == 400) {
      toast.warn("Failed to save");
    } else if (api && api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }else {
      toast.warn("Failed to save");
    }
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let equipmentDetails = null,
      optionsEquipTypeData = [],
      optionsEquipSubTypeData = [],
      index = 0,
      indexSub = 0,
      reportResponse = null,
      optionsGroup = [],
      indexGrp = 0,
      optionsComp = [],
      equipmentSubTypeDropDown = [];

    if (!prevState.readOnly) {
      if (
        nextProps.equipmentSubTypeDropDown !==
        prevState.equipmentSubTypeDropDown
      ) {
        if (nextProps.equipmentSubTypeDropDown) {
          nextProps.equipmentSubTypeDropDown.forEach((eq, i) => {
            equipmentSubTypeDropDown.push({
              key: i,
              label: `${eq.customName} -> (${eq.equipmentSubTypeCode})`,
              value: eq.equipmentSubTypeCode,
            });
          });
        }
        indexSub = equipmentSubTypeDropDown.findIndex(
          (x) => x.value === prevState.equipment.equipmentSubTypeCode
        );
        console.log(
          "indexSub1",
          indexSub,
          prevState.equipment.equipmentSubTypeCode
        );
      }
      return {
        equipmentSubTypeDropDown,
        defaultSubOption: indexSub,
        defaultSubOptionBackup: indexSub,
      };
    }
    if (nextProps.equipDetailsData !== prevState.equipDetailsData) {
      if (nextProps.equipDetailsData) {
        equipmentDetails = nextProps.equipDetailsData.data;
        localStorage.setItem(
          "equipmentName",
          nextProps.equipDetailsData.data &&
            nextProps.equipDetailsData.data.customName
        );
      }
    }

    // if (
    //   nextProps.equipmentSubTypeDropDown !== prevState.equipmentSubTypeDropDown
    // ) {
    //   if (nextProps.equipmentSubTypeDropDown) {
    //     nextProps.equipmentSubTypeDropDown.forEach((eq, i) => {
    //       equipmentSubTypeDropDown.push({
    //         key: i,
    //         label: `${eq.customName} -> (${eq.equipmentSubTypeCode})`,
    //         value: eq.equipmentSubTypeCode,
    //       });
    //     });
    //     indexSub = equipmentSubTypeDropDown.findIndex(
    //       (x) => x.value === prevState.equipment.equipmentSubTypeCode
    //     );
    //     console.log('indexSub2', indexSub);
    //   }
    // }

    if (nextProps.equipTypeData !== prevState.equipTypeData) {
      if (nextProps.equipTypeData) {
        let keyForoptionEquipTypeData = 0;
        Object.keys(nextProps.equipTypeData.data).forEach((eq, i) => {
          if (eq !== "_id") {
            optionsEquipTypeData.push({
              key: keyForoptionEquipTypeData,
              label: `${eq}`,
              value: eq,
            });
            keyForoptionEquipTypeData = keyForoptionEquipTypeData + 1;
          }
        });
        index = optionsEquipTypeData.findIndex(
          (x) => x.value === prevState.equipment.equipmentType
        );
      }
    }
    if (nextProps.equipmentSubTypeData !== prevState.equipmentSubTypeData) {
      if (nextProps.equipmentSubTypeData) {
        nextProps.equipmentSubTypeData.data.forEach((eq, i) => {
          equipmentSubTypeDropDown.push({
            key: i,
            label: `${eq.customName} -> (${eq.equipmentSubTypeCode})`,
            value: eq.equipmentSubTypeCode,
          });
        });
        indexSub = equipmentSubTypeDropDown.findIndex(
          (x) => x.value === prevState.equipment.equipmentSubTypeCode
        );
        console.log("indexSub3", indexSub);
      }
    }

    if (nextProps.equipReportsData !== prevState.equipReportsData) {
      if (nextProps.equipReportsData) {
        reportResponse = nextProps.equipReportsData.data;
      }
    }

    if (nextProps.groupData !== prevState.groupData) {
      if (nextProps.groupData) {
        nextProps.groupData.data.forEach((eq, i) => {
          optionsGroup.push({
            key: i,
            label: `${eq.groupName} -> (${eq.plantId})`,
            value: eq.plantId,
          });
        });

        if (_.isEmpty(prevState.equipment)) {
          indexGrp = 0;
        } else {
          indexGrp = optionsGroup.findIndex(
            (x) => x.value === prevState.equipment.plantId
          );
        }
      }
    }

    return {
      equipment: equipmentDetails !== null ? equipmentDetails : {},
      equipmentBackup: equipmentDetails !== null ? equipmentDetails : {},
      defaultOption: index,
      defaultOptionBackup: index,
      defaultSubOption: indexSub,
      defaultSubOptionBackup: indexSub,
      equipmentTypes: optionsEquipTypeData,
      reportResponse,
      defaultOptionGroup: indexGrp,
      defaultOptionBackupGroup: indexGrp,
      groups: optionsGroup,
      equipmentSubTypeDropDown: equipmentSubTypeDropDown,
    };
  }

  render() {
    let backUrl = "";
    if (machineId === "0000")
      backUrl =
        "/v1/companies/" + companyId + "/machines/" + machineId + "/equipments";
    else backUrl = "/v1/companies/" + companyId + "/machines/" + machineId;
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12" className="table-caption">
              <h1>Equipment Details</h1>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump(backUrl, 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <Button
                        style={{
                          background: "#fff",
                          borderColor: "#fff",
                        }}
                        onClick={async () => {
                          // this.setState({ open: true });
                          if (
                            window.confirm("Are you Sure you want to change?")
                          ) {
                            let data = await axios.feedBack_maintenanceWatch({
                              equipmentId: this.state.equipment.equipmentId,
                              requestType: "dataUploadRate",
                              dataUploadRate: 12,
                              duration: 24,
                            });
                            toast("Success!!!");
                          }
                        }}
                        size="xs"
                      >
                        Maintenace Watch
                      </Button>

                      <Button
                        style={{
                          background: "#fff",
                          borderColor: "#fff",
                        }}
                        onClick={async () => {
                          // this.setState({ open: true });
                          if (
                            window.confirm("Are you Sure you want to change?")
                          ) {
                            let data = await axios.feedBack_resetFaultDetection(
                              {
                                equipmentId: this.state.equipment.equipmentId,
                                requestType: "dataUploadRate",
                                dataUploadRate: 12,
                                duration: 24,
                              }
                            );
                            toast("Success!!!");
                          }
                        }}
                        size="xs"
                      >
                        Reset Fault Detection
                      </Button>

                      <Button
                        style={{
                          background: "#fff",
                          borderColor: "#fff",
                        }}
                        onClick={async () => {
                          if (
                            window.confirm("Are you Sure you want to change?")
                          ) {
                            let data = await axios.feedBack_syncUploadTime({
                              equipmentId: `${this.state.equipment.equipmentId}`,
                            });
                            // toast(data.data.data.cdpResponse.processStatus);
                          }
                        }}
                        size="xs"
                      >
                        Sync Time Measurement
                      </Button>
                    </Col>
                    <Col className="text-right" xs="4">
                      {/* {this.state.createAllowed && (
                        <Button
                          style={{
                            background: "#df7126",
                            borderColor: "#df7126",
                          }}
                          color="primary"
                          onClick={() => {
                            this.setState({ open: true });
                          }}
                          size="xs"
                        >
                          Upload Frequency
                        </Button>
                      )} */}
                      {this.state.readOnly && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.handleEdit(false)}
                          size="md"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Equipment Names <span className="text-danger">*</span>
                        </label>
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            type="text"
                            value={this.state.equipment.customName}
                            onChange={this.handleChange("customName")}
                            disabled={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Equipment Id
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="text"
                            value={this.state.equipment.equipmentId}
                            onChange={this.handleChange("equipmentId")}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Equipment Type{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isClearable={false}
                            isDisabled={this.state.readOnly || this.state.equipment.equipmentType === "transformer"}
                            onChange={this.handleSelectChange(
                              "equipment",
                              "equipmentType"
                            )}
                            placeholder=""
                            value={
                              this.state.equipmentTypes[
                                this.state.defaultOption
                              ]
                            }
                            options={this.state.equipmentTypes}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-plant"
                          >
                            Plant :
                          </label>
                          <br />
                          {this.state.equipment.plantName}
                        </FormGroup>
                      </Col>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Equipment Sub Type{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            isClearable={false}
                            isDisabled={this.state.readOnly}
                            onChange={this.handleSelectChange(
                              "equipment",
                              "equipmentSubType"
                            )}
                            placeholder=""
                            value={
                              this.state.equipmentSubTypeDropDown[
                                this.state.defaultSubOption
                              ]
                            }
                            options={this.state.equipmentSubTypeDropDown}
                          />
                        </FormGroup>
                      </Col> */}
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            External ID{" "}
                            <Tooltip
                              title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "30px",
                                }}
                              />
                            </Tooltip>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            value={this.state.equipment.externalId}
                            onChange={this.handleChange("externalId")}
                            disabled={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        Upload Report (2MB Max){" "}
                        <span className="text-danger">*</span>
                        {this.state.readOnly === true ? (
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="file"
                              accept="application/pdf, application/vnd.ms-excel"
                              onChange={this.handleFileInput}
                            />

                            <Row>
                              <Col lg="12">
                                {
                                  <Button
                                    className="right"
                                    color="success"
                                    onClick={() => this.handleSaveReport()}
                                    size="md"
                                    disabled={this.state.saving}
                                  >
                                    {this.state.saving ? (
                                      <i className="fas fa-spinner fa-pulse"></i>
                                    ) : (
                                      "Upload"
                                    )}
                                  </Button>
                                }

                                {!this.state.saving && (
                                  <Button
                                    className="right cmr-10"
                                    color="danger"
                                    onClick={() =>
                                      this.setState({
                                        fileName: " ",
                                        file: " ",
                                      })
                                    }
                                    size="md"
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </FormGroup>
                        ) : null}
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          {!this.state.readOnly && (
                            <div className="image-container-add">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Add Equipment Images
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                labelClass="label"
                                onChange={this.onDropAdd}
                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                maxFileSize={2000000} //2MB
                                withPreview={true}
                                label="Max file size: 2MB, accepted: jpg|png"
                              />
                            </div>
                          )}

                          <div
                            className={
                              this.state.readOnly ? "image-container " : ""
                            }
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Equipment Images
                            </label>
                            <ImageUploader
                              withIcon={true}
                              buttonText="Choose images"
                              labelClass="label"
                              onChange={this.onDrop}
                              imgExtension={[".jpg", ".jpeg", ".png"]}
                              maxFileSize={2000000} //2MB
                              withPreview={true}
                              label="Max file size: 2MB, accepted: jpg|png"
                              defaultImages={this.state.images}
                            />
                          </div>
                        </FormGroup>
                        {!this.state.readOnly && (
                          <Button
                            className="right"
                            color="success"
                            onClick={() => this.handleSave()}
                            size="md"
                            disabled={this.state.saving}
                          >
                            {this.state.saving ? (
                              <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        )}

                        {!this.state.readOnly && !this.state.saving && (
                          <Button
                            className="right cmr-10"
                            color="danger"
                            onClick={() => this.handleCancel()}
                            size="md"
                          >
                            Cancel
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                  {/* <Row> */}
                  <div className="col">
                    <Card className="bg-secondary shadow">
                      {this.state.reportResponse && this.state.list && (
                        <ToolkitProvider
                          keyField="_id"
                          columns={this.state.columns}
                          data={this.state.components}
                          search
                        >
                          {(props) => (
                            <div>
                              <SearchBar {...props.searchProps} />
                              <BootstrapTable
                                options={options}
                                keyField="_id"
                                {...props.baseProps}
                                data={this.state.reportResponse}
                                columns={this.state.reportColumns}
                                pagination={paginationFactory(options)}
                                striped
                                hover
                              ></BootstrapTable>
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </Card>
                  </div>
                  {/* </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Components */}
          {/* <br/>
          <br/>
          <br/>
          <br/> */}

          <Row>
            <Col lg="12 table-caption">
              <h1 style={{ color: "#000" }}>Components</h1>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Label check>
                <h3> RPM Type:</h3>
              </Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    checked={this.state.rpmType === "constant" ? true : false}
                    name="radio1"
                    onClick={() => {
                      if (
                        this.state.equipment.equipmentType === "transformer"
                      ) {
                        toast.error("Cannot configure transformer");
                        return;
                      }
                      this.setState({
                        rpmTypeModal: true,
                        rpmTypeSelected: "constant",
                      });
                    }}
                  />{" "}
                  Constant{"    "}
                </Label>
                <Label check>
                  {"   "}
                  <Input
                    type="radio"
                    checked={this.state.rpmType === "variable" ? true : false}
                    name="radio1"
                    onClick={() => {
                      if (
                        this.state.equipment.equipmentType === "transformer"
                      ) {
                        toast.error("Cannot configure transformer");
                        return;
                      }
                      this.setState({
                        rpmTypeModal: true,
                        rpmTypeSelected: "variable",
                      });
                    }}
                  />{" "}
                  Variables
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="7">
              {this.state.list && (
                <div>
                  <Button
                    color="success"
                    onClick={() => {
                      localStorage.setItem("machineId", machineId);
                      this.jump(
                        `/v1/companies/${companyId}/machines/${machineId}/equipments/${equipmentId}/components/add`,
                        0,
                        {
                          equipmentType:
                            this.state.equipmentTypes[this.state.defaultOption]
                              .value,
                        }
                      );
                    }}
                    size="md"
                    // className='add-btn'
                  >
                    Add Component
                  </Button>

                  <Button
                    color="success"
                    disabled={this.state.isDisableRpmRatio}
                    onClick={() => {
                      this.getSelectedComponent(this.state.components);
                      this.setState({ modal: true });
                    }}
                    size="md"
                    // className='add-btn'
                  >
                    RPM Ratio
                  </Button>
                </div>
              )}
            </Col>
            <Col lg="5">
              {this.state.list && (
                <Select
                  // className='add-btn'
                  isClearable={true}
                  onChange={this.handleSelectChange("component")}
                  placeholder="Component Type"
                  options={this.state.componentTypes}
                />
              )}
            </Col>
          </Row>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.components && this.state.list && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={this.state.columns}
                    data={this.state.components}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          options={options}
                          keyField="_id"
                          {...props.baseProps}
                          data={this.state.components}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        ></BootstrapTable>
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          scroll={"body"}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Upload Frequency</DialogTitle>
          <DialogContent style={{ height: "250px" }}>
            <Select
              className="form-control-alternative"
              isClearable={false}
              onChange={this.handleSelectChange("uploadFrequency")}
              placeholder=""
              options={this.state.frequency}
              value={this.state.frequency[this.state.selectedFrequency]}
              fullWidth
              margin="dense"
              autoFocus
            />
            <br></br>
            {this.state.sensorsUploadFrequency.length > 0 && (
              <center>
                <h4 style={{ color: "green" }}>Sensors Updated</h4>
              </center>
            )}
            {this.state.sensorsUploadFrequency.length > 0 &&
              this.state.sensorsUploadFrequency.map((sensor) => {
                return (
                  sensor.resStatus == 200 && (
                    <Chip
                      style={{
                        border: "1px solid #2dce89",
                        color: "#000",
                        margin: "2px",
                      }}
                      label={sensor.sensorId}
                      color="primary"
                      variant="outlined"
                    />
                  )
                );
              })}
            <br></br>
            <br></br>
            {this.state.sensorsUploadFrequencyError.length > 0 && (
              <center>
                <h4 style={{ color: "#f5365c" }}>
                  Electric Imp Exception in Sensors
                </h4>
              </center>
            )}
            {this.state.sensorsUploadFrequencyError.length > 0 &&
              this.state.sensorsUploadFrequencyError.map((sensor) => {
                return (
                  sensor.resStatus != 200 && (
                    <Chip
                      style={{
                        border: "1px solid #f5365c",
                        color: "#000",
                        margin: "2px",
                      }}
                      label={sensor.sensorId}
                      color="primary"
                      variant="outlined"
                    />
                  )
                );
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="danger">
              Close
            </Button>
            <Button
              onClick={this.handleUploadRateSubmit}
              color="success"
              disabled={this.state.saving}
            >
              {this.state.saving ? (
                <i className="fas fa-spinner fa-pulse"></i>
              ) : (
                "Submit"
              )}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          scroll={"body"}
          open={this.state.openDIY}
          onClose={this.handleCloseDIY}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            DIY Questionaire Details
          </DialogTitle>
          <DialogContent>
            {this.state.DIYQuestionaire ? (
              <div>
                {Object.keys(this.state.DIYQuestionaire).map((key, i) => (
                  <p key={i}>
                    <span>
                      {key} : {this.state.DIYQuestionaire[key]}{" "}
                    </span>
                  </p>
                ))}
              </div>
            ) : (
              <h4>DIY Questionaire Is Not Found For This Component</h4>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDIY} color="danger">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Modal
          isOpen={this.state.modal}
          size="lg"
          toggle={() => {
            this.setState({ modal: !this.state.modal });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ modal: !this.state.modal });
            }}
          >
            Select RPM Ratio
          </ModalHeader>
          <ModalBody>
            {this.state.rpmRatioSelected.length > 0 &&
              this.state.rpmRatioSelected.map((val) => {
                return (
                  <Row key={val !== undefined && val.tagId}>
                    <Col xs="3">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Select Primary <span className="text-danger">*</span>
                      </label>
                      <Select
                        style={{ height: "40px" }}
                        isClearable={false}
                        isDisabled={val.tagId === null ? false : true}
                        onChange={(e) => {
                          // let filteredVal = [];
                          // this.state.linkedList.map((valLL) => {
                          //   if (valLL.key !== e.key) {
                          //     filteredVal.push(valLL);
                          //   }
                          // });
                          // console.log("filteredVal:", filteredVal);
                          this.setState({
                            currentPrimary: e.tagId,
                            currentItem: e,
                            linkedListTemp:
                              this.state.linkedList &&
                              this.state.linkedList.filter((valPL) => {
                                return valPL.key != e.key;
                              }),
                          });
                        }}
                        defaultValue={
                          val !== undefined && val.componentId
                            ? null
                            : _.find(this.state.ratioDropDownData, {
                                value: val !== undefined && val.tagId,
                              })
                        }
                        placeholder=""
                        options={
                          val.tagId === null
                            ? this.state.primaryListTemp
                            : this.state.ratioDropDownData
                        }
                      />
                    </Col>
                    <Col xs="3">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Add Ratio<span className="text-danger">*</span>
                      </label>
                      <Input
                        style={{ height: "40px" }}
                        className="form-control-alternative"
                        id={val.tagId + "_" + val.linkedTagId}
                        type="number"
                        min="0.1"
                        // value={null}
                        onChange={(e) => {
                          //also add to final
                          if (e.target.value < 0) {
                            toast.warn("Please enter positive values");
                            e.target.value = null;
                            return;
                          }
                          this.setState({ currentRatio: e.target.value });
                        }}
                        defaultValue={
                          val !== undefined && val.componentId
                            ? null
                            : val !== undefined && val.ratio
                        }
                      />
                    </Col>
                    <Col xs="3">
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        Linked<span className="text-danger">*</span>
                      </label>
                      <Select
                        style={{ height: "40px" }}
                        isClearable={false}
                        isDisabled={val.linkedTagId === null ? false : true}
                        onChange={(e) => {
                          this.setState({
                            currentLinked: e.tagId,
                            currentItem: e,
                            primaryListTemp:
                              this.state.primaryList &&
                              this.state.primaryList.filter((valPL) => {
                                return valPL.key != e.key;
                              }),
                          });
                        }}
                        defaultValue={
                          val !== undefined && val.componentId
                            ? null
                            : _.find(this.state.ratioDropDownData, {
                                value: val !== undefined && val.linkedTagId,
                              })
                        }
                        placeholder=""
                        options={
                          val.linkedTagId === null
                            ? this.state.linkedListTemp
                            : this.state.ratioDropDownData
                        }
                      />
                    </Col>
                    <Col xs={3}>
                      <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                      >
                        {" "}
                      </label>
                      <div>
                        <Button
                          style={{ height: "40px" }}
                          id={val.tagId + "_" + val.linkedTagId}
                          onClick={async (e) => {
                            let currentDeletePrimary =
                              e.target.id.split("_")[0] === "null"
                                ? this.state.currentPrimary
                                : e.target.id.split("_")[0];
                            let primaryComponent =
                              this.state.ratioDropDownData.find(
                                (rpmRatio) =>
                                  rpmRatio.value === currentDeletePrimary
                              );
                            let currentDeleteLinked =
                              e.target.id.split("_")[1] === "null"
                                ? this.state.currentLinked
                                : e.target.id.split("_")[1];
                            let linkedComponent =
                              this.state.ratioDropDownData.find(
                                (rpmRatio) =>
                                  rpmRatio.value === currentDeleteLinked
                              );
                            if (
                              window.confirm(
                                `Confirm deleting the RPM Ratio for selected primary component - ${
                                  primaryComponent
                                    ? primaryComponent.label
                                    : "Null"
                                } and selected linked component - ${
                                  linkedComponent
                                    ? linkedComponent.label
                                    : "Null"
                                } `
                              )
                            ) {
                              let dataRemove = [];
                              this.state.rpmRatioSelected.map(async (o) => {
                                if (
                                  (o.tagId === e.target.id.split("_")[0] &&
                                    o.linkedTagId ===
                                      e.target.id.split("_")[1]) ||
                                  o.linkedTagId === null
                                ) {
                                  console.log(`delete found`);
                                  if (o.isLocal) {
                                    console.log(`local delete`);
                                  } else {
                                    console.log(`call api`);
                                    let del = await axios.rpmRatio_delete(
                                      o.tagId,
                                      o.linkedTagId
                                    );
                                    this.getSelectedComponent(
                                      this.state.components
                                    );
                                    // this.getComponents();
                                  }
                                } else {
                                  console.log(`delete not found`);
                                  dataRemove.push(o);
                                }
                              });
                              this.setState({
                                rpmRatioSelected: dataRemove,
                                currentPrimary: null,
                                currentLinked: null,
                                currentRatio: null,
                              });
                            }
                          }}
                        >
                          <MaterialIcon
                            id={val.tagId + "_" + val.linkedTagId}
                            icon="delete"
                            color="#ff0000"
                          />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                );
              })}

            <Row style={{ paddingTop: "10px" }}>
              <Col xs="4">
                {this.state.components !== null &&
                this.state.linkedList.length > 0 ? (
                  <Button
                    color="success"
                    disabled={this.state.linkedList.length > 0 ? false : true}
                    onClick={() => {
                      let finalDataRpm = [];
                      if (
                        this.state.rpmRatioSelected.length !== 0 &&
                        (this.state.rpmRatioSelected[0].tagId === null ||
                          this.state.rpmRatioSelected[
                            this.state.rpmRatioSelected.length - 1
                          ].tagId === null)
                      ) {
                        if (
                          this.state.currentPrimary === null ||
                          this.state.currentLinked === null ||
                          this.state.currentRatio === null
                        ) {
                          //to handle Empty entry
                          alert(
                            "Please enter values for all the fields to Add New Row"
                          );
                          return;
                        }
                      }
                      if (
                        this.state.localRows.length === 0 &&
                        this.state.currentPrimary === null &&
                        this.state.currentLinked === null
                      ) {
                        //add local row
                        this.setState({
                          rpmRatioSelected: [
                            ...this.state.rpmRatioSelected,
                            {
                              isLocal: true,
                              tagId: null,
                              linkedTagId: null,
                              primary: true,
                              ratioConfigID: null,
                              ratio: "",
                            },
                          ],
                        });
                      } else {
                        if (this.state.currentPrimary !== null) {
                          //incase last data is present with preselect
                          if (this.state.rpmRatioSelected.length == 1) {
                            this.setState(
                              {
                                localRows: [
                                  ...this.state.localRows,
                                  this.state.currentPrimary +
                                    "_" +
                                    this.state.currentLinked,
                                ],
                                rpmRatioSelected: [
                                  {
                                    isLocal: true,
                                    tagId: this.state.currentPrimary,
                                    linkedTagId: this.state.currentLinked,
                                    primary: true,
                                    ratioConfigID: `${
                                      _.find(this.state.ratioDropDownData, [
                                        "tagId",
                                        this.state.currentPrimary,
                                      ]).equipmentId
                                    }_${
                                      _.find(this.state.ratioDropDownData, [
                                        "tagId",
                                        this.state.currentPrimary,
                                      ]).componentId
                                    }`, //uniqueID
                                    ratio: this.state.currentRatio,
                                  },
                                ],
                                currentPrimary: null,
                                currentLinked: null,
                                currentRatio: null,
                              },
                              async () => {
                                this.state.rpmRatioSelected.map(async (val) => {
                                  if (val.tagId !== null) {
                                    finalDataRpm = {
                                      tagId: val.tagId, //singleTagID
                                      linkedTagId: val.linkedTagId, //single
                                      // primary: true, //true or false based on initial one
                                      // ratioConfigID: val.ratioConfigID,
                                      ratio: parseInt(
                                        document.getElementById(
                                          val.tagId + "_" + val.linkedTagId
                                        ).value
                                      ),
                                    };
                                  }
                                });
                                console.log("finalDataRpmElse:", finalDataRpm);
                                let res = await axios.rpmRatio_create(
                                  finalDataRpm
                                );
                                if (res.status == 400)
                                  return toast.error(res.data.data);
                                if (res.status == 500)
                                  return toast.error("Failed to save");
                                await this.getSelectedComponent(
                                  this.state.components
                                );
                                this.setState({
                                  finalDataPush: [],
                                  rpmRatioSelected: [
                                    ...this.state.rpmRatioSelected,
                                    {
                                      isLocal: true,
                                      tagId: null,
                                      linkedTagId: null,
                                      primary: true,
                                      ratioConfigID: null,
                                      ratio: "",
                                    },
                                  ],
                                });
                              }
                            );
                          } else {
                            this.state.rpmRatioSelected.pop();
                            this.setState(
                              {
                                localRows: [
                                  ...this.state.localRows,
                                  this.state.currentPrimary +
                                    "_" +
                                    this.state.currentLinked,
                                ],
                                rpmRatioSelected: [
                                  ...this.state.rpmRatioSelected,
                                  {
                                    isLocal: true,
                                    tagId: this.state.currentPrimary,
                                    linkedTagId: this.state.currentLinked,
                                    primary: true,
                                    ratioConfigID: `${
                                      _.find(this.state.ratioDropDownData, [
                                        "tagId",
                                        this.state.currentPrimary,
                                      ]).equipmentId
                                    }_${
                                      _.find(this.state.ratioDropDownData, [
                                        "tagId",
                                        this.state.currentPrimary,
                                      ]).componentId
                                    }`, //uniqueID
                                    ratio: this.state.currentRatio,
                                  },
                                ],
                                currentPrimary: null,
                                currentLinked: null,
                                currentRatio: null,
                              },
                              async () => {
                                this.state.rpmRatioSelected.map(async (val) => {
                                  if (val.tagId !== null) {
                                    finalDataRpm = {
                                      tagId: val.tagId, //singleTagID
                                      linkedTagId: val.linkedTagId, //single
                                      // primary: true, //true or false based on initial one
                                      // ratioConfigID: val.ratioConfigID,
                                      ratio: parseInt(
                                        document.getElementById(
                                          val.tagId + "_" + val.linkedTagId
                                        ).value
                                      ),
                                    };
                                  }
                                });
                                console.log("finalDataRpmElse:", finalDataRpm);
                                let res = await axios.rpmRatio_create(
                                  finalDataRpm
                                );

                                if (res.status == 400)
                                  return toast.error(res.data.data);
                                if (res.status == 500)
                                  return toast.error("Failed to save");

                                await this.getSelectedComponent(
                                  this.state.components
                                );
                                this.setState({
                                  finalDataPush: [],
                                  rpmRatioSelected: [
                                    ...this.state.rpmRatioSelected,
                                    {
                                      isLocal: true,
                                      tagId: null,
                                      linkedTagId: null,
                                      primary: true,
                                      ratioConfigID: null,
                                      ratio: "",
                                    },
                                  ],
                                });
                              }
                            );
                          }
                        } else {
                          this.setState({
                            rpmRatioSelected: [
                              ...this.state.rpmRatioSelected,
                              {
                                isLocal: true,
                                tagId: null,
                                linkedTagId: null,
                                primary: true,
                                ratioConfigID: null,
                                ratio: "",
                              },
                            ],
                          });
                        }
                      }
                    }}
                    size="md"
                    // className='add-btn'
                  >
                    Add New Row
                  </Button>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={async () => {
                let finalDataRpm = {};
                if (
                  this.state.rpmRatioSelected.length !== 0 &&
                  this.state.rpmRatioSelected[
                    this.state.rpmRatioSelected.length - 1
                  ].tagId === null
                ) {
                  if (
                    this.state.currentPrimary === null ||
                    this.state.currentLinked === null ||
                    this.state.currentRatio === null
                  ) {
                    //to handle Empty entry
                    alert(
                      "Please enter values for all the fields to Submit Row"
                    );
                    return;
                  }
                }
                this.setState({ modal: false });
                if (this.state.currentPrimary === null) {
                  //normal edit works fine
                  this.state.rpmRatioSelected.map(async (val) => {
                    console.log(
                      "iiiiiiiiiiiiiiiii",
                      document.getElementById(val.tagId + "_" + val.linkedTagId)
                        .value
                    );
                    finalDataRpm = [
                      { ...finalDataRpm },
                      {
                        tagId: val.tagId, //singleTagID
                        linkedTagId: val.linkedTagId, //single
                        primary: true, //true or false based on initial one
                        ratioConfigID: val.ratioConfigID,
                        ratio: document.getElementById(
                          val.tagId + "_" + val.linkedTagId
                        ).value,
                      },
                      {
                        tagId: val.linkedTagId, //singleTagID
                        linkedTagId: val.tagId, //single
                        primary: false, //true or false based on initial one
                        ratioConfigID: val.ratioConfigID, //uniqueID
                        ratio: parseFloat(
                          1 /
                            document.getElementById(
                              val.tagId + "_" + val.linkedTagId
                            ).value
                        ),
                      },
                    ];

                    let res = await axios.rpmRatio_create(finalDataRpm);

                    if (res.status == 400) return toast.error(res.data.data);
                    if (res.status == 500) return toast.error("Failed to save");
                    await this.getSelectedComponent(this.state.components);

                    this.setState({ finalDataPush: [] });
                  });
                } else {
                  //incase last data is present with preselect
                  this.setState(
                    {
                      localRows: [
                        ...this.state.localRows,
                        this.state.currentPrimary +
                          "_" +
                          this.state.currentLinked,
                      ],
                      rpmRatioSelected: [
                        ...this.state.rpmRatioSelected,
                        {
                          isLocal: true,
                          tagId: this.state.currentPrimary,
                          linkedTagId: this.state.currentLinked,
                          primary: true,
                          ratioConfigID: `${
                            _.find(this.state.ratioDropDownData, [
                              "tagId",
                              this.state.currentPrimary,
                            ]).equipmentId
                          }_${
                            _.find(this.state.ratioDropDownData, [
                              "tagId",
                              this.state.currentPrimary,
                            ]).componentId
                          }`, //uniqueID
                          ratio: this.state.currentRatio,
                        },
                      ],
                      currentPrimary: null,
                      currentLinked: null,
                      currentRatio: null,
                    },
                    async () => {
                      if (this.state.rpmRatioSelected.length > 0) {
                        this.state.rpmRatioSelected.forEach((e, i, obj) => {
                          if (obj[i].tagId == null) {
                            [obj[i], obj[obj.length - 1]] = [
                              obj[obj.length - 1],
                              obj[i],
                            ];
                            obj.pop();
                          }
                        });
                        // if (this.state.rpmRatioSelected[0].tagId == null) {
                        //   this.state.rpmRatioSelected.push(
                        //     this.state.rpmRatioSelected.shift()
                        //   );
                        // }
                      }
                      this.state.rpmRatioSelected.map(async (val) => {
                        if (val.tagId !== null) {
                          //push primarytrue
                          finalDataRpm = {
                            tagId: val.tagId, //singleTagID
                            linkedTagId: val.linkedTagId, //single
                            // primary: true, //true or false based on initial one
                            // ratioConfigID: val.ratioConfigID,
                            // ratio: parseInt(
                            //   document.getElementById(
                            //     val.tagId + "_" + val.linkedTagId
                            //   ).value
                            // ),
                            ratio: parseFloat(val.ratio),
                          };
                          //push primaryfalse
                          // finalDataRpm.push({
                          //   tagId: val.linkedTagId, //singleTagID
                          //   linkedTagId: val.tagId, //single
                          //   primary: false, //true or false based on initial one
                          //   ratioConfigID: val.ratioConfigID, //uniqueID
                          //   ratio: parseFloat(
                          //     1 /
                          //       document.getElementById(
                          //         val.tagId + "_" + val.linkedTagId
                          //       ).value
                          //   ),
                          // });
                        }
                      });
                      console.log("finalDataRpmElse:", finalDataRpm);
                      let res = await axios.rpmRatio_create(finalDataRpm);
                      if (res.status == 400) return toast.error(res.data.data);
                      else if (res.status == 500)
                        return toast.error("Failed to save");
                      else toast.success("RPM Ratio set successfully");

                      await this.getSelectedComponent(this.state.components);

                      this.setState({ finalDataPush: [] });
                    }
                  );
                }
                return;

                console.log("currentPrimary:", this.state.localRows);
                let temp = this.state.finalDataPush;
                temp.push({
                  tagId: this.state.currentPrimary, //singleTagID
                  linkedTagId: this.state.currentLinked, //single
                  primary: true, //true or false based on initial one
                  ratioConfigID: `${equipmentId}_${
                    _.find(this.state.ratioDropDownData, [
                      "tag.tagId",
                      this.state.currentPrimary,
                    ]).componentId
                  }`, //uniqueID
                  ratio: this.state.currentRatio,
                });
                temp.push({
                  tagId: this.state.currentLinked, //singleTagID
                  linkedTagId: this.state.currentPrimary, //single
                  primary: false, //true or false based on initial one
                  ratioConfigID: `${equipmentId}_${
                    _.find(this.state.ratioDropDownData, [
                      "tag.tagId",
                      this.state.currentPrimary,
                    ]).componentId
                  }`, //uniqueID
                  ratio: parseFloat(1 / this.state.currentRatio),
                });

                await axios.addRPMRatio(temp);
                await this.getSelectedComponent(this.state.components);

                this.setState({ finalDataPush: [] });
              }}
            >
              Submit
            </Button>{" "}
            <Button
              color="secondary"
              onClick={async () => {
                // let temp = [];
                // temp.push(this.state.components[0]);
                // this.setState({ modal: false, rpmRatioSelected: temp });
                // await this.getSelectedComponent(this.state.components);
                this.setState({ modal: !this.state.modal });
                // await this.getComponents();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.rpmTypeModal}
          size="lg"
          toggle={() => {
            this.setState({ rpmTypeModal: !this.state.rpmTypeModal });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ rpmTypeModal: !this.state.rpmTypeModal });
            }}
          >
            Are you sure?
          </ModalHeader>
          <ModalBody>
            <div>RPM Type: {this.state.rpmTypeSelected}</div>
            <div>
              <DIYQuestEquip
                equipmentDIY={{
                  companyId: companyId,
                  equipmentId: equipmentId,
                  equipmentType:
                    this.state.equipmentTypes.length > 0 &&
                    this.state.defaultOption !== -1
                      ? this.state.equipmentTypes[this.state.defaultOption]
                          .value
                      : null,
                }}
                closeModal={() => {
                  this.setState({
                    rpmTypeModal: false,
                    rpmType: this.state.rpmTypeSelected,
                    isDisableRpmRatio: false,
                  });
                }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                this.setState({ rpmTypeModal: false });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.openDIYAns}
          size="lg"
          toggle={() => {
            this.setState({ openDIYAns: !this.state.openDIYAns });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ openDIYAns: !this.state.openDIYAns });
            }}
          >
            ASSET CONFIGURATION
          </ModalHeader>
          <ModalBody>
            <DIYQuest
              component={this.state.componentDIY}
              equipment={this.state.equipment}
              closeModal={() => {
                this.setState({ openDIYAns: false });
              }}
            />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.popoverOpen}
          size="lg"
          toggle={() => {
            this.setState({ popoverOpen: !this.state.popoverOpen });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ popoverOpen: !this.state.popoverOpen });
            }}
          >
            Sensor Management{" "}
            {this.state.currentSensor
              ? this.state.currentSensor.sensorId
              : null}
          </ModalHeader>
          <ModalBody>
            <Button
              onClick={async () => {
                this.props.history.push(
                  `/v1/companies/${companyId}/sensors/add`,
                  {
                    componentDetails: {
                      ...this.state.currentSensor,
                      changeMountType: true,
                    },
                  }
                );
              }}
              size="lg"
              color="primary"
            >
              Change Sensor Mount Type
            </Button>
            <br />
            <Button
              onClick={async () => {
                let response = await axios.sensorManage_Unassign(
                  this.state.currentSensor.sensorId
                );
                // let link = `https://sensorapi.nanoprecisedataservices.com/coordinator/api/v1/sensors-keys?sensorIdList=${
                //   this.state.currentSensor.sensorId
                // }&logglyTag=${null}-${null}-${
                //   this.state.currentSensor.sensorId
                // }`;

                // // let apiTest = await axios.putReq(link, {});
                // // console.log("apiTestapiTestapiTest:", apiTest);

                // const requestOptions = {
                //   method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //     Authorization: `Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuZXlKcFpDSTZJbVZzWldOMGNtbGpTVzF3SW4wLjQ2eTdsVjNrUl9ZNlBVOFpNYVFxUHZMcV94aTFWcEwwbUhvempUWVFkSHc6ZTRjZjVkN2ViMDkwNGJmYmFhZTIyOTQ0MGNlMGYzYWM=`,
                //   },
                // };
                const responseTemp = await axios.put_sensorCoordinator(
                  this.state.currentSensor.sensorId,
                  null,
                  null
                );
                if (response && response.data && response.data.status) {
                  toast.success("Sensor unassigned successfully.");
                }
                await this.getComponents();
                this.setState({ popoverOpen: !this.state.popoverOpen });
              }}
              size="lg"
              color="primary"
            >
              Unassign
            </Button>
            <br />
            <Button
              onClick={async () => {
                let response = await axios.sensorManage_Unassign(
                  this.state.currentSensor.sensorId
                );
                // let link = `https://sensorapi.nanoprecisedataservices.com/coordinator/api/v1/sensors-keys?sensorIdList=${this.state.currentSensor.sensorId}&logglyTag=${companyId}-${this.state.equipment.plantName}-${this.state.currentSensor.sensorId}`;

                // // let apiTest = await axios.putReq(link, {});
                // // console.log("apiTestapiTestapiTest:", apiTest);

                // const requestOptions = {
                //   method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //     Authorization: `Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuZXlKcFpDSTZJbVZzWldOMGNtbGpTVzF3SW4wLjQ2eTdsVjNrUl9ZNlBVOFpNYVFxUHZMcV94aTFWcEwwbUhvempUWVFkSHc6ZTRjZjVkN2ViMDkwNGJmYmFhZTIyOTQ0MGNlMGYzYWM=`,
                //   },
                // };
                const responseTemp = await axios.put_sensorCoordinator(
                  this.state.currentSensor.sensorId,
                  companyId,
                  this.state.equipment.plantName
                );

                this.props.history.push(
                  `/v1/companies/${companyId}/sensors/add`,
                  {
                    componentDetails: this.state.currentSensor,
                    plantName: this.state.equipment.plantName,
                  }
                );
              }}
              size="lg"
              color="primary"
            >
              Assign New Sensor{" "}
            </Button>
            <br />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  equipDetailsData: _.isEmpty(state.equipmentType.equipDetailsData)
    ? null
    : state.equipmentType.equipDetailsData,
  equipTypeData: _.isEmpty(state.equipmentType.equipTypeData)
    ? null
    : state.equipmentType.equipTypeData,
  equipmentSubTypeData: _.isEmpty(state.equipSubType.equipSubTypeData)
    ? null
    : state.equipSubType.equipSubTypeData,

  equipReportsData: _.isEmpty(state.equipmentType.equipReportsData)
    ? null
    : state.equipmentType.equipReportsData,
  groupData: _.isEmpty(state.companies.groupData)
    ? null
    : state.companies.groupData,
  componentOptionsData: _.isEmpty(state.componentTypes.componentOptionsData)
    ? null
    : state.componentTypes.componentOptionsData,
  equipmentSubTypeDropDown: _.isEmpty(state.equipSubType.equipSubTypeDropDown)
    ? null
    : state.equipSubType.equipSubTypeDropDown,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEquipmentType: (companyId) => dispatch(fetchEquipmentType(companyId)),
    fetchEquipmentDetails: (equipmentId) =>
      dispatch(fetchEquipmentDetails(equipmentId)),
    fetchGroup: (companyId) => dispatch(fetchGroup(companyId)),
    fetchRpmRatioData: (equipmentId) =>
      dispatch(fetchRpmRatioData(equipmentId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentDetails);
