import * as yup from "yup";

const emailValidation = {
  async validate(object) {
    let error = "";
    let schema = yup.object().shape({
      smtpEmail: yup.required("Please enter email").email().label("Email"),
      smtpUsername: yup
        .string()
        .required("Please Enter username")
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for Company Code field "
        )
        .length(4)
        .uppercase()
        .label("Username"),
      smtpPassword: yup
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
      smtpPort: yup.required("Please enter Port"),
      smtpServer: yup.required("Please enter server detail"),
      senderId: yup.required("Please enter sender id"),
      senderName: yup.required("Please enter Sender name")
      .string()
      .label("Sender Name")
    });

    //validating against schema
    try {
      console.log({ object });
      await schema.validate(object, { abortEarly: false, strict: true });
    } catch (err) {
      error = err.errors[0];
    }
    return error;
  },
};

export default emailValidation;
