import CallAPI from "../../axios";
export const FETCH_COMPANIES_DATA = "FETCH_COMPANIES_DATA";
export const FETCH_NOTIF_DATA = "FETCH_NOTIF_DATA";
export const FETCH_COMPANY_DETAILS_DATA = "FETCH_COMPANY_DETAILS_DATA";

export const FETCH_USERS_DATA = "FETCH_USERS_DATA";
export const FETCH_USERS_DETAILS_DATA = "FETCH_USERS_DETAILS_DATA";

export const FETCH_GROUPS_DATA = "FETCH_GROUPS_DATA";
export const FETCH_GROUPS_DETAILS_DATA = "FETCH_GROUPS_DETAILS_DATA";
export const FETCH_GROUPS_OPTION_DATA = "FETCH_GROUPS_OPTION_DATA";

export const FETCH_TAG_OPTIONS = "FETCH_TAG_OPTIONS";
export const FETCH_TAG_OPTIONS_DETAILS = "FETCH_TAG_OPTIONS_DETAILS";

export const FETCH_COMPANY_SCANNER = "FETCH_COMPANY_SCANNER";
export const FETCH_COMPANY_SCANNER_DETAILS = "FETCH_COMPANY_SCANNER_DETAILS";

export const FETCH_THEME_CONGIF = "FETCH_THEME_CONGIF";
export const FETCH_LOGIN_THEME_CONFIG = "FETCH_LOGIN_THEME_CONFIG";

const fetchData = (data) => {
  return {
    type: FETCH_COMPANIES_DATA,
    payload: data,
  };
};

const fetchNotifData = (data) => {
  return {
    type: FETCH_NOTIF_DATA,
    payload: data,
  };
};

const fetchCompanyDetailsData = (data) => {
  return {
    type: FETCH_COMPANY_DETAILS_DATA,
    payload: data,
  };
};

const fetchUsersData = (data) => {
  return {
    type: FETCH_USERS_DATA,
    payload: data,
  };
};

const fetchUsersDetailsData = (data) => {
  return {
    type: FETCH_USERS_DETAILS_DATA,
    payload: data,
  };
};

const fetchGroupData = (data) => {
  return {
    type: FETCH_GROUPS_DATA,
    payload: data,
  };
};

const fetchGroupDetailsData = (data) => {
  return {
    type: FETCH_GROUPS_DETAILS_DATA,
    payload: data,
  };
};

const fetchGroupOptionData = (data) => {
  return {
    type: FETCH_GROUPS_OPTION_DATA,
    payload: data,
  };
};

const fetchCompanyTagOptionData = (data) => {
  return {
    type: FETCH_TAG_OPTIONS,
    payload: data,
  };
};
const fetchCompanyTagDetailsOptionData = (data) => {
  return {
    type: FETCH_TAG_OPTIONS_DETAILS,
    payload: data,
  };
};

const fetchCompanyScannerData = (data) => {
  return {
    type: FETCH_COMPANY_SCANNER,
    payload: data,
  };
};
const fetchCompanyScannerDetailsData = (data) => {
  return {
    type: FETCH_COMPANY_SCANNER_DETAILS,
    payload: data,
  };
};

const fetchThemeConfigData = (data) => {
  return {
    type: FETCH_THEME_CONGIF,
    payload: data,
  };
};

const fetchLoginThemeConfigData = (data) => {
  return {
    type: FETCH_LOGIN_THEME_CONFIG,
    payload: data,
  };
};

export const fetchCompanies = () => {
  return (dispatch) => {
    return CallAPI.companyManage_CompanyList()
      .then((response) => {
        response && dispatch(
          fetchData(JSON.parse(JSON.stringify(response && response.data)))
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchNotifCount = (companyId, booleanVar) => {
  return (dispatch) => {
    CallAPI.getData(`/notificationAlertData/count/${companyId}/${booleanVar}`)
      .then((response) => {
        dispatch(fetchNotifData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchCompanyDetails = (companyId) => {
  return (dispatch) => {
    CallAPI.companyManage_CompanyDetails(companyId)
      .then((response) => {
        CallAPI.getData(`/companies/${companyId}`).then((response2) => {
          let tempAPI2Resp = response2.data.data;
          if (tempAPI2Resp) delete tempAPI2Resp.company;

          dispatch(
            fetchCompanyDetailsData({
              data: { company: { ...response.data.data, ...tempAPI2Resp } },
            })
          );
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchUser = (companyId) => {
  return (dispatch) => {
    CallAPI.getData(`/users/${companyId}`)
      .then((response) => {
        dispatch(fetchUsersData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchUserDetails = (companyId, userId) => {
  return (dispatch) => {
    CallAPI.getData(`/users/${companyId}/${userId}`)
      .then((response) => {
        dispatch(fetchUsersDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchGroup = (companyId) => {
  return (dispatch) => {
    CallAPI.plantManage_plantList(companyId)
      .then((response) => {
        response && dispatch(fetchGroupData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchGroupDetails = (companyId, id) => {
  return (dispatch) => {
    CallAPI.plantManage_plantDetails(id)
      .then((response) => {
        dispatch(fetchGroupDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchGroupOption = (companyId) => {
  return (dispatch) => {
    CallAPI.getData(`/groups/options/${companyId}`)
      .then((response) => {
        dispatch(fetchGroupOptionData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchCompanyTagOption = (companyId) => {
  return (dispatch) => {
    CallAPI.trtagmapsview_company(companyId)
      .then((response) => {
        dispatch(fetchCompanyTagOptionData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchCompanyTagDetailsOption = (companyId, tagId) => {
  return (dispatch) => {
    CallAPI.getData(`/groups/options/${companyId}/${tagId}`)
      .then((response) => {
        dispatch(fetchCompanyTagDetailsOptionData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchCompanyScanner = (companyId, status) => {
  return (dispatch) => {
    CallAPI.getData(`/scanners/${companyId}?status=${status}`)
      .then((response) => {
        dispatch(fetchCompanyScannerData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchCompanyScannerDetails = (companyId, scannerId) => {
  return (dispatch) => {
    CallAPI.getData(`/scanners/${companyId}/${scannerId}`)
      .then((response) => {
        dispatch(fetchCompanyScannerDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchThemeConfig = (companyId) => {
  return (dispatch) => {
    CallAPI.company_theme(companyId)
      .then((response) => {
        response && dispatch(fetchThemeConfigData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchLoginThemeConfig = (domainName) => {
  return (dispatch) => {
    CallAPI.company_loginTheme(domainName)
      .then((response) => {
        response && dispatch(fetchLoginThemeConfigData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
