// This feature is closed

import React from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ifvisible from "ifvisible.js";
import $ from "jquery";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
// core components

import UserHeader from "components/Headers/Header.jsx";
import Helper from "../../../../helper";
import axios from "../../../../axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";
import readXlsxFile from "read-excel-file";
import QRCode from "qrcode.react";

let portalUser = {};
const options = {
  showTotal: true,
};
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

class QRExport extends React.Component {
  state = {
    data: "",
    readOnly: true,
    saving: false,
    columns: [
      {
        text: "Sensor Id",
        dataField: "sensorId",
        sort: false,
      },
      {
        text: "SSID",
        dataField: "ssid",
        sort: false,
      },
      {
        text: "QR Code",
        dataField: "sensorId",
        sort: false,
        formatter: this.qrCodes,
      },
      {
        text: "Equipment",
        dataField: "equipment",
        sort: false,
      },
      {
        text: "Component",
        dataField: "component",
        sort: false,
      },
    ],
    openDelete: false,
    print: false,
  };

  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  qrCodes(cell, row) {
    return (
      <QRCode
        id={cell}
        style={{ height: 60, width: 60 }}
        value={"Nano_" + cell}
        includeMargin={true}
      />
    );
  }

  handleChange = (state, key) => (e) => {
    let subject = { ...this.state[state] };
    Helper.reduce(subject, key, e.target.value);
    this.setState({ [state]: subject });
  };

  handleAlarmDelete() {
    // console.log("kkk");
    // var elem = document.getElementById('print-action');
    // elem.parentNode.removeChild(elem);
    // elem.style.display = "none"
    window.print();
    // this.setState({ openDelete: false })
  }

  handleCancel = () => {
    this.setState({
      user: this.state.userBackup,
      readOnly: true,
    });
  };

  handleSave = async () => {
    let data = { ...this.state.data };
    //Validating
    let err = await validation.validate(data);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    if (this.state.data.new_password != this.state.data.confirm_new_password) {
      return toast.error("Password do not match");
    }

    this.setState({ saving: true });
    let email = JSON.parse(localStorage.getItem("portal-user")).email;
    data.email = email;
    let api = await axios.postData("/auth/change-password", data);
    if (api.status === 200) {
      toast.success(
        "Password changed successfully. Please login again with new credentials"
      );
      setTimeout(() => {
        this.logout();
      }, 3000);
    } else if (api.status === 400) {
      toast.error(api.data.message);
    } else {
      toast.error("Something went wrong. Please try again..");
    }
    this.setState({ saving: false });
  };

  logout = () => {
    Helper.clearAuth();
    localStorage.removeItem("companyId");
    this.props.history.push("/auth/login");
  };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  parseExcel = (e) => {
    let data = [];
    const schema = {
      Equipment: {
        prop: "equipment",
        type: String,
        required: true,
      },
      "Component Name and Location": {
        prop: "component",
        type: String,
        required: true,
      },
      "Identification(Sensor Number)": {
        prop: "sensor",
        type: String,
        required: true,
      },
      SSID: {
        prop: "SSID",
        type: String,
        required: true,
      },
    };

    readXlsxFile(e.target.files[0]).then((rows) => {
      let i = 0;
      rows.map((row) => {
        let d = {
          id: i++,
          ssid: row[3],
          sensorId: row[2],
          equipment: row[0],
          component: row[1],
        };
        data.push(d);
      });
      data.shift();
      // console.log(data.length)
      //Saving to localStorags
      localStorage.setItem("print-job", JSON.stringify(data));
      this.setState({ data: data, print: true });
    });
  };

  downloadQR = () => {
    const canvas = document.getElementById("30000c2a691dcf22");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(pngUrl);
  };

  render() {
    var today = moment();
    var valid = function (current) {
      return current.isBefore(today);
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">QR & Label Export</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={2}>
                        Upload File:
                      </Label>
                      <Col sm={4}>
                        <input
                          type="file"
                          id="input"
                          onChange={this.parseExcel}
                        />
                      </Col>
                      <Col lg="6">
                        {/* <Button
                                        color="success"
                                        // onClick={this.downloadQR}
                                        // onClick={() => {
                                        //   this.setState({ openDelete: true });
                                        //   this.jump("/print")
                                        // }}
                                        size="md"
                                      >
                                        Print
                                    </Button> */}
                        {this.state.print && (
                          <Link
                            style={{
                              background: "seagreen",
                              color: "#fff",
                              padding: "10px 20px 10px 20px",
                              borderRadius: "6px",
                            }}
                            target="_blank"
                            to="/print"
                            activeClassName="active"
                          >
                            Print
                          </Link>
                        )}
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <br></br>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.data && (
                  <>
                    <center>
                      <h3 className="mb-0">Imported Data</h3>
                    </center>
                    <ToolkitProvider
                      keyField="id"
                      data={this.state.data}
                      columns={this.state.columns}
                      search={{ searchFormatted: true }}
                    >
                      {(props) => (
                        <div>
                          <SearchBar {...props.searchProps} />
                          {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton>  */}
                          <BootstrapTable
                            {...props.baseProps}
                            // rowEvents={ rowEvents }
                            pagination={paginationFactory(options)}
                            striped
                            hover
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>

        <Dialog
          open={this.state.openDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
        >
          {/* <DialogActions className="no-print" id="print-action">
                <Button onClick={() => { this.setState({ openDelete: false }) }} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {

                    this.handleAlarmDelete();
                  }}
                  color="primary"
                  variant="contained"
                  style={{ color: '#fff' }}
                >
                  Print
                </Button>
            </DialogActions> */}

          {this.state.data && (
            <ToolkitProvider
              keyField="id"
              data={this.state.data}
              columns={this.state.columns}
            >
              {(props) => (
                <div>
                  <BootstrapTable {...props.baseProps} striped hover />
                </div>
              )}
            </ToolkitProvider>
          )}
        </Dialog>
      </>
    );
  }
}

export default QRExport;
