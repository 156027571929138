import {
  FETCH_EQUIPMENT_TYPES,
  FETCH_EQUIPMENT_TYPES_DETAILS,
  FETCH_MACHINE_EQUIPMENT_DETAILS,
  FETCH_EQUIPMENT_DETAILS,
  FETCH_EQUIPMENT_OPTIONS,
  FETCH_EQUIPMENT_REPORTS,
  FETCH_EQUIPMENT_UTIL,
  FETCH_RPM_RATIO_DETAILS,
} from "../actions/equipmentType.action";

const equipTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EQUIPMENT_TYPES:
      return {
        ...state,
        equipTypeData: action.payload,
      };
    case FETCH_EQUIPMENT_TYPES_DETAILS:
      return {
        ...state,
        equipTypeDetailsData: action.payload,
      };
    case FETCH_MACHINE_EQUIPMENT_DETAILS:
      return {
        ...state,
        machineEquipDetailsData: action.payload,
      };
    case FETCH_EQUIPMENT_DETAILS:
      return {
        ...state,
        equipDetailsData: action.payload,
      };
    case FETCH_EQUIPMENT_OPTIONS:
      return {
        ...state,
        equipOptionsData: action.payload,
      };
    case FETCH_EQUIPMENT_REPORTS:
      return {
        ...state,
        equipReportsData: action.payload,
      };
    case FETCH_EQUIPMENT_UTIL:
      return {
        ...state,
        equipUtilData: action.payload,
      };
    case FETCH_RPM_RATIO_DETAILS:
      return {
        ...state,
        rpmRatioData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default equipTypeReducer;
