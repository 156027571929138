import * as yup from 'yup';

let validation = {

    async validate(object) {

        let error = '';

        //Defining schema
        let schema = yup.object().shape({
            customName: yup.string().required().matches(/([A-Za-z]+)/, "Only numbers are not allowed for this field ").min(2).label('Plant Name'),
            // description: yup.string().required().label('Description'),
            // hierarchy: yup.array().of(yup.object().shape({
            //     name: yup.string().required().trim().label('Name'),
            //     value: yup.string().required().trim().label('Value'),
            //     description: yup.string().required().trim().label('Description'),
            // }))
        });

        //validating against schema
        try {
            await schema.validate(object, {abortEarly: false, strict: true});
          } catch(err) {
            error =  err.errors[0];
          }
          return error;
    }
}

export default validation;