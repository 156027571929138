import React from "react";
import "./Shimmer.css";

const TableShimmer = () => {
  return (
    <div className="graph-shimmer">
      <div className="rectangular-boxt">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-boxt">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-boxt">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-boxt">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-boxt">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-boxt">
        <div className="shimmer-effect"></div>
      </div>
    </div>
  );
};

export default TableShimmer;