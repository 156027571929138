import React from "react";
import { connect } from "react-redux";
import { fetchEulaInfoDetails } from "../../../../redux/actions/eulaInfo.action";
import _ from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import axios from "../../../../axios";
import "./eula.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";
import isValidDomain from "is-valid-domain";

const options = {
  showTotal: true,
};

let eulaInfoId = "";

class EulaDetails extends React.Component {
  state = {
    eula: {},
    readOnly: true,
    saving: false,
    file: null,
    fileName: null,
    active: false,
  };

  async componentDidMount() {
    eulaInfoId = this.props.match.params.eulaInfoId;
    this.props.fetchEulaInfoDetails(eulaInfoId);
  }

  handleChange = (input) => (e) => {
    let eula = { ...this.state.eula };
    eula[input] = e.target.value;
    this.setState({ eula });
  };

  async handleSave() {
    this.setState({ saving: true });
    let eula = { ...this.state.eula };
    const uploadTimestamp = parseInt(Date.now() / 1000);
    eula.uploadTimestamp = uploadTimestamp;
    eula.eula = this.state.file;
    eula.fileName = this.state.fileName;
    eula.active = this.state.active;
    let testGroup = { ...eula };
    let err = await validation.validate(testGroup);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }
    let valid = isValidDomain(eula.domain, { subdomain: true });
    if (!valid) {
      toast.warn("Enter Valid the Domain");
      this.setState({ saving: false });
      return;
    }
    let extention = eula.fileName.split(".").pop();
    if (extention !== "txt") {
      toast.warn("You can only upload Text file!");
      this.setState({ saving: false });
      return;
    }

    let api = await axios.update_eula(eulaInfoId, eula);
    this.setState({ saving: false });
    if (api.status == 200) {
      toast.success("Eula Info Updated successfully");
      this.setState({ readOnly: true });
      this.jump("/v1/eula", 1000);
    } else if (api.status == 400) {
      toast.warning(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.readOnly) {
      // updateMode name changes here
      return;
    }
    let eula = { domain: null };
    let active = false;
    let fileName = null;
    let file = null;
    if (nextProps.eulaInfoDetailsData !== prevState.eulaInfoDetailsData) {
      if (nextProps.eulaInfoDetailsData) {
        eula.domain =
          nextProps.eulaInfoDetailsData.data &&
          nextProps.eulaInfoDetailsData.data.domain
            ? nextProps.eulaInfoDetailsData.data.domain
            : null;
        active =
          nextProps.eulaInfoDetailsData.data &&
          nextProps.eulaInfoDetailsData.data.active
            ? true
            : false;
        file =
          nextProps.eulaInfoDetailsData.data &&
          nextProps.eulaInfoDetailsData.data.eula
            ? nextProps.eulaInfoDetailsData.data.eula
            : null;
        fileName =
          nextProps.eulaInfoDetailsData.data &&
          nextProps.eulaInfoDetailsData.data.fileName
            ? nextProps.eulaInfoDetailsData.data.fileName
            : null;
      }
    }
    return {
      eula: eula,
      active: active,
      file: file,
      fileName: fileName,
    };
  }

  handleFileInput = async (e) => {
    if (e.target.files[0] && e.target.files[0].size > 2000000) {
      alert("File Size should be less than 2MB");
      return;
    }
    let fileName = e.target.files[0] ? e.target.files[0].name : "";
    let file = "";
    if (fileName) {
      file = await this.toBase64(e.target.files[0]);
    }

    this.setState({
      fileName,
      file,
    });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}

          <Row>
            <div className="col">
              <div
                onClick={() => {
                  this.jump("/v1/eula", 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>{" "}
              <br /> <br />
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Eula Info Details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnly && (
                        <Button
                          color="primary"
                          onClick={() => this.setState({ readOnly: false })}
                          size="md"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>

                {
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="1"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Domain:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("domain")}
                              placeholder="eg. domain.com or domain.subdomain.com"
                              value={
                                this.state.eula && this.state.eula.domain
                                  ? this.state.eula.domain
                                  : ""
                              }
                              disabled={
                                this.state.readOnly ||
                                (this.state.eula && this.state.eula.domain)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>

                      <Row>
                        <Col lg="1"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Eula File:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="file"
                              accept=".txt"
                              onChange={this.handleFileInput}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          {this.state.fileName ? (
                            <span>Uploaded File - {this.state.fileName}</span>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="1"></Col>
                        <Col class="float-left">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.active}
                                onChange={() => {
                                  this.setState({
                                    active: !this.state.active,
                                  });
                                }}
                                name="checkedB"
                                color="primary"
                                disabled={this.state.readOnly}
                              />
                            }
                            label={
                              this.state.active
                                ? "Eula State Active"
                                : "Eula State Inactive"
                            }
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          {!this.state.readOnly && (
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          )}

                          {!this.state.readOnly && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ readOnly: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                }
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  eulaInfoDetailsData: _.isEmpty(state.eulaInfo.eulaInfoDetailsData)
    ? null
    : state.eulaInfo.eulaInfoDetailsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEulaInfoDetails: (eulaInfoId) =>
      dispatch(fetchEulaInfoDetails(eulaInfoId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EulaDetails);
