import React from "react";
import { connect } from "react-redux";
import { fetchGroup } from "../../../../redux/actions/companies.action";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import Select from "react-select";

import axios from "../../../../axios";
import "./groups.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Form,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;

const columns = [
  {
    text: "Plant Name",
    dataField: "customName",
    sort: true,
  },
  {
    text: "Plant ID",
    dataField: "plantId",
    sort: true,
  },
  {
    text: "Delete",
    dataField: "plantId",
    formatter: (row) => {
      return <MaterialIcon icon="delete" color="red" />;
    },
  },
];

const options = {
  showTotal: true,
};

let companyId = "";

class Groups extends React.Component {
  state = {
    groups: null,
    list: true,
    group: {},
    hierarchy: [
      {
        name: "",
        level: "",
        value: "",
        description: "",
      },
    ],
    saving: false,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    this.props.fetchGroup(companyId);
    // await this.getGroups();
  }

  async getGroups() {
    let groups = await axios.plantManage_plantList(companyId);
    this.setState({ groups: groups.data.data });
  }

  handleChange = (input) => (e) => {
    let group = { ...this.state.group };
    group[input] = e.target.value;
    this.setState({ group: group });
  };

  handleSelectChange = () => (option) => {
    let equipmentType = { ...this.state.equipmentType };
    equipmentType.equipmentType = option.value;
    this.setState({ equipmentType: equipmentType });
  };

  async handleSave() {
    this.setState({ saving: true });
    let group = { ...this.state.group };

    //Validating
    let testGroup = { ...group };
    testGroup.hierarchy = this.state.hierarchy;
    let err = await validation.validate(testGroup);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    group.companyId = companyId;
    // let hierarchy = [...this.state.hierarchy];
    // hierarchy.map((hier, i) => {
    //   hier.level = parseInt(i+1);
    // });

    // group.hierarchy = JSON.stringify(hierarchy);
    group = {
      companyId: group.companyId,
      customName: group.customName,
      externalId: group.externalId,
    };

    let api = await axios.plantManage_create(group);
    this.setState({ saving: false });
    if (api.status == 200) {
      toast.success("Group saved successfully");
      localStorage.removeItem("companyId");
      this.setState({ list: true });
      this.props.fetchGroup(group.companyId);
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  handleAdd() {
    let hierarchy = [...this.state.hierarchy];
    let obj = {
      name: "",
      level: "",
      value: "",
      description: "",
    };
    hierarchy.push(obj);
    this.setState({ hierarchy });
  }

  handleRemove() {
    let hierarchy = [...this.state.hierarchy];
    hierarchy.pop();
    this.setState({ hierarchy });
  }

  handleHierarchyChange = (input, index) => (e) => {
    let hierarchy = [...this.state.hierarchy];
    hierarchy[index][input] = e.target.value;
    this.setState({ hierarchy });
  };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.groupData !== prevState.groupData) {
      if (nextProps.groupData) {
        console.log(
          "nextProps.groupDatanextProps.groupData:",
          nextProps.groupData
        );

        return {
          groups:
            nextProps.groupData.status !== "failed"
              ? nextProps.groupData.data
              : [],
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: async (e, row, rowIndex) => {
        if (e.target.outerHTML.includes("delete")) {
          console.log(row.plantId);
          if (window.confirm("Do you really want to delete it?")) {
            await axios.plantManage_Delete(row.plantId);
            toast.success("Deleted Successfully");
            this.props.fetchGroup(companyId);
          }
          return;
        }
        this.jump("/v1/companies/" + companyId + "/groups/" + row.plantId);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.list ? (
                <div
                  onClick={() => {
                    this.jump("/v1/companies/" + companyId);
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : (
                <div
                  onClick={() => {
                    this.setState({ list: true });
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              )}
              {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.setState({ list: false, group: null });
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Plant
                </Button>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Plants</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Plant</h3>
                  </CardHeader>
                )}
                {this.state.groups && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          rowEvents={rowEvents}
                          pagination={paginationFactory(options)}
                          keyField="_id"
                          data={this.state.groups}
                          columns={columns}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Equipment Types */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Plant Name:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            External ID{" "}
                            <Tooltip
                              title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "30px",
                                }}
                              />
                            </Tooltip>
                          </label>
                        </Col>
                        <Col lg="2">
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            onChange={this.handleChange("externalId")}
                            disabled={this.state.readOnly}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Description:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("description")}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row> */}

                      {/* <Row>
                    <Col lg="2"></Col>
                    <Col lg="2">
                        <label
                            className="form-control-label"
                            htmlFor="input-username"
                            >
                          Hierarchy:
                        </label>
                    </Col>
                    <Col lg="6">
                        {
                            this.state.hierarchy.map((hier, i) => {
                                return (
                                    <div className="grey" key={i}>
                                        
                                    <Col md="12">
                                        <Row>
                                            <Col lg="3">
                                                <label className="form-control-label" htmlFor="input-username">Name:</label>
                                            </Col>
                                            <Col lg="9">
                                                <FormGroup>
                                                    <Input 
                                                        id="exampleFormControlInput1" 
                                                        type="text"
                                                        value={this.state.hierarchy[i].name}
                                                        onChange={this.handleHierarchyChange('name', i)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                        <Row>
                                            <Col lg="3">
                                                <label className="form-control-label" htmlFor="input-username">Value:</label>
                                            </Col>
                                            <Col lg="9">
                                                <FormGroup>
                                                    <Input 
                                                        id="exampleFormControlInput1" 
                                                        type="text"
                                                        value={this.state.hierarchy[i].value}
                                                        onChange={this.handleHierarchyChange('value', i)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="12">
                                        <Row>
                                            <Col lg="3">
                                                <label className="form-control-label" htmlFor="input-username">Description:</label>
                                            </Col>
                                            <Col lg="9">
                                                <FormGroup>
                                                    <Input 
                                                        id="exampleFormControlInput1" 
                                                        type="text"
                                                        value={this.state.hierarchy[i].description}
                                                        onChange={this.handleHierarchyChange('description', i)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col> 
                                </div>
                                )
                            })
                        }
                    </Col>
                    <Col lg="2">
                        <Button
                          className=""
                          color="success"
                          onClick={() => this.handleAdd()}
                          size="sm"
                        >
                          Add
                        </Button>
                        {
                            this.state.hierarchy.length >=2 &&
                            <Button
                            className="right"
                            color="danger"
                            onClick={() => this.handleRemove()}
                            size="sm"
                            >
                            Remove
                            </Button>
                        }
                    </Col>
                </Row> */}

                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  groupData: _.isEmpty(state.companies.groupData)
    ? null
    : state.companies.groupData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGroup: (companyId) => dispatch(fetchGroup(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
