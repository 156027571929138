import {
  FETCH_SENSOR_TYPES,
  FETCH_SENSOR_TYPE_DETAILS,
  FETCH_SENSOR_DETAILS,
  FETCH_SENSOR,
  FETCH_SENSOR_COMPANY,
  SET_SENSOR_TYPE_ID
} from "../actions/sensorTypes.action";

const sensorTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SENSOR_TYPES:
      return {
        ...state,
        sensorTypesData: action.payload,
      };
    case FETCH_SENSOR_TYPE_DETAILS:
      return {
        ...state,
        sensorTypesDetailsData: action.payload,
      };
    case FETCH_SENSOR_DETAILS:
      return {
        ...state,
        sensorDetailsData: action.payload,
      };
    case FETCH_SENSOR:
      return {
        ...state,
        sensorData: action.payload,
      };
    case FETCH_SENSOR_COMPANY:
      return {
        ...state,
        sensorCompanyData: action.payload,
      };
    case SET_SENSOR_TYPE_ID:
      return {
        ...state,
        sensorTypeId:action.payload
      }
    default:
      // need this for default case
      return state;
  }
};
export default sensorTypesReducer;
