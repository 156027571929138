import { combineReducers } from "redux";
import homeReducer from "./home.reducer";
import sensorTypesReducer from "./sensorTypes.reducer";
import firmwareTypesReducer from "./firmwareTypes.reducer";
import processConfigurationReducer from "./processConfiguration.reducer";
import mountConfigurationReducer from "./mountConfiguration.reducer";
import machineTypeReducer from "./machineType.reducer";
import equipmentTypeReducer from "./equipmentType.reducer";
import componentTypesReducer from "./componentTypes.reducer";
import technicalSpecificationReducer from "./technicalSpecification.reducer";
import activitiesReducer from "./activities.reducer";
import companiesReducer from "./companies.reducer";
import notificationReducer from "./notification.reducer";
import employeeReducer from "./employee.reducer";
import eulaInfoReducer from "./eulaInfo.reducer";
import equipSubTypeReducer from "./equipmentSubTypes.reducer";
import manualReducer from "./manuals.reducer";
import feedbackReducer from "./feedback.reducer";
import organizationReducer from "./organization.reducer";
import userReducer from "./user.reducer";

// Combine with other reducers we may add in the future
const webPortal = combineReducers({
  home: homeReducer,
  feedback: feedbackReducer,
  sensorTypes: sensorTypesReducer,
  firmwareTypes: firmwareTypesReducer,
  processConfiguration: processConfigurationReducer,
  mountConfiguration: mountConfigurationReducer,
  machineType: machineTypeReducer,
  equipmentType: equipmentTypeReducer,
  componentTypes: componentTypesReducer,
  technicalSpecification: technicalSpecificationReducer,
  activities: activitiesReducer,
  companies: companiesReducer,
  notificationData: notificationReducer,
  employeeData: employeeReducer,
  eulaInfo: eulaInfoReducer,
  equipSubType: equipSubTypeReducer,
  manual: manualReducer,
  organization: organizationReducer,
  user: userReducer,
});

export default webPortal;
