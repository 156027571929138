import CallAPI from "../../axios";

export const FETCH_MACHINE_TYPES = "FETCH_MACHINE_TYPES";
export const FETCH_MACHINE_TYPES_DETAILS = "FETCH_MACHINE_TYPES_DETAILS";
export const FETCH_MACHINE_OPTIONS_DETAILS = "FETCH_MACHINE_OPTIONS_DETAILS";
export const FETCH_MACHINE_DETAILS = "FETCH_MACHINE_DETAILS";
export const FETCH_MACHINE = "FETCH_MACHINE";

const fetchData = (data) => {
  return {
    type: FETCH_MACHINE_TYPES,
    payload: data,
  };
};

const fetchMachineTypeDetailsData = (data) => {
  return {
    type: FETCH_MACHINE_TYPES_DETAILS,
    payload: data,
  };
};

const fetchMachineOptionsData = (data) => {
  return {
    type: FETCH_MACHINE_OPTIONS_DETAILS,
    payload: data,
  };
};

const fetchMachineData = (data) => {
  return {
    type: FETCH_MACHINE,
    payload: data,
  };
};

const fetchMachineDetailsData = (data) => {
  return {
    type: FETCH_MACHINE_DETAILS,
    payload: data,
  };
};

export const fetchMachineType = () => {
  return (dispatch) => {
    CallAPI.getData(`/machine-types`)
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchMachineTypeDetails = (plantId) => {
  return (dispatch) => {
    CallAPI.getData(`/machine-types/${plantId}`)
      .then((response) => {
        dispatch(fetchMachineTypeDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchMachineOptions = (companyId) => {
  return (dispatch) => {
    CallAPI.getData(`/machines/options/${companyId}`)
      .then((response) => {
        dispatch(fetchMachineOptionsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchMachine = (companyId) => {
  return (dispatch) => {
    CallAPI.machineManage_machineList(companyId)
      .then((response) => {
        if (response && response.status === 404) {
          dispatch(fetchMachineData([]));
        } else if (response === undefined) {
          dispatch(fetchMachineData([]));
        } else if (response) {
          dispatch(fetchMachineData(response.data));
        }
      })
      .catch((error) => {
        // Handle the error appropriately, e.g., dispatch an error action or log the error
        throw error;
      });
  };
};

export const fetchMachineDetails = (companyId, machineId) => {
  return (dispatch) => {
    CallAPI.machineManage_machineDetails(machineId)
      .then((response) => {
        dispatch(fetchMachineDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
