import React from "react";
import axios from "../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import Checkbox from "@material-ui/core/Checkbox";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import classes from "./sensor.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let sensorId = "";

class HardwareAdd extends React.Component {
  state = {
    currentTab: "rul",
    currentTab2: "clipping_thresholds",
    sensors: [],
    sensorUnit: ["vibration", "ultrasonic", "temperature", "humidity"],
    sensor: {},
    sensorTypes: [],
    tagOptions: [],
    //Navigations
    currentPage: 1,
    saving: false,
    pages: ["main", "sensorConfigurations"],
    defaultSensorType: "",
    sensors: ["vibration", "ultrasonic", "temperature", "humidity"],
    //SensorConfig
    sensorConfigurations: [
      {
        sensor: "vibration",
        sensitivity: "",
        samplingRate: "",
        maximumAcceleration: "",
        adcCoefficient: "",
        dataUnits: "",
        adcConversion: "",
      },
      {
        sensor: "ultrasonic",
        sensitivity: "",
        maximumAcceleration: "",
        samplingRate: "",
        referenceVoltage: "",
        maxVoltOutput: "",
        maxAdcOutput: "",
        numberToDrop: "",
        dataUnits: "",
      },
      {
        sensor: "temperature",
        sensitivity: "",
        maximumAcceleration: "",
        samplingRate: "",
        dataUnits: "",
      },
      {
        sensor: "humidity",
        sensitivity: "",
        maximumAcceleration: "",
        samplingRate: "",
        dataUnits: "",
      },
    ],
  };

  async componentDidMount() {
    sensorId = this.props.match.params.sensorId
      ? this.props.match.params.sensorId
      : null;

    if (sensorId) {
      await this.getSensor();
    }
  }

  handleRemove = (obj, index, key) => () => {
    let copy = [...this.state[obj]];
    delete copy[index][key];
    // console.log(copy[index][key]);
    this.setState({ [obj]: copy });
  };

  add = (obj, key, field) => {
    // console.log(obj);
    // return;
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.add(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        field
      );
    }

    if (keySplit.length == 1 && keySplit[0] != "") {
      // obj[key] = val;
      return (obj[key][field] = "");
    } else {
      // obj = val;
      return (obj[field] = "");
    }
  };

  handleAdd = (obj, index, key) => () => {
    let copy = { ...this.state[obj] };
    this.add(copy[index], key, this.state.field);
    this.setState({ add: false, field: "" });
  };

  handleAddChange = (obj) => (e) => {
    this.setState({ [obj]: e.target.value });
  };

  handleChange =
    (obj, key, index = -1, type = "string") =>
    (e) => {
      let copy = { ...this.state[obj] };
      let value = type === "string" ? e.target.value : e.target.checked;

      if (index >= 0) {
        copy[index][key] = value;
        let config = [...this.state.sensorConfigurations];
        this.setState({ sensorConfigurations: config });
      } else {
        copy[key] = value;
        this.setState({ [obj]: copy });
      }
    };

  handleNext = async (page) => {
    if (this.state.currentPage === 2) {
      this.setState({ saving: true });

      //Sensor API
      let sensor = { ...this.state.sensor };
      sensor.sensorConfigurations = [...this.state.sensorConfigurations];

      let sensorConfigAPI = await this.saveSensorConfig(sensor);
      if (sensorConfigAPI.status === 200) {
        toast.success("Sensor type saved successfully.");
        this.jump("/v1/sensor-inventory", 1000);
      } else if (sensorConfigAPI.status === 400) {
        toast.warn(sensorConfigAPI.message);
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let sensor = { ...this.state.sensor };
        //Validating
        let err = await validation.validate(sensor);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleCancel = (page) => {
    if (page === "main") {
      this.jump("/v1/sensor-inventory");
    }
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  async saveSensorConfig(sensorConfig) {
    let sensorConfigAPI = await axios.post_SensorType(sensorConfig);
    return {
      status: sensorConfigAPI.status,
      message: sensorConfigAPI.data.message,
    };
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    let label = "";
    if (this.state.currentPage === 1) label = "Sensor Type";
    else if (this.state.currentPage === 2) label = "Sensor Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Sensor Type";

    let front = "";
    if (this.state.currentPage === 1) front = "Sensor Configuration";
    front = "Submit";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6"></Col>
            <Col lg="6 table-caption">
              <h1>Add Sensor Type</h1>
            </Col>
          </Row>

          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <div
                    onClick={() => {
                      this.jump("/v1/sensor-inventory", 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>

                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>

                {/* Add Sensor Types*/}

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Sensor Type:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.sensor.sensorId}
                                onChange={this.handleChange(
                                  "sensor",
                                  "sensorType"
                                )}
                                disabled={this.state.readOnly || sensorId}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <div className="pl-lg-4">
                        <Tabs>
                          <TabList>
                            {this.state.sensors.length &&
                              this.state.sensors.map((tab, i) => (
                                <Tab key={i}>
                                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                </Tab>
                              ))}
                          </TabList>
                          <br />

                          {this.state.sensorConfigurations.length &&
                            this.state.sensorConfigurations.map((field, j) => {
                              if (field != "sensor") {
                                return (
                                  <TabPanel key={j}>
                                    {Object.keys(field).map((key) => {
                                      if (key != "sensor") {
                                        return (
                                          <Row key={key + j}>
                                            <Col lg="1"></Col>
                                            <Col lg="3">
                                              <FormGroup>
                                                <label className="form-control-label">
                                                  {key}:
                                                </label>
                                              </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                              <FormGroup>
                                                {key != "adcConversion" ? (
                                                  <Input
                                                    className="form-control-alternative"
                                                    id="input-username"
                                                    type="text"
                                                    value={field[key]}
                                                    onChange={this.handleChange(
                                                      "sensorConfigurations",
                                                      key,
                                                      j
                                                    )}
                                                  />
                                                ) : (
                                                  <Checkbox
                                                    checked={field[key]}
                                                    onChange={this.handleChange(
                                                      "sensorConfigurations",
                                                      key,
                                                      j,
                                                      "checkbox"
                                                    )}
                                                    color="primary"
                                                    inputProps={{
                                                      "aria-label":
                                                        "primary checkbox",
                                                    }}
                                                    disabled={
                                                      this.state.readOnly
                                                    }
                                                  />
                                                )}
                                              </FormGroup>
                                            </Col>
                                            <Col lg="2">
                                              {!this.state.readOnly && (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={this.handleRemove(
                                                    "sensorConfigurations",
                                                    j,
                                                    key
                                                  )}
                                                  className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                                                >
                                                  <i className="fa fa-times" />
                                                </div>
                                              )}
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    })}

                                    <Row>
                                      <Col mc="1"></Col>
                                      <Col md="11">
                                        {!this.state.readOnly && (
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              this.setState({ add: true });
                                            }}
                                            className="icon custom-icon rem-icon icon-shape bg-info text-white rounded-circle shadow"
                                          >
                                            <i className="fa fa-plus" />
                                          </div>
                                        )}
                                      </Col>
                                    </Row>

                                    {this.state.add && (
                                      <>
                                        <Col className="add-tab" md="12">
                                          <Col md="6">
                                            <FormGroup>
                                              <label className="form-control-label">
                                                Key name:
                                              </label>
                                              <Input
                                                className="form-control-alternative"
                                                id="input-username"
                                                type="text"
                                                value={this.state.field}
                                                onChange={this.handleAddChange(
                                                  "field"
                                                )}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <Button
                                              className={classes.deleteImage}
                                              color="danger"
                                              onClick={() => {
                                                this.setState({
                                                  add: false,
                                                  field: "",
                                                });
                                              }}
                                              size="sm"
                                            >
                                              Cancel
                                            </Button>

                                            <Button
                                              className={classes.deleteImage}
                                              color="success"
                                              onClick={this.handleAdd(
                                                "sensorConfigurations",
                                                j,
                                                ""
                                              )}
                                              size="sm"
                                            >
                                              Add
                                            </Button>
                                          </Col>
                                          <br />
                                        </Col>
                                      </>
                                    )}
                                  </TabPanel>
                                );
                              }
                            })}
                        </Tabs>
                      </div>
                    )}

                    {this.state.currentPage <= 2 && (
                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                <>
                                  {front}
                                  <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                    <i className="fa fa-forward" />
                                  </div>
                                </>
                              )}
                            </Button>
                          }

                          {
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() =>
                                this.handleCancel(
                                  this.state.pages[this.state.currentPage - 1]
                                )
                              }
                              size="md"
                            >
                              {this.state.currentPage === 1 ? (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-ban" />
                                </div>
                              ) : (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-backward" />
                                </div>
                              )}
                              {back}
                            </Button>
                          }
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default HardwareAdd;
