import CallAPI from "../../axios";

export const FETCH_REPORT_DATA = "FETCH_REPORT_DATA";

export const fetchReport = (startDate, endDate) => {
  return (dispatch) => {
    CallAPI.fetchReport(startDate, endDate)
      .then((response) => {
        dispatch(fetchReport(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
