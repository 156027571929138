import CallAPI from "../../axios";

export const FETCH_TECH_SPEC_DATA = "FETCH_TECH_SPEC_DATA";
export const FETCH_TECH_SPEC_DETAILS_DATA = "FETCH_TECH_SPEC_DETAILS_DATA";

const fetchData = (data) => {
  return {
    type: FETCH_TECH_SPEC_DATA,
    payload: data,
  };
};

const fetchTechSpecDetailsData = (data) => {
  return {
    type: FETCH_TECH_SPEC_DETAILS_DATA,
    payload: data,
  };
};

export const fetchTechSpec = () => {
  return (dispatch) => {
    CallAPI.getData("/technical-specifications")
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchTechSpecDetails = (id) => {
  return (dispatch) => {
    CallAPI.getData(`/technical-specifications/${id}`)
      .then((response) => {
        dispatch(fetchTechSpecDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
