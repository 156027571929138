import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MaterialIcon, { colorPalette } from "material-icons-react";
import axios from "../../../../axios";
import "./reports.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Form,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;

const columns = [
  {
    text: "Report Name",
    dataField: "name",
    sort: true,
  },
  {
    text: "Report Date",
    dataField: "timestamp",
    sort: true,
  },
  {
    text: "Report Url",
    dataField: "url",
    sort: true,
  },
];

const options = {
  showTotal: true,
};

let companyId = "";

class Reports extends React.Component {
  state = {
    reports: null,
    list: true,
    report: {},
    saving: false,
    date: "",
    columns: [
      {
        text: "Report Name",
        dataField: "name",
        sort: true,
      },
      {
        text: "Report Date",
        dataField: "timestamp",
        formatter: this.dateFormatter.bind(this),
        sort: true,
      },
      {
        text: "Report Url",
        dataField: "url",
        sort: true,
      },
      {
        text: "Actions",
        dataField: "_id",
        formatter: this.actionFormatter.bind(this),
        hidden: false,
      },
    ],
  };

  dateFormatter(cell, row, rowIndex, formatExtraData) {
    let dateObj = new Date(cell);
    return <p>{dateObj.toLocaleDateString()}</p>;
  }

  actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <MaterialIcon
        onClick={this.handleDelete.bind(this, row)}
        icon="delete"
        color="red"
      ></MaterialIcon>
    );
  }

  async handleDelete(firmwareType) {
    if (window.confirm("Do you really want to delete it?")) {
      //Calling backend API to delelte firmwareTypes
      let api = await axios.deleteReq("/reports/" + firmwareType._id);
      if (api.status === 200) {
        //Remove from state
        let index = this.state.reports.findIndex(
          (x) => x._id === firmwareType._id
        );
        let reports = [...this.state.reports];
        reports.splice(index, 1);
        this.setState({ reports: reports });
        toast.success("Data deleted successfully");
      }

      if (api.status === 400) {
        toast.error(api.data.message);
      }

      if (api.status === 500) {
        toast.error("Something went wrong. Please try again");
      }
    }
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    await this.getReports();
  }

  async getReports() {
    let reports = await axios.getData("/reports/" + companyId);
    this.setState({ reports: reports.data.data });
  }

  handleChange = (input) => (e) => {
    let report = { ...this.state.report };
    report[input] = e.target.value;
    this.setState({ report: report });
  };

  handleSelectChange = () => (option) => {
    let equipmentType = { ...this.state.equipmentType };
    equipmentType.equipmentType = option.value;
    this.setState({ equipmentType: equipmentType });
  };

  async handleSave() {
    this.setState({ saving: true });
    let report = { ...this.state.report };
    report.timestamp = this.state.timestamp;
    report.file = this.state.file;
    //Validating
    let testGroup = { ...report };
    let err = await validation.validate(testGroup);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    report.companyId = companyId;
    report.fileName = this.state.fileName;

    let endpoint = "/reports";
    let api = await axios.postData(endpoint, report);
    this.setState({ saving: false });
    if (api.status == 200) {
      toast.success("Report saved successfully");
      localStorage.removeItem("companyId");
      this.setState({ list: true });
      await this.getReports();
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  handleAdd() {
    let hierarchy = [...this.state.hierarchy];
    let obj = {
      name: "",
      level: "",
      value: "",
      description: "",
    };
    hierarchy.push(obj);
    this.setState({ hierarchy });
  }

  handleRemove() {
    let hierarchy = [...this.state.hierarchy];
    hierarchy.pop();
    this.setState({ hierarchy });
  }

  handleHierarchyChange = (input, index) => (e) => {
    let hierarchy = [...this.state.hierarchy];
    hierarchy[index][input] = e.target.value;
    this.setState({ hierarchy });
  };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  handleDateChange = (date) => {
    if (date) {
      this.setState({
        date: date,
        timestamp: date.getTime(),
      });
    }
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleFileInput = async (e) => {
    if(e.target.files[0] && e.target.files[0].size > 2000000){
      alert("File Size should be less than 2MB")
      return;
    }
    let fileName = e.target.files[0] ? e.target.files[0].name : "";
    let file = "";
    if (fileName) {
      file = await this.toBase64(e.target.files[0]);
    }

    this.setState({
      fileName,
      file,
    });
  };

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/companies/" + companyId + "/reports/" + row.plantId);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.list ? (
                <div
                  onClick={() => {
                    this.jump("/v1/companies/" + companyId);
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : (
                <div
                  onClick={() => {
                    this.setState({ list: true });
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              )}
              {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.setState({ list: false });
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Report
                </Button>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Reports</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Report</h3>
                  </CardHeader>
                )}
                {this.state.reports && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          // rowEvents={ rowEvents }
                          pagination={paginationFactory(options)}
                          keyField="_id"
                          data={this.state.reports}
                          columns={this.state.columns}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Equipment Types */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Name:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("name")}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>

                      <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            File:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="file"
                              accept="application/pdf, application/vnd.ms-excel"
                              onChange={this.handleFileInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>

                      <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Date:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <DatePicker
                              className="form-control-alternative"
                              selected={this.state.date}
                              onChange={this.handleDateChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Reports;
