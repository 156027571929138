import moment from "moment-timezone";

const helper = {
  isAuth() {
    return localStorage.getItem("portal-user")
      ? localStorage.getItem("portal-user")
      : null;
  },

  toLogin() {
    this.props.history.push("/auth/login/");
  },

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  deleteCookie(name) {
    return (document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
  },

  makeAuth(payload) {
    localStorage.setItem("portal-user", JSON.stringify(payload));
  },

  clearAuth() {
    localStorage.removeItem("portal-user");
    localStorage.removeItem("portal-user-access-token");
  },

  swap(json) {
    var ret = {};
    for (var key in json) {
      ret[json[key]] = key.toUpperCase();
    }
    return ret;
  },

  reduce(obj, key, val) {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  },

  isValidHttpUrl(string) {
    try {
      if (!string) return false;
      const newUrl = new URL(string);
      return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (err) {
      return false;
    }
  },

  getTimezonOptions() {
    const selectorOptions = moment.tz
      .names()
      .reduce((memo, tz) => {
        memo.push({
          value: tz,
          label: `(${
            moment.tz(tz).utcOffset() ? moment.tz(tz).format("Z") : "00:00"
          }) `.concat(tz),
          offset: moment.tz(tz).utcOffset(),
        });
        return memo;
      }, [])
      .sort((a, b) => {
        return a.offset - b.offset;
      });
    return selectorOptions;
  },
  deleteCookie ()  {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // delete all the cookies related to domain=.nanoprecisedataservices.com
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;  domain=.nanoprecisedataservices.com`;
    }
  },
  createDirectMessageJSON (directMessageCode) {
    const object={}
    directMessageCode.map(ele=>{
      object[ele.value]=
      {
          "condition": {
              "rul": null,
              "stage": null
          }
        }
    })
    return object
}
};


export default helper;
