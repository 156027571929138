import CallAPI from "../../axios";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
const fetchUserData = (data) => {
  return {
    type: FETCH_USER_DETAILS,
    payload: data,
  };
};

export const fetchUserDetails = (id) => {
  return (dispatch) => {
    CallAPI.fetchUserInfo(id)
      .then((response) => {
        if (response) {
          dispatch(fetchUserData(JSON.parse(JSON.stringify(response))));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};
