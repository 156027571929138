import CallAPI from "../../axios";

export const FETCH_ACTIVITIES_DATA = "FETCH_ACTIVITIES_DATA";
export const FETCH_EVENTS_DATA = "FETCH_EVENTS_DATA";
export const FETCH_COMPANY_DATA = "FETCH_COMPANY_DATA";

const fetchData = (data) => {
  return {
    type: FETCH_ACTIVITIES_DATA,
    payload: data,
  };
};

const fetchCompanyData = (data) => {
  return {
    type: FETCH_COMPANY_DATA,
    payload: data,
  };
};

const fetchEventsData = (data) => {
  return {
    type: FETCH_EVENTS_DATA,
    payload: data,
  };
};

export const fetchActivities = (companyId) => {
  return (dispatch) => {
    CallAPI.getData(`/activities/${companyId}`)
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEvents = (sessionId) => {
  return (dispatch) => {
    CallAPI.getData(`/activities/events/${sessionId}`)
      .then((response) => {
        dispatch(fetchEventsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
