import React from "react";
import axios from "../../../axios";
import Select from "react-select";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import "react-tabs/style/react-tabs.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./firmware.css";

class FirmwareAdd extends React.Component {
  state = {
    sensors: [],
    sensorUnit: ["vibration", "ultrasonic", "temperature", "humidity"],
    firmware: {},
    sensorTypes: [],
    defaultSensorType: "",
    //Navigations
    currentPage: 1,
    saving: false,
    pages: ["main", "firmwareConfigurations"],
    //SensorConfig
    firmwareConfiguration: [
      {
        sensor: "vibration",
        sensitivity: "",
        samplingFrequency: "",
        maximumAcceleration: "",
        adcCoefficient: "",
        dataUnits: "",
      },
      {
        sensor: "ultrasonic",
        sensitivity: "",
        samplingFrequency: "",
        maximumAcceleration: "",
        samplingRate: "",
        referenceVoltage: "",
        maxVoltOutput: "",
        maxAdcOutput: "",
        numberToDrop: "",
        dataUnits: "",
      },
      {
        sensor: "temperature",
        sensitivity: "",
        samplingFrequency: "",
        maximumAcceleration: "",
        samplingRate: "",
        dataUnits: "",
      },
      {
        sensor: "humidity",
        sensitivity: "",
        samplingFrequency: "",
        maximumAcceleration: "",
        samplingRate: "",
        dataUnits: "",
      },
      // {
      //   "adcConvertion": true
      // }
    ],
    field: "",
    add: false,
  };

  async componentDidMount() {
    await this.getSensorTypes();
  }

  async getSensorTypes() {
    let api = await axios.getSensorTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.sensorType,
        value: sen._id,
        id: sen._id,
      });
    });
    this.setState({ sensorTypes: options });
  }

  handleRemove = (obj, index, key) => () => {
    let copy = [...this.state[obj]];
    delete copy[index][key];
    // console.log(copy[index][key]);
    this.setState({ [obj]: copy });
  };

  add = (obj, key, field) => {
    // console.log(obj);
    // return;
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.add(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        field
      );
    }

    if (keySplit.length == 1 && keySplit[0] != "") {
      // obj[key] = val;
      return (obj[key][field] = "");
    } else {
      // obj = val;
      return (obj[field] = "");
    }
  };

  handleAdd = (obj, index, key) => () => {
    let copy = { ...this.state[obj] };
    this.add(copy[index], key, this.state.field);
    this.setState({ add: false, field: "" });
  };

  handleAddChange = (obj) => (e) => {
    this.setState({ [obj]: e.target.value });
  };

  handleChange =
    (obj, key, index = -1, type = "string") =>
    (e) => {
      let copy = { ...this.state[obj] };
      let value = type === "string" ? e.target.value : e.target.checked;

      if (index >= 0) {
        copy[index][key] = value;
        let config = [...this.state[obj]];
        this.setState({ [obj]: config });
      } else {
        copy[key] = value;
        this.setState({ [obj]: copy });
      }
    };

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  // handleChange = (param, input, type='string') => (e) => {
  //   let copy = {...this.state[param]};
  //   if (type === 'array') {
  //     let val = parseFloat(e.target.value);
  //     this.reduce(copy, input, [val]);
  //   } else if(type === 'checkbox') {
  //     this.reduce(copy, input, e.target.checked);
  //   } else if (type === 'number') {
  //     let val = parseFloat(e.target.value);
  //     this.reduce(copy, input, val);
  //   } else {
  //     this.reduce(copy, input, e.target.value);
  //   }
  //   // console.log(copy)
  //   this.setState({[param]: copy});
  // }

  handleNext = async (page) => {
    if (this.state.currentPage === 2) {
      this.setState({ saving: true });

      //Sensor API
      let firmware = { ...this.state.firmware };
      firmware.firmwareConfigurations = [...this.state.firmwareConfiguration];

      // return console.log(firmware);

      // console.log(sensorConfig, 'sensor Configuration');

      let firmwareAPI = await this.saveFirmware(firmware);
      if (firmwareAPI.status === 200) {
        toast.success("Firmware type saved successfully.");
        this.jump("/v1/firmware-types/", 1000);
      } else if (firmwareAPI.status === 400) {
        toast.warn(firmwareAPI.message);
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let firmware = { ...this.state.firmware };
        //Validating
        let err = await validation.validate(firmware);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleCancel = (page) => {
    if (page === "main") {
      this.jump("/v1/firmware-types/");
    }
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  async saveFirmware(firmware) {
    let API = await axios.post_FirmwareType(firmware);
    return {
      status: API.status,
      message: API.data.message,
    };
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    let processConfig = "";

    let label = "";
    if (this.state.currentPage === 1) label = "Firmware Type";
    else if (this.state.currentPage === 2) label = "Firmware Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Firmware Type";

    let front = "";
    if (this.state.currentPage === 1) front = "Firmware Configuration";
    else if (this.state.currentPage === 2) front = "Submit";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6"></Col>
            <Col lg="6 table-caption">
              <h1>Add Firmware Type</h1>
            </Col>
          </Row>

          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <div
                    onClick={() => {
                      this.jump("/v1/firmware-types", 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Firmware Type:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.firmware.firmwareType}
                                onChange={this.handleChange(
                                  "firmware",
                                  "firmwareType"
                                )}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Sensor Type
                            </label>
                            <Select
                                isClearable={false}
                                isDisabled={this.state.readOnly}
                                onChange={this.handleSelectChange('firmware', 'sensorType')}
                                // defaultValue={this.state.sensorTypes[this.state.defaultSensorId]}
                                value={this.state.sensorTypes[this.state.defaultSensorType]}
                                options={this.state.sensorTypes}
                            />
                          </FormGroup>
                        </Col> */}
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <div className="pl-lg-4">
                        <Tabs>
                          <TabList>
                            {this.state.sensorUnit.length &&
                              this.state.sensorUnit.map((tab, i) => (
                                <Tab key={i}>
                                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                </Tab>
                              ))}
                            {/* <Tab>ADC Convertion</Tab> */}
                          </TabList>
                          <br />

                          {this.state.firmwareConfiguration.length &&
                            this.state.firmwareConfiguration.map((field, j) => {
                              if (field != "sensor") {
                                return (
                                  <TabPanel key={j}>
                                    {Object.keys(field).map((key) => {
                                      if (key != "sensor") {
                                        return (
                                          <Row key={key + j}>
                                            <Col lg="1"></Col>
                                            <Col lg="3">
                                              <FormGroup>
                                                <label className="form-control-label">
                                                  {key}:
                                                </label>
                                              </FormGroup>
                                            </Col>

                                            {key != "adcConversion" ? (
                                              <Col lg="6">
                                                <FormGroup>
                                                  {
                                                    <Input
                                                      className="form-control-alternative"
                                                      id="input-username"
                                                      type="text"
                                                      value={field[key]}
                                                      onChange={this.handleChange(
                                                        "firmwareConfiguration",
                                                        key,
                                                        j
                                                      )}
                                                    />
                                                  }
                                                </FormGroup>
                                              </Col>
                                            ) : (
                                              <Col lg="6">
                                                <FormGroup>
                                                  {
                                                    <Input
                                                      className="form-control-alternative"
                                                      id="input-check"
                                                      type="checkbox"
                                                      checked={field[key]}
                                                      onChange={this.handleChange(
                                                        "firmwareConfiguration",
                                                        key,
                                                        j,
                                                        field,
                                                        "checkbox"
                                                      )}
                                                    />
                                                  }
                                                </FormGroup>
                                              </Col>
                                            )}

                                            {/* <Col lg="6">
                                            <FormGroup>
                                              {
                                                <Input
                                                  className="form-control-alternative"
                                                  id="input-username"
                                                  type="text"
                                                  value={field[key]}                                        
                                                  // onChange={this.handleChange(key, j, field)}
                                                  onChange={this.handleChange('firmwareConfiguration', key, j)}
                                                />
                                              }
                                            </FormGroup>
                                          </Col> */}
                                            <Col lg="2">
                                              {!this.state.readOnly && (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={this.handleRemove(
                                                    "firmwareConfiguration",
                                                    j,
                                                    key
                                                  )}
                                                  className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                                                >
                                                  <i className="fa fa-times" />
                                                </div>
                                              )}
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    })}

                                    <Row>
                                      <Col mc="1"></Col>
                                      <Col md="11">
                                        {!this.state.readOnly && (
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              this.setState({ add: true });
                                            }}
                                            className="icon custom-icon rem-icon icon-shape bg-info text-white rounded-circle shadow"
                                          >
                                            <i className="fa fa-plus" />
                                          </div>
                                        )}
                                      </Col>
                                    </Row>

                                    {this.state.add && (
                                      <>
                                        <Col className="add-tab" md="12">
                                          <Col md="6">
                                            <FormGroup>
                                              <label className="form-control-label">
                                                Key name:
                                              </label>
                                              <Input
                                                className="form-control-alternative"
                                                id="input-username"
                                                type="text"
                                                value={this.state.field}
                                                onChange={this.handleAddChange(
                                                  "field"
                                                )}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <Button
                                              className={classes.deleteImage}
                                              color="danger"
                                              onClick={() => {
                                                this.setState({
                                                  add: false,
                                                  field: "",
                                                });
                                              }}
                                              size="sm"
                                            >
                                              Cancel
                                            </Button>

                                            <Button
                                              className={classes.deleteImage}
                                              color="success"
                                              onClick={this.handleAdd(
                                                "firmwareConfiguration",
                                                j,
                                                ""
                                              )}
                                              size="sm"
                                            >
                                              Add
                                            </Button>
                                          </Col>
                                          <br />
                                        </Col>
                                      </>
                                    )}
                                  </TabPanel>
                                );
                              }
                            })}

                          {/* <TabPanel>
                          <Row>
                            <Col lg="2"></Col>
                            <Col lg="2">
                              <FormGroup>
                                <label className="form-control-label">
                                  ADC Convertion:
                                </label>
                              </FormGroup>  
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="checkbox"
                                  checked={this.state.adcConvertion.adcConvertion}            
                                  onChange={this.handleChange('adcConvertion', 'adcConvertion', 'checkbox')}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </TabPanel> */}
                        </Tabs>
                      </div>
                    )}

                    {this.state.currentPage <= 4 && (
                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                <>
                                  {front}
                                  <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                    <i className="fa fa-forward" />
                                  </div>
                                </>
                              )}
                            </Button>
                          }

                          {
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() =>
                                this.handleCancel(
                                  this.state.pages[this.state.currentPage - 1]
                                )
                              }
                              size="md"
                            >
                              {this.state.currentPage === 1 ? (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-ban" />
                                </div>
                              ) : (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-backward" />
                                </div>
                              )}
                              {back}
                            </Button>
                          }
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default FirmwareAdd;
