import React from "react";
// import { Select } from "antd";
import Select from "react-select";
import AsyncSelect from 'react-select/async';

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    },
  },
};

const BearingCustom = ({ ...props }) => {
  return <AsyncSelect defaultOptions placeholder={"Please Input At least 2 Characters to Search"} {...props} />;
};

export default BearingCustom;
