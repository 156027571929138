import {
  FETCH_MOUNT_TYPES,
  FETCH_MOUNT_TYPES_DETAILS
} from "../actions/mountConfiguration.action";

const mountTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MOUNT_TYPES:
      return {
        ...state,
        mountTypeData: action.payload,
      };
    case FETCH_MOUNT_TYPES_DETAILS:
      return {
        ...state,
        mountTypeDetailsData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default mountTypeReducer;
