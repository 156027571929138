import * as yup from "yup";

let validation = {
  async validate(object) {
    let error = "";

    //Defining schema
    let schema = yup.object().shape({
      domain: yup.string().required().label("domain"),
      eula: yup.string().required().label("eula"),
      active: yup.boolean().required().label("active"),
    });

    //validating against schema
    try {
      await schema.validate(object, { abortEarly: false, strict: true });
    } catch (err) {
      error = err.errors[0];
    }
    return error;
  },
};

export default validation;
