import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { fetchCompanies } from "../../../redux/actions/companies.action";
import "./companies.css";
import axios from "../../../axios";
import Papa from "papaparse";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import Info from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import zones from "./timezones-pytz";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import DIYQuest from "./Equipments/componentConfigForm/index";
import Helper from "../../../helper";
import validation from "./validations";
import moment from "moment";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class Companies extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;
  }

  state = {
    companies: null,
    list: true,
    chek: [],
    open: false,
    defaultTimezone: "",
    company: {
      energyCostFactor: 0.19,
      timezone: "",
      notificationToBeChecked: "false",
      active: "true",
      Layout: null,
      dashboard_link: "https://nanoprecisedataservices.com/",
      hyperlink_link: "https://nanoprecisedataservices.com/",
      defaultLang: {
        key: "defaultLang",
        langType: "eng",
        label: "English",
      },
      secondLang: {
        key: "secondLang",
        langType: "eng",
        label: "English",
      },
      config: {
        sensorHealthDashboard: false,
        exportDataToPtc: false,
        healthSummaryDashboard: false,
        soundHPF: true,
        S3ForStaticData: true,
        overallrms: false,
        demo: false,
        unit: "metric",
        dataExport: {
          active: false,
          url: "https://cloud.stg.az.relayr.io",
          key: "",
        },
        maintenanceLog: false,
        thresholdSharing: {
          active: false,
          keyList: [],
        },
        dailyConsolidatedAlarm: {
          active: false,
          directMessageCodes: {},
          numberOfBatchNotificationPerDay: 1,
          includeDirectNotification: true,
          smsNotification: true,
        },
        idleTimeConfig: {
          type: "lastRunning",
          rangeColor: [
            {
              idleDuration: "0-30",
              color: "#7ada2c",
            },
            {
              idleDuration: "31-60",
              color: "#f0c82a",
            },
            {
              idleDuration: "61-90",
              color: "#f57c00",
            },
            {
              idleDuration: ">90",
              color: "#fa0011",
            },
            {
              idleDuration: "No Data",
              color: "#BDBDBD",
            },
          ],
        },
        notificationConfig: {
          batteryNotification: false,
          batteryNotificationThreshold: 2.5,
        },
        s3Export: {
          active: false,
          bucket: "",
        },
      },
      sensorStatsFrequencyInHours: 96,
      externalId: null,
    },
    layoutType: [
      {
        key: 0,
        label: "plant-equipment-component",
        value: "plantEquipmentComponent",
      },
      {
        key: 1,
        label: "plant-machine-equipment-component",
        value: "plantMachineEquipmentComponent",
      },
    ],

    saving: false,
    columns: [
      {
        text: "Company Code",
        dataField: "companyId",
        sort: true,
      },
      {
        text: "Company Name",
        dataField: "companyName",
        sort: true,
      },
      {
        text: "Custom Name",
        dataField: "customName",
        sort: true,
      },
      {
        text: "Headquarters",
        dataField: "headQuarters",
        sort: true,
        formatter: this.strong,
      },
      {
        text: "Delete",
        dataField: "companyId",
        formatter: (row) => {
          return <MaterialIcon icon="delete" color="red" />;
        },
      },
    ],
    csvfile: undefined,
    isUpload: true,
    demoOpen: false,
    demoOpen2: false,
    users: [],
  };

  strong(cell, row) {
    let length = cell.length;
    let trim = length > 35 ? cell.substr(0, 35) + "......" : cell;
    return trim;
  }
  async getTimezones() {
    let timezones = [];
    zones.forEach((z, i) => {
      timezones.push({
        key: i,
        label: z,
        value: z,
      });
    });
    this.setState({
      timezones: timezones,
    });
  }
  async componentDidMount() {
    this.props.fetchCompanies();
    await this.getTimezones();
  }

 

  handleChange = (input) => (e) => {
    let company = { ...this.state.company };
    company[input] = e.target.value;
    this.setState({ company: company });
  };

  async handleSave() {
    this.setState({ saving: true });

    if (this.state.company.Layout === null) {
      toast.warn("Please Select a Layout Type");
      this.setState({ saving: false });
      return;
    }
    //Validating
    let err = await validation.validate(this.state.company);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }
    // if (this.state.company && this.state.company.timezone == "") {
    //   this.setState({ saving: false });
    //   return toast.error("Select a timezone");
    // }

    let api = await axios.companyManage_create({
      companyName: this.state.company.companyName,
      companyId: this.state.company.companyId,
      aboutCompany: this.state.company.aboutCompany,
      customName: this.state.company.customName,
      Layout: this.state.company.Layout,
      active: true,
      headQuarters: this.state.company.headQuarters,
      externalId: this.state.company.externalId,
    });

    this.setState({ saving: false });
    if (api.status == 200) {
      let saveConfig = {
        companyId: this.state.company.companyId,
        timezone: null,
        notificationToBeChecked: false,
        dashboard_link: "https://nanoprecisedataservices.com/",
        energyCostFactor: 0.19,
        hyperlink_link: "https://nanoprecisedataservices.com/",
        defaultLang: {
          key: "defaultLang",
          langType: "eng",
          label: "English",
        },
        secondLang: {
          key: "secondLang",
          langType: "eng",
          label: "English",
        },
        sensorHealthDashboard: false,
        exportDataToPtc: false,
        healthSummaryDashboard: false,
        energyEfficiency: false,
        soundHPF: true,
        S3ForStaticData: true,
        overallrms: false,
        demo: this.state.company.config.demo,
        unit: "metric",
        dataExport: {
          active: false,
          url: "https://cloud.stg.az.relayr.io",
          key: "",
        },
        maintenanceLog: false,
        thresholdSharing: {
          active: false,
          keyList: [],
        },
        dailyConsolidatedAlarm: {
          active: false,
          directMessageCodes: {},
          numberOfBatchNotificationPerDay: 1,
          includeDirectNotification: true,
          smsNotification: true,
        },
        idleTimeConfig: {
          type: "lastRunning",
          rangeColor: [
            {
              idleDuration: "0-30",
              color: "#7ada2c",
            },
            {
              idleDuration: "31-60",
              color: "#f0c82a",
            },
            {
              idleDuration: "61-90",
              color: "#f57c00",
            },
            {
              idleDuration: ">90",
              color: "#fa0011",
            },
            {
              idleDuration: "No Data",
              color: "#BDBDBD",
            },
          ],
        },
        notificationConfig: {
          batteryNotification: false,
          batteryNotificationThreshold: 2.5,
        },
        s3Export: {
          active: false,
          bucket: "",
        },
        sensorStatsFrequencyInHours: 96,
        vib_rms_unit: "acc",
        vib_spectrum_domain: "time",
        vib_spectrum_unit: "vel",
        vib_waterfall_domain: "order",
        vib_waterfall_unit: "vel",
        vib_waveform_domain: "envelope",
        vib_waveform_unit: "dis",
        vibSpecCpmRange: "55000",
        vibSpecDenoisedRange: "1000",
        vibSpecDomainValue: "cycle",
        vibSpecEnvFreqRange: "1000",
        vibSpecEnvOrdRange: "100",
        vibSpecFreqDomainRange: "1000",
        vibSpecOrderDomainRange: "100",
        vibSpecRange: "80000",
        temperature: "C",
        intenseNanoAIMeasurement: true,
        dynamicDashboardetails: { isDynamicDashboardDetails: false },
      };

      let api2 = await axios.companyConfig_all(saveConfig);
      if (api.status === 200 && this.state.csvfile) {
        let endpoint = "/users";
        this.setState({ isUpload: true });
        let api3 = await axios.postData(endpoint, this.state.usersList);

        toast.success("File uploaded Successfully");
        this.handleDemoSuccess();
      } else {
        toast.warn(api.data.message);
        let { company } = this.state;
        company.config.demo = false;
        this.setState({ company });
      }
      toast.success("Company saved successfully !");
      localStorage.removeItem("companyId");
      //Redirect to company details page
      this.jump("/v1/companies/" + this.state.company.companyId, 1000);
    } else if (api.status == 400) {
      if (api.data.data == "Existing record found with the same companyId") {
        let msg = `Company code ${this.state.company.companyId} already exist`;
        toast.warn(msg);
      } else {
        toast.warn(api.data.data);
      }
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  handleDemoCompanies = async (event) => {
    let { demoOpen, company } = this.state;
    demoOpen = event.target.checked;
    company.config.demo = event.target.checked;
    this.setState({ demoOpen, company });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleDemoClose = () => {
    let { company } = this.state;
    company.config.demo = !company.config.demo;
    this.setState({ demoOpen: false, csvfile: null, isUpload: false, company });
  };
  handleDemoSuccess = () => {
    let { company } = this.state;
    company.config.demo = true;
    this.setState({ demoOpen: false, isUpload: true, company });
  };

  handleFileInput = (event) => {
    this.setState({
      csvfile: event.target.files[0],
      isUpload: false,
    });
  };

  validateDataEntered = (dataToValidate) => {
   
    let successArray = [];
    //  let successArray = _.differenceBy(dataToValidate, this.state.users, 'userName')

    dataToValidate.map((val) => {
      let foundUserName = false,
        foundEmail = false;
      for (var i = 0; i < this.state.users.length; i++) {
        if (
          this.state.users.length &&
          this.state.users[i].userName === val.userName
        ) {
          foundUserName = true;
        }
        if (
          this.state.users.length &&
          this.state.users[i].email === val.email
        ) {
          foundEmail = true;
        }
      }

      if (foundUserName === true) {
        alert(`${val.userName} already exist`);
      }
      if (foundEmail === true) {
        alert(`${val.email} already exist`);
      }
      if (foundUserName === false && foundEmail === false) {
        let userGroupId = 2000;
        val["companyId"] = this.state.company.companyId;
        val["userGroupId"] = userGroupId;
        successArray.push(val);
      }
    });
    return { successArray };
  };

  updateData = async (result) => {
    let formatCheck = false;
    result.data.forEach((item) => {
      if (item.hasOwnProperty("userName") && item.hasOwnProperty("email"))
        return;
      formatCheck = true;
    });

    if (formatCheck) {
      alert(
        "Wrong format!!! please upload a right format use header userName and email"
      );
      return;
    }

    const data = this.validateDataEntered(result.data);

    //const data = result.data
    if (data.successArray.length > 0) {
      let validData = data.successArray;
      let usersList = {
        validData,
        companyId: this.state.company.companyId,
        bulkUsers: true,
      };
      this.setState({ usersList: usersList, demoOpen: false });
    }
  };

  importCSV = () => {
    if (!this.state.csvfile) {
      alert("Please upload file");
    }
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true,
    });
  };

  handleSkip = () => {
    let { company } = this.state;
    company.config.demo = company.config.demo;
    this.setState({ demoOpen: false, isUpload: false, company });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleSelectChange(e) {
    // console.log(e.value, "logdata");
    let { company } = this.state;

    if (e.value === "plantEquipmentComponent") {
      company.Layout = 0;
    } else if (e.value === "plantMachineEquipmentComponent") {
      company.Layout = 1;
    } else {
      company.Layout = null;
    }
    this.setState({ company });
  }

  handleTimeZoneSelect = (option) => {
    let company = { ...this.state.company };
    company.timezone = option.value;
    this.setState({
      company,
      defaultTimezone: option.key,
    });
  };

  handleS3ForStaticDataActive = async (event) => {
    let company = { ...this.state.company };
    company.config.S3ForStaticData = !company.config.S3ForStaticData;
    this.setState({ company });
  };

  handleOverAllRmsActive = async (event) => {
    let company = { ...this.state.company };
    company.config.overallrms = !company.config.overallrms;
    this.setState({ company });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.companyData !== prevState.companyData) {
      if (nextProps.companyData) {
        return {
          companies: nextProps.companyData.data,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: async (e, row, rowIndex) => {
        if (e.target.outerHTML.includes("delete")) {
          console.log(row.companyId);
          if (window.confirm("Do you really want to delete it?")) {
            await axios.companyManage_Delete(row.companyId);
            toast.success("Deleted Successfully");
            this.props.fetchCompanies();
          }
          return;
        }
        this.jump("/v1/companies/" + row.companyId);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />

        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {/* {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.setState({ list: false });
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Company
                </Button>
              )} */}
            </Col>
            <Col lg="6 table-caption">
              <h1>Companies</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Company</h3>
                  </CardHeader>
                )}
                {this.state.companies && this.state.list && (
                  <ToolkitProvider
                    keyField="_id"
                    data={this.state.companies}
                    columns={this.state.columns}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          rowEvents={rowEvents}
                          keyField="_id"
                          data={this.state.companies}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Company */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Company Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              valid={false}
                              onChange={this.handleChange("companyName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Company Code (4 Char Upper Case){" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              type="email"
                              onChange={this.handleChange("companyId")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Custom Name <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              type="text"
                              onChange={this.handleChange("customName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Headquarter <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              onChange={this.handleChange("headQuarters")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-dashboard"
                            >
                              DashBoard
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              defaultValue={this.state.company.dashboard_link}
                              onChange={this.handleChange("dashboard_link")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col> */}
                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-dashboard"
                            >
                              Hyperlink
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              defaultValue={this.state.company.hyperlink_link}
                              onChange={this.handleChange("hyperlink_link")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col> */}
                        {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-dashboard"
                            >
                              Sensor Stats email Frequency (in hours)
                              <Tooltip
                                title="Send notification if the data is not being received for this amount of time from the sensor. Default value is 4 days or 96 hours"
                                placement="right-start"
                              >
                                <Info
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    fontSize: "30px",
                                  }}
                                />
                              </Tooltip>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              defaultValue={
                                this.state.company.sensorStatsFrequencyInHours
                              }
                              type="number"
                              onChange={this.handleChange(
                                "sensorStatsFrequencyInHours"
                              )}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col> */}
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Layout Type <span className="text-danger">*</span>
                            </label>

                            <Select
                              onChange={(e) => this.handleSelectChange(e)}
                              placeholder=""
                              options={this.state.layoutType}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              External ID{" "}
                              <Tooltip
                                title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                                placement="right-start"
                              >
                                <Info
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    fontSize: "30px",
                                  }}
                                />
                              </Tooltip>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              onChange={this.handleChange("externalId")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              About Company{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-about-company"
                              // placeholder="A few words about company ..."
                              rows="8"
                              type="textarea"
                              onChange={this.handleChange("aboutCompany")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                              style={{ fontWeight: "bold" }}
                            >
                              Timezone{" "}
                              <span className="text-danger">
                                * (This will effect the Date & Time parameter in
                                the notifications)
                              </span>
                            </label>
                            {this.state.timezones && (
                              <Select
                                className="form-control-alternative"
                                isClearable={true}
                                onChange={this.handleTimeZoneSelect}
                                placeholder=""
                                options={this.state.timezones}
                                value={
                                  this.state.timezones[
                                    this.state.defaultTimezone
                                  ]
                                }
                                // isDisabled={this.state.readOnly}
                              />
                            )}
                          </FormGroup>
                        </Col> */}
                        {/* <Col lg="6">
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <IOSSwitch
                                checked={
                                  this.state.company.config.S3ForStaticData
                                }
                                onChange={this.handleS3ForStaticDataActive}
                                name="S3ForStaticData"
                              />
                            }
                            label={
                              this.state.company.config.S3ForStaticData === true
                                ? "S3 For Static Data is Enabled"
                                : "S3 For Static Data is Disabled"
                            }
                          />
                        </Col> */}
                        {/* <Col lg="6">
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <IOSSwitch
                                checked={this.state.company.config.overallrms}
                                onChange={this.handleOverAllRmsActive}
                                name="overallrms"
                              />
                            }
                            label={
                              this.state.company.config.overallrms === true
                                ? "Overall RMS is Enabled"
                                : "Overall RMS Data is Disabled"
                            }
                          />
                        </Col> */}
                        <Col lg="6">
                          <FormControlLabel
                            className="mb-0"
                            control={
                              <IOSSwitch
                                checked={this.state.company.config.demo}
                                onChange={this.handleDemoCompanies}
                                name="demo"
                              />
                            }
                            label={
                              this.state.company.config.demo === false
                                ? "Demo Dashboard"
                                : "Demo Dashboard Enabled"
                            }
                          />
                        </Col>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              //onClick={this.state.company.config.demo === true ? this.handleSaveDemo && this.handleSave : this.handleSave}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>

        <Dialog
          maxWidth="xl"
          onClose={this.handleDemoClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.demoOpen}
        >
          <DialogTitle>
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="font-weight-bold">Upload user file</h5>
              </div>
            </div>
          </DialogTitle>

          <DialogContent dividers>
            <Input
              className="csv-input"
              id="input-username"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={this.handleFileInput}
            />

            <Button
              style={{ float: "right", marginTop: "10px", marginRight: "5px" }}
              color="warning"
              size="sm"
              onClick={this.handleSkip}
            >
              skip
            </Button>

            <Button
              style={{ float: "right", marginTop: "10px", marginRight: "10px" }}
              color="success"
              size="sm"
              onClick={this.importCSV}
            >
              upload
            </Button>
            <Button
              style={{ float: "right", marginTop: "10px" }}
              className="right cmr-10"
              color="danger"
              onClick={this.handleDemoClose}
              size="sm"
            >
              Cancel
            </Button>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: _.isEmpty(state.companies.companyData)
    ? null
    : state.companies.companyData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanies: () => dispatch(fetchCompanies()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
