import React from "react";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ImageUploader from "react-images-upload";
import "./equipments.css";
import axios from "../../axios";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { SearchBar } = Search;

const columns = [
  {
    text: "Id",
    dataField: "_id",
    sort: true,
  },
  {
    text: "Company Code",
    dataField: "companyId",
    sort: true,
  },
  {
    text: "Plant Name",
    dataField: "plantName",
    sort: true,
  },
  {
    text: "Equipment Name",
    dataField: "equipmentName",
    sort: true,
  },
  {
    text: "Component Name",
    dataField: "componentName",
    sort: true,
  },
  {
    text: "Sensor Id",
    dataField: "sensorID",
    sort: true,
  },
  // {
  //   text: 'Equipment Type',
  //   dataField: 'equipmentTypeData.equipmentType',
  //   sort: true
  // }
];

const options = {
  showTotal: true,
};

let companyId = "";
let machineId = "";

class Scanners extends React.Component {
  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login", 0);
    }
  }

  state = {
    scanners: null,
    saving: false,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;

    await this.getScanners(); //Get Scanners
  }

  async getScanners(equipmentType = null) {
    let endpoint = "/scanners";
    let scanners = await axios.getData(endpoint);
    this.setState({ scanners: scanners.data });
  }

  jump = (url, delay = 1000) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/companies/" + row.companyCode + "/scanners/" + row._id, 0);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row>
            <Col lg="12" className="table-caption">
              <h1>Scanner App Data</h1>
            </Col>
          </Row>
          <Row></Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.scanners && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={this.state.equipments}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="_id"
                          rowEvents={rowEvents}
                          data={this.state.scanners}
                          columns={columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Scanners;
