import React from "react";
import axios from "../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { SearchBar } = Search;

const columns = [
  {
    text: "Sensor Id",
    dataField: "sensorId",
    sort: true,
  },
  {
    text: "Sensor Type",
    dataField: "sensorType",
    sort: true,
  },
  {
    text: "Tag ID",
    dataField: "configMap.tagId",
    sort: true,
  },
];

let mountTypes = [
  {
    key: 0,
    label: "Vertical",
    value: "vertical",
  },
  {
    key: 1,
    label: "Horizontal",
    value: "horizontal",
  },
  {
    key: 2,
    label: "Axial",
    value: "axial",
  },
];

let companyId = "";
let sensorId = "";
let is = [];

class HardwareAdd extends React.Component {
  state = {
    currentTab: "rul",
    currentTab2: "clipping_thresholds",
    sensors: [],
    sensorUnit: ["vibration", "ultrasonic", "temperature", "humidity"],
    sensor: {},
    sensorTypes: [],
    tagOptions: [],
    //Navigations
    currentPage: 1,
    saving: false,
    pages: ["main", "sensorConfigurations"],
    defaultSensorType: "",

    //SensorConfig
    vibration: {
      sensor: "vibration",
      sensitivity: "",
      samplingFrequency: "",
      maximumAcceleration: "",
    },
    ultrasonic: {
      sensor: "ultrasonic",
      sensitivity: "",
      samplingFrequency: "",
      maximumAcceleration: "",
    },
    temperature: {
      sensor: "temperature",
      sensitivity: "",
      samplingFrequency: "",
      maximumAcceleration: "",
    },
    humidity: {
      sensor: "humidity",
      sensitivity: "",
      samplingFrequency: "",
      maximumAcceleration: "",
    },
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId
      ? this.props.match.params.sensorId
      : null;

    if (sensorId) {
      await this.getSensor();
    }
  }

  //Only for editing Tag that exists
  async getTagConfigMap(tagId, sensorTypeId) {
    let endpoint = "/tags/" + tagId + "/" + sensorTypeId;
    let api = await axios.getData(endpoint);
    let data = api.data.data;
    if (!data) {
      this.setState(this.initialState);
      return;
    }

    //Setting data for sensorConfig
    let configurations = data.sensorConfig.configurations;
    this.setState({ configurations });
    // let sensors = [ "vibration", "ultrasonic", "temperature", "humidity" ];

    let sensors = this.state.sensors;
    if (configurations[0]) {
      configurations.forEach((conf) => {
        if (conf.sensor) {
          let sensor = conf.sensor.toLowerCase();
          sensors.push(sensor);
        }
      });
    }
    this.setState({ sensors });

    //Getting configurations data and saving in state
    sensors.forEach((sensor, i) => {
      let smallSensor = sensor;
      let capitilizeSensor = sensor.charAt(0).toUpperCase() + sensor.slice(1);

      let indexSmall = configurations.findIndex((x) => x.sensor == smallSensor);
      let indexCapitilize = configurations.findIndex(
        (x) => x.sensor == capitilizeSensor
      );
      if (indexSmall > -1)
        this.setState({ [sensor]: configurations[indexSmall] });
      if (indexCapitilize > -1) {
        this.setState({ [sensor]: configurations[indexCapitilize] });
      }
    });
    this.setState({ adcConvertion: configurations[configurations.length - 1] });
  }

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  handleChange =
    (param, input, type = "string") =>
    (e) => {
      let copy = { ...this.state[param] };
      if (type === "array") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, [val]);
      } else if (type === "checkbox") {
        this.reduce(copy, input, e.target.checked);
      } else if (type === "number") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, val);
      } else {
        this.reduce(copy, input, e.target.value);
      }
      // console.log(copy)
      this.setState({ [param]: copy });
    };

  handleSelectChange = (state, key) => async (option) => {
    let copy = { ...this.state[state] };
    copy[key] = option ? option.value : "";
    let defaultKey = "";
    let value = option ? option.value : "";
    let defaultValue = option ? option.key : 0;

    if (key === "sensorType") {
      copy["sensorTypeId"] = option ? option.id : "";
      defaultKey = "defaultSensorType";

      //Call API and fetch tagConfigMap data from server and set in state
      if (this.state.sensor.tagId) {
        // alert(option.id);
        // alert(this.state.sensor.tagId)
        await this.getTagConfigMap(this.state.sensor.tagId, option.id);
      }
    }

    if (key === "tagId") {
      defaultKey = "defaultTagId";

      //Call API and fetch tagConfigMap data from server and set in state
      if (option.value && this.state.sensor.sensorType)
        await this.getTagConfigMap(
          option.value,
          this.state.sensor.sensorTypeId
        );
    }

    if (key === "mountType") {
      defaultKey = "defaultMountType";
    }

    if (key === "mountMapping.horizontal") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultHorizontal";
    }

    if (key === "mountMapping.vertical") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultVertical";
    }

    if (key === "mountMapping.axial") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultAxial";
    }

    this.setState({ [state]: copy, [defaultKey]: defaultValue });
  };

  handleSave = async () => {
    this.setState({ saving: true });
    let sensorType = { ...this.state.sensor };

    // console.log(sensorType);
    // return;

    let api = await axios.post_SensorType(sensorType);
    if (api.status == 200) {
      toast.success("Sensor type saved successfully");
      //Redirect to company details page
      this.jump("/v1/sensor-inventory");
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status == 500) {
      toast.error("Something wet wrong. Please try again");
    }
  };

  handleNext = async (page) => {
    if (this.state.currentPage === 2) {
      this.setState({ saving: true });

      //Sensor API
      let sensor = { ...this.state.sensor };

      //Sensor Config
      let sensorConfig = {};
      sensor.sensorConfigurations = [
        this.state.vibration,
        this.state.ultrasonic,
        this.state.temperature,
        this.state.humidity,
      ];
      // console.log(sensorConfig, 'sensor Configuration');

      let sensorConfigAPI = await this.saveSensorConfig(sensor);
      if (sensorConfigAPI.status === 200) {
        toast.success("Sensor type saved successfully.");
        this.jump("/v1/sensor-inventory", 1000);
      } else if (sensorConfigAPI.status === 400) {
        toast.warn(sensorConfigAPI.message);
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let sensor = { ...this.state.sensor };
        //Validating
        let err = await validation.validate(sensor);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleCancel = (page) => {
    if (page === "main") {
      this.jump("/v1/sensor-inventory");
    }
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  async saveSensorConfig(sensorConfig) {
    let sensorConfigAPI = await axios.post_SensorType(sensorConfig);
    return {
      status: sensorConfigAPI.status,
      message: sensorConfigAPI.data.message,
    };
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    let label = "";
    if (this.state.currentPage === 1) label = "Sensor Type";
    else if (this.state.currentPage === 2) label = "Sensor Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Sensor Type";

    let front = "";
    if (this.state.currentPage === 1) front = "Sensor Configuration";
    front = "Submit";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {/* Add Sensor */}

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Sensor Type:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.sensor.sensorId}
                                onChange={this.handleChange(
                                  "sensor",
                                  "sensorType"
                                )}
                                disabled={this.state.readOnly || sensorId}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <div className="pl-lg-4">
                        <Tabs>
                          <TabList>
                            {this.state.sensorUnit.length &&
                              this.state.sensorUnit.map((tab, i) => (
                                <Tab key={i}>
                                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                </Tab>
                              ))}
                          </TabList>
                          <br />
                          {this.state.sensorUnit.length &&
                            this.state.sensorUnit.map((sensor, i) => (
                              <TabPanel key={i}>
                                {Object.keys(this.state[sensor]).map(
                                  (field, j) => {
                                    if (field != "sensor") {
                                      return (
                                        <Row key={j}>
                                          <Col lg="1"></Col>
                                          <Col lg="3">
                                            <FormGroup>
                                              <label className="form-control-label">
                                                {field}:
                                              </label>
                                            </FormGroup>
                                          </Col>
                                          <Col lg="6">
                                            <FormGroup>
                                              <Input
                                                className="form-control-alternative"
                                                id="input-username"
                                                type="text"
                                                value={
                                                  this.state[sensor][field]
                                                }
                                                onChange={this.handleChange(
                                                  sensor,
                                                  field
                                                )}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="2"></Col>
                                        </Row>
                                      );
                                    }
                                  }
                                )}
                              </TabPanel>
                            ))}
                        </Tabs>
                      </div>
                    )}

                    {this.state.currentPage <= 2 && (
                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                <>
                                  {front}
                                  <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                    <i className="fa fa-forward" />
                                  </div>
                                </>
                              )}
                            </Button>
                          }

                          {
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() =>
                                this.handleCancel(
                                  this.state.pages[this.state.currentPage - 1]
                                )
                              }
                              size="md"
                            >
                              {this.state.currentPage === 1 ? (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-ban" />
                                </div>
                              ) : (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-backward" />
                                </div>
                              )}
                              {back}
                            </Button>
                          }
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default HardwareAdd;
