import React from "react";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ImageUploader from "react-images-upload";
import "./equipments.css";
import axios from "../../../../axios";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;

let companyId = "";
let machineId = "";

class FeedbackSection extends React.Component {
  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  state = {
    equipments: null,
    equipment: {},
    equipmentTypes: [],
    equipmentType: null,
    images: [],
    saving: false,
    groups: [],
    selectedOption: null,
    selectedRstFaultDetect: false,
    selectedMainTenanceWatch: false,
    selectedNoAction: false,
    isModalOpen: false,
    isModal2Open: false,
    isArchiveBtn: false,
    archiveMessage: null,
    portalComments: null,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId
      ? this.props.match.params.companyId
      : companyId;
  }

  async getEquipmentTypeOptions() {
    let endpoint = "/equipments/types";
    let equipmentTypes = await axios.getData(endpoint);
    let options = [];
    equipmentTypes.data.data.forEach((eq, i) => {
      options.push({
        key: i,
        label: `${eq.customName} -> (${eq.equipmentTypeCode})`,
        value: eq.equipmentTypeCode,
      });
    });

    this.setState({ equipmentTypes: options });
  }

  handleSelectChange =
    (field, type = "filter") =>
    async (option) => {
      if (type === "filter") {
        let equipmentType = option ? option.value : null;
        await this.getEquipments(equipmentType);
      } else if (type === "add") {
        // let equipment = {...this.state.equipment};
        // equipment[field] = option.value;
        // this.setState({equipment});

        if (field === "plantId") {
          let equipment = { ...this.state.equipment };
          equipment.plantId = option.value;
          this.setState({ equipment });
        } else if (field === "equipmentType") {
          let equipment = { ...this.state.equipment };
          equipment.equipmentType = option.value;
          this.setState({ equipment });
        }
      }
    };

  handleChange = (input) => (e) => {
    let equipment = { ...this.state.equipment };
    equipment[input] = e.target.value;
    this.setState({ equipment: equipment });
  };

  async handleSave() {
    if (!this.state.portalComments || this.state.portalComments.length === 0) {
      alert("Please enter Comments!");
      return;
    }
    if (this.state.selectedMainTenanceWatch) {
      let data = {
        maintenanceTimestamp: parseInt(+new Date() / 1000),
        tagId: this.props.location.state.tagId,
        companyId: this.props.location.state.companyId,
        feedbackId: this.props.location.state.feedbackId,
        equipmentId: this.props.location.state.equipmentId,
        portalComments: this.state.portalComments,
        feedbackId: this.props.location.state.feedbackId,
        checkSensorList: false,
      };
      await axios.post_feedBack_maintenanceWatch(data).then((val) => {
        if (val.status === 200) toast.success("User saved successfully");
        else {
          if (val.data.message) toast.warn(val.data.message);
          else toast.warn("Failed to save");
        }
      });
    } else {
      this.setState({ isModalOpen: true });
    }
  }

  jump = (url, delay = 0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  handleOptionChange = (e) => {
    switch (e.target.value) {
      case "mainTenanceWatch":
        this.setState({
          selectedMainTenanceWatch: !this.state.selectedMainTenanceWatch,
          selectedNoAction: false,
        });
        break;
      case "rstFaultDetect":
        this.setState({
          selectedRstFaultDetect: !this.state.selectedRstFaultDetect,
          selectedNoAction: false,
        });
        break;
      case "noAction":
        this.setState({
          selectedRstFaultDetect: false,
          selectedMainTenanceWatch: false,
          selectedNoAction: !this.state.selectedNoAction,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const backUrl = "/v1/companies/" + companyId + "/feedback";
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <div
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        cursor: "pointer",
                      }}
                      className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <Col xs="8">
                      <h3 className="mb-0">Feedback Update</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                {
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup tag="fieldset">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="radio1"
                                  value="rstFaultDetect"
                                  checked={this.state.selectedRstFaultDetect}
                                  onChange={this.handleOptionChange}
                                />{" "}
                                Reset Fault Detection
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="radio2"
                                  value="mainTenanceWatch"
                                  checked={this.state.selectedMainTenanceWatch}
                                  onChange={this.handleOptionChange}
                                />{" "}
                                Maintenance Watch
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="radio3"
                                  value="noAction"
                                  checked={this.state.selectedNoAction}
                                  onChange={this.handleOptionChange}
                                />{" "}
                                No Action
                              </Label>
                            </FormGroup>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="exampleText">Comments...</Label>
                            <Input
                              type="textarea"
                              onChange={(e) => {
                                this.setState({
                                  portalComments: e.target.value,
                                });
                              }}
                              name="text"
                              id="exampleText"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={
                                this.state.selectedMainTenanceWatch ||
                                this.state.selectedNoAction ||
                                this.state.selectedRstFaultDetect
                                  ? false
                                  : true
                              }
                            >
                              Submit
                            </Button>
                          }
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                }
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.isModalOpen}
          size="sm"
          toggle={() => {
            this.setState({
              isModalOpen: !this.state.isModalOpen,
              isArchiveBtn: false,
            });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({
                isModalOpen: !this.state.isModalOpen,
                isArchiveBtn: false,
              });
            }}
          >
            {this.state.archiveMessage
              ? this.state.archiveMessage
              : "Have you taken all the actions regarding the feedback?"}
          </ModalHeader>
          <ModalBody>
            {/* {this.state.isArchiveBtn ? (
              <Button
                color="danger"
                onClick={() => {
                  this.setState({
                    isModal2Open: true,
                    isModalOpen: false,
                    isArchiveBtn: false,
                  });
                }}
              >
                Archive
              </Button>
            ) : null} */}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (this.state.selectedMainTenanceWatch) {
                  let data = {
                    maintenanceTimestamp: parseInt(+new Date() / 1000),
                    tagId: this.props.location.state.tagId,
                    companyId: this.props.location.state.companyId,
                    feedbackId: this.props.location.state.feedbackId,
                    equipmentId: this.props.location.state.equipmentId,
                    portalComments: this.state.portalComments,
                    feedbackId: this.props.location.state.feedbackId,
                    checkSensorList: false,
                  };
                  axios.post_feedBack_maintenanceWatch(data).then((val) => {
                    alert(val.data && val.data.message);
                    this.setState(
                      { selectedMainTenanceWatch: false, isModalOpen: false },
                      () => {
                        if (
                          !this.state.selectedMainTenanceWatch &&
                          !this.state.selectedRstFaultDetect
                        ) {
                          this.props.history.push(
                            "/v1/companies/" + companyId + "/feedback",
                            { archive: true }
                          );
                        }
                      }
                    );
                  });
                }
                if (this.state.selectedRstFaultDetect) {
                  let data = {
                    tagId:
                      this.props.location.state &&
                      this.props.location.state.tagId,
                    portalComments: this.state.portalComments,
                    timestamp: parseInt(+new Date() / 1000),
                    feedbackId:
                      this.props.location.state &&
                      this.props.location.state.feedbackId,
                  };
                  axios.post_feedBack_faultDetection(data).then((val) => {
                    alert(val.data && val.data.message);

                    this.setState({ selectedRstFaultDetect: false }, () => {
                      if (
                        !this.state.selectedMainTenanceWatch &&
                        !this.state.selectedRstFaultDetect
                      ) {
                        this.props.history.push(
                          "/v1/companies/" + companyId + "/feedback",
                          { archive: true }
                        );
                      }
                    });
                  });
                }
                if (this.state.selectedNoAction) {
                  let data = {
                    maintenanceTimestamp: parseInt(+new Date() / 1000),
                    companyId: this.props.location.state.companyId,
                    tagId: this.props.location.state.tagId,
                    feedbackId: this.props.location.state.feedbackId,
                    equipmentId: this.props.location.state.equipmentId,
                    portalComments: this.state.portalComments,
                    feedbackId: this.props.location.state.feedbackId,
                    checkSensorList: false,
                  };
                  axios.post_feedBack_noAction(data).then((val) => {
                    alert(val.data.message);
                    this.props.history.push(
                      "/v1/companies/" + companyId + "/feedback",
                      { archive: true }
                    );
                  });
                }
              }}
            >
              Yes
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                this.setState({ isModalOpen: false, isArchiveBtn: false });
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.isModal2Open}
          size="sm"
          toggle={() => {
            this.setState({ isModal2Open: !this.state.isModal2Open });
          }}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ isModal2Open: !this.state.isModal2Open });
            }}
          >
            {/* {this.state.selectedRstFaultDetect
              ? "Are you sure about resetting fault detection?"
              : null}
            {this.state.selectedMainTenanceWatch
              ? "Are you sure about doing Maintenance Watch?"
              : null}
            {this.state.selectedNoAction
              ? "Are you sure about doing No Action?"
              : null} */}
          </ModalHeader>
          <ModalBody>
            {this.state.selectedRstFaultDetect ? (
              <Col xs="6">
                <h3>Are you sure about resetting fault detection?</h3>
                <Button
                  color="danger"
                  onClick={() => {
                    let data = {
                      tagId:
                        this.props.location.state &&
                        this.props.location.state.tagId,
                      portalComments: this.state.portalComments,
                      timestamp: parseInt(+new Date() / 1000),
                      feedbackId:
                        this.props.location.state &&
                        this.props.location.state.feedbackId,
                    };
                    axios.post_feedBack_faultDetection(data).then((val) => {
                      alert(val.data && val.data.message);

                      this.setState({ selectedRstFaultDetect: false }, () => {
                        if (
                          !this.state.selectedMainTenanceWatch &&
                          !this.state.selectedRstFaultDetect
                        ) {
                          this.props.history.push(
                            "/v1/companies/" + companyId + "/feedback",
                            { archive: true }
                          );
                        }
                      });
                    });
                  }}
                >
                  Reset Fault Detection
                </Button>
              </Col>
            ) : null}
            {this.state.selectedMainTenanceWatch ? (
              <Col xs="6">
                <h3>Are you sure about doing Maintenance Watch?</h3>
                <Button
                  color="danger"
                  onClick={() => {
                    let data = {
                      maintenanceTimestamp: parseInt(+new Date() / 1000),
                      tagId: this.props.location.state.tagId,
                      companyId: this.props.location.state.companyId,
                      feedbackId: this.props.location.state.feedbackId,
                      equipmentId: this.props.location.state.equipmentId,
                      portalComments: this.state.portalComments,
                      feedbackId: this.props.location.state.feedbackId,
                      checkSensorList: false,
                    };
                    axios.post_feedBack_maintenanceWatch(data).then((val) => {
                      alert(val.data && val.data.message);
                      this.setState({ selectedMainTenanceWatch: false }, () => {
                        if (
                          !this.state.selectedMainTenanceWatch &&
                          !this.state.selectedRstFaultDetect
                        ) {
                          this.props.history.push(
                            "/v1/companies/" + companyId + "/feedback",
                            { archive: true }
                          );
                        }
                      });
                    });
                  }}
                >
                  Maintenance Watch
                </Button>
              </Col>
            ) : null}

            {this.state.selectedNoAction ? (
              <Col xs="12">
                <h3>Are you sure about doing No Action?</h3>
                <Button
                  color="danger"
                  onClick={() => {
                    let data = {
                      maintenanceTimestamp: parseInt(+new Date() / 1000),
                      companyId: this.props.location.state.companyId,
                      tagId: this.props.location.state.tagId,
                      feedbackId: this.props.location.state.feedbackId,
                      equipmentId: this.props.location.state.equipmentId,
                      portalComments: this.state.portalComments,
                      feedbackId: this.props.location.state.feedbackId,
                      checkSensorList: false,
                    };
                    axios.post_feedBack_noAction(data).then((val) => {
                      alert(val.data.message);
                      this.props.history.push(
                        "/v1/companies/" + companyId + "/feedback",
                        { archive: true }
                      );
                    });
                  }}
                >
                  No Action
                </Button>
              </Col>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => {
                this.setState({ isModal2Open: false, selectedOption: null });
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default FeedbackSection;
