import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { fetchMachineDetails } from "../../../../redux/actions/machineType.action";
import {
  fetchMachineEquipmentDetails,
  fetchEquipmentOptions,
} from "../../../../redux/actions/equipmentType.action";
import _ from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import ImageUploader from "react-images-upload";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import MaterialIcon, { colorPalette } from "material-icons-react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import classes from "./machine.css";
import axios from "../../../../axios";
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

//Datatable configurations for components

const { SearchBar } = Search;

// function strong(cell, row) {
//   if (1) {
//     return (
//       <span>
//         <strong style={ { fontWeight: 'bold' } }>{ cell }</strong>
//       </span>
//     );
//   }
// }

// const columns = [
//   {
//     text: 'Equipment Name',
//     dataField: 'customName',
//     sort: true
//   },
//   {
//     text: 'Equipment Id',
//     dataField: 'equipmentId',
//     sort: true
//   },
//   {
//     text: 'Equipment Type',
//     dataField: 'equipmentTypeData.customName',
//     sort: true
//   },
//   // {
//   //   text: 'Equipment Type',
//   //   dataField: 'equipmentTypeData.equipmentTypeMetaCode',
//   //   sort: true
//   // }
// ];

const options = {
  showTotal: true,
};

//Datatable configurations end

let companyId = "";
let machineId = "";

class MachineDetails extends React.Component {
  constructor(props) {
    super(props);
    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  state = {
    machine: {},
    machineBackup: {},
    machineImages: [],
    machineTypes: [],
    readOnly: true,
    optData: [],
    defaultOption: 0,
    defaultOptionBackup: 0,
    //Components state
    saving: false,
    isFilter: false,
    equipments: null,
    equipmentTypes: [],
    list: true,
    equipment: {},
    equipmentType: null,
    images: [],
    columns: [
      {
        text: "Equipment Name",
        dataField: "customName",
        sort: true,
      },
      {
        text: "Equipment Id",
        dataField: "equipmentId",
        sort: true,
      },
      {
        text: "Equipment Type",
        dataField: "equipmentType",
        sort: true,
      },
      // {
      //   text: "Sensor Id",
      //   dataField: "dummy_sensor",
      //   formatter: this.sensorDisplay,
      //   hidden: true,
      // },
      // {
      //   text: "TagId",
      //   dataField: "dummy_tag",
      //   sort: true,
      //   formatter: this.tagDisplay,
      //   hidden: true,
      // },
      {
        text: "Action",
        dataField: "tag.equipmentId",
        sort: false,
        formatter: this.action.bind(this),
      },
    ],
  };

  sensorDisplay(cell, row) {
    let sensorIds = [];
    let tags = row.tag.map((config) => {
      if (config.tagConfigMaps)
        sensorIds.push(
          config.tagConfigMaps.sensorConfig &&
            config.tagConfigMaps.sensorConfig.sensorId
        );
    });
    return sensorIds.join(", ");
  }

  tagDisplay(cell, row) {
    let tagIds = [];
    let tags = row.tag.map((config) => {
      return config.tagId;
    });
    return tags.join(", ");
  }

  action(cell, row) {
    return (
      <MaterialIcon
        onClick={() =>
          this.jump(
            "/v1/companies/" +
              companyId +
              "/machines/" +
              machineId +
              "/equipments/" +
              row.equipmentId,
            row,
            0
          )
        }
        icon="open_in_new"
        color="#0288d1"
      ></MaterialIcon>
    );
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    machineId = this.props.match.params.machineId;

    this.props.fetchMachineDetails(companyId, machineId);

    this.props.fetchMachineEquipmentDetails(companyId, machineId);

    // this.props.fetchEquipmentOptions(companyId);
    let image = await axios.fileDataImage_get(
      `${this.props.match.params.machineId}.jpg`,
      companyId
    );
    this.setState({
      images: image && image.data && image.data.data.file ? [image.data.data.file] : [],
    });
  }

  async getMachineTypeOptions() {
    let endpoint = "/machine-types";
    let machineTypes = await axios.getData(endpoint);
    let options = [];
    machineTypes.data.data.forEach((eq, i) => {
      options.push({
        key: i,
        label: `${eq.customName} -> (${eq.machineTypeCode})`,
        value: eq.machineTypeCode,
      });
    });

    let index = options.findIndex(
      (x) => x.value === this.state.machine.machineType
    );
    this.setState({ defaultOption: index, defaultOptionBackup: index });
    this.setState({ machineTypes: options });
  }

  async getEquipments(equipmentType = null) {
    let endpoint = "/equipments/" + companyId + "/" + machineId;
    if (equipmentType) {
      endpoint += "/" + equipmentType;
    }
    let equipments = await axios.machineManage_machineList(companyId);
    this.setState({ equipments: equipments.data.data });
  }

  async getEquipmentTypeOptions() {
    let endpoint = "/equipments/options/" + companyId;
    let equipmentTypes = await axios.equipmentType_get(companyId);
    let options = [];
    equipmentTypes.data.data.forEach((eq, i) => {
      options.push({
        key: i,
        label: `${eq.equipmentTypeData.customName} -> ${eq.equipmentTypeData.equipmentTypeCode}`,
        value: eq.equipmentTypeData.equipmentTypeCode,
      });
    });

    this.setState({ equipmentTypes: options });
  }

  handleChange = (input) => (e) => {
    let machine = { ...this.state.machine };
    machine[input] = e.target.value;
    this.setState({ machine: machine });
  };

  handleEdit(edit) {
    this.setState({ readOnly: edit });
  }

  handleCancel() {
    let machineBackup = { ...this.state.machineBackup };
    this.setState({
      machine: machineBackup,
      defaultOption: this.state.defaultOptionBackup,
      readOnly: true,
      machineImages: [],
    });
  }

  handleSelectChange = (type) => async (option) => {
    if (type === "machine") {
      let machine = { ...this.state.machine };
      machine.machineType = option.value;
      this.setState({ machine, defaultOption: option.key });
    } else if (type === "equipmentType") {
      let selectedEquipmentType = option ? option.value : null;
      await this.getEquipments(selectedEquipmentType);
    }
  };

  onDrop = (pictureFiles, pictureData) => {
    let machine = { ...this.state.machine };
    machine.imagesUrl = pictureData.length > 0 ? pictureData : null;
    this.setState({ machine: machine });
  };

  onDropAdd = (pictureFiles, pictureData) => {
    let machineImages = { ...this.state.machineImages };
    machineImages = pictureData;
    this.setState({ machineImages });
  };

  async handleSave() {
    this.setState({ saving: true });
    let machine = {
      ...this.state.machine,
    };

    //Validating
    let err = await validation.validate(machine);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    delete machine._id;
    machine = {
      customName: machine.customName,
      plantId: machine.plantId,
      externalId: machine.externalId,
    };

    let api = await axios.machineManage_update(
      parseInt(this.state.machine.machineId),
      machine
    );
    if (api.status == 200) {
      toast.success("Machine saved successfully");
      if (this.state.machineImages.length) {
        let machineImage = {
          file: this.state.machineImages[0],
          fileName: `${this.state.machine.machineId}.jpg`,
          companyId: companyId,
        };
        let api2 = await axios.fileDataImage_create(machineImage);
      }

      this.setState({
        readOnly: true,
        machine: api.data.data,
        machineImages: [],
      });

      localStorage.setItem("machineName", api.data.data.customName);
      this.jump("/v1/companies/" + companyId + "/machines");
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  jump = (url, row = null, delay = 1000) => {
    setTimeout(() => {
      this.props.history.push(url, row);
    }, delay);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let machineDetailsData = null,
      optionsTypeData = [],
      index = 0,
      equipments = null,
      optionsEquipTypeData = [];
    if (!prevState.readOnly || prevState.isFilter) {
      return;
    }
    if (nextProps.machineDetailsData !== prevState.machineDetailsData) {
      if (nextProps.machineDetailsData) {
        localStorage.setItem(
          "machineName",
          nextProps.machineDetailsData.data.customName
        );
        machineDetailsData = nextProps.machineDetailsData.data;
      }
    }

    if (nextProps.machineTypeData !== prevState.machineTypeData) {
      if (nextProps.machineTypeData) {
        nextProps.machineTypeData.data.forEach((eq, i) => {
          optionsTypeData.push({
            key: i,
            label: `${eq.customName} -> (${eq.machineTypeCode})`,
            value: eq.machineTypeCode,
          });
        });
        index = optionsTypeData.findIndex((x) =>
          x.value === _.isEmpty(prevState.machine)
            ? 0
            : prevState.machine.machineType
        );
      }
    }

    if (nextProps.equipOptionsData !== prevState.equipOptionsData) {
      if (nextProps.equipOptionsData) {
        nextProps.equipOptionsData.data.forEach((eq, i) => {
          optionsEquipTypeData.push({
            key: i,
            label:
              eq.equipmentTypeData &&
              `${eq.equipmentTypeData.customName} -> ${eq.equipmentTypeData.equipmentTypeCode}`,
            value:
              eq.equipmentTypeData && eq.equipmentTypeData.equipmentTypeCode,
          });
        });
      }
    }

    if (
      nextProps.machineEquipDetailsData !== prevState.machineEquipDetailsData
    ) {
      if (nextProps.machineEquipDetailsData) {
        equipments =
          nextProps.machineEquipDetailsData.status !== "failed"
            ? nextProps.machineEquipDetailsData.data
            : null;
      }
    }

    return {
      machine: machineDetailsData !== null ? machineDetailsData : {},
      machineBackup: machineDetailsData !== null ? machineDetailsData : {},
      defaultOption: index !== 0 ? index : 0,
      defaultOptionBackup: index !== 0 ? index : 0,
      machineTypes: optionsTypeData.length !== 0 ? optionsTypeData : [],
      equipments: equipments !== null ? equipments : null,
      equipmentTypes:
        optionsEquipTypeData.length !== 0 ? optionsEquipTypeData : [],
    };
  }

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12" className="table-caption">
              <h1>Machine Details</h1>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump(
                        "/v1/companies/" + companyId + "/machines",
                        null,
                        0
                      );
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8">
                      {/* <h3 className="mb-0">Machine Details</h3> */}
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnly && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.handleEdit(false)}
                          size="md"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Machine Name <span className="text-danger">*</span>
                        </label>
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            type="text"
                            value={this.state.machine.customName}
                            onChange={this.handleChange("customName")}
                            disabled={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Machine Id
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            type="text"
                            value={this.state.machine.machineId}
                            onChange={this.handleChange("machineId")}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            External ID{" "}
                            <Tooltip
                              title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                              placement="right-start"
                            >
                              <Info
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                  fontSize: "30px",
                                }}
                              />
                            </Tooltip>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            type="text"
                            value={this.state.machine.externalId}
                            onChange={this.handleChange("externalId")}
                            disabled={this.state.readOnly}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {/* <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Machine Type <span className="text-danger">*</span>
                          </label>
                          <Select
                            isClearable={false}
                            isDisabled={this.state.readOnly}
                            onChange={this.handleSelectChange("machine")}
                            placeholder=""
                            value={
                              // this.state.machineTypes[this.state.defaultOption]
                              this.state.selectedMachine
                            }
                            options={this.state.machineTypes}
                          />
                        </FormGroup>
                      </Col> */}
                      <Col lg="6"></Col>

                      <Col lg="12">
                        <FormGroup>
                          {!this.state.readOnly && (
                            <div className="image-container-add">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Add Machine Images
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                labelClass="label"
                                onChange={this.onDropAdd}
                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                maxFileSize={2000000} //2MB
                                withPreview={true}
                                label="Max file size: 2MB, accepted: jpg|png"
                              />
                            </div>
                          )}

                          <div
                            className={
                              this.state.readOnly ? "image-container " : ""
                            }
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Machine Images
                            </label>
                            <ImageUploader
                              withIcon={true}
                              buttonText="Choose images"
                              labelClass="label"
                              onChange={this.onDrop}
                              imgExtension={[".jpg", ".jpeg", ".png"]}
                              maxFileSize={2000000} //2MB
                              withPreview={true}
                              label="Max file size: 2MB, accepted: jpg|png"
                              defaultImages={this.state.images}
                            />
                          </div>
                        </FormGroup>
                        {!this.state.readOnly && (
                          <Button
                            className="right"
                            color="success"
                            onClick={() => this.handleSave()}
                            size="md"
                            disabled={this.state.saving}
                          >
                            {this.state.saving ? (
                              <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        )}

                        {!this.state.readOnly && !this.state.saving && (
                          <Button
                            className="right cmr-10"
                            color="danger"
                            onClick={() => this.handleCancel()}
                            size="md"
                          >
                            Cancel
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Equipments */}
          {/* <br/>
          <br/>
          <br/>
          <br/> */}

          <Row>
            <Col lg="12 table-caption">
              <h1 style={{ color: "#000" }}>Equipments</h1>
            </Col>
          </Row>
          <Row>
            <Col lg="8">
              {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.jump(
                      `/v1/companies/${companyId}/machines/${machineId}/equipments/add`,
                      null,
                      0
                    );
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Equipment
                </Button>
              )}
            </Col>
            <Col lg="4">
              {/* {this.state.list && (
                <Select
                  className="add-btn"
                  isClearable={true}
                  onChange={this.handleSelectChange("equipmentType")}
                  placeholder="Equipment Types"
                  options={this.state.equipmentTypes}
                />
              )} */}
            </Col>
          </Row>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.equipments && this.state.list && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={this.state.columns}
                    data={this.state.equipments}
                    search={{ searchFormatted: true }}
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          keyField="_id"
                          {...props.baseProps}
                          // rowEvents={ rowEvents }
                          data={this.state.equipments}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  machineDetailsData: _.isEmpty(state.machineType.machineDetailsData)
    ? null
    : state.machineType.machineDetailsData,
  machineTypeData: _.isEmpty(state.machineType.machineTypeData)
    ? null
    : state.machineType.machineTypeData,
  equipOptionsData: _.isEmpty(state.equipmentType.equipOptionsData)
    ? null
    : state.equipmentType.equipOptionsData,
  machineEquipDetailsData: _.isEmpty(
    state.equipmentType.machineEquipDetailsData
  )
    ? null
    : state.equipmentType.machineEquipDetailsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMachineDetails: (companyId, machineId) =>
      dispatch(fetchMachineDetails(companyId, machineId)),
    fetchMachineEquipmentDetails: (companyId, machineId) =>
      dispatch(fetchMachineEquipmentDetails(companyId, machineId)),
    fetchEquipmentOptions: (companyId) =>
      dispatch(fetchEquipmentOptions(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineDetails);
