import CallAPI from "../../axios";

export const FETCH_COMPONENT_TYPES_DATA = "FETCH_COMPONENT_TYPES_DATA";
export const FETCH_COMPONENT_TYPES_DETAILS_DATA =
  "FETCH_COMPONENT_TYPES_DETAILS_DATA";
export const FETCH_EQUIPMENT_COMPONENT = "FETCH_EQUIPMENT_COMPONENT";
export const FETCH_COMPONENT_OPTIONS = "FETCH_COMPONENT_OPTIONS";
export const FETCH_COMPONENT_RPM_RATIO = "FETCH_COMPONENT_RPM_RATIO";
export const FETCH_COMPONENT_QUESTIONARE = "FETCH_COMPONENT_QUESTIONARE";
export const FETCH_COMPONENT_UTIL = "FETCH_COMPONENT_UTIL";
export const FETCH_COMPONENT_DETAILS = "FETCH_COMPONENT_DETAILS";

const fetchData = (data) => {
  return {
    type: FETCH_COMPONENT_TYPES_DATA,
    payload: data,
  };
};

const fetchComponentTypesDetailsData = (data) => {
  return {
    type: FETCH_COMPONENT_TYPES_DETAILS_DATA,
    payload: data,
  };
};

const fetchEquipComponentData = (data) => {
  return {
    type: FETCH_EQUIPMENT_COMPONENT,
    payload: data,
  };
};

const fetchComponentOptionsData = (data) => {
  return {
    type: FETCH_COMPONENT_OPTIONS,
    payload: data,
  };
};

const fetchComponentRpmRatioData = (data) => {
  return {
    type: FETCH_COMPONENT_RPM_RATIO,
    payload: data,
  };
};

const fetchComponentquestionareData = (data) => {
  return {
    type: FETCH_COMPONENT_QUESTIONARE,
    payload: data,
  };
};

const fetchComponentUtilData = (data) => {
  return {
    type: FETCH_COMPONENT_UTIL,
    payload: data,
  };
};

const fetchEquipComponentDetailsData = (data) => {
  return {
    type: FETCH_COMPONENT_DETAILS,
    payload: data,
  };
};

export const fetchComponentType = () => {
  return (dispatch) => {
    CallAPI.componentType_get()
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchComponentTypeDetails = (companyId) => {
  return (dispatch) => {
    CallAPI.getData(`/components/types/${companyId}`)
      .then((response) => {
        dispatch(fetchComponentTypesDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipComponent = (companyId, equipmentId) => {
  return (dispatch) => {
    CallAPI.getData(`/components/${companyId}/${equipmentId}`)
      .then((response) => {
        dispatch(fetchEquipComponentData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipComponentDetails = (componentId) => {
  return (dispatch) => {
    CallAPI.get_component(componentId)
      .then((response) => {
        dispatch(fetchEquipComponentDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchComponentOptions = (companyId, equipmentId) => {
  return (dispatch) => {
    CallAPI.getData(`/components/componentTypes/${equipmentId}`)
      .then((response) => {
        dispatch(fetchComponentOptionsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchComponentRpmRatio = () => {
  return (dispatch) => {
    CallAPI.getData(`/components/getRPMRatio`)
      .then((response) => {
        dispatch(fetchComponentRpmRatioData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchComponentquestionare = (tagId) => {
  return (dispatch) => {
    CallAPI.getData(`/components/questionare/${tagId}`)
      .then((response) => {
        dispatch(fetchComponentquestionareData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchComponentUtil = (senesorId) => {
  return (dispatch) => {
    CallAPI.getData(`/utilities/imageUpload/${senesorId}/component`)
      .then((response) => {
        dispatch(fetchComponentUtilData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
