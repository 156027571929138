import React from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";
import ifvisible from "ifvisible.js";
import $ from "jquery";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components

import UserHeader from "components/Headers/Header.jsx";
import Helper from "../../../helper";
import axios from "../../../axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let portalUser = {};

class Profile extends React.Component {
  state = {
    user: {
      address: {
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
      department: {},
    },
    readOnly: true,
    saving: false,
  };

  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }

    //Binding methods
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    await this.parseUser();
  }

  parseUser() {
    let user = JSON.parse(localStorage.getItem("portal-user"));
    if (!user.address) {
      user.address = {
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      };
    }
    let userBackup = JSON.parse(localStorage.getItem("portal-user"));
    if (!userBackup.address) {
      userBackup.address = {
        area: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      };
    }
    this.setState({ user, userBackup });
  }

  handleChange = (state, key) => (e) => {
    let subject = { ...this.state[state] };
    if (key === "dob") {
      try {
        let moment = e.format("DD/MMMM/YYYY");
        subject[key] = moment;
      } catch (err) {
        subject[key] = "";
      }
    } else {
      Helper.reduce(subject, key, e.target.value);
    }
    this.setState({ [state]: subject });
  };

  handleCancel = () => {
    this.setState({
      user: this.state.userBackup,
      readOnly: true,
    });
  };

  handleSave = async () => {
    this.setState({ saving: true });
    let user = { ...this.state.user };
    let api = await axios.postData("/employees", user, user._id);
    if (api.status === 200) {
      toast.success("Profile updated successfully");
      this.setState({ readOnly: true });
      localStorage.setItem("portal-user", JSON.stringify(user));
    } else if (api.status === 400) {
      toast.error(api.data.message);
    } else {
      toast.error("Something went wrong. Please try again..");
    }
    this.setState({ saving: false });
  };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    var today = moment();
    var valid = function (current) {
      return current.isBefore(today);
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {/* <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card style={{height: '200px'}} className="card-profile shadow">
                    <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                        <div className="card-profile-image">
                        <a href="#" onClick={e => e.preventDefault()}>
                            <img
                            alt="..."
                            className="rounded-circle"
                            src={require("assets/img/theme/avatar.png")}
                            />
                        </a>
                        </div>
                    </Col>
                    </Row>
                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    </CardHeader>
                    <CardBody className="pt-0 pt-md-4">
                    
                    </CardBody>
                </Card>
                </Col> */}
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Profile</h3>
                    </Col>
                    {/* Edit feature removed */}
                    {/* <Col className="text-right" xs="4">
                            {
                                this.state.readOnly ?
                                <Button
                                    color="primary"
                                    onClick={() => {this.setState({readOnly: false})}}
                                    size="sm"
                                >
                                    Edit
                                </Button> :
                                <>
                                    <Button
                                        className="right"
                                        color="success"
                                        onClick={this.handleSave}
                                        size="md"
                                        disabled={this.state.saving}
                                    >
                                        { 
                                            this.state.saving ?
                                            <i class="fas fa-spinner fa-pulse"></i> :
                                            'Save'
                                        }
                                    </Button>

                                {
                                    !this.state.saving &&
                                    <Button
                                        className="right cmr-10"
                                        color="danger"
                                        onClick={this.handleCancel}
                                        size="md"
                                    >
                                        Cancel
                                    </Button>
                                }
                                </>
                            }
                        </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              disabled={this.state.readOnly}
                              value={this.state.user.name}
                              onChange={this.handleChange("user", "name")}
                              id="input-username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              disabled={this.state.readOnly}
                              value={this.state.user.email}
                              disabled={true}
                              id="input-email"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Phone Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              disabled={this.state.readOnly}
                              value={this.state.user.phone}
                              onChange={this.handleChange("user", "phone")}
                              id="input-phone"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              DOB
                            </label>
                            {this.state.readOnly ? (
                              <Input
                                className="form-control-alternative"
                                disabled={true}
                                value={this.state.user.dob}
                              />
                            ) : (
                              <InputGroup
                                className="input-group-alternative"
                                onKeyUp={() => {}}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-calendar-grid-58" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                  value={this.state.user.dob}
                                  isValidDate={valid}
                                  onChange={this.handleChange("user", "dob")}
                                  inputProps={{
                                    placeholder: "",
                                  }}
                                  dateFormat={"DD/MMM/YYYY"}
                                  timeFormat={false}
                                />
                              </InputGroup>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Department
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.user.department.name}
                              disabled={true}
                              id="input-first-name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Designation
                            </label>
                            <Input
                              className="form-control-alternative"
                              disabled={this.state.readOnly}
                              value={this.state.user.designation}
                              onChange={this.handleChange(
                                "user",
                                "designation"
                              )}
                              id="input-first-name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-4">
                      Contact information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Address (Area and Street)
                            </label>
                            <Input
                              className="form-control-alternative"
                              disabled={this.state.readOnly}
                              value={this.state.user.address.area}
                              onChange={this.handleChange(
                                "user",
                                "address.area"
                              )}
                              id="input-address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              City/District/Town
                            </label>
                            <Input
                              className="form-control-alternative"
                              disabled={this.state.readOnly}
                              value={this.state.user.address.city}
                              onChange={this.handleChange(
                                "user",
                                "address.city"
                              )}
                              id="input-city"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              State
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-state"
                              type="text"
                              disabled={this.state.readOnly}
                              value={this.state.user.address.state}
                              onChange={this.handleChange(
                                "user",
                                "address.state"
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Country
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-country"
                              type="text"
                              disabled={this.state.readOnly}
                              value={this.state.user.address.country}
                              onChange={this.handleChange(
                                "user",
                                "address.country"
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Pincode
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-postal-code"
                              type="number"
                              value={this.state.user.address.pincode}
                              onChange={this.handleChange(
                                "user",
                                "address.pincode"
                              )}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
