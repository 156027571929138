import CallAPI from "../../axios";

export const FETCH_NOTIFCATION_MUTE_DATA = "FETCH_NOTIFCATION_MUTE_DATA",
  FETCH_NOTIFCATION_DATA = "FETCH_NOTIFCATION_DATA",
  FETCH_NOTIFCATION_CODES_DATA = "FETCH_NOTIFCATION_CODES_DATA",
  FETCH_NOTIFCATION_GROUP_DATA = "FETCH_NOTIFCATION_GROUP_DATA";

const fetchData = (data) => {
  return {
    type: FETCH_NOTIFCATION_MUTE_DATA,
    payload: data,
  };
};

const fetchNotifData = (data) => {
  return {
    type: FETCH_NOTIFCATION_DATA,
    payload: data,
  };
};

const fetchNotifCodesData = (data) => {
  return {
    type: FETCH_NOTIFCATION_CODES_DATA,
    payload: data,
  };
};

const fetchNotifGroupData = (data) => {
  return {
    type: FETCH_NOTIFCATION_GROUP_DATA,
    payload: data,
  };
};

export const fetchNotificationMute = (tagId) => {
  return (dispatch) => {
    CallAPI.getData(`/notificationMute/${tagId}`)
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchNotification = (companyId, status, startDate, endDate) => {
  return (dispatch) => {
    CallAPI.getNoticationAlertData(companyId, status, startDate, endDate)
      .then((response) => {
        dispatch(fetchNotifData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchNotificationCodes = (roleId) => {
  return (dispatch) => {
    return CallAPI.get_notificationCodes(roleId)
      .then((response) => {
        dispatch(fetchNotifCodesData(response.data));
        return response && response.data && response.data.data || []
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchNotificationGroup = (companyId) => {
  return (dispatch) => {
    CallAPI.getData(`/notificationGroup/groupList/${companyId}`)
      .then((response) => {
        dispatch(fetchNotifGroupData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
