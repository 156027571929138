import React from "react";

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const tiles = (props) => {
  let cards = props.cards.map((card, key) => {
    return (
      <Col
        key={key}
        lg="6"
        xl="3"
        onClick={() =>
          props.jumpPage("/v1/companies/" + props.companyId + "/" + card.path)
        }
      >
        <Card style={{ cursor: "pointer" }} className="card-stats mb-4 mb-xl-3">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  {card.title}
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{card.count}</span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                  <i className={card.icon} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  });

  return (
    <>
      <div className="header bg-gradient-info mt--7 pb-8 pt-5 pt-md-0">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>{cards}</Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default tiles;
