import { useCallback, useState } from "react";

export const useSetState = (initialState = {}) => {
    const [state, set] = useState(initialState);

    const setState = useCallback(patch => {
        set(prevState =>
            Object.assign({}, prevState, typeof patch === "function" ? patch(prevState) : patch)
        );
    }, []);

    return [state, setState];
};
