import React from "react";
import Select from "react-select";
import { find, isObject, uniqBy, uniq } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CancelIcon from "@material-ui/icons/Cancel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import axios from "../../../../axios";
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GraphShimmer from "../../../../components/Shimmer/GraphShimmer";

let companyId = "";
let layout = 0;
class NotificationGroup extends React.Component {
  constructor(props) {
    super(props);
    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  state = {
    loading: false,
    saving: false,
    deleting: false,
    plantList: [],
    machineList: [],
    equipmentList: [],
    selectedGroupType: null,
    disabled: false,
    defaultDisabled: false,
    groups: [],
    defaultgroup: {
      plantId: null,
      groupType: "",
      plantList: [],
      machineList: [],
      equipmentList: [],
      emailIds: "",
      phoneNos: "",
      daily: {
        urgent: true,
        summary: true,
      },
      direct: {
        all: true,
      },
      default: true,
      submitted: false,
    },
    groupTypes: [
      {
        key: 0,
        label: "Company",
        value: "notificationGroupsCompany",
      },
      {
        key: 1,
        label: "Plant",
        value: "notificationGroupsPlant",
      },
      {
        key: 2,
        label: "Machine",
        value: "notificationGroupsMachine",
      },
      {
        key: 3,
        label: "Equipment",
        value: "notificationGroupsEquipment",
      },
    ],
  };

  async componentDidMount() {
    companyId = localStorage.getItem("companyId");
    layout = parseInt(localStorage.getItem("layout"));
    if (layout === 0) {
      let filteredGroupTypes = this.state.groupTypes.filter((groupType) => {
        return groupType.value !== "notificationGroupsMachine";
      });
      this.setState({ groupTypes: filteredGroupTypes });
    }
    // await this.getPlantList();
    await this.getDefaultGroup();
    await this.getGroups();
  }

  async getDefaultGroup() {
    let endpoint = "/notificationGroup/groups/" + companyId + "/default";
    let result = await axios.getData(endpoint);
    if (
      result &&
      result.data &&
      result.data.groups &&
      result.data.groups.length > 0
    ) {
      this.setState({
        defaultgroup: result.data.groups[0],
        defaultDisabled: true,
        selectedGroupType: result.data.groupType,
      });
    } else {
      let defaultgroup = this.state.defaultgroup;
      defaultgroup.emailIds = "";
      defaultgroup.phoneNos = "";
      defaultgroup.groupType = "";
      defaultgroup.submitted = "";
      defaultgroup.daily.urgent = true;
      defaultgroup.daily.summary = true;
      defaultgroup.direct.all = true;
      this.setState({
        defaultgroup: defaultgroup,
        defaultDisabled: false,
        selectedGroupType: null,
      });
    }
  }

  async getGroups() {
    let endpoint = "/notificationGroup/groups/" + companyId;
    let result = await axios.getData(endpoint);
    if (result && result.data && result.data.groups) {
      this.setState({
        groups: result.data.groups,
        disabled: true,
        selectedGroupType: result.data.groupType,
      });
      if (result.data.groups.length === 0) {
        this.addNewGroup(true);
      }
      await this.getPlantList();
    } else {
      this.addNewGroup(true);
    }
  }

  async getPlantList() {
    // let endpoint = "/notificationGroup/list/plant/null/" + companyId + "/null";
    // let result = await axios.getData(endpoint);
    // let plantList = [];
    // result &&
    //   result.data.map((plant, index) => {
    //     plantList.push({
    //       key: plant.plantId,
    //       label: plant.groupName,
    //       value: plant.plantId,
    //     });
    //   });
    // this.setState({ plantList: plantList });
  }

  async handleSubmit(index) {
    this.setState({ loading: true });
    let group = this.state.groups[index];
    group.emailIds = group.emailIds.trim().split(",");
    group.phoneNos = group.phoneNos.trim().split(",");
    group.companyId = companyId;
    let endpoint = "/notificationGroup/create";
    let body = {
      group: group,
    };
    let response = await axios.createNotificationGroup(endpoint, body);
    if (response.status === 200) {
      toast.success("Group created successfully");
      await this.getGroups();
    } else {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ loading: false });
  }

  deleteGroup = async (index) => {
    this.setState({ loading: true });
    let group = this.state.groups[index];
    group.emailIds = group.emailIds.trim().split(",");
    group.phoneNos = group.phoneNos.trim().split(",");
    group.companyId = companyId;
    group.deleteGroupTypye = this.state.groups.length === 1 ? true : false;
    let endpoint = "/notificationGroup/delete";
    let body = {
      group: group,
    };
    let response = await axios.deleteNotificationGroup(endpoint, body);
    if (response.status === 200) {
      toast.success("Group deleted successfully");
      await this.getGroups();
    } else {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ groups: this.state.groups, loading: false });
  };

  async handleDefaultSubmit() {
    this.setState({ loading: true });
    let group = this.state.defaultgroup;
    group.emailIds = group.emailIds.trim().split(",");
    group.phoneNos = group.phoneNos.trim().split(",");
    group.companyId = companyId;
    let endpoint = "/notificationGroup/create";
    let body = {
      group: group,
    };
    let response = await axios.createNotificationGroup(endpoint, body);
    if (response.status === 200) {
      toast.success("Group created successfully");
      await this.getDefaultGroup();
      await this.getGroups();
    } else {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ loading: false });
  }

  deleteDefaultGroup = async () => {
    this.setState({ loading: true });
    let group = this.state.defaultgroup;
    group.emailIds = group.emailIds.trim().split(",");
    group.phoneNos = group.phoneNos.trim().split(",");
    group.companyId = companyId;
    group.deleteGroupTypye =
      this.state.groups.length === 0 ||
      (this.state.groups.length === 1 && !this.state.groups[0].submitted)
        ? true
        : false;
    let endpoint = "/notificationGroup/delete";
    let body = {
      group: group,
    };
    let response = await axios.deleteNotificationGroup(endpoint, body);
    if (response.status === 200) {
      toast.success("Group deleted successfully");
      await this.getDefaultGroup();
      await this.getGroups();
    } else {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ loading: false });
  };

  jump = (url, delay = 0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  handleGroupTypeChange(e) {
    let groups = this.state.groups.map((group, key) => {
      group.groupType = e.value;
      return group;
    });
    let defaultgroup = this.state.defaultgroup;
    defaultgroup.groupType = e.value;
    this.setState({
      groups: groups,
      defaultgroup: defaultgroup,
      selectedGroupType: e.value,
    });
  }

  addNewGroup = async (empty = false) => {
    let groups = empty ? [] : this.state.groups;
    let groupCount =
      groups.length > 0 && groups[groups.length - 1]
        ? parseInt(groups[groups.length - 1].plantId.split("-")[1]) + 1
        : 1;
    groups.push({
      plantId: companyId + "GROUP-" + groupCount,
      groupType: this.state.disabled ? this.state.selectedGroupType : null,
      plantList: [],
      machineList: [],
      equipmentList: [],
      emailIds: "",
      phoneNos: "",
      daily: {
        urgent: true,
        summary: true,
      },
      direct: {
        all: true,
      },
      default: false,
      submitted: false,
    });
    this.setState({
      groups: groups,
      disabled: empty ? false : this.state.disabled,
    });
    // await this.getPlantList();
  };

  clearGroup = (index) => {
    if (index > -1) {
      this.state.groups.splice(index, 1);
    }
    this.setState({ groups: this.state.groups });
  };

  handleEmailChange = (event, index) => {
    let groups = this.state.groups;
    groups[index].emailIds = event.target.value;
    this.setState({ groups: groups });
  };

  handlePhoneChange = (event, index) => {
    let groups = this.state.groups;
    groups[index].phoneNos = event.target.value.replace(/[^0-9\-\+\,]+/g, "");
    this.setState({ groups: groups });
  };

  handleDefaultEmailChange = (event) => {
    let group = this.state.defaultgroup;
    group.emailIds = event.target.value;
    this.setState({ defaultgroup: group });
  };

  handleDefaultPhoneChange = (event) => {
    let group = this.state.defaultgroup;
    group.phoneNos = event.target.value.replace(/[^0-9\-\+\,]+/g, "");
    this.setState({ defaultgroup: group });
  };

  handlePlantChange = async (plantList, index) => {
    if (
      layout === 0 &&
      this.state.selectedGroupType === "notificationGroupsEquipment"
    ) {
      let equipmentList = [];
      plantList &&
        plantList.map(async (plant) => {
          let endpoint =
            "/notificationGroup/list/equipment/null/" +
            companyId +
            "/" +
            plant.value +
            "/";
          let result = await axios.getData(endpoint);
          result &&
            result.data &&
            result.data.map((equipment, index) => {
              equipmentList.push({
                key: equipment.equipmentId,
                label: equipment.customName,
                value: equipment.equipmentId,
              });
            });
        });
      setTimeout(() => {
        let groups = this.state.groups;
        let filteredEquipmentList = [];
        groups[index].equipmentList.map((equipment) => {
          equipmentList.map((value) => {
            if (equipment.value === value.value) {
              filteredEquipmentList.push(equipment);
            }
          });
        });
        groups[index].equipmentList = filteredEquipmentList;
        this.setState({ equipmentList: equipmentList, groups: groups });
      }, 1000);
    }
    if (
      layout === 1 &&
      (this.state.selectedGroupType === "notificationGroupsMachine" ||
        this.state.selectedGroupType === "notificationGroupsEquipment")
    ) {
      let machineList = [];
      plantList &&
        plantList.map(async (plant) => {
          let endpoint =
            "/notificationGroup/list/machine/null/" +
            companyId +
            "/" +
            plant.value +
            "/";
          let result = await axios.getData(endpoint);
          result &&
            result.data &&
            result.data.map((machine, index) => {
              machineList.push({
                key: machine.machineId,
                label: machine.machineName,
                value: machine.machineId,
              });
            });
        });
      setTimeout(() => {
        let groups = this.state.groups;
        let filteredMachineList = [];
        groups[index].machineList.map((machine) => {
          machineList &&
            machineList.map((value) => {
              if (machine.value === value.value) {
                filteredMachineList.push(machine);
              }
            });
        });
        groups[index].machineList = filteredMachineList;
        this.setState({ machineList: machineList, groups: groups });
        if (
          this.state.selectedGroupType === "notificationGroupsEquipment" &&
          groups[index].equipmentList.length > 0
        ) {
          let equipmentList = [];
          machineList &&
            machineList.map(async (machine) => {
              let machineId = machine && machine.value ? machine.value : null;
              let endpoint =
                "/notificationGroup/list/equipment/machine/" +
                companyId +
                "/" +
                machineId +
                "/";
              let result = await axios.getData(endpoint);

              result &&
                result.data &&
                result.data.map((equipment, index) => {
                  equipmentList.push({
                    key: equipment.equipmentId,
                    label: equipment.customName,
                    value: equipment.equipmentId,
                  });
                });
            });
          setTimeout(() => {
            let groups = this.state.groups;
            let filteredEquipmentList = [];
            groups[index].equipmentList.map((equipment) => {
              equipmentList.map((value) => {
                if (equipment.value === value.value) {
                  filteredEquipmentList.push(equipment);
                }
              });
            });
            groups[index].equipmentList = filteredEquipmentList;
            this.setState({ groups: groups });
          }, 2000);
        }
      }, 1000);
    }
    let groups = this.state.groups;
    groups[index].plantList = plantList !== null ? plantList : [];
    this.setState({ groups: groups });
  };

  handleMachineChange = async (machineList, index) => {
    if (this.state.selectedGroupType === "notificationGroupsEquipment") {
      let equipmentList = [];
      machineList &&
        machineList.map(async (machine) => {
          let endpoint =
            "/notificationGroup/list/equipment/machine/" +
            companyId +
            "/" +
            machine.value +
            "/";
          let result = await axios.getData(endpoint);

          result &&
            result.data &&
            result.data.map((equipment, index) => {
              equipmentList.push({
                key: equipment.equipmentId,
                label: equipment.customName,
                value: equipment.equipmentId,
              });
            });
        });
      setTimeout(() => {
        let groups = this.state.groups;
        let filteredEquipmentList = [];
        groups &&
          groups[index].equipmentList.map((equipment) => {
            equipmentList.map((value) => {
              if (equipment.value === value.value) {
                filteredEquipmentList.push(equipment);
              }
            });
          });
        groups[index].equipmentList = filteredEquipmentList;
        this.setState({ equipmentList: equipmentList, groups: groups });
      }, 1000);
    }
    let groups = this.state.groups;
    groups[index].machineList = machineList !== null ? machineList : [];
    this.setState({ groups: groups });
  };

  handleEquipmentChange = async (equipmentList, index) => {
    let groups = this.state.groups;
    groups[index].equipmentList = equipmentList !== null ? equipmentList : [];
    this.setState({ groups: groups });
  };

  render() {
    let backUrl = "/v1/companies/" + companyId + "/notification/";

    return (
      <>
        <ToastContainer position="top-left" />
        {this.state.loading && (
          <GraphShimmer />
        )}
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6" className="table-caption"></Col>
            <Col lg="6 table-caption">
              <h1>Notification Group</h1>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump(backUrl, 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div className="pl-lg-4">
                    <Row style={{ marginBottom: "25px" }}>
                      <Col lg="12" xl="12" style={{ marginBottom: "25px" }}>
                        <Card className="card-stats mb-4 mb-xl-0">
                          <CardBody>
                            <Row
                              style={{
                                marginBottom: "5px",
                              }}
                            >
                              <Col className="order-xl-1" xl="12">
                                <center>
                                  {"Default Group "}
                                  <Tooltip title="Creation of notification group is allowed after the creation of default group. When there is no specific group, notification will be sent to default group">
                                    <InfoIcon />
                                  </Tooltip>
                                </center>
                              </Col>
                            </Row>
                            <Row
                              style={{
                                marginBottom: "25px",
                              }}
                            >
                              <Col className="order-xl-1" xl="12">
                                <Row>
                                  <InputLabel>Group Type </InputLabel>
                                  <Tooltip title="It will allow to create a group under anyone of the type">
                                    <InfoIcon
                                      style={{
                                        paddingBottom: "5px",
                                      }}
                                    />
                                  </Tooltip>
                                </Row>
                                <Select
                                  defaultValue={this.state.groupTypes.filter(
                                    (groupType) =>
                                      groupType.value ===
                                      this.state.selectedGroupType
                                  )}
                                  isDisabled={this.state.defaultDisabled}
                                  options={this.state.groupTypes}
                                  value={this.state.groupTypes.filter(
                                    (groupType) =>
                                      groupType.value ===
                                      this.state.selectedGroupType
                                  )}
                                  onChange={(e) =>
                                    this.handleGroupTypeChange(e)
                                  }
                                />
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: "25px" }}>
                              <Col className="order-xl-1" xl="6">
                                <Row>
                                  <InputLabel>Email Id </InputLabel>
                                  <Tooltip title="Enter a comma separated emails, Only these people will receive all the mails related to this group">
                                    <InfoIcon
                                      style={{
                                        paddingBottom: "5px",
                                      }}
                                    />
                                  </Tooltip>
                                </Row>
                                <Input
                                  value={this.state.defaultgroup.emailIds}
                                  placeholder="Enter Email Id comma separated"
                                  onChange={(e) => {
                                    this.handleDefaultEmailChange(e);
                                  }}
                                  disabled={this.state.defaultgroup.submitted}
                                />
                              </Col>
                              <Col className="order-xl-1" xl="6">
                                <Row>
                                  <InputLabel>Phone Number </InputLabel>
                                  <Tooltip title="Enter a comma separated phone No., Only these people will receive all the SMS related to this group">
                                    <InfoIcon
                                      style={{
                                        paddingBottom: "5px",
                                      }}
                                    />
                                  </Tooltip>
                                </Row>
                                <Input
                                  value={this.state.defaultgroup.phoneNos}
                                  placeholder="Enter comma separated Phone Number with code"
                                  onChange={(e) => {
                                    this.handleDefaultPhoneChange(e);
                                  }}
                                  disabled={this.state.defaultgroup.submitted}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col className="order-xl-1" xl="4">
                                <Row>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        disabled={true}
                                        checked={
                                          this.state.defaultgroup.daily.urgent
                                        }
                                        onChange={() => {
                                          let group = this.state.defaultgroup;
                                          group.daily.urgent =
                                            !this.state.defaultgroup.daily
                                              .urgent;
                                          this.setState({
                                            defaultgroup: group,
                                          });
                                        }}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />
                                    }
                                    label={
                                      this.state.defaultgroup.daily.urgent
                                        ? "Urgent Notification is Enabled"
                                        : "Urgent Notification is Disabled"
                                    }
                                  />
                                  <Tooltip title="Daily urgent mail">
                                    <InfoIcon
                                      style={{
                                        paddingTop: "7px",
                                      }}
                                    />
                                  </Tooltip>
                                </Row>
                              </Col>
                              <Col className="order-xl-1" xl="4">
                                <Row>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        disabled={true}
                                        checked={
                                          this.state.defaultgroup.daily.summary
                                        }
                                        onChange={() => {
                                          let group = this.state.defaultgroup;
                                          group.daily.summary =
                                            !this.state.defaultgroup.daily
                                              .summary;
                                          this.setState({
                                            defaultgroup: group,
                                          });
                                        }}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />
                                    }
                                    label={
                                      this.state.defaultgroup.daily.summary
                                        ? "Summary Notification is Enabled"
                                        : "Summary Notification is Disabled"
                                    }
                                  />
                                  <Tooltip title="Daily summary mail">
                                    <InfoIcon
                                      style={{
                                        paddingTop: "7px",
                                      }}
                                    />
                                  </Tooltip>
                                </Row>
                              </Col>
                              <Col className="order-xl-1" xl="4">
                                <Row>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        disabled={true}
                                        checked={
                                          this.state.defaultgroup.direct.all
                                        }
                                        onChange={() => {
                                          let group = this.state.defaultgroup;
                                          group.direct.all =
                                            !this.state.defaultgroup.direct.all;
                                          this.setState({
                                            defaultgroup: group,
                                          });
                                        }}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />
                                    }
                                    label={
                                      this.state.defaultgroup.direct.all
                                        ? "Direct Notification is Enabled"
                                        : "Direct Notification is Disabled"
                                    }
                                  />
                                  <Tooltip title="Direct mail to all">
                                    <InfoIcon
                                      style={{
                                        paddingTop: "7px",
                                      }}
                                    />
                                  </Tooltip>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="order-xl-1" xl="12">
                                <center>
                                  {!this.state.defaultgroup.submitted ? (
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      className="login-form-button"
                                      onClick={() => {
                                        if (
                                          !this.state.defaultgroup.groupType ||
                                          this.state.defaultgroup.groupType ===
                                            ""
                                        ) {
                                          alert(
                                            "Select a group type to continue"
                                          );
                                          return;
                                        }
                                        if (
                                          window.confirm(
                                            "Do you really want to create a default group? It will create the default topic and send subscribe email to respective emailIds"
                                          )
                                        ) {
                                          this.handleDefaultSubmit();
                                        }
                                      }}
                                    >
                                      {this.state.saving ? (
                                        <i className="fas fa-spinner fa-pulse"></i>
                                      ) : (
                                        "Submit Default Group"
                                      )}
                                    </Button>
                                  ) : null}
                                  {this.state.defaultgroup.submitted &&
                                  (this.state.groups.length === 0 ||
                                    !this.state.groups[0].submitted) ? (
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      className="login-form-button"
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Do you really want to delete the Default Group? It will delete the default topic and send unsubscribe email to respective emailIds"
                                          )
                                        ) {
                                          this.deleteDefaultGroup();
                                        }
                                      }}
                                    >
                                      {this.state.saving ? (
                                        <i className="fas fa-spinner fa-pulse"></i>
                                      ) : (
                                        "Delete Default Group"
                                      )}
                                    </Button>
                                  ) : null}
                                </center>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      {this.state.selectedGroupType &&
                        this.state.groups.map((group, index) => {
                          return (
                            <Col lg="6" xl="6" style={{ marginBottom: "25px" }}>
                              <Card className="card-stats mb-4 mb-xl-0">
                                <CardBody>
                                  <Row>
                                    <div className="col">
                                      <CardTitle
                                        tag="h5"
                                        className="text-uppercase text-muted mb-0"
                                      >
                                        {"Plant Code: " + group.plantId}
                                      </CardTitle>
                                    </div>
                                    {!group.submitted &&
                                    this.state.groups.length !== 1 ? (
                                      <Col className="col-auto">
                                        <CancelIcon
                                          isDisabled={true}
                                          onClick={() => {
                                            this.clearGroup(index);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        ></CancelIcon>
                                      </Col>
                                    ) : null}
                                  </Row>{" "}
                                  {this.state.selectedGroupType !==
                                    "notificationGroupsCompany" && (
                                    <Row style={{ marginBottom: "25px" }}>
                                      <Col className="order-xl-1" xl="12">
                                        <Row>
                                          <InputLabel>Plant </InputLabel>
                                          <Tooltip title="Select list of Plants for the new group">
                                            <InfoIcon
                                              style={{
                                                paddingBottom: "5px",
                                              }}
                                            />
                                          </Tooltip>
                                        </Row>
                                        <Select
                                          isMulti
                                          isDisabled={group.submitted}
                                          isClearable={true}
                                          closeMenuOnSelect={false}
                                          value={group.plantList}
                                          defaultValue={group.plantList}
                                          options={this.state.plantList}
                                          onChange={(e) => {
                                            this.handlePlantChange(e, index);
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  {layout === 1 &&
                                    ![
                                      "notificationGroupsCompany",
                                      "notificationGroupsPlant",
                                    ].includes(
                                      this.state.selectedGroupType
                                    ) && (
                                      <Row style={{ marginBottom: "25px" }}>
                                        <Col className="order-xl-1" xl="12">
                                          <Row>
                                            <InputLabel>Machine </InputLabel>
                                            <Tooltip title="Select list of Machines for the new group">
                                              <InfoIcon
                                                style={{
                                                  paddingBottom: "5px",
                                                }}
                                              />
                                            </Tooltip>
                                          </Row>
                                          <Select
                                            isMulti
                                            isDisabled={group.submitted}
                                            isClearable={true}
                                            closeMenuOnSelect={false}
                                            value={group.machineList}
                                            options={this.state.machineList}
                                            onChange={(e) => {
                                              this.handleMachineChange(
                                                e,
                                                index
                                              );
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                    )}
                                  {![
                                    "notificationGroupsCompany",
                                    "notificationGroupsPlant",
                                    "notificationGroupsMachine",
                                  ].includes(this.state.selectedGroupType) && (
                                    <Row style={{ marginBottom: "25px" }}>
                                      <Col className="order-xl-1" xl="12">
                                        <Row>
                                          <InputLabel>Equipment </InputLabel>
                                          <Tooltip title="Select list of Equipments for the new group">
                                            <InfoIcon
                                              style={{
                                                paddingBottom: "5px",
                                              }}
                                            />
                                          </Tooltip>
                                        </Row>
                                        <Select
                                          isMulti
                                          isDisabled={group.submitted}
                                          isClearable={true}
                                          closeMenuOnSelect={false}
                                          value={group.equipmentList}
                                          options={this.state.equipmentList}
                                          onChange={(e) => {
                                            this.handleEquipmentChange(
                                              e,
                                              index
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  <Row style={{ marginBottom: "25px" }}>
                                    <Col className="order-xl-1" xl="12">
                                      <Row>
                                        <InputLabel>Email Id </InputLabel>
                                        <Tooltip title="Enter a comma separated emails, Only these people will receive all the mails related to this group">
                                          <InfoIcon
                                            style={{
                                              paddingBottom: "5px",
                                            }}
                                          />
                                        </Tooltip>
                                      </Row>
                                      <Input
                                        value={group.emailIds}
                                        placeholder="Enter Email Id comma separated"
                                        onChange={(e) => {
                                          this.handleEmailChange(e, index);
                                        }}
                                        disabled={group.submitted}
                                      />
                                    </Col>
                                  </Row>
                                  <Row style={{ marginBottom: "25px" }}>
                                    <Col className="order-xl-1" xl="12">
                                      <Row>
                                        <InputLabel>Phone Number </InputLabel>
                                        <Tooltip title="Enter a comma separated phone No., Only these people will receive all the SMS related to this group">
                                          <InfoIcon
                                            style={{
                                              paddingBottom: "5px",
                                            }}
                                          />
                                        </Tooltip>
                                      </Row>
                                      <Input
                                        value={group.phoneNos}
                                        placeholder="Enter comma separated Phone Number with code"
                                        onChange={(e) => {
                                          this.handlePhoneChange(e, index);
                                        }}
                                        disabled={group.submitted}
                                      />
                                    </Col>
                                  </Row>
                                  <Row style={{ marginBottom: "25px" }}>
                                    <Col className="order-xl-1" xl="6">
                                      <FormControlLabel
                                        control={
                                          <Tooltip title="Daily urgent mail">
                                            <Switch
                                              disabled={group.submitted}
                                              checked={group.daily.urgent}
                                              onChange={() => {
                                                let groups = this.state.groups;
                                                groups[index].daily.urgent =
                                                  !group.daily.urgent;
                                                this.setState({
                                                  groups: groups,
                                                });
                                              }}
                                              color="primary"
                                              name="checkedB"
                                              inputProps={{
                                                "aria-label":
                                                  "primary checkbox",
                                              }}
                                            />
                                          </Tooltip>
                                        }
                                        label={
                                          group.daily.urgent
                                            ? "Urgent Notification is Enabled"
                                            : "Urgent Notification is Disabled"
                                        }
                                      />
                                    </Col>
                                    <Col className="order-xl-1" xl="6">
                                      <FormControlLabel
                                        control={
                                          <Tooltip title="Daily summary mail">
                                            <Switch
                                              disabled={group.submitted}
                                              checked={group.daily.summary}
                                              onChange={() => {
                                                let groups = this.state.groups;
                                                groups[index].daily.summary =
                                                  !group.daily.summary;
                                                this.setState({
                                                  groups: groups,
                                                });
                                              }}
                                              color="primary"
                                              name="checkedB"
                                              inputProps={{
                                                "aria-label":
                                                  "primary checkbox",
                                              }}
                                            />
                                          </Tooltip>
                                        }
                                        label={
                                          group.daily.summary
                                            ? "Summary Notification is Enabled"
                                            : "Summary Notification is Disabled"
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row style={{ marginBottom: "25px" }}>
                                    <Col className="order-xl-1" xl="6">
                                      <FormControlLabel
                                        control={
                                          <Tooltip title="Direct mail to all">
                                            <Switch
                                              disabled={group.submitted}
                                              checked={group.direct.all}
                                              onChange={() => {
                                                let groups = this.state.groups;
                                                groups[index].direct.all =
                                                  !group.direct.all;
                                                this.setState({
                                                  groups: groups,
                                                });
                                              }}
                                              color="primary"
                                              name="checkedB"
                                              inputProps={{
                                                "aria-label":
                                                  "primary checkbox",
                                              }}
                                            />
                                          </Tooltip>
                                        }
                                        label={
                                          group.direct.all
                                            ? "Direct Notification is Enabled"
                                            : "Direct Notification is Disabled"
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="order-xl-1" xl="12">
                                      <center>
                                        {!group.submitted ? (
                                          <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                            onClick={() => {
                                              if (
                                                !this.state.defaultgroup
                                                  .submitted
                                              ) {
                                                alert(
                                                  "Submit the default group to continue"
                                                );
                                                return;
                                              }
                                              if (
                                                (this.state
                                                  .selectedGroupType ===
                                                  "notificationGroupsPlant" &&
                                                  group.plantList.length ===
                                                    0) ||
                                                (this.state
                                                  .selectedGroupType ===
                                                  "notificationGroupsMachine" &&
                                                  group.machineList.length ===
                                                    0) ||
                                                (this.state
                                                  .selectedGroupType ===
                                                  "notificationGroupsEquipment" &&
                                                  group.equipmentList.length ===
                                                    0)
                                              ) {
                                                alert(
                                                  "Atleast select one group list to submit"
                                                );
                                                return;
                                              }
                                              if (
                                                window.confirm(
                                                  "Do you really want to create a group? It will create all the topics and send subscribe email to respective emailIds"
                                                )
                                              ) {
                                                this.handleSubmit(index);
                                              }
                                            }}
                                          >
                                            {this.state.saving ? (
                                              <i className="fas fa-spinner fa-pulse"></i>
                                            ) : (
                                              "Submit Group"
                                            )}
                                          </Button>
                                        ) : null}
                                        {group.submitted ? (
                                          <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  "Do you really want to delete the Group? It will delete all the topics and send unsubscribe email to respective emailIds"
                                                )
                                              ) {
                                                this.deleteGroup(index);
                                              }
                                            }}
                                          >
                                            {this.state.saving ? (
                                              <i className="fas fa-spinner fa-pulse"></i>
                                            ) : (
                                              "Delete Group"
                                            )}
                                          </Button>
                                        ) : null}
                                      </center>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                    <br></br>
                    <Row>
                      {
                        <Col lg="12">
                          {
                            <center>
                              {this.state.selectedGroupType &&
                              this.state.selectedGroupType !==
                                "notificationGroupsCompany" ? (
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (
                                      this.state.groups.length > 0 &&
                                      !this.state.groups[
                                        this.state.groups.length - 1
                                      ].submitted
                                    ) {
                                      alert(
                                        "Submit the existing group to create a new group"
                                      );
                                      return;
                                    }
                                    {
                                      this.addNewGroup();
                                    }
                                  }}
                                  size="md"
                                >
                                  {this.state.saving ? (
                                    <i className="fas fa-spinner fa-pulse"></i>
                                  ) : (
                                    "Add New Group"
                                  )}
                                </Button>
                              ) : null}
                            </center>
                          }
                        </Col>
                      }
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NotificationGroup;
