import * as yup from "yup";

const validation = {
  async validate(object) {
    let error = "";

    //Defining schema
    let schema = yup.object().shape({
      customName: yup
        .string()
        .required()
        .matches(
          /([A-Za-z]+)/,
          "Only numbers are not allowed for Company Name field "
        )
        .label("Machine Name"),
      plantId: yup.number().required().label("Plant"),
    });

    try {
      await schema.validate(object, { abortEarly: false, strict: true });
    } catch (err) {
      error = err.errors[0];
    }
    return error;
  },
};

export default validation;
