import CallAPI from "../../axios";

export const FETCH_STATS_DATA = "FETCH_STATS_DATA";
export const FETCH_REQUEST_USER_DATA = "FETCH_REQUEST_USER_DATA";

const fetchData = (data) => {
  return {
    type: FETCH_STATS_DATA,
    payload: data,
  };
};

const fetchUserRequestData = (data) => {
  return {
    type: FETCH_REQUEST_USER_DATA,
    payload: data,
  };
};

export const fetchStatsData = () => {
  return (dispatch) => {
    CallAPI.organization_stats_data()
      .then((response) => {
        dispatch(fetchData(response && response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchRequestUserData = () => {
  return (dispatch) => {
    CallAPI.getData("/requestUser")
      .then((response) => {
        dispatch(fetchUserRequestData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
