import {
  FETCH_EQUIPMENT_SUB_TYPES,
  FETCH_EQUIPMENT_SUB_TYPES_DETAILS,
  FETCH_EQUIPMENT_SUB_TYPES_DD
} from "../actions/equipmentSubTypes.action";

const equipSubTypeReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_EQUIPMENT_SUB_TYPES:
      return {
        ...state,
        equipSubTypeData: action.payload,
      };
    case FETCH_EQUIPMENT_SUB_TYPES_DETAILS:
      return {
        ...state,
        equipSubTypeDetailsData: action.payload,
      };
    case FETCH_EQUIPMENT_SUB_TYPES_DD:
      return {
        ...state,
        equipSubTypeDropDown: action.payload,
      };
    default:
      return state;
  }
};

export default equipSubTypeReducer;
