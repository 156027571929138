import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "../../../axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import MaterialIcon, { colorPalette } from "material-icons-react";
import { ToastContainer, toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "react-toastify/dist/ReactToastify.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

import { fetchFirmwareTypes } from "../../../redux/actions/firmwareTypes.action";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";

const { SearchBar } = Search;

const columns = [
  {
    text: "Id",
    dataField: "_id",
    sort: true,
  },
  {
    text: "Firmware Type",
    dataField: "firmwareType",
    sort: true,
  },
];

const options = {
  showTotal: true,
};

let portal_user = null;

class FirmwareTypes extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));

    this.actionFormatter = this.actionFormatter.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  state = {
    firmwareTypes: null,
    list: true,
    hardware: {},
    createAllowed: true,
    columns: [
      {
        text: "Id",
        dataField: "_id",
        formatter: this.copyIcon,
        sort: true,
        // events: {
        //   onClick: (e, col, colIndex, row, rowIndex) => {
        //     this.jump('/v1/firmware-types/' + row._id);
        //   }
        // }
      },
      {
        text: "Firmware Type",
        dataField: "firmwareType",
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("/v1/firmware-types/" + row._id);
          },
        },
      },
      {
        text: "Actions",
        dataField: "_id",
        formatter: this.actionFormatter.bind(this),
        hidden:
          JSON.parse(localStorage.getItem("portal-user")).department.slug ===
            "admin" || "root"
            ? false
            : true,
      },
    ],
  };

  actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <MaterialIcon
        onClick={this.handleDelete.bind(this, row)}
        icon="delete"
        color="red"
      />
    );
  }

  copyIcon(cell, row) {
    let _this = this;
    return (
      <span>
        {row._id}{" "}
        <CopyToClipboard text={row._id} onCopy={() => toast("Copied!!!")}>
          <MaterialIcon icon="content_copy" color="#0288d1" />
        </CopyToClipboard>
      </span>
    );
  }

  async componentDidMount() {
    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != "admin") {
      //Hiding action column from table for normal users
      let col = [...this.state.columns];
      // col[2].hidden = true;

      this.setState({ createAllowed: false, columns: col });
    }

    // await this.getfirmwareTypes();
    this.props.fetchFirmwareTypes();
  }

  async getfirmwareTypes() {
    let api = await axios.getFirmwareTypes();
    let firmwareTypes = api.data.data;
    this.setState({ firmwareTypes: firmwareTypes });
  }

  handleTextChange =
    (state, key = null) =>
    (e) => {
      let subject = { ...this.state[state] };
      subject[key] = e.target.value;
      this.setState({ [state]: subject });
    };

  async handleDelete(firmwareType) {
    if (window.confirm("Do you really want to delete it?")) {
      //Calling backend API to delelte firmwareTypes
      let api = await axios.delete_FirmwareType(firmwareType._id);
      if (api.status === 200) {
        //Remove from state
        this.props.fetchFirmwareTypes();

        toast.success("Data deleted successfully");
      }

      if (api.status === 400) {
        toast.error(api.data.message);
      }

      if (api.status === 500) {
        toast.error("Something went wrong. Please try again");
      }
    }
  }

  jump(url) {
    this.props.history.push(url);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.firmwareTypesData !== prevState.firmwareTypesData) {
      if (nextProps.firmwareTypesData) {
        return {
          firmwareTypes:
            nextProps.firmwareTypesData.firmwareTypesData &&
            nextProps.firmwareTypesData.firmwareTypesData.data,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log();
        this.jump("/v1/firmware-types/" + row._id);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.createAllowed && (
                <Button
                  color="success"
                  onClick={() => {
                    this.jump(`/v1/firmware-types/add`);
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Firmware Type
                </Button>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Firmware Types</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.firmwareTypes && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          // rowEvents={ rowEvents }
                          keyField="_id"
                          data={this.state.firmwareTypes}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        ></BootstrapTable>
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  firmwareTypesData: _.isEmpty(state.firmwareTypes)
    ? null
    : state.firmwareTypes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFirmwareTypes: (obj) => dispatch(fetchFirmwareTypes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareTypes);
