import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import _ from "lodash";
import { fetchComponentTypeDetails } from "../../../redux/actions/componentTypes.action";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import axios from "../../../axios";
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;
const options = {
  showTotal: true,
};

let stats = {};
let companyId = "";
let componentTypeCode = "";
let portal_user = "";

const columns = [
  {
    text: "Component Type",
    dataField: "customName",
    sort: true,
  },
  {
    text: "Type Code",
    dataField: "componentTypeCode",
    sort: true,
  },
];

// const options = [
//     { value: 'motor', label: 'motor' },
//     { value: 'pump', label: 'pump' },
//   ];

class EquipmentTypeDetails extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    componentType: {},
    componentTypeBackup: {},
    readOnly: true,
    optData: [],
    defaultOption: 0,
    defaultOptionBackup: 0,
    saving: false,
    createAllowed: true,
  };

  async componentDidMount() {
    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != "admin") {
      this.setState({ createAllowed: false });
    }

    componentTypeCode = this.props.match.params.componentTypeCode;
    console.log(this.props.location.state);
    if (this.props.location.state) {
      let optionsEquipTypeData = [];
      this.props.location.state.forEach((eq, i) => {
        if (eq !== "_id") {
          optionsEquipTypeData.push({
            key: i,
            componentTypeCode: `${eq}`,
            customName: eq,
          });
        }
      });

      this.setState({ optData: optionsEquipTypeData });
    }
    // await this.getComponentTypes();
    this.props.fetchComponentTypeDetails(componentTypeCode);
  }

  async getComponentTypes() {
    // let api = await axios.getComponentTypes(companyId, componentTypeCode);
    let api = await axios.getData("/components/types/" + componentTypeCode);
    let data = api.data.data;
    this.setState({ componentType: data, componentTypeBackup: data });
  }

  handleChange = (input) => (e) => {
    let componentType = { ...this.state.componentType };
    componentType[input] = e.target.value;
    this.setState({ componentType: componentType });
  };

  handleEdit(edit) {
    this.setState({ readOnly: edit });
  }

  handleCancel() {
    let componentTypeBackup = { ...this.state.componentTypeBackup };
    this.setState({
      componentType: componentTypeBackup,
      defaultOption: this.state.defaultOptionBackup,
      readOnly: true,
    });
  }

  handleSelectChange = () => (option) => {
    let componentType = { ...this.state.componentType };
    componentType.componentTypeMetaCode = option.value;
    this.setState({ componentType: componentType, defaultOption: option.key });
  };

  async handleSave() {
    this.setState({ saving: true });
    let componentType = {
      ...this.state.componentType,
    };

    //Validating
    let err = await validation.validate(componentType);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    delete componentType._id;
    let api = await axios.saveComponentType(
      componentType,
      this.state.componentType._id
    );
    // console.log(api)
    if (api.status == 200) {
      toast.success("Component type saved successfully");
      this.setState({ readOnly: true });
      this.jump("/v1/component-types", 1000);
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong.Please try again.");
    }

    this.setState({ saving: false });
  }

  jump = (url, delay = 0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.componentTypeDetailsData !== prevState.componentTypeDetailsData
    ) {
      if (nextProps.componentTypeDetailsData) {
        return {
          componentType: nextProps.componentTypeDetailsData.data,
          componentTypeBackup: nextProps.componentTypeDetailsData.data,
        };
      }
    }
  }

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump("/v1/component-types", 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Component Sub Type</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <ToolkitProvider>
                          {(props) => (
                            <div>
                              <BootstrapTable
                                {...props.baseProps}
                                keyField="_id"
                                data={this.state.optData}
                                columns={columns}
                                striped
                                hover
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  componentTypeDetailsData: _.isEmpty(
    state.componentTypes.componentTypeDetailsData
  )
    ? null
    : state.componentTypes.componentTypeDetailsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchComponentTypeDetails: (id) => dispatch(fetchComponentTypeDetails(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentTypeDetails);
