import React from "react";
import { connect } from "react-redux";
import {
  fetchComponentOptions,
  fetchEquipComponentDetails,
} from "../../../../redux/actions/componentTypes.action";
import { fetchNotificationMute } from "../../../../redux/actions/notification.action";
import ReactDOM from "react-dom";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";
import _ from "lodash";
import ImageUploader from "react-images-upload";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Helper from "../../../../helper";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import classes from "./components.css";
import axios from "../../../../axios";
import Header from "components/Headers/Header.jsx";
import validation from "./validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notificationOption = [
  { value: "cdp", label: "CDP" },
  { value: "sensorStatus", label: "Sensor Status" },
  { value: "edge", label: "Edge" },
  { value: "rms", label: "Rms" },
  { value: "nanoAI", label: "Nano AI" },
  { value: "temp", label: "Temp Alarm" },
];

let companyId = "";
let equipmentId = "";
let componentId = "";
let machineId = "";

class ComponentDetails extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;
    this.handleSaveLocation = this.handleSaveLocation.bind(this);
  }

  state = {
    component: {
      operationalSpecifications: [],
      tag: {
        docs: {
          engineeringDrawingsUrl: [],
          otherDocsUrl: [],
        },
      },
      equipment: {},
    },
    componentBackup: {
      operationalSpecifications: [],
      tag: {},
    },
    defaultOperationalSpecifications: [],
    operationalSpecifications: [],
    technicalSpecifications: [],
    componentImages: [],
    componentTypes: [],
    readOnlyComponent: true,
    readOnlyLocation: true,
    readOnlyNotificationMute: true,
    optData: [],
    defaultOption: 0,
    defaultOptionBackup: 0,
    //Location state
    locationImages: {},
    engineeringDrawings: {},
    otherDocs: {},
    locationKey: 1,
    savingComponent: false,
    savingLocation: false,
    savingNotificationMute: false,
    notificationType: {},
    noOfDays: 0,
    notificationMute: {},
    images: [],
  };

  // async getSpecifications() {
  //   let endpoint = '/technical-specifications'
  //   let api = await axios.getData(endpoint);
  //   let options = [];
  //   if (api) {
  //     api.data.data.forEach((s, i) => {
  //       options.push({
  //         key: i,
  //         label: `${s.name} - ${s.units}`,
  //         value: s.name
  //       });
  //     });
  //   }

  //   let defaultOperationalSpecifications = [];
  //   this.state.operationalSpecifications.forEach((o, i) => {
  //     let index = options.findIndex(x => x.value === o.name);
  //     defaultOperationalSpecifications.push(index);
  //   });

  //   this.setState({defaultOperationalSpecifications, defaultOperationalSpecificationsBackup: defaultOperationalSpecifications});

  //   this.setState({technicalSpecifications: options});
  // }

  async getComponentTypeOptions() {
    let endpoint = "/components/options/" + equipmentId;
    let componentTypes = await axios.componentType_get(endpoint);
    let options = [],
      optionsSubtype = [];
    if (componentTypes) {
      componentTypes.data.data.map((eq, i) => {
        options.push({
          key: i,
          label: `${
            eq.componentTypeData && eq.componentTypeData.customName
          } - ${
            eq.componentTypeData && eq.componentTypeData.componentTypeCode
          } - ${eq.componentTypeData && eq.componentTypeData.componentType}`,
          value: eq.componentTypeData && eq.componentTypeData.componentTypeCode,
        });
      });
    }

    let index = options.findIndex(
      (x) => x.value === this.state.component.componentType
    );

    this.setState({
      defaultOption: index,
      defaultOptionBackup: index,
      componentTypes: options,
    });
    if (index === -1) {
      options.push({
        key: 0,
        label: "No Data",
        value: "No Data",
      });
      this.setState({ defaultOption: options.length - 1 });
    }
  }

  async getComponentDetails() {
    let api = await axios.get_component(componentId);
    console.log("aaaaaa", api.data);
    if (api) {
      let operationalSpecifications = api.data.data.operationalSpecifications;
      this.setState({
        component: api.data.data,
        componentBackup: api.data.data,
        operationalSpecifications,
        operationalSpecificationsBackup:
          api.data.data.operationalSpecifications,

        locationImages: api.data.data.tag.docs.imagesUrl,
        engineeringDrawings: api.data.data.tag.docs.engineeringDrawingsUrl,
        otherDocs: api.data.data.tag.docs.otherDocsUrl,
      });
    }
  }

  async getNotificationMuteDetails() {
    // this.props.fetchNotificationMute(this.state.component.tag.tagId);
    const endpoint = "/notificationMute/" + this.props.location.state.tagId;
    const api = await axios.getData(endpoint);
    if (api) {
      this.setState({
        notificationMute: api.data,
        notificationType: api.data.notificationType,
        noOfDays: api.data.noOfDays,
      });
    }
  }

  handleChange =
    (input, key = null, index = null) =>
    (e) => {
      if (input === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index].value = e.target.value;
        this.setState({ operationalSpecifications: operationalSpecifications });
      } else {
        let component = { ...this.state.component };
        component[input] = e.target.value;
        this.setState({ component: component });
      }
    };

  handleSelectChange =
    (type, key = null, index = null) =>
    async (option) => {
      if (type === "operationalSpecifications") {
        let operationalSpecifications = [
          ...this.state.operationalSpecifications,
        ];
        operationalSpecifications[index][key] = option ? option.value : "";

        let defaultOperationalSpecifications = [
          ...this.state.defaultOperationalSpecifications,
        ];
        defaultOperationalSpecifications[index] = option ? option.key : "";

        this.setState({
          operationalSpecifications,
          defaultOperationalSpecifications,
        });
      } else {
        let component = { ...this.state.component };
        component.componentType = option ? option.value : "";
        this.setState({ component, defaultOption: option ? option.key : "" });
      }
    };

  handleAdd() {
    let operationalSpecifications = [...this.state.operationalSpecifications];
    let obj = {
      name: "",
      value: "",
    };
    operationalSpecifications.push(obj);
    this.setState({ operationalSpecifications });
  }

  handleRemove() {
    let operationalSpecifications = [...this.state.operationalSpecifications];
    operationalSpecifications.pop();
    this.setState({ operationalSpecifications });
  }

  handleChangeTag = (input) => (e) => {
    let component = { ...this.state.component };
    component.tag[input] = e.target.value;
    this.setState({ component });
  };

  handleChangeLocation =
    (input, key = null, index = null) =>
    (e) => {
      // locationImages
      // engineeringDrawings
      // otherDocs
      let file = e.target.value;
      let encodedFiles = [];
      encodedFiles[0] = file;
      this.setState({ [input]: encodedFiles });
    };

  handleEdit(edit) {
    this.setState({ readOnlyComponent: edit });
  }

  handleEditLocation(edit) {
    this.setState({ readOnlyLocation: edit });
  }
  handleEditNotificationMute(edit) {
    this.setState({ readOnlyNotificationMute: edit });
  }

  handleCancel(type) {
    if (type === "component") {
      let componentBackup = { ...this.state.componentBackup };
      this.setState({
        component: componentBackup,
        defaultOption: this.state.defaultOptionBackup,
        readOnlyComponent: true,
        componentImages: [],
        operationalSpecifications: this.state.operationalSpecificationsBackup,
        defaultOperationalSpecifications:
          this.state.defaultOperationalSpecificationsBackup,
      });
    } else if (type === "location") {
      let componentBackup = { ...this.state.componentBackup };
      this.setState({
        readOnlyLocation: true,
        locationImages: {},
        engineeringDrawings: {},
        otherDocs: {},
        locationKey: this.state.locationKey + 1,
      });
    } else if (type === "notificationMute") {
      let componentBackup = { ...this.state.componentBackup };
      this.setState({
        readOnlyNotificationMute: true,
      });
    }
  }

  onDrop = (pictureFiles, pictureData) => {
    let component = { ...this.state.component };
    component.imagesUrl = pictureData.length > 0 ? pictureData : null;
    this.setState({ component: component });
  };

  onDropAdd = (pictureFiles, pictureData) => {
    let componentImages = { ...this.state.componentImages };
    componentImages = pictureData;
    this.setState({ componentImages });
  };

  fileChangedHandler = (data) => async (event) => {
    let files = event.target.files;
    let encodedFiles = [];
    for (let i = 0; i < files.length; i++) {
      let encoded = await this.getBase64(files[i]);
      encodedFiles.push(encoded);
    }

    // console.log(encodedFiles, 'encoded files');
    this.setState({ [data]: encodedFiles });
  };

  getBase64(file) {
    let reader = new FileReader();

    //Returning a promise so that we can call it using await
    return new Promise((resolve, reject) => {
      //on error reject it
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file"));
      };

      reader.onload = () => {
        //on successfull resolve it
        resolve(reader.result);
      };

      reader.readAsDataURL(file);
    });
  }

  async handleSave() {
    this.setState({ savingComponent: true });

    console.log("this.state.component:", this.state.component);

    let component = {
      ...this.state.component,
    };

    //Validating
    let testComponent = { ...component };

    let err = await validation.validateComponent(testComponent);
    if (err) {
      toast.warn(err);
      this.setState({ savingComponent: false });
      return;
    }

    delete component.tag;
    let endpoint = "/components";
    let compoenntTempOne = {
      customName: component.customName,
      externalId: component.externalId,
    };
    let api = await axios.assetManage_ComponentUpdate(
      component.componentId,
      compoenntTempOne
    );
    if (api && api.status == 200) {
      if (this.state.componentImages.length) {
        let compImage = {
          file: this.state.componentImages[0],
          fileName: `${this.props.location.state.componentId}.jpg`,
          companyId: companyId,
        };
        let api2 = await axios.fileDataImage_create(compImage);
        let image = await axios.fileDataImage_get(
          `${this.props.location.state.componentId}.jpg`,
          companyId
        );
        this.setState({
          images: image.data.data.file ? [image.data.data.file] : [],
        });
      }

      toast.success("Component saved successfully");
      this.setState({
        readOnlyComponent: true,
        component: api.data.data,
        componentImages: [],
      });
      companyId = this.props.match.params.companyId;
      equipmentId = this.props.match.params.equipmentId;
      componentId = this.props.match.params.componentId;
      machineId = this.props.match.params.machineId;

      // await this.getComponentDetails();
      this.props.fetchEquipComponentDetails(componentId);
      // await this.getComponentTypeOptions();
      this.props.fetchComponentOptions(companyId, equipmentId);
    } else if (api && api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status == 500) {
      toast.error("Something wet wrong. Please try again");
    }
    this.setState({ savingComponent: false });
  }

  handleSaveNotificationMute = async () => {
    this.setState({ savingNotificationMute: true });
    let endPoint = "";
    let api = {};
    const data = {
      tagId: this.props.location.state.tagId,
      notificationType: this.state.notificationType,
      noOfDays: this.state.noOfDays,
      currentDate: parseInt(new Date().getTime() / 1000),
      updatedBy: localStorage.getItem("portal-user"),
    };
    if (this.state.notificationMute._id) {
      endPoint = "/notificationMute/" + this.state.notificationMute._id;
      api = await axios.updateData(endPoint, data);
    } else {
      endPoint = "/notificationMute/";
      api = await axios.postNotificationMute(endPoint, data);
    }

    if (api && api.status == 200) {
      toast.success("Notification saved successfully");
      this.setState({
        readOnlyNotificationMute: true,
        notificationMute: api.data.data,
      });
    } else if (api && api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status == 500) {
      toast.error("Something wet wrong. Please try again");
    }

    this.setState({ savingNotificationMute: false });
  };

  async handleSaveLocation(event) {
    this.setState({ savingLocation: true });
    event.preventDefault();
    let data = {
      // tag: this.state.component.tag,
      locationImages: JSON.stringify(this.state.locationImages),
      engineeringDrawings: JSON.stringify(this.state.engineeringDrawings),
      otherDocs: JSON.stringify(this.state.otherDocs),
    };

    // console.log(data, 'put data');

    let endpoint = "/components/locations";
    let api = await axios.postData(
      endpoint,
      data,
      this.state.component.tag._id
    );
    if (api && api.status === 200) {
      toast.success("Location saved successfully");
      this.setState({
        readOnlyLocation: true,
        locationKey: this.state.locationKey + 1,
      });
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status == 500) {
      toast.error("Something went wrong. Please try again");
    }
    this.setState({ savingLocation: false });
  }

  handleSelectNotification = (e) => {
    this.setState({ notificationType: e });
  };

  handleChangeNoOfDays = (e) => {
    this.setState({ noOfDays: e.target.value });
  };

  jump = (url) => {
    this.props.history.push(url);
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    equipmentId = this.props.match.params.equipmentId;
    componentId = this.props.match.params.componentId;
    machineId = this.props.match.params.machineId;

    // await this.getComponentDetails();
    this.props.fetchEquipComponentDetails(componentId);
    // await this.getComponentTypeOptions();
    this.props.fetchComponentOptions(companyId, equipmentId);

    // this.props.fetchNotificationMute(this.state.component.tag.tagId);
    console.log("thiskhbfabdf", this.props.location.state);
    await this.getNotificationMuteDetails();
    let image = await axios.fileDataImage_get(
      `${this.props.location.state.componentId}.jpg`,
      companyId
    );
    this.setState({
      images: image && image.data && image.data.data.file ? [image.data.data.file] : [],
    });
    // await this.getSpecifications();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.readOnlyComponent ||
      !prevState.readOnlyLocation ||
      !prevState.readOnlyNotificationMute
    ) {
      return;
    }
    let optionsComp = [],
      component = null,
      operationalSpecifications = [],
      index = 0;

    if (nextProps.componentOptionsData !== prevState.componentOptionsData) {
      if (
        nextProps.componentOptionsData &&
        nextProps.componentOptionsData.data
      ) {
        nextProps.componentOptionsData.data.forEach((eq, i) => {
          optionsComp.push({
            key: i,
            label: `${eq.customName} -> ${eq.componentTypeCode}`,
            value: eq.componentTypeCode,
          });
        });
        index = optionsComp.findIndex((x) =>
          x.value === _.isEmpty(prevState.component)
            ? 0
            : prevState.component.componentType
        );
      }
    }

    if (nextProps.componentDetailsData !== prevState.componentDetailsData) {
      if (nextProps.componentDetailsData) {
        operationalSpecifications =
          nextProps.componentDetailsData.data &&
          nextProps.componentDetailsData.data.operationalSpecifications;
        component = nextProps.componentDetailsData.data;
      }
    }

    return {
      // defaultOption: index,
      // defaultOptionBackup: index,
      // componentTypes: optionsComp,

      component:
        component !== null
          ? component
          : {
              operationalSpecifications: [],
              tag: {
                docs: {
                  engineeringDrawingsUrl: [],
                  otherDocsUrl: [],
                },
              },
              equipment: {},
            },
      componentBackup:
        component !== null
          ? component
          : {
              operationalSpecifications: [],
              tag: {},
            },
      operationalSpecifications,
      operationalSpecificationsBackup:
        component !== null ? component.operationalSpecification : [],
      // locationImages:
      //   component !== null
      //     ? component.tag !== null
      //       ? component.tag.docs.imagesUrl
      //       : {}
      //     : {},
      // engineeringDrawings:
      //   component !== null
      //     ? component.tag !== null
      //       ? component.tag.docs.engineeringDrawingsUrl
      //       : {}
      //     : {},
      // otherDocs:
      //   component !== null
      //     ? component.tag !== null
      //       ? component.tag.docs.otherDocsUrl
      //       : {}
      //     : {},
    };
  }

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump(
                        "/v1/companies/" +
                          companyId +
                          "/machines/" +
                          machineId +
                          "/equipments/" +
                          equipmentId,
                        0
                      );
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Component Details</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnlyComponent && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.handleEdit(false)}
                          size="sm"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Component Name
                        </label>
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            id="input-customName"
                            type="text"
                            value={this.state.component.customName}
                            onChange={this.handleChange("customName")}
                            disabled={this.state.readOnlyComponent}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Component Type
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-customName"
                            type="text"
                            value={this.state.component.componentType}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Component Sub Type
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-customName"
                            type="text"
                            value={this.state.component.componentSubType}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Equipment Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-equipmentType"
                            type="text"
                            value={
                              this.state.component.equipment &&
                              this.state.component.equipment.customName
                            }
                            onChange={this.handleChange("equipmentId")}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          External ID{" "}
                          <Tooltip
                            title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                            placement="right-start"
                          >
                            <Info
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                                fontSize: "30px",
                              }}
                            />
                          </Tooltip>
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-last-name"
                          type="text"
                          value={this.state.component.externalId}
                          onChange={this.handleChange("externalId")}
                          disabled={this.state.readOnly}
                        />
                      </FormGroup>
                    </Col>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <div
                            className={
                              this.state.readOnlyComponent
                                ? "image-container "
                                : ""
                            }
                          >
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Component Images
                            </label>
                            <ImageUploader
                              withIcon={true}
                              buttonText="Choose images"
                              labelClass="label"
                              onChange={this.onDrop}
                              imgExtension={[".jpg", ".jpeg", ".png"]}
                              maxFileSize={2000000} //2MB
                              withPreview={true}
                              label="Max file size: 2MB, accepted: jpg|png"
                              defaultImages={this.state.images}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          {!this.state.readOnlyComponent && (
                            <div className="image-container-add">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Add Component Images
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                labelClass="label"
                                onChange={this.onDropAdd}
                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                maxFileSize={2000000} //2MB
                                withPreview={true}
                                label="Max file size: 2MB, accepted: jpg|png"
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        {!this.state.readOnlyComponent && (
                          <Button
                            className="right"
                            color="success"
                            onClick={() => this.handleSave()}
                            size="md"
                            disabled={this.state.savingComponent}
                          >
                            {this.state.savingComponent ? (
                              <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        )}

                        {!this.state.readOnlyComponent &&
                          !this.state.savingComponent && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.handleCancel("component")}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <br />
          <br />
          <br />
          {/* NDS-4945 nOTIFICATION MUTE COMMENTED */}
          {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
          {/* <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Notification Mute</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnlyNotificationMute && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.handleEditNotificationMute(false)}
                          size="sm"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Tag Id
                        </label>
                        <FormGroup>
                          <Input
                            className="form-control-alternative"
                            type="text"
                            value={this.props.location.state.tagId}
                            onChange={this.handleChangeTag("locationId")}
                            name="locationId"
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Notification Type
                          </label>
                          <br />
                          <Select
                            // isClearable={true}
                            isDisabled={this.state.readOnlyNotificationMute}
                            onChange={this.handleSelectNotification}
                            value={
                              this.state.notificationType === undefined
                                ? null
                                : this.state.notificationType
                            }
                            options={notificationOption}
                            isMulti={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            No Of Days
                          </label>
                          <br />

                          <Input
                            className="form-control-alternative"
                            type="number"
                            min="0"
                            value={this.state.noOfDays}
                            onChange={this.handleChangeNoOfDays}
                            name="locationImages"
                            disabled={this.state.readOnlyNotificationMute}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        {!this.state.readOnlyNotificationMute && (
                          <Button
                            type="submit"
                            className="right"
                            color="success"
                            size="md"
                            disabled={this.state.savingNotificationMute}
                            onClick={this.handleSaveNotificationMute}
                          >
                            {this.state.savingNotificationMute ? (
                              <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        )}

                        {!this.state.readOnlyNotificationMute &&
                          !this.state.savingNotificationMute && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() =>
                                this.handleCancel("notificationMute")
                              }
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          {/* Location details */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => (
  console.log("eeeeeee", state),
  {
    componentOptionsData: _.isEmpty(state.componentTypes.componentOptionsData)
      ? null
      : state.componentTypes.componentOptionsData,
    componentDetailsData: _.isEmpty(state.componentTypes.componentDetailsData)
      ? null
      : state.componentTypes.componentDetailsData,
  }
);

const mapDispatchToProps = (dispatch) => {
  return {
    fetchComponentOptions: (companyId, equipmentId) =>
      dispatch(fetchComponentOptions(companyId, equipmentId)),
    fetchEquipComponentDetails: (componentId) =>
      dispatch(fetchEquipComponentDetails(componentId)),
    fetchNotificationMute: (tagId) => dispatch(fetchNotificationMute(tagId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentDetails);
