import React from "react";
import axios from "../../../axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Card, Container, Row, Col } from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";
import GraphShimmer from "../../../components/Shimmer/GraphShimmer";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

class DelayedCDP extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;
  }

  state = {
    delayedCDP: null,
    records: [],
    open: false,
    columns: [
      {
        text: "Company Code",
        dataField: "CompanyCode",
        sort: true,
      },
      {
        text: "Sensor ID",
        dataField: "sensorId",
        sort: true,
      },
      {
        text: "Tag ID",
        dataField: "tagId",
        sort: true,
      },
      {
        text: "Timestamp",
        dataField: "timestamp",
        sort: true,
      },
    ],
  };

  async componentDidMount() {
    this.getDelayedCDP();
  }

  async getDelayedCDP() {
    let delayedCDP = await axios.getDelayedCDP_list();
    this.setState({ delayedCDP: delayedCDP.data.data });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  async delayProcess() {
    this.setState({ open: false, loading: true });
    const requestData ={records: this.state.records}
    let api = await axios.delayProcessTags(requestData);
    let delayedCDP = await axios.getDelayedCDP_list();
    this.setState({ loading: false, records: [], delayedCDP: delayedCDP.data.data });
    if (api.status == 200) {
      toast.success(api.data.message);
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error(api.data.message);
    }
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    const showButton = this.state.records.length > 0 ? true : false;

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      hideSelectAll: true,
      classes: "selection-row",
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          var arr = this.state.records;
          if(isSelect && this.state.records.length===5){
            alert('Oops, You can not select more than 5 records')
            this.setState({ records: arr });
            return false
          }
          arr.push(row);
        } else {
          var arr = this.state.records;
          arr.pop(row);
        }
        this.setState({ records: arr });
        return true
      },
      onSelectAll: (isSelect, rows, e) => {
        if (isSelect) {
          var arr = [];
          rows.forEach((row) => {
            arr.push(row);
          });
        } else {
          var arr = [];
        }
        this.setState({ records: arr });
      },
    };
    return (
      <>
        <ToastContainer position="top-left" />

        <Header />
        {/* Page content */}
        {this.state.loading ? (
          <GraphShimmer />
        ) : (
          <Container className="mt--7" fluid>
            <Row>
              <Col lg="3">
                <div
                  onClick={() => {
                    this.jump("/");
                  }}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    cursor: "pointer",
                  }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              </Col>
              <Col lg="3">
                {showButton ? (
                  <Button
                    color="success"
                    onClick={() => {
                      this.setState({ open: true });
                    }}
                    size="md"
                  >
                    Request Delay Process
                  </Button>
                ) : null}
              </Col>
              <Col lg="6 table-caption">
                <h1>Delay Process</h1>
              </Col>
            </Row>

            {/* Table */}
            <Row>
              <div className="col">
                <Card className="bg-secondary shadow">
                  {this.state.delayedCDP && (
                    <ToolkitProvider
                      keyField="_id"
                      data={this.state.delayedCDP}
                      columns={this.state.columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <SearchBar {...props.searchProps} />
                          <BootstrapTable
                            {...props.baseProps}
                            keyField="_id"
                            data={this.state.delayedCDP}
                            columns={this.state.columns}
                            pagination={paginationFactory(options)}
                            striped
                            hover
                            selectRow={selectRow}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </Card>
              </div>
            </Row>
          </Container>
        )}
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          scroll={"body"}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          open={this.state.open}
        >
          <Close
            onClick={this.handleClose}
            style={{ color: "#000", float: "right", cursor: "pointer" }}
          />
          <DialogTitle id="form-dialog-title">
            Reprocess Failed Tags
          </DialogTitle>
          <DialogContent dividers>
            <div>
              <center>
                <span>
                  <h4>
                    Make sure the error is fixed before you click on submit
                  </h4>
                </span>
              </center>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              size="md"
              autoFocus
              onClick={() => this.delayProcess()}
              color="success"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DelayedCDP;
