import React from "react";
import { Input, Radio } from "antd";
import BearingCustom from "./bearingCustom";
import GMFCustom from "./gmfCustom";

const FormItemGen = ({ type, option, optionList, ...props }) => {
  const { dataExtractor } = option;
  if (typeof dataExtractor === "function")
    props.options = dataExtractor(optionList);
  // console.log({ type, option, optionList, props });
  switch (type) {
    case "Input":
      return <Input {...props} />;
    case "Radio":
      return <Radio.Group {...props} />;
    case "BearingCustom":
      return (
        <BearingCustom
          isMulti={true}
          {...props}
        />
      );
    case "GMFCustom":
      return <GMFCustom {...props} />;
    default:
      return null;
  }
};

export default FormItemGen;
