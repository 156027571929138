import React from "react";
import axios from "../../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MaterialIcon, { colorPalette } from "material-icons-react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

let companyId = "";
let sensorId = "";
let is = [];

class SensorTags extends React.Component {
  state = {
    sensors: null,
    sensorUnit: ["vibration", "ultrasonic", "temperature", "humidity"],
    list: true,
    sensor: {},
    sensorTypes: [],
    tagOptions: [],
    //Navigations
    currentPage: 0,
    saving: false,
    pages: [
      "pageSensors",
      "pageSensorAdd",
      "pageSensorConfig",
      "pageProcessConfig",
      "pageMountConfig",
    ],
    defaultSensorType: 0,
    columns: [
      // {
      //   text: 'Sensor Id',
      //   dataField: 'sensorId',
      //   sort: true
      // },
      {
        text: "Tag ID",
        dataField: "tagId",
        sort: true,
        // formatter: this.tagDisplay
      },
    ],
  };

  // tagDisplay(cell, row) {
  //   let tagIds = [];

  //   // cell.map(config => {
  //   //   tagIds.push(config.tagId);
  //   // });

  //   // tagIds = tagIds.join(", ")

  //   return (
  //     <div>
  //       {
  //         cell.map(config => {
  //           console.log("sds")
  //           let s = {}

  //           if (firmwareConfig) {
  //             s['color'] = '#3f51b5'
  //           } else {
  //             s['color'] = 'red'
  //           }

  //           return (
  //             <div style={s}>
  //               {config.tagId}
  //             </div>
  //           )
  //         })
  //       }
  //     </div>
  //   );
  // }

  strong(cell, row) {
    return (
      // <MaterialIcon icon="open_in_new" color='#0288d1'/>
      cell
    );
  }

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId
      ? this.props.match.params.sensorId
      : null;

    await this.getTags();
  }

  async getTags() {
    let endpoint = "/tags/" + sensorId;
    let api = await axios.getData(endpoint);
    this.setState({ sensors: api.data.data });
  }

  jump(url) {
    this.props.history.push(url);
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (row.firmwareConfig) {
          this.jump(
            "/v1/companies/" +
              companyId +
              "/sensors/" +
              row.sensorConfig.sensorId +
              "/tags/" +
              row.tagId
          );
        } else {
          this.jump(
            "/v1/companies/" +
              companyId +
              "/sensors/o/" +
              row.sensorConfig.sensorId +
              "/tags/" +
              row.tagId
          );
        }
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              <div
                onClick={() => {
                  this.jump("/v1/companies/" + companyId + "/sensors", 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
            </Col>
            <Col lg="6 table-caption">
              <h1>Select TagId</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.currentPage > 0 && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Sensor</h3>
                  </CardHeader>
                )}
                {this.state.sensors && this.state.currentPage < 1 && (
                  <ToolkitProvider
                    keyField="_id"
                    data={this.state.sensors}
                    columns={this.state.columns}
                    // pagination={ paginationFactory(options) }
                    // striped
                    // hover
                    search={{
                      searchFormatted: true,
                    }}
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          rowEvents={rowEvents}
                          keyField="_id"
                          data={this.state.sensors}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default SensorTags;
