import React from "react";
import { useCookies } from "react-cookie";

import axios from "../../axios";
import Helper from "../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class Forgot extends React.Component {
  constructor(props) {
    super(props);

    let t = Helper.isAuth() ? this.props.history.push("/v1/index") : null;
  }

  state = {
    message: null,
    saving: false,
    organizationId: "master",
  };

  componentDidMount() {
    // this.clickLogin();
  }

  handleInputChange = (input) => (event) => {
    this.setState({
      [input]: event.target.value,
      message: null,
    });
  };

  async clickReset() {
    let username = this.state.username;
    if (!username) {
      return this.setState({ message: "Email/Username is a required field" });
    }
    this.setState({ saving: true });
    let keyName = username.includes("@") ? "email" : "username";
    let data = {
      [keyName]: username,
    };
    let organizationId = this.state.organizationId;
    let api = await axios.fetchForgotPassword("master", data);
    if (api.status == 204) {
      //Redirect to login after a pop notification

      toast.success(
        "A Reset Link has been sent to your Email Address, click on the link to reset your password, Thank You!"
      );
      setTimeout(() => {
        this.props.history.push("/auth/login");
      }, 5000);

      //Saving user details to localStorage
    } else {
      this.setState({ saving: false });
      toast.warn(api.data);
    }
  }

  render() {
    return (
      <>
        <ToastContainer position="top-center" />
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            {/* <CardHeader className="bg-transparent pb-5">
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5">
              <div
                onClick={() => {
                  this.props.history.push("/auth/login");
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>

              <center>
                <h3>Reset your password</h3>
              </center>
              <br></br>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email/Username"
                    type="email"
                    onChange={this.handleInputChange("username")}
                  />
                </InputGroup>
              </FormGroup>

              <p className="text-center text-danger font-weight-500 h5">
                {this.state.message}
              </p>

              <div className="text-center">
                <Button
                  disabled={this.state.saving}
                  onClick={() => this.clickReset()}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default Forgot;
