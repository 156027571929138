import React from "react";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ImageUploader from "react-images-upload";
import "./equipments.css";
import axios from "../../../../axios";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";
import { fetchMachineDetails } from "../../../../redux/actions/machineType.action";
import { connect } from "react-redux";
import _ from "lodash";

const { SearchBar } = Search;

let companyId = "";
let machineId = "";

class EquipmentAdd extends React.Component {
  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  state = {
    equipments: null,
    equipment: {},
    equipmentSubTypes: {},
    equipmentTypes: [],
    equipmentType: null,
    machine: [],
    images: [],
    saving: false,
    groups: [],
    layout: 0,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    machineId = this.props.match.params.machineId;
   
    await this.getEquipmentTypeOptions(); //Get EquipmentType for equipment type filter
    await this.getGroupOptions(); //Get Group Options
    let apiCompnayDetails = await axios.companyManage_CompanyDetails(companyId); //layoutDetails

    this.setState({ layout: apiCompnayDetails.data.data.Layout });
    if (
      this.state.layout === 1 &&
      this.props.match.params.machineId === "0000"
    ) {
      this.getMachine();
    }
    if( this.props.match.params.machineId !== "0000"){
      this.props.fetchMachineDetails(companyId, machineId);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let equipmetData = {}
    if (nextProps.machineDetailsData !== prevState.machineDetailsData) {
      if (nextProps.machineDetailsData) {
        equipmetData =  {machineId: nextProps.machineDetailsData.data.machineId , 
        plantId: nextProps.machineDetailsData.data.plantId}
      }
    }
    return {equipment: {...prevState.equipment, ...equipmetData}}
  }

  async getEquipmentTypeOptions() {
    let equipmentTypes = await axios.equipmentType_get(companyId);
    let options = [];
    Object.keys(equipmentTypes.data.data).forEach((eq, i) => {
      if (eq !== "_id") {
        options.push({
          key: i,
          label: `${eq}`,
          value: eq,
        });
      }
    });

    this.setState({ equipmentTypes: options });
  }

  async getMachine(plantId = 0) {
    if (
      this.state.layout === 1 &&
      this.props.match.params.machineId === "0000"
    ) {
      let machineList = await axios.machineManage_machineList(companyId);
      let options = [];
      machineList.data.data.forEach((eq, i) => {
        options.push({
          key: i,
          label: `${eq.customName}`,
          value: eq.machineId,
        });
      });
      this.setState({ machine: options });
      return;
    }

    let machineList = await axios.machineManage_plantMachineList(plantId);
    let options = [];
    machineList.data.data.forEach((eq, i) => {
      options.push({
        key: i,
        label: `${eq.customName}`,
        value: eq.machineId,
      });
    });
    this.setState({ machine: options });
  }

  async getGroupOptions() {
    // let endpoint = "/groups/options/" + ;
    let groups = await axios.plantManage_plantList(companyId);
    let options = [];
    groups.data.data.forEach((group, i) => {
      options.push({
        key: i,
        label: `${group.customName}`,
        value: group.plantId,
      });
    });

    this.setState({ groups: options });
  }

  handleSelectChange =
    (field, type = "filter") =>
    async (option) => {
      if (type === "filter") {
        let equipmentType = option ? option.value : null;
        //await this.getEquipments(equipmentType);
      } else if (type === "add") {
        // let equipment = {...this.state.equipment};
        // equipment[field] = option.value;
        // this.setState({equipment});

        if (field === "plantId") {
          let equipment = { ...this.state.equipment };
          equipment.plantId = option.value;
          this.setState({ equipment });
          if (this.state.layout === 1) {
            this.getMachine(option.value);
          }
        } else if (field === "equipmentType") {
          let equipment = { ...this.state.equipment };
          equipment.equipmentTypeCode = option.value;
          this.setState({ equipment });
          this.setState({ equipmentTypeCode: option.value });
        } else if (field === "machineId") {
          let equipment = { ...this.state.equipment };
          equipment.machineId = option.value;
          this.setState({ equipment });
        }
      }
    };

  handleChange = (input) => (e) => {
    let equipment = { ...this.state.equipment };
    equipment[input] = e.target.value;
    this.setState({ equipment: equipment });
  };

  onDrop = (pictureFiles, pictureData) => {
    let equipment = { ...this.state.equipment };
    equipment.imagesUrl = JSON.stringify(pictureData);
    this.setState({ equipment: equipment });
  };

  async handleSave() {
    this.setState({ saving: true });
    let equipment = { ...this.state.equipment };
    //Validating
    let err = await validation.validate(equipment);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }
    if(this.state.layout === 0 && equipment.plantId === undefined){
      toast.warn('Plant is a required field');
      this.setState({ saving: false });
      return;
    }
    if(this.state.layout === 1 && equipment.machineId === undefined){
      toast.warn('Machine is a required field');
      this.setState({ saving: false });
      return;
    }
    equipment.companyId = companyId;
    if (machineId != "0000") equipment.machineId = machineId;

    if (!equipment.machineId) {
      if (this.state.layout === 0) {
        //createDummy machine
        let defaultMachineDetails = {
          customName: "default_machine",
          plantId: equipment.plantId,
          externalId: equipment.externalId
        };
        let api = await axios.machineManage_create(defaultMachineDetails);
        equipment["machineId"] = api.data.data.machineId;
      }
    }

    let tempImageUrl = equipment.imagesUrl;

    equipment = {
      customName: equipment.customName,
      equipmentType: equipment.equipmentTypeCode,
      machineId: parseInt(equipment.machineId),
      externalId: equipment.externalId
    };

    let api = await axios.equipmentManage_create(equipment);
    if (api.status === 200) {
      if (tempImageUrl) {
        let equipImage = {
          file: JSON.parse(tempImageUrl)[0],
          fileName: `${api.data.data.equipmentId}.jpg`,
          companyId: companyId,
        };
        let api2 = await axios.fileDataImage_create(equipImage);
      }

      toast.success("Equipment saved successfully");
      localStorage.removeItem("companyId");
      if (machineId === "0000") {
        //Equipment is not being added under a machine
        this.jump(
          `/v1/companies/${companyId}/machines/${machineId}/equipments`,
          2000
        );
      } else {
        //Equipment is being added under a machine
        this.jump(`/v1/companies/${companyId}/machines/${machineId}`, 2000);
      }
      this.setState({ list: true });
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  jump = (url, delay = 0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  render() {
    let backUrl = "";
    if (machineId === "0000")
      backUrl =
        "/v1/companies/" + companyId + "/machines/" + machineId + "/equipments";
    else backUrl = "/v1/companies/" + companyId + "/machines/" + machineId;

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <div
                      onClick={() => {
                        this.jump(backUrl);
                      }}
                      style={{
                        width: "2rem",
                        height: "2rem",
                        cursor: "pointer",
                      }}
                      className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                    >
                      <i
                        style={{ fontSize: "25px" }}
                        className="fa fa-arrow-left"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <Col xs="8">
                      <h3 className="mb-0">Add Equipment</h3>
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>

                {/* Add Equipment */}

                {
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipment Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Equipment Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              isClearable={false}
                              onChange={this.handleSelectChange(
                                "equipmentType",
                                "add"
                              )}
                              placeholder=""
                              options={this.state.equipmentTypes}
                            />
                          </FormGroup>
                        </Col>
                        {this.state.layout === 0 &&
                        this.props.match.params.machineId === "0000" ? (
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Plant
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable={false}
                                placeholder=""
                                options={this.state.groups}
                                onChange={this.handleSelectChange(
                                  "plantId",
                                  "add"
                                )}
                              />
                            </FormGroup>
                          </Col>
                        ) : null}
                        {this.state.layout === 1 &&
                        this.props.match.params.machineId === "0000" ? (
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Machine
                                <span className="text-danger">*</span>
                              </label>
                              <Select
                                isClearable={false}
                                placeholder=""
                                options={this.state.machine}
                                onChange={this.handleSelectChange(
                                  "machineId",
                                  "add"
                                )}
                              />{" "}
                            </FormGroup>
                          </Col>
                        ) : null}
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              External ID{" "}
                              <Tooltip
                                title="This is the ID used by Nanoprecise customers to identity this asset in their internal system, and it will make Nanoprecise integration with customer's software and APIs possible"
                                placement="right-start"
                              >
                                <Info
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    fontSize: "30px",
                                  }}
                                />
                              </Tooltip>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              type="text"
                              onChange={this.handleChange("externalId")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        ;
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <div className="image-container-add">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Equipment Image
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                onChange={this.onDrop}
                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                maxFileSize={2000000} //2MB
                                withPreview={true}
                                label="Max file size: 2MB, accepted: jpg|png"
                              />
                            </div>
                          </FormGroup>
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                }
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  machineDetailsData: _.isEmpty(state.machineType.machineDetailsData)
    ? null
    : state.machineType.machineDetailsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMachineDetails: (companyId, machineId) =>
      dispatch(fetchMachineDetails(companyId, machineId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentAdd);
