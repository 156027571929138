import React from "react";
import "./Shimmer.css";

const PiShimmer = () => {
  return (
<div className="pi-shimmer">
      <div className="rectangular-box-onepi">
        <div className="shimmer-effect"></div>
      </div>
      <div className="rectangular-box-onepi">
        <div className="shimmer-effect"></div>
      </div>
    </div>
  );
};

export default PiShimmer;