import React from "react";
import { connect } from "react-redux";
import axios from "../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import classes from "./mount.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";
import Helper from "../../../helper";

import { ToastContainer, toast } from "react-toastify";
import { fetchMountTypeDetails } from "../../../redux/actions/mountConfiguration.action";
import "react-toastify/dist/ReactToastify.css";

let mountTypes = [
  {
    key: 0,
    label: "Vertical",
    value: "vertical",
  },
  {
    key: 1,
    label: "Horizontal",
    value: "horizontal",
  },
  {
    key: 2,
    label: "Axial",
    value: "axial",
  },
];

let axis = [
  {
    key: 0,
    label: "X",
    value: "x",
  },
  {
    key: 1,
    label: "Y",
    value: "y",
  },
  {
    key: 2,
    label: "Z",
    value: "z",
  },
];

let id = "";
let portal_user = "";

class SensorDetails extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    saving: false,
    readOnly: true,
    process: {},
    //Navigations
    currentPage: 1,
    pages: [
      "pageSensorAdd",
      "pageSensorConfig",
      "pageProcessConfig",
      "pageMountConfig",
    ],
    mountConfiguration: {},
    createAllowed: true,
  };

  async componentDidMount() {
    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != "admin") {
      this.setState({ createAllowed: false });
    }

    id = this.props.match.params.id;
    this.props.fetchMountTypeDetails(id);
  }

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  remove = (obj, key) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.remove(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join(".")
      );
    }
    if (keySplit.length == 1) {
      // obj[key] = val;
      // console.log(obj[key]);
      delete obj[key];
      return obj[key];
    } else {
      // obj = val;
      return obj;
    }
  };

  handleRemove = (obj, key) => () => {
    let copy = { ...this.state[obj] };
    this.remove(copy, key);
    this.setState({ [obj]: copy });
  };

  add = (obj, key, field) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.add(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        field
      );
    }
    if (keySplit.length == 1 && keySplit[0] != "") {
      // obj[key] = val;
      return (obj[key][field] = "");
    } else {
      // obj = val;
      return (obj[field] = {});
    }
  };

  handleAddChange = (obj) => (e) => {
    this.setState({ [obj]: e.target.value });
  };

  handleAdd = (obj, key, type) => () => {
    let copy = { ...this.state[obj] };
    if (type === "field") {
      this.add(copy, key, this.state.field);
      this.setState({ [obj]: copy, add3: false, field: "" });
    } else if (type === "tab") {
      this.add(copy, key, this.state.tab);
      this.setState({ [obj]: copy, add1: false, tab: "" });
    }
  };

  handleChange =
    (param, input, type = "string") =>
    (e) => {
      let copy = { ...this.state[param] };
      if (type === "array") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, [val]);
      } else if (type === "checkbox") {
        this.reduce(copy, input, e.target.checked);
      } else if (type === "number") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, val);
      } else {
        this.reduce(copy, input, e.target.value);
      }
      console.log("[param]: copy",param, copy);
      this.setState({ [param]: copy });
    };

  handleSelectChange = (state, key) => async (option) => {
    let copy = { ...this.state[state] };
    copy[key] = option ? option.value : "";
    let defaultKey = "";
    let value = option ? option.value : "";
    let defaultValue = option ? option.key : 0;

    if (key === "mountType") {
      defaultKey = "defaultMountType";
    }

    if (key === "mountMapping.horizontal") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultHorizontal";
    }

    if (key === "mountMapping.vertical") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultVertical";
    }

    if (key === "mountMapping.axial") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultAxial";
    }
    console.log([state], copy);
    this.setState({ [state]: copy, [defaultKey]: defaultValue }, () => {
      console.log(this.state[state]);
    });
  };

  handleNext = async (page) => {
    if (this.state.currentPage === 2) {
      this.setState({ saving: true });

      let sensorConfigAPI = await this.saveSensorConfig({
        ...this.state.sensorMountInfoBackup,
        label: this.state.mountConfiguration.label,
        mountConfig: this.state.mountConfiguration.mountConfig,
        sensorMountInfo: this.state.sensorMountInfo,
      });
      if (sensorConfigAPI.status === 200) {
        toast.success("Mount Configuration saved successfully.");
        this.setState({ readOnly: true, currentPage: 1, saving: false });
        this.jump("/v1/mount-configs");
      } else if (sensorConfigAPI.status === 400) {
        toast.warning(sensorConfigAPI.message);
        this.setState({ saving: false });
      } else if (sensorConfigAPI.status === 500) {
        toast.warning("Something went wrong. Please try again.");
        this.setState({ saving: false });
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let process = { ...this.state.mountConfiguration };
        //Validating
        let err = await validation.validate(process);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleBack = (page) => {
    if (page === "pageSensorAdd") {
      this.setState({ list: true });
    }

    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  handleCancel() {
    this.setState({
      readOnly: true,
      sensor: this.state.sensorBackup,
    });
    // this.jump('/v1/sensors/' + companyId);
  }

  async saveSensorConfig(sensorConfig) {
    const tempVal = sensorConfig._id;
    delete sensorConfig._id;
    let sensorConfigAPI = await axios.mountConfig_update(tempVal, {
      label: sensorConfig.label,
      sensorMountInfo: sensorConfig.sensorMountInfo,
      mountConfig: sensorConfig.mountConfig
    });
    return {
      status: sensorConfigAPI.status,
      message: sensorConfigAPI.data.message,
    };
  }

  jump(url, delay = 1000) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.createAllowed && !prevState.readOnly) {//if edit on
      return;
    }

    if (nextProps.mountTypeDetailsData !== prevState.mountTypeDetailsData) {
      if (nextProps.mountTypeDetailsData) {
        let data = nextProps.mountTypeDetailsData.data;
        let mountConfiguration = {
          _id: data._id,
          mountConfig: data.mountConfig,
          description: data.description,
          label: data.label,
        };
        //Setting data for sensorMountConfig
        let sensorMountInfo = data.sensorMountInfo;

        let defaultMountType = mountTypes.findIndex(
          (x) => x.value === sensorMountInfo.mountType
        );
        let defaultHorizontal = axis.findIndex(
          (x) => x.value === sensorMountInfo.mountMapping.horizontal
        );
        let defaultVertical = axis.findIndex(
          (x) => x.value === sensorMountInfo.mountMapping.vertical
        );
        let defaultAxial = axis.findIndex(
          (x) => x.value === sensorMountInfo.mountMapping.axial
        );

        return {
          sensorMountInfo: data.sensorMountInfo,
          sensorMountInfoBackup: nextProps.mountTypeDetailsData.data,
          mountConfiguration: mountConfiguration,
          defaultHorizontal,
          defaultVertical,
          defaultAxial,
          defaultMountType,
        };
      }
    }
  }

  render() {
    let label = "";
    if (this.state.currentPage === 1) label = "Mount Configuration";
    else if (this.state.currentPage === 2) label = "Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Mount Configuration";

    let front = "";
    if (this.state.currentPage === 1) front = "Configuration";
    else if (this.state.currentPage === 2)
      front = "Mount Process Configuration";
    else if (this.state.currentPage === 3) front = "Mount Mount Configurations";
    else if (this.state.currentPage === 4) front = "Submit";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <div
                    onClick={() => {
                      this.jump("/v1/mount-configs", 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>

                  <Row className="align-items-center">
                    <Col xs="8">
                      {this.state.currentPage > 0 && (
                        <h3 className="mb-0">{label}</h3>
                      )}
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnly && this.state.createAllowed && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.setState({ readOnly: false })}
                          size="md"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Mount Configuration
                                <small className="text-info"></small>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={
                                  this.state.mountConfiguration.mountConfig
                                }
                                onChange={this.handleChange(
                                  "mountConfiguration",
                                  "mountConfig"
                                )}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Description
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-about-company"
                              // placeholder="A few words about company ..."
                              rows="8"
                              type="textarea"
                              value={this.state.mountConfiguration.description}
                              onChange={this.handleChange('mountConfiguration', 'description')}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col> */}
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <div className="pl-lg-4">
                        <>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Mount Type
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountType"
                                  )}
                                  defaultValue={
                                    mountTypes[this.state.defaultMountType]
                                  }
                                  options={mountTypes}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Label
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  disabled={this.state.readOnly}
                                  id="input-username"
                                  type="text"
                                  defaultValue={
                                    this.state.mountConfiguration.label
                                  }
                                  onChange={this.handleChange(
                                    "mountConfiguration",
                                    "label"
                                  )}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Horizontal
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.horizontal"
                                  )}
                                  defaultValue={
                                    axis[this.state.defaultHorizontal]
                                  }
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Vertical
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.vertical"
                                  )}
                                  defaultValue={
                                    axis[this.state.defaultVertical]
                                  }
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Axial
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.axial"
                                  )}
                                  defaultValue={axis[this.state.defaultAxial]}
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      </div>
                    )}

                    {this.state.currentPage <= 2 && (
                      <Row>
                        <Col lg="12">
                          {this.state.currentPage < 2 && !this.state.saving && (
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                            >
                              {front}
                              <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                <i className="fa fa-forward" />
                              </div>
                            </Button>
                          )}

                          {!this.state.readOnly &&
                            this.state.currentPage === 2 && (
                              <Button
                                className="right"
                                color="success"
                                onClick={() =>
                                  this.handleNext(
                                    this.state.pages[this.state.currentPage + 1]
                                  )
                                }
                                size="md"
                                disabled={this.state.saving}
                              >
                                {this.state.saving ? (
                                  <i className="fas fa-spinner fa-pulse"></i>
                                ) : (
                                  <>
                                    Save
                                    <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                      <i className="fa fa-paper-plane" />
                                    </div>
                                  </>
                                )}
                              </Button>
                            )}

                          {this.state.currentPage > 1 && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="info"
                              onClick={() =>
                                this.handleBack(
                                  this.state.pages[this.state.currentPage]
                                )
                              }
                              size="md"
                            >
                              <div className="icon custom-icon icon-shape bg-info text-white rounded-circle shadow">
                                <i className="fa fa-backward" />
                              </div>
                              {back}
                            </Button>
                          )}

                          {!this.state.readOnly && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.handleCancel()}
                              size="md"
                            >
                              Cancel
                              <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fa fa-ban" />
                              </div>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  mountTypeDetailsData: _.isEmpty(state.mountConfiguration.mountTypeDetailsData)
    ? null
    : state.mountConfiguration.mountTypeDetailsData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMountTypeDetails: (id) => dispatch(fetchMountTypeDetails(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorDetails);

// export default SensorDetails;
