import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducers from './redux/reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['lang', 'sensorTypes']
};

const reducers = persistReducer(persistConfig, rootReducers);
const middlewares = [thunk];
const composeEnhancers = composeWithDevTools({ realtime: true });

const store = createStore(
    reducers,
    undefined,
    composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

export { store, persistor };