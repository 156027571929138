import React from "react";
import axios from "../../../axios";
import { connect } from "react-redux";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import classes from "./firmware.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";
import Helper from "../../../helper";

import { ToastContainer, toast } from "react-toastify";

import { fetchFirmwareTypesDetails } from "../../../redux/actions/firmwareTypes.action";
import { fetchSensorTypes } from "../../../redux/actions/sensorTypes.action";

import "react-toastify/dist/ReactToastify.css";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

let companyId = "";
let firmwareId = "";
let portal_user = "";

class FirmwareDetails extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    list: true,
    saving: false,
    readOnly: true,
    firmware: {},
    sensorTypes: [],
    tagOptions: [],
    //Navigations
    currentPage: 1,
    pages: [
      "pageSensorAdd",
      "pageSensorConfig",
      "pageProcessConfig",
      "pageMountConfig",
    ],

    vibration: { sensor: "vibration" },
    ultrasonic: { sensor: "ultrasonic" },
    temperature: { sensor: "temperature" },
    humidity: { sensor: "humidity" },
    adcConvertion: { adcConvertion: false },
    hasAdcConvertion: false,
    createAllowed: true,
    firmwareConfigurations: [],
    add: false,
    field: "",
    checked: true,
  };

  async componentDidMount() {
    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != "admin") {
      this.setState({ createAllowed: false });
    }

    firmwareId = this.props.match.params.id;

    this.props.fetchFirmwareTypesDetails(firmwareId);

    this.props.fetchSensorTypes();
  }

  async getSensor() {
    let api = await axios.getFirmwareType_byId(firmwareId);
    let data = api.data.data;
    let firmware = {
      id: data._id,
      firmwareType: data.firmwareType,
      sensorType: data.sensorType,
    };

    this.setState({ firmware: firmware, firmware: firmware });

    //Seting data for sensorConfig
    let firmwareConfigurations = data.firmwareConfigurations;
    this.setState({ firmwareConfigurations });
    let sensors = ["vibration", "ultrasonic", "temperature", "humidity"];
    this.setState({ sensors });
  }

  async getSensorTypes() {
    let api = await axios.getSensorTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.sensorType,
        value: sen._id,
      });
    });

    if (firmwareId) {
      let index = options.findIndex(
        (x) => x.value === this.state.firmware.sensorType
      );
      this.setState({
        defaultSensorType: index,
        defaultSensorTypeBackup: index,
      });
    }

    this.setState({ sensorTypes: options });
  }

  handleRemove = (obj, index, key) => () => {
    let copy = [...this.state[obj]];
    delete copy[index][key];
    this.setState({ [obj]: copy });
  };

  add = (obj, key, field) => {
    // console.log(obj);
    // return;
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.add(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        field
      );
    }

    if (keySplit.length == 1 && keySplit[0] != "") {
      // obj[key] = val;
      return (obj[key][field] = "");
    } else {
      // obj = val;
      return (obj[field] = "");
    }
  };

  handleAdd = (obj, index, key) => () => {
    let copy = { ...this.state[obj] };
    this.add(copy[index], key, this.state.field);
    this.setState({ add: false, field: "" });
  };

  handleAddChange = (obj) => (e) => {
    this.setState({ [obj]: e.target.value });
  };

  handleChange =
    (obj, key, index = -1, type = "string") =>
    (e) => {
      let copy = { ...this.state[obj] };
      let value = type === "string" ? e.target.value : e.target.checked;

      if (index >= 0) {
        copy[index][key] = value;
        let config = [...this.state[obj]];
        this.setState({ [obj]: config });
      } else {
        copy[key] = value;
        this.setState({ [obj]: copy });
      }
    };

  handleNext = async (page) => {
    if (this.state.currentPage === 2) {
      this.setState({ saving: true });

      //Firmware API
      let firmware = { ...this.state.firmware };
      firmware["_id"] = firmwareId;
      if (firmware && firmware.id) {
        delete firmware.id;
      }
      firmware.firmwareConfigurations = [...this.state.firmwareConfigurations];
      let sensorConfigAPI = await axios.update_FirmwareType(firmware);
      if (sensorConfigAPI.status === 200) {
        toast.success("Firmware type saved successfully.");
        this.setState({ readOnly: true, currentPage: 1, saving: false });
        this.jump("/v1/firmware-types");
      } else if (sensorConfigAPI.status === 400) {
        toast.warning(sensorConfigAPI.message);
        this.setState({ saving: false });
      } else if (sensorConfigAPI.status === 500) {
        toast.warning("Something went wrong. Please try again.");
        this.setState({ saving: false });
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let firmware = { ...this.state.firmware };
        //Validating
        let err = await validation.validate(firmware);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleBack = (page) => {
    if (page === "pageSensorAdd") {
      this.setState({ list: true });
    }
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  handleCancel() {
    this.setState({
      readOnly: true,
      sensor: this.state.sensorBackup,
    });
    // this.jump('/v1/sensors/' + companyId);
  }

  async saveFirmware(firmware) {
    let sensorAPI = await axios.postData(
      "/firmware-types/",
      firmware,
      this.state.firmware.id
    );
    return {
      status: sensorAPI.status,
      message: sensorAPI.data.message,
    };
  }

  jump(url, delay = 1000) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.firmwareTypesDetailsData !== prevState.firmwareTypesDetailsData
    ) {
      if (nextProps.firmwareTypesDetailsData) {
        let data = nextProps.firmwareTypesDetailsData.data;
        let firmware = {
          id: data._id,
          firmwareType: data.firmwareType,
          sensorType: data.sensorType,
        };

        //Seting data for sensorConfig
        let firmwareConfigurations = data.firmwareConfigurations;
        let sensors = ["vibration", "ultrasonic", "temperature", "humidity"];
        return {
          firmware,
          firmwareConfigurations,
          sensors,
        };
      }
    }

    if (nextProps.sensorTypesData !== prevState.sensorTypesData) {
      if (nextProps.sensorTypesData) {
        let options = [],
          index = 0;
        nextProps.sensorTypesData.sensorTypesData.data.forEach((sen, i) => {
          options.push({
            key: i,
            label: sen.sensorType,
            value: sen._id,
          });
        });

        if (firmwareId) {
          index = options.findIndex(
            (x) => x.value === prevState.firmware.sensorType
          );
          // this.setState({
          //   defaultSensorType: index,
          //   defaultSensorTypeBackup: index,
          // });
        }

        return {
          sensorTypes: options,
          defaultSensorType: index,
          defaultSensorTypeBackup: index,
        };
      }
    }
  }

  render() {
    let label = "";
    if (this.state.currentPage === 1) label = "Firmware Type";
    else if (this.state.currentPage === 2) label = "Firmware Configurations";
    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Firmware Type";

    let front = "";
    if (this.state.currentPage === 1) front = "Firmware Configuration";
    else if (this.state.currentPage === 2) front = "Submit";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6"></Col>
            <Col lg="6 table-caption">
              <h1>Firmware Type Details</h1>
            </Col>
          </Row>

          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <div
                    onClick={() => {
                      this.jump("/v1/firmware-types", 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnly && this.state.createAllowed && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.setState({ readOnly: false })}
                          size="md"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Firmware Type:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.firmware.firmwareType}
                                onChange={this.handleChange(
                                  "firmware",
                                  "firmwareType"
                                )}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          {/* <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Sensor Type:
                            </label>
                            <Select
                                isClearable={false}
                                isDisabled={this.state.readOnly}
                                onChange={this.handleSelectChange('firmware', 'sensorType')}
                                // defaultValue={this.state.sensorTypes[this.state.defaultSensorId]}
                                value={this.state.sensorTypes[this.state.defaultSensorType]}
                                options={this.state.sensorTypes}
                            />
                          </FormGroup>
                        </Col> */}
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <div className="pl-lg-4">
                        <Tabs>
                          <TabList>
                            {this.state.sensors.length &&
                              this.state.sensors.map((tab, i) => (
                                <Tab key={i}>
                                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                </Tab>
                              ))}
                            {/* <Tab>ADC Convertion</Tab> */}
                          </TabList>
                          <br />
                          {this.state.firmwareConfigurations.length &&
                            this.state.firmwareConfigurations.map(
                              (field, j) => {
                                if (field != "sensor") {
                                  return (
                                    <TabPanel key={j}>
                                      {Object.keys(field).map((key) => {
                                        if (key != "sensor") {
                                          return (
                                            <Row key={key + j}>
                                              <Col lg="1"></Col>
                                              <Col lg="3">
                                                <FormGroup>
                                                  <label className="form-control-label">
                                                    {key}:
                                                  </label>
                                                </FormGroup>
                                              </Col>
                                              {key != "adcConversion" ? (
                                                <Col lg="6">
                                                  <FormGroup>
                                                    {
                                                      <Input
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        type="text"
                                                        value={field[key]}
                                                        readOnly={
                                                          this.state.readOnly
                                                        }
                                                        onChange={this.handleChange(
                                                          "firmwareConfigurations",
                                                          key,
                                                          j
                                                        )}
                                                      />
                                                    }
                                                  </FormGroup>
                                                </Col>
                                              ) : (
                                                <Col lg="6">
                                                  <FormGroup>
                                                    {
                                                      <Input
                                                        className="form-control-alternative"
                                                        id="input-check"
                                                        type="checkbox"
                                                        checked={field[key]}
                                                        readOnly={
                                                          this.state.readOnly
                                                        }
                                                        onChange={this.handleChange(
                                                          "firmwareConfigurations",
                                                          key,
                                                          j,
                                                          "checkbox"
                                                        )}
                                                      />
                                                    }
                                                  </FormGroup>
                                                </Col>
                                              )}
                                              <Col lg="2">
                                                {!this.state.readOnly && (
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={this.handleRemove(
                                                      "firmwareConfigurations",
                                                      j,
                                                      key
                                                    )}
                                                    className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                                                  >
                                                    <i className="fa fa-times" />
                                                  </div>
                                                )}
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      })}

                                      <Row>
                                        <Col mc="1"></Col>
                                        <Col md="11">
                                          {!this.state.readOnly && (
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                this.setState({ add: true });
                                              }}
                                              className="icon custom-icon rem-icon icon-shape bg-info text-white rounded-circle shadow"
                                            >
                                              <i className="fa fa-plus" />
                                            </div>
                                          )}
                                        </Col>
                                      </Row>

                                      {this.state.add && (
                                        <>
                                          <Col className="add-tab" md="12">
                                            <Col md="6">
                                              <FormGroup>
                                                <label className="form-control-label">
                                                  Key name:
                                                </label>
                                                <Input
                                                  className="form-control-alternative"
                                                  id="input-username"
                                                  type="text"
                                                  value={this.state.field}
                                                  onChange={this.handleAddChange(
                                                    "field"
                                                  )}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <Button
                                                className={classes.deleteImage}
                                                color="danger"
                                                onClick={() => {
                                                  this.setState({
                                                    add: false,
                                                    field: "",
                                                  });
                                                }}
                                                size="sm"
                                              >
                                                Cancel
                                              </Button>

                                              <Button
                                                className={classes.deleteImage}
                                                color="success"
                                                onClick={this.handleAdd(
                                                  "firmwareConfigurations",
                                                  j,
                                                  ""
                                                )}
                                                size="sm"
                                              >
                                                Add
                                              </Button>
                                            </Col>
                                            <br />
                                          </Col>
                                        </>
                                      )}
                                    </TabPanel>
                                  );
                                }
                              }
                            )}
                        </Tabs>
                      </div>
                    )}

                    {this.state.currentPage <= 2 && (
                      <Row>
                        <Col lg="12">
                          {this.state.currentPage < 2 && !this.state.saving && (
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                            >
                              {front}
                              <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                <i className="fa fa-forward" />
                              </div>
                            </Button>
                          )}

                          {!this.state.readOnly &&
                            this.state.currentPage === 2 && (
                              <Button
                                className="right"
                                color="success"
                                onClick={() =>
                                  this.handleNext(
                                    this.state.pages[this.state.currentPage + 1]
                                  )
                                }
                                size="md"
                                disabled={this.state.saving}
                              >
                                {this.state.saving ? (
                                  <i className="fas fa-spinner fa-pulse"></i>
                                ) : (
                                  <>
                                    Save
                                    <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                      <i className="fa fa-paper-plane" />
                                    </div>
                                  </>
                                )}
                              </Button>
                            )}

                          {this.state.currentPage > 1 && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="info"
                              onClick={() =>
                                this.handleBack(
                                  this.state.pages[this.state.currentPage]
                                )
                              }
                              size="md"
                            >
                              <div className="icon custom-icon icon-shape bg-info text-white rounded-circle shadow">
                                <i className="fa fa-backward" />
                              </div>
                              {back}
                            </Button>
                          )}

                          {!this.state.readOnly && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.handleCancel()}
                              size="md"
                            >
                              Cancel
                              <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fa fa-ban" />
                              </div>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  firmwareTypesDetailsData: _.isEmpty(
    state.firmwareTypes.firmwareTypesDetailsData
  )
    ? null
    : state.firmwareTypes.firmwareTypesDetailsData,
  sensorTypesData: _.isEmpty(state.sensorTypes) ? null : state.sensorTypes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFirmwareTypesDetails: (firmwareId) =>
      dispatch(fetchFirmwareTypesDetails(firmwareId)),
    fetchSensorTypes: () => dispatch(fetchSensorTypes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareDetails);

// export default FirmwareDetails;
