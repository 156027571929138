import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import { Provider } from "react-redux";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./configureStore";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";

import QRPrint from "./views/examples/Companies/Sensors/QRPrint";

import CompanyProfile from "./views/examples/Companies/CompanyProfile";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Switch>
          <Route path="/v1" render={(props) => <AdminLayout {...props} />} />
          {/* <Route path="/v1/admin" render={props => <AdminLayout {...props} />} /> */}
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/print" component={QRPrint} />
          <Redirect from="/" to="/v1/index" />
        </Switch>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
