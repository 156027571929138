import React from "react";
import axios from "../../../../axios";
import Select from "react-select";
import _ from "lodash";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertCompilerOptionsFromJson } from "typescript";

let companyId = "";
let sensorId = "";

class SensorAssignConfig extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    sensorTypeId: null,
    firmwareTypeId: null,
  };

  async componentDidMount() {
    console.log(this.props.location.state);
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId
      ? this.props.match.params.sensorId
      : null;
    await this.getSensorTypes(); //All the sensor types
    await this.getFirmwareTypes(); //All the firmware types
  }

  async getSensorTypes() {
    let api = await axios.getSensorTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.sensorType,
        value: sen._id,
        id: sen._id,
      });
    });

    if (sensorId) {
      let index = options.findIndex((x) => x.value === this.state.sensorType);
      this.setState({
        defaultSensorType: index,
        defaultSensorTypeBackup: index,
      });
    }
    console.log("options:", options);
    this.setState({ sensorTypes: options });
  }

  async getFirmwareTypes() {
    let api = await axios.getFirmwareTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.firmwareType,
        value: sen._id,
        id: sen._id,
      });
    });

    if (sensorId) {
      let index = options.findIndex((x) => x.value === this.state.firmwareType);
      this.setState({
        defaultFirmwareType: index,
        defaultFirmwareTypeBackup: index,
      });
    }

    this.setState({ firmwareTypes: options });
  }

  handleSelectChange =
    (state, key = null) =>
    async (option) => {
      console.log(`key`, state);
      return;
      let copy = { ...this.state[state] };
      if (key) {
        copy[key] = option ? option.value : "";
      } else {
        copy = option.value;
      }
    };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6 ">
              <div
                onClick={() => {
                  this.jump("/v1/companies/" + companyId + "/sensors", 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
            </Col>
            <Col lg="6 table-caption">
              <h1>Add Configurations</h1>
            </Col>
          </Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              {/* Add Sensor */}
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="12"></Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col lg="6">
                    {this.props.location.state.showSensDropDown ? (
                      <Select
                        placeholder={"Select Sensor Type"}
                        isClearable={false}
                        isDisabled={false}
                        onChange={(e) => {
                          console.log(e);
                          this.setState({ sensorTypeId: e.value });
                        }}
                        // value={this.state.sensorTypes[this.state.defaultSensorType]}
                        options={this.state.sensorTypes}
                      />
                    ) : null}
                  </Col>
                  <Col lg="6">
                    {this.props.location.state.showFrimwareDropDown ? (
                      <Select
                        placeholder={"Select Firmware Type"}
                        isClearable={false}
                        isDisabled={false}
                        onChange={(e) => {
                          console.log(e);
                          this.setState({ firmwareTypeId: e.value });
                        }}
                        // value={this.state.firmwareTypes[this.state.defaultFirmwareType]}
                        options={this.state.firmwareTypes}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col lg="11"></Col>
                  <Col lg="1">
                    <Button
                      style={{ background: "#df7126", borderColor: "#df7126" }}
                      color="primary"
                      onClick={async () => {
                        let api1 = null,
                          api2 = null;
                        console.log(`asdfgjhgfds`);
                        if (this.state.firmwareTypeId) {
                          //call post API
                          let endpoint = "/sensors/saveMDFirmwareTypeConfig";
                          api1 = await axios.postData(endpoint, {
                            companyId,
                            sensorId,
                            firmwareTypeId: this.state.firmwareTypeId,
                          });
                        }

                        if (this.state.sensorTypeId) {
                          //call post API
                          let endpoint = "/sensors/saveMDSensorTypeConfig";
                          api2 = await axios.postData(endpoint, {
                            companyId,
                            sensorId,
                            sensorTypeId: this.state.sensorTypeId,
                          });
                        }
                        this.jump("/v1/companies/" + companyId + "/sensors", 0);
                      }}
                      size="xs"
                    >
                      Done
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Container>
      </>
    );
  }
}

export default SensorAssignConfig;
