import {
  FETCH_ACTIVITIES_DATA,
  FETCH_EVENTS_DATA,
  FETCH_COMPANY_DATA,
} from "../actions/activities.action";

const homeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACTIVITIES_DATA:
      return {
        ...state,
        activitiesData: action.payload,
      };
    case FETCH_EVENTS_DATA:
      return {
        ...state,
        eventsData: action.payload,
      };
    case FETCH_COMPANY_DATA:
      return {
        ...state,
        companiesData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default homeReducer;
