import * as yup from "yup";

const validations = {
  async validate(object) {
    let error = "";
    let schema = yup.object().shape({
      organizationName: yup
        .string()
        // .required()
        .matches(/([A-Za-z]+)/, "Only numbers are not allowed for Organization Name field ")
        .min(4)
        .label("Organization Name"),
    });

    //validating against schema
    try {
      console.log({ object });
      await schema.validate(object, { abortEarly: false, strict: true });
    } catch (err) {
      error = err.errors[0];
    }
    return error;
  },
};

export default validations;
