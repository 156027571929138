import * as yup from "yup";

let validation = {
  async validate(object) {
    let error = "";

    //Defining schema
    let schema = yup.object().shape({
      userName: yup.string().required().min(2).label("Username"),
      email: yup.string().required().email().label("Email"),
      fullName: yup.string().required().label("Full Name"),
    });

    //validating against schema
    try {
      await schema.validate(object, { abortEarly: false, strict: true });
    } catch (err) {
      error = err.errors[0];
    }
    return error;
  },
};

export default validation;
