import * as yup from 'yup';

let validation = {

    async validate(object) {

        let error = '';

        //Defining schema
        let schema = yup.object().shape({
          file: yup.string().required().label('File'),
        });

        //validating against schema
        try {
            await schema.validate(object, {abortEarly: false, strict: true});
          } catch(err) {
            error =  err.errors[0];
          }
          return error;
    }
}

export default validation;