import {
  FETCH_EULA_INFO_DATA,
  FETCH_EULA_INFO_DETAILS_DATA,
} from "../actions/eulaInfo.action";

const eulaInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EULA_INFO_DATA:
      return {
        ...state,
        eulaInfoData: action.payload,
      };
    case FETCH_EULA_INFO_DETAILS_DATA:
      return {
        ...state,
        eulaInfoDetailsData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default eulaInfoReducer;
