import * as yup from 'yup';

const validation = {

    async validate(object) {
        let error = '';

        //Defining schema
        let schema = yup.object().shape({
            firmwareType: yup.string().required().trim().label('Firmware Type'),
            sensorType: yup.string().label('Sensor Type'),
        });

        try {
            await schema.validate(object, {abortEarly: false, strict: true});
        } catch(err) {
            error = err.errors[0];
        }
        return error;
    }
}

export default validation;