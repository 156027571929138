import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
// node.js library that concatenates classes (strings)
import { withStyles } from "@material-ui/core/styles";
// javascipt plugin for creating charts
import Chart from "chart.js";
import { ToastContainer, toast } from "react-toastify";
// react plugin used to create charts
import "./index.css";
import Helper from "../helper";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";
import axios from "../axios";
import validation from "./validation";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { fetchStatsData } from "../redux/actions/home.action";
import { fetchReport } from "../redux/actions/report.action";
import { fetchRequestUserData } from "../redux/actions/home.action";

import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { AlertTwoTone, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import Select from "react-select";
import { fetchOrganizationsList } from "../redux/actions/organization.action";
import userValidation from "./examples/Companies/Users/validation";
import { makeStyles } from "@material-ui/core/styles";
import GraphShimmer from "../components/Shimmer/GraphShimmer";
import PiShimmer from "../components/Shimmer/PiShimmer";
const options = {
  showTotal: true,
};
const { SearchBar } = Search;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

let portal_user = "";

let startDate = moment()
  .subtract(30, "days")
  .toISOString();
let endDate = moment().toISOString();
class Index extends React.Component {
  state = {
    isCookie: Helper.getCookie("userPortalAuth"),
    flag: true,
    companies: 0,
    startDate: moment()
      .subtract(30, "days")
      .toISOString(),
    endDate: moment().toISOString(),
    sensorsMounted: 0,
    scanners: 0,
    delayedCDP: 0,
    open: false,
    manual: {
      fileName: "",
      file: "",
      updatedAt: "",
      isVisible: "",
    },
    notification_manual: {
      fileName: "",
      file: "",
      updatedAt: "",
      isVisible: "",
    },
    isFeedbackConfigManual: false,
    isExpanded: true,
    uploading: false,
    isUploadDisabled: true,
    loading: true,
    toggling: false,
    manualTypeName: "",
    manualTypeCode: "",
    homePageData: null,
    organizationNumber: null,
    userColoumns: [
      {
        text: "Id",
        dataField: "_id",
        sort: true,
      },
      {
        text: "Company Name",
        dataField: "companyName",
        sort: true,
      },
      {
        text: "Full Name",
        dataField: "fullName",
        sort: true,
      },
      {
        text: "Email",
        dataField: "emailAddress",
        sort: true,
      },
      {
        text: "Phone Number",
        dataField: "phoneNumber",
        sort: true,
      },
      {
        text: "Status",
        dataField: "status",
        sort: true,
      },

      {
        text: "Approve",
        dataField: "_id",
        formatter: this.actionApprove,
        sort: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            if (row.status === "pending") {
              this.approve(row);
            }
          },
        },
      },
      {
        text: "disapprove",
        dataField: "_id",
        formatter: this.actionDisapprove,

        sort: true,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            if (row.status === "pending") {
              this.disapprove(row);
            }
          },
        },
      },
    ],
    companyOptions: [],

    openUser: false,
    openCompanies: false,
    openProjectReport: false,
    maintenanceTypeList: [
      {
        val: "preventive",
        name: "Preventive",
        desc: "Preventive",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "corrective",
        name: "Corrective",
        desc: "Corrective",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "safety",
        name: "Safety",
        desc: "Safety",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "upgrade",
        name: "Upgrade",
        desc: "Upgrade",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "electrical",
        name: "Electrical",
        desc: "Electrical",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "project",
        name: "Project",
        desc: "Project",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "inspection",
        name: "Inspection",
        desc: "Inspection",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "meterReading",
        name: "Meter Reading",
        desc: "Meter Reading",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "other",
        name: "Other",
        desc: "Other",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
    ],
    isCreateMaintenanceType: false,
    homePageData: null,
    maintenanceTypeName: null,
    maintenanceTypeDesc: null,
    maintenanceTypeVal: null,
  };

  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  async componentDidMount() {
    if (this.state.isCookie) {
      this.props.history.push("/v1/index");
    } else {
      this.props.history.push("/auth/login");
    }
    // let endpoint = "/feedbackLog/maintenanceTypes";
    // let maintenanceTypes = await axios.getData(endpoint);
    // this.setState({ maintenanceTypeList: maintenanceTypes.data.data });

    await this.getDashboardStats();
    await axios.fetchUserInfo("master");
    await this.getManuals();

    if (this.state.delayedCDP > 0) {
      setInterval(() => {
        var div = document.getElementById("delay-process");
        // console.log(div)
        if (div !== null) {
          div.style.backgroundColor = this.state.flag ? "yellow" : "white";
          this.setState({ flag: !this.state.flag });
        }
      }, 1000);
    }
  }

  deleteCookie = (name) => {
    return (document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
  };
  clearAuth = () => {
    this.deleteCookie("userPortalAuth");
  };
  logout = () => {
    Helper.clearAuth();
    this.clearAuth();
    localStorage.removeItem("companyId");
    this.props.history.push("/auth/login/");
  };

  async getDashboardStats() {
    this.props.fetchStatsData();
    this.props.fetchOrganizationsList();
  }

  async getRequestUserDetails() {
    this.props.fetchRequestUserData();
  }

  handleCompany = (e) => {
    this.setState({ userCompany: e });
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleProjectClose = () => {
    this.setState({ openProjectReport: false });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  handleFileInput = async (manualType, e) => {
    if (e.target.files[0] && e.target.files[0].size > 2000000) {
      alert("File Size should be less than 2MB");
      return;
    }
    let fileName = e.target.files[0] ? e.target.files[0].name : "";
    let file = "";
    if (fileName) {
      file = await this.toBase64(e.target.files[0]);
    }

    let manual = { ...this.state[manualType] };
    manual.fileName = fileName;
    manual.file = file;
    this.setState({ [manualType]: manual, isUploadDisabled: false });
  };

  async handleSave(manualTypeCode) {
    this.setState({ uploading: true });
    let manual = { ...this.state[manualTypeCode] };
    //Validating
    let err = await validation.validate(manual);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    manual.updatedAt = Math.floor(Date.now() / 1000);
    manual.resourceType = manualTypeCode;
    manual.isVisible = this.state[manualTypeCode].isVisible;

    let api;

    api = await axios.Post_Manual("dashboard-manuals", manual);
    this.setState({ saving: false });
    if (api && api.status == 200) {
      toast.success("Data saved successfully");
      let manual = { ...api.data.data };
      manual.updatedAt = moment
        .unix(parseInt(manual.updatedAt))
        .format("DD, MMMM, YYYY h:mm: A");
      this.setState({
        uploading: false,
        editing: false,
        [this.state.manualTypeCode]: manual,
      });
      this.handleClose();
    } else if (api && api.status == 400) {
      toast.warn(api.data.message);
    } else if (api && api.status == 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  async handleUserSave() {
    this.setState({ saving: true });
    let { userCompany, approveRow } = this.state;
    let user = {};
    user.companyId = userCompany.value;
    user.userGroupId = 2000;
    user.fullName = approveRow.fullName;
    user.email = approveRow.emailAddress;
    user.userName = approveRow.fullName;
    user.phoneNumber = approveRow.phoneNumber;
    user.requestUserId = approveRow._id;
    //Validating
    let testGroup = { ...user };
    let err = await userValidation.validate(testGroup);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    let endpoint = "/users";
    let api = await axios.postData(endpoint, user);
    this.setState({ saving: false });
    if (api.status == 200) {
      toast.success("User saved successfully");
      this.getRequestUserDetails();
      this.setState({ openCompanies: false });
    } else if (api.status == 400) {
      this.setState({ openCompanies: false });

      toast.warn(api.data.message);
    } else if (api.status === 500) {
      this.setState({ openCompanies: false });

      toast.error("Something went wrong. Please try again.");
    }
  }

  downloadPDF = () => {
    const downloadLink = document.createElement("a");
    const linkSource = this.state[this.state.manualTypeCode].file;
    const fileName = this.state[this.state.manualTypeCode].fileName;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  jump(url) {
    this.props.history.push(url);
  }

  getManuals = async () => {
    //Dashboard Manual
    let manual = await axios.get_Manual("manual");
    let notification_manual = await axios.get_Manual("notification_manual");
    if (manual && manual.data && manual.data.data) {
      manual.data.data.updatedAt = moment
        .unix(parseInt(manual.data.data.updatedAt))
        .format("DD, MMMM, YYYY h:mm: A");
    }

    // Notification Manual
    if (
      notification_manual &&
      notification_manual.data &&
      notification_manual.data.data
    ) {
      notification_manual.data.data.updatedAt = moment
        .unix(parseInt(notification_manual.data.data.updatedAt))
        .format("DD, MMMM, YYYY h:mm: A");
    }

    this.setState({
      manual: manual && manual.data && manual.data.data,
      loading: false,
      notification_manual:
        notification_manual &&
        notification_manual.data &&
        notification_manual.data.data,
    });
  };

  handleStartDateChange = (date) => {
    if (date === null) {
      startDate = moment()
        .subtract(30, "days")
        .toISOString();
    } else {
      this.setState(
        { startDate: date && date.toISOString() },
        () => {
          startDate = this.state.startDate;
        }
      );
    } 
  };

  handleEndDateChange = (date) => {
    if (date === null) {
      endDate = moment().toISOString();
    } else {
      this.setState(
        { endDate: date && date.toISOString() },
        () => {
          endDate = this.state.endDate;
        }
      );
    }
  };

  getReport = async () => {
    if (startDate > endDate || endDate < startDate) {
      toast.warn("From date should be less than To date");
      return;
    } else {
      //Report
      await axios
        .fetchReport(startDate, endDate, { responseType: "blob" })
        .then((res) => {
          if(res && res.data && res.data.status==="accepted"){
          toast.success("Success! You will shortly receive a csv report on your registered email.");}
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  openModal = (type_name, type_code) => {
    // type: manual => for dashboard Manual, notification_manual => for notification Manual
    this.setState({
      manualTypeName: type_name,
      manualTypeCode: type_code,
      open: true,
    });
  };

  approve = (row) => {
    this.setState({
      openCompanies: true,
      approveRow: row,
    });
  };
  disapprove = async (row) => {
    this.setState({
      approveRow: row,
    });
    if (window.confirm("Are you sure to Disapprove the request")) {
      let endpoint = "/requestUser/disapprove/" + row._id;
      let api = await axios.postData(endpoint, {});
      this.setState({ saving: false });
      if (api.status == 200) {
        toast.success("User disapproved successfully");
        this.getRequestUserDetails();
        this.setState({ openCompanies: false });
      } else if (api.status == 400) {
        this.setState({ openCompanies: false });
        toast.warn(api.data.message);
      } else if (api.status === 500) {
        this.setState({ openCompanies: false });
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  actionApprove(cell, row, rowIndex) {
    let _this = this;
    return (
      <Button
        color={row.status === "pending" ? "success" : "secondary"}
        disabled={row.status === "pending" ? false : true}
      >
        <CheckOutlined />
      </Button>
    );
  }
  actionDisapprove(cell, row) {
    let _this = this;
    return (
      <Button
        color={row.status === "pending" ? "danger" : "secondary"}
        disabled={row.status === "pending" ? false : true}
      >
        <CloseOutlined />
      </Button>
    );
  }

  handleToggle = async () => {
    this.setState({ toggling: true });
    let manual = {
      isVisible: !this.state[this.state.manualTypeCode].isVisible,
      resourceType: this.state.manualTypeCode,
      domain: window.location.hostname,
    };
    //API call
    let api = await axios.Post_Manual("dashboard-manuals", manual);

    this.setState({ saving: false });
    if (api.status == 200) {
      toast.success("Data saved successfully");
      let manual = { ...api.data.data };
      manual.updatedAt = moment
        .unix(parseInt(manual.updatedAt))
        .format("DD, MMMM, YYYY h:mm: A");
      this.setState({
        uploading: false,
        editing: false,
        [this.state.manualTypeCode]: manual,
        toggling: false,
      });
      // this.handleClose();
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let delayedCDP = 0;
    let homePageData = null;
    let organizationNumber = null;
    let maintenanceTypeList = [
      {
        val: "preventive",
        name: "Preventive",
        desc: "Preventive",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "corrective",
        name: "Corrective",
        desc: "Corrective",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "safety",
        name: "Safety",
        desc: "Safety",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "upgrade",
        name: "Upgrade",
        desc: "Upgrade",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "electrical",
        name: "Electrical",
        desc: "Electrical",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "project",
        name: "Project",
        desc: "Project",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "inspection",
        name: "Inspection",
        desc: "Inspection",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "meterReading",
        name: "Meter Reading",
        desc: "Meter Reading",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
      {
        val: "other",
        name: "Other",
        desc: "Other",
        resetFaultDetection: false,
        maintenanceWatch: false,
        noAction: true,
      },
    ];
    if (nextProps.homePageData !== prevState.homePageData) {
      if (nextProps.homePageData) {
        return {
          homePageData: nextProps.homePageData,
          delayedCDP:
            nextProps.homePageData.statsData &&
            nextProps.homePageData.statsData.data &&
            nextProps.homePageData.statsData.data.delayedCDP,
          requestUsers:
            nextProps.homePageData.statsData &&
            nextProps.homePageData.statsData.data &&
            nextProps.homePageData.statsData.data.requestUsers,
          organizationCount:
            nextProps.homePageData.statsData &&
            nextProps.homePageData.statsData.data &&
            nextProps.homePageData.statsData.data.organization,
          requestUsersDetails:
            nextProps.homePageData.requestUsersDetails &&
            nextProps.homePageData.requestUsersDetails.data &&
            nextProps.homePageData.requestUsersDetails.data.requestUsers,
        };
      }
    }

    if (nextProps.companyData !== prevState.companyData) {
      if (nextProps.companyData) {
        let companies = [];
        nextProps.companyData.data &&
          nextProps.companyData.data.map((x, i) => {
            let obj = {
              key: i,
              label: x.companyId,
              value: x.companyId,
            };
            companies.push(obj);
          });
        return {
          companyOptions: companies,
        };
      }
    }

    return {
      homePageData:
        homePageData !== null ? homePageData : prevState.homePageData,
      delayedCDP: delayedCDP !== 0 ? delayedCDP : prevState.delayedCDP,
      maintenanceTypeList:
        maintenanceTypeList !== null
          ? maintenanceTypeList
          : prevState.maintenanceTypeList,
      organizationNumber: nextProps.organizationNumber
        ? nextProps.organizationNumber
        : 0,
    };
  }

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  render() {
    const { classes } = this.props;

    let spinner = (
      <i
        style={{ float: "right", marginTop: "10px" }}
        className="fas fa-spinner fa-pulse"
      ></i>
    );
    return (
      <>
        <ToastContainer position="top-left" />
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                {/* add organisation */}
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.jump("/v1/organizations");
                      }}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Organizations
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.homePageData !== null
                              ? this.state.homePageData.statsData &&
                                this.state.homePageData.statsData.data &&
                                this.state.homePageData.statsData.data
                                  .organization
                              : 0}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fa fa-address-book" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.jump("/v1/companies");
                      }}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Companies
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.homePageData !== null
                              ? this.state.homePageData.statsData &&
                                this.state.homePageData.statsData.data &&
                                this.state.homePageData.statsData.data.companies
                              : 0}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fa fa-university" />
                          </div>
                        </Col>
                      </Row>
                      {/* <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span>
                      </p> */}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Sensors Mounted
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.homePageData !== null
                              ? this.state.homePageData &&
                                this.state.homePageData.statsData &&
                                this.state.homePageData.statsData.data &&
                                this.state.homePageData.statsData.data.sensors
                              : 0}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-microchip" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {(portal_user && portal_user.department.slug == "admin") ||
                "root" ||
                "cmteam" ? (
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody
                        style={{ cursor: "pointer", height: "80px" }}
                        onClick={() => {
                          this.jump("/v1/companies/ALL/feedback");
                        }}
                      >
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Feedback
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0"></span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fa fa-book" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ) : null}

                {/* <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.jump("/v1/scanners");
                      }}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            New Scanner Data
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.homePageData !== null
                              ? this.state.homePageData &&
                                this.state.homePageData.statsData &&
                                this.state.homePageData.statsData.data &&
                                this.state.homePageData.statsData.data.scanners
                              : 0}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fa fa-qrcode" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>

              <Row className="row-home">
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer", height: "80px" }}
                      onClick={this.openModal.bind(
                        this,
                        "Dashboard Manual",
                        "manual"
                      )}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Dashboard Manual
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0"></span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fa fa-book" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer", height: "80px" }}
                      onClick={this.openModal.bind(
                        this,
                        "Notification Manual",
                        "notification_manual"
                      )}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Notification Manual
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0"></span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fa fa-book" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer", height: "80px" }}
                      onClick={() => {
                        this.jump("/v1/manual");
                      }}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Upload Manuals
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fa fa-university" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      id="delay-process"
                      style={{ cursor: "pointer", height: "80px" }}
                      onClick={() => {
                        this.jump("/v1/eula");
                      }}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Eula Info
                          </CardTitle>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fa fa-university" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="row-home">
                {this.state.delayedCDP > 0 ? (
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody
                        id="delay-process"
                        style={{ cursor: "pointer", height: "80px" }}
                        onClick={() => {
                          this.jump("/v1/delayedCDP");
                        }}
                      >
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Delayed Data
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.homePageData !== null
                                ? this.state.homePageData &&
                                  this.state.homePageData.statsData &&
                                  this.state.homePageData.statsData.data &&
                                  this.state.homePageData.statsData.data
                                    .delayedCDP
                                : 0}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fa fa-cogs" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ) : null}

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer", height: "80px" }}
                      onClick={() => {
                        this.setState({
                          openProjectReport: true,
                        });
                      }}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Project Report
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0"></span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fa fa-book" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {/* feature is closed */}
                {/* <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody
                      style={{ cursor: "pointer", height: "80px" }}
                      onClick={() => {
                        this.jump("/v1/qr-export");
                      }}
                    >
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            QR & Label Generation
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0"></span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fa fa-file" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </div>
          </Container>
        </div>

        <Dialog
          maxWidth="lg"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.state.manualTypeName}

            {this.state.toggling ? spinner : ""}
            {this.state.open && (
              <FormControlLabel
                style={{ float: "right" }}
                control={
                  <Switch
                    checked={this.state[this.state.manualTypeCode].isVisible}
                    onChange={this.handleToggle}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Dashboard rendering"
              />
            )}
          </DialogTitle>
          <DialogContent dividers>
            {this.state.loading ? (
              <PiShimmer />
            ) : (
              <div>
                <Button
                  style={{ float: "right" }}
                  color="primary"
                  onClick={() => {
                    this.setState({
                      editing: !this.state.editing,
                      isUploadDisabled: true,
                    });
                  }}
                  size="sm"
                >
                  {this.state.editing ? "Cancel" : "Edit"}
                </Button>
                <FormGroup>
                  {this.state.open && (
                    <>
                      <p>
                        Last Updated:{" "}
                        <span style={{ color: "#233dd2" }}>
                          {this.state[this.state.manualTypeCode].updatedAt}
                        </span>{" "}
                      </p>
                      <p>
                        Download Manual:{" "}
                        <a onClick={this.downloadPDF} href="#">
                          {this.state[this.state.manualTypeCode].fileName}
                        </a>
                      </p>
                    </>
                  )}
                  {this.state.editing && (
                    <Input
                      className="form-control-alternative"
                      id="input-username"
                      type="file"
                      accept="application/pdf, application/vnd.ms-excel"
                      onChange={this.handleFileInput.bind(
                        this,
                        this.state.manualTypeCode
                      )}
                    />
                  )}
                </FormGroup>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            {this.state.editing && !this.state.isUploadDisabled && (
              <Button
                size="md"
                autoFocus
                onClick={() => this.handleSave(this.state.manualTypeCode)}
                color="success"
              >
                {this.state.uploading ? (
                  <i className="fas fa-spinner fa-pulse"></i>
                ) : (
                  "Upload"
                )}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="lg"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.openUser}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            User Requests
          </DialogTitle>
          <DialogContent dividers>
            {this.state.requestUsersDetails &&
              this.state.requestUsersDetails.length > 0 && (
                <ToolkitProvider search>
                  {(props) => (
                    <div>
                      <SearchBar {...props.searchProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        // rowEvents={rowEvents}
                        pagination={paginationFactory(options)}
                        keyField="_id"
                        data={this.state.requestUsersDetails}
                        columns={this.state.userColoumns}
                        striped
                        hover
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
          </DialogContent>
        </Dialog>
        <Dialog
          // maxWidth="lg"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.openCompanies}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            User Requests
          </DialogTitle>
          <DialogContent dividers>
            <br></br>
            <Select
              // isClearable={true}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              onChange={this.handleCompany}
              value={this.state.userCompany}
              options={this.state.companyOptions}
              // isMulti={true}
            />
            <br></br>
          </DialogContent>
          <DialogActions>
            <Button
              size="md"
              autoFocus
              onClick={() => this.handleUserSave()}
              color="success"
            >
              Submit
            </Button>

            <Button
              size="md"
              autoFocus
              onClick={() => this.handleClose()}
              color="danger"
            >
              Close{" "}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          // maxWidth="lg"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.openProjectReport}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Project Report
          </DialogTitle>
          <DialogContent dividers>
            <br></br>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-date">
                    From Date
                  </label>
                  <DatePicker
                    popupStyle
                    className="startDate"
                    disabledDate={this.disabledDate}
                    placeholder={moment()
                      .subtract(30, "days")
                      .format("YYYY-MM-DD")}
                    onChange={(date) => {
                      this.handleStartDateChange(date);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-date">
                    To Date
                  </label>
                  <DatePicker
                    className="endDate"
                    disabledDate={this.disabledDate}
                    placeholder={moment().format("YYYY-MM-DD")}
                    onChange={(date) => {
                      this.handleEndDateChange(date);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br></br>
            <Button
              size="md"
              autoFocus
              color="success"
              onClick={this.getReport}
            >
              Download
            </Button>
          </DialogContent>
          <DialogActions>
            <Button
              size="md"
              autoFocus
              onClick={() => this.handleProjectClose()}
              color="danger"
            >
              Close{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  homePageData: _.isEmpty(state.home) ? null : state.home,
  companyData: _.isEmpty(state.activities.companiesData)
    ? null
    : state.activities.companiesData,
  maintenanceTypesData: _.isEmpty(state.feedback) ? null : state.feedback,
  organizationNumber: _.isEmpty(
    state.organization.companyData && state.organization.companyData.data
  )
    ? null
    : state.organization.companyData.data.length,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStatsData: (obj) => dispatch(fetchStatsData()),
    fetchRequestUserData: (obj) => dispatch(fetchRequestUserData()),
    fetchOrganizationsList: () => dispatch(fetchOrganizationsList()),
    fetchReport: () => dispatch(fetchReport()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Index));
