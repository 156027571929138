import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchCompanyScanner } from "../../../../redux/actions/companies.action";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ImageUploader from "react-images-upload";
import "./equipments.css";
import axios from "../../../../axios";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;

const columns = [
  {
    text: "Id",
    dataField: "_id",
    sort: true,
  },
  {
    text: "Plant Name",
    dataField: "plantName",
    sort: true,
  },
  {
    text: "Equipment Name",
    dataField: "equipmentName",
    sort: true,
  },
  {
    text: "Component Name",
    dataField: "componentName",
    sort: true,
  },
  {
    text: "Sensor Id",
    dataField: "sensorID",
    sort: true,
  },
  // {
  //   text: 'Equipment Type',
  //   dataField: 'equipmentTypeData.equipmentType',
  //   sort: true
  // }
];

const options = {
  showTotal: true,
};

let companyId = "";
let machineId = "";

class Scanners extends React.Component {
  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login", 0);
    }
  }

  state = {
    scanners: null,
    saving: false,
    status: [
      {
        key: 0,
        label: "Pending",
        value: "pending",
      },
      {
        key: 1,
        label: "Approved",
        value: "approved",
      },
      {
        key: 2,
        label: "Disapproved",
        value: "disapproved",
      },
    ],
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;

    // await this.getScanners('pending'); //Get Scanners
    this.props.fetchCompanyScanner(companyId, "pending");
  }

  async getScanners(status) {
    let endpoint = "/scanners/" + companyId + "?status=" + status;
    let scanners = await axios.getData(endpoint);
    this.setState({ scanners: scanners.data.data });
  }

  handleSelectChange = () => async (option) => {
    // await this.getScanners(option.value);
    this.props.fetchCompanyScanner(companyId, option.value);
  };

  jump = (url, delay = 1000) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.scannerData !== prevState.scannerData) {
      if (nextProps.scannerData) {
        return {
          scanners: nextProps.scannerData.data,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/companies/" + companyId + "/scanners/" + row._id, 0);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row>
            <Col lg="12" className="table-caption">
              <h1>Scanner App Data</h1>
            </Col>
          </Row>
          <Row>
            <Col lg="8">
              <div
                onClick={() => {
                  this.jump("/v1/companies/" + companyId, 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
            </Col>

            <Col lg="4">
              <Select
                className="add-btn"
                onChange={this.handleSelectChange("status")}
                placeholder="Status"
                options={this.state.status}
                defaultValue={this.state.status[0]}
              />
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.scanners && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={this.state.equipments}
                    search
                  >
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="_id"
                          rowEvents={rowEvents}
                          data={this.state.scanners}
                          columns={columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  scannerData: _.isEmpty(state.companies.scannerData)
    ? null
    : state.companies.scannerData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanyScanner: (companyId, status) =>
      dispatch(fetchCompanyScanner(companyId, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Scanners);
