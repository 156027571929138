import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Sidebar2 from "components/Sidebar/Sidebar2.jsx";

import routes from "routes.js";

let not_allowed = [];

class Admin extends React.Component {
  state = {
    portal_user: null,
    menu: null,
  };

  componentDidMount() {
    let portal_user = JSON.parse(localStorage.getItem("portal-user"));
    this.setState({ portal_user: portal_user });

    not_allowed = portal_user ? portal_user.department.notAllowed : [];

    let menu = [];
    routes.map((route) => {
      if (route.hasOwnProperty("id")) {
        if (!not_allowed.includes(route.id)) {
          menu.push(route);
        }
      } else {
        menu.push(route);
      }
    });

    this.setState({ menu: menu });
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.hasOwnProperty("id")) {
        if (!not_allowed.includes(prop.id)) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key + 1}
            />
          );
        }
      } else {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key + 1}
          />
        );
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brands";
  };

  render() {
    return (
      <>
        {this.state.menu && (
          <Sidebar
            {...this.props}
            routes={this.state.menu}
            portal_user={this.state.portal_user}
            logo={{
              innerLink: "/v1/index",
              imgSrc: require("assets/img/brand/logo.png"),
              imgAlt: "...",
            }}
          />
        )}
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
