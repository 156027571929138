import CallAPI from "../../axios";

export const FETCH_EMPLOPEES_DATA = "FETCH_EMPLOPEES_DATA";
export const FETCH_EMPLOPEES_DETAILS_DATA = "FETCH_EMPLOPEES_DETAILS_DATA";

export const FETCH_DEPARTMENTS_DATA = "FETCH_DEPARTMENTS_DATA";

const fetchEmployeesData = (data) => {
  return {
    type: FETCH_EMPLOPEES_DATA,
    payload: data,
  };
};

const fetchEmployeesDetailsData = (data) => {
  return {
    type: FETCH_EMPLOPEES_DETAILS_DATA,
    payload: data,
  };
};

const fetchDepartmentsData = (data) => {
  return {
    type: FETCH_DEPARTMENTS_DATA,
    payload: data,
  };
};

export const fetchEmployee = () => {
  return (dispatch) => {
    CallAPI.getData(`/employees`)
      .then((response) => {
        dispatch(fetchEmployeesData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEmployeeDetails = (id) => {
  return (dispatch) => {
    CallAPI.getData(`/employees/${id}`)
      .then((response) => {
        dispatch(fetchEmployeesDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchDepartment = () => {
  return (dispatch) => {
    CallAPI.getData(`/employee-departments`)
      .then((response) => {
        dispatch(fetchDepartmentsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
