import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchCompanyScannerDetails } from "../../../../redux/actions/companies.action";
import { fetchEquipmentType } from "../../../../redux/actions/equipmentType.action";
import { fetchComponentType } from "../../../../redux/actions/componentTypes.action";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImageUploader from "react-images-upload";
import BootstrapTable from "react-bootstrap-table-next";
import TableHeaderColumn from "react-bootstrap-table-next";
import InsertButton from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import MaterialIcon, { colorPalette } from "material-icons-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import CustomDialog from "./CustomDialog";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import classes from "./equipments.css";
import axios from "../../../../axios";
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

//
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

//
//Datatable configurations for components

const options = {
  showTotal: true,
};

//Datatable configurations end
let companyId = "";
let sensorId = "";

class ScannerDetails extends React.Component {
  constructor(props) {
    super(props);
    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  state = {
    openDelete: false,
    scanner: {
      _id: "",
      companyId: "",
      plantId: "",
      equipmentId: "",
      userId: "",
      sensorID: "",
      qrCodeID: "",
      companyName: "",
      description: "",
      plantName: "",
      equipmentName: "",
      componentName: "",
      componentRPM: "",
      componentBearingMake: "",
      componentBearingNumber: "",
      componentGearbox: "",
      componentTemp: "",
      noOfVanes: "",
      config: {},
      addEquipmentType: "",
      addComponentType: "",
      approvedBy: {
        name: "",
        email: "",
      },
    },
    readOnly: true,
    saving: false,
    savingArchive: false,
    fetched: false,
    selectedEquipment: 0,
    selectedComponent: 0,
    equipmentTypeArray: [],
    componentTypeArray: [],
    openDialog: false,
    disapprove: {
      reason: "",
      user: "",
    },
    status: "",
    componentImage: null,
    equipmentImage: null,
    componentFileName: null,
    equipmentFileName: null,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId;

    // await this.getScanner();
    this.props.fetchCompanyScannerDetails(companyId, sensorId);
    this.props.fetchEquipmentType();
    this.props.fetchComponentType();
    await this.getImages("equipment");
    await this.getImages("component");
  }

  async getImages(resourceType) {
    const Image = await axios.getData(
      `/utilities/imageUpload/${this.state.scanner.sensorID}/${resourceType}`
    );
    console.log(Image);
    resourceType === "component"
      ? this.setState({
          componentImage: Image.data.file,
          componentFileName: Image.data.fileName,
        })
      : this.setState({
          equipmentImage: Image.data.file,
          equipmentFileName: Image.data.fileName,
        });
  }

  async getScanner(componentType = null) {
    let endpoint = "/scanners/" + companyId + "/" + sensorId;
    let scanner = await axios.getData(endpoint);
    let equipmentType = await axios.getData("/equipments/types");
    let componentTypeDetails = await axios.getData("/components/types");

    let result = equipmentType.data.data;
    let componentResult = componentTypeDetails.data.data;

    let equipmentArray = [];
    for (var i = 0; i < result.length; i++) {
      equipmentArray.push({
        key: i,
        label: result[i].customName + "->" + result[i].equipmentTypeCode,
        value: result[i].equipmentType,
      });
    }

    let componentArray = [];
    for (var i = 0; i < componentResult.length; i++) {
      componentArray.push({
        key: i,
        label:
          componentResult[i].customName +
          "->" +
          componentResult[i].componentTypeCode,
        value: componentResult[i].componentType,
      });
    }

    let status = "";
    if (scanner.data.data.isAccepted) status = "Approved";
    if (scanner.data.data.isArchived) status = "Disapproved";
    if (!scanner.data.data.isArchived && !scanner.data.data.isAccepted)
      status = "Pending";

    let scannerData = { ...scanner.data.data };
    scannerData.addEquipmentType = "MR100";
    scannerData.addComponentType = "MRMND";
    this.setState({
      status: status,
      scanner: scannerData,
      equipmentTypeArray: equipmentArray,
      componentTypeArray: componentArray,
      scannerBackup: scanner.data.data,
      fetched: true,
    });
  }

  handleChange = (state, key) => (e) => {
    let subject = { ...this.state[state] };
    Helper.reduce(subject, key, e.target.value);
    this.setState({ [state]: subject });
  };

  handleEdit(edit) {
    this.setState({ readOnly: edit });
  }

  handleCancel() {
    let scannerBackup = { ...this.state.scannerBackup };
    this.setState({
      scanner: scannerBackup,
      readOnly: true,
    });
  }

  handleUnassigned = async (event) => {
    let scanner = { ...this.state.scanner };
    scanner.unassignId = event.target.checked;
    this.setState({
      scanner: scanner,
    });

    // /**
    //    **toArrayBuffer
    //    * @description converts a image buffer to an buffer array
    //    * @param {*} myBuf image buffer to be changed into buffer array
    //    * @returns buffer array - myBuffer
    //    */
    //   toArrayBuffer = async (myBuf) => {
    //     var myBuffer = new ArrayBuffer(myBuf.length);
    //     var res = new Uint8Array(myBuffer);
    //     for (var i = 0; i < myBuf.length; ++i) {
    //         res[i] = myBuf[i];
    //     }
    //     return myBuffer;
    // }

    // let company = { ...this.state.company };
    // company.notificationToBeChecked = event.target.checked.toString();
    // if (company.active === "false") {
    //   return;
    // }
    // const companyDup = company;
    // delete companyDup._id;
    // let api = await axios.updateCompany(companyDup, this.state.company._id);
    // if (api.status === 200) {
    //   toast.success("Company Notification status set succesfully!");
    //   company._id = api.data.data._id;
    //   company.notificationToBeChecked = api.data.data.notificationToBeChecked;
    //   this.setState({ company });
    // } else if (api.status === 400) {
    //   toast.warning(api.data.message);
    // } else if (api.status === 500) {
    //   toast.danger("Something went wrong. Please try again.");
  };

  // handleSelectChange = (type, key=null) => async (option) => {
  //   if (type === 'equipment') {
  //     let equipment = {...this.state.equipment};
  //     if (key === 'groupCode') {
  //       equipment.groupCode = option.value;
  //       this.setState({equipment, defaultOptionGroup: option.key});
  //     }
  //     if (key === 'equipmentType') {
  //       equipment.equipmentType = option.value;
  //       this.setState({equipment, defaultOption: option.key});
  //     }
  //   } else if (type === 'component') {
  //     let selectedComponentType = option ? option.value : null
  //     await this.getComponents(selectedComponentType);
  //   }
  // }

  async handleSave() {
    let portalUser = JSON.parse(localStorage.getItem("portal-user"));

    const report = {
      companyId: this.state.scanner.companyId,
      name: portalUser.name,
      equipmentId: this.state.scanner.equipmentId,
      componentFile: this.state.componentImage,
      componentFileName: this.state.componentFileName,
      equipmentFileName: this.state.equipmentFileName,
      equipmentFile: this.state.equipmentImage,
      timestamp: parseInt(new Date().getTime() / 1000),
    };

    const reportAPI = await axios.postData("/reports/scannerReport", report);

    //
    if (this.state.scanner.unassignId === true) {
      const bodyData = { tagId: this.state.scanner.config.tagId };
      const saveAPI = await axios.postData("/sensors/unassign", bodyData);
      // if (this.state.scanner.isAccepted === true) {
      const deleteEntry = await axios.deleteReq(
        `/scanners/equipmentremove/${this.state.scanner.sensorID}`
      );
      // }
    }
    //

    if (
      this.state.scanner.newEquipment === true ||
      this.state.scanner.newComponent === true
    ) {
      this.setState({ openDelete: true });
    } else {
      this.setState({ saving: true });
      let scanner = {
        ...this.state.scanner,
      };
      if (scanner.isArchived === true) {
        scanner.isArchived = false;
      }

      scanner.approvedBy = {
        name: portalUser.name,
        email: portalUser.email,
      };

      let endpoint = "/scanners";
      let api = await axios.postData(endpoint, scanner, this.state.scanner._id);
      if (api.status == 200) {
        toast.success("Data saved successfully");
        let backUrl = "/v1/companies/" + companyId + "/scanners";
        localStorage.removeItem("companyId");
        this.jump(backUrl, 1000);
      } else if (api.status === 400) {
        toast.warn(api.data.message);
      } else if (api.status === 500) {
        toast.error("Something went wrong. Please try again.");
      }
      this.setState({ saving: false });
    }
  }

  async handleArchive() {
    this.setState({ savingArchive: true });
    let disapprove = { ...this.state.disapprove };

    let portalUser = JSON.parse(localStorage.getItem("portal-user"));
    disapprove.user = {
      name: portalUser.name,
      email: portalUser.email,
    };

    let endpoint = "/scanners/archive/" + sensorId;
    let api = await axios.postData(endpoint, disapprove);
    if (api.status == 200) {
      toast.success("Data archived successfully");
      let backUrl = "/v1/companies/" + companyId + "/scanners";
      localStorage.removeItem("companyId");
      this.jump(backUrl, 1000);
    } else if (api.status === 400) {
      toast.warn(api.data.message);
      this.closeDialog();
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  jump = (url, delay = 0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  handleEquipmentChange = (text) => {
    // console.log(text.value);
    this.state.scanner.addEquipmentType = text.value;
    this.setState({ selectedEquipment: text.key });
  };

  handleComponentChange = (text) => {
    // console.log(text.value);
    this.state.scanner.addComponentType = text.value;
    this.setState({ selectedComponent: text.key });
  };

  async handleSelect() {
    this.setState({ saving: true });
    let scanner = {
      ...this.state.scanner,
    };

    let portalUser = JSON.parse(localStorage.getItem("portal-user"));
    scanner.approvedBy = {
      name: portalUser.name,
      email: portalUser.email,
    };

    let endpoint = "/scanners";
    let api = await axios.postData(endpoint, scanner, this.state.scanner._id);
    if (api.status == 200) {
      toast.success("Data saved successfully");
      let backUrl = "/v1/companies/" + companyId + "/scanners";
      localStorage.removeItem("companyId");
      this.jump(backUrl, 1000);
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  action(cell, row) {
    let _this = this;
    return <MaterialIcon icon="open_in_new" color="#0288d1"></MaterialIcon>;
  }

  openDialog = () => {
    this.setState({
      openDialog: true,
    });
  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let equipmentArray = [],
      componentArray = [],
      status = "",
      scannerData = {},
      scannerBackup = {};

    if (nextProps.scannerDetailsData !== prevState.scannerDetailsData) {
      if (nextProps.scannerDetailsData) {
        scannerBackup = nextProps.scannerDetailsData.data;
        if (nextProps.scannerDetailsData.data.isAccepted) {
          status = "Approved";
        }
        if (nextProps.scannerDetailsData.data.isArchived) {
          status = "Disapproved";
        }
        if (
          !nextProps.scannerDetailsData.data.isArchived &&
          !nextProps.scannerDetailsData.data.isAccepted
        ) {
          status = "Pending";
        }
        scannerData = { ...nextProps.scannerDetailsData.data };
        scannerData.addEquipmentType = "MR100";
        scannerData.addComponentType = "MRMND";
      }
    }

    if (nextProps.componentTypeData !== prevState.componentTypeData) {
      if (nextProps.componentTypeData) {
        for (var i = 0; i < nextProps.componentTypeData.data.length; i++) {
          componentArray.push({
            key: i,
            label:
              nextProps.componentTypeData.data[i].customName +
              "->" +
              nextProps.componentTypeData.data[i].componentTypeCode,
            value: nextProps.componentTypeData.data[i].componentType,
          });
        }
      }
    }

    if (nextProps.equipTypeData !== prevState.equipTypeData) {
      if (nextProps.equipTypeData) {
        for (var i = 0; i < nextProps.equipTypeData.data.length; i++) {
          equipmentArray.push({
            key: i,
            label:
              nextProps.equipTypeData.data[i].customName +
              "->" +
              nextProps.equipTypeData.data[i].equipmentTypeCode,
            value: nextProps.equipTypeData.data[i].equipmentType,
          });
        }
      }
    }

    return {
      status: status,
      scanner: scannerData,
      equipmentTypeArray: equipmentArray,
      componentTypeArray: componentArray,
      scannerBackup,
      fetched: true,
    };
  }

  render() {
    let backUrl = "/v1/companies/" + companyId + "/scanners";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6" className="table-caption">
              {/* <h1>Equipments</h1> */}
            </Col>
            <Col lg="6 table-caption">
              <h1>Scanner Data</h1>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump(backUrl, 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="10"></Col>
                      <Col lg="2">
                        {this.state.scanner.config &&
                          this.state.fetched &&
                          (this.state.status === "Disapproved" ||
                            this.state.status === "Pending") && (
                            <Button
                              color="primary"
                              onClick={() => {
                                this.setState({
                                  readOnly: !this.state.readOnly,
                                });
                              }}
                              size="sm"
                            >
                              {this.state.readOnly ? "Edit" : "Done"}
                            </Button>
                          )}
                      </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                      <Col lg="6">
                        <div className="inline">Status: </div>
                        <h4 className={"inline " + this.state.status}>
                          {this.state.status}
                          {this.state.scanner.isAccepted &&
                            " (" +
                              this.state.scanner.approvedBy.name +
                              "/" +
                              this.state.scanner.approvedBy.email +
                              ")"}

                          {this.state.scanner.isArchived &&
                            " (" +
                              this.state.scanner.disapprove.user.name +
                              "/" +
                              this.state.scanner.disapprove.user.email +
                              ")"}
                        </h4>
                      </Col>
                      <br></br>
                      <Col lg="6">
                        {this.state.scanner.isArchived && (
                          <>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              <div className="inline Disapproved">Reason: </div>
                              <h4 className="inline Disapproved">
                                {this.state.scanner.disapprove.reason}
                              </h4>
                            </label>
                          </>
                        )}
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Company Name: </div>
                          <h4 className="inline orange">
                            {this.state.scanner.companyName}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.companyName}
                              onChange={this.handleChange(
                                "scanner",
                                "companyName"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          <div className="inline">Plant Name: </div>
                          <h4 className="inline orange">
                            {this.state.scanner.plantName}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.plantName}
                              onChange={this.handleChange(
                                "scanner",
                                "plantName"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Equipment Name: </div>
                          <h4 className="inline orange">
                            {this.state.scanner.equipmentName}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={
                                this.state.scanner.equipmentName === ""
                                  ? "NA"
                                  : this.state.scanner.equipmentName
                              }
                              onChange={this.handleChange(
                                "scanner",
                                "equipmentName"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Component Name: </div>
                          <h4 className="inline orange">
                            {this.state.scanner.componentName}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={
                                this.state.scanner.componentName === ""
                                  ? "NA"
                                  : this.state.scanner.componentName
                              }
                              onChange={this.handleChange(
                                "scanner",
                                "componentName"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="10">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Description: </div>
                          <h4 className="inline orange">
                            {this.state.scanner.description}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              style={{ maxWidth: "700px" }}
                              className="form-control-alternative"
                              value={
                                this.state.scanner.description === ""
                                  ? "NA"
                                  : this.state.scanner.description
                              }
                              onChange={this.handleChange(
                                "scanner",
                                "description"
                              )}
                              type="textarea"
                            />
                          )}
                        </label>
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="4">
                        <h4 className=" text-muted mb-4 inline">SensorId: </h4>{" "}
                        <h4 className="configs inline">
                          {this.state.scanner.sensorID}
                        </h4>
                      </Col>
                      {/* <hr className='my-4' /> */}
                      <Col lg="8">
                        {this.state.scanner.config && (
                          <>
                            <h4 className=" text-muted mb-4 inline">
                              {" "}
                              assigned to tagId:{" "}
                            </h4>{" "}
                            <h4 className="configs inline">
                              {this.state.scanner.config.tagId === ""
                                ? "NA"
                                : this.state.scanner.config.tagId}
                            </h4>
                          </>
                        )}
                      </Col>
                    </Row>

                    <br></br>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Component RPM: </div>
                          <h4 className="inline configs">
                            {this.state.scanner.componentRPM === ""
                              ? "NA"
                              : this.state.scanner.componentRPM}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentRPM}
                              onChange={this.handleChange(
                                "scanner",
                                "componentRPM"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Component Bearing Make: </div>
                          <h4 className="inline configs">
                            {this.state.scanner.componentBearingMake === ""
                              ? "NA"
                              : this.state.scanner.componentBearingMake}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentBearingMake}
                              onChange={this.handleChange(
                                "scanner",
                                "componentBearingMake"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">
                            Component Bearing Number:{" "}
                          </div>
                          <h4 className="inline configs">
                            {this.state.scanner.componentBearingNumber === ""
                              ? "NA"
                              : this.state.scanner.componentBearingNumber}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentBearingNumber}
                              onChange={this.handleChange(
                                "scanner",
                                "componentBearingNumber"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Component Gearbox: </div>
                          <h4 className="inline configs">
                            {this.state.scanner.componentGearbox === ""
                              ? "NA"
                              : this.state.scanner.componentGearbox}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentGearbox}
                              onChange={this.handleChange(
                                "scanner",
                                "componentGearbox"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Component Temperature: </div>
                          <h4 className="inline configs">
                            {this.state.scanner.componentTemp === ""
                              ? "NA"
                              : this.state.scanner.componentTemp}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentTemp}
                              onChange={this.handleChange(
                                "scanner",
                                "componentTemp"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>

                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Number of Vanes: </div>
                          <h4 className="inline configs">
                            {!this.state.scanner.noOfVanes
                              ? "NA"
                              : this.state.scanner.noOfVanes}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.noOfVanes}
                              onChange={this.handleChange(
                                "scanner",
                                "noOfVanes"
                              )}
                              type="text"
                            />
                          )}
                        </label>
                      </Col>
                    </Row>
                    <br></br>
                    <Row>
                      <Col lg="2">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <IOSSwitch
                              checked={
                                this.state.scanner.unassignId === true
                                  ? true
                                  : false
                              }
                              onChange={this.handleUnassigned}
                              name="assign"
                            />
                          }
                          disabled={this.state.readOnly}
                          label={
                            this.state.scanner.unassignId === true
                              ? "Unassigned"
                              : "Assigned"
                          }
                          style={{ float: "right" }}
                        />
                      </Col>
                    </Row>

                    <br></br>
                    <Row>
                      <Col lg="6">
                        <figure style={{ marginLeft: "50px" }}>
                          <img
                            title="Equipment"
                            src={this.state.equipmentImage}
                            alt="Equipment"
                            height="150px"
                            width="150px"
                          />
                          <figcaption>Equipment</figcaption>
                        </figure>
                      </Col>
                      <Col lg="6">
                        <figure style={{ marginLeft: "50px" }}>
                          <img
                            title="Component"
                            src={this.state.componentImage}
                            alt="Component"
                            height="150px"
                            width="150px"
                          />
                          <figcaption>Component</figcaption>
                        </figure>
                      </Col>{" "}
                    </Row>
                  </div>
                </CardBody>
              </Card>

              <br></br>
              <Row>
                {this.state.fetched && (
                  <Col lg="12">
                    {this.state.scanner.config ? (
                      <center>
                        {(this.state.status === "Disapproved" ||
                          this.state.status === "Pending") && (
                          <Button
                            color="success"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Do you really want to accept the data?"
                                )
                              ) {
                                this.handleSave();
                              }
                            }}
                            size="md"
                            disabled={this.state.saving}
                          >
                            {this.state.saving ? (
                              <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                              "Approve"
                            )}
                          </Button>
                        )}

                        {this.state.status === "Pending" && (
                          <Button
                            color="danger"
                            onClick={() => {
                              this.openDialog();
                            }}
                            size="md"
                          >
                            {this.state.savingArchive ? (
                              <i className="fas fa-spinner fa-pulse"></i>
                            ) : (
                              "Disapprove"
                            )}
                          </Button>
                        )}
                      </center>
                    ) : (
                      <center>
                        <h2 style={{ color: "red" }}>Sensor not configured</h2>
                      </center>
                    )}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Container>

        <Dialog
          open={this.state.openDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              flexDirection: "row",
              width: 300,
              height: 300,
              justifySelf: "center",
              padding: 10,
            }}
          >
            {this.state.scanner.newEquipment === true ? (
              <div>
                <span>Equipment Type</span>
                {this.state.equipmentTypeArray && (
                  <Select
                    defaultValue={this.state.equipmentTypeArray[0]}
                    label="Select component"
                    onChange={(e) => this.handleEquipmentChange(e)}
                    options={this.state.equipmentTypeArray}
                    styles={{ width: 400 }}
                    value={
                      this.state.equipmentTypeArray[
                        this.state.selectedEquipment
                      ]
                    }
                  />
                )}
              </div>
            ) : null}
            {this.state.scanner.newComponent === true ? (
              <div>
                {" "}
                <span>Component Type</span>
                {this.state.componentTypeArray && (
                  <Select
                    defaultValue={this.state.componentTypeArray[0]}
                    label="Select component"
                    onChange={(e) => this.handleComponentChange(e)}
                    options={this.state.componentTypeArray}
                    styles={{ width: 400 }}
                    value={
                      this.state.componentTypeArray[
                        this.state.selectedComponent
                      ]
                    }
                  />
                )}
              </div>
            ) : null}
          </div>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ openDelete: false });
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({ openDelete: false });
                this.handleSelect();
              }}
              color="primary"
              variant="contained"
              style={{ color: "#fff" }}
            >
              Select
            </Button>
          </DialogActions>
        </Dialog>

        {
          // this.state.rejectOpenDialog &&
          <CustomDialog
            closeDialog={this.closeDialog}
            isDialogOpen={this.state.openDialog}
            handleChange={this.handleChange}
            reason={this.state.disapprove.reason}
            handleArchive={() => this.handleArchive()}
          />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  scannerDetailsData: _.isEmpty(state.companies.scannerDetailsData)
    ? null
    : state.companies.scannerDetailsData,
  componentTypeData: _.isEmpty(state.componentTypes.componentTypeData)
    ? null
    : state.componentTypes.componentTypeData,
  equipTypeData: _.isEmpty(state.equipmentType.equipTypeData)
    ? null
    : state.equipmentType.equipTypeData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanyScannerDetails: (companyId, scannerId) =>
      dispatch(fetchCompanyScannerDetails(companyId, scannerId)),
    fetchEquipmentType: () => dispatch(fetchEquipmentType()),
    fetchComponentType: () => dispatch(fetchComponentType()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScannerDetails);
