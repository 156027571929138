import { FETCH_USER_DETAILS } from "../actions/user.action";

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS:
      return { ...state, details: action.payload };
    default:
      return state;
  }
};

export default userReducer;
