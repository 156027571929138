import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Helper from "../../../../helper";

// reactstrap components
import {
  Input,
  Button, 
} from "reactstrap";

class CustomDialog extends React.Component{

    componentDidMount() {
      
    }


    handleChange = (state, key) => (e) => {
      let subject = { ...this.state[state] };
      Helper.reduce(subject, key, e.target.value)
      this.setState({ [state]: subject });
    }

    render() {
      return (
        <Dialog
          open={this.props.isDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ flexDirection: "row", width: 400, height: 200, justifySelf: "center", padding: 10 }} >
            <div>
              <span>Reason for Disapproval:</span>
              <Input 
                type="textarea" 
                name="text" 
                id="exampleText"
                value={this.props.reason}
                onChange={this.props.handleChange('disapprove', 'reason')} 
              />
            </div>
          </div>
          <DialogActions>
            <Button onClick={ () => this.props.closeDialog() } color="danger">
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.props.handleArchive()
              }}
              color="success"
              variant="contained"
              style={{ color: '#fff' }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
}

export default CustomDialog;