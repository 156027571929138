import CallAPI from "../../axios";

export const FETCH_ORGANIZATION_LIST = "FETCH_ORGANIZATION_LIST";
export const FETCH_ORGANIZATION_DETAILS = "FETCH_ORGANIZATION_DETAILS";
export const FETCH_ORGANIZATION_COMPANY_LIST =
  "FETCH_ORGANIZATION_COMPANY_LIST";
export const FETCH_ORGANIZATION_GROUP_LIST = "FETCH_ORGANIZATION_GROUP_LIST";
export const FETCH_ORGANIZATION_MACHINE_LIST =
  "FETCH_ORGANIZATION_MACHINE_LIST";
export const FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_MACHINE =
  "FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_MACHINE,";
export const FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_PLANT =
  "FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_PLANT";

export const FETCH_ORGANIZATION_COMPONENT_LIST =
  "FETCH_ORGANIZATION_COMPONENT_LIST";

const fetchData = (data) => {
  return {
    type: FETCH_ORGANIZATION_LIST,
    payload: data,
  };
};

const fetchOrganizationDetailsData = (data) => {
  return {
    type: FETCH_ORGANIZATION_DETAILS,
    payload: data,
  };
};

const fetchCompListData = (data) => {
  return {
    type: FETCH_ORGANIZATION_COMPANY_LIST,
    payload: data,
  };
};

const fetchGroupListData = (data) => {
  return {
    type: FETCH_ORGANIZATION_GROUP_LIST,
    payload: data,
  };
};

const fetchMachineListData = (data) => {
  return {
    type: FETCH_ORGANIZATION_MACHINE_LIST,
    payload: data,
  };
};

const fetchEquipmentListDataByM = (data) => {
  return {
    type: FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_MACHINE,
    payload: data,
  };
};

const fetchEquipmentListDataByP = (data) => {
  return {
    type: FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_PLANT,
    payload: data,
  };
};

const fetchComponentListData = (data) => {
  return {
    type: FETCH_ORGANIZATION_COMPONENT_LIST,
    payload: data,
  };
};

export const fetchOrganizationsList = () => {
  return (dispatch) => {
    CallAPI.organization_List()
      .then((response) => {
        dispatch(
            fetchData(JSON.parse(JSON.stringify(typeof response !== "undefined" && response && response.data)))
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchOrganizationDetails = (organizationId) => {
  return (dispatch) => {
    CallAPI.organization_Details(organizationId)
      .then((response) => {
        dispatch(
          fetchOrganizationDetailsData(
            JSON.parse(JSON.stringify(response.data))
          )
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchOrganizationCompanyList = (organizationId) => {
  return (dispatch) => {
    CallAPI.organization_companyList(organizationId)
      .then((response) => {
        if (response && response.status === 404) {
          dispatch(fetchCompListData([]));
        } else {
          dispatch(
            fetchCompListData(
              JSON.parse(JSON.stringify(response && response.data))
            )
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchOrganizationGroupList = (companyId) => {
  return (dispatch) => {
    CallAPI.organization_groupList(companyId)
      .then((response) => {
        dispatch(fetchGroupListData(JSON.parse(JSON.stringify(response.data))));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchOrganizationMachineList = () => {
  return (dispatch) => {
    CallAPI.organization_machineList()
      .then((response) => {
        dispatch(
          fetchMachineListData(JSON.parse(JSON.stringify(response.data)))
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchOrganizationEquipmentList_ByMachineId = () => {
  return (dispatch) => {
    CallAPI.organization_equipmentList_byMachineId()
      .then((response) => {
        dispatch(
          fetchEquipmentListDataByM(JSON.parse(JSON.stringify(response.data)))
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchOrganizationEquipmentList_ByPlantId = () => {
  return (dispatch) => {
    CallAPI.organization_equipmentList_byPlantId()
      .then((response) => {
        dispatch(
          fetchEquipmentListDataByP(JSON.parse(JSON.stringify(response.data)))
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchOrganizationComponentList = () => {
  return (dispatch) => {
    CallAPI.organization_componentList()
      .then((response) => {
        dispatch(
          fetchComponentListData(JSON.parse(JSON.stringify(response.data)))
        );
      })
      .catch((error) => {
        throw error;
      });
  };
};
