import React, { useEffect, useState } from "react";
import { Form, Button, Input, Radio } from "antd";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import API from "../../../../../axios";
// import { useSetState } from "../../../../../Hooks/useSetState";

const QuesList = {
  isConstantSpeed: {
    quesID: "isConstantSpeed",
    type: "Radio",
    ques: "Is the equipment running at constant speed (rpm variation < 5%)?",
    isSelected: true,
    input: [
      {
        answer: true,
        gotoId: null,
        remove: [],
      },
      {
        answer: false,
        gotoId: null,
        remove: [],
      },
    ],
  },
  isRotatingEquipment: {
    quesID: "isRotatingEquipment",
    type: "Radio",
    ques: "Is this a rotating equipment?",
    isSelected: true,
    input: [
      {
        answer: true,
        gotoId: "isConstantSpeed",
        remove: [],
      },
      {
        answer: false,
        gotoId: null,
        remove: ["isConstantSpeed"],
      },
    ],
  },
};

let tempObject = {
  isConstantSpeed: true,
  isRotatingEquipment: true,
};

const initState = {
  isLoading: false,
  isCreate: true,
};

// Style
const labelCol = { span: 8 };
const wrapperCol = { span: 16 };
const initialValues = { remember: true };
let initRender = true;
const offset = 8;

const DIYQuest = ({ ...props }) => {
  const [questionList, setQuestionList] = useState([]);
  const [state, setState] = useState(initState);
  initRender = false;
  const [form] = Form.useForm();

  const onMount = async () => {
    const { equipmentId, equipmentType } = props.equipmentDIY;

    setState({ ...state, isLoading: true });
    try {
      const response = await API.equipConfig_details(equipmentId);
      let equipmentConfig = {};
      if (response.status === 200) {
        equipmentConfig = response.data.data;
      } else {
        //new data since not going in error
        setQuestionList([
          props.equipmentDIY.equipmentType === "other"
            ? QuesList.isRotatingEquipment
            : QuesList.isConstantSpeed,
        ]);
        setState({...state, isLoading: false });
        return;
      }
      setState({
        isLoading: false,
        isCreate: Object.keys(equipmentConfig).length === 0 ? true : false,
        equipmentConfig,
      });
      let questList = [];

      if (equipmentConfig.equipmentType === "other") {
        QuesList.isRotatingEquipment.isSelected =
          equipmentConfig.isRotatingEquipment;
        questList.push(QuesList.isRotatingEquipment);
      }
      QuesList.isConstantSpeed.isSelected = equipmentConfig.isConstantSpeed;
      questList.push(QuesList.isConstantSpeed);

      setQuestionList(questList);
    } catch (error) {
      setQuestionList([
        props.equipmentDIY.equipmentType === "other"
          ? QuesList.isRotatingEquipment
          : QuesList.isConstantSpeed,
      ]);
      setState({...state, isLoading: false });
    }
  };
  useEffect(() => {
    onMount();
  }, []);

  const onChange = (value, val2) => {
    let nextKey = null,
      removeKeys = [];
    QuesList[val2.quesID].input.map((val) => {
      if (val.answer === value.target.value) {
        nextKey = val.gotoId;
        removeKeys = val.remove;
      }
    });
    tempObject[val2.quesID] = value.target.value;

    if (_.find(questionList, { quesID: nextKey })) {
      //stop adding reduandant
      return;
    }

    if (removeKeys.length > 0) {
      let tempQuestionList = questionList;
      removeKeys.map((val) => {
        tempQuestionList = _.reject(tempQuestionList, (n) => {
          if (n.quesID == val) {
            tempObject[val] = false;
          }
          return n.quesID == val;
        });
      });
      setQuestionList(tempQuestionList);
    }

    if (nextKey) {
      setQuestionList([...questionList, QuesList[nextKey]]);
    }
  };

  const onFinish = async () => {
    let outputObject = {
      config: {
        [props.equipmentDIY.equipmentType]: tempObject,
      },
      companyId: props.equipmentDIY.companyId,
      equipmentId: props.equipmentDIY.equipmentId,
      description: "NA",
    };

    if (state.isCreate) {
      let response = await API.equipConfig_create(outputObject);
      if (response && response.status === 200) {
        toast.success("Success");
        props.closeModal();
      } else {
        toast.error("Failed");
      }
    } else {
      let response = await API.equipConfig_update(
        props.equipmentDIY.equipmentId,
        {
          config: outputObject.config,
          description: "NA",
        }
      );
      if (response && response.status === 200) {
        toast.success("Success");
        props.closeModal();
      } else {
        toast.error("Failed");
      }
    }
    return;
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", { errorInfo });
  };

  if (state.isLoading) return <div>Loading...</div>;

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      {questionList &&
        questionList.map((val, key) => {
          return (
            <span key={key}>
              <Form.Item name={key}>{val.ques}</Form.Item>
              <Form.Item
                name={val.quesID}
                initialValue={val.isSelected}
                rules={[{ required: true, message: "Please select case!" }]}
              >
                <Radio.Group
                  key={val.quesID}
                  options={[
                    {
                      label: "Yes",
                      value: true,
                    },
                    {
                      label: "No",
                      value: false,
                    },
                  ]}
                  onChange={(e) => onChange(e, val)}
                />
              </Form.Item>
            </span>
          );
        })}

      <Form.Item wrapperCol={{ ...wrapperCol, offset }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DIYQuest;
