import { FETCH_PROCESS_CONFIGS_DATA } from '../actions/processConfiguration.action';

const processConfigurationReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PROCESS_CONFIGS_DATA:
            return {
                ...state,
                processConfig: action.payload
            }
        default: // need this for default case
            return state;
    }
}
export default processConfigurationReducer;