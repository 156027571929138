import {
  FETCH_COMPONENT_TYPES_DATA,
  FETCH_COMPONENT_TYPES_DETAILS_DATA,
  FETCH_EQUIPMENT_COMPONENT,
  FETCH_COMPONENT_OPTIONS,
  FETCH_COMPONENT_RPM_RATIO,
  FETCH_COMPONENT_QUESTIONARE,
  FETCH_COMPONENT_UTIL,
  FETCH_COMPONENT_DETAILS,
} from "../actions/componentTypes.action";

const componentTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPONENT_TYPES_DATA:
      return {
        ...state,
        componentTypeData: action.payload,
      };
    case FETCH_COMPONENT_TYPES_DETAILS_DATA:
      return {
        ...state,
        componentTypeDetailsData: action.payload,
      };
    case FETCH_EQUIPMENT_COMPONENT:
      return {
        ...state,
        equipmentComponentData: action.payload,
      };
    case FETCH_COMPONENT_OPTIONS:
      return {
        ...state,
        componentOptionsData: action.payload,
      };
    case FETCH_COMPONENT_RPM_RATIO:
      return {
        ...state,
        componentRPMRatioData: action.payload,
      };
    case FETCH_COMPONENT_QUESTIONARE:
      return {
        ...state,
        componentQuestionareData: action.payload,
      };
    case FETCH_COMPONENT_UTIL:
      return {
        ...state,
        componentUtilData: action.payload,
      };
    case FETCH_COMPONENT_DETAILS:
      return {
        ...state,
        componentDetailsData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default componentTypeReducer;
