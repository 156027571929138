import CallAPI from "../../axios";

export const FETCH_EQUIPMENT_TYPES = "FETCH_EQUIPMENT_TYPES";
export const FETCH_EQUIPMENT_TYPES_DETAILS = "FETCH_EQUIPMENT_TYPES_DETAILS";
export const FETCH_MACHINE_EQUIPMENT_DETAILS =
  "FETCH_MACHINE_EQUIPMENT_DETAILS";
export const FETCH_EQUIPMENT_DETAILS = "FETCH_EQUIPMENT_DETAILS";
export const FETCH_EQUIPMENT_OPTIONS = "FETCH_EQUIPMENT_OPTIONS";
export const FETCH_EQUIPMENT_REPORTS = "FETCH_EQUIPMENT_REPORTS";
export const FETCH_EQUIPMENT_UTIL = "FETCH_EQUIPMENT_UTIL";
export const FETCH_RPM_RATIO_DETAILS = "FETCH_RPM_RATIO_DETAILS";

const fetchData = (data) => {
  return {
    type: FETCH_EQUIPMENT_TYPES,
    payload: data,
  };
};

const fetchEquipTypeDetailsData = (data) => {
  return {
    type: FETCH_EQUIPMENT_TYPES_DETAILS,
    payload: data,
  };
};

const fetchMachineEquipDetailsData = (data) => {
  return {
    type: FETCH_MACHINE_EQUIPMENT_DETAILS,
    payload: data,
  };
};

const fetchEquipDetailsData = (data) => {
  return {
    type: FETCH_EQUIPMENT_DETAILS,
    payload: data,
  };
};
const fetchRpmRatio = (data) => {
  return {
    type: FETCH_RPM_RATIO_DETAILS,
    payload: data,
  };
};

const fetchEquipmentOptionsData = (data) => {
  return {
    type: FETCH_EQUIPMENT_OPTIONS,
    payload: data,
  };
};

const fetchEquipmentReportsData = (data) => {
  return {
    type: FETCH_EQUIPMENT_REPORTS,
    payload: data,
  };
};

const fetchEquipmentUtilData = (data) => {
  return {
    type: FETCH_EQUIPMENT_UTIL,
    payload: data,
  };
};

export const fetchEquipmentType = (companyId) => {
  return (dispatch) => {
    CallAPI.equipmentType_get(companyId)
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipmentTypeDetails = (plantId) => {
  return (dispatch) => {
    CallAPI.equipmentType_get(plantId)
      .then((response) => {
        dispatch(fetchEquipTypeDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchMachineEquipmentDetails = (companyId, machineId) => {
  return (dispatch) => {
    if (machineId === "00000") {
      CallAPI.equipmentManage_equipmentList(companyId)
        .then((response) => {
          response && dispatch(fetchMachineEquipDetailsData(response.data));
        })
        .catch((error) => {
          throw error;
        });
      return;
    }
    CallAPI.equipmentManage_equipmentListMachine(machineId)
      .then((response) => {
        dispatch(fetchMachineEquipDetailsData(response && response.data));
      })
      .catch((error) => {
        dispatch(fetchMachineEquipDetailsData([]));
        throw error;
      });
  };
};

export const fetchEquipmentDetails = (equipmentId) => {
  return (dispatch) => {
    CallAPI.equipmentManage_equipmentDetails(equipmentId)
      .then((response) => {
        dispatch(fetchEquipDetailsData(response && response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchRpmRatioData = (equipmentId) => {
  return (dispatch) => {
    CallAPI.rpmRatio_get(equipmentId)
      .then((response) => {
        dispatch(fetchRpmRatio(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipmentOptions = (companyId) => {
  return (dispatch) => {
    CallAPI.getData(`/equipments/options/${companyId}`)
      .then((response) => {
        dispatch(fetchEquipmentOptionsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipmentReport = (companyId, equipmentId) => {
  return (dispatch) => {
    CallAPI.getData(`/reports/${companyId}/${equipmentId}`)
      .then((response) => {
        dispatch(fetchEquipmentReportsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchEquipmentUtil = (senesorId) => {
  return (dispatch) => {
    CallAPI.getData(`/utilities/imageUpload/${senesorId}/equipment`)
      .then((response) => {
        dispatch(fetchEquipmentUtilData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
