import React from "react";
import axios from "../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let companyId = "";
let sensorId = "";
let is = [];

let mountTypes = [
  {
    key: 0,
    label: "Vertical",
    value: "vertical",
  },
  {
    key: 1,
    label: "Horizontal",
    value: "horizontal",
  },
  {
    key: 2,
    label: "Axial",
    value: "axial",
  },
];

let axis = [
  {
    key: 0,
    label: "X",
    value: "x",
  },
  {
    key: 1,
    label: "Y",
    value: "y",
  },
  {
    key: 2,
    label: "Z",
    value: "z",
  },
];

class MountConfigAdd extends React.Component {
  state = {
    //Navigations
    currentPage: 1,
    saving: false,
    pages: ["main", "sensorConfigurations"],
    mountConfig: "",
    label:"",
    sensorMountInfo: {
      mountMapping: {
        axial: "",
        horizontal: "",
        vertical: "",
      },
    },
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
  }

  handleChange = (state, key) => (e) => {
    let mountConfig = this.state.mountConfig;
    mountConfig = e.target.value;
    this.setState({ [state]: e.target.value });
  };

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  handleSelectChange = (state, key) => async (option) => {
    let copy = { ...this.state[state] };
    copy[key] = option ? option.value : "";
    let defaultKey = "";
    let value = option ? option.value : "";
    let defaultValue = option ? option.key : 0;

    if (key === "mountType") {
      defaultKey = "defaultMountType";
    }

    if (key === "mountMapping.horizontal") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultHorizontal";
    }

    if (key === "mountMapping.vertical") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultVertical";
    }

    if (key === "mountMapping.axial") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultAxial";
    }

    this.setState({ [state]: copy, [defaultKey]: defaultValue });
  };

  handleNext = async (page) => {
    if (this.state.currentPage === 2) {
      this.setState({ saving: true });

      let data = {
        description: "NA",
        mountConfig: this.state.mountConfig,
        label: this.state.label,
        sensorMountInfo: {
          mountMapping: this.state.sensorMountInfo.mountMapping,
          mountType: this.state.sensorMountInfo.mountType,
        },
      };

      let api = await axios.mountConfig_create(data);
      if (api.status === 200) {
        toast.success("Mount configuration saved successfully.");
        this.setState({ readOnly: true, currentPage: 1, saving: false });
        this.jump("/v1/mount-configs");
      } else if (api.status === 400) {
        toast.warning(api.message);
        this.setState({ saving: false });
      } else if (api.status === 500) {
        toast.warning("Something went wrong. Please try again.");
        this.setState({ saving: false });
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let mountConfig = this.state.mountConfig;
        if (!mountConfig) {
          toast.warn("Mount Config Name is required");
          this.setState({ saving: false });
          return;
        }
        //Validating
        // let err = await validation.validate(process);
        // if (err) {
        //   toast.warn(err);
        //   this.setState({saving: false});
        //   return;
        // }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleCancel = (page) => {
    if (page === "main") {
      this.jump("/v1/mount-configs");
    }
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    let label = "";
    if (this.state.currentPage === 1) label = "Mount Configuration";
    else if (this.state.currentPage === 2) label = "Mount Information";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Mount Configuration";

    let front = "";
    if (this.state.currentPage === 1) front = "Mount Information";
    else if (this.state.currentPage === 2) front = "Submit";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <div
                    onClick={() => {
                      this.jump("/v1/mount-configs", 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>{" "}
                  <br />
                  <br />
                  <Row className="align-items-center">
                    <Col xs="8">
                      {this.state.currentPage > 0 && (
                        <h3 className="mb-0">Add Mount Configuration</h3>
                      )}
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>
                {/* Add Sensor */}

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Mount Config Name:{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.mountConfig}
                                onChange={this.handleChange("mountConfig")}
                                disabled={this.state.readOnly || sensorId}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <div className="pl-lg-4">
                        <>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Mount Type
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountType"
                                  )}
                                  defaultValue={
                                    mountTypes[this.state.defaultMountType]
                                  }
                                  options={mountTypes}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Label
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={this.state.label}
                                  onChange={this.handleChange("label")}
                                  disabled={this.state.readOnly || sensorId}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Horizontal
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.horizontal"
                                  )}
                                  defaultValue={
                                    axis[this.state.defaultHorizontal]
                                  }
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Vertical
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.vertical"
                                  )}
                                  defaultValue={
                                    axis[this.state.defaultVertical]
                                  }
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Axial
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.axial"
                                  )}
                                  defaultValue={axis[this.state.defaultAxial]}
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      </div>
                    )}

                    {this.state.currentPage <= 2 && (
                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                <>
                                  {front}
                                  <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                    <i className="fa fa-forward" />
                                  </div>
                                </>
                              )}
                            </Button>
                          }

                          {
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() =>
                                this.handleCancel(
                                  this.state.pages[this.state.currentPage - 1]
                                )
                              }
                              size="md"
                            >
                              {this.state.currentPage === 1 ? (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-ban" />
                                </div>
                              ) : (
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-backward" />
                                </div>
                              )}
                              {back}
                            </Button>
                          }
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default MountConfigAdd;
