import React from "react";
import { connect } from "react-redux";
import { fetchCompanies } from "../../../../redux/actions/companies.action";
import {
  fetchNotification,
  fetchNotificationCodes,
  fetchNotificationGroup,
} from "../../../../redux/actions/notification.action";

import { fetchUserDetails } from "../../../../redux/actions/user.action";
import { Table, DatePicker, Spin } from "antd";
import Select from "react-select";
import Icon from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import ReactSelect from "react-select";
import _, { random } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  ColumnToggle,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import ImageUploader from "react-images-upload";
// import './notification.css';
import "./equipments.css";
import axios from "../../../../axios";
import MaterialIcon, { colorPalette } from "material-icons-react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "../validations";
import TableShimmer from "../../../../components/Shimmer/TableShimmer";

const { SearchBar } = Search;
const { ToggleList } = ColumnToggle;
const { ExportCSVButton } = CSVExport;

const { Option, OptGroup } = Select;

const options = {
  showTotal: true,
};

let companyId = "none";
let machineId = "";
let now = moment(new Date(), "DD MM YYYY hh:mm:ss");
let startDate = moment(
  new Date(new Date() - 15 * 24 * 60 * 60 * 1000),
  "DD MM YYYY hh:mm:ss"
);
let filterColumns = [];

class Equipments extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeSelectCode = this.handleChangeSelectCode.bind(this);
    this.filterDataCode = this.filterDataCode.bind(this);
    if (!Helper.isAuth()) {
      this.jump("/auth/login", 0);
    }
  }
  search = (value, confirm, dataIndex) => {
    const { newTableData } = this.state;
    let filterTable = null;

    switch (dataIndex) {
      case "companyCode":
        filterTable = newTableData.filter((o) => {
          return String(o["companyCode"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "sensorId":
        filterTable = newTableData.filter((o) => {
          return String(o["sensorId"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "status":
        filterTable = newTableData.filter((o) => {
          return String(o["status"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "plantName":
        filterTable = newTableData.filter((o) => {
          return String(o["plantName"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "equipmentName":
        filterTable = newTableData.filter((o) => {
          return String(o["equipmentName"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "componentName":
        filterTable = newTableData.filter((o) => {
          return String(o["componentName"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "subject":
        filterTable = newTableData.filter((o) => {
          return String(o["subject"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "tagId":
        filterTable = newTableData.filter((o) => {
          return String(o["tagId"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "notifierId":
        filterTable = newTableData.filter((o) => {
          return String(o["notifierId"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "rul":
        filterTable = newTableData.filter((o) => {
          return String(o["rul"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "stage":
        filterTable = newTableData.filter((o) => {
          return String(o["stage"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      case "faultType":
        filterTable = newTableData.filter((o) => {
          return String(o["faultType"])
            .toLowerCase()
            .includes(value[0] ? value[0].toLowerCase() : value[0]);
        });
        break;
      default:
        break;
    }

    this.setState({ filterTable, isFilter: true });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.search(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.search(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.setState({ filterTable: null, isFilter: true })}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <MaterialIcon icon="search" color="#0288d1"></MaterialIcon>
    ),
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  state = {
    companiesloaderFalse: false,
    downloadAllLoading: false,
    equipments: [],
    filterTable: null,
    companyId:
      this.props.match.params.companyId !== undefined
        ? this.props.match.params.companyId
        : null,
    list: true,
    equipment: {},
    equipmentTypes: [],
    equipmentType: null,
    images: [],
    saving: false,
    startDate: moment(
      new Date(
        new Date(new Date() - 15 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
      ),
      "DD MM YYYY hh:mm:ss"
    ),
    endDate: now,
    loading: true,
    status: "sent",
    statusOptions: [
      {
        value: "sent",
        label: "Sent",
      },
      {
        value: "pending",
        label: "Pending/Approved",
      },
      {
        value: "rejected",
        label: "Disapproved",
      },
    ],
    filterColumns: [],
    columns: [
      {
        title: "Company Code",
        dataIndex: "companyCode",
        key: "companyCode",
        sorter: (a, b) => {
          if (a.companyCode === null) {
            a.companyCode = "";
          }
          if (b.companyCode === null) {
            b.companyId = "";
          }
          return a.companyCode.localeCompare(b.companyCode);
        },
        ...this.getColumnSearchProps("companyCode"),
      },
      {
        title: "Sensor Id",
        dataIndex: "sensorId",
        key: "sensorId",
        sorter: (a, b) => {
          if (a.sensorId === null) {
            a.sensorId = "";
          }
          if (b.sensorId === null) {
            b.sensorId = "";
          }
          return a.sensorId.localeCompare(b.sensorId);
        },
        ...this.getColumnSearchProps("sensorId"),
      },
      {
        title: "Time Stamp",
        dataIndex: "updatedTimeStamp",
        key: "updatedTimeStamp",
        sorter: (a, b) => a.updatedTimeStamp - b.updatedTimeStamp,
        render: (cell, record) => {
          return <div>{moment(cell * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>;
        },
        // ...this.getColumnSearchProps("timestamp"),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        ...this.getColumnSearchProps("status"),
      },
      {
        title: "Action",
        dataIndex: "tagId",
        key: "tagId",
        render: (record, row) => {
          return (
            <div>
              {row.isNotificationReviewed === true ||
              this.state.status === "sent" ||
              this.state.status === "rejected" ? (
                <MaterialIcon
                  onClick={() => {
                    localStorage.setItem(
                      "notification-status",
                      this.state.status
                    );
                    localStorage.setItem(
                      "notification-start-date",
                      this.state.startDate
                    );
                    localStorage.setItem(
                      "notification-end-date",
                      this.state.endDate
                    );
                    this.jump(
                      "/v1/companies/" +
                        companyId +
                        "/machines/0000/notification/" +
                        row._id +
                        "/" +
                        this.state.status,
                      0
                    );
                  }}
                  icon="open_in_new"
                  color="#0288d1"
                ></MaterialIcon>
              ) : (
                <Button
                  onClick={() => {
                    localStorage.setItem(
                      "notification-status",
                      this.state.status
                    );
                    localStorage.setItem(
                      "notification-start-date",
                      this.state.startDate
                    );
                    localStorage.setItem(
                      "notification-end-date",
                      this.state.endDate
                    );
                    this.jump(
                      "/v1/companies/" +
                        companyId +
                        "/machines/0000/notification/" +
                        row._id +
                        "/" +
                        this.state.status,
                      0
                    );
                  }}
                >
                  Review
                </Button>
              )}
            </div>
          );
        },
        // ...this.getColumnSearchProps("action"),
      },
      {
        title: "Approver",
        dataIndex: "approver",
        key: "approver",
        sorter: (a, b) => {
          if (a.approver === null) {
            a.approver = "";
          }
          if (b.approver === null) {
            b.approver = "";
          }
          return a.approver.localeCompare(b.approver);
        },
        ...this.getColumnSearchProps("approver"),
      },
      {
        title: "Disapprover",
        dataIndex: "disapprover",
        key: "disapprover",
        ...this.getColumnSearchProps("disapprover"),
      },
      {
        title: "Reviewer",
        dataIndex: "reviewer",
        key: "reviewer",
        ...this.getColumnSearchProps("reviewer"),
      },
      {
        title: "Plant Name",
        dataIndex: "plantName",
        key: "plantName",
        sorter: (a, b) => {
          if (a.plantName === null) {
            a.plantName = "";
          }
          if (b.plantName === null) {
            b.plantName = "";
          }
          return a.plantName.localeCompare(b.plantName);
        },
        ...this.getColumnSearchProps("plantName"),
      },
      {
        title: "Equipment Name",
        dataIndex: "equipmentName",
        key: "equipmentName",
        sorter: (a, b) => {
          if (a.equipmentName === null) {
            a.equipmentName = "";
          }
          if (b.equipmentName === null) {
            b.equipmentName = "";
          }
          return a.equipmentName.localeCompare(b.equipmentName);
        },
        ...this.getColumnSearchProps("equipmentName"),
      },
      {
        title: "Component Name",
        dataIndex: "componentName",
        key: "componentName",
        sorter: (a, b) => {
          if (a.componentName === null) {
            a.componentName = "";
          }
          if (b.componentName === null) {
            b.componentName = "";
          }
          return a.componentName.localeCompare(b.componentName);
        },
        ...this.getColumnSearchProps("componentName"),
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        sorter: (a, b) => {
          if (a.subject === null) {
            a.subject = "";
          }
          if (b.subject === null) {
            b.subject = "";
          }
          return a.subject.localeCompare(b.subject);
        },
        ...this.getColumnSearchProps("subject"),
      },
      {
        title: "Tag Id",
        dataIndex: "tagId",
        key: "tagId",
        sorter: (a, b) => {
          if (a.tagId === null) {
            a.tagId = "";
          }
          if (b.tagId === null) {
            b.tagId = "";
          }
          return a.tagId.localeCompare(b.tagId);
        },
        ...this.getColumnSearchProps("tagId"),
      },
      {
        title: "Notifier Id",
        dataIndex: "notifierId",
        key: "notifierId",
        sorter: (a, b) => {
          if (a.notifierId === null) {
            a.notifierId = "";
          }
          if (b.notifierId === null) {
            b.notifierId = "";
          }
          return a.notifierId.localeCompare(b.notifierId);
        },
        ...this.getColumnSearchProps("notifierId"),
      },
      {
        title: "RUL",
        dataIndex: "rul",
        key: "rul",
        sorter: (a, b) => a.rul - b.rul,
        ...this.getColumnSearchProps("rul"),
      },
      {
        title: "Stage",
        dataIndex: "stage",
        key: "stage",
        sorter: (a, b) => {
          if (a.stage === null) {
            a.stage = "";
          }
          if (b.stage === null) {
            b.stage = "";
          }
          return a.stage - b.stage;
        },
        ...this.getColumnSearchProps("stage"),
      },
      {
        title: "Fault type",
        dataIndex: "faultType",
        key: "faultType",
        sorter: (a, b) => {
          if (Array.isArray(a.faultType)) {
            a.faultType = a.faultType[0];
          }
          if (Array.isArray(b.faultType)) {
            b.faultType = b.faultType[0];
          }
          if (a.faultType === null) {
            a.faultType = "";
          }
          if (b.faultType === null) {
            b.faultType = "";
          }
          return a.faultType.localeCompare(b.faultType);
        },
        ...this.getColumnSearchProps("faultType"),
      },
      {
        title: "AlarmValue Object",
        dataIndex: "alarmValueObject",
        key: "alarmValueObject",
        render: (record, row) => {
          return (
            <div>
              {row.alarmValueObject !== null
                ? JSON.stringify(row.alarmValueObject)
                : null}
            </div>
          );
        },
        // ...this.getColumnSearchProps("status"),alarmValueObject
      },
    ],
    headers: [
      { label: "Company Code", key: "companyCode" },
      { label: "Sensor Id", key: "sensorId" },
      { label: "Timestamp", key: "timestamp" },
      { label: "Status", key: "status" },
      { label: "Tag Id", key: "tagId" },
      { label: "Approver", key: "approver" },
      { label: "Disapprover", key: "disapprover" },
      { label: "Reviewer", key: "reviewer" },
      { label: "Plant Name", key: "plantName" },
      { label: "Equipment Name", key: "equipmentName" },
      { label: "Component Name", key: "componentName" },
      { label: "Subject", key: "subject" },
      { label: "Notifier Id", key: "notifierId" },
      { label: "RUL", key: "rul" },
      { label: "Stage", key: "stage" },
      { label: "Fault Type", key: "faultType" },
      { label: "AlarmValue Object", key: "alarmValueObjectString" },
    ],
    companies: [],
    notificationCodes: {},
    notificationGroup: [],
    notificationData: [],
    isFilter: false,
    notiGrpVal: [],
    notiCodeVal: null,
    downloadData: [],
  };

  sensorDisplay(cell, row) {
    let sensorIds = [];
    let tags = row.tag.map((config) => {
      if (config.tagConfigMaps)
        sensorIds.push(config.tagConfigMaps.sensorConfig.sensorId);
    });
    return sensorIds.join(", ");
  }

  tagDisplay(cell, row) {
    let tagIds = [];
    let tags = row.tag.map((config) => {
      return config.tagId;
    });
    return tags.join(", ");
  }

  action(cell, row) {
    return (
      <div>
        {row.isNotificationReviewed === true ||
        this.state.status === "sent" ||
        this.state.status === "rejected" ? (
          <MaterialIcon
            onClick={() => {
              localStorage.setItem("notification-status", this.state.status);
              localStorage.setItem(
                "notification-start-date",
                this.state.startDate
              );
              localStorage.setItem("notification-end-date", this.state.endDate);
              this.jump(
                "/v1/companies/" +
                  companyId +
                  "/machines/0000/notification/" +
                  row._id +
                  "/" +
                  this.state.status,
                0
              );
            }}
            icon="open_in_new"
            color="#0288d1"
          ></MaterialIcon>
        ) : (
          <Button
            onClick={() => {
              localStorage.setItem("notification-status", this.state.status);
              localStorage.setItem(
                "notification-start-date",
                this.state.startDate
              );
              localStorage.setItem("notification-end-date", this.state.endDate);
              this.jump(
                "/v1/companies/" +
                  companyId +
                  "/machines/0000/notification/" +
                  row._id +
                  "/" +
                  this.state.status,
                0
              );
            }}
          >
            Review
          </Button>
        )}
      </div>
    );
  }

  async componentDidMount() {
    machineId = this.props.match.params.machineId;
    await this.props.fetchCompanies();
    let roleId = "admin";
    await this.props.fetchNotificationCodes(roleId);
    await this.props.fetchUserDetails("master");

    // await this.props.fetchNotification()

    if (localStorage.getItem("notification-status")) {
      this.setState({
        status: localStorage.getItem("notification-status"),
      });
      localStorage.removeItem("notification-status");
    }
    if (localStorage.getItem("notification-start-date")) {
      this.setState({
        startDate: new Date(localStorage.getItem("notification-start-date")),
      });
      localStorage.removeItem("notification-start-date");
    }
    if (localStorage.getItem("notification-end-date")) {
      this.setState({
        endDate: new Date(localStorage.getItem("notification-end-date")),
      });
      localStorage.removeItem("notification-end-date");
    }
    await this.getNotification(this.state.companyId);
  }

  deleteCookie = (name) => {
    return (document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
  };
  clearAuth = () => {
    this.deleteCookie("userPortalAuth");
  };
  logout = () => {
    Helper.clearAuth();
    this.clearAuth();
    localStorage.removeItem("companyId");
    this.props.history.push("/auth/login/");
  };

  async getNotification(companyId = null) {
    if (
      !window.location.pathname.includes("/companies") &&
      this.state.companyId == null
    ) {
      toast.warn("Please select a company");
      return;
    }
    // if (equipmentType) {
    //   endpoint += "/" + equipmentType;
    // }
    const toTimestamp = (strDate) => {
      const dt = moment(strDate).unix() * 1000;
      return dt;
    };
    const convertStart = toTimestamp(this.state.startDate);
    const convertEnd = toTimestamp(this.state.endDate);
    let data = [];

    if (companyId && companyId !== "none") {
      data = await axios.getNoticationAlertData(
        companyId,
        this.state.status,
        convertStart,
        convertEnd
      );
    } else {
      data = await axios.getNoticationAlertData(
        "ALL",
        this.state.status,
        convertStart,
        convertEnd
      );
    }
    if (data && data.data && data.data.data) {
      let downloadData = JSON.parse(JSON.stringify(data.data.data));
      downloadData = downloadData.map((val) => {
        val.timestamp = moment(val.timestamp * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        val.updatedTimeStamp = moment(val.updatedTimeStamp * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return val;
      });
      this.setState({
        newTableData: data.data.data,
        loading: false,
        downloadData: downloadData,
      });
    }
  }

  handleSelectChange =
    (equipmentType, type = "filter") =>
    async (option) => {
      if (type === "filter") {
        let equipmentType = option ? option.value : null;
        await this.getEquipments(equipmentType);
      } else if (type === "add") {
        let equipment = { ...this.state.equipment };
        equipment.equipmentType = option.value;
        this.setState({ equipment });
      }
    };

  handleStatusChange(status) {
    this.setState(
      { status: status.value, loading: true, equipments: [] },
      async () => {
        await this.getNotification(
          this.state.companyIdOptions && this.state.companyIdOptions.value
            ? this.state.companyIdOptions.value
            : this.state.companyId
        );
      }
    );
  }

  handleStartDateChange(date) {
    this.setState(
      { startDate: date, loading: true, equipments: [] },
      async () => {
        await this.getNotification(
          this.state.companyIdOptions && this.state.companyIdOptions.value
            ? this.state.companyIdOptions.value
            : this.state.companyId
        );
      }
    );
  }

  handleEndDateChange(date) {
    this.setState(
      { endDate: date, loading: true, equipments: [] },
      async () => {
        await this.getNotification(
          this.state.companyIdOptions && this.state.companyIdOptions.value
            ? this.state.companyIdOptions.value
            : this.state.companyId
        );
      }
    );
  }

  handleCompanyChange = (companyId) => {
    this.setState(
      {
        companyIdOptions: {
          key: Math.floor(Math.random() * 100 + 1),
          label: companyId.label,
          value: companyId.value,
        },
        companyId: companyId.value,
        loading: true,
        equipments: [],
        isFilter: false,
        filterTable: null,
        notiGrpVal: null,
        notiCodeVal: null,
        status: "sent",
      },
      async () => {
        await this.getNotification(companyId.label);
      }
    );
  };

  handleChange = (input) => (e) => {
    let equipment = { ...this.state.equipmet };
    equipment[input] = e.target.value;
    this.setState({ equipment: equipment });
  };

  handleChangeSelectCode(e) {
    this.setState(
      {
        notiGrpVal: e || [],
      },
      this.filterDataCode
    );
  }

  filterDataCode() {
    let filterTable = null;
    if (this.state.newTableData && this.state.newTableData.length > 0) {
      filterTable = this.state.newTableData.filter(
        (item) =>
          this.state.notiGrpVal &&
          this.state.notiGrpVal.some((option) => option.value === item.code)
      );
    }
    let downloadData = filterTable
      ? JSON.parse(JSON.stringify(filterTable))
      : [];
    downloadData = downloadData.map((val) => {
      val.updatedTimeStamp = moment(val.updatedTimeStamp * 1000).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return val;
    });
    this.setState({
      isFilter: true,
      // notiGrpVal: event,
      filterTable: filterTable,
      downloadData,
    });
  }

  onDrop = (pictureFiles, pictureData) => {
    let equipment = { ...this.state.equipment };
    equipment.imagesUrl = JSON.stringify(pictureData);
    this.setState({ equipment: equipment });
  };

  async handleSave() {
    this.setState({ saving: true });
    let equipment = { ...this.state.equipment };

    //Validating
    let err = await validation.validate(equipment);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    equipment.companyId = companyId;
    let api = await axios.postData("/equipments", equipment);
    if (api.status === 200) {
      toast.success("Equipment saved successfully");
      localStorage.removeItem("companyId");
      await this.getEquipments();
      this.setState({ list: true });
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  jump = (url, delay = 1000) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };
  handleDownloadAll = async () => {
    this.setState({ downloadAllLoading: true });

    const toTimestamp = (strDate) => {
      const dt = moment(strDate).unix() * 1000;
      return dt;
    };
    const convertStart = toTimestamp(this.state.startDate);
    const convertEnd = toTimestamp(this.state.endDate);
    let data = await axios.getAllNotificationData(
      "ALL",
      this.state.status,
      convertStart,
      convertEnd,
      this.state.userData && this.state.userData.email
    );
    if (data.status === 200 && data.data) {
      toast.success("File Sent to your mail");
    } else if (data.status === 404) {
      toast.warn(data && data.data && data.data.message);
    } else {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ downloadAllLoading: false });
  };

  // renderGroupDropDown = () => {
  //   let optionData = [];
  //   optionData.push(<Option value="none">ALL</Option>);
  //   for (let key in this.state.notificationCodes) {
  //     if (this.state.notificationCodes.hasOwnProperty(key)) {
  //       const singleDDData = this.state.notificationCodes[key];
  //       optionData.push(
  //         <OptGroup label={key}>
  //           {singleDDData.map((val) => {
  //             return <Option value={val}>{val}</Option>;
  //           })}
  //         </OptGroup>
  //       );
  //     }
  //   }

  //   return (
  //     <Select
  //       style={{ width: 250 }}
  //       // showSearch
  //       optionFilterProp="options"
  //       filterOption={(input, option) => {
  //         if (option.options) {
  //           return option.options.map((val) => {
  //             return val.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  //             // if (val.value.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
  //             //   return true;
  //             // }
  //           });
  //         } else {
  //           return false;
  //         }
  //       }}
  //       value={this.state.notiCodeVal}
  //       onChange={(e) => {
  //         const valueOne = e.split("- ")[1];
  //         // return;
  //         if (e === "none") {
  //           this.setState({
  //             filterTable: null,
  //             isFilter: true,
  //             notiCodeVal: e,
  //           });
  //           return;
  //         }
  //         let filterTable = null;
  //         if (this.state.filterTable === null) {
  //           filterTable = this.state.equipments.filter((o) => {
  //             if (Array.isArray(o.code)) {
  //               if (o.code.includes(valueOne)) {
  //                 return o;
  //               }
  //             } else {
  //               if (o.code === valueOne) {
  //                 return o;
  //               }
  //             }
  //           });
  //         } else {
  //           filterTable = this.state.filterTable.filter((o) => {
  //             if (Array.isArray(o.code)) {
  //               if (o.code.includes(valueOne)) {
  //                 return o;
  //               }
  //             } else {
  //               if (o.code === valueOne) {
  //                 return o;
  //               }
  //             }
  //           });
  //         }
  //         this.setState({
  //           filterTable: filterTable,
  //           isFilter: true,
  //           notiCodeVal: e,
  //         });
  //       }}
  //     >
  //       {optionData}
  //     </Select>
  //   );
  // };

  static getDerivedStateFromProps(nextProps, prevState) {
    let company = [],
      sortedEquipment = [],
      // loading = true,
      notificationCodes = [],
      filterColumns = [],
      notificationGroup = [],
      userData = [],
      companyId = prevState.companyId,
      companiesloaderFalse = prevState.companiesloaderFalse || false;
    if (prevState.isFilter) {
      return {
        isFilter: false,
      };
    }

    if (nextProps.companyData !== prevState.companyData) {
      if (nextProps.companyData) {
        let companies = nextProps.companyData.data;
        if (companies && companies.length && prevState.companyId === "") {
          companyId = companies[0].companyId;
        }
        company
          .push
          // {
          //   value: "none",
          //   label: "Select a Company",
          // },
          // {
          //   value: "ALL",
          //   label: "ALL",
          // }
          ();
        for (let index in companies) {
          company.push({
            value: companies[index].companyId,
            label: companies[index].companyId,
          });
        }
        companiesloaderFalse = true;
        // company = nextProps.companyData.data.map((val) => {
        //   return {
        //     value: val.companyId,
        //     label: val.companyName,
        //   };
        // });
      }
    }

    // if (nextProps.notificationCodes !== prevState.notificationCodes) {
    //   if (nextProps.notificationCodes) {
    //     notificationCodes = nextProps.notificationCodes;
    //   }
    // }

    if (nextProps.notificationCodes !== prevState.notificationCodes) {
      if (nextProps.notificationCodes) {
        notificationCodes = nextProps.notificationCodes.data;
      }
    }

    if (nextProps.userData !== prevState.userData) {
      if (nextProps.userData && nextProps.userData.details) {
        userData = nextProps.userData.details.data;
      }
    }

    // if (nextProps.notificationGroup !== prevState.notificationGroup) {
    //   if (nextProps.notificationGroup) {
    //     notificationGroup = nextProps.notificationGroup.map((val, key) => {
    //       return {
    //         value: val,
    //         label: val,
    //       };
    //     });
    //   }
    // }

    if (nextProps.notificationData !== prevState.notificationData) {
      if (nextProps && nextProps.notificationData) {
        sortedEquipment = nextProps.notificationData.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        sortedEquipment = sortedEquipment.map((equipment) => {
          if (prevState.status === "pending") {
            equipment.status =
              equipment.approver == undefined || equipment.approver == ""
                ? "Pending"
                : "Approved";
          } else if (prevState.status === "sent") {
            equipment.status = "Sent";
          } else if (prevState.status === "rejected") {
            equipment.status = "Disapproved";
          }
          return equipment;
        });

        if (prevState.status === "pending") {
          filterColumns = _.filter(prevState.columns, function (x) {
            if (x.title !== "Disapprover" && x.title !== "View") return x;
          });
        } else if (prevState.status === "rejected") {
          filterColumns = _.filter(prevState.columns, function (x) {
            if (x.title !== "Approver" && x.title !== "Action") return x;
          });
        } else {
          filterColumns = _.filter(prevState.columns, function (x) {
            if (
              x.title !== "Disapprover" &&
              x.title !== "Approver" &&
              x.title !== "Reviewer" &&
              x.title !== "Action"
            )
              return x;
          });
        }
        // if (nextProps.notificationData.length !== prevState.equipments.length) {
        //   loading = false;
        // }
      }
    }
    if (nextProps.notificationData === prevState.notificationData) {
      if (nextProps.notificationData.length === prevState.equipments.length) {
        sortedEquipment = prevState.equipments;
        // if (prevState.equipments.length > 0) {
        //   loading = false;
        // }
      }
    }
    // if (
    //   nextProps.notificationData.length === 0 &&
    //   prevState.equipments.length === 0 &&
    //   prevState.companyId === "none"
    // ) {
    //   loading = false;
    // }
    let downloadData = JSON.parse(JSON.stringify(sortedEquipment));
    downloadData = downloadData.map((val) => {
      val.timestamp = moment(val.timestamp * 1000).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return val;
    });

    return {
      companies: company,
      equipments: sortedEquipment.length === 0 ? [] : sortedEquipment,
      notificationData: nextProps.notificationData,
      filterColumns:
        filterColumns.length === 0 ? prevState.filterColumns : filterColumns,
      notificationCodes:
        notificationCodes.length === 0
          ? prevState.notificationCodes
          : notificationCodes,
      notificationGroup: notificationGroup,
      userData: userData,
      // downloadData,
      // notificationGroup.length === 0
      //   ? prevState.notificationGroup
      //   : notificationGroup,
      // loading: loading,
      isFilter: false,
      companyId: companyId,
      // companyIdOptions: {
      //   key: Math.floor(Math.random() * 100 + 1),
      //   label: companyId,
      //   value: companyId,
      // },
      companiesloaderFalse: companiesloaderFalse,
    };
  }

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // this.jump('/v1/equipment-details/' + companyId + '/00000/' +  row.equipmentId, 0);
        // this.jump('/v1/companies/' + companyId + '/00000/' +  row.equipmentId, 0);
        this.jump(
          "/v1/companies/" + companyId + "/notification/notificationDetails/",
          0
        );
      },
    };
  
    return (
      <>
        <ToastContainer position="top-left" />
        {/* {this.state.loading && (
          <div className="loader">
            <img alt="..." src={require("assets/img/theme/loader.gif")} />
          </div>
        )} */}
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row>
            <Col lg="6" className="table-caption">
              {this.state.companiesloaderFalse && (
                <h1 style={{ float: "left" }}>
                  Company Code:{" "}
                  {this.state.companyIdOptions
                    ? this.state.companyIdOptions &&
                      this.state.companyIdOptions.value
                    : this.state.companyId}{" "}
                  ({this.state.newTableData && this.state.newTableData.length})
                </h1>
              )}
            </Col>
            <Col lg="6" className="table-caption">
              <h1>Notification</h1>
            </Col>
          </Row>
          {this.state.companiesloaderFalse && (
            <Row>
              <Col lg="7">
                <div
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
                {/* {
                this.state.list &&
                <Button
                color="success"
                // onClick={() => {this.setState({list: false})}}
                onClick={() => {this.jump(`/v1/companies/${companyId}/machines/${machineId}/equipments/add`, 0)}}
                size="md"
                className='add-btn'
                style={{ zIndex: '9999 !important'  }}
                >
                  Add Equipments
                </Button>
              } */}
              </Col>
              <Col>
                <Button style={{ marginBottom: "10px" }}>
                  <CSVLink
                    headers={this.state.headers}
                    filename={"Notification.csv"}
                    data={this.state.downloadData}
                  >
                    Export CSV
                  </CSVLink>
                </Button>
              </Col>
              <Col>
                {this.state.downloadAllLoading ? (
                  <Button style={{ marginBottom: "10px" }} disabled>
                    Sharing data...
                  </Button>
                ) : (
                  <Button
                    style={{ marginBottom: "10px" }}
                    onClick={() => this.handleDownloadAll()}
                  >
                    Download All Data
                  </Button>
                )}
              </Col>
              {this.state.companyId &&
                this.state.companyId !== "ALL" &&
                this.state.companyId !== "none" && (
                  <Col>
                    <div>
                      <Button
                        style={{ marginBottom: "10px" }}
                        onClick={() => {
                          this.jump(
                            "/v1/companies/" +
                              this.state.companyId +
                              "/notificationGroup/",
                            0
                          );
                        }}
                      >
                        Configure Notification Groups
                      </Button>
                    </div>
                    {/* {
                this.state.list &&
                <Select
                    className='add-btn'
                    isClearable={true}
                    onChange={this.handleSelectChange('equipmentType')}
                    placeholder="Equipment Type"
                    options={this.state.equipmentTypes}
                />
              } */}
                  </Col>
                )}
            </Row>
          )}
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.list && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={this.state.columns}
                    data={this.state.equipments}
                    columnToggle
                    search={{ searchFormatted: true }}
                  >
                    {(props) => (
                      <div>
                        {/* <br></br>
                          <center>
                            <ToggleList 
                              contextual="info"
                              className="list-custom-class"
                              btnClassName="list-btn-custom-class"
                              { ...props.columnToggleProps } 
                            />
                          </center> */}
                        {this.state.companiesloaderFalse && (
                          <Row>
                            <Col lg="2">
                              <label
                                style={{ marginLeft: "15px" }}
                                className="form-control-label"
                                htmlFor="input-status"
                              >
                                Select Company
                              </label>
                              {/* <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  `${option.children}`
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                defaultValue={this.state.companyId}
                                style={{ width: 167, marginLeft: "15px" }}
                                isDisabled={companyId ? true : false}
                                onChange={(e) => {
                                  this.handleCompanyChange(e);
                                }}
                              >
                                {this.state.companies.map((val) => {
                                  return (
                                    <Option value={val.value}>
                                      {val.label}
                                    </Option>
                                  );
                                })}
                              </Select> */}

                              <Select
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                className="form-control-alternative"
                                isDisabled={false}
                                onChange={(e) => {
                                  this.handleCompanyChange(e);
                                }}
                                options={this.state.companies}
                                value={this.state.companyIdOptions}
                                defaultInputValue={this.state.companyId}
                                isLoading={
                                  !this.state.companies ||
                                  this.state.companies.length === 0
                                }
                              />
                            </Col>
                            <Col lg="1">
                              <label
                                className="form-control-label"
                                htmlFor="input-status"
                              >
                                Status
                              </label>
                              {/* <Select
                                defaultValue="sent"
                                style={{ width: 80 }}
                                onChange={(e) => {
                                  this.handleStatusChange(e);
                                }}
                              >
                                {this.state.statusOptions.map((val) => {
                                  return (
                                    <Option value={val.value}>
                                      {val.label}
                                    </Option>
                                  );
                                })}
                              </Select> */}
                              <Select
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                className="form-control-alternative"
                                isDisabled={false}
                                onChange={(e) => {
                                  this.handleStatusChange(e);
                                }}
                                options={this.state.statusOptions}
                                defaultInputValue="sent"
                                value={this.state.status}
                              />
                            </Col>
                            <Col lg="2">
                              <label
                                className="form-control-label"
                                htmlFor="input-date"
                              >
                                From Date
                              </label>
                              <DatePicker
                                className="startDate"
                                disabledDate={this.disabledDate}
                                value={this.state.startDate}
                                onChange={(e) => {
                                  this.handleStartDateChange(e);
                                }}
                              />
                            </Col>
                            <Col lg="2">
                              <label
                                className="form-control-label"
                                htmlFor="input-date"
                              >
                                To Date
                              </label>
                              <DatePicker
                                className="endDate"
                                disabledDate={this.disabledDate}
                                value={this.state.endDate}
                                onChange={(e) => {
                                  this.handleEndDateChange(e);
                                }}
                              />
                            </Col>
                            {/* <Col lg="3">
                            <label
                              className="form-control-label"
                              htmlFor="input-status"
                            >
                              Select Notification Codes
                            </label>
                          </Col> */}

                            {
                              <Col lg="4">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-status"
                                >
                                  Select Notification Codes
                                </label>
                                <Select
                                  menuPortalTarget={document.body}
                                  menuPosition={"fixed"}
                                  className="form-control-alternative"
                                  isDisabled={false}
                                  isMulti
                                  onChange={(e) => {
                                    if (e === "none") {
                                      let downloadData = JSON.parse(
                                        JSON.stringify(this.state.newTableData)
                                      );
                                      downloadData = downloadData.map((val) => {
                                        val.updatedTimeStamp = moment(
                                          val.updatedTimeStamp * 1000
                                        ).format("YYYY-MM-DD HH:mm:ss");
                                        return val;
                                      });

                                      this.setState({
                                        filterTable: null,
                                        isFilter: true,
                                        notiGrpVal: e,
                                        downloadData,
                                      });
                                      return;
                                    }

                                    this.handleChangeSelectCode(e);
                                  }}
                                  // defaultInputValue="sent"
                                  options={this.state.notificationCodes}
                                  // value={this.state.companyIdOptions}
                                  // defaultInputValue={
                                  //   this.state.notificationCodes.length > 0 &&
                                  //   this.state.notificationCodes &&
                                  //   this.state.notificationCodes[0].label
                                  // }
                                />
                              </Col>
                            }
                          </Row>
                        )}

                        {this.state.loading ? (
                          <TableShimmer />
                        ) : (
                          <Table
                            scroll={{ x: 400 }}
                            pagination={{ defaultPageSize: 5, size: "small" }}
                            columns={this.state.filterColumns}
                            dataSource={
                              this.state.filterTable === null
                                ? this.state.newTableData
                                : this.state.filterTable
                            }
                          />
                        )}

                        {/* <BootstrapTable
                          {...props.baseProps}
                          keyField="_id"
                          // rowEvents={ rowEvents }
                          data={this.state.equipments}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                          wrapperClasses="table-responsive"
                        /> */}
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Equipment */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipment Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                              disabled={this.state.readOnly}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup style={{ zIndex: 99999 }}>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Equipment Type
                            </label>
                            <ReactSelect
                              isClearable={true}
                              onChange={this.handleSelectChange(
                                "equipmentType",
                                "add"
                              )}
                              placeholder=""
                              options={this.state.equipmentTypes}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <div className="image-container-add">
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Equipment Image
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText="Choose images"
                                onChange={this.onDrop}
                                imgExtension={[".jpg", ".jpeg", ".png"]}
                                maxFileSize={2000000} //2MB
                                withPreview={true}
                                label="Max file size: 5mb, accepted: jpg|png"
                              />
                            </div>
                          </FormGroup>
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) =>
  // notificationData.notificationCodes.data

  ({
    companyData: _.isEmpty(state.companies.companyData)
      ? null
      : state.companies.companyData,
    notificationData: _.isEmpty(state.notificationData.currentNotificationData)
      ? []
      : state.notificationData.currentNotificationData,
    notificationCodes: _.isEmpty(state.notificationData.notificationCodes)
      ? null
      : state.notificationData.notificationCodes,
    notificationGroup: _.isEmpty(state.notificationData.notificationGroup)
      ? null
      : state.notificationData.notificationGroup,
    userData: _.isEmpty(state.user) ? null : state.user,
  });

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchNotification: (endPoint) => dispatch(fetchNotification(endPoint)),
    fetchNotificationCodes: (roleId) =>
      dispatch(fetchNotificationCodes(roleId)),
    fetchNotificationGroup: (companyId) =>
      dispatch(fetchNotificationGroup(companyId)),
    fetchUserDetails: (companyId) => dispatch(fetchUserDetails(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Equipments);
