import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";
import Select from "react-select";

import axios from "../../../axios";
import "./equipmentTypes.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";

import { fetchEquipmentType } from "../../../redux/actions/equipmentType.action";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";
import Helper from "../../../helper";

const { SearchBar } = Search;

const columns = [
  {
    text: "Equipment Type",
    dataField: "customName",
    sort: true,
  },
  {
    text: "Type Code",
    dataField: "equipmentTypeCode",
    sort: true,
  },
];

const options = {
  showTotal: true,
};

let portal_user = "";

class EquipmentTypes extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    equipmentTypes: null,
    list: true,
    equipmentType: {},
    saving: false,
    createAllowed: true,
  };

  async componentDidMount() {
    //Checking Authorization
    let slug = portal_user && portal_user.department && portal_user.department.slug;
    if (slug != "admin") {
      this.setState({ createAllowed: false });
    }

    // await this.getEquipmentTypes();
    this.props.fetchEquipmentType();
  }

  async getEquipmentTypes() {
    let api = await axios.getData("/equipments/types");
    // console.log(api.data.data )
    this.setState({ equipmentTypes: api.data.data });
  }

  handleChange = (input) => (e) => {
    let equipmentType = { ...this.state.equipmentType };
    equipmentType[input] = e.target.value;
    this.setState({ equipmentType: equipmentType });
  };

  handleSelectChange = () => (option) => {
    let equipmentType = { ...this.state.equipmentType };
    equipmentType.equipmentTypeMetaCode = option.value;
    this.setState({ equipmentType: equipmentType });
  };

  async handleSave() {
    this.setState({ saving: true });
    let equipmentType = { ...this.state.equipmentType };

    //Validating
    let err = await validation.validate(equipmentType);
    if (err) {
      toast.warn(err);
      this.setState({ saving: false });
      return;
    }

    let api = await axios.saveEquipmentType(equipmentType);
    if (api.status == 200) {
      toast.success("Equipment type saved successfully");
      localStorage.removeItem("companyId");
      await this.getEquipmentTypes();
      this.setState({ list: true });
      //Redirect to company details page
      // this.jump('/v1/equipment-types/' + companyId + '/' + api.data.data.equipmentTypeCode);
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status == 500) {
      toast.error("Something wet wrong. PLease try again");
    }

    this.setState({ saving: false });
  }

  jump(url) {
    this.props.history.push(url);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.equipmentTypeData !== prevState.equipmentTypeData) {
      if (nextProps.equipmentTypeData) {
        let optionsEquipTypeData = [];
        Object.keys(nextProps.equipmentTypeData.data).forEach((eq, i) => {
          if (eq !== "_id") {
            optionsEquipTypeData.push({
              key: i,
              equipmentTypeCode: `${eq}`,
              customName: eq,
            });
          }
        });
        return {
          equipmentTypes: optionsEquipTypeData,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/equipment-types/" + row.equipmentTypeCode);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
            </Col>
            <Col lg="6 table-caption">
              <h1>Equipment Types</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Equipment Types</h3>
                  </CardHeader>
                )}
                {this.state.equipmentTypes && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          // rowEvents={rowEvents}
                          pagination={paginationFactory(options)}
                          keyField="_id"
                          data={this.state.equipmentTypes}
                          columns={columns}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Equipment Types */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Equipment Type{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("customName")}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Description
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-about-company"
                              rows="8"
                              type="textarea"
                              onChange={this.handleChange("description")}
                            />
                          </FormGroup>

                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i class="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  equipmentTypeData: _.isEmpty(state.equipmentType.equipTypeData)
    ? null
    : state.equipmentType.equipTypeData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEquipmentType: () => dispatch(fetchEquipmentType()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentTypes);
