import {
  FETCH_ORGANIZATION_LIST,
  FETCH_ORGANIZATION_DETAILS,
  FETCH_ORGANIZATION_COMPANY_LIST,
  FETCH_ORGANIZATION_GROUP_LIST,
  FETCH_ORGANIZATION_MACHINE_LIST,
  FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_MACHINE,
  FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_PLANT,
  FETCH_ORGANIZATION_COMPONENT_LIST,
} from "../actions/organization.action";

const organizationReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_LIST:
      return {
        ...state,
        companyData: action.payload,
      };
    case FETCH_ORGANIZATION_DETAILS:
      return {
        ...state,
        organizationDetails: action.payload,
      };
    case FETCH_ORGANIZATION_COMPANY_LIST:
      return {
        ...state,
        organizationCompanyList: action.payload,
      };
    case FETCH_ORGANIZATION_GROUP_LIST:
      return {
        ...state,
        organizationGroupList: action.payload,
      };
    case FETCH_ORGANIZATION_MACHINE_LIST:
      return {
        ...state,
        organizationMachineList: action.payload,
      };
    case FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_PLANT:
      return {
        ...state,
        organizationEquipmentList_P: action.payload,
      };
    case FETCH_ORGANIZATION_EQUIPMENT_LIST_BY_MACHINE:
      return {
        ...state,
        organizationEquipmentList_M: action.payload,
      };
    case FETCH_ORGANIZATION_COMPONENT_LIST:
      return {
        ...state,
        organizationComponentList: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};

export default organizationReducer;
