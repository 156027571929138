import React from "react";
import { connect } from "react-redux";
import { fetchDashboardManual } from "../../../redux/actions/manuals.action";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import moment from "moment";
import MaterialIcon from "material-icons-react";
import axios from "../../../axios";
import "./Manual.css";
import isValidDomain from "is-valid-domain";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Form,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

class Manual extends React.Component {
  state = {
    manual: null,
    list: true,
    manualData: {},
    saving: false,
    fileName: null,
    file: null,
    isVisible: false,
    columns: [
      {
        text: "Title",
        dataField: "title",
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("manual/" + row._id);
          },
        },
      },
      {
        text: "Domain",
        dataField: "domain",
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("manual/" + row._id);
          },
        },
      },
      {
        text: "File Name",
        dataField: "fileName",
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("manual/" + row._id);
          },
        },
      },
      {
        text: "Upload Timestamp",
        dataField: "uploadTimestamp",
        sort: true,
        formatter: (cell, row) => {
          return <div>{moment(cell * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>;
        },
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("manual/" + row._id);
          },
        },
      },
      {
        text: "Actions",
        dataField: "_id",
        formatter: this.actionFormatter.bind(this),
        hidden: false,
      },
    ],
  };

  actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <MaterialIcon
        onClick={this.handleDelete.bind(this, row)}
        icon="delete"
        color="red"
      ></MaterialIcon>
    );
  }

  async componentDidMount() {
    this.props.fetchDashboardManual();
  }

  handleChange = (input) => (e) => {
    let manualData = { ...this.state.manualData };
    manualData[input] = e.target.value;
    this.setState({ manualData });
  };

  async handleSave() {
    this.setState({ saving: true });
    let manualData = { ...this.state.manualData };

    manualData.file = this.state.file;
    manualData.fileName = this.state.fileName;
    manualData.isVisible = this.state.isVisible;
    const uploadTimestamp = parseInt(Date.now() / 1000);
    manualData.uploadTimestamp = uploadTimestamp;
    let testGroup = { ...manualData };
    let err = await validation.validate(testGroup);
    if (err) {
      if (!manualData.title && !manualData.domain) {
        toast.warn("ALL FIELDS ARE REQUIRED");
        this.setState({ saving: false });
        return;
      }
      if (!manualData.domain) {
        toast.warn("Domain field is required");
        this.setState({ saving: false });
        return;
      }
      if (!manualData.title) {
        toast.warn("Title is required");
        this.setState({ saving: false });
        return;
      } else if (!manualData.fileName) {
        toast.warn("Upload Manual File");
        this.setState({ saving: false });
        return;
      }
    }
    let valid = isValidDomain(manualData.domain, { subdomain: true });
    if (!valid) {
      toast.warn("Enter Valid the Domain");
      this.setState({ saving: false });
      return;
    }
    let extention = manualData.fileName.split(".").pop();
    if (extention !== "pdf") {
      toast.warn("You can only upload PDF file!");
      this.setState({ saving: false });
      return;
    }
    let api = await axios.post_DashboardManual(manualData);
    this.setState({ saving: false });
    if (api.status == 200) {
      toast.success("Dashboard Manual saved successfully");
      this.setState({ list: true });
      this.props.fetchDashboardManual();
    } else if (api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
  }

  async handleDelete(manual) {
    if (window.confirm("Do you really want to delete it?")) {
      let api = await axios.delete_DashboardManual(manual._id);
      if (api.status === 200) {
        this.props.fetchDashboardManual();
        toast.success("Dashboard Manual deleted successfully");
      }

      if (api.status === 400) {
        toast.error(api.data.message);
      }

      if (api.status === 500) {
        toast.error("Something went wrong. Please try again");
      }
    }
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let manual = null;
    if (nextProps.dashboardManualData !== prevState.dashboardManualData) {
      if (nextProps.dashboardManualData) {
        manual = nextProps.dashboardManualData.data;
      }
    }
    return {
      manual: manual,
    };
  }

  handleFileInput = async (e) => {
    if (e.target.files[0] && e.target.files[0].size > 2000000) {
      alert("File Size should be less than 2MB");
      return;
    }
    let fileName = e.target.files[0] ? e.target.files[0].name : "";
    let file = "";
    if (fileName) {
      file = await this.toBase64(e.target.files[0]);
    }

    this.setState({
      fileName,
      file,
    });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  render() {
    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.list ? (
                <div
                  onClick={() => {
                    this.jump("/index");
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              ) : (
                <div
                  onClick={() => {
                    this.setState({ list: true });
                  }}
                  style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                  className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i
                    style={{ fontSize: "25px" }}
                    className="fa fa-arrow-left"
                    aria-hidden="true"
                  ></i>
                </div>
              )}
              {this.state.list && (
                <Button
                  color="success"
                  onClick={() => {
                    this.setState({ list: false });
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Manual
                </Button>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Manual</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {!this.state.list && (
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Add Manual</h3>
                  </CardHeader>
                )}
                {this.state.manual && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          pagination={paginationFactory(options)}
                          keyField="_id"
                          data={this.state.manual}
                          columns={this.state.columns}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                {/* Add Equipment Types */}

                {!this.state.list && (
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Domain:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("domain")}
                              placeholder="eg. domain.com or domain.subdomain.com"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>
                      <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Title:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              onChange={this.handleChange("title")}
                              placeholder="eg. Manual Name"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>

                      <Row>
                        <Col lg="2"></Col>
                        <Col lg="2">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Manual File:
                          </label>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="file"
                              accept=".pdf"
                              onChange={this.handleFileInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="2"></Col>
                      </Row>

                      <Row>
                        <Col lg="1"></Col>
                        <Col class="float-left">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.isVisible}
                                onChange={() => {
                                  this.setState({
                                    isVisible: !this.state.isVisible,
                                  });
                                }}
                                name="checkedB"
                                color="primary"
                                disabled={this.state.readOnly}
                              />
                            }
                            label={
                              this.state.active
                                ? "Manual State Active"
                                : "Manual State Inactive"
                            }
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                          {
                            <Button
                              className="right"
                              color="success"
                              onClick={() => this.handleSave()}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          }

                          {!this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.setState({ list: true })}
                              size="md"
                            >
                              Cancel
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardManualData: _.isEmpty(state.manual.dashboardManualData)
    ? null
    : state.manual.dashboardManualData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardManual: () => dispatch(fetchDashboardManual()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Manual);
