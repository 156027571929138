import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  Label
} from "reactstrap";

const AxisImage = () => {
  return (
    <Row>
      <Col lg="4">
        <img
          src="https://i.imgur.com/nQWIFqT.png"
          width={250}
          height={200}
          alt="NA"
        />
        <br />
        <Label>Axis Indicator</Label>
      </Col>
      <Col lg="4">
        <img
          src="https://i.imgur.com/wDpiXAz.png"
          width={250}
          height={200}
          alt="NA"
        />
        <br />
        <Label>Horizontal Asset</Label>
      </Col>
      <Col lg="4">
        <img
          src="https://i.imgur.com/jnzds9F.png"
          width={250}
          height={200}
          alt="NA"
        />
        <br />
        <Label>Vertical Asset</Label>
      </Col>
    </Row>
  );
};

export default AxisImage;
