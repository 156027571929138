import {
  FETCH_COMPANIES_DATA,
  FETCH_NOTIF_DATA,
  FETCH_COMPANY_DETAILS_DATA,
  FETCH_USERS_DATA,
  FETCH_USERS_DETAILS_DATA,
  FETCH_GROUPS_DATA,
  FETCH_GROUPS_DETAILS_DATA,
  FETCH_GROUPS_OPTION_DATA,
  FETCH_TAG_OPTIONS,
  FETCH_TAG_OPTIONS_DETAILS,
  FETCH_COMPANY_SCANNER,
  FETCH_COMPANY_SCANNER_DETAILS,
  FETCH_THEME_CONGIF,
  FETCH_LOGIN_THEME_CONFIG,
} from "../actions/companies.action";

const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_DATA:
      return {
        ...state,
        companyData: action.payload,
      };
    case FETCH_NOTIF_DATA:
      return {
        ...state,
        notifData: action.payload,
      };
    case FETCH_COMPANY_DETAILS_DATA:
      return {
        ...state,
        companyDetailsData: action.payload,
      };
    case FETCH_USERS_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case FETCH_USERS_DETAILS_DATA:
      return {
        ...state,
        userDetailsData: action.payload,
      };
    case FETCH_GROUPS_DATA:
      return {
        ...state,
        groupData: action.payload,
      };
    case FETCH_GROUPS_DETAILS_DATA:
      return {
        ...state,
        groupDetailsData: action.payload,
      };
    case FETCH_GROUPS_OPTION_DATA:
      return {
        ...state,
        groupOptionData: action.payload,
      };
    case FETCH_TAG_OPTIONS:
      return {
        ...state,
        tagOptionsData: action.payload,
      };
    case FETCH_TAG_OPTIONS_DETAILS:
      return {
        ...state,
        tagOptionsDetailsData: action.payload,
      };
    case FETCH_COMPANY_SCANNER:
      return {
        ...state,
        scannerData: action.payload,
      };
    case FETCH_COMPANY_SCANNER_DETAILS:
      return {
        ...state,
        scannerDetailsData: action.payload,
      };
    case FETCH_THEME_CONGIF:
      return {
        ...state,
        themeConfigData: action.payload,
      };
    case FETCH_LOGIN_THEME_CONFIG:
      return {
        ...state,
        loginThemeConfigData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default companyReducer;
