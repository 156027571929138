import React from "react";
import axios from "../../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import _ from "lodash";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import classes from "./sensors.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GraphShimmer from "../../../../components/Shimmer/GraphShimmer";

const { SearchBar } = Search;

let fieldMap = {
  dataPushRate: "Data Push Rate",
  dataCollectionRate: "Data Collection Rate",
  samplingRate: "Sampling Rate",
  numOfSamples: "Number of Samples",
  dataUnits: "Data Unit",
  batchSize: "Batch Size",
  maxAcceleration: "Maximum Acceleration",
  sensitivity: "Sensitivity",
  description: "Description",
};

let mountTypes = [
  {
    key: 0,
    label: "Vertical",
    value: "vertical",
  },
  {
    key: 1,
    label: "Horizontal",
    value: "horizontal",
  },
  {
    key: 2,
    label: "Axial",
    value: "axial",
  },
];

let axis = [
  {
    key: 0,
    label: "X",
    value: "x",
  },
  {
    key: 1,
    label: "Y",
    value: "y",
  },
  {
    key: 2,
    label: "Z",
    value: "z",
  },
];

let levels = {
  rul: 2,
  sensorDataProcessConfigs: 2,
  algorithmPreference: 1,
  powerEnd: 1,
};

const options = {
  showTotal: true,
};

let companyId = "";
let sensorId = "";
let is = [];

class SensorDetails extends React.Component {
  state = {
    sensors: [],
    list: true,
    saving: false,
    loading: true,
    readOnly: true,
    sensor: {},
    sensorTypes: [],
    tagOptions: [],
    //Navigations
    currentPage: 1,
    pages: [
      "pageSensorAdd",
      "pageSensorConfig",
      "pageProcessConfig",
      "pageMountConfig",
    ],

    vibration: { sensor: "vibration" },
    ultrasonic: { sensor: "ultrasonic" },
    temperature: { sensor: "temperature" },
    humidity: { sensor: "humidity" },
    adcConvertion: { adcConvertion: false },
    //Process Configuration
    processConfigurations: {
      rul: {
        clipping_thresholds: {
          lowerClipping: "",
          upperClipping: "",
        },
        faultFrequencies: {
          ftf: [],
          bpfi: [],
          bpfo: [],
          bsf: [],
        },
        faultFrequencyThresholds: {
          ftf: [],
          bpfi: [],
          bpfo: [],
          bsf: [],
        },
        faultFreqRange: "",
        maxHarmonicsCount: "",
        rpm: "",
        rpmRange: "",
        powerFunctionCoefficients: {
          alpha: [],
          beta: [],
          gamma: [],
        },
        boundLimits: {
          lowerLimit: "",
          upperLimit: "",
        },
      },
      sensorDataProcessConfigs: {
        vibration: {
          filterCutOff: "",
          filterOrder: "",
        },
        ultrasonic: {
          filterCutOff: "",
          filterOrder: "",
          maxVoltOutput: "",
          maxAdcOutput: "",
        },
      },
      algorithmPreference: {
        equipmentType: "",
        equipmentSubtype: "",
        componentType: "",
      },
    },
    //Mount Configuration
    sensorMountInfo: {
      mountMapping: {
        horizontal: "",
        vertical: "",
        axial: "",
      },
      mountType: "",
      description: "",
    },
    hasAdcConvertion: false,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId;

    this.setState({ loading: true });
    await this.getSensor();
    await this.getSensorTypes();
    await this.getUnassignedTags();
    this.setState({ loading: false });
  }

  async getSensor() {
    let endpoint = "/sensors/details/" + sensorId;
    let api = await axios.getData(endpoint);
    let data = api.data.data;
    let sensor = {
      id: data._id,
      sensorId: data.sensor.sensorId,
      sensorType: data.sensor.sensorType,
      tagId: data.tagId,
    };

    console.log(sensor);

    this.setState({ sensor: sensor, sensorBackup: sensor });

    //Seting data for sensorConfig
    let configurations = data.sensorConfig.configurations;
    this.setState({ configurations });
    // let sensors = [ "vibration", "ultrasonic", "temperature", "humidity" ];

    let sensors = this.state.sensors;
    configurations.forEach((conf) => {
      if (conf.sensor) {
        let sensor = conf.sensor.toLowerCase();
        sensors.push(sensor);
      }
    });
    this.setState({ sensors });

    sensors.forEach((sensor, i) => {
      let smallSensor = sensor;
      let capitilizeSensor = sensor.charAt(0).toUpperCase() + sensor.slice(1);

      let indexSmall = configurations.findIndex((x) => x.sensor == smallSensor);
      let indexCapitilize = configurations.findIndex(
        (x) => x.sensor == capitilizeSensor
      );
      if (indexSmall > -1)
        this.setState({ [sensor]: configurations[indexSmall] });
      if (indexCapitilize > -1) {
        this.setState({ [sensor]: configurations[indexCapitilize] });
      }
    });
    let last = configurations[configurations.length - 1];
    let adcConvertion = {};
    if ("adcConvertion" in last) {
      this.setState({ hasAdcConvertion: true });
      adcConvertion = configurations[configurations.length - 1];
    } else {
      adcConvertion = { adcConvertion: false };
      // adcConvertion = {};
    }
    this.setState({ adcConvertion });
    // console.log(adcConvertion);

    //Setting data for sensorProcessConfig
    if (data.processConfig.processConfigurations) {
      this.setState({
        processConfigurations: data.processConfig.processConfigurations,
      });
    }

    //Setting data for sensorMountConfig
    if (data.sensorMountConfig.sensorMountInfo) {
      let sensorMountInfo = data.sensorMountConfig.sensorMountInfo;
      sensorMountInfo.description = data.sensorMountConfig.description;
      this.setState({
        sensorMountInfo: data.sensorMountConfig.sensorMountInfo,
      });

      let defaultMountType = mountTypes.findIndex(
        (x) => x.value === data.sensorMountConfig.sensorMountInfo.mountType
      );
      let defaultHorizontal = axis.findIndex(
        (x) =>
          x.value ===
          data.sensorMountConfig.sensorMountInfo.mountMapping.horizontal
      );
      let defaultVertical = axis.findIndex(
        (x) =>
          x.value ===
          data.sensorMountConfig.sensorMountInfo.mountMapping.vertical
      );
      let defaultAxial = axis.findIndex(
        (x) =>
          x.value === data.sensorMountConfig.sensorMountInfo.mountMapping.axial
      );

      this.setState({
        defaultHorizontal,
        defaultVertical,
        defaultAxial,
        defaultMountType,
      });
    }
  }

  async getSensorTypes() {
    let endpoint = "/sensors/options";
    let api = await axios.getData(endpoint);
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.sensorType,
        value: sen.sensorType,
      });
    });

    if (sensorId) {
      let index = options.findIndex(
        (x) => x.value === this.state.sensor.sensorType
      );
      this.setState({
        defaultSensorType: index,
        defaultSensorTypeBackup: index,
      });
    }

    this.setState({ sensorTypes: options });
  }

  async getUnassignedTags() {
    let endpoint = "/tags/options/" + companyId + "/" + this.state.sensor.tagId;
    let api = await axios.getData(endpoint);
    let tagOptions = [];
    api.data.data.forEach((tag, i) => {
      tagOptions.push({
        key: i,
        label: tag.tagId,
        value: tag.tagId,
      });
    });

    let index = tagOptions.findIndex(
      (x) => x.value === this.state.sensor.tagId
    );
    this.setState({ defaultTagId: index, defaultTagIdBackup: index });
    this.setState({ tagOptions });
  }

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  remove = (obj, key) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.remove(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join(".")
      );
    }
    if (keySplit.length == 1) {
      // obj[key] = val;
      // console.log(obj[key]);
      delete obj[key];
      return obj[key];
    } else {
      // obj = val;
      return obj;
    }
  };

  handleRemove = (obj, key) => () => {
    let copy = { ...this.state[obj] };
    this.remove(copy, key);
    this.setState({ [obj]: copy });
  };

  add = (obj, key, field) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.add(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        field
      );
    }
    if (keySplit.length == 1 && keySplit[0] != "") {
      // obj[key] = val;
      return (obj[key][field] = "");
    } else {
      // obj = val;
      return (obj[field] = {});
    }
  };

  handleAddChange = (obj) => (e) => {
    this.setState({ [obj]: e.target.value });
  };

  handleAdd = (obj, key, type) => () => {
    let copy = { ...this.state[obj] };
    if (type === "field") {
      this.add(copy, key, this.state.field);
      this.setState({ [obj]: copy, add3: false, field: "" });
    } else if (type === "tab") {
      this.add(copy, key, this.state.tab);
      this.setState({ [obj]: copy, add1: false, tab: "" });
    }
  };

  handleChange =
    (param, input, type = "string") =>
    (e) => {
      let copy = { ...this.state[param] };
      if (type === "array") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, [val]);
      } else if (type === "checkbox") {
        this.reduce(copy, input, e.target.checked);
      } else if (type === "number") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, val);
      } else {
        this.reduce(copy, input, e.target.value);
      }
      // console.log(copy)
      this.setState({ [param]: copy });
    };

  handleSelectChange = (state, key) => async (option) => {
    let copy = { ...this.state[state] };
    copy[key] = option ? option.value : "";
    let defaultKey = "";
    let value = option ? option.value : "";
    let defaultValue = option ? option.key : 0;

    if (key === "sensorType") {
      defaultKey = "defaultSensorType";
    }

    if (key === "tagId") {
      defaultKey = "defaultTagId";
    }

    if (key === "mountType") {
      defaultKey = "defaultMountType";
    }

    if (key === "mountMapping.horizontal") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultHorizontal";
    }

    if (key === "mountMapping.vertical") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultVertical";
    }

    if (key === "mountMapping.axial") {
      delete copy[key];
      this.reduce(copy, key, value);
      defaultKey = "defaultAxial";
    }

    this.setState({ [state]: copy, [defaultKey]: defaultValue });
  };

  isValid = (type) => {
    if (type === "sensor") {
      let sensor = { ...this.state.sensor };
      if (!sensor.sensorId || !sensor.sensorType || !sensor.tagId) {
        return false;
      }
      return true;
    }
  };

  handleNext = async (page) => {
    if (this.state.currentPage === 4) {
      this.setState({ saving: true });
      localStorage.removeItem("companyId");

      //Sensor API
      let sensor = { ...this.state.sensor };
      sensor.companyId = companyId;

      //Sensor Config
      let sensorConfig = {};
      sensorConfig.tagId = this.state.sensor.tagId;
      sensorConfig.sensorId = this.state.sensor.sensorId;
      sensorConfig.configurations = [];

      //Only send the configurations which are already present in the sensor
      if (this.state.sensors.includes("vibration")) {
        sensorConfig.configurations.push(this.state.vibration);
      }

      if (this.state.sensors.includes("ultrasonic")) {
        sensorConfig.configurations.push(this.state.ultrasonic);
      }

      if (this.state.sensors.includes("temperature")) {
        sensorConfig.configurations.push(this.state.temperature);
      }

      if (this.state.sensors.includes("humidity")) {
        sensorConfig.configurations.push(this.state.humidity);
      }

      if (
        this.state.hasAdcConvertion ||
        this.state.adcConvertion.adcConvertion
      ) {
        sensorConfig.configurations.push(this.state.adcConvertion);
      }
      // console.log(sensorConfig, 'sensor Configuration');

      //Sensor Process Config
      let processConfigurations = {};
      processConfigurations.processConfigurations =
        this.state.processConfigurations;
      processConfigurations.tagId = this.state.sensor.tagId;
      processConfigurations.processConfigurations.faultDetection = {};

      // console.log(processConfigurations, 'processConfigurations');

      //Sensor Mount Config
      let sensorMountConfig = {};
      sensorMountConfig.sensorMountInfo = { ...this.state.sensorMountInfo };
      sensorMountConfig.tagId = this.state.sensor.tagId;
      sensorMountConfig.sensorId = this.state.sensor.sensorId;
      sensorMountConfig.description = this.state.sensorMountInfo.description;
      delete sensorMountConfig.sensorMountInfo.description;
      // console.log(sensorMountConfig, 'sensorMountConfiguration');

      let data = {
        sensorConfig: sensorConfig,
        sensorProcessConfig: processConfigurations,
        sensorMountConfig: sensorMountConfig,
      };

      console.log(data);
      // return;

      let sensorConfigAPI = await this.saveSensorConfig(data);
      if (sensorConfigAPI.status === 200) {
        toast.success("Sensor saved successfully.");
        this.setState({ readOnly: true, currentPage: 1, saving: false });
        this.jump("/v1/companies/" + companyId + "/sensors");
      } else if (sensorConfigAPI.status === 400) {
        toast.warning(sensorConfigAPI.message);
        this.setState({ saving: false });
      } else if (sensorConfigAPI.status === 500) {
        toast.warning("Something went wrong. Please try again.");
        this.setState({ saving: false });
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let sensor = { ...this.state.sensor };
        //Validating
        let err = await validation.validate(sensor);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleBack = (page) => {
    if (page === "pageSensorAdd") {
      this.setState({ list: true });
    }

    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  handleCancel() {
    this.setState({
      readOnly: true,
      sensor: this.state.sensorBackup,
    });
    // this.jump('/v1/sensors/' + companyId);
  }

  async saveSensor(sensor) {
    let sensorAPI = await axios.postData(
      "/sensors/" + this.state.sensor.id,
      sensor
    );
    return {
      status: sensorAPI.status,
      message: sensorAPI.data.message,
    };
  }

  async saveSensorConfig(sensorConfig) {
    let sensorConfigAPI = await axios.postData(
      "/sensors/configs",
      sensorConfig
    );
    return {
      status: sensorConfigAPI.status,
      message: sensorConfigAPI.data.message,
    };
  }

  handleUnassign = async () => {
    let data = { tagId: this.state.sensor.tagId };
    let unassign = await axios.postData("/sensors/unassign", data);
    if (unassign.status === 200) {
      toast.success("Sensor unassigned successfully.");
      this.jump("/v1/companies/" + companyId + "/sensors", 1000);
    } else if (unassign.status === 400) {
      toast.warn(unassign.data.message);
      this.setState({ saving: false });
    } else if (unassign.status === 500) {
      toast.error("Something went wrong. Please try again.");
      this.setState({ saving: false });
    }
  };

  jump(url, delay = 1000) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  render() {
    let processConfig = "";

    processConfig = Object.keys(this.state.processConfigurations).map(
      (field1, i) => {
        return (
          <TabPanel>
            {levels[field1] === 2 ? (
              <Tabs onSelect={this.handleTabChange2}>
                <TabList>
                  {Object.keys(this.state.processConfigurations[field1]).map(
                    (field2, i) => {
                      return <Tab style={{ color: "#e57373" }}>{field2}</Tab>;
                    }
                  )}
                </TabList>
                {Object.keys(this.state.processConfigurations[field1]).map(
                  (field2, i) => {
                    return (
                      <TabPanel>
                        <Row className="mt-5">
                          {Object.keys(
                            this.state.processConfigurations[field1][field2]
                          ).map((field3, i) => {
                            let type = "";
                            if (
                              field2 === "faultFrequencies" ||
                              field2 === "faultFrequencyThresholds" ||
                              field2 === "powerFunctionCoefficients"
                            ) {
                              type = "array";
                            }
                            if (
                              field2 === "clipping_thresholds" ||
                              field2 === "faultFrequencyData" ||
                              field2 === "vibration" ||
                              field2 === "ultrasonic"
                            ) {
                              type = "number";
                            }
                            if (field2 === "boundLimits") {
                              type = field3 === "lowerLimit" ? "number" : "";
                            }
                            return (
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    {field3}
                                  </label>
                                  {!this.state.readOnly && (
                                    <div
                                      onClick={this.handleRemove(
                                        "processConfigurations",
                                        `${field1}.${field2}.${field3}`
                                      )}
                                      className="icon custom-icon hardware-icon icon-shape bg-danger text-white rounded-circle shadow"
                                    >
                                      <i className="fa fa-times" />
                                    </div>
                                  )}

                                  {type === "array" || type === "number" ? (
                                    <Input
                                      className="form-control-alternative"
                                      id="input-username"
                                      type="number"
                                      disabled={this.state.readOnly}
                                      value={
                                        this.state.processConfigurations[
                                          field1
                                        ][field2][field3]
                                      }
                                      onChange={this.handleChange(
                                        "processConfigurations",
                                        `${field1}.${field2}.${field3}`,
                                        type
                                      )}
                                    />
                                  ) : (
                                    <Input
                                      className="form-control-alternative"
                                      id="input-username"
                                      type="text"
                                      disabled={this.state.readOnly}
                                      value={
                                        this.state.processConfigurations[
                                          field1
                                        ][field2][field3]
                                      }
                                      onChange={this.handleChange(
                                        "processConfigurations",
                                        `${field1}.${field2}.${field3}`,
                                        type
                                      )}
                                    />
                                  )}
                                </FormGroup>
                              </Col>
                            );
                          })}
                        </Row>
                        {!this.state.readOnly && (
                          <div
                            onClick={() => {
                              this.setState({ add3: true });
                            }}
                            className="icon custom-icon hardware-icon icon-shape bg-success text-white rounded-circle shadow"
                          >
                            <i className="fa fa-plus" />
                          </div>
                        )}
                        {this.state.add3 && (
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                Key name:
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.field}
                                onChange={this.handleAddChange("field")}
                              />{" "}
                              <br />
                              <Button
                                className={classes.deleteImage}
                                color="danger"
                                onClick={() => {
                                  this.setState({ add3: false, field: "" });
                                }}
                                size="sm"
                              >
                                Cancel
                              </Button>
                              <Button
                                className={classes.deleteImage}
                                color="success"
                                onClick={this.handleAdd(
                                  "processConfigurations",
                                  `${field1}.${field2}`
                                )}
                                size="sm"
                              >
                                Add
                              </Button>
                            </FormGroup>
                          </Col>
                        )}
                      </TabPanel>
                    );
                  }
                )}
              </Tabs>
            ) : (
              <>
                <Row className="mt-5">
                  {Object.keys(this.state.processConfigurations[field1]).map(
                    (field2, i) => {
                      return (
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label">
                              {field2}
                            </label>
                            {!this.state.readOnly && (
                              <div
                                onClick={this.handleRemove(
                                  "processConfigurations",
                                  `${field1}.${field2}`
                                )}
                                className="icon custom-icon hardware-icon icon-shape bg-danger text-white rounded-circle shadow"
                              >
                                <i className="fa fa-times" />
                              </div>
                            )}
                            <Input
                              disabled={this.state.readOnly}
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              value={
                                this.state.processConfigurations[field1][field2]
                              }
                              onChange={this.handleChange(
                                "processConfigurations",
                                `${field1}.${field2}`
                              )}
                            />
                          </FormGroup>
                        </Col>
                      );
                    }
                  )}
                </Row>

                {!this.state.readOnly && (
                  <div
                    onClick={() => {
                      this.setState({ add3: true });
                    }}
                    className="icon custom-icon hardware-icon icon-shape bg-success text-white rounded-circle shadow"
                  >
                    <i className="fa fa-plus" />
                  </div>
                )}
                {this.state.add3 && (
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Key name:</label>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        type="text"
                        value={this.state.field}
                        onChange={this.handleAddChange("field")}
                      />{" "}
                      <br />
                      <Button
                        className={classes.deleteImage}
                        color="danger"
                        onClick={() => {
                          this.setState({ add3: false, field: "" });
                        }}
                        size="sm"
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.deleteImage}
                        color="success"
                        onClick={this.handleAdd(
                          "processConfigurations",
                          `${field1}`,
                          "field"
                        )}
                        size="sm"
                      >
                        Add
                      </Button>
                    </FormGroup>
                  </Col>
                )}
              </>
            )}
          </TabPanel>
        );
      }
    );

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/sensors/" + row.sensorId);
      },
    };

    let label = "";
    if (this.state.currentPage === 1) label = "Sensor";
    else if (this.state.currentPage === 2) label = "Sensor Configurations";
    else if (this.state.currentPage === 3)
      label = "Sensor Process Configurations";
    else if (this.state.currentPage === 4)
      label = "Sensor Mount Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Sensor";
    else if (this.state.currentPage === 3) back = "Sensor Configuration";
    else if (this.state.currentPage === 4)
      back = "Sensor Process Configuration";

    let front = "";
    if (this.state.currentPage === 1) front = "Sensor Configuration";
    else if (this.state.currentPage === 2)
      front = "Sensor Process Configuration";
    else if (this.state.currentPage === 3)
      front = "Sensor Mount Configurations";
    else if (this.state.currentPage === 4) front = "Submit";

    let backURL = "";
    if (this.state.tagId) {
      backURL = "/v1/companies/" + companyId + "/sensors/" + sensorId + "/tags";
    } else {
      backURL = "/v1/companies/" + companyId + "/sensors";
    }

    return (
      <>
        <ToastContainer position="top-left" />
        {this.state.loading && (
          <GraphShimmer />
        )}
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              <div
                onClick={() => {
                  this.jump(backURL, 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
            </Col>
            <Col lg="6 table-caption">
              <h1>Sensor Configurations</h1>
            </Col>
          </Row>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {this.state.currentPage > 0 && (
                        <h3 className="mb-0">{label}</h3>
                      )}
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnly && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.setState({ readOnly: false })}
                          size="sm"
                        >
                          Edit
                        </Button>
                      )}

                      {!this.state.readOnly && (
                        <Button
                          color="danger"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to unassign the sensor?"
                              )
                            ) {
                              this.handleUnassign();
                            }
                          }}
                          size="sm"
                        >
                          <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-power-off" />
                          </div>
                          Un-assign
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Sensor Id
                                <small className="text-info"></small>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={sensorId}
                                onChange={this.handleChange(
                                  "sensor",
                                  "sensorId"
                                )}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Sensor Type
                              </label>
                              <Select
                                isClearable={false}
                                isDisabled={true}
                                onChange={this.handleSelectChange(
                                  "sensor",
                                  "sensorType"
                                )}
                                value={
                                  this.state.sensorTypes[
                                    this.state.defaultSensorType
                                  ]
                                }
                                options={this.state.sensorTypes}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Tag Id
                              </label>
                              <Select
                                isClearable={false}
                                isDisabled={true}
                                onChange={this.handleSelectChange(
                                  "sensor",
                                  "tagId"
                                )}
                                value={
                                  this.state.tagOptions[this.state.defaultTagId]
                                }
                                options={this.state.tagOptions}
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6"></Col>
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <div className="pl-lg-4">
                        <Tabs>
                          <TabList>
                            {this.state.sensors.length &&
                              this.state.sensors.map((tab, i) => (
                                <Tab key={i}>
                                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                </Tab>
                              ))}
                            <Tab>ADC Convertion</Tab>
                          </TabList>
                          <br />
                          {this.state.sensors.length &&
                            this.state.sensors.map((sensor, i) => (
                              <TabPanel key={i}>
                                {Object.keys(this.state[sensor]).map(
                                  (field, j) => {
                                    if (field != "sensor") {
                                      return (
                                        <Row key={j}>
                                          <Col lg="1"></Col>
                                          <Col lg="3">
                                            <FormGroup>
                                              <label className="form-control-label">
                                                {fieldMap[field]}:
                                              </label>
                                            </FormGroup>
                                          </Col>
                                          <Col lg="6">
                                            <FormGroup>
                                              <Input
                                                className="form-control-alternative"
                                                disabled={this.state.readOnly}
                                                id="input-username"
                                                type="text"
                                                value={
                                                  this.state[sensor][field]
                                                }
                                                onChange={this.handleChange(
                                                  sensor,
                                                  field
                                                )}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="2"></Col>
                                        </Row>
                                      );
                                    }
                                  }
                                )}
                              </TabPanel>
                            ))}

                          <TabPanel>
                            <Row>
                              <Col lg="2"></Col>
                              <Col lg="2">
                                <FormGroup>
                                  <label className="form-control-label">
                                    ADC Convertion:
                                  </label>
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <Input
                                    isDisabled={this.state.readOnly}
                                    className="form-control-alternative"
                                    id="input-username"
                                    type="checkbox"
                                    checked={
                                      this.state.adcConvertion.adcConvertion
                                    }
                                    onChange={this.handleChange(
                                      "adcConvertion",
                                      "adcConvertion",
                                      "checkbox"
                                    )}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </TabPanel>
                        </Tabs>
                      </div>
                    )}

                    {this.state.currentPage === 3 && (
                      <>
                        <div className="pl-lg-4">
                          {!this.state.readOnly && (
                            <div
                              onClick={() => {
                                this.setState({ add1: true });
                              }}
                              className="icon custom-icon hardware-icon icon-shape bg-success text-white rounded-circle shadow"
                            >
                              <i className="fa fa-plus" />
                            </div>
                          )}
                          {this.state.add1 && (
                            <>
                              <Col className="add-tab" md="12">
                                <Col md="6">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      Key name:
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-username"
                                      type="text"
                                      value={this.state.tab}
                                      onChange={this.handleAddChange("tab")}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="6">
                                  <Button
                                    className={classes.deleteImage}
                                    color="danger"
                                    onClick={() => {
                                      this.setState({ add1: false, tab: "" });
                                    }}
                                    size="sm"
                                  >
                                    Cancel
                                  </Button>

                                  <Button
                                    className={classes.deleteImage}
                                    color="success"
                                    onClick={this.handleAdd(
                                      "processConfigurations",
                                      "",
                                      "tab"
                                    )}
                                    size="sm"
                                  >
                                    Add
                                  </Button>
                                </Col>
                                <br />
                              </Col>
                            </>
                          )}
                        </div>

                        <div className="pl-lg-4">
                          <Tabs onSelect={this.handleTabChange}>
                            <TabList>
                              {/* <Tab style={{backgroundColor:'#e57373', color: '#fff'}}>RUL</Tab>
                            <Tab style={{backgroundColor:'#FFB74D', color: '#fff'}}>Sensor Data Process Configuration</Tab>
                            <Tab style={{backgroundColor:'#4FC3F7', color: '#fff'}}>Algorithm Preference</Tab> */}
                              {Object.keys(
                                this.state.processConfigurations
                              ).map((field, i) => {
                                return (
                                  <Tab
                                    style={{
                                      backgroundColor: "#FFB74D",
                                      color: "#fff",
                                    }}
                                  >
                                    {field}
                                  </Tab>
                                );
                              })}
                            </TabList>

                            {processConfig}
                          </Tabs>
                        </div>
                      </>
                    )}

                    {this.state.currentPage === 4 && (
                      <div className="pl-lg-4">
                        <>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Mount Type
                                </label>
                                <Select
                                  isClearable={false}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountType"
                                  )}
                                  value={
                                    mountTypes[this.state.defaultMountType]
                                  }
                                  options={mountTypes}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Description
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  disabled={this.state.readOnly}
                                  value={this.state.sensorMountInfo.description}
                                  onChange={this.handleChange(
                                    "sensorMountInfo",
                                    "description"
                                  )}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Horizontal
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.horizontal"
                                  )}
                                  value={axis[this.state.defaultHorizontal]}
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Vertical
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.vertical"
                                  )}
                                  value={axis[this.state.defaultVertical]}
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label className="form-control-label">
                                  Axial
                                </label>
                                <Select
                                  isClearable={true}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensorMountInfo",
                                    "mountMapping.axial"
                                  )}
                                  value={axis[this.state.defaultAxial]}
                                  options={axis}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      </div>
                    )}

                    {this.state.currentPage <= 4 && (
                      <Row>
                        <Col lg="12">
                          {this.state.currentPage < 4 && !this.state.saving && (
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                            >
                              {front}
                              <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                <i className="fa fa-forward" />
                              </div>
                            </Button>
                          )}

                          {!this.state.readOnly &&
                            this.state.currentPage === 4 && (
                              <Button
                                className="right"
                                color="success"
                                onClick={() =>
                                  this.handleNext(
                                    this.state.pages[this.state.currentPage + 1]
                                  )
                                }
                                size="md"
                                disabled={this.state.saving}
                              >
                                {this.state.saving ? (
                                  <i className="fas fa-spinner fa-pulse"></i>
                                ) : (
                                  <>
                                    Save
                                    <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                      <i className="fa fa-paper-plane" />
                                    </div>
                                  </>
                                )}
                              </Button>
                            )}

                          {this.state.currentPage > 1 && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="info"
                              onClick={() =>
                                this.handleBack(
                                  this.state.pages[this.state.currentPage]
                                )
                              }
                              size="md"
                            >
                              <div className="icon custom-icon icon-shape bg-info text-white rounded-circle shadow">
                                <i className="fa fa-backward" />
                              </div>
                              {back}
                            </Button>
                          )}

                          {!this.state.readOnly && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.handleCancel()}
                              size="md"
                            >
                              Cancel
                              <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fa fa-ban" />
                              </div>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default SensorDetails;
