import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import axios from '../../../axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

import { fetchProcessConfigData } from "../../../redux/actions/processConfiguration.action";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";

const { SearchBar } = Search;

const columns = [
  {
    text: 'Id',
    dataField: '_id',
    sort: true
  },
  {
    text: 'Process Configs',
    dataField: 'processConfig',
    sort: true
  },
  // {
  //   text: 'Sensitivity',
  //   dataField: 'sensitivity',
  //   sort: true
  // },
  // {
  //   text: 'Sampling Frequency',
  //   dataField: 'samplingFrequency',
  //   sort: true
  // },
  // {
  //   text: 'Maximum Acceleation',
  //   dataField: 'maximumAcceleration',
  //   sort: true
  // }
];

const options = {
  showTotal: true
}

let portal_user = "";

class Hardwares extends React.Component {

  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump('/auth/login') : null;

    portal_user = JSON.parse(localStorage.getItem('portal-user'));
  }

  state = {
    hardwares: null,
    list: true,
    hardware: {},
    createAllowed: true
  }

  componentDidMount() {
    
    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != 'admin') {
      this.setState({'createAllowed': false});
    }

    // await this.getHardwares();
    this.props.fetchProcessConfigData();
  }

  async getHardwares() {
    let api = await axios.getData('/process-configs');
    let hardwares = api.data.data;
    this.setState({hardwares});
  }

  handleTextChange = (state, key=null) => (e) => {
    let subject = {...this.state[state]};
    subject[key] = e.target.value;
    this.setState({[state]: subject});
  }

  jump(url) {
    this.props.history.push(url);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.firmwareTypesData !== prevState.firmwareTypesData) {
      if (nextProps.firmwareTypesData) {
        return {
          firmwareTypes: nextProps.firmwareTypesData.firmwareTypesData.data
        };
      }
    }
  }

  render() {

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump('/v1/process-configs/' + row._id);
      }
    };
      
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {/* {
                this.state.createAllowed && 
                <Button
                  color="success"
                  onClick={() => {
                    this.jump(`/v1/sensor-inventory/add`);
                  }}
                  size="md"
                  className='add-btn'
                  >
                  Add Process Configurations
                </Button>
              } */}
            </Col>
            <Col lg="6 table-caption">
                <h1>Process Configurations</h1>
            </Col>
          </Row>
         
          {/* Table */}
          <Row> 
            <div className="col">          
              <Card className="bg-secondary shadow">
              {
                this.state.hardwares && this.state.list && 
                <ToolkitProvider
                  search
                >
                  {
                    props => (
                      <div>
                          <SearchBar { ...props.searchProps } />
                          <BootstrapTable
                            { ...props.baseProps }
                            rowEvents={ rowEvents }
                            keyField='_id'
                            data={this.state.hardwares}
                            columns={columns}
                            pagination={ paginationFactory(options) }
                            striped
                            hover
                          />
                      </div>
                      )
                  }
                </ToolkitProvider>
              }
              
              </Card>
            </div>
          </Row>
          
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  firmwareTypesData: _.isEmpty(state.firmwareTypes)? null : state.firmwareTypes
});

const mapDispatchToProps = dispatch => {
  return {
    fetchProcessConfigData: obj => dispatch(fetchProcessConfigData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hardwares);
