import React from "react";
import Select from "react-select";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImageUploader from "react-images-upload";
import BootstrapTable from "react-bootstrap-table-next";
import TableHeaderColumn from "react-bootstrap-table-next";
import InsertButton from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import MaterialIcon, { colorPalette } from "material-icons-react";

import CustomDialog from "./CustomDialog";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components

import classes from "./equipments.css";
import axios from "../../../../axios";
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "../validations";

//Datatable configurations for components

const options = {
  showTotal: true,
};

//Datatable configurations end
let companyId = "";
let sensorId = "";
let status = "pending";

class NotificationDetails extends React.Component {
  constructor(props) {
    super(props);
    if (!Helper.isAuth()) {
      this.jump("/auth/login");
    }
  }

  state = {
    openDelete: false,
    openSuggestion: false,
    scanner: {
      _id: "",
      companyId: "",
      plantId: "",
      equipmentId: "",
      userId: "",
      sensorID: "",
      qrCodeID: "",
      companyName: "",
      description: "",
      plantName: "",
      equipmentName: "",
      componentName: "",
      componentRPM: "",
      componentBearingMake: "",
      componentBearingNumber: "",
      componentGearbox: "",
      componentTemp: "",
      config: {},
      addEquipmentType: "",
      addComponentType: "",
      approvedBy: {
        name: "",
        email: "",
      },
    },
    readOnly: true,
    saving: false,
    savingArchive: false,
    fetched: false,
    selectedEquipment: 0,
    selectedComponent: 0,
    equipmentTypeArray: [],
    componentTypeArray: [],
    openDialog: false,
    disapprove: {
      reason: "",
      user: "",
      noOfDays: null,
    },
    notificationDetails: "",
    suggestionExist: false,
    hideSuggestion: false,
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.id;
    status = this.props.match.params.status;
    await this.getNotificationDetails();
  }

  async getNotificationDetails() {
    let endpoint = "/notificationAlertData/sensorId/" + sensorId + "/" + status;
    let scanner = await axios.getNotification(endpoint);
    if (
      scanner.data.isNotificationReviewed === false &&
      scanner.data.reviewer === ""
    ) {
      let endpoint = "/notificationAlertData/" + scanner.data._id;
      let notificationUpdate = await axios.getNotificationUpdate(
        endpoint,
        true,
        JSON.parse(localStorage.getItem("portal-user")).name
      );
      this.setState({ notificationUpdate });
    }
    if (scanner.data.suggestedAction) {
      this.setState({ suggestionExist: true });
    }
    if (scanner.data.code === "CVFDC") {
      this.setState({ hideSuggestion: true });
    }
    this.setState({ notificationDetails: scanner.data });
  }

  // async getScanner(componentType = null) {
  //   let endpoint = '/scanners/' + companyId + "/" + sensorId
  //   let scanner = await axios.getData(endpoint);
  //   let equipmentType = await axios.getData("/equipments/types");
  //   let componentTypeDetails = await axios.getData("/components/types");

  //   let result = equipmentType.data.data
  //   let componentResult = componentTypeDetails.data.data

  //   let equipmentArray = []
  //   for (var i = 0; i < result.length; i++) {
  //     equipmentArray.push({
  //       key: i,
  //       label: result[i].customName + "->" + result[i].equipmentTypeCode,
  //       value: result[i].equipmentType,
  //     })
  //   }

  //   let componentArray = []
  //   for (var i = 0; i < componentResult.length; i++) {
  //     componentArray.push({
  //       key: i,
  //       label: componentResult[i].customName + "->" + componentResult[i].componentTypeCode,
  //       value: componentResult[i].componentType,
  //     })
  //   }

  //   let status = ""
  //   if (scanner.data.data.isAccepted)
  //   status = "Approved"
  //   if (scanner.data.data.isArchived)
  //   status = "Disapproved"
  //   if (!scanner.data.data.isArchived && !scanner.data.data.isAccepted)
  //     status = "Pending"

  //   let scannerData = {...scanner.data.data}
  //   scannerData.addEquipmentType = "MR100"
  //   scannerData.addComponentType = "MRMND"
  //   this.setState({ status: status, scanner: scannerData, equipmentTypeArray: equipmentArray, componentTypeArray: componentArray, scannerBackup: scanner.data.data, fetched: true });
  // }

  handleChange = (state, key) => (e) => {
    let subject = { ...this.state[state] };
    Helper.reduce(subject, key, e.target.value);
    this.setState({ [state]: subject });
  };

  handleSelectChange = (state, key) => (e) => {
    let subject = { ...this.state[state] };
    Helper.reduce(subject, key, e.value);

    this.setState({ [state]: subject });
  };
  handleEdit(edit) {
    this.setState({ readOnly: edit });
  }

  handleCancel() {
    let scannerBackup = { ...this.state.scannerBackup };
    this.setState({
      scanner: scannerBackup,
      readOnly: true,
    });
  }

  // handleSelectChange = (type, key=null) => async (option) => {
  //   if (type === 'equipment') {
  //     let equipment = {...this.state.equipment};
  //     if (key === 'groupCode') {
  //       equipment.groupCode = option.value;
  //       this.setState({equipment, defaultOptionGroup: option.key});
  //     }
  //     if (key === 'equipmentType') {
  //       equipment.equipmentType = option.value;
  //       this.setState({equipment, defaultOption: option.key});
  //     }
  //   } else if (type === 'component') {
  //     let selectedComponentType = option ? option.value : null
  //     await this.getComponents(selectedComponentType);
  //   }
  // }

  async handleSave() {
    let endpoint =
      "/notificationAlertData/" + this.state.notificationDetails._id;
    let notificationUpdate = await axios.getNotificationUpdateApproved(
      endpoint,
      true,
      JSON.parse(localStorage.getItem("portal-user")).name
    );
    // console.log(notificationUpdate);
    // let endpointDetails = 'http://18.236.190.74:5000/sns/api/v1/'
    // let notificationAdded = await axios.notificationAlert(endpointDetails, this.state.notificationDetails.notifierId,this.state.notificationDetails.companyId,this.state.notificationDetails.tagId,this.state.notificationDetails.subject,
    //   this.state.notificationDetails.frequency,new Date().getTime(),this.state.notificationDetails.source,"EARMS",true)
    this.jump("/v1/companies/" + companyId + "/notification/", 0);
    // console.log(notificationAdded);

    // if (this.state.scanner.newEquipment === true || this.state.scanner.newComponent === true) {
    //   this.setState({ openDelete: true })
    // }
    // else {
    //   this.setState({ saving: true });
    //   let scanner = {
    //     ...this.state.scanner
    //   };

    //   let portalUser = JSON.parse(localStorage.getItem('portal-user'))
    //   scanner.approvedBy = {
    //     name: portalUser.name,
    //     email: portalUser.email
    //   }

    //   let endpoint = '/scanners';
    //   let api = await axios.postData(endpoint, scanner, this.state.scanner._id);
    //   if (api.status == 200) {
    //     toast.success('Data saved successfully');
    //     let backUrl = "/v1/companies/" + companyId + "/scanners";
    //     localStorage.removeItem('companyId');
    //     this.jump(backUrl, 1000)
    //   } else if (api.status === 400) {
    //     toast.warn(api.data.message);
    //   } else if (api.status === 500) {
    //     toast.error("Something went wrong. Please try again.");
    //   }
    //   this.setState({ saving: false });
    // }
  }

  async handleArchive() {
    // alert("kkk")

    let endpoint = "/notificationAlertData/message";

    let notificationAdded = await axios.addNotification(
      endpoint,
      this.state.notificationDetails.notifierId,
      this.state.notificationDetails.companyId,
      this.state.notificationDetails.tagId,
      this.state.notificationDetails.sensorId,
      this.state.notificationDetails.message.default,
      this.state.notificationDetails.message.sms,
      this.state.notificationDetails.message.email,
      this.state.notificationDetails.subject,
      this.state.notificationDetails.frequency,
      this.state.notificationDetails.timestamp,
      this.state.notificationDetails.source,
      this.state.notificationDetails.code,
      this.state.notificationDetails.isNotificationApproved,
      true,
      this.state.notificationDetails.reviewer !== ""
        ? this.state.notificationDetails.reviewer
        : JSON.parse(localStorage.getItem("portal-user")).name,
      this.state.notificationDetails.approver,
      JSON.parse(localStorage.getItem("portal-user")).name,
      this.state.disapprove.reason,
      false,
      parseInt(new Date().getTime() / 1000),
      this.state.notificationDetails.suggestedAction
    );
    this.handleSaveNotificationMute();
    let deleteEndpoint =
      "/notificationAlertData/delete/" + this.state.notificationDetails._id;
    let notificationDelete = await axios.notificationDelete(deleteEndpoint);
    // console.log(notificationDelete,"testnotification");
    this.jump("/v1/companies/" + companyId + "/notification/", 0);

    // this.setState({ savingArchive: true });
    // let disapprove = {...this.state.disapprove}

    // let portalUser = JSON.parse(localStorage.getItem('portal-user'))
    // disapprove.user = {
    //   name: portalUser.name,
    //   email: portalUser.email
    // }

    // let endpoint = '/scanners/archive/' + sensorId;
    // let api = await axios.postData(endpoint, disapprove);
    // if (api.status == 200) {
    //   toast.success('Data archived successfully');
    //   let backUrl = "/v1/companies/" + companyId + "/scanners";
    //   localStorage.removeItem('companyId');
    //   this.jump(backUrl, 1000)
    // } else if (api.status === 400) {
    //   toast.warn(api.data.message);
    //   this.closeDialog();
    // } else if (api.status === 500) {
    //   toast.error("Something went wrong. Please try again.");
    // }
    // this.setState({ saving: false });
  }

  jump = (url, delay = 0) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };

  handleSaveNotificationMute = async () => {
    let endPoint = "";
    let api = {};
    const data = {
      tagId: this.state.notificationDetails.tagId,
      code: this.state.notificationDetails.code,
      noOfDays: this.state.disapprove.noOfDays,
      currentDate: parseInt(new Date().getTime() / 1000),
      updatedBy: localStorage.getItem("portal-user"),
    };

    endPoint = "/notificationMute/";
    api = await axios.postNotificationMute(endPoint, data);

    if (api && api.status == 200) {
      toast.success("Notification saved successfully");
      // this.setState({
      //   readOnlyNotificationMute: true,
      //   notificationMute: api.data.data,
      // });
    } else if (api && api.status == 400) {
      toast.warn(api.data.message);
    } else if (api.status == 500) {
      toast.error("Something wet wrong. Please try again");
    }
  };

  handleEquipmentChange = (text) => {
    this.state.scanner.addEquipmentType = text.value;
    this.setState({ selectedEquipment: text.key });
  };

  handleComponentChange = (text) => {
    this.state.scanner.addComponentType = text.value;
    this.setState({ selectedComponent: text.key });
  };

  async handleSelect() {
    this.setState({ saving: true });
    let scanner = {
      ...this.state.scanner,
    };

    let portalUser = JSON.parse(localStorage.getItem("portal-user"));
    scanner.approvedBy = {
      name: portalUser.name,
      email: portalUser.email,
    };

    let endpoint = "/scanners";
    let api = await axios.postData(endpoint, scanner, this.state.scanner._id);
    if (api.status == 200) {
      toast.success("Data saved successfully");
      let backUrl = "/v1/companies/" + companyId + "/scanners";
      localStorage.removeItem("companyId");
      this.jump(backUrl, 1000);
    } else if (api.status === 400) {
      toast.warn(api.data.message);
    } else if (api.status === 500) {
      toast.error("Something went wrong. Please try again.");
    }
    this.setState({ saving: false });
  }

  action(cell, row) {
    let _this = this;
    return <MaterialIcon icon="open_in_new" color="#0288d1"></MaterialIcon>;
  }

  openDialog = () => {
    this.setState({
      openDialog: true,
    });
  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  openSuggestion = () => {
    this.setState({
      openSuggestion: true,
    });
  };

  closeSuggestion = () => {
    this.setState({
      openSuggestion: false,
    });
  };

  saveSuggestion = async () => {
    let suggestion = this.state.notificationDetails.suggestedAction.trim();

    if (
      !this.state.notificationDetails.suggestedAction ||
      !this.state.notificationDetails.suggestedAction.trim()
    ) {
      suggestion = null;
    }
    let endpoint =
      "/notificationAlertData/suggestion/" + this.state.notificationDetails._id;
    let NotificationUpdateSuggestion = await axios.NotificationUpdateSuggestion(
      endpoint,
      suggestion
    );
    if (
      (this.state.suggestionExist && suggestion) ||
      (this.state.suggestionExist === false && suggestion)
    ) {
      this.setState({ suggestionExist: true, suggestionAction: suggestion });
    } else {
      this.setState({ suggestionExist: false, suggestionAction: suggestion });
    }
    this.closeSuggestion();
  };

  render() {
    let backUrl = "/v1/companies/" + companyId + "/notification/";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6" className="table-caption">
              {/* <h1>Equipments</h1> */}
            </Col>
            <Col lg="6 table-caption">
              <h1>Notification Data</h1>
            </Col>
          </Row>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div
                    onClick={() => {
                      this.jump(backUrl, 0);
                    }}
                    style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                    className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
                  >
                    <i
                      style={{ fontSize: "25px" }}
                      className="fa fa-arrow-left"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <Row className="align-items-center">
                    <Col xs="8"></Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="10"></Col>
                      <Col lg="2">
                        {this.state.scanner.config &&
                          this.state.fetched &&
                          (this.state.status === "Disapproved" ||
                            this.state.status === "Pending") && (
                            <Button
                              color="primary"
                              onClick={() => {
                                this.setState({
                                  readOnly: !this.state.readOnly,
                                });
                              }}
                              size="sm"
                            >
                              {this.state.readOnly ? "Edit" : "Done"}
                            </Button>
                          )}
                      </Col>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                      <Col lg="6">
                        <div className="inline">Status: </div>
                        <h4 className={"inline " + this.state.status}>
                          {this.state.status}
                          {status === "pending"
                            ? this.state.notificationDetails.approver ==
                                undefined ||
                              this.state.notificationDetails.approver == ""
                              ? "Pending"
                              : "Approved by " +
                                this.state.notificationDetails.approver
                            : status === "sent"
                            ? "Sent"
                            : "Disapproved"}
                        </h4>
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="12">
                        <div className="inline">Subject: </div>
                        <h4 className={"inline orange"}>
                          {this.state.status}
                          {this.state.notificationDetails.subject}
                        </h4>
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Company Code: </div>
                          <h4 className="inline orange">
                            {this.state.notificationDetails.companyId}
                          </h4>
                          {/* {
                            !this.state.readOnly &&
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.companyName}
                              onChange={this.handleChange('scanner', 'companyName')}
                              type="text"
                            />
                          } */}
                        </label>
                      </Col>
                    </Row>
                    <br></br>

                    <Row>
                      <Col lg="2">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline"> Message:</div>
                        </label>
                        {!this.state.readOnly && (
                          <Input
                            className="form-control-alternative max-width"
                            value={this.state.scanner.plantName}
                            onChange={this.handleChange("scanner", "plantName")}
                            type="text"
                          />
                        )}
                      </Col>
                      <Col lg="8">
                        {/* <span> */}
                        <h4>
                          {this.state.notificationDetails &&
                            this.state.notificationDetails.message.email}
                        </h4>
                        {/* </span> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Date and Time: </div>
                          <h4 className="inline orange">
                            {moment(
                              this.state.notificationDetails.timestamp * 1000
                            ).format("YYYY-MM-DD HH:mm:ss")}
                          </h4>
                          {!this.state.readOnly && (
                            <Input
                              style={{ maxWidth: "700px" }}
                              className="form-control-alternative"
                              value={this.state.scanner.description}
                              onChange={this.handleChange(
                                "scanner",
                                "description"
                              )}
                              type="textarea"
                            />
                          )}
                        </label>
                      </Col>
                    </Row>
                    <br></br>
                    {this.state.suggestionExist && (
                      <Row>
                        <Col lg="12">
                          <div className="inline">Suggested Action: </div>
                          <h4 className={"inline orange"}>
                            {this.state.notificationDetails.suggestedAction}
                          </h4>
                        </Col>
                      </Row>
                    )}
                    {/* <br></br>
                    <hr className="my-4" />
                    <h4 className=" text-muted mb-4 inline">SensorId   </h4> <h4 className="configs inline">{this.state.scanner.sensorID}</h4>
                    {
                      this.state.scanner.config &&
                      <><h4 className=" text-muted mb-4 inline"> assigned to tagId  </h4> <h4 className="configs inline">{this.state.scanner.config.tagId}</h4></>
                    }
                    <br></br>
                    <br></br>
                    <Row>
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          <div className="inline">Component RPM: </div> 
                          <h4 className="inline configs">{this.state.scanner.componentRPM}</h4>
                          {
                            !this.state.readOnly &&
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentRPM}
                              onChange={this.handleChange('scanner', 'componentRPM')}
                              type="text"
                            />
                          }
                        </label>
                      </Col>
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Component Bear Making: </div> 
                          <h4 className="inline configs">{this.state.scanner.componentBearingMake}</h4>
                          {
                            !this.state.readOnly &&
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentBearingMake}
                              onChange={this.handleChange('scanner', 'componentBearingMake')}
                              type="text"
                            />
                          }
                        </label>
                      </Col>
                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Component Bear Number: </div> 
                          <h4 className="inline configs">{this.state.scanner.componentBearingNumber}</h4>
                          {
                            !this.state.readOnly &&
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentBearingNumber}
                              onChange={this.handleChange('scanner', 'componentBearingNumber')}
                              type="text"
                            />
                          }
                        </label>
                      </Col>


                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Component Gearbox: </div> 
                          <h4 className="inline configs">{this.state.scanner.componentGearbox}</h4>
                          {
                            !this.state.readOnly &&
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentGearbox}
                              onChange={this.handleChange('scanner', 'componentGearbox')}
                              type="text"
                            />
                          }
                        </label>
                      </Col>


                      <Col lg="4">
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          <div className="inline">Component Temperature: </div> 
                          <h4 className="inline configs">{this.state.scanner.componentTemp}</h4>
                          {
                            !this.state.readOnly &&
                            <Input
                              className="form-control-alternative max-width"
                              value={this.state.scanner.componentTemp}
                              onChange={this.handleChange('scanner', 'componentTemp')}
                              type="text"
                            />
                          }
                        </label>
                      </Col>
                    </Row> */}
                  </div>
                </CardBody>
              </Card>

              <br></br>
              <Row>
                {
                  <Col lg="12">
                    {
                      <center>
                        {!this.state.hideSuggestion && status === "pending" ? (
                          this.state.notificationDetails.approver === "" ? (
                            <Button
                              color="primary"
                              onClick={() => {
                                this.openSuggestion();
                              }}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : !this.state.suggestionExist ? (
                                "Add Suggestion"
                              ) : (
                                "Update Suggestion"
                              )}
                            </Button>
                          ) : null
                        ) : null}
                        {status === "pending" ? (
                          this.state.notificationDetails.approver === "" ? (
                            <Button
                              color="success"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Do you really want to accept the data?"
                                  )
                                ) {
                                  this.handleSave();
                                }
                              }}
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Approve"
                              )}
                            </Button>
                          ) : null
                        ) : null}

                        {status === "pending" ? (
                          this.state.notificationDetails.approver === "" ? (
                            <Button
                              color="danger"
                              onClick={() => {
                                this.openDialog();
                              }}
                              size="md"
                            >
                              {this.state.savingArchive ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                "Disapprove"
                              )}
                            </Button>
                          ) : null
                        ) : null}
                      </center>
                    }
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Container>

        <Dialog
          open={this.state.openDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            style={{
              flexDirection: "row",
              width: 300,
              height: 300,
              justifySelf: "center",
              padding: 10,
            }}
          >
            {this.state.scanner.newEquipment === true ? (
              <div>
                <span>Equipment Type</span>
                {this.state.equipmentTypeArray && (
                  <Select
                    defaultValue={this.state.equipmentTypeArray[0]}
                    label="Select component"
                    onChange={(e) => this.handleEquipmentChange(e)}
                    options={this.state.equipmentTypeArray}
                    styles={{ width: 400 }}
                    value={
                      this.state.equipmentTypeArray[
                        this.state.selectedEquipment
                      ]
                    }
                  />
                )}
              </div>
            ) : null}
            {this.state.scanner.newComponent === true ? (
              <div>
                {" "}
                <span>Component Type</span>
                {this.state.componentTypeArray && (
                  <Select
                    defaultValue={this.state.componentTypeArray[0]}
                    label="Select component"
                    onChange={(e) => this.handleComponentChange(e)}
                    options={this.state.componentTypeArray}
                    styles={{ width: 400 }}
                    value={
                      this.state.componentTypeArray[
                        this.state.selectedComponent
                      ]
                    }
                  />
                )}
              </div>
            ) : null}
          </div>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ openDelete: false });
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.setState({ openDelete: false });
                this.handleSelect();
              }}
              color="primary"
              variant="contained"
              style={{ color: "#fff" }}
            >
              Select
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          scroll={"body"}
          open={this.state.openSuggestion}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {!this.state.suggestionExist
              ? "Add Suggestion"
              : "Update Suggestion"}
            <h4>{"(keep the suggestion short (<=100 characters))"}</h4>
          </DialogTitle>
          <DialogContent style={{ height: "200px" }}>
            <Input
              style={{ Width: "450px", height: "150px" }}
              className="form-control-alternative"
              value={this.state.notificationDetails.suggestedAction}
              onChange={this.handleChange(
                "notificationDetails",
                "suggestedAction"
              )}
              type="textarea"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSuggestion} color="danger">
              Cancel
            </Button>
            <Button onClick={this.saveSuggestion} color="success">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {
          // this.state.rejectOpenDialog &&
          <CustomDialog
            closeDialog={this.closeDialog}
            isDialogOpen={this.state.openDialog}
            handleChange={this.handleChange}
            handleSelectChange={this.handleSelectChange}
            notificationDetails={
              this.state.notificationDetails.isNotificationMutable
            }
            reason={this.state.disapprove.reason}
            handleArchive={() => this.handleArchive()}
          />
        }
      </>
    );
  }
}

export default NotificationDetails;
