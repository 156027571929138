import {
  FETCH_DASHBOARD_MANUAL_DATA,
  FETCH_DASHBOARD_MANUAL_DETAILS_DATA,
} from "../actions/manuals.action";

const manualReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_MANUAL_DATA:
      return {
        ...state,
        dashboardManualData: action.payload,
      };
    case FETCH_DASHBOARD_MANUAL_DETAILS_DATA:
      return {
        ...state,
        dashboardManualDetailsData: action.payload,
      };
    default:
      // need this for default case
      return state;
  }
};
export default manualReducer;
