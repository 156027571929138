import React from "react";
import { useCookies } from "react-cookie";

import axios from "../../axios";
import Helper from "../../helper";
import { connect } from "react-redux";

import { fetchOrganizationDetails } from "../../redux/actions/organization.action";
import { fetchUserDetails } from "../../redux/actions/user.action";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";

let organizationId = "";

class Login extends React.Component {
  constructor(props) {
    super(props);

    //this.props.history.push("/v1/index")
    organizationId = this.props.match.params.organizationId;
  }

  state = {
    message: null,
    saving: false,
    isCookie: Helper.getCookie("userPortalAuth"),
  };

  async componentDidMount() {}
  handleInputChange = (input) => (event) => {
    this.setState({
      [input]: event.target.value,
      message: null,
    });
  };


  setCookie = (cname, cvalue, exdays = 1, domain = '') => {
    console.log(cname,"*****",cvalue,"******",domain)
     const d = new Date();
     d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
     let expires = 'expires=' + d.toUTCString();
     let cookieString = cname + '=' + cvalue + ';' + expires + ';path=/'
     document.cookie = domain !== '' ? cookieString + ';domain=' + domain : cookieString
   };

  deleteCookie = (name) => {
    return (document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;");
  };
  clearAuth = () => {
    this.deleteCookie("userPortalAuth");
  };

  fetchUserData(email, id, password) {
    let data = {
      username: email,
      password: password,
      client_id: "dashboard",
      token_decode: true,
      category: "Portal",
      sub_category: "Organic",
    };
    this.setCookie("userName", email, 365, ".nanoprecisedataservices.com");
    axios
      .post_organizationLogin(id, data)
      .then((res) => {
        this.setCookie(
          "access_token",
          res && res.data && res.data.access_token,
          365,
          ".nanoprecisedataservices.com"
        );
        this.setCookie(
          "refresh_token",
          res && res.data && res.data.refresh_token,
          365,
          ".nanoprecisedataservices.com"
        );
        if (res && res.status !== 200) {
          console.log("Error In Organinzation", res);
          this.setState({ saving: false });
        } else if (res && res.status == 200) {
          setTimeout(() => {
            axios
              .fetchUserInfo(id)
              .then((userCred) => {
                console.log("userCred", userCred);
                this.props.fetchUserDetails(id);
                if (userCred.status === 200) {
                  this.setCookie(
                    "userPortalAuth",
                    true,
                    365,
                    ".nanoprecisedataservices.com"
                  );
                  let newUser = {
                    ...userCred.data,
                    department: {
                      slug: "admin",
                      notAllowed: [],
                    },
                  };
                  if (
                    userCred &&
                    userCred.data &&
                    userCred.data.roleID === 137
                  ) {
                    toast.error("Blink up user not allowed to login");
                    return;
                  }
                  if (
                    !userCred.data.userLevel.toLowerCase().includes("master")
                  ) {
                    this.clearAuth();
                    this.setState({ saving: false });
                    return toast.warn("Only master level user is allowed");
                  }
                  localStorage.setItem("portal-user", JSON.stringify(newUser));
                  this.props.history.push("/v1/index");
                  // if (userCred.data.roleName.toLowerCase().includes("admin")) {
                  //   this.props.history.push("/v1/index");
                  // }
                } else {
                  console.log("Error...");
                  this.setState({ saving: false });
                }
              })
              .catch((userCredError) => {
                console.error(
                  "Error fetching user credentials:",
                  userCredError.message
                );
                this.setState({ saving: false });
              });
          }, 500);
        } else if ((res && res.status == 400) || typeof res === "undefined") {
          console.log("Error In Organinzation", res);
          this.setState({ saving: false });
          return toast.warn("Incorrect Email/Username or Password");
        } else {
          this.setState({ saving: false });
          return toast.warn("Failed to Login");
        }
      })
      .catch((orgIdError) => {
        console.log("Error fetching organization ID:", orgIdError);
        this.setState({ saving: false });
        return toast.warn("Failed to Sign in");
      });
  }
  async clickLogin() {
    console.log({ props: this.props });
    if (!this.state.email) {
      return toast.warn(" Please enter Email/Username");
    }
    if (this.state.email.length < 4 || this.state.email.length > 32) {
      return toast.warn("Email/Username Should be between 4 to 32 characters");
    }
    if (!this.state.password) {
      return toast.warn("Please enter password");
    }
    if (this.state.password.length < 8 || this.state.password.length > 32) {
      return toast.warn("Password Should be between 8 to 32 characters");
    }
    if (!this.state.organizationId) {
      return toast.warn("Please enter an Organization Name");
    } else if (this.state.organizationId.toLowerCase() !== "master") {
      return toast.warn("Only master user are allowed");
    }

    if (this.state.organizationId === "master") {
      this.setState({ saving: true });
      let email = this.state.email;
      let password = this.state.password;
      let organizationId = this.state.organizationId;
      // this.setCookie(
      //   "organizationId",
      //   "master",
      //   365,
      //   ".nanoprecisedataservices.com"
      // );
      this.setCookie(
        "orgId",
        "master",
        365,
        ".nanoprecisedataservices.com"
      );
      this.fetchUserData(email, organizationId, password);
    }
  }

  render() {
    return (
      <>
        <ToastContainer position="top-left" />

        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            {/* <CardHeader className="bg-transparent pb-5">
            </CardHeader> */}
            <CardBody className="px-lg-5 py-lg-5">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email/Username"
                    type="email"
                    onChange={this.handleInputChange("email")}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    onChange={this.handleInputChange("password")}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Organization Name"
                    type="text"
                    onChange={this.handleInputChange("organizationId")}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                {/* <InputGroup className="input-group-alternativ"> */}
                <center>
                  <small
                    style={{
                      color: "red",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.props.history.push("/auth/forgot-password");
                    }}
                  >
                    Forgot Password?
                  </small>
                </center>
                {/* </InputGroup> */}
              </FormGroup>

              <p className="text-center text-danger font-weight-500 h5">
                {this.state.message}
              </p>

              <div className="text-center">
                <Button
                  onClick={() => this.clickLogin()}
                  className="my-4"
                  color="primary"
                  type="button"
                >
                  {this.state.saving ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                {/* <small>Forgot password?</small> */}
              </a>
            </Col>
            {/* <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col> */}
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserDetails: (organizationId) =>
      dispatch(fetchUserDetails(organizationId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
