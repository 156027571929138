import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "../../../axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import MaterialIcon, { colorPalette } from "material-icons-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../helper";
import { fetchSensorTypes } from "../../../redux/actions/sensorTypes.action";

const { SearchBar } = Search;

const options = {
  showTotal: true,
};

let portal_user = "";

class SensorTypes extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    sensorTypes: null,
    list: true,
    hardware: {},
    createAllowed: true,
    columns: [
      {
        text: "Id",
        dataField: "_id",
        sort: true,
        formatter: this.copyIcon,
        // events: {
        //   onClick: (e, col, colIndex, row, rowIndex) => {
        //     this.jump('/v1/sensor-inventory/' + row._id);
        //   }
        // }
      },
      {
        text: "Sensor Types",
        dataField: "sensorType",
        sort: true,
        events: {
          onClick: (e, col, colIndex, row, rowIndex) => {
            this.jump("/v1/sensor-inventory/" + row._id);
          },
        },
      },
      {
        text: "Actions",
        formatter: this.actionFormatter.bind(this),
        hidden:
          JSON.parse(localStorage.getItem("portal-user")).department.slug ===
            "admin" || "root"
            ? false
            : true,
      },
    ],
  };

  actionFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <MaterialIcon
        onClick={this.handleDelete.bind(this, row)}
        icon="delete"
        color="red"
      ></MaterialIcon>
    );
  }

  copyIcon(cell, row) {
    let _this = this;
    return (
      <span>
        {row._id}{" "}
        <CopyToClipboard text={row._id} onCopy={() => toast("Copied!!!")}>
          <MaterialIcon icon="content_copy" color="#0288d1" />
        </CopyToClipboard>
      </span>
    );
  }

  async componentDidMount() {
    //Checking Authorization
    let slug = portal_user.department.slug;
    if (slug != "admin") {
      //Hiding action column from table for normal users
      let col = [...this.state.columns];
      // col[2].hidden = true;

      this.setState({ createAllowed: false, columns: col });
    }
    this.props.fetchSensorTypes();
  }

  handleTextChange =
    (state, key = null) =>
    (e) => {
      let subject = { ...this.state[state] };
      subject[key] = e.target.value;
      this.setState({ [state]: subject });
    };

  async handleDelete(sensorType) {
    if (window.confirm("Do you really want to delete it?")) {
      //Calling backend API to delelte firmwareTypes
      let api = await axios.delete_SensorType(sensorType._id);
      if (api.status === 200) {
        //Remove from state

        this.props.fetchSensorTypes();

        toast.success("Data deleted successfully");
      }

      if (api.status === 400) {
        toast.error(api.data.message);
      }

      if (api.status === 500) {
        toast.error("Something went wrong. Please try again");
      }
    }
  }

  jump(url) {
    this.props.history.push(url);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.sensorTypesData !== prevState.sensorTypesData) {
      if (nextProps.sensorTypesData) {
        return {
          sensorTypes:
            nextProps.sensorTypesData.sensorTypesData &&
            nextProps.sensorTypesData.sensorTypesData.data,
        };
      }
    }
  }

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/sensor-inventory/" + row._id);
      },
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6">
              {this.state.createAllowed && (
                <Button
                  color="success"
                  onClick={() => {
                    this.jump(`/v1/sensor-inventory/add`);
                  }}
                  size="md"
                  className="add-btn"
                >
                  Add Sensor Type
                </Button>
              )}
            </Col>
            <Col lg="6 table-caption">
              <h1>Sensor Types</h1>
            </Col>
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.sensorTypes && this.state.list && (
                  <ToolkitProvider search>
                    {(props) => (
                      <div>
                        <SearchBar {...props.searchProps} />
                        <BootstrapTable
                          {...props.baseProps}
                          keyField="_id"
                          data={this.state.sensorTypes}
                          columns={this.state.columns}
                          pagination={paginationFactory(options)}
                          striped
                          hover
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sensorTypesData: _.isEmpty(state.sensorTypes) ? null : state.sensorTypes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSensorTypes: (obj) => dispatch(fetchSensorTypes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorTypes);
