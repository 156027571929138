import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { DatePicker } from "antd";

import ImageUploader from "react-images-upload";
import "./equipments.css";
import axios from "../../../../axios";
import { fetchFeedbackLog } from "../../../../redux/actions/feedback.action";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

// core components
import Header from "components/Headers/Header.jsx";
import Helper from "../../../../helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validation from "./validation";
import moment from "moment";

const { SearchBar } = Search;

const columns = [
  {
    text: "Tag Id",
    dataField: "tagId",
    sort: true,
  },
  {
    text: "Feedback Time",
    dataField: "maintenanceTimestamp",
    sort: true,
  },
  {
    text: "User Given Feedback",
    dataField: "user",
    sort: true,
  },
  {
    text: "Company Id",
    dataField: "companyId",
    sort: true,
  },
  {
    text: "Maintenance Type",
    dataField: "maintenanceType",
    sort: true,
  },
  {
    text: "Equipment Name",
    dataField: "equipmentName",
    sort: true,
  },
  {
    text: "Feedback",
    dataField: "feedback",
    sort: true,
  },
  {
    text: "Resolved",
    dataField: "resolved",
    sort: true,
  },
  {
    text: "Feedback Id",
    dataField: "feedbackId",
    sort: true,
  },
  {
    text: "Portal Comments",
    dataField: "portalComments",
    sort: true,
  },
];

const options = {
  showTotal: true,
};

let companyId = "";
let machineId = "";

class Feedback extends React.Component {
  constructor(props) {
    super(props);

    if (!Helper.isAuth()) {
      this.jump("/auth/login", 0);
    }
  }

  state = {
    endDate: moment(new Date(), "DD MM YYYY hh:mm:ss"),
    startDate: moment(
      new Date(
        new Date(new Date() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
      ),
      "DD MM YYYY hh:mm:ss"
    ),
    scanners: null,
    saving: false,
    isArchived: false,
    status: [
      {
        key: 0,
        label: "Pending",
        value: "pending",
      },
      {
        key: 1,
        label: "Approved",
        value: "approved",
      },
      {
        key: 2,
        label: "Disapproved",
        value: "disapproved",
      },
    ],
    tagGroupData: null,
    activeTab: "1",
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    let data = "false";
    if (this.props.location.state) {
      if (this.props.location.state.archive) {
        data = "true";
        this.setState({ isArchived: true, activeTab: "2" });
      }
    }

    // await this.getFeedbackList(data); //Get Scanners
    const end_date = moment(new Date()).toISOString();
    const start = moment(
      new Date(
        new Date(new Date() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)
      ),
      "DD MM YYYY hh:mm:ss"
    );
    const start_date = start.toISOString();
    // this.props.fetchFeedbackLog(companyId, data, end_date, start_date);
    let resp = await axios.get_feedBack(companyId, data, end_date, start_date);
    if (resp.status === 200) {
      this.setState({
        scanners: resp.data.items,
      });
    } else {
      toast.error("Failed to fetch feedback data");
    }
  }

  // async getFeedbackList(data = "false") {
  //   let endpoint = "/feedbackLog/" + companyId + "/" + data;
  //   let scanners = await axios.getData(endpoint);
  //   this.setState({ scanners: scanners.data.data });
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.feedbackLogData !== prevState.feedbackLogData) {
      if (
        nextProps.feedbackLogData &&
        nextProps.feedbackLogData.feedbackLogData
      ) {
        return {
          scanners: _.uniqBy(
            nextProps.feedbackLogData.feedbackLogData.items,
            "tagId"
          ),
          tagGroupData: _.groupBy(
            nextProps.feedbackLogData.feedbackLogData.items,
            (tag) => tag.tagId
          ),
        };
      }
    }
  }

  handleSelectChange = () => async (option) => {
    await this.getScanners(option.value);
  };

  jump = (url, delay = 1000) => {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  };
  toggle = async (tab) => {
    if (tab == 1) {
      const resp = await axios.get_feedBack(
        companyId,
        "false",
        this.state.endDate.toISOString(),
        this.state.startDate.toISOString()
      );
      this.setState({ scanners: resp.data && resp.data.items });
    } else {
      const resp = await axios.get_feedBack(
        companyId,
        "true",
        this.state.endDate.toISOString(),
        this.state.startDate.toISOString()
      );
      this.setState({ scanners: resp.data && resp.data.items });
    }
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  handleStartDateChange = async (date, bool) => {
    const resp = await axios.get_feedBack(
      companyId,
      bool,
      this.state.endDate.toISOString(),
      date.toISOString()
    );
    if (resp && resp.data && resp.data.status == 400) {
      toast.warn(resp.data.message);
      this.setState({ scanners: [] });
      return;
    }

    this.setState({ startDate: date, scanners: resp.data.items });
  };
  handleEndDateChange = async (date, bool) => {
    const resp = await axios.get_feedBack(
      companyId,
      bool,
      date.toISOString(),
      this.state.startDate.toISOString()
    );
    if (resp && resp.data && resp.data.status == 400) {
      toast.warn(resp.data.message);
      this.setState({ scanners: [] });
      return;
    }
    this.setState({ endDate: date, scanners: resp.data.items });
  };

  render() {
    // const startDateReadable = moment(this.state.startDate).format("YYYY-MM-DD");
    // const endDateReadable = moment(this.state.endDate).format("YYYY-MM-DD");
    // console.log(
    //   "startDateReadable",
    //   startDateReadable,
    //   "endDateReadable",
    //   endDateReadable
    // );
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.props.history.push(
          "/v1/companies/" + companyId + "/feedbacks/" + row.tagId,
          row
        );
      },
    };

    const expandRow = {
      renderer: (row) => (
        <div>
          <BootstrapTable
            keyField="_id"
            rowEvents={rowEvents}
            data={this.state.tagGroupData[row.tagId]}
            columns={columns}
            striped
            hover
          />
        </div>
      ),
      showExpandColumn: true,
      expandByColumnOnly: true,
      onlyOneExpanding: true,
    };

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--8" fluid>
          <Row>
            <Col lg="8">
              <div
                onClick={() => {
                  this.props.history.goBack();
                }}
                style={{
                  width: "2rem",
                  height: "2rem",
                  cursor: "pointer",
                }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
            </Col>
            <Col lg="4" className="table-caption mb-1">
              <h1>Feedback</h1>
            </Col>

            {/* <Col lg="4">
              <Select
                className='add-btn'
                onChange={this.handleSelectChange('status')}
                placeholder="Status"
                options={this.state.status}
                defaultValue={this.state.status[0]}
              />
            </Col> */}
          </Row>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                {this.state.scanners && (
                  <ToolkitProvider
                    keyField="_id"
                    columns={columns}
                    data={this.state.scanners}
                    search
                  >
                    {(props) => (
                      <div>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === "1",
                              })}
                              onClick={() => {
                                this.toggle("1");
                              }}
                            >
                              Unresolved
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: this.state.activeTab === "2",
                              })}
                              onClick={() => {
                                this.toggle("2");
                              }}
                            >
                              Archived
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <SearchBar {...props.searchProps} />
                            <Row>
                              <Col lg="2">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-date"
                                >
                                  From Date
                                </label>
                                <DatePicker
                                  className="startDate"
                                  // disabledDate={this.disabledDate}
                                  value={this.state.startDate}
                                  onChange={(e) => {
                                    this.handleStartDateChange(e, "false");
                                  }}
                                />
                              </Col>
                              <Col lg="2">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-date"
                                >
                                  To Date
                                </label>
                                <DatePicker
                                  className="endDate"
                                  // disabledDate={this.disabledDate}
                                  value={this.state.endDate}
                                  onChange={(e) => {
                                    this.handleEndDateChange(e, "false");
                                  }}
                                />
                              </Col>
                            </Row>
                            <BootstrapTable
                              {...props.baseProps}
                              keyField="_id"
                              rowEvents={rowEvents}
                              data={this.state.scanners}
                              columns={columns}
                              pagination={paginationFactory(options)}
                              expandRow={expandRow}
                              striped
                              hover
                            />
                          </TabPane>
                        </TabContent>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="2">
                            <SearchBar {...props.searchProps} />
                            <Row>
                              <Col lg="2">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-date"
                                >
                                  From Date
                                </label>
                                <DatePicker
                                  className="startDate"
                                  // disabledDate={this.disabledDate}
                                  value={this.state.startDate}
                                  onChange={(e) => {
                                    this.handleStartDateChange(e, "true");
                                  }}
                                />
                              </Col>
                              <Col lg="2">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-date"
                                >
                                  To Date
                                </label>
                                <DatePicker
                                  className="endDate"
                                  // disabledDate={this.disabledDate}
                                  value={this.state.endDate}
                                  onChange={(e) => {
                                    this.handleEndDateChange(e, "true");
                                  }}
                                />
                              </Col>
                            </Row>
                            <BootstrapTable
                              {...props.baseProps}
                              keyField="_id"
                              data={this.state.scanners}
                              columns={columns}
                              pagination={paginationFactory(options)}
                              expandRow={expandRow}
                              striped
                              hover
                            />
                          </TabPane>
                        </TabContent>

                        {
                          !this.state.isArchived
                            ? null
                            : // <Button
                              //   color="primary"
                              //   onClick={async () => {
                              //     // let endpoint =
                              //     //   "/feedbackLog/" + companyId + "/true";
                              //     // let scanners = await axios.getData(endpoint);
                              //     this.props.fetchFeedbackLog(companyId, "true");
                              //     this.setState({
                              //       isArchived: true,
                              //     });
                              //   }}
                              // >
                              //   Archived
                              // </Button>
                              null
                          // <Button
                          //   color="primary"
                          //   onClick={async () => {
                          //     // let endpoint =
                          //     //   "/feedbackLog/" + companyId + "/flase";
                          //     // let scanners = await axios.getData(endpoint);
                          //     this.props.fetchFeedbackLog(companyId, "false");
                          //     this.setState({
                          //       isArchived: false,
                          //     });
                          //   }}
                          // >
                          //   Unresolved
                          // </Button>
                        }

                        {/* <SearchBar {...props.searchProps} /> */}
                        {/* <BootstrapTable
                          {...props.baseProps}
                          keyField="_id"
                          rowEvents={rowEvents}
                          data={this.state.scanners}
                          columns={columns}
                          pagination={paginationFactory(options)}
                          expandRow={expandRow}
                          striped
                          hover
                        /> */}
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  feedbackLogData: _.isEmpty(state.feedback) ? null : state.feedback,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFeedbackLog: (companyId, isArchived) =>
      dispatch(fetchFeedbackLog(companyId, isArchived)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
