import CallAPI from "../../axios";

export const FETCH_FIRMWARE_TYPES = "FETCH_FIRMWARE_TYPES",
  FETCH_FIRMWARE_DETAILS = "FETCH_FIRMWARE_DETAILS";

const fetchData = (data) => {
  return {
    type: FETCH_FIRMWARE_TYPES,
    payload: data,
  };
};

const fetchDetailsData = (data) => {
  return {
    type: FETCH_FIRMWARE_DETAILS,
    payload: data,
  };
};

export const fetchFirmwareTypes = () => {
  return (dispatch) => {
    CallAPI.getFirmwareTypes()
      .then((response) => {
        dispatch(fetchData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetchFirmwareTypesDetails = (firmwareId) => {
  return (dispatch) => {
    CallAPI.getFirmwareType_byId(firmwareId)
      .then((response) => {
        dispatch(fetchDetailsData(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
