import {
  FETCH_MACHINE_TYPES,
  FETCH_MACHINE_TYPES_DETAILS,
  FETCH_MACHINE_OPTIONS_DETAILS,
  FETCH_MACHINE,
  FETCH_MACHINE_DETAILS
} from "../actions/machineType.action";

const machineTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MACHINE_TYPES:
      return {
        ...state,
        machineTypeData: action.payload,
      };
    case FETCH_MACHINE_TYPES_DETAILS:
      return {
        ...state,
        machineTypeDetailsData: action.payload,
      };
      case FETCH_MACHINE_OPTIONS_DETAILS:
        return {
          ...state,
          machineOptionsData: action.payload
        }
      case FETCH_MACHINE:
        return {
          ...state,
          machineData: action.payload
        }
      case FETCH_MACHINE_DETAILS:
        return {
          ...state,
          machineDetailsData: action.payload
        }
    default:
      // need this for default case
      return state;
  }
};
export default machineTypeReducer;