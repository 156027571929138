import React from "react";
import { Form, Input, Button, Typography, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import BearingCustom from "./bearingCustom";
import API from "../../../../../axios";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    },
  },
  wrapperCol: {
    xs: {
      span: 12,
    },
    sm: {
      span: 12,
    },
  },
};

const rpmOut = (rpmInMin, T1, T2) => Math.round(rpmInMin * (T1 / T2));

const isMaxGreaterThanMin = (min, max) => {
  if (Number(min) >= Number(max))
    return Promise.reject("Max RPM should be greater than Min RPM");

  return Promise.resolve();
};

const GMFCustom = ({ options, value, ...props }) => {
  // console.log({ props });
  const {isConstantSpeed} = props
  const getRpmOut = (index, minCalculationFlag) => {
    const Val = value[index];
    if (Val === undefined) return "";

    const { noOfTeeth1: T1, noOfTeeth2: T2, rpmInMin, rpmInMax } = Val;
   
    if(T1 && T2 && minCalculationFlag && rpmInMin ){
      return rpmOut(rpmInMin , T1, T2)
    }else if(T1 && T2 && !minCalculationFlag && rpmInMax){
      return rpmOut(rpmInMax , T1, T2)
    }
    return ""
  };

  const getBearingList = async (inputValue) => {
    if (inputValue.length >= 2) {
      const response = await API.bearing_list(inputValue);
      if (response && response.status && response.status === 200) {
        const bearingData = response.data && response.data.data;
        const OptionBearingListTemp = bearingData.map((bearing) => ({
          value: `${bearing.BearingNumber} || ${bearing.BearingMake}`,
          label: `${bearing.BearingNumber} || ${bearing.BearingMake}`,
        }));
        return [
          {
            label: "NA",
            value: "NA",
          },
          ...OptionBearingListTemp,
        ];
      }
      return [{
        label: 'NA',
        value: 'NA'
      }];
    }
    return [{
      label: 'NA',
      value: 'NA'
    }];
  };


  const loadOptions = (
    inputValue
  ) => new Promise((resolve) => {
      resolve(getBearingList(inputValue));
  });
  return (
    <Form.List
      name={props.id}
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length < 0) {
              return Promise.reject(new Error("At least 1 bearing"));
            }
          },
          type: "array",
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <label>
            ( input rpm , number of teeth T1 and T2, output rpm , bearing
            type/number)
          </label>
          <img
            src={"https://i.imgur.com/csHWcCy.jpg"}
            width={250}
            height={200}
            alt="NA"
          />
          {fields.map((field, index) => (
            <Form.Item required={false} name={`${index}`} key={field.key}>
              <Form.Item
                noStyle
                {...formItemLayout}
                label={"Number of teeth T1"}
                name={[field.name, "noOfTeeth1"]}
                rules={[
                  {
                    required: true,
                    message: "Please input Number of teeth T1",
                  },
                ]}
              >
                <Input placeholder="Number of teeth T1" />
              </Form.Item>
              <Form.Item
                noStyle
                {...formItemLayout}
                label={"Number of teeth T2"}
                name={[field.name, "noOfTeeth2"]}
                rules={[
                  {
                    required: true,
                    message: "Please input Number of teeth T2",
                  },
                ]}
              >
                <Input placeholder="Number of teeth T2" />
              </Form.Item>

               {isConstantSpeed ?
                  <> 
                    <Form.Item
                      noStyle
                      {...formItemLayout}
                      label={'Input RPM'}
                      name={[field.name, "rpmInMin"]}
                      rules={[
                        {
                          required: true,
                          message: `Please input RPM`,
                        },
                      ]}
                    >
                      <InputNumber placeholder={'Input RPM'} style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      {...formItemLayout}
                      name={[field.name, "rpmOut"]}
                    >
                      <Typography.Text>
                        Output RPM{getRpmOut(index, true)=="" ? '?' : `: ${getRpmOut(index, true)}`}
                      </Typography.Text>
                    </Form.Item>
                  </>
                : <>
                    <Form.Item
                      noStyle
                      {...formItemLayout}
                      label={'What is the min RPM?'}
                      name={[field.name, "rpmInMin"]}
                      dependencies={[[props.id, field.name, "rpmInMax"]]}
                      rules={  [
                        {
                              required: true,
                              message: `Please input ${isConstantSpeed ? 'RPM' : "min RPM"}`,
                        }
                      ]}
                    >
                      <InputNumber placeholder={`${isConstantSpeed ? 'Input RPM' : 'What is the min RPM?'}`} style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      {...formItemLayout}
                      name={[field.name, "rpmOut"]}
                    >
                      <Typography.Text>
                        Output{isConstantSpeed ? ' RPM' : ' min RPM'}{getRpmOut(index, true)=="" ? '?' : `: ${getRpmOut(index, true)}`}
                      </Typography.Text>
                    </Form.Item>
                    <Form.Item
                      noStyle
                      {...formItemLayout}
                      label={"What is the max RPM?"}
                      name={[field.name, "rpmInMax"]}
                      dependencies={[[props.id, field.name, "rpmInMin"]]}
                      rules={[
                        {
                          required: true,
                          message: "Please input max RPM",
                        },
                        ({ getFieldValue }) => ({
                          async validator(_, value) {
                          return isMaxGreaterThanMin(getFieldValue([props.id, field.name, 'rpmInMin']), value)
                          }
                        })
                      ]}
                    >
                      <InputNumber placeholder="What is the max RPM?" style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      {...formItemLayout}
                      name={[field.name, "rpmMaxOut"]}
                    >
                      <Typography.Text>
                        Output max RPM{getRpmOut(index, false)=="" ? '?' : `: ${getRpmOut(index, false)}`}
                      </Typography.Text>
                    </Form.Item>
                  </>
              }
              <Form.Item
                {...formItemLayout}
                noStyle
                name={[field.name, "bearingSelect"]}
                rules={[
                  {
                    required: true,
                    message: "Please select bearing details",
                  },
                ]}
              >
                <BearingCustom
                  isMulti={true}
                  showSearch
                  loadOptions={loadOptions}
                  name={`GearBoxBearing${field.name}`}
                  onChange={(e, obj)=>props.onChange(e, obj, value)}
                  {...{ options }}
                />
              </Form.Item>

              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{
                width: "60%",
              }}
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default GMFCustom;
