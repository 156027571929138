import React from "react";
import { connect } from "react-redux";
import axios from "../../../axios";
import Select from "react-select";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import classes from "./process.css";
import "./index.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";
import Helper from "../../../helper";

import { ToastContainer, toast } from "react-toastify";
import { fetchProcessConfigData } from "../../../redux/actions/processConfiguration.action";
import "react-toastify/dist/ReactToastify.css";

const { SearchBar } = Search;

let levels = {
  rul: 2,
  sensorDataProcessConfigs: 2,
  algorithmPreference: 1,
  powerEnd: 1,
  faultDetection: 1,
};

let companyId = "";
let id = "";
let portal_user = "";

class SensorDetails extends React.Component {
  constructor(props) {
    super(props);
    let t = !Helper.isAuth() ? this.jump("/auth/login") : null;

    portal_user = JSON.parse(localStorage.getItem("portal-user"));
  }

  state = {
    saving: false,
    readOnly: true,
    process: {},
    //Navigations
    currentPage: 2,
    pages: [
      "pageSensorAdd",
      "pageSensorConfig",
      "pageProcessConfig",
      "pageMountConfig",
    ],
    //Process Configuration
    processConfigurations: {
      rul: {
        clipping_thresholds: {
          lowerClipping: "",
          upperClipping: "",
        },
        faultFrequencies: {
          ftf: [],
          bpfi: [],
          bpfo: [],
          bsf: [],
        },
        faultFrequencyThresholds: {
          ftf: [],
          bpfi: [],
          bpfo: [],
          bsf: [],
        },
        faultFreqRange: "",
        maxHarmonicsCount: "",
        rpm: "",
        rpmRange: "",
        powerFunctionCoefficients: {
          alpha: [],
          beta: [],
          gamma: [],
        },
        boundLimits: {
          lowerLimit: "",
          upperLimit: "",
        },
      },
      sensorDataProcessConfigs: {
        vibration: {
          filterCutOff: "",
          filterOrder: "",
        },
        ultrasonic: {
          filterCutOff: "",
          filterOrder: "",
          maxVoltOutput: "",
          maxAdcOutput: "",
        },
      },
      algorithmPreference: {
        equipmentType: "",
        equipmentSubtype: "",
        componentType: "",
      },
    },
    open: false,
    createAllowed: true,
    expanded: false,
    add5: false,
    windowTypeOptions: [
      {
        key: 0,
        label: "Hanning",
        value: "hanning",
      },
      {
        key: 1,
        label: "Hamming",
        value: "hamming",
      },
      {
        key: 2,
        label: "Rectangular",
        value: "rectangular",
      },
    ],
  };

  async componentDidMount() {
    //Checking Authorization
    let slug = portal_user && portal_user.department && portal_user.department.slug;
    if (slug != "admin") {
      this.setState({ createAllowed: false });
    }

    id = "5db948b6c77abe4a5784603f";
    // await this.getSensor();
    this.props.fetchProcessConfigData(id);
  }

  async getSensor() {
    let endpoint = "/process-configs/" + id;
    let api = await axios.getData(endpoint);
    let data = api.data.data;
    let process = {
      _id: data._id,
      processConfig: data.processConfig,
      description: data.description,
    };

    this.setState({ process: process, processBackup: process });

    //Setting data for sensorProcessConfig
    if (data.processConfigurations) {
      this.setState({ processConfigurations: data.processConfigurations });
    }
  }

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  remove = (obj, key) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.remove(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join(".")
      );
    }
    if (keySplit.length == 1) {
      // obj[key] = val;
      // console.log(obj[key]);
      delete obj[key];
      return obj[key];
    } else {
      // obj = val;
      return obj;
    }
  };

  handleRemove = (obj, key) => () => {
    let copy = { ...this.state[obj] };
    this.remove(copy, key);
    this.setState({ [obj]: copy });
  };

  add = (obj, key, field, type) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.add(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        field
      );
    }

    if (keySplit.length == 1 && keySplit[0] != "") {
      if (type === "object") return (obj[key][field] = {});
      else
        return (obj[key][field] =
          key === "windowType" ? obj.windowType.field : "");
    } else {
      // obj = val;
      return (obj[field] = {});
    }
  };

  handleAddChange = (obj) => (e) => {
    this.setState({ [obj]: e.target.value });
  };

  handleAdd = (obj, key, type) => () => {
    let copy = { ...this.state[obj] };
    if (type === "field") {
      this.add(copy, key, this.state.field);
      this.setState({ [obj]: copy, add3: false, field: "", open: false });
    } else if (type === "tab") {
      this.add(copy, key, this.state.tab, "object");
      this.setState({ [obj]: copy, add1: false, add4: false, tab: "" });
    }
  };

  handleAddDropDown = (obj, key, type, selectedValue) => {
    let copy = { ...this.state[obj] };
    copy = { ...copy, ...{ windowType: { field: selectedValue } } };
    // return;
    if (type === "field") {
      this.add(copy, key, type);
      this.setState({ [obj]: copy, add5: false, field: "", open: false });
    } else if (type === "tab") {
      this.add(copy, key, this.state.tab, "object");
      this.setState({ [obj]: copy, add5: false, tab: "" });
    }
  };

  handleChange =
    (param, input, type = "string") =>
    (e) => {
      let copy = { ...this.state[param] };
      if (type === "array") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, [val]);
      } else if (type === "checkbox") {
        this.reduce(copy, input, e.target.checked);
      } else if (type === "number") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, val);
      } else {
        this.reduce(copy, input, e.target.value);
      }
      // console.log(copy)
      this.setState({ [param]: copy });
    };

  handleSelectChange = (state, key) => async (option) => {
    let copy = { ...this.state[state] };
    copy[key] = option ? option.value : "";
    let defaultKey = "";
    let value = option ? option.value : "";
    let defaultValue = option ? option.key : 0;

    if (key === "sensorType") {
      defaultKey = "defaultSensorType";
    }
    this.setState({ [state]: copy, [defaultKey]: defaultValue });
  };

  isValid = (type) => {
    if (type === "sensor") {
      let sensor = { ...this.state.sensor };
      if (!sensor.id || !sensor.sensorType || !sensor.tagId) {
        return false;
      }
      return true;
    }
  };

  handleNext = async (page) => {
    if (this.state.currentPage === 2) {
      this.setState({ saving: true });

      //Sensor API
      let process = { ...this.state.process };

      //Sensor Process Config
      let processConfigurations = { ...this.state.processConfigurations };
      // console.log(processConfigurations, 'processConfigurations');

      process.processConfigurations = processConfigurations;

      let sensorConfigAPI = await this.saveSensorConfig(process);
      if (sensorConfigAPI.status === 200) {
        toast.success("Process Configuration saved successfully.");
        this.setState({ readOnly: true, currentPage: 2, saving: false });
        this.jump("/v1/process-configs");
      } else if (sensorConfigAPI.status === 400) {
        toast.warning(sensorConfigAPI.message);
        this.setState({ saving: false });
      } else if (sensorConfigAPI.status === 500) {
        toast.warning("Something went wrong. Please try again.");
        this.setState({ saving: false });
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let process = { ...this.state.process };
        //Validating
        let err = await validation.validate(process);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }
      if (valid) this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.setState({ saving: false });
  };

  handleBack = (page) => {
    if (page === "pageSensorAdd") {
      this.setState({ list: true });
    }

    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  handleCancel() {
    this.setState({
      readOnly: true,
      sensor: this.state.sensorBackup,
    });
    // this.jump('/v1/sensors/' + companyId);
  }

  async saveSensorConfig(sensorConfig) {
    let sensorConfigAPI = await axios.postData(
      "/process-configs",
      sensorConfig,
      sensorConfig._id
    );
    return {
      status: sensorConfigAPI.status,
      message: sensorConfigAPI.data.message,
    };
  }

  jump(url) {
    setTimeout(() => {
      this.props.history.push(url);
    }, 1000);
  }

  handleExpandChange = (panel) => (e, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  // handleTabChange = (index, lastindex, event) => {
  //   let currentTab = Object.keys(this.state.processConfigurations)[index];
  //   let currentTab2 = Object.keys(this.state.processConfigurations[currentTab])[0];
  //   let objectLevel = levels[currentTab];

  //   this.setState({currentTab: currentTab, currentTab2: currentTab2, objectLevel: objectLevel});
  // }

  // handleTabChange2 = (index, lastindex, event) => {
  //   this.setState({currentTab2: Object.keys(this.state.processConfigurations[this.state.currentTab])[index]})
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.processConfiguration !== prevState.processConfiguration) {
      if (nextProps.processConfiguration) {
        let data = nextProps.processConfiguration.data;
        let process = {
          _id: data._id,
          processConfig: data.processConfig,
          description: data.description,
        };
        return {
          process: process,
          processBackup: process,
          processConfigurations: data.processConfigurations
            ? data.processConfigurations
            : prevState.processConfigurations,
        };
      }
    }
  }

  render() {
    let processConfig = "";

    processConfig = Object.keys(this.state.processConfigurations).map(
      (field1, i) => {
        return (
          <TabPanel style={{ marginBottom: "20px" }}>
            {levels[field1] === 2 ? (
              <Tabs>
                {!this.state.readOnly && (
                  <div
                    onClick={() => {
                      this.setState({ add4: true });
                    }}
                    className="icon custom-icon rem-icon icon-shape bg-info text-white rounded-circle shadow"
                  >
                    <i className="fa fa-plus" />
                  </div>
                )}

                {this.state.add4 && (
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Key name:</label>
                      <Input
                        className="form-control-alternative"
                        id="input-username"
                        type="text"
                        value={this.state.tab}
                        onChange={this.handleAddChange("tab")}
                      />{" "}
                      <br />
                      <Button
                        className={classes.deleteImage}
                        color="danger"
                        onClick={() => {
                          this.setState({ add4: false, field: "" });
                        }}
                        size="sm"
                      >
                        Cancel
                      </Button>
                      <Button
                        className={classes.deleteImage}
                        color="success"
                        onClick={this.handleAdd(
                          "processConfigurations",
                          `${field1}`,
                          "tab"
                        )}
                        size="sm"
                      >
                        Add
                      </Button>
                    </FormGroup>
                  </Col>
                )}

                <TabList>
                  {Object.keys(this.state.processConfigurations[field1]).map(
                    (field2, i) => {
                      return (
                        <Tab style={{ color: "#e57373" }}>
                          {field2}
                          {!this.state.readOnly && (
                            <div
                              onClick={this.handleRemove(
                                "processConfigurations",
                                `${field1}.${field2}`
                              )}
                              className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                            >
                              <i className="fa fa-times" />
                            </div>
                          )}
                        </Tab>
                      );
                    }
                  )}
                </TabList>

                {Object.keys(this.state.processConfigurations[field1]).map(
                  (field2, i) => {
                    return (
                      <TabPanel>
                        <Row className="mt-5">
                          {Object.keys(
                            this.state.processConfigurations[field1][field2]
                          ).map((field3, i) => {
                            let type = "";
                            if (
                              field2 === "faultFrequencies" ||
                              field2 === "faultFrequencyThresholds" ||
                              field2 === "powerFunctionCoefficients"
                            ) {
                              type = "array";
                            }
                            if (
                              field2 === "clipping_thresholds" ||
                              field2 === "faultFrequencyData" ||
                              field2 === "vibration" ||
                              field2 === "ultrasonic"
                            ) {
                              type = "number";
                            }
                            if (field2 === "boundLimits") {
                              type = field3 === "lowerLimit" ? "number" : "";
                            }
                            return (
                              <Col lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    {field3}
                                  </label>
                                  {!this.state.readOnly && (
                                    <div
                                      onClick={this.handleRemove(
                                        "processConfigurations",
                                        `${field1}.${field2}.${field3}`
                                      )}
                                      className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                                    >
                                      <i className="fa fa-times" />
                                    </div>
                                  )}
                                  {type === "array" || type === "number" ? (
                                    <Input
                                      className="form-control-alternative"
                                      id="input-username"
                                      type="number"
                                      disabled={this.state.readOnly}
                                      value={
                                        this.state.processConfigurations[
                                          field1
                                        ][field2][field3]
                                      }
                                      onChange={this.handleChange(
                                        "processConfigurations",
                                        `${field1}.${field2}.${field3}`,
                                        type
                                      )}
                                    />
                                  ) : (
                                    <Input
                                      className="form-control-alternative"
                                      id="input-username"
                                      type="text"
                                      disabled={this.state.readOnly}
                                      value={
                                        this.state.processConfigurations[
                                          field1
                                        ][field2][field3]
                                      }
                                      onChange={this.handleChange(
                                        "processConfigurations",
                                        `${field1}.${field2}.${field3}`,
                                        type
                                      )}
                                    />
                                  )}
                                </FormGroup>
                              </Col>
                            );
                          })}
                        </Row>
                        {!this.state.readOnly && (
                          <div
                            onClick={() => {
                              this.setState({ add3: true });
                            }}
                            className="icon custom-icon rem-icon icon-shape bg-success text-white rounded-circle shadow"
                          >
                            <i className="fa fa-plus" />
                          </div>
                        )}
                        {this.state.add3 && (
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                Key name:
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.field}
                                onChange={this.handleAddChange("field")}
                              />{" "}
                              <br />
                              <Button
                                className={classes.deleteImage}
                                color="danger"
                                onClick={() => {
                                  this.setState({ add3: false, field: "" });
                                }}
                                size="sm"
                              >
                                Cancel
                              </Button>
                              <Button
                                className={classes.deleteImage}
                                color="success"
                                onClick={this.handleAdd(
                                  "processConfigurations",
                                  `${field1}.${field2}`,
                                  "field"
                                )}
                                size="sm"
                              >
                                Add
                              </Button>
                            </FormGroup>
                          </Col>
                        )}
                      </TabPanel>
                    );
                  }
                )}
              </Tabs>
            ) : (
              <>
                <Row className="mt-5">
                  {field1 != "faultDetection" &&
                  field1 != "aeFaultDetection" ? (
                    <>
                      {Object.keys(
                        this.state.processConfigurations[field1]
                      ).map((field2, i) => {
                        return (
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                {field2}
                              </label>
                              {!this.state.readOnly && (
                                <div
                                  onClick={this.handleRemove(
                                    "processConfigurations",
                                    `${field1}.${field2}`
                                  )}
                                  className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                                >
                                  <i className="fa fa-times" />
                                </div>
                              )}

                              {field2 != "resampling" ? (
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.processConfigurations[field1][
                                      field2
                                    ]
                                  }
                                  readOnly={this.state.readOnly}
                                  onChange={this.handleChange(
                                    "processConfigurations",
                                    `${field1}.${field2}`
                                  )}
                                />
                              ) : (
                                <>
                                  <br />
                                  <Checkbox
                                    checked={
                                      this.state.processConfigurations[field1][
                                        field2
                                      ]
                                    }
                                    onChange={this.handleChange(
                                      "processConfigurations",
                                      `${field1}.${field2}`,
                                      "checkbox"
                                    )}
                                    color="primary"
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                    disabled={this.state.readOnly}
                                  />
                                </>
                              )}
                            </FormGroup>
                          </Col>
                        );
                      })}
                      {!this.state.readOnly && (
                        <div
                          onClick={() => {
                            console.log(field1);
                            if (field1 == "windowType") {
                              this.setState({ add5: true });
                            } else {
                              this.setState({ add3: true });
                            }
                          }}
                          className="icon custom-icon rem-icon icon-shape bg-success text-white rounded-circle shadow"
                        >
                          <i className="fa fa-plus" />
                        </div>
                      )}
                      {this.state.add3 && (
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label">
                              Key name:
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              value={this.state.field}
                              onChange={this.handleAddChange("field")}
                            />{" "}
                            <br />
                            <Button
                              className={classes.deleteImage}
                              color="danger"
                              onClick={() => {
                                this.setState({ add3: false, field: "" });
                              }}
                              size="sm"
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.deleteImage}
                              color="success"
                              onClick={this.handleAdd(
                                "processConfigurations",
                                `${field1}`,
                                "field"
                              )}
                              size="sm"
                            >
                              Add
                            </Button>
                          </FormGroup>
                        </Col>
                      )}
                      {this.state.add5 && field1 === "windowType" && (
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label">
                              Key name:
                            </label>

                            <Select
                              onChange={(e) => {
                                this.handleAddDropDown(
                                  "processConfigurations",
                                  `${field1}`,
                                  "field",
                                  e.value
                                );
                              }}
                              placeholder=""
                              defaultValue={this.state.windowTypeOptions[0]}
                              options={this.state.windowTypeOptions}
                            />

                            {/* <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              value={this.state.field}
                              onChange={this.handleAddChange("field")}
                            />{" "}
                            <br />
                            <Button
                              className={classes.deleteImage}
                              color="danger"
                              onClick={() => {
                                this.setState({ add3: false, field: "" });
                              }}
                              size="sm"
                            >
                              Cancel
                            </Button> */}
                            {/* <Button
                              className={classes.deleteImage}
                              color="success"
                              onClick={this.handleAdd(
                                "processConfigurations",
                                `${field1}`,
                                "field"
                              )}
                              size="sm"
                            >
                              Add
                            </Button> */}
                          </FormGroup>
                        </Col>
                      )}
                    </>
                  ) : (
                    <>
                      {!this.state.readOnly && (
                        <div
                          onClick={() => {
                            this.setState({ add4: true });
                          }}
                          className="icon custom-icon rem-icon icon-shape bg-info text-white rounded-circle shadow"
                        >
                          <i className="fa fa-plus" />
                        </div>
                      )}

                      {this.state.add4 && (
                        <Col lg="4">
                          <FormGroup>
                            <label className="form-control-label">
                              Key name:
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              type="text"
                              value={this.state.tab}
                              onChange={this.handleAddChange("tab")}
                            />{" "}
                            <br />
                            <Button
                              className={classes.deleteImage}
                              color="danger"
                              onClick={() => {
                                this.setState({ add4: false, field: "" });
                              }}
                              size="sm"
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.deleteImage}
                              color="success"
                              onClick={this.handleAdd(
                                "processConfigurations",
                                `${field1}`,
                                "tab"
                              )}
                              size="sm"
                            >
                              Add
                            </Button>
                          </FormGroup>
                        </Col>
                      )}

                      {Object.keys(
                        this.state.processConfigurations[field1]
                      ).map((fault, i) => {
                        if (
                          fault === "bearing_faults" ||
                          fault === "gear_fault"
                        ) {
                          return (
                            <Container maxWidth="sm">
                              <ExpansionPanel
                                expanded={this.state.expanded === "panel" + i}
                                onChange={this.handleExpandChange("panel" + i)}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions1-content"
                                  id="additional-actions1-header"
                                >
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={<div></div>}
                                    label={fault}
                                  />
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails>
                                  <Container maxWidth="sm">
                                    <Row>
                                      <Button
                                        onClick={(e) => {
                                          switch (fault) {
                                            case "bearing_faults":
                                              this.handleGMFGearFaultAdd(
                                                "bearing_faults"
                                              );
                                              break;
                                            case "gear_fault":
                                              this.handleGMFGearFaultAdd(
                                                "gear_fault"
                                              );
                                              break;

                                            default:
                                              break;
                                          }
                                        }}
                                      >
                                        ADD NEW
                                      </Button>
                                    </Row>
                                    <Row>
                                      {this.state.processConfigurations[field1][
                                        fault
                                      ].map((iArrFault, key) => {
                                        return (
                                          <Row>
                                            {"\n"}
                                            {"\n"}
                                            {"\n"}
                                            <label> </label>
                                            <label>
                                              <b>{key}</b>
                                            </label>
                                            {Object.keys(
                                              this.state.processConfigurations[
                                                field1
                                              ][fault][key]
                                            ).map((field2, i) => {
                                              if (field2 != "apply") {
                                                let element = [];
                                                element.push(
                                                  <Col key={i} lg="3">
                                                    <FormGroup>
                                                      <label className="form-control-label">
                                                        {field2}
                                                      </label>
                                                      <Input
                                                        disabled={
                                                          this.state.readOnly
                                                        }
                                                        className="form-control-alternative"
                                                        id="input-username"
                                                        type="text"
                                                        value={
                                                          this.state
                                                            .processConfigurations[
                                                            field1
                                                          ][fault][key][field2]
                                                        }
                                                        // onChange={this.handleGMFGearFaultProcess(
                                                        //   "processConfiguration",
                                                        //   `${field1}.${fault}.${key}.${field2}`,
                                                        //   key
                                                        // )}
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                );
                                                return element;
                                              }
                                            })}
                                          </Row>
                                        );
                                      })}
                                    </Row>
                                  </Container>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Container>
                          );
                        }
                        return (
                          <Container maxWidth="sm">
                            <ExpansionPanel
                              expanded={this.state.expanded === "panel" + i}
                              onChange={this.handleExpandChange("panel" + i)}
                            >
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header"
                              >
                                <Typography className={classes.heading}>
                                  {fault}
                                </Typography>
                                {!this.state.readOnly && (
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <div
                                        onClick={() => {
                                          this.setState({
                                            open: true,
                                            faultField: fault,
                                          });
                                        }}
                                        style={{ marginLeft: "20px" }}
                                        className="icon custom-icon rem-icon icon-shape bg-success text-white rounded-circle shadow"
                                      >
                                        <i className="fa fa-plus" />
                                      </div>
                                    }
                                    label={""}
                                  />
                                )}
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails>
                                <Container maxWidth="sm">
                                  <Row>
                                    {Object.keys(
                                      this.state.processConfigurations[field1][
                                        fault
                                      ]
                                    ).map((field2, i) => {
                                      if (field2 != "apply") {
                                        return (
                                          <Col lg="3">
                                            <FormGroup>
                                              <label className="form-control-label">
                                                {field2}
                                              </label>
                                              {!this.state.readOnly && (
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={this.handleRemove(
                                                    "processConfigurations",
                                                    `${field1}.${fault}.${field2}`
                                                  )}
                                                  className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                                                >
                                                  <i className="fa fa-times" />
                                                </div>
                                              )}
                                              <Input
                                                disabled={this.state.readOnly}
                                                className="form-control-alternative"
                                                id="input-username"
                                                type="text"
                                                value={
                                                  this.state
                                                    .processConfigurations[
                                                    field1
                                                  ][fault][field2]
                                                }
                                                onChange={this.handleChange(
                                                  "processConfigurations",
                                                  `${field1}.${fault}.${field2}`
                                                )}
                                              />
                                            </FormGroup>
                                          </Col>
                                        );
                                      }
                                    })}
                                  </Row>
                                </Container>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Container>
                        );
                      })}
                    </>
                  )}
                </Row>
              </>
            )}
          </TabPanel>
        );
      }
    );

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.jump("/v1/sensors/" + row.id);
      },
    };

    let label = "";
    if (this.state.currentPage === 1) label = "Process Configuration";
    else if (this.state.currentPage === 2) label = "Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Process Configuration";

    let front = "";
    if (this.state.currentPage === 1) front = "Configurations";

    return (
      <>
        <Dialog
          disableBackdropClick={true}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Key</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Key Name"
              type="text"
              fullWidth
              value={this.state.field}
              onChange={this.handleAddChange("field")}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.deleteImage}
              color="danger"
              onClick={() => this.setState({ open: false })}
              size="sm"
            >
              Cancel
            </Button>
            <Button
              className={classes.deleteImage}
              color="success"
              onClick={this.handleAdd(
                "processConfigurations",
                `faultDetection.${this.state.faultField}`,
                "field"
              )}
              size="sm"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {this.state.currentPage > 0 && (
                        <h3 className="mb-0">{label}</h3>
                      )}
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.readOnly && this.state.createAllowed && (
                        <Button
                          className={classes.deleteImage}
                          color="primary"
                          onClick={() => this.setState({ readOnly: false })}
                          size="md"
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Process Config
                                <small className="text-info">
                                  {" "}
                                  (For unassigning sensor enter <b>'None'</b>)
                                </small>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-username"
                                type="text"
                                value={this.state.process.processConfig}
                                onChange={this.handleChange(
                                  "process",
                                  "processConfig"
                                )}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Description
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-about-company"
                                // placeholder="A few words about company ..."
                                rows="8"
                                type="textarea"
                                value={this.state.process.description}
                                onChange={this.handleChange(
                                  "process",
                                  "description"
                                )}
                                disabled={this.state.readOnly}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <>
                        <div className="pl-lg-4">
                          {!this.state.readOnly && (
                            <div
                              onClick={() => {
                                this.setState({ add1: true });
                              }}
                              className="icon custom-icon rem-icon icon-shape bg-info text-white rounded-circle shadow"
                            >
                              <i className="fa fa-plus" />
                            </div>
                          )}
                          {this.state.add1 && (
                            <>
                              <Col className="add-tab" md="12">
                                <Col md="6">
                                  <FormGroup>
                                    <label className="form-control-label">
                                      Key name:
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-username"
                                      type="text"
                                      value={this.state.tab}
                                      onChange={this.handleAddChange("tab")}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md="6">
                                  <Button
                                    className={classes.deleteImage}
                                    color="danger"
                                    onClick={() => {
                                      this.setState({ add1: false, tab: "" });
                                    }}
                                    size="sm"
                                  >
                                    Cancel
                                  </Button>

                                  <Button
                                    className={classes.deleteImage}
                                    color="success"
                                    onClick={this.handleAdd(
                                      "processConfigurations",
                                      "",
                                      "tab"
                                    )}
                                    size="sm"
                                  >
                                    Add
                                  </Button>
                                </Col>
                                <br />
                              </Col>
                            </>
                          )}
                        </div>

                        <div className="pl-lg-4">
                          <Tabs onSelect={this.handleTabChange}>
                            <TabList>
                              {/* <Tab style={{backgroundColor:'#e57373', color: '#fff'}}>RUL</Tab>
                            <Tab style={{backgroundColor:'#FFB74D', color: '#fff'}}>Sensor Data Process Configuration</Tab>
                            <Tab style={{backgroundColor:'#4FC3F7', color: '#fff'}}>Algorithm Preference</Tab> */}
                              {Object.keys(
                                this.state.processConfigurations
                              ).map((field, i) => {
                                return (
                                  <Tab
                                    style={{
                                      backgroundColor: "#FFB74D",
                                      color: "#fff",
                                    }}
                                  >
                                    {field}
                                    {!this.state.readOnly && (
                                      <div
                                        onClick={this.handleRemove(
                                          "processConfigurations",
                                          `${field}`
                                        )}
                                        className="icon custom-icon rem-icon icon-shape bg-danger text-white rounded-circle shadow"
                                      >
                                        <i className="fa fa-times" />
                                      </div>
                                    )}
                                  </Tab>
                                );
                              })}
                            </TabList>

                            {processConfig}
                          </Tabs>
                        </div>
                      </>
                    )}

                    {this.state.currentPage <= 2 && (
                      <Row>
                        <Col lg="12">
                          {this.state.currentPage < 2 && !this.state.saving && (
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                            >
                              {front}
                              <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                <i className="fa fa-forward" />
                              </div>
                            </Button>
                          )}

                          {!this.state.readOnly &&
                            this.state.currentPage === 2 && (
                              <Button
                                className="right"
                                color="success"
                                onClick={() =>
                                  this.handleNext(
                                    this.state.pages[this.state.currentPage + 1]
                                  )
                                }
                                size="md"
                                disabled={this.state.saving}
                              >
                                {this.state.saving ? (
                                  <i className="fas fa-spinner fa-pulse"></i>
                                ) : (
                                  <>
                                    Save
                                    <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                      <i className="fa fa-paper-plane" />
                                    </div>
                                  </>
                                )}
                              </Button>
                            )}

                          {this.state.currentPage > 1 && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="info"
                              onClick={() =>
                                this.handleBack(
                                  this.state.pages[this.state.currentPage]
                                )
                              }
                              size="md"
                            >
                              <div className="icon custom-icon icon-shape bg-info text-white rounded-circle shadow">
                                <i className="fa fa-backward" />
                              </div>
                              {back}
                            </Button>
                          )}

                          {!this.state.readOnly && !this.state.saving && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() => this.handleCancel()}
                              size="md"
                            >
                              Cancel
                              <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                <i className="fa fa-ban" />
                              </div>
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  processConfiguration: _.isEmpty(state.processConfiguration.processConfig)
    ? null
    : state.processConfiguration.processConfig,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProcessConfigData: (id) => dispatch(fetchProcessConfigData(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorDetails);

// export default SensorDetails;
